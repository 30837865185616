import {action, computed, IReactionDisposer, makeObservable, observable, reaction, toJS} from "mobx"
import {ProfileModel, ProfileType} from "../models/ProfileModel"
import {CurrentAccount} from "../../vizz_account/lib/CurrentAccount"
import {create, persist} from "mobx-persist"
import AsyncStorage from "@react-native-async-storage/async-storage"
import {GroupCallHistoryItem} from "../models/GroupCallHistoryItem"
import {NativeState} from "../../../app/services/NativeStateService"
import {AppController} from "../../../app/controllers/AppController"
import {ProfileStatusService} from "../services/ProfileStatusService"
import {FeatureFlagModel} from "../../vizz_account/models/FeatureFlagModel"
import HomeController from "../../start/controllers/HomeController"
import {CallUtils} from "../models/Call"
import {NotificationService} from "../../../app/services/NotificationService"
import {RobloxPresenceExtensions, RobloxUserPresenceType} from "../models/RobloxFriendModel"
import {ProfileStatus} from "../models/ProfileStatus"
import {SchoolModel} from "../models/SchoolModel"
import {LAVA_BOT_PRESENCE} from "../models/LavaBot"
import {GameBookModel} from "../../browse/models/GameBookModel"
import SentryService from "../../../app/services/SentryService"
import {comparePresenceLists, PresenceModel} from "../models/PresenceModel"
import {SocialStatusUpdateResponse} from "../models/SocialStatusUpdateResponse"
import {CallConnection} from "../models/CallConnection"
import {PermissionUtils} from "../../../app/utils/PermissionUtils"
import {ChannelModel, compareChannelLists} from "../models/ChannelModel"
import {ArrayUtils} from "../../../app/utils/ArrayUtils"
import {isTablet} from "../../../app/lib/Appearance"

export enum FriendWidgetState {
    LOADING,
    SETUP_PROFILE,
    NOTIFICATIONS_DISABLED,
    MICROPHONE_DISABLED,
    POLLING_DISABLED,
    FRIENDS,
}

export enum FriendsTab {
    FRIENDS,
    GROUPS
}

const hydrate = create({
    storage: AsyncStorage,
    jsonify: true,
})

export class FriendWidgetController {
    private debug: boolean = false   // don't set this to true in production

    private currentAccount: CurrentAccount
    public appController: AppController
    private cleanupAppStateReaction?: IReactionDisposer
    private cleanupControllerStateReaction?: IReactionDisposer
    public homeController?: HomeController
    private cleanupCallConnectionReaction?: IReactionDisposer

    @observable state: FriendWidgetState = FriendWidgetState.LOADING
    @observable @persist('list') friendPresences: PresenceModel[] = [];            @action private setFriendPresences(friendPresences: PresenceModel[]) { this.friendPresences = friendPresences }
    @observable @persist('list') callPresences: PresenceModel[] = [];              @action private setCallPresences(callPresences: PresenceModel[]) { this.callPresences = callPresences }
    @observable @persist('list') channels: ChannelModel[] = [];                    @action private setChannels(channels: ChannelModel[]) { this.channels = channels }
    @observable outgoingFriendRequestProfiles: ProfileModel[] = []
    @observable addFriendVisible: boolean = false
    @observable invitationReminderVisible: boolean = false
    @observable @persist('list') groupCallHistory: GroupCallHistoryItem[] = []
    @observable groupCallVisible: boolean = false
    @observable lavaBotPresence?: PresenceModel;                                   @action private setLavaBotPresence(presence?: PresenceModel) { this.lavaBotPresence = presence }
    @observable parentProfile?: ProfileModel;                                      @action private setParentProfile(profile?: ProfileModel) { this.parentProfile = profile }
    @observable personKeysForChannelDetail?: string[];                             @action private setPersonKeysForChannelDetail(keys?: string[]) { this.personKeysForChannelDetail = keys }
    @observable isShowingMinimizedCall: boolean = false;                           @action public setShowingMinimizedCall(showing: boolean) { this.isShowingMinimizedCall = showing }
    @observable robloxFriendsCount:number = 0;                                     @action public setRobloxFriendsCount(count: number) { this.robloxFriendsCount = count }
    nextChannelDetailAutoStartAction?: 'call'|'callback'|'chat';                   setNextChannelDetailAutoStartAction(action?: 'call'|'callback'|'chat') { this.nextChannelDetailAutoStartAction = action }

    @observable school?: SchoolModel | null
    @observable isLoadingProfileDetail: boolean = false
    @observable showingParentFlow: boolean = false;                                @action public setShowingParentFlow(show: boolean) { this.showingParentFlow = show }

    public groupCallPreselect: ProfileModel[] = []

    constructor(currentAccount: CurrentAccount, appController: AppController, homeController: HomeController) {
        this.consoleDebug(`new()`)

        this.currentAccount = currentAccount
        this.appController = appController
        if (homeController) {
            this.homeController = homeController
        }

        void hydrate('social-v2', this)

        makeObservable(this)
    }

    public async initialize() {
        this.consoleDebug(`initialize()`)

        if (!this.lavaBotPresence && this.currentAccount.profile?.username != LAVA_BOT_PRESENCE.profile_username) {
            this.setLavaBotPresence(LAVA_BOT_PRESENCE)
        }

        const robloxFriendsResponse = await this.currentAccount.api.get('roblox_people_path')
        this.setRobloxFriendsCount(robloxFriendsResponse.length ?? 0)

        this.cleanupControllerStateReaction = reaction(() => this.state, async(state) => {
            if (state == FriendWidgetState.FRIENDS) {
                await this.appController.notification.refreshPushTokens()

                if (NotificationService.platformDeviceToken) {
                    await this.appController.message.addPushToken(NotificationService.platformDeviceToken)
                }
            }
        })

        this.appController.backgroundRefresh.addCallback('socialPollCallback', async() => { // overwrites existing socialPollCallback
            const statusResponse = await ProfileStatusService.frequentUpdateStatus(this.currentAccount, this.appController) as SocialStatusUpdateResponse // does not start working until personData.profileOnboardingNeeded = false

            if (statusResponse != undefined) {

                // NOTE: In the following code, we run deep list compare code to determine if the new list has changed
                // and avoid setting the observable if not. This prevents a re-render of the entire component tree
                // that would otherwise occur on every poll since MobX/React triggers a reaction
                // on list objects reference change (e.g. replacing the list entirely).

                if (!comparePresenceLists(this.friendPresences, statusResponse.friend_presences)) {
                    this.setFriendPresences(statusResponse.friend_presences)
                }

                if (!comparePresenceLists(this.callPresences, statusResponse.call_presences)) {
                    this.setCallPresences(statusResponse.call_presences)
                }

                if (!compareChannelLists(this.channels, statusResponse.channels)) {
                    this.setChannels(statusResponse.channels)
                }

                if (this.outgoingFriendRequestProfiles.length != (statusResponse.outgoing_friend_request_profiles ?? []).length) {
                    this.setOutgoingFriendRequestProfiles(statusResponse.outgoing_friend_request_profiles)
                }

                await this.appController.call.onAppPoll(statusResponse)
                if (statusResponse.profile) {
                    await this.appController.message.onProfileUpdate(statusResponse.profile)
                    this.currentAccount.setProfile(statusResponse.profile)
                }

                if (this.homeController?.inboxWidgetController?.notReadMessageStories.length != statusResponse.not_read_message_stories_count) {
                    await this.homeController?.inboxWidgetController?.refreshInbox()
                }

                if (this.homeController?.feedWidgetController?.totalStoriesCount != statusResponse.feed_stories_count) {
                    await this.homeController?.feedWidgetController?.refreshFeed()
                }

                this.currentAccount.personData.hasRobloxAccount = statusResponse.profile.roblox_profile != undefined

                const myRecentGameBooks = statusResponse.recent_game_books as GameBookModel[]
                if (myRecentGameBooks && this.homeController &&
                    JSON.stringify(toJS(myRecentGameBooks)) !== JSON.stringify(this.homeController.recentGameBooks)) { // new data!
                    this.homeController?.setRecentGameBooks(myRecentGameBooks)
                }

                if (!this.lavaBotPresence && this.currentAccount.profile?.username != LAVA_BOT_PRESENCE.profile_username) {
                    this.setLavaBotPresence(LAVA_BOT_PRESENCE)
                }

                if (this.currentAccount.person.properties.phone_number && this.currentAccount.person.properties.phone_number_who && !this.parentProfile) {
                    this.setParentProfile({
                        id: 101,
                        person_id: 101,
                        person_key: 'abc-101-uuid',
                        username: `Moms_Phone`,
                        first_name: `${this.currentAccount.person.properties.phone_number_who}s`,
                        nickname: `Phone`,
                        last_changed: 'Always Online',
                        status: ProfileStatus.ONLINE,
                        created_at: '123',
                        updated_at: '123',
                        profile_type: ProfileType.PARENT,
                        pretty_username: `${this.currentAccount.person.properties.phone_number_who}'s Phone`
                    })
                }
            }

            await this.determineAndSetState()
        })

        this.cleanupAppStateReaction = reaction(() => this.appController.nativeState.state, (state) => {
            if (state == NativeState.FOREGROUND) void this.onAppForeground()
        })

        await this.onAppForeground()

        const onCallActiveBlock = (callConnection?: CallConnection) => {
            if (callConnection) {
                // If we are initiating a call, make sure the profile card is up.
                if (!this.personKeysForChannelDetail) {
                    const personKeys = CallUtils.participantPersonKeysExcludingPerson(callConnection.call, this.currentAccount.person.id)
                    void this.openChannelDetails(personKeys)
                }
            } else {
                if (this.isShowingMinimizedCall) {
                    this.setShowingMinimizedCall(false)
                }
            }
        }

        this.cleanupCallConnectionReaction = reaction(() => this.appController.call.activeCallConnection, (callConnection) => {
            onCallActiveBlock(callConnection)
        })

        // If we already have a call connection, trigger the block because we may have missed
        // a reaction
        if (this.appController.call.activeCallConnection) {
            onCallActiveBlock(this.appController.call.activeCallConnection)
        }

        if (this.currentAccount.person.school_id) {
            try {
                const school = await this.currentAccount.api.get('school_path') as SchoolModel | null
                this.setSchool(school)
            } catch (error) {
                SentryService.captureError(error)
            }
        }
    }

    public uninitialize() {
        this.consoleDebug(`uninitialize()`)
        if (this.cleanupAppStateReaction) this.cleanupAppStateReaction()
        if (this.cleanupControllerStateReaction) this.cleanupControllerStateReaction()
        if (this.cleanupCallConnectionReaction) this.cleanupCallConnectionReaction()
    }

    public async onAppForeground() {
        this.consoleDebug(`onAppForeground()`)

        await this.appController.notification.clearBadge()

        await this.determineAndSetState()
        await this.refreshPoll() // one attempt to reconnect

        if (this.state != FriendWidgetState.FRIENDS) return

        await this.appController.backgroundRefresh.call('socialPollCallback')

        const onlineFriendPresences = this.friendPresences.filter((f) => f.broadcasted_status == 'online')

        this.currentAccount.analytics.logEvent('friendslist', 'loaded', `${onlineFriendPresences.length}/${this.friendPresences.length} online`, {
            total_friends_online: onlineFriendPresences.length,
            total_friends: this.friendPresences.length,
            friends_online: onlineFriendPresences.map((f) => f.profile_username).join(', '),
            friends: this.friendPresences.map((f) => f.profile_username).join(', '),
        })
    }

    public async determineAndSetState() {
        const notificationsEnabled = await this.appController.notification.alreadyEnabled()
        const microphoneEnabled = await PermissionUtils.isAudioEnabled()

        if (this.currentAccount.personData.profileOnboardingNeeded) {
            this.setState(FriendWidgetState.SETUP_PROFILE)
        } else if (!notificationsEnabled) {
            this.setState(FriendWidgetState.NOTIFICATIONS_DISABLED)
        } else if (!microphoneEnabled) {
            this.setState(FriendWidgetState.MICROPHONE_DISABLED)
        } else {
            if (!this.currentAccount.hasFeature(FeatureFlagModel.SOCIAL_POLLING) || this.appController.lastPollFailed) {
                this.setState(FriendWidgetState.POLLING_DISABLED)
            } else {
                this.setState(FriendWidgetState.FRIENDS)
            }
        }
    }

    // end: initialization methods


    // Public methods

    @computed
    get title(): string {
        const prettyUsername = this.currentAccount.profile?.pretty_username

        if (this.state == FriendWidgetState.FRIENDS && prettyUsername)
            return `${prettyUsername}'s Friends`
        else
            return 'Friends'
    }

    public async refreshPoll() {
        try {
            await this.appController.backgroundRefresh.call('socialPollCallback')
        } catch (error) {
            SentryService.captureError(error)
        }
    }

    @action
    public setState(state: FriendWidgetState) {
        this.consoleDebug(`setState()`)
        if (this.state != state) this.state = state
    }

    @action
    public showAddFriend() {
        this.addFriendVisible = true
        this.currentAccount.analytics.logEvent('social-addfriend', 'opened')
    }

    @action
    public hideAddFriend() {
        if (this.addFriendVisible) {
            this.currentAccount.analytics.logEvent('social-addfriend', 'closed')
            this.addFriendVisible = false
        }
    }

    @action
    public showInvitationReminder() {
        if (!this.invitationReminderVisible) {
            this.invitationReminderVisible = true
            this.currentAccount.analytics.logEvent('social-invitation-reminder', 'opened', this.currentAccount.personData.numberOfInvitationRemindersSeen, {"numberOfInvitationRemindersSeen": this.currentAccount.personData.numberOfInvitationRemindersSeen})
        }
    }

    @action
    public hideInvitationReminder() {
        if (this.invitationReminderVisible) {
            this.currentAccount.personData.lastInvitationReminderTimestamp = (new Date()).toString()
            this.currentAccount.personData.numberOfInvitationRemindersSeen = this.currentAccount.personData.numberOfInvitationRemindersSeen + 1
            this.invitationReminderVisible = false
            this.currentAccount.analytics.logEvent('social-invitation-reminder', 'closed')
        }
    }

    @action
    public showCreateGroup(profiles?: ProfileModel[]) {
        if (profiles)
            this.groupCallPreselect = profiles
        else
            this.groupCallPreselect = []

        this.groupCallVisible = true
        this.currentAccount.analytics.logEvent('social-groupmodal', 'opened')
    }

    @action
    public hideCreateGroup() {
        if (this.groupCallVisible) {
            this.groupCallVisible = false
            this.currentAccount.analytics.logEvent('social-groupmodal', 'closed')
        }
    }

    @computed
    get friendGroups() {
        return this.channels
            .filter((c) => c.people_count > 2)
    }

    // Private helper methods

    private groupCallIdFromPersonKeys(personKeys: string[]) {
        return personKeys
            .sort()
            .join('-')
    }

    @computed
    get friends() {
        return this.friendPresences.map((p) => this.presenceToProfile(p))
    }

    private presenceToProfile(presence: PresenceModel) {
        const lastChanged = presence.playing ? RobloxUserPresenceType.IN_GAME : (presence.broadcasted_status == 'online' ? RobloxUserPresenceType.ONLINE : RobloxUserPresenceType.OFFLINE)

        return {
            id: presence.id,
            person_id: presence.person_id,
            person_key: presence.person_key,
            username: presence.profile_username,
            nickname: presence.profile_nickname,
            first_name: presence.profile_first_name,
            avatar_image_url: presence.roblox_profile_avatar_headshot_url,
            created_at: '',
            updated_at: '',
            pretty_username: presence.pretty_username,
            profile_type: presence.lava_installed ? ProfileType.LAVA : ProfileType.ROBLOX,
            last_changed: RobloxPresenceExtensions.lastChanged(lastChanged),
            last_changed_detail: '',
            status: RobloxPresenceExtensions.status(lastChanged),
            status_detail: RobloxPresenceExtensions.statusDetail(lastChanged),
            roblox_profile: {id: presence.roblox_profile_id}
        } as ProfileModel
    }

    @computed
    get friendAndCallAndChannelPresences() {
        const channelPresences = this.channels.map(channel => channel.presences).flat()
        return this.friendPresences.concat(this.callPresences).concat(channelPresences)
    }

    @computed
    get presencesWithLava() {
        return this.friendPresences
            .filter((f) => f.lava_installed)
            .sort((a, b) =>
                Number(b.broadcasted_status == "online") - Number(a.broadcasted_status == "online"))
    }

    @computed
    get presencesWithoutLava() {
        return this.friendPresences
            .filter((f) => !f.lava_installed)
            .sort((a, b) =>
                Number(b.broadcasted_status == "online") - Number(a.broadcasted_status == "online"))
    }

    @computed
    get shouldShowFindFriendsCallout() {
        return this.presencesWithLava.length == 0 || this.currentAccount.personData.numberOfDaysOpened <= 3
    }

    @action
    private setOutgoingFriendRequestProfiles(outgoingFriendRequestProfiles: ProfileModel[]) {
        this.consoleDebug(`setOutgoingFriendRequestProfiles()`)
        this.outgoingFriendRequestProfiles = outgoingFriendRequestProfiles
    }

    @action
    setSchool(school: SchoolModel | null) {
        this.school = school
    }

    @action
    setLoadingProfileDetail(loading: boolean) {
        this.isLoadingProfileDetail = loading
    }

    public async openChannelDetails(personKeys?: string[], autoStartAction?: 'call'|'callback'|'chat') {
        const existingWithoutSelf = ArrayUtils.arrayRemovingItem(this.personKeysForChannelDetail ?? [], this.currentAccount.person.key)
        const newWithoutSelf = ArrayUtils.arrayRemovingItem(personKeys ?? [], this.currentAccount.person.key)

        if (ArrayUtils.arraysEqual(existingWithoutSelf, newWithoutSelf)) {
            this.consoleDebug('Tried to open channel detail for same people, aborting.')
            return
        }

        await this.homeController?.closeOverlays()
        this.setNextChannelDetailAutoStartAction(autoStartAction)
        this.setPersonKeysForChannelDetail(personKeys)
    }

    public async maximizeActiveCall() {
        this.setShowingMinimizedCall(false)

        const callConnection = this.appController.call.activeCallConnection
        if (!callConnection) return

        await this.closeChannelDetails()
        const personKeys = CallUtils.participantPersonKeysExcludingPerson(callConnection.call, this.currentAccount.person.id)
        await this.openChannelDetails(personKeys)
    }

    public async leaveMinimizedCall() {
        await this.appController.call.leaveCallFromAppUI()
        this.setShowingMinimizedCall(false)
    }

    public async closeChannelDetails() {
        this.setPersonKeysForChannelDetail(undefined)
        this.setNextChannelDetailAutoStartAction(undefined)
    }

    public async startParentApproval() {
        this.setShowingParentFlow(true)
        this.currentAccount.analytics.logEvent('parent-approval', 'started')
    }

    // Private instance utility methods

    private consoleDebug(method: string, force: boolean = false) {
        if (this.debug || force) console.log(`${this.constructor.name}: ${method}  state = ${this.state}`)
    }
}
