import VideoModel from "../../models/VideoModel"
import {Modal} from "react-native-paper"
import {Video} from "../Video"
import React, {useEffect, useRef} from "react"
import {isTablet} from "../../../../app/lib/Appearance"
import {View} from "react-native"

type Props = {
    video?: VideoModel
    onDismiss: () => void
}

export const VideoLightbox = (props: Props) => {

    const videoRef = useRef<any>(null)

    useEffect(() => {
        if (props.video) {
            videoRef.current?.autoPlay()
        }
    }, [props.video])

    return (
        <Modal
            contentContainerStyle={{justifyContent: 'center', alignItems: 'center'}}
            visible={props.video != undefined}
            dismissable={true}
            onDismiss={props.onDismiss}
            style={{backgroundColor: '#00000080', top:-20}}
        >
            {props.video &&
            <View style={{ position:'absolute'}}>
                <Video
                    ref={videoRef}
                    style={{width: isTablet() ? 600 : 300}}
                    video={props.video}
                />
            </View>
            }
        </Modal>
    )

}