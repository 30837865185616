import React, {useContext, useEffect, useRef, useState} from 'react'
import {observer} from "mobx-react"
import {Platform, StyleProp, StyleSheet, Text, View, ViewStyle} from "react-native"
import {FriendWidgetController} from "../../controllers/FriendWidgetController"
import {SpeechUtils} from '../../../../app/utils/AVUtils'
import CurrentAccountContext from '../../../vizz_account/lib/CurrentAccount'
import {PermissionUtils} from '../../../../app/utils/PermissionUtils'
import Button from '../../../lava-components/components/atoms/Button/Button'
import {platformName} from "../../../../app/lib/utils/CrossPlatformUtils"
import {openSettings} from "expo-linking"

type Props = {
    friendWidgetController: FriendWidgetController
    style?: StyleProp<ViewStyle>
}

export const MicrophoneDisabled = observer((props: Props) => {
    const currentAccount = useContext(CurrentAccountContext)
    const [speech] = useState(() => new SpeechUtils(currentAccount, true))
    const [canAsk, setCanAsk] = useState<boolean>(false)
    const isMounted = useRef(false)
    const audioCheckInterval = useRef<any>(undefined)

    useEffect(() => {
        isMounted.current = true

        async function init() {
            await autoSetCanAsk()

            audioCheckInterval.current = setInterval(() => void autoSetCanAsk(), 1000)
        }

        void init()

        return () => {
            isMounted.current = false
            clearTimeout(audioCheckInterval.current) // this is NOT in teardown b/c we don't want to clear it at the end of allow()
            teardown()
        }
    }, [])

    const allow = async() => {
        if (isMounted.current) void speech.speak(Platform.OS == 'ios'
            ? "Tap OK again, it's the button on the right."
            : 'Now tap the first option')
        if (isMounted.current) props.friendWidgetController.homeController?.setDimBackground(true)
        if (isMounted.current) await PermissionUtils.requestAudio(currentAccount)
        if (isMounted.current) await autoSetCanAsk()
        teardown()
    }

    const autoSetCanAsk = async() => {
        const canAsk = await PermissionUtils.canRequestAudio()
        setCanAsk(canAsk)

        if (!canAsk) clearTimeout(audioCheckInterval.current)
    }

    const teardown = () => {
        props.friendWidgetController.homeController?.setDimBackground(false)
        void props.friendWidgetController.determineAndSetState()
    }

    return (
        <View style={[styles.container, props.style]}>
            <Text style={styles.text}>Your mic is disabled so you can't call friends.</Text>

            {canAsk && <Text style={styles.text}>Tap to allow it.</Text>}
            {canAsk && <Button variation={'gravity'} onPress={allow}>Okay</Button>}

            {!canAsk && Platform.OS === 'ios' && <Text style={styles.text}>Go to iOS Settings, find Lava, and tap Microphone.</Text>}
            {!canAsk && Platform.OS === 'android' && <Text style={styles.text}>Go to Android Settings, tap Permissions, and tap Microphone.</Text>}
            {!canAsk && <Button variation={'gravity'} onPress={() => openSettings()}>{platformName()} Settings</Button>}
        </View>
    )
})

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        ...StyleSheet.absoluteFillObject
    },
    text: {
        padding: 10,
        color: 'white',
        textAlign: 'center'
    }
})
