import {CurrentAccount} from "../../../vizz_account/lib/CurrentAccount"
import {FriendWidgetController} from "../FriendWidgetController"
import {Share} from "react-native"
import {action, observable} from 'mobx'
import {ProfileModel} from "../../models/ProfileModel"
import SentryService from "../../../../app/services/SentryService"

export class AddFriendController {
    private debug: boolean = false  // don't set this to true in production

    private currentAccount: CurrentAccount
    public friendWidgetController: FriendWidgetController
    @observable friendSearchResults: ProfileModel[]

    constructor(currentAccount: CurrentAccount, friendWidgetController: FriendWidgetController) {
        this.consoleDebug(`new()`)

        this.currentAccount = currentAccount
        this.friendWidgetController = friendWidgetController
        this.friendSearchResults = []
    }

    public initialize() {
        this.consoleDebug(`initialize()`)
    }

    async searchForFriend(username: string) {
        this.consoleDebug(`searchForFriend()`)

        if (username.trim().length < 2) {
            this.friendSearchResults = []
            return ''
        }

        let message
        let response
        try {
            response = await this.currentAccount.api.get('social.profile_search_path', { 'username': username })
            message = response?.message
        } catch(e) {
            message = `Unexpected error: ${e}`
            SentryService.captureError(e)
        }

        if (response?.success) {
            this.currentAccount.analytics.logEvent('find-friend', 'searched', username, { username: username, search_results: response.search_results})
            this.friendSearchResults = response.search_results as any[]
        } else {
            this.currentAccount.analytics.logEvent('find-friend', 'search-error', message, { message: message, username: username })
        }

        return message
    }


    @action
    private setFriendSearchResults(friendSearchResults: any[]) {
        this.consoleDebug(`setFriendSearchResults()`)
        this.friendSearchResults = friendSearchResults
    }

    // Private instance utility methods

    private consoleDebug(method: string, force: boolean = false) {
        if(this.debug || force) console.log(`${this.constructor.name}: ${method}`)
    }

    async shareLink() {
        this.currentAccount.analytics.logEvent('social-addfriend', 'share-tapped')
        const url = `https://app.lava.co/${this.currentAccount.profile?.username}`
        const message = `Can you install the Lava app so we can connect? It will let us see when we're online so we can FaceTime or chat. Open this link on your iOS device ${url} to install it.`
        await Share.share({message: message})
    }
}
