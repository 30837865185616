export enum FeatureFlagModel {
    // Long-term reliability "features"
    AUTO_UPDATE = 'auto_update',
    SOCIAL_POLLING = 'social_polling',
    SUGGESTIONS_REQUIRE_YOUTUBE = 'suggestions_require_youtube',

    // Long-term feature configuration
    IOS_REVIEWER = 'ios_reviewer',
    ANDROID_REVIEWER = 'android_reviewer',
    MODERATION = 'moderation',

    // Short-term features we're testing and will remove soon
    DAILY_CONTENT = 'daily_content',
    LAVA_BOT_ONBOARD = 'lava_bot_onboard',
    LAVA_BOT_SUBSEQUENT = 'lava_bot_subsequent',
    OFFLINE = 'offline',
}