import React, {useContext, useEffect, useState} from 'react'
import {Dimensions, FlatList, ListRenderItemInfo, StyleProp, StyleSheet, View, ViewStyle} from "react-native"
import {observer} from "mobx-react"
import {FeedWidgetController, FeedWidgetState} from "../controllers/FeedWidgetController"
import CurrentAccountContext from "../../../modules/vizz_account/lib/CurrentAccount"
import AppControllerContext from '../../../app/controllers/AppController'
import HomeController from '../../start/controllers/HomeController'
import VideoModel from '../../browse/models/VideoModel'
import {Widget, WIDGET_DISABLED_OPACITY} from '../../start/views/home/Widget'
import {Story} from './feed_widget/Story'
import BookModel from '../../browse/models/BookModel'
import StoryModel from "../models/StoryModel"
import {InboxWidget} from "./InboxWidget"
import {RobloxGamesWidget} from "../../browse/views/RobloxGamesWidget"
import {LibraryWidget} from "../../browse/views/LibraryWidget"
import {useNavigation} from "@react-navigation/native"
import {RenderLog} from "../../../app/services/RenderLog"

type Props = {
    style?: StyleProp<ViewStyle>
    homeController: HomeController
    onVideoTapped: ((video: VideoModel, position: number) => void)
    onTappedFriendPlayedGame: (book: BookModel) => void
    disabled?: boolean
    horizontal: boolean
}

export const FeedWidget = observer((props: Props) => {
    const currentAccount = useContext(CurrentAccountContext)
    const appController = useContext(AppControllerContext)
    const [controller] = useState<FeedWidgetController>(() => new FeedWidgetController(currentAccount, props.homeController, appController))
    const navigation = useNavigation<any>()

    useEffect(() => {
        void controller.initialize()

        return () => {
            controller.uninitialize()
        }
    }, [])

    const onDebugTapped = () => {
        navigation.navigate('Debug')
    }

    const MobileHeader = () => {
        if (props.horizontal) return null
        if (controller.state == FeedWidgetState.LOADING) return null

        RenderLog.track('FeedWidget.MobileHeader')

        return (
            <View style={{flex: 1}}>
                <InboxWidget homeController={controller.homeController} style={{marginBottom: 16}} mode={'feed'}/>

                {currentAccount.personData.hasRobloxAccount &&
                    <RobloxGamesWidget
                        style={{
                            height: 168,
                            marginBottom: 16,
                        }}
                        type={'regular'}
                        homeController={controller.homeController}
                        onBookTapped={(book, focus) => {
                            controller.homeController.openBook(book, 'roblox-games-list', undefined, focus)
                        }}
                    />}
                <LibraryWidget onDebugTapped={onDebugTapped}
                               style={{
                                   height: 140,
                                   marginHorizontal: 10,
                                   marginBottom: 16
                                 }}
                               homeController={controller.homeController}
                               rows={1}
                               type={'regular'} />
            </View>
        )
    }

    const FeedItem = (info: ListRenderItemInfo<StoryModel>) => {
        const story = info.item
        const index = info.index

        if (story.source?.story_type == 'Book' &&
            props.homeController?.friendWidgetController?.friends &&
            story.properties['roblox_profile_id']) {

            //TODO: Remove this hack after Story objects explicitly link to the actor
            const robloxFriend = props.homeController.friendWidgetController.friends.filter((friend) => friend.roblox_profile && friend.roblox_profile.id == story.properties['roblox_profile_id'])[0]
            if (robloxFriend) return <Story type={'game_played'} story={story} controller={controller} robloxFriend={robloxFriend} onBookTapped={props.onTappedFriendPlayedGame} horizontal={props.horizontal} />
        }

        if (story.source?.story_type == 'Video') {
            return <Story type={'video'} story={story} position={index} onVideoTapped={props.onVideoTapped} horizontal={props.horizontal} />
        }

        if (story.source?.story_type == 'Concept' && story.properties.event == "referral_invitation") {
            return <Story type={'referral_invitation'} story={story} position={index} controller={controller} horizontal={props.horizontal} />
        }

        if (story.source?.story_type == 'Referral' && (story.properties.event == "referral_reminder" || story.properties.referral_target != undefined)) {
            return <Story type={'referral_reminder'} story={story} position={index} controller={controller} horizontal={props.horizontal} />
        }

        if (story.source?.story_type == 'Referral' && story.properties.event == "referral_completion") {
            return <Story type={'referral_completion'} story={story} position={index} controller={controller} horizontal={props.horizontal} />
        }

        if (story.source?.story_type == 'School') {
            return <Story type={'new_school_person'} story={story} position={index} controller={controller} horizontal={props.horizontal} />
        }

        if (story.source?.story_type == 'Profile') {
            return <Story type={'generic'} story={story} position={index} controller={controller} horizontal={props.horizontal} />
        }

        return <View/>
    }

    RenderLog.track('FeedWidget')

    return (
        <Widget type={'regular'} style={props.style} loading={controller.state == FeedWidgetState.LOADING} disabled={props.disabled && props.horizontal}>
            <View style={{flex: 1, flexDirection: props.horizontal ? 'row' : 'column'}}>
                <FlatList
                    style={{flex: 1}}
                    horizontal={props.horizontal}
                    showsHorizontalScrollIndicator={false}
                    showsVerticalScrollIndicator={false}
                    data={controller.stories}
                    ListHeaderComponent={() => MobileHeader()}
                    contentInset={{bottom: props.horizontal ? 0 : Dimensions.get('window').height * 0.3}}
                    renderItem={(info: ListRenderItemInfo<StoryModel>) => {
                        return <View
                            style={{opacity: !props.horizontal && props.disabled ? WIDGET_DISABLED_OPACITY : 1}}
                            key={`story-${info.item.id}`}>
                            {FeedItem(info)}
                            {!props.horizontal && props.disabled &&
                            <View style={{flex: 1, ...StyleSheet.absoluteFillObject}} />}
                        </View>
                    }}
                />
            </View>
        </Widget>
    )
})

