import {action, makeObservable, observable} from "mobx"

export const ROBLOX_SECURITY_COOKIE_KEY = '.ROBLOSECURITY'

export class RobloxCookieWatcher {

    @observable robloxCookie?: any
    @action setRobloxCookie(cookie?: any) { this.robloxCookie = cookie }

    constructor() {
        makeObservable(this)
    }

    public async initialize() {
    }

    public async uninitialize() {
    }

}