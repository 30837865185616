import React, {useEffect, useRef} from 'react'
import {View, StyleSheet, StatusBar, Animated, TouchableOpacity, Easing} from 'react-native'

type Props = React.PropsWithChildren<{
    active: boolean
    duration?: number
    onActivate?: (() => void)
    onActivated?: (() => void)
    onDeactivate?: (() => void)
    onDeactivated?: (() => void)
}>

const DimBackground = (props: Props) => {
    const overlayOpacity = useRef(new Animated.Value(0))

    const DEFAULT_MILLISEC_TO_FADE = 150
    const FADE_TO_THIS_PERCT_BLACK = 0.8

    useEffect(() => {
        if (props.active)
            activate()
        else
            deactivate()
    }, [props.active])

    const duration = () => { return (props.duration) ? props.duration : DEFAULT_MILLISEC_TO_FADE }

    const activate = () => {
        if (props.onActivate) props.onActivate()

        // fade in the background grey
        Animated.timing(overlayOpacity.current, {
            toValue: 1,
            duration: duration(),
            easing: Easing.out(Easing.quad),
            useNativeDriver: true,
        }).start((result) => {
            if (result.finished) {
                if (props.onActivated) props.onActivated()
            }
        })
    }

    const deactivate = () => {
        if (props.onDeactivate) props.onDeactivate()

        Animated.timing(overlayOpacity.current, {
            toValue: 0,
            duration: duration(),
            easing: Easing.in(Easing.quad),
            useNativeDriver: true,
        }).start((result) => {
            if (result.finished) {
                if (props.onDeactivated) props.onDeactivated()
            }
        })
    }

    return (
        <View style={{flex: 1, zIndex: 300,  ...StyleSheet.absoluteFillObject}}>
            <View style={{flex: 1}}>
                <StatusBar barStyle={'light-content'}/>

                <Animated.View style={{
                    flex: 1,
                    backgroundColor: `rgba(0, 0, 0, ${FADE_TO_THIS_PERCT_BLACK})`,
                    opacity: overlayOpacity.current,
                    ...StyleSheet.absoluteFillObject
                }} />

                <TouchableOpacity
                    style={{flex: 1, backgroundColor: 'clear', ...StyleSheet.absoluteFillObject}}
                    onPress={deactivate}
                    onLongPress={deactivate}/>

                {props.children}

            </View>

        </View>
    )
}

export default DimBackground