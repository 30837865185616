import {action, makeObservable, observable} from "mobx"
import {AppController} from "../../../../app/controllers/AppController"
import {PermissionUtils} from "../../../../app/utils/PermissionUtils"
import {ProfileModel} from "../../../social/models/ProfileModel"
import {ProfileStatus} from "../../../social/models/ProfileStatus"
import {ProfileStatusDetail} from "../../../social/models/ProfileStatusDetail"
import {CurrentAccount} from "../../../vizz_account/lib/CurrentAccount"
import {OnboardingController} from "../OnboardingController"
import { Platform } from "react-native"

export enum SampleState {
    LOADING,
    INTRO,
    ONLINE,
    CALLING,
    UNLOADING
}

export enum PermissionState {
    NONE,
    NOTIFICATION,
    CAMERA,
    AUDIO,
}

export class SampleController {
    private debug: boolean = false  // don't set this to true in production

    private currentAccount!: CurrentAccount
    private appController!: AppController
    public onboardingController!: OnboardingController
    private introHeadline = '< This is your friends list'

    @observable state: SampleState = SampleState.LOADING
    @observable showNotificationPermission: boolean = false;      @action public setShowNotificationPermission(val: boolean) { this.showNotificationPermission = val }
    @observable showCameraPermission: boolean = false;            @action public setShowCameraPermission(val: boolean) { this.showCameraPermission = val }
    @observable showAudioPermission: boolean = false;             @action public setShowAudioPermission(val: boolean) { this.showAudioPermission = val }
    @observable permissionState: PermissionState = PermissionState.NONE;            @action public setPermissionState(val: PermissionState) { this.permissionState = val }
    @observable cameraEnabled: boolean = false;                   @action public setCameraEnabled(val: boolean) { this.cameraEnabled = val }
    @observable headline?: string
    @observable sampleProfileStatus: ProfileStatus = ProfileStatus.OFFLINE
    @observable sampleProfile?: ProfileModel;                     @action private setSampleProfile(profile?: ProfileModel) { this.sampleProfile = profile }
    @observable animateNotification: boolean = false;             @action public setAnimateNotification(animate: boolean) { this.animateNotification = animate }

    constructor(currentAccount: CurrentAccount, appController: AppController, onboardingController: OnboardingController) {
        if (!currentAccount.personData.deviceKey) return

        this.consoleDebug(`new()`)

        this.currentAccount = currentAccount
        this.appController = appController
        this.onboardingController = onboardingController

        makeObservable(this)
    }

    public async initialize() {
        this.consoleDebug(`initialize()`)
        if (this.state != SampleState.LOADING) return

        this.onboardingController.speech.preloadPhrase('sample-intro', `Here is your FRIENDS list. Let's imagine one of your friends is named Riley.`)

        this.onboardingController.speech.preloadPhrase('sample-notification', `To see when your friends are online, tap the Allow button on the right.`)

        if(Platform.OS === 'ios') this.onboardingController.speech.preloadPhrase('sample-notification-tip', `Tap Allow again on the right.`)
        else if (Platform.OS === 'android') this.onboardingController.speech.preloadPhrase('sample-notification-tip', 'Tap the Allow button when your device asks you for permission.')

        this.onboardingController.speech.preloadPhrase('sample-online', `Your pretend, example friend, "Riley" -- is not available right now -- but watch what happens, when Riley gets home and turns on their device!`)
        this.onboardingController.speech.preloadPhrase('sample-waiting', `See Riley now? They're "online"! You can see they've opened Roblox and are playing Brookhaven. Tap the blue "video call" button now.`)

        this.onboardingController.speech.preloadPhrase('sample-camera', `Before we can start this call, tap the OK button on the right to enable video.`)

        if(Platform.OS === 'ios') this.onboardingController.speech.preloadPhrase('sample-camera-tip', `Tap OK again on the right.`)
        else if(Platform.OS === 'android') this.onboardingController.speech.preloadPhrase('sample-camera-tip', 'Now tap the first option')

        this.onboardingController.speech.preloadPhrase('sample-audio', `Last step to start this call, tap the OK button for sound.`)

        if(Platform.OS === 'ios') this.onboardingController.speech.preloadPhrase('sample-audio-tip', `Tap OK again on the right.`)
        else if(Platform.OS ==='android') this.onboardingController.speech.preloadPhrase('sample-audio-tip', 'Now tap the first option')

        this.onboardingController.speech.preloadPhrase('sample-call', `Obviously, Riley is not a real person. This is not a real call. But this is showing what it will be like, once you get your friends on Lava.`)

        this.setState(SampleState.INTRO)
    }

    public uninitialize() {
        this.consoleDebug(`uninitialize()`)
        this.setState(SampleState.UNLOADING)

        this.onboardingController.speech.pause()

        this.onboardingController.speech.unloadPreloadedPhrase('sample-intro')
        this.onboardingController.speech.unloadPreloadedPhrase('sample-notification')
        this.onboardingController.speech.unloadPreloadedPhrase('sample-notification-tip')

        this.onboardingController.speech.unloadPreloadedPhrase('sample-online')
        this.onboardingController.speech.unloadPreloadedPhrase('sample-waiting')

        this.onboardingController.speech.unloadPreloadedPhrase('sample-camera')
        this.onboardingController.speech.unloadPreloadedPhrase('sample-camera-tip')
        this.onboardingController.speech.unloadPreloadedPhrase('sample-audio')
        this.onboardingController.speech.unloadPreloadedPhrase('sample-audio-tip')
        this.onboardingController.speech.unloadPreloadedPhrase('sample-call')
    }


    // Public methods

    public async startStep() {
        this.consoleDebug(`startStep()`)

        this.setShowNotificationPermission(false)
        this.setShowCameraPermission(false)
        this.setPermissionState(PermissionState.NONE)
        this.setHeadline(this.introHeadline)
        this.setAnimateNotification(false)

        this.setSampleProfile({
            id: 123,
            person_id: 123,
            person_key: 'abc-123-uuid',
            username: 'Riley',
            nickname: 'Gamer',
            first_name: 'Riley',
            last_changed: 'Offline, 5 hours ago',
            status: ProfileStatus.OFFLINE,
            avatar_image_url: 'https://res.cloudinary.com/explanation/image/upload/v1692045922/bacon_aqoby4.png',
            created_at: '123',
            updated_at: '123',
            pretty_username: 'Riley'
        })

        this.currentAccount.analytics.logEvent('sample-onboarding','started')

        if (this.state == SampleState.UNLOADING) return

        await this.onboardingController.speech.speakPreloadedPhrase('sample-intro', async() => {
            this.setHeadline('Get notified when friends come online.')
            this.setShowNotificationPermission(true)
            this.setPermissionState(PermissionState.NOTIFICATION)

            this.onboardingController.speech.unloadPreloadedPhrase('sample-intro')
            await this.onboardingController.speech.speakPreloadedPhrase('sample-notification', () => {
                this.onboardingController.speech.unloadPreloadedPhrase('sample-notification')
            })
        })
    }

    public async requestNotificationPermission() {
        this.setShowNotificationPermission(false)
        await this.onboardingController.speech.speakPreloadedPhrase('sample-notification-tip')
        await this.appController.notification.enableNotifications()
        await this.startOnline()
    }

    public async skipNotificationPermission() {
        this.currentAccount.analytics.logEvent('notification-permission','skipped')
        await this.startOnline()
    }

    public async startOnline() {
        this.setHeadline('')
        this.setShowNotificationPermission(false)
        this.setState(SampleState.ONLINE)
        await this.onboardingController.speech.speakPreloadedPhrase('sample-online', async() => {

            this.setAnimateNotification(true)

            this.setHeadline('Tap the blue video call button.')
            this.setSampleFriendStatus(ProfileStatus.ONLINE)
            this.setSampleProfile({
                ...this.sampleProfile,
                last_changed: `Online, Playing`,
                last_changed_detail: `Brookhaven`,
                status: ProfileStatus.ONLINE,
                status_detail: ProfileStatusDetail.ROBLOX,
            } as ProfileModel)
            await this.onboardingController.speech.speakPreloadedPhrase('sample-waiting')
        })
    }

    public async startCameraPermission() {
        if (this.permissionState != PermissionState.NOTIFICATION) return
        this.setPermissionState(PermissionState.CAMERA)
        this.setHeadline('Turn on video for calls.')
        this.setShowCameraPermission(true)
        await this.onboardingController.speech.speakPreloadedPhrase('sample-camera')
    }

    public async requestCameraPermission() {
        this.setShowCameraPermission(false)
        await this.onboardingController.speech.speakPreloadedPhrase('sample-camera-tip')
        let cameraEnabled = false
        try {
            cameraEnabled = await PermissionUtils.requestCamera(this.currentAccount)
        } catch {}
        this.setCameraEnabled(cameraEnabled)
        await this.startAudioPermission()
    }

    public async skipCameraPermission() {
        this.setShowCameraPermission(false)
        this.setCameraEnabled(false)
        this.currentAccount.analytics.logEvent('camera-permission','skipped')
        await this.startSampleCall()
    }

    public async startAudioPermission() {
        this.setPermissionState(PermissionState.AUDIO)
        this.setHeadline('Also, turn on audio for calls.')
        this.setShowAudioPermission(true)
        await this.onboardingController.speech.speakPreloadedPhrase('sample-audio')
    }

    public async requestAudioPermission() {
        await this.onboardingController.speech.speakPreloadedPhrase('sample-audio-tip')
        await PermissionUtils.requestAudio(this.currentAccount)
        await this.startSampleCall()
    }

    public async skipAudioPermission() {
        this.currentAccount.analytics.logEvent('audio-permission','skipped')
        await this.startSampleCall()
    }

    public async startSampleCall() {
        this.onboardingController.speech.pause()
        this.setState(SampleState.CALLING)
        this.currentAccount.analytics.logEvent('sample-onboarding','calling')

        await this.onboardingController.speech.speakPreloadedPhrase('sample-call', async() => {
            await this.nextStep()
        })
    }

    public async nextStep() {
        this.consoleDebug(`nextStep()`)

        this.onboardingController.speech.pause()
        this.currentAccount.analytics.logEvent('sample-onboarding','finished')
        this.onboardingController.nextStep()
    }


    // Private helper methods

    @action
    private setState(state: SampleState) {
        this.consoleDebug(`setState()`)
        this.state = state
    }

    @action
    private setHeadline(headline: string) {
        this.consoleDebug(`setHeadline()`)
        this.headline = headline
    }

    @action
    private setSampleFriendStatus(sampleFriendStatus: ProfileStatus) {
        this.consoleDebug(`setSampleFriendStatus()`)
        this.sampleProfileStatus = sampleFriendStatus
    }


    // Private instance utility methods

    private consoleDebug(method: string, force: boolean = false) {
        if (this.debug || force) console.log(`${this.constructor.name}: ${method}  state = ${this.state}`)
    }
}
