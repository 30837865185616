import {action, makeObservable, observable} from "mobx"
import {CurrentAccount} from "../../../vizz_account/lib/CurrentAccount"
import {OnboardingController, OnboardingState} from "../OnboardingController"
import SentryService from "../../../../app/services/SentryService"

export enum SMSState {
    LOADING,
    PHONE,
    CODE,
    WHO,
    BLANK
}

export class SMSController {
    private debug: boolean = false  // don't set this to true in production

    private currentAccount: CurrentAccount
    public onboardingController: OnboardingController

    @observable state: SMSState = SMSState.LOADING;     @action public setState(state: SMSState) { this.state = state }
    @observable phoneNumber: string = '';               @action public setPhoneNumber(phone: string) { this.phoneNumber = phone }
    @observable errorMessage?: string;                  @action public setErrorMessage(msg?: string) { this.errorMessage = msg }
    @observable verificationCode: string = '';          @action public setVerificationCode(code: string) { this.verificationCode = code }
    @observable verifyCodeButtonText?: string;          @action public setVerifyCodeButtonText(code?: string) { this.verifyCodeButtonText = code }

    constructor(currentAccount: CurrentAccount, onboardingController: OnboardingController) {
        this.consoleDebug(`new()`)

        this.currentAccount = currentAccount
        this.onboardingController = onboardingController
        this.onboardingController.speech.preloadPhrase('sms-intro', `Since a child will be using this app, we need to make sure parents are okay with this. This is a quick step. -- Enter the cell phone number of one of the parents.`)
        this.onboardingController.speech.preloadPhrase('sms-code', `We just texted you a code. Please check your phone and enter the code below.`)
        this.onboardingController.speech.preloadPhrase('sms-who', `Perfect! Whose phone number is this? Your mom, dad, or other.`)

        makeObservable(this)
    }

    public async initialize() {
        this.consoleDebug(`initialize()`)

        this.setState(SMSState.PHONE)
    }

    public uninitialize() {
        this.consoleDebug(`uninitialize()`)
    }


    // Public methods

    public async startStep() {
        this.consoleDebug(`startStep()`)

        this.setErrorMessage(undefined)
        this.setVerifyCodeButtonText(undefined)
        this.setErrorMessage('')

        // DISABLED FOR NOW
        this.skipStep()

        // this.currentAccount.analytics.logEvent('sms-onboarding','started')
        // await this.onboardingController.speech.speakPreloadedPhrase('sms-intro')
    }

    public async onSubmitPhoneNumber() {
        try {
            this.setState(SMSState.CODE)
            this.setErrorMessage('')
            this.setVerificationCode('')
            void this.onboardingController.speech.speakPreloadedPhrase('sms-code')
            this.currentAccount.analytics.logEvent('sms-onboarding','phone', this.phoneNumber, {phone_number: this.phoneNumber})
            const result = await this.currentAccount.api.patch('vizz_account.update_phone_number', {phone_number: this.phoneNumber}) as any
            if (!result['success']) this.setErrorMessage('Message could not be sent, is this number correct?')
        } catch (error) {
            SentryService.captureError(error)
        }
    }

    public async onChangePhoneNumber() {
        this.onboardingController.speech.pause()
        this.currentAccount.analytics.logEvent('sms-onboarding','phone-change')
        this.setPhoneNumber('')
        this.setVerificationCode('')
        this.setState(SMSState.PHONE)
    }

    public async onSubmitVerificationCode() {
        try {
            this.onboardingController.speech.pause()
            this.setVerifyCodeButtonText('Checking...') // this also causes the button to be disabled so we need
            this.currentAccount.analytics.logEvent('sms-onboarding','code', this.verificationCode, {code: this.verificationCode})
            var result = await this.currentAccount.api.patch('vizz_account.verify_phone_number', {"verification_code_entered": this.verificationCode})
            if (result.success){
                this.setErrorMessage("✓")
                void this.onboardingController.speech.speakPreloadedPhrase('sms-who')
                this.setState(SMSState.WHO)
            } else {
                this.currentAccount.analytics.logEvent('sms-onboarding','code-incorrect')
                this.setErrorMessage("This code is not correct.")
                this.setVerifyCodeButtonText('')
            }
        } catch (error) {
            SentryService.captureError(error)
        }
    }

    public async onAnswerWho(who: string) {
        try {
            this.onboardingController.speech.pause()
            this.setState(SMSState.BLANK)
            this.currentAccount.analytics.logEvent('sms-onboarding','who', who, {who: who})
            await this.currentAccount.api.patch("vizz_account.update_person_properties", {properties: {phone_number_who: who}})
            this.nextStep()
        } catch (error) {
            SentryService.captureError(error)
        }
    }

    public prevStep() {
        this.consoleDebug(`prevStep()`)

        this.currentAccount.personData.ageOnboardingNeeded = true
        this.onboardingController.setState(OnboardingState.AGE)
    }

    public skipStep() {
        this.consoleDebug(`skipStep()`)

        this.currentAccount.analytics.logEvent('sms-onboarding','skipped')
        this.nextStep()
    }

    public nextStep() {
        this.consoleDebug(`nextStep()`)

        this.currentAccount.analytics.logEvent('sms-onboarding','finished')
        this.currentAccount.personData.smsOnboardingNeeded = false
        this.onboardingController.nextStep()
    }


    // Private helper methods



    // Private instance utility methods

    private consoleDebug(method: string, force: boolean = false) {
        if (this.debug || force) console.log(`${this.constructor.name}: ${method}  state = ${this.state}`)
    }
}