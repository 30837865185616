import {action, computed, makeObservable, observable} from "mobx"
import {CurrentAccount} from "../../../vizz_account/lib/CurrentAccount"
import {OutgoingFriendRequestModel} from "../../models/OutgoingFriendRequestModel"
import {SpeechUtils} from "../../../../app/utils/AVUtils"

export type DraftFriendsState = 'approval' | 'friends-with-lava' | 'friends-need-lava'

export class DraftFriendsController {

    private currentAccount: CurrentAccount
    private onFinish: () => void

    @observable state: DraftFriendsState = 'approval'

    @action setState(state: DraftFriendsState) {
        this.state = state
    }

    @observable requestsRequiringAction: OutgoingFriendRequestModel[]

    @action setRequestsRequiringAction(requests: OutgoingFriendRequestModel[]) {
        this.requestsRequiringAction = requests
    }

    @observable approvedRequests: OutgoingFriendRequestModel[]

    @action setApprovedRequests(requests: OutgoingFriendRequestModel[]) {
        this.approvedRequests = requests
    }

    @computed
    get approvedWithLava() {
        return this.approvedRequests.filter((r) => r.destination_person.activated_at)
    }

    @computed
    get approvedWithoutLava() {
        return this.approvedRequests.filter((r) => !r.destination_person.activated_at)
    }

    allRequests: OutgoingFriendRequestModel[]
    speech: SpeechUtils

    constructor(currentAccount: CurrentAccount, requests: OutgoingFriendRequestModel[], onFinish: () => void) {
        this.currentAccount = currentAccount
        this.allRequests = requests
        this.requestsRequiringAction = requests
        this.approvedRequests = []
        this.speech = new SpeechUtils(currentAccount, true)
        this.onFinish = onFinish
        makeObservable(this)
    }

    public async initialize() {
        await this.speech.speak('You made a new friend on Roblox, Would you like to add them to Lava so you can call and chat.')
    }

    public uninitialize() {
        this.speech.unload()
    }

    public async actOnRequest(request: OutgoingFriendRequestModel, approve: boolean) {
        const newArr = this.requestsRequiringAction.filter((r) => r.id !== request.id)
        this.setRequestsRequiringAction(newArr)

        if (approve) {
            let approved = this.approvedRequests
            approved.push(request)
            this.setApprovedRequests(approved)

            void this.currentAccount.api.post('friend_requests_path', {
                keys: [request.destination_person.key]
            })
        } else {
            void this.currentAccount.api.delete(`friend_requests_path(${request.id})`)
        }

        if (this.requestsRequiringAction.length == 0) {
            if (this.approvedWithLava.length > 0) {
                void this.showFriendsWithLava()
            } else if (this.approvedWithoutLava.length > 0) {
                void this.showFriendsWithoutLava()
            } else {
                this.finish()
            }
        }
    }

    public async showFriendsWithLava() {
        this.setState('friends-with-lava')
        const text: string = this.approvedWithLava.length > 1 ?
            'These Real friends already have the Lava app.' :
            'This Real friend already has the Lava app.'
        await this.speech.speak(text)
    }

    public async showFriendsWithoutLava() {
        this.setState('friends-need-lava')
        const text = this.approvedWithoutLava.length > 1 ?
            'These friends need Lava so you can call each other.' :
            'This friend needs Lava so you can call each other.'
        await this.speech.speak(text)
    }

    public onFriendsWithLavaAction() {
        if (this.approvedWithoutLava.length > 0) {
            void this.showFriendsWithoutLava()
        } else {
            this.finish()
        }
    }

    public onFriendsWithoutLavaAction() {
        this.finish()
    }

    public finish() {
        this.onFinish()
    }
}
