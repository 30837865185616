import React, {ForwardedRef, useCallback, useContext, useEffect, useImperativeHandle, useRef, useState} from 'react'
import {LayoutRectangle, StyleSheet, View, Text, ViewStyle} from "react-native"
import {ActivityIndicator, Button} from "react-native-paper"
import {observer} from "mobx-react"
import {Color, isTablet} from "../../../app/lib/Appearance"
import CurrentAccountContext from "../../vizz_account/lib/CurrentAccount"
import VideoModel from '../models/VideoModel'
import {Cover} from './video/Cover'
import FixedAspectView from '../../../app/views/components/FixedAspectView'
import Vizz from '../../vizz_maker/views/Vizz'
import {AsyncUtils} from '../../../app/utils/AsyncUtils'
import {ActivityName, TimedActivity} from "../../../app/lib/services/TimedActivity"
import {ProfileModel} from "../../social/models/ProfileModel"
import {FRIEND_PICKER_VERTICAL_WIDTH, FriendPickerVertical} from "./FriendPickerVertical"
import YoutubeIframe from '../../../app/views/components/YoutubeIframe'
import AppControllerContext from '../../../app/controllers/AppController'
import {VIDEO_PLAYER_TOUCH_ACTION_EVENT} from '../../../app/views/components/YoutubeIframe/constants'
import {Video as ExpoVideo} from 'expo-av'
import {AVPlaybackSource} from "expo-av/build/AV"
import {ResizeMode} from "expo-av/src/Video.types"
import DateUtils from '../../../app/utils/DateUtils'

type Props = {
    style?: ViewStyle
    video: VideoModel
    friendsForSharing?: ProfileModel[]
    onTappedFriend?: (friend: ProfileModel) => void
    autoPlayable?: boolean
    preRollSource?: AVPlaybackSource
    onVerify?: () => void
    onRemove?: () => void
}

class VideoStats {
    static activeVideoComponents: number = 0
}

const DELAY_TIME_TO_CHANGE_PLAY_STATE = 1000

export const Video = observer(React.forwardRef((props: Props, ref: ForwardedRef<any>) => {
    const currentAccount = useContext(CurrentAccountContext)
    const appController = useContext(AppControllerContext)
    const [layout, setLayout] = useState<LayoutRectangle>({x: 0, y: 0, width: 0, height: 0})
    const [disabled, setDisabled] = useState<boolean>(false)
    const [currentTouchAction, setCurrentTouchAction] = useState<Number>(0)
    const [isPauseOverlayVisible, setIsPauseOverlayVisible] = useState<boolean>(false)
    const [isPlaying, setIsPlaying] = useState(false)
    const [isPlayerReady, setIsPlayerReady] = useState(false)
    const [isPlayed, setIsPlayed] = useState(false)
    const [isPlayerInit, setIsPlayerInit] = useState(false)
    const isRequirePlayRef = useRef<boolean>(false)
    const [isPlayingPreRoll, setPlayingPreRoll] = useState<boolean>(false)

    const playerRef = useRef<any>()
    const preRollPlayerRef = useRef<ExpoVideo>(null)
    const timeOutPauseOverlayVisibleRef = useRef<any>(null)
    const timeOutDelayVideoTapRef = useRef(0)
    const videoUsageLoggingEnabled = false

    const onStateChange = useCallback((state: string, isTouched: boolean) => {
        if (isPlayed) {
            if (state === "paused") {
                if (Date.now() - timeOutDelayVideoTapRef.current > DELAY_TIME_TO_CHANGE_PLAY_STATE) {
                    onPause(isTouched)
                    timeOutDelayVideoTapRef.current = Date.now()
                }
            }
            else if (state === "ended") {
                setIsPlaying(false)
                setIsPauseOverlayVisible(true)
            }
            else if (state === "playing") {
                setIsPlaying(true)
                TimedActivity.end(ActivityName.PLAY_VIDEO, currentAccount)
            }
        }
    }, [isPlayed])

    const onPlayerReady = useCallback((evt: any) => {
        setIsPlayerReady(true)
        if (isRequirePlayRef.current) {
            setIsPlaying(true)
            setIsPauseOverlayVisible(false)
            isRequirePlayRef.current = false
        }
    }, [])

    const onPlayerPlayed = useCallback(() => {
        setIsPlayed(true)
    }, [])

    const onPlayVideo = () => {
        if (props.preRollSource != undefined && !isPlayingPreRoll) {
            setPlayingPreRoll(true)
        } else if (isPlayerReady) {
            setIsPlaying(true)
            setIsPauseOverlayVisible(false)
        }
        else {
            isRequirePlayRef.current = true
        }
    }

    const onUpdateVisibilityPauseOverlay = (isVisible: boolean) => {
        clearTimeout(timeOutPauseOverlayVisibleRef.current)
        if (timeOutPauseOverlayVisibleRef.current != null) {
            timeOutPauseOverlayVisibleRef.current = null
        }
        else {
            timeOutPauseOverlayVisibleRef.current = setTimeout(() => {
                setIsPauseOverlayVisible(isVisible)
                timeOutPauseOverlayVisibleRef.current = null
            }, 0)
        }
    }

    const onCurrentTouchAction = (actionId: Number) => {
        setCurrentTouchAction(actionId)
    }

    useEffect(() => {
        setIsPlayerInit(true)

        if (videoUsageLoggingEnabled) {
            VideoStats.activeVideoComponents++
            console.log(`Active video components: ${VideoStats.activeVideoComponents}`)
        }

        return () => {
            if (videoUsageLoggingEnabled) {
                VideoStats.activeVideoComponents--
                console.log(`Active video components: ${VideoStats.activeVideoComponents}`)
            }

            if (appController.video.playingVideo == props.video) appController.video.stopPlayingVideo()

            void preRollPlayerRef.current?.unloadAsync()
        }
    }, [])


    useEffect(() => {
        if (isPlaying) appController.video.startPlayingVideo(props.video)
        if (!isPlaying) {
            if (appController.video.playingVideo == props.video) appController.video.stopPlayingVideo()
        }

    }, [isPlaying])

    const renderLoading = () => {
        return (
            <View style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}>
                <ActivityIndicator color={Color.primary} />
            </View>
        )
    }

    useImperativeHandle(ref, () => ({
        autoPlay: () => {
            onPlayVideo()
        },
        autoPause: () => {
            onPause(true)
        }
    }))

    const onPause = (isTouched: boolean) => {
        void preRollPlayerRef.current?.unloadAsync()
        setPlayingPreRoll(false)
        setIsPlaying(false)
        setIsPlayed(true)
        if (isTouched) setCurrentTouchAction(0)
        setIsPauseOverlayVisible(true)
    }

    const changeVizzState = (state: string) => {
        if (state == 'playing') {
            setIsPlaying(true)
            setIsPauseOverlayVisible(false)
        }
        if (state == 'stopped') {
            setIsPlaying(false)
            setIsPauseOverlayVisible(true)
        }
    }

    const play = async () => {
        setIsPauseOverlayVisible(false)
        setIsPlaying(true) // at the end of a youtube video, this single call to play does not always work
        await AsyncUtils.sleep(500)
        setIsPlaying(true)
        await AsyncUtils.sleep(500)
        setIsPlaying(true)
    }



    return (
        <View style={[styles.container, props.style, disabled ? {opacity: 0.1} : {}]} onLayout={(e) => setLayout(e.nativeEvent.layout)}>
            {!isPlayerInit && !isPlayingPreRoll && renderLoading()}
            {isPlayerInit && !isPlayingPreRoll &&
                <View style={{backgroundColor: 'black', position: 'relative', width: Math.round(layout.width), height: Math.round(layout.width * (9 / 16))}}>

                    {
                        (props.autoPlayable || isPlayed) &&
                        !isPlaying &&
                        isPauseOverlayVisible &&
                        !(currentTouchAction === VIDEO_PLAYER_TOUCH_ACTION_EVENT.TOUCH_START || currentTouchAction === VIDEO_PLAYER_TOUCH_ACTION_EVENT.TOUCH_MOVING) &&
                        <Cover imageUrl={props.video.thumbnail_url} onPlay={play}>
                            <View style={styles.moderationContainer}>
                                <View style={{flexDirection: 'row'}}>
                                    <Button color={'white'} mode={'contained'} style={{marginHorizontal: 5}} onPress={() => props.onVerify && props.onVerify()}>I Verified It</Button>
                                </View>
                                <View>
                                    {props.video.verified_by_person?.profile &&
                                    <Text style={{color: 'white', marginVertical: 10}}>
                                        Verified by {props.video.verified_by_person.profile.pretty_username}{' — '}
                                        {DateUtils.timeAgoInWords(props.video.verified_at)}
                                    </Text>}

                                    {!props.video.verified_by_person?.profile && <Text style={{marginVertical: 10}}>{' '}</Text>}
                                </View>
                            </View>
                        </Cover>
                    }

                    {props.video.youtube_key &&
                        <YoutubeIframe
                            ref={playerRef}
                            width={Math.round(layout.width)}
                            height={Math.round(layout.width * (9 / 16))}
                            videoId={props.video.youtube_key}
                            play={isPlaying}
                            setIsPlaying={setIsPlaying}
                            onChangeState={onStateChange}
                            onReady={onPlayerReady}
                            initialPlayerParams={{preventFullScreen: true, modestbranding: true, rel: false, controls: true}}
                            onCurrentTouchAction={onCurrentTouchAction}
                            onUpdateVisibilityPauseOverlay={onUpdateVisibilityPauseOverlay}
                            onPlayerPlayed={onPlayerPlayed}
                            forceAndroidAutoplay={true}
                            webViewStyle={{display: isPlayerReady ? 'flex' : 'none'}}
                        />}

                    {!props.video.youtube_key && props.video.vizz &&
                        <FixedAspectView aspectRatio={9 / 16}>
                            <Vizz
                                vizz={props.video.vizz}
                                preload={true}
                                play={isPlaying}
                                onChangeState={changeVizzState}
                            />
                        </FixedAspectView>
                    }

                </View>
            }

            {
                props.preRollSource && isPlayingPreRoll &&
                <View style={{
                    backgroundColor: 'black',
                    position: 'relative',
                    width: Math.round(layout.width),
                    height: Math.round(layout.width * (9 / 16))
                }}>

                    <ExpoVideo
                        ref={preRollPlayerRef}
                        style={{flex: 1, backgroundColor: 'black'}}
                        source={props.preRollSource}
                        resizeMode={ResizeMode.CONTAIN}
                        shouldPlay={true}
                        onPlaybackStatusUpdate={(status) => {
                            if ((status as any).didJustFinish) {
                                setPlayingPreRoll(false)
                                onPlayVideo()
                            }
                        }}
                    />
                </View>
            }

            {props.friendsForSharing &&
                props.friendsForSharing.length > 0 &&
                isPlayerInit && !isPlayingPreRoll &&
                <View
                    style={{
                        flex: 1,
                        justifyContent: 'center',
                        position: 'absolute',
                        right: isTablet() ? -(FRIEND_PICKER_VERTICAL_WIDTH / 2) : 4,
                        top: 0,
                        bottom: 0
                    }}>
                    <FriendPickerVertical
                        friends={props.friendsForSharing}
                        onTappedFriend={props.onTappedFriend}
                    />
                </View>
            }
        </View>
    )
}))

const styles = StyleSheet.create({
    container: {
    },

    moderationContainer: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        justifyContent: 'flex-end',
        alignItems: 'center',
        backgroundColor: 'rgba(0,0,0,0.5)'
    }
})
