import {action, makeObservable, observable} from "mobx"
import {observer} from "mobx-react"
import React, {useLayoutEffect} from "react"
import useTheme from "../../modules/lava-components/components/hooks/useTheme"
import {View} from "react-native"
import {isTablet} from "../lib/Appearance"
import Title from "../../modules/lava-components/components/atoms/Title/Title"
import {useSafeAreaInsets} from "react-native-safe-area-context"
import {AnimationUtils} from "../utils/AnimationUtils"

export type LavaToastModel = {
    title: string
    message: string
    color?: string
}

class LavaToastController {
    @observable toast?: LavaToastModel
    @action setToast(toast?: LavaToastModel) { this.toast = toast }
    private dismissTimeoutHandle?: any

    constructor() {
        makeObservable(this)
    }

    show(toast: LavaToastModel) {
        this.setToast(toast)
        this.dismissAfterTimeout()
    }

    dismiss() {
        if (this.dismissTimeoutHandle) clearTimeout(this.dismissTimeoutHandle)
        this.setToast(undefined)
    }

    private dismissAfterTimeout() {
        if (this.dismissTimeoutHandle) clearTimeout(this.dismissTimeoutHandle)
        this.dismissTimeoutHandle = setTimeout(() => {
            this.dismiss()
        }, 5000)
    }
}

export const LavaToastOverlay = observer(() => {

    const theme = useTheme()
    const safeArea = useSafeAreaInsets()

    useLayoutEffect(() => {
        AnimationUtils.configureNext()
    }, [LavaToast.controller.toast])

    if (!LavaToast.controller.toast) return null

    return (
        <View style={{
            position: 'absolute',
            zIndex: 2000,
            top: safeArea.top,
            left: 0,
            right: 0,
            alignItems: 'center'
        }} pointerEvents={'box-none'}>
            <View style={{
                backgroundColor: LavaToast.controller.toast.color ?? 'red',
                padding: 16,
                borderRadius: 6,
                width: isTablet() ? 500 : 320,
                justifyContent: 'center'
            }}>
                <Title style={{color: 'white'}} variation={"title2"}>{LavaToast.controller.toast.title}</Title>
                <Title style={{color: 'white', marginTop: 4}} variation={"subtitle2"}>{LavaToast.controller.toast.message}</Title>
            </View>
        </View>
    )
})

export class LavaToast {
    static controller = new LavaToastController()

    public static show(title: string, message: string, color?: string) {
        this.controller.show({
            title: title,
            message: message,
            color: color
        })
    }

    public static dismiss() {
        this.controller.dismiss()
    }
}