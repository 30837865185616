import {FullscreenModal} from "../../../../app/views/components/FullscreenModal"
import {Animated, Dimensions, ScrollView, StyleSheet, View} from "react-native"
import React, {useContext, useEffect, useRef, useState} from "react"
import {RobloxGameDetailSidePanel} from "./RobloxGameDetailSidePanel"
import {observer} from "mobx-react"
import {RobloxGameDetailController,} from "../../controllers/roblox_games_widget/RobloxGameDetailController"
import {GameBookModel} from "../../models/GameBookModel"
import CurrentAccountContext from "../../../vizz_account/lib/CurrentAccount"
import HomeController from "../../../start/controllers/HomeController"
import {isLandscape, isPortrait, isTablet} from "../../../../app/lib/Appearance"
import useTheme from "../../../lava-components/components/hooks/useTheme"
import {ActivityIndicator} from "react-native-paper"
import {RobloxGameDetailState} from "./RobloxGameDetailState"
import {RobloxGameDetailContent} from "./RobloxGameDetailContent"
import {COLLAPSED_WIDTH, EXPANDED_WIDTH, RobloxGameDetailSidePanelState} from "./RobloxGameDetailConstants"
import GameCard from "../../../lava-components/components/molecules/GameCard/GameCard"
import {VideoLightbox} from "../video/VideoLightbox"
import {reaction} from "mobx"
import {useSafeAreaInsets} from "react-native-safe-area-context"

const {width} = Dimensions.get('screen')

export type RobloxGameDetailScrollingContentProvider = {
    onScroll: (xCoordinate: number) => void
    onScrollEnded: () => void
    forcedScrollOffset: number
}

type Props = {
    book: GameBookModel
    onDismiss: () => void
    homeController: HomeController
    openTrigger?: string
}

export const RobloxGameDetail = observer((props: Props) => {

    const currentAccount = useContext(CurrentAccountContext)
    const [controller] = useState(() => new RobloxGameDetailController(currentAccount, props.homeController, props.book, props.openTrigger))
    const theme = useTheme()
    const sidePanelWidth = useRef(new Animated.Value(EXPANDED_WIDTH))
    const [sidePanelState, setSidePanelState] = useState(RobloxGameDetailSidePanelState.EXPANDED)
    const [animatedPanelWidth] = useState(new Animated.Value(EXPANDED_WIDTH))
    const [playPreviewVideo,setPlayPreviewVideo] = useState(false)
    const safeAreaInsets = useSafeAreaInsets()
    const mobileScrollViewRef = useRef<any>()

    useEffect(() => {
        void controller.initialize()
        controller.stateReaction = reaction(() => controller.state, (newState, oldState) => {
            if (newState == RobloxGameDetailState.LOADED) {
                if (props.homeController.focus === 'secrets' || props.homeController.focus === 'codes') {
                    collapseSidePanel()
                }
                if (props.homeController.focus === 'trailer') {
                    setPlayPreviewVideo(true)
                }
            }
        })

        return () => {
            controller.uninitialize()
        }
    }, [])

    useEffect(() => {
        const animationListener = animatedPanelWidth.addListener(({value}) => {
            sidePanelWidth.current.setValue(value)
        })

        return () => {
            animatedPanelWidth.removeListener(animationListener)
        }
    }, [animatedPanelWidth])

    const collapseSidePanel = () => {
        if (isPortrait()) return

        if (sidePanelState === RobloxGameDetailSidePanelState.EXPANDED) {
            setSidePanelState(RobloxGameDetailSidePanelState.COLLAPSED)
            setPlayPreviewVideo(false)
            Animated.timing(animatedPanelWidth, {
                toValue: COLLAPSED_WIDTH,
                duration: 200,
                useNativeDriver: true
            }).start()
        }
    }

    const onDismiss = () => {
        controller.logEvent('game-details', 'closed', props.book.title, {book: {title: props.book.title, id: props.book.id}})

        if(playPreviewVideo){
            setPlayPreviewVideo(false)
        }
        if(controller.trailerVideo){
            controller.setTrailerVideo(undefined)
        }
        props.onDismiss()
    }

    const Header = () => {
        return (
            <View style={{flexDirection: 'row', height: isTablet() ? 78 : 78, alignItems: 'center'}}>
                <GameCard
                    containerStyle={{
                        marginStart: 28,
                    }}
                    imageUrl={controller.parentBook.image_url ?? ''}
                    name={controller.parentBook.title} onPress={() => {
                }} variation={'icon'} />
            </View>
        )
    }

    return (
        <FullscreenModal visible={props.homeController.gameDetailVisible} onDidDisappear={onDismiss} innerModal={!!controller.trailerVideo}>
            <View style={{
                flex:1,
                paddingTop: safeAreaInsets.top,
                paddingBottom: isPortrait() ? 0 : safeAreaInsets.bottom,
                paddingLeft: safeAreaInsets.left,
                paddingRight: safeAreaInsets.right
            }} >
                {Header()}

                {controller.state == RobloxGameDetailState.LOADED && isLandscape() &&
                    <View style={{flex: 1, flexDirection: 'row', marginTop: theme.spacing.lg}}>
                        <Animated.View style={{width: sidePanelWidth.current}}>
                            <RobloxGameDetailSidePanel
                                controller={controller}
                                sidePanelWidth={sidePanelWidth.current}
                                animatedPanelWidth={animatedPanelWidth}
                                sidePanelState={sidePanelState}
                                setSidePanelState={setSidePanelState}
                                playPreviewVideo={playPreviewVideo}
                                setPlayPreviewVideo={setPlayPreviewVideo}
                            />
                        </Animated.View>

                    <View style={{flex: 1, marginLeft: theme.spacing.xl * 2}}>
                        <RobloxGameDetailContent
                            robloxGameDetailController={controller}
                            collapseSidePanel={collapseSidePanel}
                            sidePanelState={sidePanelState}
                            secretVideoPlayable={props.homeController.focus === "secrets"}
                            codesVideoPlayable={props.homeController.focus === "codes"}
                        />
                        <View />
                    </View>

                    </View>
                }

            {controller.state == RobloxGameDetailState.LOADED && !isLandscape() &&
                <ScrollView style={{flex: 1}} ref={mobileScrollViewRef}>
                    <RobloxGameDetailSidePanel
                        controller={controller}
                        animatedPanelWidth={animatedPanelWidth}
                        sidePanelWidth={new Animated.Value(width)}
                        sidePanelState={RobloxGameDetailSidePanelState.COLLAPSED}
                        setSidePanelState={setSidePanelState}
                        playPreviewVideo={playPreviewVideo}
                        setPlayPreviewVideo={setPlayPreviewVideo}
                        />
                    <View style={{flex: 1}}>
                        <RobloxGameDetailContent
                            robloxGameDetailController={controller}
                            sidePanelState={RobloxGameDetailSidePanelState.COLLAPSED}
                            secretVideoPlayable={props.homeController.focus === "secrets"}
                            codesVideoPlayable={props.homeController.focus === "codes"}
                            scrollMobileToEnd={() => mobileScrollViewRef.current?.scrollToEnd()}
                        />
                    </View>
                </ScrollView>
            }



                {controller.state == RobloxGameDetailState.LOADING &&
                    <View style={{flex: 1, justifyContent: 'center', alignItems: 'center', ...StyleSheet.absoluteFillObject}}>
                        <ActivityIndicator color={'white'} />
                    </View>}
            </View>
           <VideoLightbox
                video={controller.trailerVideo}
                onDismiss={() => controller.setTrailerVideo(undefined)} />
        </FullscreenModal>
    )
})
