import React, {useState} from 'react'
import {
    ListRenderItemInfo,
    TouchableWithoutFeedback,
    View,
} from "react-native"
import {SearchForFriendsController} from "../controllers/SearchForFriendsController"
import {PrimaryButton} from '../../app/views/components/PrimaryButton'
import {SpeechUtils} from '../../app/utils/AVUtils'
import FriendDetails from "../../modules/social/views/friend_widget/FriendDetails"
import { ProfileModel } from '../../modules/social/models/ProfileModel'
import SentryService from '../../app/services/SentryService'

type Props = {
    speech: SpeechUtils
    controller: SearchForFriendsController
    profile: ProfileModel
    onProfileTapped?: (profile: ProfileModel) => void
}

export const SearchForFriendsItem = ({ speech, controller, profile, onProfileTapped = () => {}}: Props) => {
    const [loading, setLoading] = useState(false)

    const onAddClicked = async (profile: ProfileModel) => {
        try {
            speech.pause()
            setLoading(true);
            await controller.addFriend(profile)
            void speech.speak("Great! We've sent the friend request. You'll get a notification when it is accepted.")
            setLoading(false);
        } catch (error) {
            SentryService.captureError(error)
        }
    }

    return(
        <TouchableWithoutFeedback onPress={() => onProfileTapped(profile)}>
            <View style={{flex: 1, flexDirection: "row", justifyContent: "space-between", alignItems: "center", marginBottom: 20}}>
                <FriendDetails profile={profile} />
                { !profile.person?.friendship_status &&
                    <PrimaryButton
                        disabled={loading}
                        iconType={'FontAwesome'}
                        icon={'plus'}
                        title={'Add'}
                        onPress={() => onAddClicked(profile)}/>
                }
            </View>
        </TouchableWithoutFeedback>
    )
}
