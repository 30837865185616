import {action, makeObservable, observable} from "mobx"
import {AppController} from "../../../../app/controllers/AppController"
import {CurrentAccount} from "../../../vizz_account/lib/CurrentAccount"
import {OnboardingController} from "../OnboardingController"
import {GooglePlacesService} from "../../../../app/services/GooglePlacesService"
import SentryService from "../../../../app/services/SentryService"
import PersonModel from "../../../vizz_account/models/PersonModel"
import {AsyncUtils} from "../../../../app/utils/AsyncUtils"

export enum SchoolState {
    LOADING,
    SEARCH,
    CONFIRM,
    ADD_FRIENDS,
    SUBMIT
}

export interface PlaceResult {
    google_place_id: string
    name: string
    pretty_location: string
}


export class SchoolController {
    private debug: boolean = false  // don't set this to true in production

    public appController: AppController
    public onboardingController: OnboardingController
    private currentAccount: CurrentAccount
    private spokeTapTip: boolean = false
    private executePlacesSearchTimeout?: any

    @observable state: SchoolState = SchoolState.LOADING
    @action public setState(state: SchoolState) {
        this.state = state
        void this.onStateChanged()
    }
    @observable isSearching?: boolean;                  @action public setIsSearching(is: boolean) { this.isSearching = is }
    @observable mentionedHomeschool: boolean = false;   @action public setMentionedHomeschool(m: boolean) { this.mentionedHomeschool = m }
    @observable results: PlaceResult[] = [];            @action public setResults(results: PlaceResult[]) { this.results = results }
    @observable schoolInput: string = '';               @action setSchoolInput(text: string) { this.schoolInput = text }
    @observable selectedSchool?: PlaceResult;           @action public setSelectedSchool(selectedSchool: PlaceResult) { this.selectedSchool = selectedSchool }


    constructor(currentAccount: CurrentAccount, appController: AppController, onboardingController: OnboardingController) {
        this.consoleDebug(`new()`)

        this.currentAccount = currentAccount
        this.appController = appController
        this.onboardingController = onboardingController

        makeObservable(this)
    }

    public async initialize() {
        this.consoleDebug(`initialize()`)
        if (this.state != SchoolState.LOADING) return
        if (this.currentAccount.personData.ageBand == 'grown-up') return

        this.onboardingController.speech.preloadPhrase('select-school', `Let's see who else at your school also uses Lava so you can call and text them. Start typing your school.`)
        this.onboardingController.speech.preloadPhrase('select-school-homeschool', `Or if you're homeschooled, you can type homeschool.`)
        this.onboardingController.speech.preloadPhrase('tap-school', `Scroll down & tap your school.`)
        this.onboardingController.speech.preloadPhrase('confirm-school', 'Is this the right school? If not, go back and pick again.')
        this.onboardingController.speech.preloadPhrase('confirm-school-first-on-lava', 'You are the first person from your school to join Lava. Is this the right school? If not, go back and pick again.')
        this.onboardingController.speech.preloadPhrase('add-friends', 'Here are the other people from your school that are using Lava. Add your friends or skip.')
    }

    public uninitialize() {
        this.consoleDebug(`uninitialize()`)
        this.onboardingController.speech.unloadPreloadedPhrase('select-school')
        this.onboardingController.speech.unloadPreloadedPhrase('confirm-school')
        this.onboardingController.speech.unloadPreloadedPhrase('confirm-school-first-on-lava')
        this.onboardingController.speech.unloadPreloadedPhrase('add-friends')
    }

    public async startStep() {
        this.consoleDebug(`startStep()`)
        if (this.currentAccount.personData.ageBand == 'grown-up') {
            await this.nextStep()
            return
        }

        this.currentAccount.analytics.logEvent('school-onboarding','started')
        this.setState(SchoolState.SEARCH)
    }

    public onSearchTextChanged(text: string) {
        this.setIsSearching(true)
        this.setSchoolInput(text)

        this.setMentionedHomeschool(true)

        if (this.executePlacesSearchTimeout) clearTimeout(this.executePlacesSearchTimeout)
        this.executePlacesSearchTimeout = setTimeout(async () => {

            this.currentAccount.analytics.logEvent('school-onboarding','searched', text)

            let rawResults, results:PlaceResult[] = []
            if ("homeschool".startsWith(text.toLowerCase())) {
                results = [] as PlaceResult[]
            } else {
                rawResults = await GooglePlacesService.getAutocompleteSchools(text)
                results = rawResults.map((r: any) => {
                    return {
                        google_place_id: r.place_id,
                        name: r.structured_formatting.main_text,
                        pretty_location: r.structured_formatting.secondary_text,
                    }
                })
            }
            this.setIsSearching(false)
            if (!this.spokeTapTip) await this.onboardingController.speech.speakPreloadedPhrase('tap-school', () => {
                this.spokeTapTip = true
            })
            this.setResults(results)
        }, 1000)
    }

    public selectSchool(school: PlaceResult) {
        this.consoleDebug(`selectSchool()`)
        this.setSelectedSchool(school)
        this.setState(SchoolState.CONFIRM)
        this.setIsSearching(false)
        this.setResults([])

        this.currentAccount.analytics.logEvent('school-onboarding', 'tapped', this.selectedSchool?.name, { selected_school: this.selectedSchool })
    }

    public async confirmSchool() {
        if (!this.selectedSchool) return

        this.setState(SchoolState.SUBMIT)
        let schoolPeopleCount = 0

        try {
            const schoolPeople = await this.currentAccount.api.patch('school_path', {
                school: this.selectedSchool
            }) as PersonModel[]
            schoolPeopleCount = schoolPeople.length
        } catch (error) {
            console.log(error)
            SentryService.captureError(error as Error)
            this.setState(SchoolState.CONFIRM) // TODO: This does not seem right. We should try explicitly throwing an error to see what the user experience is here.
            return
        }

        this.currentAccount.analytics.logEvent('school-onboarding', 'confirmed', `${schoolPeopleCount} people`)

        if (schoolPeopleCount > 0) {
            this.setState(SchoolState.ADD_FRIENDS)
        } else {
            void this.nextStep()
        }
    }

    public doneAddingFriends() {
        void this.nextStep()
    }

    public async skip() {
        this.consoleDebug(`skip()`)
        this.setState(SchoolState.LOADING)

        //this.currentAccount.api.post("social.create_school_reminder_stories_path", {})

        await this.currentAccount.analytics.asyncLogEvent('school-onboarding', 'skipped', this.selectedSchool?.name, { selected_school: this.selectedSchool })
        this.onboardingController.nextStep()
    }

    public schoolNotFound() {
        this.currentAccount.analytics.logEvent('school-onboarding', 'school-not-found', undefined,
            { results: this.results.slice(0, 3) })

        void this.nextStep()
    }

    public async nextStep() {
        this.consoleDebug(`nextStep()`)

        this.spokeTapTip = true              // interrupt the setTimeout stuff that was happening on search
        if (this.executePlacesSearchTimeout) clearTimeout(this.executePlacesSearchTimeout)

        await this.currentAccount.analytics.asyncLogEvent('school-onboarding', 'finished', this.selectedSchool?.name, { selected_school: this.selectedSchool })
        this.onboardingController.nextStep()
    }


    // Private helper methods

    private async onStateChanged() {
        switch (this.state) {
            case SchoolState.LOADING: break
            case SchoolState.SEARCH: {
                await this.onboardingController.speech.speakPreloadedPhrase('select-school', async() => {
                    await AsyncUtils.sleep(2000)
                    if (!this.mentionedHomeschool) {
                        this.setMentionedHomeschool(true)
                        await this.onboardingController.speech.speakPreloadedPhrase('select-school-homeschool')
                    }
                })
                if (this.schoolInput != '') this.onSearchTextChanged(this.schoolInput)
                break
            }
            case SchoolState.CONFIRM: {
                // TODO: Replace with API check
                const schoolHasOtherPeople = true
                if (schoolHasOtherPeople) {
                    await this.onboardingController.speech.speakPreloadedPhrase('confirm-school')
                } else {
                    await this.onboardingController.speech.speakPreloadedPhrase('confirm-school-first-on-lava')
                }
                break
            }
            case SchoolState.ADD_FRIENDS: {
                await this.onboardingController.speech.speakPreloadedPhrase('add-friends')
                break
            }
        }
    }

    // Private instance utility methods

    private consoleDebug(method: string, force: boolean = false) {
        if (this.debug || force) console.log(`${this.constructor.name}: ${method}  state = ${this.state}`)
    }
}
