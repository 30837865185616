import {observer} from "mobx-react"
import {StyleSheet, View} from "react-native"
import {action, makeObservable, observable} from "mobx"
import {isTablet} from "../../lib/Appearance"
import useTheme from "../../../modules/lava-components/components/hooks/useTheme"
import Title from "../../../modules/lava-components/components/atoms/Title/Title"
import Button, {ButtonVariation} from "../../../modules/lava-components/components/atoms/Button/Button"
import {FontAwesome} from "@expo/vector-icons"
import React, {useContext, useEffect} from "react"
import CurrentAccountContext, {CurrentAccount} from "../../../modules/vizz_account/lib/CurrentAccount"
import {SpeechUtils} from "../../utils/AVUtils"

type LavaAlertButtonIcon = 'thumbs-up' | 'thumbs-down'

export type LavaAlertButton = {
    text: string,
    icon?: LavaAlertButtonIcon
    onPress?: () => void
    variation?: 'primary'|'secondary'
}

export type LavaAlertModel = {
    title: string
    buttons: LavaAlertButton[]
    speak?: boolean
}

class LavaAlertController {
    @observable alert?: LavaAlertModel
    @action setAlert(alert?: LavaAlertModel) { this.alert = alert }

    currentAccount?: CurrentAccount
    speech?: SpeechUtils

    constructor() {
        makeObservable(this)
    }

    setCurrentAccount(currentAccount: CurrentAccount) {
        this.currentAccount = currentAccount
        this.speech = new SpeechUtils(currentAccount, true)
    }

    public speak(text: string) {
        void this.speech?.speak(text)
    }

    public stopSpeaking() {
        this.speech?.pause()
    }
}

export const LavaAlertOverlay = observer(() => {

    const currentAccount = useContext(CurrentAccountContext)
    const theme = useTheme()

    useEffect(() => {
        LavaAlert.controller.setCurrentAccount(currentAccount)
    }, [])

    const icon = (button: LavaAlertButton) => {
        if (!button.icon) return undefined
        switch (button.icon) {
            case 'thumbs-up': return <FontAwesome name="thumbs-up" size={16} color={theme.colors.sand} />
            case 'thumbs-down': return <FontAwesome name="thumbs-down" size={16} color={theme.colors.sand} />
        }
    }

    if (!LavaAlert.controller.alert) return null

    return (
        <View style={{
            flex: 1,
            backgroundColor: 'rgba(0, 0, 0, 0.75)',
            justifyContent: 'center',
            alignItems: 'center',
            ...StyleSheet.absoluteFillObject
        }}>
            <View style={{
                justifyContent: 'center',
                alignItems: 'center',
                width: isTablet() ? 626 : 320,
                backgroundColor: theme.colors.secondaryBk,
                borderWidth: 0.5,
                borderColor: theme.colors.tertiarySand20,
                borderRadius: 2,
                padding: 32
            }}>
                <Title style={{marginBottom: theme.spacing.xxl * 2}} variation={'title1'}>{LavaAlert.controller.alert.title}</Title>
                <View style={{flexDirection: 'row'}}>
                    {LavaAlert.controller.alert.buttons?.map((button, index) => {
                        let variation: ButtonVariation = 'gravity'
                        if (button.variation == 'secondary') {
                            variation = 'tertiary'
                        }


                        return (
                            <Button
                                key={`button-${index}`}
                                style={{marginHorizontal: theme.spacing.xl}}
                                variation={variation}
                                icon={icon(button)}
                                onPress={() => {
                                    if (button.onPress) button.onPress()
                                    LavaAlert.dismiss()
                                }}
                            >{button.text}</Button>
                        )
                    })}
                </View>
            </View>
        </View>
    )
})

export class LavaAlert {
    static controller = new LavaAlertController()

    public static show(title: string, buttons: LavaAlertButton[] | undefined = [{text: 'OK', icon: 'thumbs-up'}]) {
        this.controller.setAlert({
            title: title,
            buttons: buttons
        })
    }

    public static showModel(model: LavaAlertModel) {
        this.controller.setAlert(model)
        if (model.speak) {
            this.controller.speak(model.title)
        }
    }

    public static dismiss() {
        this.controller.setAlert(undefined)
        this.controller.stopSpeaking()
    }
}
