import React, {useCallback, useRef, useState} from 'react'
import {Image, Pressable} from 'react-native'
import {SafeAreaView} from 'react-native'
import {StyleSheet, Text, View, Animated, PanResponder, Dimensions} from 'react-native'
import {FlatList} from 'react-native-gesture-handler'

declare module 'react-native' {
    namespace Animated {
      interface Value {
        _value: number;
      }
    }
  }

const {height} = Dimensions.get('screen')
  
const Feed = ({index}:any) => (
    <View style={styles.feed}>
        <Text style={styles.text}>News feed {index}</Text>
    </View>
)

const Friend = ({index}:any) => (
    <View style={styles.friend}>
        <Text style={styles.text}>Friend {index}</Text>
    </View>
)

const Message = () => (
  <View style={styles.message}>
        <Text style={styles.text}>New message</Text>
    </View>
)

const viewabilityConfig = {
    waitForInteraction: true,
    viewAreaCoveragePercentThreshold: 0
}

export const Home = () => {
    const [_, setState] = useState(false)
    const [messages, setMessages] = useState<string[]>([])
    const focusItemRef = useRef<number>(0)
    const flatListRef = useRef<FlatList>(null)
    const friendListRef = useRef<FlatList>(null)
    const messageListRef = useRef<FlatList>(null)
    const marginLeft = useRef(new Animated.Value(0)).current
    const marginTop = useRef(new Animated.Value(20)).current;
    const inboxHeight = useRef(new Animated.Value(60)).current;
    const onViewableItemsChanged = useCallback(({ viewableItems}:any ) => focusItemRef.current = viewableItems[0].index, []);
    const viewabilityConfigCallbackPairs = useRef([{viewabilityConfig,  onViewableItemsChanged }])

    const panResponder = useRef(
      PanResponder.create({
        onStartShouldSetPanResponder: () => marginTop._value > -320,
        onMoveShouldSetPanResponderCapture: () => marginTop._value > -320,
        onPanResponderGrant: () => { setState(false)},
        onPanResponderMove: (event, gestureState) => {
          if (marginTop._value === -320) {
            // Disable dragging when marginTop is already -320
            setState(true)
            return;
          }
          if (gestureState.dy >= -320 && gestureState.dy <= 0) {
            marginTop.setValue(20 + gestureState.dy);
            if (gestureState.dy <= -10) {
              Animated.timing(marginLeft, {
                toValue: 340,
                duration:50,
                useNativeDriver:false
              }).start()
            }
          }
        },
        onPanResponderRelease: (event, gestureState) => {
          if (marginTop._value <= -320) {
            // Already at marginTop -320, no need to animate
              setState(true)
            return;
          }
          if (gestureState.dy <= -10) {
            // Dragged enough to bring marginTop to -320
            Animated.timing(marginTop, {
              toValue: -320,
              duration: 200,
              useNativeDriver: false,
            }).start(() => {
              flatListRef.current?.scrollToIndex({
                animated:true,
                index:0
              })
              setState(true)
            });        
          } else {
            // Not dragged enough, reset marginTop to 0
            Animated.timing(marginTop, {
              toValue: 20,
              duration: 100,
              useNativeDriver: false,
            }).start();
             Animated.timing(marginLeft, {
              toValue: 0,
              useNativeDriver: false,
              duration: 50
            }).start()
          }
        },
      })
    ).current;

    const feedPanResponder = useRef(
      PanResponder.create({
        onStartShouldSetPanResponder: () => marginLeft._value > 1,
        onMoveShouldSetPanResponderCapture: () => marginLeft._value > 1,
        onPanResponderGrant: () => {setState(false)},
        onPanResponderMove: (event, gestureState) => {
          if(marginLeft._value < 1) {
              setState(true)
              return;
          }
          if(gestureState.dx >= -320 && gestureState.dx < 0){
              marginLeft.setValue(340 + gestureState.dx)
              if(gestureState.dx <= -10){
                Animated.timing(marginTop, {
                  toValue: 20,
                  duration: 50,
                  useNativeDriver: false,
                }).start();
                Animated.timing(inboxHeight, {
                  toValue: 60,
                  duration:50,
                  useNativeDriver:false
                }).start()
              }
          }
        },
        onPanResponderRelease: (_, gestureState) => {
          if (marginLeft._value < 1) {
              setState(true)
              return;
          }
          if (gestureState.dx <= -10) {
              Animated.timing(marginLeft, {
                toValue: 0,
                duration: 100,
                useNativeDriver: false,
              }).start(() => {
                friendListRef.current?.scrollToIndex({
                  animated:true,
                  index:0
                })
                messageListRef.current?.scrollToIndex({
                  animated:true,
                  index:0
                })
                setState(true)
              })
          } else {
            Animated.timing(marginTop, {
              toValue: -320,
              duration: 50,
              useNativeDriver: false
            }).start()
          }
        },
      })
    ).current;

    const handleInboxClick = () => {
      if(inboxHeight._value > 60) return
      const messagesHeight = 80 * messages.length

      let toValue = messagesHeight > 320 ? messagesHeight : 320
      toValue = toValue > height - 300 ? height - 300 : toValue

      Animated.timing(inboxHeight, {
        toValue: toValue,
        duration: 200,
        useNativeDriver: false
      }).start()
      Animated.timing(marginLeft, {
        toValue: 340,
        duration:200,
        useNativeDriver:false
      }).start()
      Animated.timing(marginTop, {
        toValue: -320,
        duration: 200,
        useNativeDriver: false
      }).start()
      flatListRef.current?.scrollToIndex({
        animated:true,
        index:0
      })
    }

    const handleInboxCloseClick = () => {
      Animated.timing(inboxHeight, {
        toValue: 60,
        duration: 200,
        useNativeDriver: false
      }).start()
      Animated.timing(marginTop, {
        toValue: 20,
        duration: 200,
        useNativeDriver: false
      }).start()
      Animated.timing(marginLeft, {
        toValue: 0,
        duration:200,
        useNativeDriver:false
      }).start()
      setState(true)
    }

    const addMessage = () => {
      setMessages([...messages, "New message"])

      const messagesHeight = (80 * messages.length) + 80

      if(messagesHeight > 320 && messagesHeight < height - 300) {
        Animated.timing(inboxHeight, {
          toValue: messagesHeight,
          duration: 100,
          useNativeDriver: false
        }).start()
      
      }
    }

    return (
        <SafeAreaView style={{flex:1}}>
            <View style={{ flex: 1 }}>
                <Animated.View style={[styles.feedContent, {marginLeft: marginLeft}]} {...feedPanResponder.panHandlers}>
                    <FlatList 
                      horizontal={true}
                      data={[1,2,3,4,5,6,7,8,9,10,11,12]}
                      renderItem={({index})=> <Feed index={index}/>}
                      ref={flatListRef}
                      showsHorizontalScrollIndicator={false}
                      scrollEnabled={true}
                      contentContainerStyle={{ flexGrow: 1 }}
                      viewabilityConfigCallbackPairs={viewabilityConfigCallbackPairs.current}
                      scrollEventThrottle={16}
                    />
                </Animated.View>
                <View style={{flex:1,flexDirection:'row'}}>
                  <View style={{flex:1, width:320}}>
                    <Animated.View
                      style={{
                        width:320,
                        flex: 1,
                        marginTop,
                      }}
                      {...panResponder.panHandlers}
                    >
                        <Animated.View style={{ height:inboxHeight, backgroundColor:'black' }}>
                          <FlatList
                            data={messages}
                            renderItem={({item, index})=> <Message key={index}/>}
                            onTouchEnd={handleInboxClick}
                            ref={messageListRef}
                          />

                          <Pressable onPress={handleInboxCloseClick} style={{
                            alignItems:'center', 
                            justifyContent: 'center',
                            position:'absolute',
                            top:10,
                            right: 10,
                            width: 30,
                            height: 30,
                          }}>
                            <Image source={require("../../start/assets/Chevron.png")} style={{width: 25, height: 15}}/>
                          </Pressable>
                        </Animated.View>
                        <FlatList
                            data={[1,2,3,4,5,6,7,8,9,10,11,12]}
                            renderItem={({index})=> <Friend index={index}/>}
                            scrollEnabled={true}
                            contentContainerStyle={{ flexGrow: 1 }}
                            ref={friendListRef}
                            bounces={false}
                        />
                    </Animated.View>
                  </View>
                 <View style={{flex:1, justifyContent:'center'}}>
                  <Pressable style={{padding:20, backgroundColor:'yellow', alignSelf:'baseline'}} onPress={addMessage}>
                      <Text>Add Messages</Text>
                    </Pressable>
                 </View>
              </View>
            </View>
        </SafeAreaView>
    )

}

const styles = StyleSheet.create({
    container:{
        flex:1,
        backgroundColor:'gray',
        flexDirection: 'column'
    },
    sideBar: {
        width:320,
        padding:20,
        backgroundColor: 'green',
        marginTop: 340,
        height: '100%',
        flex: 1
    },
    feedContent: {
        height:320,
        backgroundColor: 'transparent',
    },
    text: {
        color: '#fff'
    },
    feed: {
        height: 320,
        width: 320,
        backgroundColor:'blue',
        marginRight:20,
        justifyContent:'center',
        alignItems:'center'
    },
    friend: {
        height: 320,
        justifyContent:'center',
        alignItems:'center',
        backgroundColor: 'red'
    },
    message: {
      height: 60,
      backgroundColor: 'gray',
      marginVertical: 10
    }
})