import React from 'react'
import {TouchableRipple} from "react-native-paper"
import {StyleSheet, ViewStyle} from "react-native"
import {ResizeMode} from "expo-av/build/Video.types"
import {LaunchableAppModel} from "../models/LaunchableAppModel"
import {Color} from "../../../app/lib/Appearance"
import {LavaImage} from "../../../app/views/components/LavaImage"

type Props = {
    app: LaunchableAppModel
    onPress?: () => void
    style?: ViewStyle
}

export const AppIcon = (props: Props) => {
    return (
        <TouchableRipple style={[styles.container, props.style]} onPress={props.onPress}>
            <LavaImage style={{flex: 1}} source={{uri: props.app.appIconUrl}}
                   accessibilityLabel={props.app.name}
                   resizeMode={ResizeMode.CONTAIN}/>
        </TouchableRipple>
    )
}

const styles = StyleSheet.create({
    container: {
        overflow: 'hidden',
        borderRadius: 25,
        borderColor: Color.hairline,
        borderWidth: 1
    }
})