import AsyncStorage from "@react-native-async-storage/async-storage"
import {CurrentAccount} from "../../modules/vizz_account/lib/CurrentAccount"
import * as Updates from 'expo-updates'
import {HttpError} from "./RailsAPIService"
import {AppController} from "../controllers/AppController"
import SentryService from "./SentryService"


export class AccountService {
    private debug: boolean = false  // don't set this to true in production

    private currentAccount!: CurrentAccount

    // Public instance methods

    constructor() {
        //makeObservable(this)
    }

    public initialize(currentAccount: CurrentAccount) {
        this.consoleDebug(`initialize()`)

        this.currentAccount = currentAccount
    }

    public async registerAndLogin(robloxUserId: number, cookies: string) {
        this.consoleDebug(`registerAndLogin(${robloxUserId}, ${cookies ? 'cookies' : ''})`)

        try {
            await this.currentAccount.api.post('roblox_profiles_path', { roblox_user_id: robloxUserId })

            await this.currentAccount.api.post('roblox_credentials_path', {
                roblox_credential: {
                    roblox_user_id: robloxUserId,
                    cookies: cookies,
                }})
        } catch (error) {
            if (!(error instanceof Error)) return //TODO: Also fall back to Onboarding network errors screen
            const isConflict = (error instanceof HttpError) && error.status == "conflict"

            if (isConflict) // conflict
                return false
            else
                throw error
        }

        await this.login(robloxUserId, cookies, 'skip-credential-updating')

        // All rails API calls from this point forward should have an authentication key within the triplet.
        // for existing user installs we created the authentication on the server-side and it automatically
        // logged people in and started passing it back.

        return true
    }

    public async login(robloxUserId: number, cookies: string, mode?: 'skip-credential-updating') {
        this.consoleDebug(`login(${robloxUserId}, ${cookies ? 'cookies' : ''})`)

        await this.currentAccount.api.post('roblox_authentications_path', {
            roblox_credential: {
                roblox_user_id: robloxUserId,
                cookies: cookies,
            }})

        if (mode != 'skip-credential-updating') {
            // update existing credentials with the new cookie
            await this.currentAccount.api.patch('roblox_credential_path(0)', {
                roblox_credential: {
                    roblox_user_id: robloxUserId,
                    cookies: cookies,
                }})
        }

        return true
    }

    public async logoutAndReset(appController?: AppController) {

        try {
            await appController?.uninitialize()
            await this.logout()
        } catch (error) {} // we couldn't log the user out but we'll proceed anyway

        try {
            const installationKey = this.currentAccount.personData.installationKey // preserve this

            await AsyncStorage.clear()

            await this.currentAccount.personData.loadPreviousFromStorage()
            await this.currentAccount.personData.setDataAsync('installationKey', installationKey)
            await this.currentAccount.personData.setDataAsync('pitchOnboardingNeeded', false)
            await this.currentAccount.personData.setDataAsync('audioOnboardingNeeded', false)
            await this.currentAccount.personData.setDataAsync('idfaOnboardingNeeded', false)

            console.log(`Local storage has been cleared. Device key = ${this.currentAccount.personData.deviceKey} and installation key was preserved = ${this.currentAccount.personData.installationKey}`)

            // Reload the app
            await Updates.reloadAsync()
        } catch (e) {
            SentryService.captureError(e)
        }
    }

    public async logout() {
        await this.currentAccount.api.delete('roblox_authentications_path', {})
    }

    private consoleDebug(method: string, details: any = '') {
        if (this.debug) console.log(`${this.constructor.name}: ${method}`, details)
    }
}
