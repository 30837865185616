import {action, computed, makeObservable, observable} from "mobx"
import {AsyncUtils} from "../../../../app/utils/AsyncUtils"
import {CurrentAccount} from "../../../vizz_account/lib/CurrentAccount"
import FriendModel from "../../models/steps/FriendModel"
import {OnboardingController} from "../OnboardingController"
import {FriendsGridController} from "./friends/FriendsGridController"
import SentryService from "../../../../app/services/SentryService"

export const MAX_FRIENDS = 25

export enum FriendsState {
    LOADING,
    LOADED,
    DELAY,
    SELECT,
}

export class FriendsController {
    private debug: boolean = false  // don't set this to true in production

    private currentAccount: CurrentAccount
    public onboardingController: OnboardingController
    public maxFriends:number = MAX_FRIENDS
    private executePlacesSearchTimeout?: any
    private spokeTapTip: boolean = false
    private recordedInitialInteraction: boolean = false

    @observable state: FriendsState = FriendsState.LOADING;             @action private setState(state: FriendsState) { this.state = state }
    @observable friendsGridController?: FriendsGridController;          @action public setFriendsGridController(controller: FriendsGridController) { this.friendsGridController = controller }
    @observable freezeSelections:boolean = false;                       @action public setFreezeSelections(freeze: boolean) { this.freezeSelections = freeze }
    @observable robloxFriendsCount:number = 0;                          @action public setRobloxFriendsCount(count: number) { this.robloxFriendsCount = count }
    @observable friendSearchQuery:string = '';                          @action public setFriendSearchQuery(search: string) { this.friendSearchQuery = search }
    @observable mode?: 'browse'|'search';                               @action private setMode(mode: 'browse'|'search') { this.mode = mode }
    @observable isSearching: boolean = false;                           @action public setIsSearching(is: boolean) { this.isSearching = is }
    @observable isHidingDoneButton: boolean = false;                    @action public setIsHidingDoneButton(is: boolean) { this.isHidingDoneButton = is }
    @observable isWaitingForSelectingMoreFriends: boolean = false;      @action public setIsWaitingForSelectingMoreFriends(is: boolean) { this.isWaitingForSelectingMoreFriends = is }

    constructor(currentAccount: CurrentAccount, onboardingController: OnboardingController) {
        this.consoleDebug(`new()`)

        this.currentAccount = currentAccount
        this.onboardingController = onboardingController

        makeObservable(this)
    }

    public async initialize() {
        try {
            this.consoleDebug(`initialize()`)
            if (this.state != FriendsState.LOADING) return

            this.onboardingController.speech.preloadPhrase('friends-intro', `So that's how you make a call. Now... Let's pick your "REAL" friends from Roblox so you can call and chat them.`)
            this.onboardingController.speech.preloadPhrase('friends-select-browse', `Here are your friends in Roblox. Tap each person who is a "REAL" friend, or someone you want to be able to call and chat.`)
            this.onboardingController.speech.preloadPhrase('friends-select-search', `Here are your friends in Roblox. Tap each person who is a "REAL" friend, or someone you want to be able to call and chat. You can also search for friends.`)
            this.onboardingController.speech.preloadPhrase('friends-select-searched', `Tap each person who is a real friend, or someone you want to be able to call and chat.`)
            this.onboardingController.speech.preloadPhrase('friends-select-tap', `Once you selected all your "REAL" friends, tap the thumbs up.`)
            this.onboardingController.speech.preloadPhrase('friends-select-max', `You have already picked ${this.maxFriends} real friends. Unselect someone and pick your favorite ones. Then tap the thumbs up.`)
            this.onboardingController.speech.preloadPhrase('friends-instruct-tap', `Scroll down & tap your "REAL" friends.`)

            const response = await this.currentAccount.api.get('roblox_people_path')
            this.setRobloxFriendsCount(response.length ?? 0)

            if (this.robloxFriendsCount > 30)
                this.setMode('search')
            else
                this.setMode('browse')

            if (this.currentAccount.personData.hasRobloxAccount && this.robloxFriendsCount > 0) {
                this.currentAccount.analytics.logEvent('friends-onboarding','started', `${this.mode} mode`, { mode: this.mode })
                this.setState(FriendsState.LOADED)
            } else {
                void this.nextStep()
            }
        } catch (erro) {
            SentryService.captureError(erro)
        }
    }

    public uninitialize() {
        this.consoleDebug(`uninitialize()`)

        this.onboardingController.speech.unloadPreloadedPhrase('friends-intro')
        this.onboardingController.speech.unloadPreloadedPhrase('friends-select-browse')
        this.onboardingController.speech.unloadPreloadedPhrase('friends-select-search')
        this.onboardingController.speech.unloadPreloadedPhrase('friends-select-searched')
        this.onboardingController.speech.unloadPreloadedPhrase('friends-select-tap')
        this.onboardingController.speech.unloadPreloadedPhrase('friends-select-max')
        this.onboardingController.speech.unloadPreloadedPhrase('friends-instruct-tap')
    }


    // Public methods

    public async startStep() {
        this.consoleDebug(`startStep()`)
        if (this.robloxFriendsCount == 0) return

        await this.onboardingController.speech.speakPreloadedPhrase('friends-intro', async() => {
            this.setState(FriendsState.DELAY)
            await this.beginDelay()
        })
    }

    public async beginDelay() {
        await AsyncUtils.sleep(2000)
        this.setState(FriendsState.SELECT)
        await this.beginFriendSelecting()
    }

    public async beginFriendSelecting() {
        if (this.mode == 'browse')
            await this.onboardingController.speech.speakPreloadedPhrase('friends-select-browse', async() => {})
        else
            await this.onboardingController.speech.speakPreloadedPhrase('friends-select-search', async() => {})
    }

    public onToggleSelectedFriend(friend: FriendModel) {
        this.consoleDebug(`onToggleSelectedFriend() - ${friend.isSelected} and ${friend.robloxFriend.display_name} (${this.realFriends.length})`)

        if (friend.isSelected) {
            this.currentAccount.analytics.logEvent('friends-onboarding','selected', friend.robloxFriend.display_name)

            if (this.realFriends.length == 1) void this.onboardingController.speech.speakPreloadedPhrase('friends-select-tap')

            this.setIsHidingDoneButton(false)
        } else {
            if (this.realFriends.length == this.maxFriends)
                void this.onboardingController.speech.speakPreloadedPhrase('friends-select-max')
            else
                this.currentAccount.analytics.logEvent('friends-onboarding','unselected', friend.robloxFriend.display_name)
        }
    }

    public async onFriendSearchQueryChanged(text: string) {
        this.setIsSearching(true)
        this.setFriendSearchQuery(text)

        if (this.recordedInitialInteraction) {
            this.currentAccount.analytics.logEvent('friends-onboarding','initially', `searched`)
            this.recordedInitialInteraction = true
        }

        if (this.executePlacesSearchTimeout) clearTimeout(this.executePlacesSearchTimeout)
        this.executePlacesSearchTimeout = setTimeout(async () => {
            await this.friendsGridController?.search(text)

            this.setIsSearching(false)
            if (!this.spokeTapTip) await this.onboardingController.speech.speakPreloadedPhrase('friends-instruct-tap', () => {
                this.spokeTapTip = true
            })
        }, 1000)
    }

    public onFriendGridInteraction() {
        if (this.recordedInitialInteraction) {
            this.currentAccount.analytics.logEvent('friends-onboarding','initially', `swiped`)
            this.recordedInitialInteraction = true
        }
    }

    @computed
    get realFriends() {
        return this.friendsGridController ? this.friendsGridController.realFriends : []
    }

    @computed
    get suggestedFriendRequestsToDecline() {
        return this.friendsGridController ? this.friendsGridController.suggestedFriendRequestsToDecline : []
    }

    public async nextStep() {
        this.consoleDebug(`nextStep()`)

        const realFriendsCount = this.realFriends.length
        const allFriendsCount = (this.friendsGridController?.friends ?? []).length
        if (realFriendsCount < this.maxFriends && realFriendsCount < allFriendsCount && !this.isWaitingForSelectingMoreFriends) {
            this.onboardingController.speech.pause()
            this.setIsHidingDoneButton(true)
            this.setIsWaitingForSelectingMoreFriends(true)

            const confirmMessage = realFriendsCount == 1 ?
                `It looks like you picked 1 real friend. Does that look right? ` :
                `It looks like you picked ${realFriendsCount} real friends. Does that look right? `
            const suggestMessage = this.suggestedFriendRequestsToDecline.length > 0 ?
                `Here are some suggested friends. "Add" anyone you know in real life and want to be able to call.` :
                `"Add" anyone else you know in real life who you want to be able to call and chat.`

            await this.onboardingController.speech.speakAndWait(confirmMessage + suggestMessage)
            this.setIsHidingDoneButton(false)
            return
        }

        this.setFreezeSelections(true)
        this.onboardingController.speech.pause()

        if (this.realFriends.length > 0) {
            try {
                await this.currentAccount.api.post('friend_requests_path', {
                    keys: this.realFriends.map((f) => f.robloxFriend.person_key)
                })
            } catch (error) {
                SentryService.captureError(error)
            }
        }

        if (this.suggestedFriendRequestsToDecline.length > 0) {
            try {
                await this.currentAccount.api.patch('bulk_update_friend_requests', {
                    ids: this.suggestedFriendRequestsToDecline.map((f) => f.id),
                    state: "declined",
                })
            } catch (error) {
                SentryService.captureError(error)
            }
        }

        this.currentAccount.analytics.logEvent('friends-onboarding', 'finished', this.realFriends.map((f) => f.robloxFriend.display_name).join(", "), {
            roblox_profile_id: this.realFriends.map((f) => f.robloxFriend.id)
        })

        this.onboardingController.nextStep()
    }


    // Private instance utility methods

    private consoleDebug(method: string, force: boolean = false) {
        if (this.debug || force) console.log(`${this.constructor.name}: ${method}  state = ${this.state}`)
    }
}
