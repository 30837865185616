import React from 'react'
import {View, StyleSheet} from "react-native"
import {Entypo} from '@expo/vector-icons'
import {TouchableRipple} from 'react-native-paper'

type PlayButtonProps = {
    size: number
    disabled?: boolean
    onPress?: () => void
}

export const PlayButton = (props: PlayButtonProps) => {

    const normalize = (num: number) => {
        return Math.round(
            (props.size / 100) * num
        )
    }

    return (
        <TouchableRipple
            style={{...StyleSheet.absoluteFillObject, justifyContent: 'center', alignItems: 'center'}}
            onPress={props.onPress}
            onLongPress={props.onPress}
            underlayColor={'clear'}>

            <View style={{justifyContent: 'center', alignItems: 'center'}}>

                <View style={{
                    backgroundColor: 'black',
                    opacity: 0.7,
                    width: props.size,
                    height: props.size,
                    borderRadius: props.size / 2
                }}/>

                {props.disabled &&
                    <Entypo style={{position: 'absolute', marginTop: normalize(1), marginLeft: normalize(1)}} name={'circle-with-cross'} color={'white'} size={normalize(90)}/>
                }
                {!props.disabled &&
                    <Entypo style={{position: 'absolute', marginTop: normalize(3), marginLeft: normalize(6)}} name={'controller-play'} color={'white'} size={normalize(44)}/>
                }
            </View>
        </TouchableRipple>
    )
}
