import React, {useContext} from 'react'
import {observer} from "mobx-react"
import {StyleProp, View, ViewStyle} from "react-native"
import {FriendWidgetController} from '../../controllers/FriendWidgetController'
import CurrentAccountContext from '../../../vizz_account/lib/CurrentAccount'
import {PresenceModel} from "../../models/PresenceModel"
import {FriendRow} from "../../../lava-components/components/molecules/Friend/FriendRow"
import {FriendDotProps} from "../../../lava-components/components/molecules/Friend/FriendDot"
import useTheme from "../../../lava-components/components/hooks/useTheme"

type Props = {
    friendWidgetController: FriendWidgetController,
    presences: PresenceModel[],
    disableOpenProfile?: boolean,
    style?: StyleProp<ViewStyle>,
}

export const FriendGroup = observer((props: Props) => {
    const currentAccount = useContext(CurrentAccountContext)
    const theme = useTheme()

    const onTap = () => {
        openPeopleDetails()
    }

    const presencesWithoutSelf = () => {
        return props.presences.filter((p) => p.person_key != currentAccount.person.key)
    }

    const openPeopleDetails = () => {
        if (props.disableOpenProfile) return
        void props.friendWidgetController?.openChannelDetails(presencesWithoutSelf().map((p) => p.person_key))
    }

    const friendCircles = () => {
        return presencesWithoutSelf().map((p) => {
            return {
                username: p.profile_first_name,
                avatarUrl: p.roblox_profile_avatar_headshot_url ?? '',
                status: p.broadcasted_status,
                variant: 'primaryLarge',
            } as FriendDotProps
        })
    }

    return (
        <View>
            <FriendRow
                friendCircles={friendCircles()}
                onPress={onTap}
            />
        </View>
    )
})
