class AudioLoadError extends Error {
    code: number = 0

    constructor(message: string) {
      // Pass remaining arguments (including vendor specific ones) to parent constructor
      super(message)

      // Set the prototype explicitly
      Object.setPrototypeOf(this, AudioLoadError.prototype)

      // Maintains proper stack trace for where our error was thrown (only available on V8)
      if (Error.captureStackTrace) {
        Error.captureStackTrace(this, AudioLoadError)
      }

      this.name = 'AudioLoadError'
      // Custom debugging information
      this.code = 500
    }
  }

export default AudioLoadError
