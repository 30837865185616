import React, {useContext, useEffect, useRef, useState} from 'react'
import {FlatList, ListRenderItemInfo, ScrollView, StyleSheet, View, ViewStyle} from "react-native"
import {observer} from "mobx-react"
import {LibraryWidgetController} from '../controllers/LibraryWidgetController'
import CurrentAccountContext from "../../vizz_account/lib/CurrentAccount"
import AppControllerContext from '../../../app/controllers/AppController'
import {Widget} from '../../start/views/home/Widget'
import {reaction} from 'mobx'
import {NativeState} from '../../../app/services/NativeStateService'
import {AsyncUtils} from '../../../app/utils/AsyncUtils'
import HomeController from '../../start/controllers/HomeController'
import BookModel from "../models/BookModel"
import useTheme from "../../lava-components/components/hooks/useTheme"
import NewGameCard from "../../lava-components/components/molecules/NewGameCard/NewGameCard"


type Props = {
    style?: ViewStyle
    homeController: HomeController
    rows: number
    onDebugTapped: () => void
    disabled?: boolean
    type: 'compact'|'regular'
}

export const LibraryWidget = observer((props: Props) => {
    const currentAccount = useContext(CurrentAccountContext)
    const appController = useContext(AppControllerContext)
    const [controller] = useState<LibraryWidgetController>(() => new LibraryWidgetController(currentAccount, appController))
    const [layout, setLayout] = useState({x: 0, y: 0, width: 0, height: 0})
    const theme = useTheme()

    const scrollView = useRef<ScrollView>(null)


    useEffect(() => {
        const cleanupAppStateReaction = reaction(() => appController.nativeState.state, async(state) => {
            if (state == NativeState.FOREGROUND) {
                scrollToStartingState()
                await controller.refreshBooks()
                await scrollToTop()
            }
        });

        async function init() {
            scrollToStartingState()
            await controller.initialize()
            await scrollToTop()
        }
        void init()

        return () => {
            controller.uninitialize()
            cleanupAppStateReaction()
        }
    }, [])

    const scrollToTop = async() => {
        await AsyncUtils.sleep(200)
        scrollView.current?.scrollTo({ x: 0, y: 0, animated: true })
    }

    const scrollToStartingState = () => {
        scrollView.current?.scrollTo({ x: 200, y: 0, animated: false })
    }

    const renderFlatList = () => {
        return (
            <FlatList
                style={{flex: 1}}
                horizontal={true}
                showsHorizontalScrollIndicator={false}
                data={controller.books}
                renderItem={(info: ListRenderItemInfo<BookModel>) => {
                    const book = info.item
                    return (
                        <NewGameCard
                            key={`book-${book.id}`}
                            placement={'topic'}
                            containerStyle={{marginEnd: theme.spacing.xxl}}
                            name={book.title}
                            imageUrl={book.image_url}
                            variation={'active'}
                            onPress={() => props.homeController.openBook(info.item, 'tapped-home', info.index)}
                        />
                    )
                }}/>
        )
    }

    return (
        <Widget type={props.type}
                style={[props.style, props.type == 'regular' ? {} : {paddingRight: 0}]} loading={controller.books == undefined}
                disabled={props.disabled}
                onQuadTap={props.onDebugTapped}>
            <View style={{flex: 1, flexDirection: 'row'}} onLayout={(e) => setLayout(e.nativeEvent.layout)}>
                {renderFlatList()}
            </View>
        </Widget>
    )
})

const styles = StyleSheet.create({
})
