import {SpreadDetailController} from "../../controllers/roblox_games_widget/SpreadDetailController"
import {observer} from "mobx-react"
import {FlatList, LogBox, ScrollView, Text, View, ViewabilityConfigCallbackPair} from "react-native"
import React, {useContext, useEffect, useLayoutEffect, useRef, useState} from "react"
import {
    CodeViewModel,
    SpreadDetailContentController,
    SecretViewModel
} from "../../controllers/roblox_games_widget/SpreadDetailContentController"
import CurrentAccountContext from "../../../vizz_account/lib/CurrentAccount"
import Title from "../../../lava-components/components/atoms/Title/Title"
import useTheme from "../../../lava-components/components/hooks/useTheme"
import {ActivityIndicator, TouchableRipple} from "react-native-paper"
import Button from "../../../lava-components/components/atoms/Button/Button"
import {GameBookModel} from "../../models/GameBookModel"
import {AnimationUtils} from "../../../../app/utils/AnimationUtils"
import {SpreadDetailContentSection} from "./SpreadDetailContentSection"
import {Color, isLandscape, isPortrait, isTablet} from "../../../../app/lib/Appearance"
import {SpreadDetailSidePanelState, robloxGameStyles} from "./SpreadDetailConstants"
import {SecretVideo} from "../roblox_games_widget/SecretVideo"

// TODO: This seems like a harmless warning as per: https://github.com/mobxjs/mobx/issues/2913
LogBox.ignoreLogs(['[mobx] Out of bounds read'])

type Props = {
    spreadDetailController: SpreadDetailController
    collapseSidePanel?: () => void
    sidePanelState: SpreadDetailSidePanelState
    secretVideoPlayable?: boolean
    codesVideoPlayable?: boolean
    scrollMobileToEnd?: () => void
}

export const SpreadDetailContent = observer((props: Props) => {

    const currentAccount = useContext(CurrentAccountContext)
    const [controller] = useState(() => new SpreadDetailContentController(currentAccount, props.spreadDetailController))
    const theme = useTheme()

    const [watchingSecretsBook, setWatchingSecretsBook] = useState<GameBookModel | undefined>(undefined)
    const [showVerifiedHeader, setShowVerifiedHeader] = useState(false)
    const [isVerifiedListItemVisible, setVerifiedListItemVisible] = useState(false)

    useEffect(() => {
        void controller.initialize()
        return () => {
            controller.uninitialize()
        }
    }, [])

    useEffect(() => {
        if (!watchingSecretsBook) {
            setShowVerifiedHeader(isVerifiedListItemVisible)
        }
    }, [watchingSecretsBook])

    useLayoutEffect(() => {
        AnimationUtils.configureNext()
    }, [watchingSecretsBook])

    const onViewableSecretsChanged = (info: any) => {
        const verifiedAndVisibleItems = info.viewableItems.filter((i: any) => i.isViewable && i.item.verified)
        const verified = verifiedAndVisibleItems && verifiedAndVisibleItems.length > 0
        setVerifiedListItemVisible(verified)
        setShowVerifiedHeader(verified)
    }

    const secretsViewabilityConfigCallbackPairs = useRef<ViewabilityConfigCallbackPair[]>([
        {
            viewabilityConfig: {
                waitForInteraction: false,
                minimumViewTime: 0,
                viewAreaCoveragePercentThreshold: 1
            },
            onViewableItemsChanged: onViewableSecretsChanged
        },
    ])

    let height = isTablet() ? 200 : 240

    return (
        <ScrollView style={{flex: 1}}>

            {props.spreadDetailController.parentBook.chapters?.map((chapter) => {
                return (
                    <SpreadDetailContentSection
                        style={{flex: 1}}
                        title={chapter.title}
                        showCollapseIcon={watchingSecretsBook != undefined}
                        onTitlePress={() => setWatchingSecretsBook(undefined)}
                        verified={showVerifiedHeader}
                        key={`chapter-${chapter.id}`}
                    >

                        {chapter.book?.pages && chapter.book.pages.length > 0 &&
                            <View style={{overflow: 'visible', display: 'flex', flex: 1}}>
                                <FlatList
                                    style={{height: height, overflow: 'visible'}}
                                    horizontal={true}
                                    showsHorizontalScrollIndicator={false}
                                    data={chapter.book.pages}
                                    onScroll={()=> {}}
                                    renderItem={(info) => {
                                        const page = info.item

                                        const secretViewModel = new SecretViewModel(page, chapter.book as GameBookModel, false, false)

                                        return (
                                            <View style={{overflow: 'visible'}} key={`secret-${chapter.id}-${page.id}`}>
                                                <View style={{flex: 1, flexDirection: 'row'}}>
                                                    <SecretVideo
                                                        secretView={secretViewModel}
                                                        playable={false}
                                                        stopPlaying={false}
                                                        videoPreRollSource={undefined}
                                                        onSecretSelected={() => props.spreadDetailController.playVideo(page.video)}
                                                        featured={true}
                                                    />
                                                </View>
                                            </View>
                                        )
                                    }}
                                    viewabilityConfigCallbackPairs={secretsViewabilityConfigCallbackPairs.current}
                                />
                            </View>
                        }
                    </SpreadDetailContentSection>
                )
            })}

        </ScrollView>
    )
})
