import React, {useEffect, useRef} from 'react'
import {SafeAreaView, ViewStyle, StyleSheet, View, Dimensions} from 'react-native'
import {isPortrait, isTablet, Style} from '../../../../app/lib/Appearance'

type Props = React.PropsWithChildren<{
    style?: ViewStyle
}>


const WidgetContainer = (props: Props) => {
    return (
        <SafeAreaView style={[props.style, {flex: 1}]}>
            {props.children}
        </SafeAreaView>
    )
}

const styles = StyleSheet.create({
    container: {
    },
})

export default WidgetContainer