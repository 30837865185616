import {action, IReactionDisposer, makeObservable, observable, reaction} from "mobx"
import {createContext} from "react"
import {CurrentAccount} from "../../modules/vizz_account/lib/CurrentAccount"
import NativeStateService, {NativeState} from "../services/NativeStateService"
import BackgroundRefreshService from "../services/BackgroundRefreshService"
import {CallService} from "../services/CallService"
import {MessageService} from "../services/MessageService"
import {NotificationService} from "../services/NotificationService"
import VizzAudioService from "../../modules/vizz_maker/services/VizzAudioService"
import {AppUpdateService} from "../services/AppUpdateService.native"
import {AppState} from "./AppControllerState"
import {ManifestUtils} from "../utils/ManifestUtils"
import {LauncherWidgetController} from '../../modules/launcher/controllers/LauncherWidgetController'
import {Platform} from "react-native"
import {ActivityName, TimedActivity} from "../lib/services/TimedActivity"
import {VideoService} from "../services/VideoService"
import LinkingUtils from "../utils/LinkingUtils"
import {HttpError} from "../services/RailsAPIService"
import SentryService from "../services/SentryService"
import {MaintenanceModeError} from "../lib/MaintenanceModeError"
import LavaBotService from "../lib/services/LavaBotService"
import VIForegroundService from '@voximplant/react-native-foreground-service'

// AppState is defined in AppControllerState. We could not put it in here because it's also needed in
// AppUpdateService and that causes a circular dependency.
export class AppController {
    private debug: boolean = false  // don't set this to true in production

    @observable state: AppState = AppState.INITIALIZING
    @observable error?: Error;                              @action public setError(error?: Error) { this.currentAccount?.setAppError(error); this.error = error }

    @observable lastPollFailed: boolean = false;            @action setLastPollFailed(failed: boolean) { this.lastPollFailed = failed }
    @observable justOpenedAppStore: boolean = false;        @action setJustOpenedAppStore(opened: boolean) { this.justOpenedAppStore = opened }

    public currentAccount!: CurrentAccount
    private appUpdate!: AppUpdateService // do not expose app-wide
    public notification!: NotificationService
    public call!: CallService
    public video!: VideoService
    public backgroundRefresh: BackgroundRefreshService
    public message!: MessageService
    private lavaBot!: LavaBotService
    public nativeState: NativeStateService // we considered not exposing this app wide and relying on appController.state. Should we do that?
    public launcherWidgetController: LauncherWidgetController

    private initializeCompleted: boolean = false
    private stateChangeReaction?: IReactionDisposer
    private foregroundBackgroundReaction?: IReactionDisposer
    private monitorServerUpdateNumberReaction?: IReactionDisposer
    private checkForAutoUpdateReaction?: IReactionDisposer
    private restartBackgroundAfterCallReaction?: IReactionDisposer


    constructor() {
        this.consoleDebug(`new()`)

        this.appUpdate = new AppUpdateService(this)
        this.notification = new NotificationService()
        this.call = new CallService()
        this.video = new VideoService()
        this.backgroundRefresh = new BackgroundRefreshService()
        this.message = new MessageService()
        this.lavaBot = new LavaBotService()
        this.launcherWidgetController = new LauncherWidgetController()
        this.nativeState = new NativeStateService()

        makeObservable(this)
    }

    // Public instance methods

    public async initialize(currentAccount: CurrentAccount) {
        TimedActivity.log(ActivityName.APP_INIT, 'app-controller-record-conversion')
        this.consoleDebug(`initialize()`)
        if (this.initializeCompleted) return

        this.currentAccount = currentAccount
        this.currentAccount.analytics.logEvent('app-controller', 'initializing')

        TimedActivity.log(ActivityName.APP_INIT, 'app-controller-config-audio')
        await VizzAudioService.changeToAudioPlaybackMode()

        // Initialize all Services

        TimedActivity.log(ActivityName.APP_INIT, 'app-controller-init-app-update')
        this.appUpdate.initialize(this.currentAccount, (state) => this.setState(state), async() => { await this.uninitialize('restart') })

        TimedActivity.log(ActivityName.APP_INIT, 'app-controller-init-notification')
        await this.notification.initialize(this.currentAccount)

        TimedActivity.log(ActivityName.APP_INIT, 'app-controller-init-call')
        if (this.isNative) await this.call.initialize(this.currentAccount)

        if (this.isNative) {
            this.notification.onAndroidIncomingCallNotification = async () => {
                await this.call.handleAndroidIncomingCall()
            }
        }

        TimedActivity.log(ActivityName.APP_INIT, 'app-controller-init-video')
        if (this.isNative) await this.video.initialize(this.currentAccount)

        TimedActivity.log(ActivityName.APP_INIT, 'app-controller-init-backgroundRefresh')
        if (this.isNative) this.backgroundRefresh.initialize(this, this.currentAccount, this.nativeState)

        TimedActivity.log(ActivityName.APP_INIT, 'app-controller-init-message')
        if (this.isNative) this.message.initialize(this.currentAccount)

        TimedActivity.log(ActivityName.APP_INIT, 'app-controller-init-lavabot')
        if (this.isNative) this.lavaBot.initialize(this.currentAccount)

        TimedActivity.log(ActivityName.APP_INIT, 'app-controller-init-nativeState')
        this.nativeState.initialize() // everything before this will get the NativeState change out of COLD_START

        TimedActivity.log(ActivityName.APP_INIT, 'app-controller-init-launcherWidgetController')
        await this.launcherWidgetController.initialize(this, this.currentAccount) // after so we can await. if we move before nativeState.init then it fires twice

        TimedActivity.log(ActivityName.APP_INIT, 'app-controller-init-reactions')

        this.stateChangeReaction = reaction(() => this.state, (state) => {
            if (state == AppState.LOADED) void this.onLoaded()
        })

        this.foregroundBackgroundReaction = reaction(() => this.nativeState.state, async(newState, oldState) => {
            if (oldState == NativeState.COLD_START) return  // This should never happen since we don't register
                                                            // the reaction until after app is foregrounded, but
                                                            // it's here just in case

            if (newState == NativeState.FOREGROUND) {
                try {
                    await this.onForeground()
                } catch (error) {
                    await this.onLoadError(error as Error)
                    if (this.errorType(error as Error) == 'unknown') SentryService.captureError(error)
                }
            }
            if (newState == NativeState.BACKGROUND) void this.onBackground()
        }) // this is not registered in onLoaded() because the app could initialize from the background so it won't get to LOADED on 1st launch

        this.restartBackgroundAfterCallReaction = reaction(() => this.call.activeCallConnection, (newValue, oldValue) => {
            if (oldValue && !newValue) {
                void this.backgroundRefresh.call('socialPollCallback')
                void this.backgroundRefresh.restartBackgrounding()
            }
        })

        if (Platform.OS == 'android') {
            await this.helperToInitializeAndroidForegroundService()
            await this.helperToStartAndroidForegroundService()
        }

        this.initializeCompleted = true
    }

    public async uninitialize(mode?: string) {
        this.consoleDebug(`uninitialize()`)

        this.initializeCompleted = false

        try {
            if (this.currentAccount) await this.currentAccount.analytics.asyncLogEvent('app', mode ?? 'unloading') // it's more important to log this than anything else that follows so we await
        } catch {}

        if (this.appUpdate) this.appUpdate.uninitialize()
        if (this.notification) this.notification.uninitialize()
        if (this.call) this.call.uninitialize()
        if (this.video) this.video.uninitialize()
        if (this.backgroundRefresh) this.backgroundRefresh.uninitialize()
        if (this.message) await this.message.uninitialize()
        if (this.nativeState) this.nativeState.uninitialize()

        if (this.stateChangeReaction) this.stateChangeReaction()
        if (this.foregroundBackgroundReaction) this.foregroundBackgroundReaction()
        if (this.monitorServerUpdateNumberReaction) this.monitorServerUpdateNumberReaction()
        if (this.checkForAutoUpdateReaction) this.checkForAutoUpdateReaction()
        if (this.restartBackgroundAfterCallReaction) this.restartBackgroundAfterCallReaction()

        if (Platform.OS == 'android') {
            await this.helperToStopAndroidForegroundService()
        }
    }

    public async onColdStart() {
        this.consoleDebug(`onColdStart()`)

        if (this.nativeState.state == NativeState.FOREGROUND) {
            await this.onForeground()
            //void this.currentAccount.analytics.recordInstallConversion() // needs to be after the first call to analytics.logAppOpened (within onForeground)
        }
        if (this.nativeState.state == NativeState.BACKGROUND) console.log() // do nothing. App will sit in .LOADING and social/HomeController will no longer initialize in the background
    }

    public async onForeground() {
        this.consoleDebug(`onForeground()`)

        try {
            this.setError(undefined)

            if (this.state == AppState.LOADED) this.setState(AppState.SUSPENDED)

            const response = await this.currentAccount.api.get('server_update_number')    // do this *before* logAppOpened() so that app-opened event can include serverUpdateNumber
            if (response?.device_and_person == false) throw new Error('Unable to initialize your account.')
            await this.currentAccount.analytics.logAppOpened(this.nativeState.rawState)          // some personData gets set which methods like recordInstallConversion depend on
            void this.lavaBot.greet()
            this.video.stopPlayingVideo()                               // Just in case another destructor missed clearing the video
            if (this.currentAccount.personData.throwExceptionOnAppForeground) throw Error('throwExceptionOnAppForeground')
            await this.appUpdate.tryToUpdate()
        } catch (error) {
            const isUnauthorized = (error instanceof HttpError) && error.status == "unauthorized"

            if (isUnauthorized) {
                SentryService.captureError(error)
                error = new Error('Unable to initialize your account.')
            }
            throw error
        }
    }

    public async onLoadError(error: Error) {
        this.consoleDebug(`onLoadError(${error.message})`)

        if (this.currentAccount) {
            try {
                this.currentAccount.analytics.logEvent('app', 'load_error', undefined, {
                    error: error.message
                })
            } catch {}
        }
        this.setError(error)
        this.setState(AppState.INIT_ERROR)
        if (!this.internetConnectionError(error as Error)) void this.appUpdate.emergencyUpdate()
    }

    public internetConnectionError(error?: Error) {
        if (!error || !error?.stack || !error.message) return false // https://tinyurl.com/2ecf9722
        error = error as Error

        const msg = error.message
        if (msg.includes('Network request failed') ||
            msg.includes('The request timed out') ||
            msg.includes('The Internet connection appears to be offline') ||
            msg.includes('Network Error')
        ) {
            return true
        }

        return false
    }

    public errorType(error?: Error) {
        if (!error) return 'unknown'

        if (this.internetConnectionError(error as Error)) {
            return 'internet'
        } else if (error instanceof MaintenanceModeError) {
            return 'maintenance'
        } else if ((error as Error).message.includes('Unable to initialize')) {
            return 'account'
        } else {
            return 'unknown'
        }
    }

    public async onLoaded() { // Must be idempotent. Even after the app state is LOADED it can move to another state and back to LOADED
        this.consoleDebug(`onLoaded()`)

        if (!this.monitorServerUpdateNumberReaction) {
            this.monitorServerUpdateNumberReaction = reaction(() => this.currentAccount.serverUpdateNumber, async(newUpdateNumber, oldUpdateNumber) => {
                if (this.nativeState.state != NativeState.FOREGROUND || this.state != AppState.LOADED) return

                this.currentAccount.analytics.logEvent('update', 'server_triggered', undefined, {
                    server_update_number: this.currentAccount.serverUpdateNumber,
                    new_update_number: newUpdateNumber,
                    old_update_number: oldUpdateNumber,
                    server_check_for_auto_update: this.currentAccount.serverCheckForAutoUpdate,
                    client_check_for_auto_update: this.currentAccount.clientCheckForAutoUpdate,
                })

                await this.appUpdate.tryToUpdate()
            })
        }

        if (!this.checkForAutoUpdateReaction) {
            this.checkForAutoUpdateReaction = reaction(() => this.currentAccount.serverCheckForAutoUpdate, async(reaction) => {
                if (this.nativeState.state != NativeState.FOREGROUND || this.state != AppState.LOADED) return

                this.currentAccount.analytics.logEvent('update', 'server_triggered', undefined, {
                    server_update_number: this.currentAccount.serverUpdateNumber,
                    server_check_for_auto_update: this.currentAccount.serverCheckForAutoUpdate,
                })

                await this.appUpdate.tryToUpdate()
            })
        }
    }

    public async onBackground() {
        this.consoleDebug(`onBackground()`)

        this.currentAccount.personData.failureCount = 0 // We want this to counter to be unaffected when restartApp() and
                                                        // in testing the app does not background when it restarts, however
                                                        // it does re-foreground. That's why this is here in background.
        this.currentAccount.setClientCheckForAutoUpdate(true)
        this.video.stopPlayingVideo()                   // Just in case another destructor missed clearing the video
        this.currentAccount.analytics.logEvent('app', 'closed')
    }

    public async retryUpdate() {
        await this.currentAccount.api.get('server_update_number') // this is a dummy API call just-in-case no other API calls happened
        await this.appUpdate.tryToUpdate()
    }

    public openAppStore() {
        this.setJustOpenedAppStore(true)
        if(Platform.OS === 'ios') {
            void LinkingUtils.safeOpenURL('https://apps.apple.com/app/id1556311563')
        } else if (Platform.OS === 'android'){
            void LinkingUtils.safeOpenURL('http://play.google.com/store/apps/details?id=com.explanation.lava')
        }
    }

    public async skipAndDisableUpdate() {
        this.setJustOpenedAppStore(false)
        this.currentAccount.analytics.logEvent('update', 'skip', ManifestUtils.clientUpdateNumber)
        this.currentAccount.setClientCheckForAutoUpdate(false)
        await this.appUpdate.tryToUpdate()
    }

    @action
    public setState(state: AppState) {
        this.consoleDebug(`setState(${state})`)
        this.state = state
    }


    // Private instance utility methods

    private get isNative() {
        return Platform.OS != 'web'
    }

    // ANDROID FOREGROUND SERVICE PRIVATE HELPERS

    private async helperToInitializeAndroidForegroundService() {
        this.consoleDebug('helperToInitializeAndroidForegroundService')

        const channelConfig = {
            id: 'foregroundServiceChannelId',
            name: 'Background Processing',
            description: 'Lets you know when Lava is running in the background for things like calls and status updates',
            enableVibration: false,
            importance: 2
        }

        try {
            await VIForegroundService.getInstance().createNotificationChannel(channelConfig)
        } catch (error) {
            SentryService.captureError(error)
        }
    }

    private async helperToStartAndroidForegroundService() {
        this.consoleDebug('helperToStartAndroidForegroundService')

        // If we try to start the foreground service while the app is
        // not int he foreground, we get a hard crash
        if (this.nativeState.state !== 'foreground') {
            this.consoleDebug('App not in foreground, not starting foreground service')
            return
        }

        const notificationConfig = {
            channelId: 'foregroundServiceChannelId',
            id: 1234,
            title: `Running in background`,
            text: 'Lava is now running in the background',
            icon: 'ic_launcher_round',
            priority: 0,
        }
        try {
            await VIForegroundService.getInstance().startService(notificationConfig)
        } catch (e) {
            SentryService.captureError(e)
        }
    }

    private async helperToStopAndroidForegroundService() {
        this.consoleDebug('helperToStopAndroidForegroundService')
        try {
            await VIForegroundService.getInstance().stopService();
        } catch (error) {
            SentryService.captureError(error)
        }
    }

    private consoleDebug(method: string, force: boolean = false) {
        if(this.debug || force) console.log(`${this.constructor.name}: ${method}  state = ${this.state}`)
    }
}

const AppControllerContext = createContext<AppController>(new AppController())
export default AppControllerContext
