import React, {useContext, useEffect, useRef, useState} from 'react'
import {Keyboard, StyleSheet, Text, View, ViewStyle} from "react-native"
import {ActivityIndicator, TextInput} from "react-native-paper"
import {observer} from "mobx-react"
import {SearchForFriendsController, SearchForFriendsState} from "../controllers/SearchForFriendsController"
import {Color} from "../../app/lib/Appearance"
import CurrentAccountContext from "../../modules/vizz_account/lib/CurrentAccount"
import AppControllerContext from '../../app/controllers/AppController'
import {PrimaryButton} from '../../app/views/components/PrimaryButton'
import {SpeechUtils} from '../../app/utils/AVUtils'
import {ProfileModel} from '../../modules/social/models/ProfileModel'
import {FriendWidgetController} from '../../modules/social/controllers/FriendWidgetController'
import {ProfileStatus} from '../../modules/social/models/ProfileStatus'
import {SearchForFriendsItem} from './SearchForFriendsItem'
import {ScrollView} from 'react-native-gesture-handler'
import FriendDetails from '../../modules/social/views/friend_widget/FriendDetails'

type Props = {
    style?: ViewStyle
    onFinish: (friendInvited?: boolean) => void
    onProfileTapped?: (profile: ProfileModel) => void
    showDoneButton?: boolean
    showRobloxFriends?: boolean
    friendWidgetController?: FriendWidgetController
}

export const SearchForFriends = observer((props: Props) => {
    const currentAccount = useContext(CurrentAccountContext)
    const appController = useContext(AppControllerContext)
    const [controller] = useState<SearchForFriendsController>(() => new SearchForFriendsController(currentAccount, appController, props.onFinish))
    const [friendUsername, setFriendUsername] = useState('')
    const [error, setError] = useState<string>('')
    const [showHelper, setShowHelper] = useState<boolean>(false)
    const inputRef = useRef<any>()
    const isMountedRef = useRef(false)
    const [speech] = useState(() => new SpeechUtils(currentAccount, true))
    const { onProfileTapped } = props

    useEffect(() => {
        isMountedRef.current = true

        void controller.initialize()

        return () => {
            isMountedRef.current = false
            speech.unload()
            controller.uninitialize()
        }
    }, [])

    const renderLoading = () => {
        return (
            <View style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}>
                <ActivityIndicator color={Color.primary} />
            </View>
        )
    }


    const searchForFriend = async() => {
        Keyboard.dismiss()

        let message = await controller.searchForFriend(friendUsername)

        if (!isMountedRef.current) return
        setError(message)
        setShowHelper(true)
    }

    return (
        <ScrollView style={{flex: 1}} showsVerticalScrollIndicator={false}>
            <View style={[styles.container, props.style]}>
                {controller.state == SearchForFriendsState.LOADING && renderLoading()}
                {controller.state == SearchForFriendsState.LOADED &&
                <View style={{flexDirection: 'column', flexGrow: 1}}>
                    <Text style={styles.subheaderLabel}>Enter friend's first name, Lava name or Roblox username</Text>

                    <TextInput
                        ref={inputRef}
                        autoCapitalize={'none'}
                        style={styles.inputField}
                        mode={'flat'}
                        returnKeyType={'search'}
                        label="Friend's Name"
                        value={friendUsername}
                        onChangeText={text => { setFriendUsername(text); controller.setQueryChanged(true) }}
                        onSubmitEditing={searchForFriend}
                    />

                    <View style={{flexDirection: 'row', marginTop: 24}}>
                        <View style={{flex: 1, flexDirection: 'row'}}>
                            <PrimaryButton
                                disabled={friendUsername.length == 0}
                                title={'Search'}
                                resetWhen={controller.queryChanged}
                                onPress={() => { void searchForFriend(); controller.setQueryChanged(false) }}/>
                        </View>
                        {props.showDoneButton &&
                        <View style={{flex: 1, flexDirection: 'row-reverse'}}>
                            <PrimaryButton
                                onPress={() => props.onFinish()}
                                disabled={friendUsername.length > 0 && !showHelper}
                                iconType={'FontAwesome'}
                                icon={'arrow-right'}
                                iconSide={'right'}
                                title={'Skip'}
                            />
                        </View>}
                    </View>

                    {controller.requestedFriendName.length === 0 &&
                    <View style={{flexDirection: 'column', flex: 1}}>

                        {error != '' &&
                        <View style={{borderBottomWidth: 1, borderColor: 'white', paddingBottom: 8, marginTop: 30}}>
                            <Text style={[styles.subheaderLabel, {fontWeight: '800'}]}>
                                {error}
                            </Text>
                        </View>}

                        {/* School Friends Results */}
                        {controller.friendSearchResults.length === 0 && friendUsername.length === 0 && controller.schoolFriends.length > 0 &&
                            <View>
                                { controller.school?.name &&
                                    <View style={{borderBottomWidth: 1, borderColor: 'white', paddingBottom: 8, marginTop: 30, marginBottom: 10}}>
                                        <Text style={[styles.subheaderLabel, {fontWeight: '800'}]}>
                                            {controller.school.name} Students
                                        </Text>
                                    </View>
                                }

                                <View style={{flexDirection: 'column', flex: 1}}>
                                    { controller.schoolFriends.map((data) => (
                                        <SearchForFriendsItem key={data.id} speech={speech} controller={controller} profile={data} onProfileTapped={onProfileTapped} />
                                    ))}
                                </View>
                            </View>
                        }

                        {/* Search Results */}
                        {controller.friendSearchResults.map((data) => (
                            <SearchForFriendsItem key={data.id} speech={speech} controller={controller} profile={data} onProfileTapped={onProfileTapped} />
                        ))}

                        {friendUsername.length > 0 && showHelper && <View style={{marginTop: 20}}>
                            <Text style={{color: 'white'}}>
                                <Text style={{fontWeight: 'bold'}}>Don't see your friend?</Text> Tell them to install Lava and find you.
                            </Text>
                        </View>}
                    </View>}

                </View>
                }
            </View>

            {controller.requestedFriendName.length > 0 &&
                <View style={{flex: 1, alignItems: 'center', justifyContent: 'center'}}>
                    <View style={{backgroundColor: 'black', alignItems: 'center', justifyContent: 'center', borderColor: "darkgray", borderWidth: 0.5, maxWidth: 500}}>
                        <Text style={[styles.headerLabel, {marginVertical: 10, fontSize: 28}]}>Friend request sent</Text>
                        <Text style={styles.subheaderLabel}>You'll get a notification when {controller.requestedFriendName} accepts.</Text>
                        <View style={{alignItems: "center", justifyContent: "center", marginTop: 20, marginBottom: 10}}>
                            <PrimaryButton onPress={() => {props.onFinish(true)}} title={"Done"}></PrimaryButton>
                        </View>
                    </View>
                </View>
            }

            {/* Roblox Friends Results */}
            { friendUsername.length == 0 && controller.friendSearchResults.length === 0 && props.showRobloxFriends && Boolean(controller.robloxFriends.length) &&
                <View style={{flexDirection: 'column', flex: 1}}>
                     <View style={{borderBottomWidth: 1, borderColor: 'white', paddingBottom: 8, marginTop: 30, marginBottom: 10}}>
                        <Text style={[styles.subheaderLabel, {fontWeight: '800'}]}>Roblox Friends ({controller.robloxFriends.length})</Text>
                    </View>
                    {controller.robloxFriends?.map((data) => (
                        <SearchForFriendsItem
                            key={data.id}
                            speech={speech}
                            controller={controller}
                            profile={data.roblox_profile ? {
                                id: data.roblox_profile.id,
                                person_id: data.roblox_profile.user_id,
                                person_key: data.key,
                                pretty_username: data.roblox_profile.name,
                                created_at: data.created_at,
                                updated_at: data.updated_at,
                                status: ProfileStatus.OFFLINE,
                                person: data,
                                first_name: null,
                                nickname: data.roblox_profile.name,
                                username: data.roblox_profile.name,
                            } : data.profile!}
                            onProfileTapped={onProfileTapped}
                        />
                    ))}
                </View>
            }
        </ScrollView>
    )
})

const styles = StyleSheet.create({
    container: {
        flex: 1
    },
    headerLabel: {
        color: 'white',
        fontSize: 36
    },
    subheaderLabel: {
        color: 'white',
        fontSize: 16,
        fontWeight: '400',
    },
    inputField: {
        marginTop: 24,
        borderRadius: 5,
    },
    searchResultText: {
        flex: 1,
        color: "white",
        margin: 5
    }
})
