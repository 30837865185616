import React, {forwardRef, useEffect, useImperativeHandle, useRef, useState} from 'react'
import {observer} from "mobx-react"
import {Animated, Dimensions, Easing, StyleSheet, View, ViewStyle} from "react-native"

type Props = React.PropsWithChildren<{
    active: boolean,
    onWillAppear?: () => void,
    onDidDisappear?: () => void,
    style?: ViewStyle,
    animation?: 'slide' | 'scale'
}>

export const LavaModal = observer(forwardRef((props: Props, ref) => {
    const {active, onWillAppear, onDidDisappear, style, children, animation = 'slide'} = props
    const {height, width} = Dimensions.get('window')
    const positionY = useRef(new Animated.Value(0)).current
    const scaleAnim = useRef(new Animated.Value(1)).current
    const scaleAnimX = useRef(new Animated.Value(0)).current
    const scaleAnimY = useRef(new Animated.Value(0)).current
    const [internalActive, setInternalActive] = useState(false)

    useEffect(() => {
        if (active) {
            positionY.setValue(0)
            scaleAnim.setValue(1)
            scaleAnimX.setValue(0)
            scaleAnimY.setValue(0)
        } else {
            positionY.setValue(height)
            scaleAnim.setValue(0)
            scaleAnimX.setValue(width)
            scaleAnimY.setValue(height)
        }

    }, [animation])

    useEffect(() => {
        if (active) {
            positionY.setValue(height)
            scaleAnim.setValue(0)
            scaleAnimX.setValue(width)
            scaleAnimY.setValue(height)

            setInternalActive(true)

            const animation = props.animation ?? 'slide'
            switch (animation) {
                case 'slide': {
                    Animated.timing(positionY, {
                        toValue: 0,
                        duration: 300,
                        easing: Easing.out(Easing.ease),
                        useNativeDriver: true,
                    }).start(onFinishActivateAnimation)
                    break
                }
                case 'scale': {
                    const duration = 300
                    Animated.parallel([
                        Animated.timing(scaleAnim, {
                            toValue: 1,
                            duration: duration,
                            easing: Easing.out(Easing.ease),
                            useNativeDriver: true,
                        }),
                        Animated.timing(scaleAnimX, {
                            toValue: 0,
                            duration: duration,
                            easing: Easing.out(Easing.ease),
                            useNativeDriver: true,
                        }),
                        Animated.timing(scaleAnimY, {
                            toValue: 0,
                            duration: duration,
                            easing: Easing.out(Easing.ease),
                            useNativeDriver: true,
                        })
                    ]).start(onFinishActivateAnimation)
                    break
                }
            }
        }
    }, [active])

    const onFinishActivateAnimation = () => {
        if (onWillAppear) onWillAppear()
    }

    useImperativeHandle(ref, () => ({
        dismiss: () => {
            const animation = props.animation ?? 'slide'
            switch (animation) {
                case 'slide': {
                    Animated.timing(positionY, {
                        toValue: height,
                        duration: 300,
                        easing: Easing.in(Easing.ease),
                        useNativeDriver: true,
                    }).start(onFinishDeactivate)
                    break
                }
                case 'scale': {
                    const duration = 300
                    Animated.parallel([
                        Animated.timing(scaleAnim, {
                            toValue: 0,
                            duration: duration,
                            easing: Easing.in(Easing.ease),
                            useNativeDriver: true,
                        }),
                        Animated.timing(scaleAnimX, {
                            toValue: width,
                            duration: duration,
                            easing: Easing.in(Easing.ease),
                            useNativeDriver: true,
                        }),
                        Animated.timing(scaleAnimY, {
                            toValue: height,
                            duration: duration,
                            easing: Easing.in(Easing.ease),
                            useNativeDriver: true,
                        })
                    ]).start(onFinishDeactivate)
                    break
                }
            }
        }
    }))

    const onFinishDeactivate = (result: Animated.EndResult) => {
        if (result.finished) {
            if (onDidDisappear) onDidDisappear()
            setInternalActive(false)
        }
    }

    if (!active && !internalActive) return null

    const animatedStyle = animation === 'scale' ? {
        transform: [
            {scale: scaleAnim},
            {translateX: scaleAnimX},
            {translateY: scaleAnimY}
        ],
    } : {
        transform: [{translateY: positionY}],
    }

    return (
        <Animated.View style={[
            {flex: 1, zIndex: 1000, ...StyleSheet.absoluteFillObject, ...animatedStyle},
        ]}>
            <View style={{flex: 1, ...style}}>
                {children}
            </View>
        </Animated.View>
    )
}))
