import React, {useState} from 'react'
import {LayoutAnimation, LayoutChangeEvent, View} from "react-native";

type Props = React.PropsWithChildren<{aspectRatio: number}>;

const FixedAspectView = (props: Props) => {

    const [height, setHeight] = useState<number>(0)

    const onLayout = (event: LayoutChangeEvent) => {
        const newHeight = event.nativeEvent.layout.width * props.aspectRatio
        //LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut)
        setHeight(newHeight)
    }

    return (
        <View style={{height: height}} onLayout={onLayout} >
            {props.children}
        </View>
    )
}

export default FixedAspectView;
