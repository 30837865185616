export default class JSONHelpers {

    static isJSON(str: string | null): boolean {
        if (typeof str !== 'string' || str == null) return false
        try {
            const result = JSON.parse(str)
            const type = Object.prototype.toString.call(result)
            return type === '[object Object]'
                || type === '[object Array]'
        } catch (err) {
            return false
        }
    }
}
