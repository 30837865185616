import {action, computed, makeObservable, observable, reaction} from "mobx"
import {CurrentAccount} from "../../vizz_account/lib/CurrentAccount"
import HomeController from "../../start/controllers/HomeController"
import {NativeState} from "../../../app/services/NativeStateService"
import SentryService from "../../../app/services/SentryService"
import {GameBookModel} from "../models/GameBookModel"
import {create, persist} from "mobx-persist"
import AsyncStorage from "@react-native-async-storage/async-storage"
import {isPortrait} from "../../../app/lib/Appearance"

export interface RobloxGameViewModel {
    game: GameBookModel,
    isRecent: boolean
}

const hydrate = create({
    storage: AsyncStorage,
    jsonify: true,
})

export class RobloxGamesWidgetController {

    private currentAccount: CurrentAccount
    private homeController: HomeController
    @observable @persist('list') gameBooks?: GameBookModel[]
    @action setGameBooks(books: GameBookModel[]) {this.gameBooks = books }

    private cleanUpAppState?: any

    constructor(currentAccount: CurrentAccount, homeController: HomeController) {
        this.currentAccount = currentAccount
        this.homeController = homeController
        makeObservable(this)
    }

    public async initialize() {
        await hydrate('roblox-games-widget', this)

        await this.refresh()

        this.cleanUpAppState = reaction(() => this.homeController.friendWidgetController?.appController?.nativeState.state,
            (state) => {
                if (state == NativeState.FOREGROUND) void this.refresh()
            }
        )
    }

    public async uninitialize() {
        if (this.cleanUpAppState) this.cleanUpAppState()
    }

    public async refresh() {
        try {
            const books = await this.currentAccount.api.get('browse.roblox_concept_books_path') as GameBookModel[]
            this.setGameBooks(books)
        } catch (error) {
            SentryService.captureError(error)
        }
    }

    @computed
    get combinedGameBooks() {
        let gameBooks: RobloxGameViewModel[] = (this.homeController.recentGameBooks?.slice(0,10) ?? [])
            .map((g) => {
                return {game: g, isRecent: true} as RobloxGameViewModel
            })

        // The first time a user sees roblox games, bring a game with codes to the front of the list
        if (this.currentAccount.personData.numberOfDaysOpened <= 1) {
            const firstGameBookWithCodeIndex = gameBooks.findIndex((g) => this.hasCodes(g.game))

            if (firstGameBookWithCodeIndex > 0) {
                const firstGameBookWithCode = gameBooks[firstGameBookWithCodeIndex]
                gameBooks.splice(firstGameBookWithCodeIndex, 1)
                gameBooks.unshift(firstGameBookWithCode)
            }
        }

        const idToGameBook = gameBooks.reduce((acc, vm) => {
            acc[vm.game.id ?? ''] = vm.game
            return acc
        }, {} as any)

        this.gameBooks?.forEach((gameBook) => {
            if (gameBook.id && idToGameBook[gameBook.id] == undefined) {
                gameBooks.push({game: gameBook, isRecent: false})
            }
        })

        return gameBooks
    }

    public hasCodes(gameBook: GameBookModel) {
        return gameBook.active_codes_count > 0
    }
}
