import React, {ForwardedRef, useContext, useEffect, useLayoutEffect, useRef, useState} from 'react'
import {StyleProp, StyleSheet, Text, View, ViewStyle} from "react-native"
import {ActivityIndicator, TouchableRipple} from "react-native-paper"
import {Observer, observer} from "mobx-react"
import {FriendsGridController, FriendsGridState} from "../../../controllers/steps/friends/FriendsGridController"
import {Color, isLandscape, isPortrait} from "../../../../../app/lib/Appearance"
import CurrentAccountContext from "../../../../vizz_account/lib/CurrentAccount"
import {SectionGrid} from 'react-native-super-grid'
import {MaterialCommunityIcons} from "@expo/vector-icons"
import FriendModel from '../../../models/steps/FriendModel'
import {IReactionDisposer, reaction} from 'mobx'
import {LavaImage} from "../../../../../app/views/components/LavaImage"
import {AnimationUtils} from "../../../../../app/utils/AnimationUtils"

type Props = {
    style?: StyleProp<ViewStyle>
    onToggleSelectedFriend?: (friend: FriendModel) => void
    onLoad?: (FriendsGridController: FriendsGridController) => void
    onInteraction?: () => void
    maxSelect?: number
    freezeSelection?: boolean
    collapsed: boolean
    showSections: boolean
}

export const FriendsGrid = observer((props: Props, ref: ForwardedRef<any>) => {
    const currentAccount = useContext(CurrentAccountContext)
    const flatGridRef = useRef<any>(null)
    const [controller] = useState<FriendsGridController>(() => new FriendsGridController(currentAccount, flatGridRef, props.maxSelect))
    const [showedHint, setShowedHint] = useState<boolean>(false)
    const [layout, setLayout] = useState({x: 0, y: 0, width: 0, height: 0})
    let loadedReaction:IReactionDisposer|undefined = undefined

    useEffect(() => {
        void controller.initialize()

        loadedReaction = reaction(() => controller.state, async (state) => {
            if (state == FriendsGridState.LOADED && props.onLoad) props.onLoad(controller)
        })

        return () => {
            controller.uninitialize()
            if (loadedReaction) loadedReaction()
        }
    }, [])

    useLayoutEffect(() => {
        AnimationUtils.configureNext()
    }, [controller.friends, controller.realFriends, props.showSections])

    const scrollHint = () => {
        if (isPortrait() && !showedHint) {
            try {
                // @ts-ignore: typescript isn't recognizing this forwarded ref
                flatGridRef.current?.scrollToIndex({index: 1, viewOffset: 0, animated: true})
            } catch (error) {
                // ignore any erors, this is just a best-effort feature
            }

            setShowedHint(true)
        }
    }

    const renderLoading = () => {
        return (
            <View style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}>
                <ActivityIndicator color={Color.primary} />
            </View>
        )
    }

    const width = () => {
        const columns = isLandscape() ? 4 : 2

        return (layout.width - (15 * (columns+1))) / columns
    }

    const renderSectionGrid = (columns: number, w: number) => {
        const spacing = 15
        return (
            <SectionGrid
                keyboardShouldPersistTaps={'handled'}
                ref={flatGridRef}
                style={{width: layout.width, flexGrow: 0}}
                scrollEnabled={true}
                renderSectionHeader={(section) => section.section.title != 'Real Friends' ?
                    <View style={{height: 20}}/> : null}
                sections={controller.sections(props.showSections)}
                renderItem={(item) => renderGridItem(item.item, width())}
                spacing={spacing}
                maxItemsPerRow={columns}
            />

        )
    }

    const onPress = (friend: FriendModel) => {
        if (!props.onToggleSelectedFriend || props.freezeSelection) return

        scrollHint()
        controller.toggleSelectedFriend(friend)
        props.onToggleSelectedFriend(friend)
    }

    const renderGridItem = (friend: FriendModel, width: number) => {
        const robloxFriend = friend.robloxFriend
        return (
            <Observer>{() =>
                <TouchableRipple
                    key={robloxFriend.id}
                    onPress={() => onPress(friend)}
                    onLongPress={() => onPress(friend)}
                    disabled={!props.onToggleSelectedFriend}
                >
                    <View style={{flex: 1, marginBottom: 10}}>
                        <LavaImage style={{
                            width: width,
                            height: width,
                            borderColor: 'rgba(234,234,223,0.4)',
                            borderWidth: 1,
                            backgroundColor: '#BED5ED60',
                            borderRadius: 20,
                            overflow: 'hidden'}}

                            source={{uri: robloxFriend.avatar_url}}
                            resizeMode={'center'}
                        />

                        <Text style={{fontSize: 16, color: 'white', fontWeight: 'bold', marginTop: 10, overflow: 'hidden'}} numberOfLines={1}>{robloxFriend.display_name}</Text>
                        <Text style={{fontSize: 13, color: 'white', marginTop: 2}} numberOfLines={1}>@{robloxFriend.name}</Text>
                        {friend.isSelected && <View style={{
                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                            justifyContent: 'center',
                            paddingBottom: 50,
                            alignItems: 'center', ...StyleSheet.absoluteFillObject
                        }}>
                            <MaterialCommunityIcons name="check-circle" size={64} color={Color.lavaGray}/>
                        </View>}
                    </View>
                </TouchableRipple>
            }
            </Observer>
        )
    }

    return (
        <View style={[styles.container, props.style, {}]} onLayout={(e) => setLayout(e.nativeEvent.layout)}>
            {controller.state == FriendsGridState.LOADING && renderLoading()}
            {controller.state == FriendsGridState.LOADED &&
            <View onTouchStart={() => props.onInteraction && props.onInteraction()}>
                {isLandscape() ? renderSectionGrid(4, 150) : renderSectionGrid(2, 135)}
            </View>
            }
        </View>
    )
})

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
})
