/* eslint-disable */
/* There are some legit issues in this file which should be addressed. We are violating the rule-of-hooks in ways that are not good. */

import React, {
    forwardRef,
    MutableRefObject,
    useCallback,
    useEffect,
    useImperativeHandle,
    useMemo,
    useRef,
    useState
} from "react"
import {Platform, StyleSheet, View} from "react-native"
import {EventEmitter} from "events"
import {
    CUSTOM_USER_AGENT,
    onGetError,
    onGetStateName,
    PLAYER_STATE_ENDED_INDEX,
    PLAYER_STATE_PAUSED_INDEX,
    PLAYER_STATE_PLAYING_INDEX,
    VIDEO_PLAYER_TOUCH_ACTION_EVENT
} from "./YoutubeIframe/constants"
import {MAIN_SCRIPT, PLAYER_FUNCTIONS, playMode, soundMode} from "./YoutubeIframe/PlayerScripts"
import {deepComparePlayList} from "./YoutubeIframe/utils"
import {useHover} from "react-native-web-hooks"
import DisableControlOverlayView from "./YoutubeIframe/DisableControlOverlayView"
import SeekOverlayView from "./YoutubeIframe/SeekOverlayView"
import WebView from "./YoutubeIframe/WebView"

//Needs Refactoring, expo-handle-touch-view no longer exists. Advise to use TapGestureHandler to handle original intent.
let HandleTouchView: any

HandleTouchView = View



const YoutubeIframe = (props: any, ref: any) => {
    const {
        height,
        width,
        videoId,
        playList,
        play = false,
        mute = false,
        volume = 100,
        webViewStyle,
        webViewProps,
        useLocalHTML,
        baseUrlOverride,
        playbackRate = 1,
        contentScale = 1.0,
        onUpdateVisibilityPauseOverlay = (visible: boolean) => {},
        onError = (_err: any) => {},
        onReady = (_event: any) => {},
        onPlayerPlayed = () => {},
        onCurrentTouchAction = (actionId: any) => {},
        playListStartIndex = 0,
        initialPlayerParams,
        allowWebViewZoom = false,
        forceAndroidAutoplay = false,
        onChangeState = (_event: any) => {},
        onFullScreenChange = (_status: any) => {},
        onPlaybackQualityChange = (_quality: any) => {},
        onPlaybackRateChange = (_playbackRate: any) => {},
        setIsPlaying = (state: boolean) => {},
    } = props
    const lastTap = useRef<number | null>(null) as MutableRefObject<number | null>;;
    const [playerReady, setPlayerReady] = useState(false)
    const [playerOverlayVisible, setplayerOverlayVisible] = useState(false)
    const lastVideoIdRef = useRef(videoId)
    const lastPlayListRef = useRef(playList)
    const initialPlayerParamsRef = useRef(initialPlayerParams || {})
    const isFirstTimePlay = useRef(false)
    const videoDurationRef = useRef<number>(0)
    const touchMoveTimeRef = useRef<number>(0)

    const webViewRef = useRef<any | null>(null)
    const seekOverlayViewRef = useRef<any>(null)
    const eventEmitter = useRef(new EventEmitter())

    const isStartTouchRef = useRef(false)
    const isMoveTouchRef = useRef(false)
    const isEndTouchRef = useRef(false)
    const timeStartMoveRef = useRef(0)
    const currentTimeOutDurationRef = useRef(0)
    const timeoutRef = useRef<any>(null)
    const didReceiveResponse = useRef(false)

    useImperativeHandle(
        ref,
        () => ({
            getVideoUrl: () => {
                injectJavaScript(PLAYER_FUNCTIONS.getVideoUrlScript)
                return new Promise((resolve) => {
                    eventEmitter.current.once("getVideoUrl", resolve)
                })
            },
            getDuration: () => {
                injectJavaScript(PLAYER_FUNCTIONS.durationScript)
                return new Promise((resolve) => {
                    eventEmitter.current.once("getDuration", resolve)
                })
            },
            getCurrentTime: () => {
                injectJavaScript(PLAYER_FUNCTIONS.currentTimeScript)
                return new Promise((resolve) => {
                    eventEmitter.current.once("getCurrentTime", resolve)
                })
            },
            isMuted: () => {
                injectJavaScript(PLAYER_FUNCTIONS.isMutedScript)
                return new Promise((resolve) => {
                    eventEmitter.current.once("isMuted", resolve)
                })
            },
            getVolume: () => {
                injectJavaScript(PLAYER_FUNCTIONS.getVolumeScript)
                return new Promise((resolve) => {
                    eventEmitter.current.once("getVolume", resolve)
                })
            },
            getPlaybackRate: () => {
                injectJavaScript(PLAYER_FUNCTIONS.getPlaybackRateScript)
                return new Promise((resolve) => {
                    eventEmitter.current.once("getPlaybackRate", resolve)
                })
            },
            getAvailablePlaybackRates: () => {
                injectJavaScript(PLAYER_FUNCTIONS.getAvailablePlaybackRatesScript)
                return new Promise((resolve) => {
                    eventEmitter.current.once("getAvailablePlaybackRates", resolve)
                })
            },
            seekTo: (seconds: number, allowSeekAhead: boolean) => {
                injectJavaScript(
                    PLAYER_FUNCTIONS.seekToScript(seconds, allowSeekAhead)
                )
            },
            pausePlayer: () => {
                injectJavaScript(PLAYER_FUNCTIONS.pauseVideo)
            },
            playPlayer: () => {
                injectJavaScript(PLAYER_FUNCTIONS.playVideo)
                if (!isFirstTimePlay.current) {
                    setplayerOverlayVisible(true)
                }
            },
        }),
        []
    )

    const injectJavaScript = (command: string) => {
        if (Platform.OS === "web") {
            webViewRef?.current?.frameRef.contentWindow.eval(command)
        } else {
            webViewRef?.current?.injectJavaScript(command)
        }
    }

    useEffect(() => {
        if (!playerReady) {
            // no instance of player is ready
            return
        }
        const playVideoScripts = [
            play ? playMode.PLAY_MODE : playMode.PAUSE_MODE,
            mute ? soundMode.MUTE_MODE : soundMode.UNMUTE_MODE,
            PLAYER_FUNCTIONS.setVolume(volume),
            PLAYER_FUNCTIONS.setPlaybackRate(playbackRate),
        ]
        playVideoScripts.forEach((ele) => {
            injectJavaScript(ele)
        })
    }, [play, mute, volume, playbackRate, playerReady])

    useEffect(() => {
        if (!playerReady || lastVideoIdRef.current === videoId) {
            // no instance of player is ready
            // or videoId has not changed
            return
        }

        lastVideoIdRef.current = videoId

        injectJavaScript(PLAYER_FUNCTIONS.loadVideoById(videoId, play))
    }, [videoId, play, playerReady])

    useEffect(() => {
        if (!playerReady) {
            // no instance of player is ready
            return
        }

        // Also, right now, we are helping users by doing "deep" comparisons of playList prop,
        // but in the next major we should leave the responsibility to user (either via useMemo or moving the array outside)
        if (!playList || deepComparePlayList(lastPlayListRef.current, playList)) {
            return
        }

        lastPlayListRef.current = playList

        injectJavaScript(
            PLAYER_FUNCTIONS.loadPlaylist(playList, playListStartIndex, play)
        )
    }, [playList, play, playListStartIndex, playerReady])

    const onWebMessage = useCallback(
        (event: any) => {
            try {
                const message = JSON.parse(event.nativeEvent?.data || "")

                if (!message.eventType) {
                    return
                }
                const dataNumber: number = typeof message.data === 'number' ? message.data : -1
                const dataObj: any = message.data
                switch (message.eventType) {
                    case "fullScreenChange":
                        onFullScreenChange(message.data)
                        break
                    case "seekClick":
                        if (isFirstTimePlay.current) {
                            if (dataObj?.side === "left") {
                                void onSeek(0)
                            }
                            else if (dataObj?.side === "right") {
                                void onSeek(1)
                            }
                        }
                        break
                    case "playerStateChange":
                        if (!isFirstTimePlay.current && dataNumber == PLAYER_STATE_PLAYING_INDEX) {
                            onPlayerPlayed?.()
                            showPlayerOverlay(2750)
                            isFirstTimePlay.current = true
                            void updateVideoDuration()
                        }
                        if (dataNumber == PLAYER_STATE_PLAYING_INDEX) {}
                        else if (dataNumber === PLAYER_STATE_PAUSED_INDEX) {
                            if (isMoveTouchRef.current) {
                                onUpdateVisibilityPauseOverlay?.(false)
                            } else {
                                onUpdateVisibilityPauseOverlay?.(true)
                            }
                        } else if (dataNumber === PLAYER_STATE_ENDED_INDEX) {
                            //injectJavaScript(PLAYER_FUNCTIONS.pauseVideo)
                            //injectJavaScript(PLAYER_FUNCTIONS.seekToScript(0, true))
                            //isFirstTimePlay.current = false
                            //onUpdateVisibilityPauseOverlay?.(true)
                        }
                        onChangeState(onGetStateName(dataNumber), isMoveTouchRef.current && !isEndTouchRef.current)
                        break
                    case "playerReady":
                        onReady()
                        setPlayerReady(true)
                        onUpdateVisibilityPauseOverlay?.(true)
                        break
                    case "playerQualityChange":
                        onPlaybackQualityChange(message.data)
                        break
                    case "playerError":
                        onError(onGetError(dataNumber))
                        break
                    case "playbackRateChange":
                        onPlaybackRateChange(message.data)
                        break
                    default:
                        eventEmitter.current.emit(message.eventType, message.data)
                        break
                }
            } catch (error) {
                // console.warn("[rn-youtube-iframe]", error)
            }
        },
        [
            onReady,
            onError,
            onChangeState,
            onFullScreenChange,
            onPlaybackRateChange,
            onPlaybackQualityChange,
        ]
    )

    const source = useMemo(() => {
        const ytScript = MAIN_SCRIPT(
            lastVideoIdRef.current,
            lastPlayListRef.current,
            initialPlayerParamsRef.current,
            allowWebViewZoom,
            contentScale
        )

        const res = {html: ytScript.htmlString}
        return res
    }, [useLocalHTML, contentScale, baseUrlOverride, allowWebViewZoom])

    if (Platform.OS === "web") {
        const isHoveredPlayer = useHover(webViewRef)
        const checkHoverInterval = useRef<any>(null)

        useEffect(() => {
            if (isHoveredPlayer && !playerOverlayVisible) {
                setplayerOverlayVisible(true)
                checkHoverInterval.current = setInterval(() => {
                    setplayerOverlayVisible(false)
                    clearInterval(checkHoverInterval.current)
                    checkHoverInterval.current = null
                }, 3250)
            }
        }, [isHoveredPlayer])
    }

    const showPlayerOverlay = (timeout?: number) => {
        const timeoutDefault = 3250
        currentTimeOutDurationRef.current = timeout || timeoutDefault
        setplayerOverlayVisible(true)
        timeoutRef.current = setTimeout(() => {
            if (!isMoveTouchRef.current) {
                setplayerOverlayVisible(false)
            }
        }, currentTimeOutDurationRef.current)
    }

    const onPlayerTap = (evt: any) => {
        if (Platform.OS === "ios" || Platform.OS === "android") {
            if (!playerOverlayVisible) {
                showPlayerOverlay()
            }
        }
    }

    const onTouchStart = (eve: any) => {
        const now = Date.now()
        let doubleTapTouch
        touchMoveTimeRef.current = 0
        timeStartMoveRef.current = Date.now()
        isStartTouchRef.current = true
        isMoveTouchRef.current = false
        isEndTouchRef.current = false
        if (!playerOverlayVisible) {
            onPlayerTap(null)
        }
        if (lastTap && (now - lastTap.current!) < 450) {
            clearTimeout(doubleTapTouch)
        }
        else {
            if (playerOverlayVisible) {
                doubleTapTouch = setTimeout(() => {
                    setIsPlaying(false)
                    lastTap.current = null
                }, 450)
            }
        }
        lastTap.current = now
        // 1: starting touch
        onCurrentTouchAction?.(VIDEO_PLAYER_TOUCH_ACTION_EVENT.TOUCH_START)
    }

    const onTouchMove = (eve: any) => {
        isStartTouchRef.current = false
        isMoveTouchRef.current = true
        isEndTouchRef.current = false
        if (playerOverlayVisible && timeoutRef.current) {
            clearTimeout(timeoutRef.current)
            timeoutRef.current = null
        }
        touchMoveTimeRef.current += 1
        // 2: moving touch
        onCurrentTouchAction?.(VIDEO_PLAYER_TOUCH_ACTION_EVENT.TOUCH_MOVING)
    }

    const onTouchEnd = (eve: any) => {
        isStartTouchRef.current = false
        isMoveTouchRef.current = false
        isEndTouchRef.current = true

        if (playerOverlayVisible) {
            const durationMoveSec = Date.now() - timeStartMoveRef.current
            if (durationMoveSec > currentTimeOutDurationRef.current) {
                setplayerOverlayVisible(false)
            } else {
                if (timeoutRef.current) {
                    clearTimeout(timeoutRef.current)
                }
                timeoutRef.current = setTimeout(() => {
                    setplayerOverlayVisible(false)
                }, currentTimeOutDurationRef.current - durationMoveSec)
            }
        }
        timeStartMoveRef.current = 0
        // 3: end touch
        onCurrentTouchAction?.(VIDEO_PLAYER_TOUCH_ACTION_EVENT.TOUCH_END)
        if (touchMoveTimeRef.current > 10) {}
        if (isFirstTimePlay.current) {
            injectJavaScript(
                PLAYER_FUNCTIONS.toggleSeek(true)
            )
        }
    }

    const onGetVideoDuration = () => {
        injectJavaScript(PLAYER_FUNCTIONS.durationScript)
        return new Promise((resolve) => {
            eventEmitter.current.once("getDuration", resolve)
        })
    }

    const onGetVideoCurrentTime = () => {
        injectJavaScript(PLAYER_FUNCTIONS.currentTimeScript)
        return new Promise<number>((resolve) => {
            eventEmitter.current.once("getCurrentTime", resolve)
        })
    }

    const onSeekVideo = (seconds: number) => {
        injectJavaScript(
            PLAYER_FUNCTIONS.seekToScript(seconds, true)
        )
    }

    const updateVideoDuration = async () => {
        const result = await onGetVideoDuration()
        if (typeof result == 'number') {
            videoDurationRef.current = result
        }
        else {
            videoDurationRef.current = 0
        }
    }

    const onSeekRight = (currentPlayerTime: number) => {
        if (currentPlayerTime + 5 > videoDurationRef.current) {
            onSeekVideo(videoDurationRef.current)
        } else {
            onSeekVideo(currentPlayerTime + 5)
        }
        seekOverlayViewRef.current.onDoAnimation(1)
    }

    const onSeekLeft = (currentPlayerTime: number) => {
        if (currentPlayerTime - 5 < 0) {
            onSeekVideo(0)
        } else {
            onSeekVideo(currentPlayerTime - 5)
        }
        seekOverlayViewRef.current.onDoAnimation(0)
    }

    // side: 0 left, 1 right
    const onSeek = async (side: number) => {
        const currentPlayerTime = await onGetVideoCurrentTime()
        if (videoDurationRef.current) {
            if (side === 0) {
                onSeekLeft(currentPlayerTime)
            }
            else {
                onSeekRight(currentPlayerTime)
            }
            setTimeout(() => {
                injectJavaScript(PLAYER_FUNCTIONS.playVideo)
            }, 650)
        }
    }

    return (
        <View style={{height, width}}>
            <View
                style={{height, width}}
                onStartShouldSetResponderCapture={
                    Platform.OS === "ios"
                        ? (evt) => {
                            return true
                        }
                        : undefined
                }
                onResponderGrant={Platform.OS === "ios" ? onTouchStart : undefined}
                onResponderMove={Platform.OS === "ios" ? onTouchMove : undefined}
                onResponderRelease={Platform.OS === "ios" ? onTouchEnd : undefined}
            >

                <HandleTouchView
                    onTouch={
                        Platform.OS === "android"
                            ? (evt: any) => {
                                // 0 is start touch
                                if (evt.nativeEvent.type == "0") {
                                    onTouchStart(evt)
                                }
                                // 1 is end touch
                                else if (evt.nativeEvent.type == "1") {
                                    onTouchEnd(evt)
                                }
                                // 2 is move touch
                                else if (evt.nativeEvent.type == "2") {
                                    onTouchMove(evt)
                                }
                            }
                            : undefined
                    }
                    style={{width: "100%", height: "100%"}}
                >
                    <WebView
                        bounces={false}
                        originWhitelist={["*"]}
                        allowsInlineMediaPlayback
                        style={[styles.webView, webViewStyle]}
                        mediaPlaybackRequiresUserAction={false}
                        allowsFullscreenVideo={
                            !initialPlayerParamsRef.current.preventFullScreen
                        }
                        userAgent={
                            forceAndroidAutoplay
                                ? Platform.select({android: CUSTOM_USER_AGENT, ios: ""})
                                : ""
                        }
                        // props above this are override-able

                        // --
                        {...webViewProps}
                        // --

                        // add props that should not be allowed to be overridden below
                        source={source}
                        ref={webViewRef}
                        onMessage={onWebMessage}
                    />
                </HandleTouchView>
                <SeekOverlayView ref={seekOverlayViewRef} />
            </View>
            <DisableControlOverlayView width={width} height={height} />
        </View>
    )
}

const styles = StyleSheet.create({
    webView: {backgroundColor: "transparent", width: "100%", height: "100%"},
    overlay: {
        position: "absolute",
        margin: "auto",
        zIndex: 1,
        backgroundColor: "#ffffff",
        width: 50,
        height: 50,
        borderRadius: 10,
    },
    hiddenTouchOverlay: {
        position: "absolute",
        margin: "auto",
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
    },
})

export default forwardRef(YoutubeIframe)