import {action, makeObservable, observable} from "mobx"
import {AsyncUtils} from "../../../../app/utils/AsyncUtils"
import {CurrentAccount} from "../../../vizz_account/lib/CurrentAccount"
import {OnboardingController} from "../OnboardingController"
import {FeatureFlagModel} from "../../../vizz_account/models/FeatureFlagModel"

export enum PitchState {
    LOADING,
    LOADED,
    NOTIFICATIONS,
    SECRETS,

    FOURTH,
    FINAL
}

export class PitchController {
    private debug: boolean = false  // don't set this to true in production

    private currentAccount: CurrentAccount
    public onboardingController: OnboardingController
    @observable state: PitchState = PitchState.LOADING;     @action private setState(state: PitchState) { this.state = state }


    constructor(currentAccount: CurrentAccount, onboardingController: OnboardingController) {
        this.consoleDebug(`new()`)

        this.currentAccount = currentAccount
        this.onboardingController = onboardingController

        makeObservable(this)
    }

    public async initialize() {
        this.consoleDebug(`initialize()`)
        if (this.state != PitchState.LOADING) return

        if (this.currentAccount.hasFeature(FeatureFlagModel.DAILY_CONTENT)) {
            this.onboardingController.speech.preloadPhrase('pitch-intro', `Lava makes it "easy" to find your "favorite" things...`)
            this.onboardingController.speech.preloadPhrase('pitch-notifications', `both your favorite "friends",`)
            this.onboardingController.speech.preloadPhrase('pitch-secrets', `"AND!" The things you're into...`)
            this.onboardingController.speech.preloadPhrase('pitch-fourth', `Finding friends is easy because you don't need a phone number, and you "don't" need to know their Apple ID. You can see all your friends on "Roeblox" to call & chat them!`)
            this.onboardingController.speech.preloadPhrase('pitch-final', `And the things you're into are right here. Lava has "organized" all the videos about your favorite Roeblox games, "you" tubers, and "other" interests.`)
        } else {
            this.onboardingController.speech.preloadPhrase('pitch-intro', `Lava is the best video calling app for playing Roblox with your friends.`) // friends slide in
            this.onboardingController.speech.preloadPhrase('pitch-notifications', `It gives you an alert, so you'll never miss when your friends are online and available to play.`) // alert slides in
            this.onboardingController.speech.preloadPhrase('pitch-secrets', `And not only is Lava the best video calling app for playing Roblox, it also has the world's greatest collection of Roblox secrets and codes.`)
        }

        this.setState(PitchState.LOADED)
    }

    public uninitialize() {
        this.consoleDebug(`uninitialize()`)
        if (this.currentAccount.hasFeature(FeatureFlagModel.DAILY_CONTENT)) {
            this.onboardingController.speech.unloadPreloadedPhrase('pitch-intro')
            this.onboardingController.speech.unloadPreloadedPhrase('pitch-notifications')
            this.onboardingController.speech.unloadPreloadedPhrase('pitch-secrets')
            this.onboardingController.speech.unloadPreloadedPhrase('pitch-fourth')
            this.onboardingController.speech.unloadPreloadedPhrase('pitch-final')
        } else {
            this.onboardingController.speech.unloadPreloadedPhrase('pitch-intro')
            this.onboardingController.speech.unloadPreloadedPhrase('pitch-notifications')
            this.onboardingController.speech.unloadPreloadedPhrase('pitch-secrets')
        }
    }


    // Public methods

    public async startStep() {
        this.consoleDebug(`startStep()`)

        this.currentAccount.analytics.logEvent('pitch-onboarding','started')
        await AsyncUtils.sleep(1000)
        await this.onboardingController.speech.speakPreloadedPhrase('pitch-intro', async() => {

            this.setState(PitchState.NOTIFICATIONS)
            await this.onboardingController.speech.speakPreloadedPhrase('pitch-notifications', async() => {

                this.setState(PitchState.SECRETS)

                if (!this.currentAccount.hasFeature(FeatureFlagModel.DAILY_CONTENT)) await AsyncUtils.sleep(1000)
                await this.onboardingController.speech.speakPreloadedPhrase('pitch-secrets', async() => {

                    if (!this.currentAccount.hasFeature(FeatureFlagModel.DAILY_CONTENT))
                        this.nextStep()
                    else {

                        await AsyncUtils.sleep(1000)
                        this.setState(PitchState.FOURTH)
                        await this.onboardingController.speech.speakPreloadedPhrase('pitch-fourth', async() => {

                            this.setState(PitchState.FINAL)
                            await this.onboardingController.speech.speakPreloadedPhrase('pitch-final', async() => {

                                this.nextStep()
                            })
                        })
                    }
                })
            })
        })
    }

    public nextStep() {
        this.consoleDebug(`nextStep()`)

        this.onboardingController.speech.pause()
        this.currentAccount.analytics.logEvent('pitch-onboarding','finished')
        this.onboardingController.nextStep()
    }


    // Private helper methods


    // Private instance utility methods

    private consoleDebug(method: string, force: boolean = false) {
        if (this.debug || force) console.log(`${this.constructor.name}: ${method}  state = ${this.state}`)
    }
}
