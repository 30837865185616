import React, {useState} from 'react'
import {observer} from "mobx-react"
import {View, Text} from "react-native"
import {Color} from "../../../lib/Appearance"
import {Button, IconButton, RadioButton, TextInput} from "react-native-paper"
import {ConceptNode, ConceptStore} from "../../../lib/services/ConceptStore"
import {ConceptName} from "../../../lib/models/Concept"
import {EditConceptsDimensions} from "./EditConceptsDimensions"

type Props = {
    store: ConceptStore
    node: ConceptNode
    level: number
}

class InputChangeThrottler {
    delayMS: number
    timeoutHandle?: number

    constructor(delayMS: number) {
        this.delayMS = delayMS
    }

    handleInput(onComplete: () => void) {
        if (this.timeoutHandle) {
            window.clearTimeout(this.timeoutHandle)
        }

        this.timeoutHandle = window.setTimeout(() => {
            onComplete()
        }, this.delayMS)
    }
}

const EditConceptNode = observer((props: Props) => {

    const [inputThrottler] = useState(() => new InputChangeThrottler(500))

    return (
        <View style={{
            width: 400,
            marginStart: (props.level * EditConceptsDimensions.defaultIndent) + 24,
            backgroundColor: Color.secondaryBackground,
            borderRadius: 6
        }}>
            <View style={{flexDirection: 'row', alignItems: 'center', margin: 16}}>
                <Text>Search Phrases: </Text>
                <TextInput
                    style={{height: 40}}
                    mode={'outlined'}
                    value={props.node.searchPhrases}
                    onChangeText={(s) => {
                        props.node.searchPhrases = s
                        inputThrottler.handleInput(() => {
                            void props.store.changeSearchPhrases(props.node, props.node.searchPhrases)
                        })
                }}/>
            </View>
            <View style={{flexDirection: 'row', alignItems: 'center', marginHorizontal: 16, marginTop: 16}}>
                <Text style={{fontWeight: 'bold'}}>Aliases for the name:</Text>
            </View>
            {props.node.names.map((name: ConceptName, index: number) => {
                return (
                    <View key={`name-row${index}`} style={{flexDirection: 'row', alignItems: 'center', margin: 16}}>
                        <TextInput
                            key={`name-input${index}`}
                            style={{height: 40}}
                            mode={'outlined'}
                            value={name.name}
                            onChangeText={(t) => {
                                name.name = t
                                inputThrottler.handleInput(() => {
                                    void props.store.changeName(props.node, name)
                                })
                            }}/>
                        <RadioButton
                            key={`name-radio${index}`}
                            disabled={name.name.length == 0}
                            value={name.name} status={props.node.primaryName == name.name ? 'checked' : 'unchecked'}
                            onPress={() => props.store.makeNamePrimary(props.node, name)}
                        />
                        {props.node.names.length > 0 && !name.primary ?
                            <IconButton
                                onPress={() => props.store.removeName(props.node, name)}
                                icon={'close'}/>
                        : null}
                    </View>
                )
            })}
            <View style={{flexDirection: 'row', alignItems: 'center', marginHorizontal: 16}}>
                <Button icon={'plus'} mode={'outlined'} onPress={() => {
                    void props.store.addName(props.node)
                }}>
                    Add Name
                </Button>
            </View>
            <View style={{flexDirection: 'row', alignItems: 'center', margin: 16}}>
                <Button
                    mode={'contained'}
                    onPress={() => props.store.setEditingNode(undefined)}>
                    {'Close'}
                </Button>
            </View>
        </View>
    )
})

export default EditConceptNode