import {reaction} from "mobx"
import {observer} from "mobx-react"
import React, {useEffect, useState} from "react"
import {Alert, DimensionValue, Dimensions, Text, TouchableOpacity, View} from "react-native"
import {State, TapGestureHandler, TapGestureHandlerStateChangeEvent} from "react-native-gesture-handler"
import {isTablet, Style} from "../../../../../app/lib/Appearance"
import {Typography} from "../../../../../app/lib/Typography"
import BookController from "../../../controllers/library_widget/BookController"
import TableOfContents from "./title/TableOfContents"

type Props = {
    bookController: BookController
}

const TitlePage = observer((props: Props) => {
    const calcHeight = () => props.bookController.tableOfContents.chapters.length > 1 ? (Dimensions.get('screen').height * Style.Series.previewRowHeightPerct) : (Typography.headline1.lineHeight+10)

    const [height, setHeight] = useState<number>(calcHeight())

    useEffect(() => {
        const cleanupChapterWatcher = reaction(() => props.bookController.tableOfContents.chapters, () => {
            setHeight(calcHeight())
        })

        return () => {
            cleanupChapterWatcher()
        }
    }, [])

    const clearHistoryPrompt = async(event: TapGestureHandlerStateChangeEvent) => {
        if (event.nativeEvent.state !== State.ACTIVE) return

        Alert.alert(
            "Clear viewing history?",
            `Do you want to reset all the videos you've viewed in this topic?`,
            [
                {
                    text: "No",
                    onPress: () => console.log("Delete friend cancelled"),
                    style: "cancel"
                },
                {
                    text: "Yes, Reset",
                    onPress: () => props.bookController.resetBookState(),
                    style: 'destructive'
                }
            ]
        )
    }

    return (
        <TouchableOpacity style={{
                height: height,
                paddingTop: 10,
                flexDirection: isTablet() ? 'row' : 'column',
                paddingLeft: Style.Canvas.marginHorizontal,
            }}
            onPress={() => props.bookController.bringBookToFront()}
            onLongPress={() => props.bookController.bringBookToFront()}
            activeOpacity={0.97}
        >
            <View style={{
                width: isTablet() ? `${((Style.Book.indexWidthPerct)* 100).toString()}%` as DimensionValue : '100%',
                flexDirection: 'row',
                alignItems:'center'
            }}>
                <TapGestureHandler numberOfTaps={4} onHandlerStateChange={clearHistoryPrompt}>
                    <Text style={Typography.headline1}>{props.bookController.book.title}</Text>
                </TapGestureHandler>
            </View>

            <TableOfContents bookController={props.bookController} />
        </TouchableOpacity>
    )
})

export default TitlePage