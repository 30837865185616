export enum AppState {
    // Duplicated in AppUpdateService becuase of a circular dependency. Keep them in sync.
    INITIALIZING,
    APP_UPDATING,
    BINARY_OUTDATED_GOTO_APP_STORE,
    UPDATE_AHEAD_CANNOT_DOWNGRADE,
    EXTENDED_DELAY,
    INIT_ERROR,
    UPDATE_FAILED,
    LOW_DISK_SPACE,
    LOADED,
    SUSPENDED, // app was READY but has been temporarily halted
}
