/**
 * Based on https://github.com/glepur/react-native-swipe-gestures
 * Had to be changed because of scrollView / listView problem
 */

import * as React from 'react'
import {
    View,
    PanResponder,
    PanResponderInstance,
    GestureResponderEvent, PanResponderGestureState, StyleProp, ViewStyle, LayoutChangeEvent
} from 'react-native'

//Avaiable swipe directions
export enum swipeDirections {
    SWIPE_UP = 'SWIPE_UP',
    SWIPE_DOWN = 'SWIPE_DOWN'
}

//Swipe config interface
interface ISwipeConfig {
    velocityThreshold: number,
    gestureIsClickThreshold: number
}

//Default swipe config
const swipeConfig: ISwipeConfig = {
velocityThreshold: 0.3,
gestureIsClickThreshold: 5
}

//Properties interface
type IProps = React.PropsWithChildren<{
    onSwipe?: (direction: swipeDirections, gestState: PanResponderGestureState) => any,
    onSwipeUp?: (gestState: PanResponderGestureState) => any,
    onSwipeDown?: (gestState: PanResponderGestureState) => any,
    onLayout?: (event: LayoutChangeEvent) => void,
    config?: ISwipeConfig,
    style?: StyleProp<ViewStyle>
}>

//SwipeRecognizer class
export default class VerticalSwipeRecognizer extends React.Component<IProps> {

    private _swipeConfig: ISwipeConfig
    private _panResponder: PanResponderInstance

    constructor(props: IProps) {
        super(props)
        this._swipeConfig = Object.assign(swipeConfig, props.config)

        const responderEnd = this._handlePanResponderEnd.bind(this)
        const shouldSetResponder = this._handleShouldSetPanResponder.bind(this)
        this._panResponder = PanResponder.create({ //stop JS beautify collapse
            onStartShouldSetPanResponder: shouldSetResponder,
            onMoveShouldSetPanResponder: shouldSetResponder,
            onPanResponderRelease: responderEnd,
            onPanResponderTerminate: responderEnd,
        })
    }

    /**
     * Pan responder handler
     * @param {GestureResponderEvent} evt
     * @param {PanResponderGestureState} gestureState
     * @return {boolean}
     * @private
     */
    private _handleShouldSetPanResponder(evt: GestureResponderEvent, gestureState: PanResponderGestureState): boolean {
        return evt.nativeEvent.touches.length === 1 && !this._gestureIsClick(gestureState)
    }

    /**
     * Checks if geture is click
     * @param {PanResponderGestureState} gestureState
     * @return {boolean}
     * @private
     */
    private _gestureIsClick(gestureState: PanResponderGestureState) {
        return Math.abs(gestureState.dy) < 30 //&& Math.abs(gestureState.dx) < 30
    }

    /**
     * Pan responder handler
     * @param {GestureResponderEvent} evt
     * @param {PanResponderGestureState} gestureState
     * @private
     */
    private _handlePanResponderEnd(evt: GestureResponderEvent, gestureState: PanResponderGestureState): void {
        const swipeDirection = this._getSwipeDirection(gestureState)
        swipeDirection && this._triggerSwipeHandlers(swipeDirection, gestureState)
    }

    /**
     * Swipe handler
     * @param {swipeDirections} swipeDirection
     * @param {PanResponderGestureState} gestureState
     * @private
     */
    private _triggerSwipeHandlers(swipeDirection: swipeDirections, gestureState: PanResponderGestureState): void {
        const {onSwipe, onSwipeUp, onSwipeDown} = this.props
        const {SWIPE_UP, SWIPE_DOWN} = swipeDirections
        onSwipe && onSwipe(swipeDirection, gestureState)
        switch (swipeDirection) {
            case SWIPE_UP:
                onSwipeUp && onSwipeUp(gestureState)
                break
            case SWIPE_DOWN:
                onSwipeDown && onSwipeDown(gestureState)
                break
        }
    }

    /**
     * Gets swipe direction
     * @param {PanResponderGestureState} gestureState
     * @return {swipeDirections | null}
     * @private
     */
    private _getSwipeDirection(gestureState: PanResponderGestureState): swipeDirections | null {
        const {SWIPE_UP, SWIPE_DOWN} = swipeDirections
        const {dy} = gestureState
        if (this._isValidVerticalSwipe(gestureState)) {
            return (dy > 0)
                ? SWIPE_DOWN
                : SWIPE_UP
        }
        return null
    }

    /**
     * Checks if swipe is valid and is vertical
     * @param {PanResponderGestureState} gestureState
     * @return {boolean}
     * @private
     */
    private _isValidVerticalSwipe(gestureState: PanResponderGestureState): boolean {
        const {vy, dy, dx} = gestureState
        const {velocityThreshold} = this._swipeConfig

        const isDownward = (dy > 0) ? true : false
        const isBasicallyVertical = (Math.abs(dx) / Math.abs(dy)) < 0.2
        const isFastEnough = Math.abs(vy) > velocityThreshold

        //console.log(`${isDownward} and ${isBasicallyVertical} and ${isFastEnough}`)
        return isDownward && isBasicallyVertical && isFastEnough
    }

    render() {
        return (<View {...this.props} {...this._panResponder.panHandlers} />)
    }
}
