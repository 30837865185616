import React, {useContext} from 'react'
import {StyleSheet, ViewStyle} from "react-native"
import {Divider, List, Switch} from "react-native-paper"
import {Observer, observer} from "mobx-react"
import CurrentAccountContext from "../../../modules/vizz_account/lib/CurrentAccount"

type Props = {
    style?: ViewStyle
}

export const DebugAutoUpdate = observer((props: Props) => {
    const currentAccount = useContext(CurrentAccountContext)

    return (
        <List.Section style={[styles.container, props.style]}>
            <List.Subheader>Testing Auto Update</List.Subheader>
            <Divider/>
            <List.Item
                title={`Throw Exception on App Foreground`}
                description={''}
                right={() =>
                    <Observer>{() => <Switch
                        value={currentAccount.personData.throwExceptionOnAppForeground}
                        onValueChange={(v) => {
                            currentAccount.personData.throwExceptionOnAppForeground = v
                        }}/>}
                    </Observer>
                }
            />
            <Divider/>
            <List.Item
                title={`Set Client Ahead of Server`}
                description={''}
                right={() =>
                    <Observer>{() => <Switch
                        value={currentAccount.personData.throwClientAhead}
                        onValueChange={(v) => {
                            currentAccount.personData.throwClientAhead = v
                        }}/>}
                    </Observer>
                }
            />
            <Divider/>
            <List.Item
                title={`Throw Exception on 1st Check`}
                description={''}
                right={() =>
                    <Observer>{() => <Switch
                        value={currentAccount.personData.throwExceptionOnExpo1stCheck}
                        onValueChange={(v) => {
                            currentAccount.personData.throwExceptionOnExpo1stCheck = v
                        }}/>}
                    </Observer>
                }
            />
            <Divider/>
            <List.Item
                title={`Throw Exception on 2nd Check`}
                description={''}
                right={() =>
                    <Observer>{() => <Switch
                        value={currentAccount.personData.throwExceptionOnExpo2ndCheck}
                        onValueChange={(v) => {
                            currentAccount.personData.throwExceptionOnExpo2ndCheck = v
                        }}/>}
                    </Observer>
                }
            />
            <Divider/>
            <List.Item
                title={`Throw Exception on 1st Fetch`}
                description={''}
                right={() =>
                    <Observer>{() => <Switch
                        value={currentAccount.personData.throwExceptionOnExpo1stFetch}
                        onValueChange={(v) => {
                            currentAccount.personData.throwExceptionOnExpo1stFetch = v
                        }}/>}
                    </Observer>
                }
            />
            <Divider/>
            <List.Item
                title={`Throw Exception on 2nd Fetch`}
                description={''}
                right={() =>
                    <Observer>{() => <Switch
                        value={currentAccount.personData.throwExceptionOnExpo2ndFetch}
                        onValueChange={(v) => {
                            currentAccount.personData.throwExceptionOnExpo2ndFetch = v
                        }}/>}
                    </Observer>
                }
            />
            <Divider/>
            <List.Item
                title={`Throw Exception on Expo Install`}
                description={''}
                right={() =>
                    <Observer>{() => <Switch
                        value={currentAccount.personData.throwExceptionOnExpoInstall}
                        onValueChange={(v) => {
                            currentAccount.personData.throwExceptionOnExpoInstall = v
                        }}/>}
                    </Observer>
                }
            />
            <Divider/>
            <List.Item
                title={`Throw Exception for Low Disk Space`}
                description={''}
                right={() =>
                    <Observer>{() => <Switch
                        value={currentAccount.personData.throwExceptionOnDiskSpace}
                        onValueChange={(v) => {
                            currentAccount.personData.throwExceptionOnDiskSpace = v
                        }}/>}
                    </Observer>
                }
            />
        </List.Section>
    )
})

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: 'white',
    },
})
