import {observer} from "mobx-react"
import React, {useState} from "react"
import {LayoutRectangle, ScrollView} from "react-native"
import {TouchableRipple} from "react-native-paper"
import {ConceptThumbnail} from "../../../../../../app/views/web_navigation/feed/concept_navigator/ConceptThumbnail"
import BookController from "../../../../controllers/library_widget/BookController"
import ChapterModel from "../../../../models/ChapterModel"

type Props = {
    bookController: BookController
}

const TableOfContents = observer((props: Props) => {
    const [layout, setLayout] = useState<LayoutRectangle>({x: 0, y: 0, width: 0, height: 0})
    const tableOfContents = props.bookController.tableOfContents

    return (
        <ScrollView style={{
            flex: 1,
            flexDirection: 'row',
        }}
            onLayout={(e) => setLayout(e.nativeEvent.layout)}
            horizontal={true}
            showsHorizontalScrollIndicator={false}
        >
            {tableOfContents.chapters.map((chapter: ChapterModel, index: number) => {
                return (
                    <TouchableRipple
                        onPress={() => tableOfContents.selectChapter(index) }
                        onLongPress={() => tableOfContents.selectChapter(index) }
                        style={{marginVertical: 5, marginRight: 10, alignContent: 'center', alignItems: 'center'}}
                        key={`toc-touchable-${index}`}
                    >
                        <ConceptThumbnail
                                imageUrl={chapter?.image_url}
                                label={chapter?.title}
                                width={ (layout.height-10) * (4/3)}
                                disabled={false}
                                selected={tableOfContents.chapterIndex == index && !props.bookController.active}
                                key={`toc-concept-${index}`}
                        />
                    </TouchableRipple>
                )
            })}
        </ScrollView>
    )
})

export default TableOfContents