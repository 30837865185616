import {action, computed, makeObservable, observable} from "mobx"
import {getTime} from "date-fns"
import {DailyQuestion, DailyQuestionChoice} from "../models/DailyQuestion"
import DateUtils from "../../utils/DateUtils"
import {CurrentAccount} from "../../../modules/vizz_account/lib/CurrentAccount"
import {create, persist} from "mobx-persist"
import AsyncStorage from "@react-native-async-storage/async-storage"

const hydrate = create({
    storage: AsyncStorage,
    jsonify: true,
})

export class CountdownState {
    duration: number
    remaining: number

    constructor(duration: number, remaining: number) {
        this.duration = duration
        this.remaining = remaining
    }
}

export class DailyQuestionStore {

    account: CurrentAccount

    @persist('list')
    @observable
    dailyQuestions: DailyQuestion[] = []

    @persist('list')
    @observable
    nextAvailableQuestions: DailyQuestionChoice[] = []

    @persist
    @observable
    private waitingForQuestionUnlock?: boolean

    constructor(account: CurrentAccount) {
        this.account = account
        makeObservable(this)
        void hydrate('daily_question', this)
        void this.updateDailyQuestions()
    }

    public async fetchQuestionChoices() {
        // let results = await this.account.api.get('daily_experience.daily_question_choices') as DailyQuestionChoice[]
        // if (results.length > 2) {
        //     results = results.slice(0, 3)
        // }
        return []
    }

    public async fetchPreviousQuestionChoices() {
        // const results = await this.account.api.get('daily_experience.daily_question_previous') as DailyQuestion[]
        // results.forEach((q) => {
        //     q.isLocked = this.getUnlockDate(q.unlocked_at) > DateUtils.now()
        // })
        return []
    }

    public async selectDailyQuestion(key: string, firstQuestion: boolean = false) {
        const unlockOn = firstQuestion ? DateUtils.now() : DateUtils.tomorrow(0)
        // await this.account.api.post('daily_experience.select_daily_question_choice', {
        //     vizz_key: key,
        //     unlocked_at: unlockOn.toISOString()
        // })

        await this.updateDailyQuestions()
        if (!firstQuestion) {
            this.setWaitingForQuestionUnlock(true)
        }
    }

    public async updateDailyQuestions() {
        const previousQuestions = await this.fetchPreviousQuestionChoices()
        this.setDailyQuestions(previousQuestions)

        const nextQuestions = await this.fetchQuestionChoices()
        this.setNextAvailableQuestions(nextQuestions)
    }

    public async resetDailyQuestionChoices() {
        //await this.account.api.delete('daily_experience.reset_personal_video_choices')
    }

    @action
    public setWaitingForQuestionUnlock(waitingForQuestionUnlock: boolean) {
        this.waitingForQuestionUnlock = waitingForQuestionUnlock
    }

    @action
    public setNextAvailableQuestions(nextAvailableQuestions: DailyQuestionChoice[]) {
        this.nextAvailableQuestions = nextAvailableQuestions
    }

    @computed
    get shouldAutoPlayNextQuestion(): boolean {
        if (this.waitingForQuestionUnlock == undefined) return false
        return this.waitingForQuestionUnlock &&
            this.dailyQuestions.length > 1 &&
            this.dailyQuestions.filter((q) => q.isLocked).length == 0
    }

    @computed
    get pendingQuestionTimerStarted() {
        return this.latestQuestion?.selected_at
    }

    @computed
    get latestQuestion() {
        if (this.dailyQuestions.length > 0) {
            return this.dailyQuestions[0]
        } else {
            return undefined
        }
    }

    @action
    setDailyQuestions(dailyQuestions: DailyQuestion[]) {
        this.dailyQuestions = dailyQuestions
    }

    @computed
    public get hasQuestionsForTomorrow(): boolean {
        return this.nextAvailableQuestions.length > 2
    }

    @computed
    public get currentCountdownState(): CountdownState|undefined {
        if (this.latestQuestion == undefined) {
            return undefined
        }

        const fireDate = this.getUnlockDate(this.latestQuestion.unlocked_at)


        let startDate = new Date(Date.parse(this.latestQuestion.selected_at))
        startDate.setHours(7)
        startDate.setMinutes(0)
        startDate.setSeconds(0)

        const duration = (getTime(fireDate) - startDate.getTime()) / 1000
        const remaining = (getTime(fireDate) - getTime(DateUtils.now())) / 1000

        const state = new CountdownState(duration, remaining)

        if (state.remaining <= 0) return undefined

        return state
    }

    public getUnlockDate(unlockDayString: string): Date {
        const unlockDay = DateUtils.parse(unlockDayString)
        let result = new Date(unlockDay)
        result.setHours(7)
        result.setMinutes(0)
        result.setSeconds(0)

        return result
    }
}