import {action, makeObservable, observable, reaction, runInAction} from "mobx"
import {AssetType, VizzModel, VizzEndBehavior} from "../models/VizzModel"
import {Image} from "react-native"
import VizzAudioService from "../services/VizzAudioService"
import {CurrentAccount} from "../../vizz_account/lib/CurrentAccount"
import {RefObject} from "react"
import PollingAPIService from "../../../app/services/PollingAPIService"
import {Swiper} from "../views/vizz/Swiper"
import {ConceptViewModel} from "../../../app/views/web_navigation/feed/ConceptNavigator"

export enum VizzControllerState {
    // These states are coarse. If you're in the middle of playing and individual slides auto-pause or the
    // vizz buffers because it's waiting on the server, the player stays in the 'playing' state.
    UNINITIALIZED = 'uninitialized',
    INITIALIZING = 'initializing',
    INITIALIZED = 'initialized',
    LOADING = 'loading',
    STOPPED = 'stopped', // at start of vizz with first slide loaded
    PLAYING = 'playing',
    // paused: there is currently no paused state. A vizz is just stopped and it resets back to the start.
    // processing/buffering: not a state because that's a server state rather than a client state
}

export enum VizzSlideState {
    BUFFERING = 'buffering',
    STOPPED = 'stopped',
    PLAYING = 'playing',
    TIMEOUT = 'timeout',
    NOAUDIO = 'noaudio',
}

class VizzController {
    private debug: boolean = false  // don't set this to true in production

    private audioTrack?: VizzAudioService = undefined
    private audioTrackBackground?: VizzAudioService = undefined
    private slideChangeWithAudioSeeking: boolean = true
    private timeoutThenStartorStop?: any
    private progressHandle?: any
    private currentAccount: CurrentAccount
    private vizzPolling: PollingAPIService
    private slidesPrefetchedUpToIndex: number = -1
    private suffix?: string
    private concept?: ConceptViewModel
    private alreadyTasted: boolean = false
    private bufferingStartTime?: number
    private playEventually: boolean = false
    private loadEventually: boolean = false

    @observable vizz: VizzModel
    @observable state: VizzControllerState = VizzControllerState.UNINITIALIZED
    @observable currentSlideIndex: number | null = null
    @observable currentSlideState: VizzSlideState = VizzSlideState.BUFFERING
    @observable playbackProgress: number = 0.0

    public swiperRef: RefObject<Swiper>
    public onVizzEnd?: (() => void) = undefined
    public onVizzStart?: (() => void) = undefined


    constructor(vizz: VizzModel, swiperRef: RefObject<Swiper>, currentAccount: CurrentAccount, suffix?: string, concept?: ConceptViewModel) {
        this.consoleDebug(`new()`)

        this.swiperRef = swiperRef
        this.currentAccount = currentAccount
        this.suffix = suffix
        this.concept = concept

        makeObservable(this)
        this.vizz = this.vizzWithSuffix(vizz)

        this.vizzPolling = new PollingAPIService({
            startingObject: this.vizz,
            railsAPICall: async(seconds) => await this.currentAccount.api.get(`vizz_maker.vizz_path(${this.vizz.id})`, {seconds: seconds}) as VizzModel,
            maxRetriesPerUpdate: 30,
            maxRetriesTotal: 120,
            isDone: (obj: VizzModel) => { return (obj) ? obj.doneProcessing : false },
        })

        this.changeState.initialize()

        // Vizz may not be fully loaded at this point, but we don't immediately check that or do a network
        // request to rectify that. Instead, we want this constructor to be light. We'll determine a vizz
        // is incomplete when we attempt to change state and then we'll try reloading it.
    }


    // Public instance methods

    public recordIntroPlayed() {
        this.logEvent('intro-started', { vizz_key: this.vizz.id })
    }

    public changeState = {
        // Methods grouped just for readability. We want it to be obvious when you are changing state.
        // The last word of the method name indicates the state it will end in.
        // It's important that all of these methods can be called at any time, in any order, even the
        // same method multiple times, with no unexpected behavior.

        initialize: () => {
            this.consoleDebug(`changeState.initialize()`)
            if (this.state == VizzControllerState.INITIALIZING) return
            if (this.state == VizzControllerState.INITIALIZED) return
            if (this.state != VizzControllerState.UNINITIALIZED) throw new Error(`Tried to initialize() from an invalid state (${this.state})`)

            this.updateState(VizzControllerState.INITIALIZING)

            // Another thread could be attempting to uninitialize this Vizz during every line below
            this.initializeVizzFields()
            void this.slideControl.changedTo(null)

            this.updateState(VizzControllerState.INITIALIZED)   // It's important for this to be the last line so we do
                                                            // not think it's ready to load or play before it's done.
            if (this.loadEventually) void this.changeState.loadAndStop()
        },

        loadEventually: () => {
            this.consoleDebug(`changeState.loadEventually()`)

            this.loadEventually = true

            if (this.state == VizzControllerState.INITIALIZED) void this.changeState.loadAndStop()
        },

        uninitialize: async() => {
            this.consoleDebug(`changeState.uninitialize()`)
            if (this.state == VizzControllerState.UNINITIALIZED) return

            this.updateState(VizzControllerState.UNINITIALIZED)

            // Another thread could be attempting to initialize this Vizz during every line below
            this.vizzPolling.stop()
            this.timeoutThenStartorStop = this.resetTimeout(this.timeoutThenStartorStop)
            void this.slideControl.changedTo(null)
            await this.unloadAudio()
            if (this.progressHandle) clearInterval(this.progressHandle)

            // We want to be able to call initialize() again after being uninitialized()
        },

        loadAndStop: async() => {
            this.consoleDebug(`changeState.loadAndStop()`)
            if (this.state == VizzControllerState.STOPPED) return
            if (this.state == VizzControllerState.PLAYING) return
            if (this.state == VizzControllerState.UNINITIALIZED) this.changeState.initialize()
            if (this.state == VizzControllerState.INITIALIZING) await new Promise(resolve => setTimeout(resolve, 1000))
            if (this.state != VizzControllerState.INITIALIZED && this.state != VizzControllerState.LOADING) throw new Error(`Tried to loadAndStop() from an invalid state (${this.state})`)

            // Another thread could be attempting to stop this Vizz during every line below

            if (await this.load('loadAndStop')) { // load() starts a polling loop which re-calls loadAndStop()
                if (this.state != VizzControllerState.INITIALIZED && this.state != VizzControllerState.LOADING) return
                if (this.playEventually) {
                    await this.changeState.playFromStart(true)
                } else {
                    this.updateState(VizzControllerState.STOPPED)
                }
            } // do not handle the 'else' here, it's handled within load()
        },

        loadAndPlay: async() => {
            this.consoleDebug(`changeState.loadAndPlay()`)
            if (this.state == VizzControllerState.STOPPED) return
            if (this.state == VizzControllerState.PLAYING) return
            if (this.state == VizzControllerState.UNINITIALIZED) this.changeState.initialize()
            if (this.state == VizzControllerState.INITIALIZING) await new Promise(resolve => setTimeout(resolve, 1000))
            if (this.state != VizzControllerState.INITIALIZED && this.state != VizzControllerState.LOADING) throw new Error(`Tried to loadAndPlay() from an invalid state (${this.state})`)

            // Another thread could be attempting to stop this Vizz during every line below

            if (await this.load('loadAndPlay')) { // load() starts a polling loop which re-calls loadAndPlay()
                if (this.state != VizzControllerState.INITIALIZED && this.state != VizzControllerState.LOADING) return
                await this.changeState.playFromStart(true)
            } // do not handle the 'else', it's handled within load()
        },

        playFromStart: async(initialPlay?: boolean) => {
            this.consoleDebug(`changeState.playFromStart(${initialPlay})`)
            if (this.state == VizzControllerState.PLAYING) return
            if (this.state != VizzControllerState.STOPPED && initialPlay != true) throw new Error(`Tried to playFromStart() from an invalid state (${this.state})`)
            // it would be more elegant to require the player to be in the stopped state but we have this initialPlay
            // hack to prevent the stop button from flashing on screen for a second during autoplay

            this.updateState(VizzControllerState.PLAYING)
            this.logEvent('played')
            if (initialPlay) this.logEvent('play-started', { vizz_key: this.vizz.id })

            // Another thread could be attempting to stop this Vizz during every line below.
            this.timeoutThenStartorStop = this.resetTimeout(this.timeoutThenStartorStop)
            await this.updateMutedState(false)
            await this.slideControl.goTo(0, true)
            this.startProgressTimer()
            if (this.onVizzStart) this.onVizzStart()
        },

        playEventually: () => {
            this.consoleDebug(`changeState.playEventually()`)

            this.playEventually = true
            if (this.state == VizzControllerState.STOPPED) void this.changeState.playFromStart()
        },

        stop: async(mode?: string) => {
            this.consoleDebug(`changeState.stop()`)

            this.playEventually = false // This handles the case that stop() was called immediately after playEventually. No detectable state change has happened,
                                        // it's still in some process of being loaded. But we need to make sure it does not start auto-playing when it's done loading.
            if (this.state == VizzControllerState.STOPPED) return
            if (this.state != VizzControllerState.PLAYING) throw new Error(`Tried to stop() from an invalid state (${this.state})`)

            this.updateState(VizzControllerState.STOPPED)
            if (mode && mode == 'user-tapped') this.logEvent('stopped')

            // Another thread could be attempting to start or unload this Vizz during every line below.
            this.timeoutThenStartorStop = this.resetTimeout(this.timeoutThenStartorStop)
            if (this.state != VizzControllerState.PLAYING) void this.slideControl.changedTo(null)
            await this.pauseAudio()
        }
    }

    // Private helper methods for the changeState methods above

    @action
    private updateState(state: VizzControllerState) {
        this.consoleDebug(`updateState(${state})`)
        this.state = state
    }

    @action
    private updateSlideState(state: VizzSlideState) {
        this.consoleDebug(`updateSlideState(${state})`)

        if (state == VizzSlideState.BUFFERING && this.currentSlideState != VizzSlideState.BUFFERING) {
            this.logEvent('buffering-started', { vizz_key: this.vizz.id })
            this.bufferingStartTime = Date.now()
        }
        if (state == VizzSlideState.PLAYING && this.currentSlideState == VizzSlideState.BUFFERING) {
            let timeBuffered = 0
            if (this.bufferingStartTime) timeBuffered = Date.now() - this.bufferingStartTime
            this.logEvent('buffering-ended', { vizz_key: this.vizz.id, time_buffered: timeBuffered })
            this.bufferingStartTime = undefined
        }
        this.currentSlideState = state
    }

    @action
    private updatePlaybackProgress(progress: number) {
        this.playbackProgress = progress
    }

    private startProgressTimer() {
        const duration = this.vizz.slides
            .map((s) => s.duration)
            .reduce((p, c) => p + c)
        const updateInterval = 0.2
        const progressIncrement = updateInterval / duration

        this.progressHandle = setInterval(() => {
            const newVal = this.playbackProgress + progressIncrement
            this.updatePlaybackProgress(newVal)
        }, updateInterval * 1000)
    }

    private async updateMutedState(isMuted: boolean) {
        this.consoleDebug(`updateMutedState(${isMuted})`)
        if (this.state != VizzControllerState.PLAYING) return

        await this.audioTrack?.setIsMuted(isMuted)
        await this.audioTrackBackground?.setIsMuted(isMuted)
    }

    private async pauseAudio() {
        if (this.state == VizzControllerState.STOPPED) await this.audioTrack?.changeState.pause()
        if (this.state == VizzControllerState.STOPPED) await this.audioTrackBackground?.changeState.pause()
    }


    public slideControl = { // Methods grouped for readability

        goTo: async(index: number, seekAudio: boolean = true) => {
            this.consoleDebug(`slideControl.goTo(${index}, ${seekAudio})`)
            if (this.state != VizzControllerState.PLAYING) throw new Error(`Tried to goTo() from an invalid state (${this.state})`)

            this.updateState(VizzControllerState.PLAYING)
            this.updateSlideState(VizzSlideState.PLAYING)

            this.slideChangeWithAudioSeeking = seekAudio
            this.swiperRef.current?.goTo(index)
            // ^ This will trigger onIndexChange in Swiper is about to call slideControl.changedTo(). That callback exists in case the user
            //   manually swipes slides. However, there are some cases where onIndexChange doesn't fire, such as when we "go to" the
            //   slide we are already on. Rather than try to detect all those cases and prevent double-calling, we just call this again:
            await this.slideControl.changedTo(index, seekAudio)
        },

        changedTo: async(index: number | null, seekAudio?: boolean) => {
            // This method can be called multiple times in a row so handle it gracefully
            if (seekAudio != undefined) this.slideChangeWithAudioSeeking = seekAudio
            this.consoleDebug(`changedTo(${index}, ${this.slideChangeWithAudioSeeking})`)

            runInAction(() => { this.currentSlideIndex = index })
            if (index == null) this.updateSlideState(VizzSlideState.STOPPED)

            if (index == null) return // nothing else to do
            if (this.state != VizzControllerState.PLAYING) return

            if (this.audioTrack) {
                if (this.slideChangeWithAudioSeeking) {
                    let pos = this.startAudioPositionForSlideAtIndex(index) * 1000
                    await this.audioTrack?.changeState.seekAndPlay(pos)
                } else {
                    await this.audioTrack?.changeState.play()
                }
            }

            if (this.state != VizzControllerState.PLAYING) return // in case state changed in another thread
            if (this.audioTrackBackground && this.audioTrackBackground?.state != 'playing') {
                await this.audioTrackBackground?.changeState.play()
            }

            if (index == 3) this.tasted() // this means we've reached the 4th slide
            this.slideChangeWithAudioSeeking = true
        },

        restartCurrent: () => {
            this.consoleDebug(`slideControl.restartCurrent()`)
            if (!this.currentSlideIndex) return
            void this.slideControl.goTo(this.currentSlideIndex)
        },

        pause: () => {
            this.consoleDebug(`slideControl.pause()`)
            void this.audioTrack?.changeState.pause()
        },

        resume: () => {
            this.consoleDebug(`slideControl.resume()`)
            void this.audioTrack?.changeState.play()
        },

        handleEnd: async() => {
            this.consoleDebug(`slideControl.handleEnd()`)
            if (this.state != VizzControllerState.PLAYING) return

            const nextIndex = this.nextSlideIndex()
            if (nextIndex)
                this.autoAdvanceSlide()
            else if (this.currentSlideIndex != null && this.currentSlideIndex != this.lastSlideIndex()) {
                this.updateSlideState(VizzSlideState.BUFFERING)
                this.vizzPolling.start({
                    afterUpdate: async(vizz) => {
                        this.initializeVizzFields(vizz)
                        if (this.currentSlideState == VizzSlideState.BUFFERING) await this.slideControl.handleEnd()
                    },
                    afterTimeout: async() => {
                        this.updateSlideState(VizzSlideState.TIMEOUT)
                        this.logEvent('timeout', { vizz_key: this.vizz.id })
                        let vizz = await this.currentAccount.api.get(`vizz_maker.vizz_path(LMg4D)`, {seconds: 0}) as VizzModel
                        this.initializeVizzFields(vizz)
                        if (this.currentSlideState == VizzSlideState.BUFFERING) await this.slideControl.handleEnd()
                    }
                })
            } else if (this.vizz.endBehavior == VizzEndBehavior.LOOP)
                this.restartVizzAfter1Second()
            else
                this.vizzReachedEnd()
        },

        noaudio: () => {
            this.consoleDebug(`slideControl.noaudio()`)
            this.updateSlideState(VizzSlideState.NOAUDIO)
        }
    }


    // Private helper methods for the slideControls above

    private autoAdvanceSlide() {
        this.consoleDebug(`autoAdvanceSlide()`)
        const nextIndex = this.nextSlideIndex()
        if (nextIndex) {
            void this.slideControl.goTo(nextIndex, false)
        }
    }

    private restartVizzAfter1Second() {
        this.consoleDebug(`restartVizzAfter1Second()`)
        this.timeoutThenStartorStop = this.resetTimeout(this.timeoutThenStartorStop)

        this.timeoutThenStartorStop = setTimeout(async() => {
            await this.slideControl.changedTo(null)
            void this.slideControl.goTo(0, true)
        }, 1000)
    }

    private vizzReachedEnd() {
        this.consoleDebug(`vizzReachedEnd()`)
        this.timeoutThenStartorStop = this.resetTimeout(this.timeoutThenStartorStop)

        this.vizzPolling.stop()
        this.tasted()
        this.logEvent('finished')

        try {
            void this.changeState.stop()
        } catch(e) {
            this.consoleDebug(`Stop failed  ${e}`)
        }
        if (this.onVizzEnd) this.onVizzEnd()
    }


    // Private instance init methods

    private async load(calledFrom: string) {
        this.consoleDebug(`load(${calledFrom})`)
        if (this.state != VizzControllerState.INITIALIZED && this.state != VizzControllerState.LOADING) return

        try {
            await this.audioTrack?.changeState.loadAndPause()
            await this.audioTrackBackground?.changeState.loadAndPause()
            if (!this.vizz.canStartPlaying) throw new Error(`not enough slides yet`)

            this.vizzPolling.stop()

            if (!this.vizz.doneProcessing) this.vizzPolling.start({
                afterUpdate: async(vizz) => this.initializeVizzFields(vizz) // we can't call all of initialize again, but just this we can
            })

            return Promise.resolve(true)
         } catch(e) {
            if (!this.vizz.doneProcessing && !this.vizz.canStartPlaying) {
                // We only set the loading state here, when we are about to start polling. Otherwise
                // we flash the loading state for the user.
                this.updateState(VizzControllerState.LOADING)

                this.vizzPolling.start({
                    afterUpdate: async(vizz) => {
                        this.initializeVizzFields(vizz)
                        if (calledFrom == 'loadAndStop') await this.changeState.loadAndStop()
                        if (calledFrom == 'loadAndPlay') await this.changeState.loadAndPlay()
                    },
                    afterTimeout: async() => {
                        //await this.changeState.uninitialize()
                        this.logEvent('timeout', { vizz_key: this.vizz.id })
                        let vizz = await this.currentAccount.api.get(`vizz_maker.vizz_path(LMg4D)`, {seconds: 0}) as VizzModel
                        this.initializeVizzFields(vizz)
                        await this.changeState.loadAndPlay()
                    },
                })
            }
            return Promise.resolve(false)
        }
    }

    // Hack: This whole method is a hack
    private vizzWithSuffix(vizz: VizzModel) {
        //vizz.id = vizz.id.toString().includes('::') ? vizz.id : `${vizz.id}::${this.suffix}`
        return vizz
    }

    private initializeVizzFields(vizz?: VizzModel) {
        this.consoleDebug(`initializeVizzFields()`)

        let originalVizzTitle = this.vizz.title // we want to track if it changes

        if (vizz) runInAction(() => { this.vizz = this.vizzWithSuffix(vizz) })
        void this.prefetchMediaForNewSlides() // this is async but we don't want to wait for it here

        if (this.state != VizzControllerState.UNINITIALIZED &&
            this.vizz.audioUrl && !this.audioTrack) {
            this.audioTrack = new VizzAudioService(this.vizz.audioUrl, 'foreground '+this.vizz.id)
        }
        if (this.state != VizzControllerState.UNINITIALIZED &&
            this.vizz.audioBackgroundUrl && !this.audioTrackBackground) {
            this.audioTrackBackground = new VizzAudioService(this.vizz.audioBackgroundUrl, 'background '+this.vizz.id)
        }

        if (originalVizzTitle != this.vizz.title && this.state == VizzControllerState.PLAYING) {
            this.updateState(VizzControllerState.STOPPED)
            setTimeout(() => {
                void this.changeState.playFromStart() // vizz was swapped out with a placeholder vizz while playing
            }, 500)
        }
    }

    private async unloadAudio() {
        if (this.state == VizzControllerState.UNINITIALIZED) await this.audioTrack?.changeState.pauseAndUnload()
        if (this.state == VizzControllerState.UNINITIALIZED) await this.audioTrackBackground?.changeState.pauseAndUnload()
    }


    private async prefetchMediaForNewSlides() {
        this.consoleDebug(`prefetchMediaForNewSlides() — ${this.slidesPrefetchedUpToIndex+1} onward`)

        this.vizz.slides.slice(this.slidesPrefetchedUpToIndex + 1).forEach((slide) => {
            slide.media.forEach(async(media) => {
                let imageUrl:string | undefined = undefined

                switch (media.assetType) {
                    case AssetType.Image: {
                        imageUrl = media.assetUrl
                        break
                    }
                    case AssetType.Video: {
                        if (media.videoPosterUrl) imageUrl = media.videoPosterUrl
                        break
                    }
                }

                if (imageUrl) {
                    let prefetch = false
                    try {
                        prefetch = await Image.prefetch(imageUrl)
                    } catch(e) {
                        this.consoleDebug(`Image (${media.assetUrl}) was unable to be prefetched: ${(e as any).toString()}`, true)
                    } finally {
                        if (!prefetch)
                        this.consoleDebug(`Image (${media.assetUrl}) was unable to be prefetched.`, true)
                    }
                }
            })
        })

        this.slidesPrefetchedUpToIndex = this.vizz.slides.length-1
    }


    // Private instance utility methods

    private resetTimeout(timeout?: number) {
        if (timeout) clearTimeout(timeout)
        return undefined
    }

    private nextSlideIndex() {
        this.consoleDebug(`nextSlideIndex()`)
        if (this.currentSlideIndex == null) return null
        if (this.currentSlideIndex >= 0 &&
            this.currentSlideIndex < this.vizz.slides.length - 1)
            return this.currentSlideIndex + 1
        return null
    }

    private lastSlideIndex() {
        return this.vizz.slidesCount-1
    }

    private startAudioPositionForSlideAtIndex(index: number) {
        this.consoleDebug(`startAudioPositionForSlideAtIndex(${index})`)
        return this.vizz
            .slides.slice(0, index)
            .map((s) => {
                return s.audioDuration
            })
            .reduce((r, n) => {
                return r + n
            }, 0.0)
    }

    private tasted() {
        // Tasted means finished the 3rd slide of the video or played it through to the end. This is a key event we want to record.
        if (this.alreadyTasted) return
        this.alreadyTasted = true

        this.currentAccount.personData.numberOfVideosTasted += 1
        this.currentAccount.dayData.numberOfVideosTasted += 1
        this.logEvent('tasted')
    }

    private logEvent(verb: string, params: object = {}) {
        let allParams = {
            ...params,
            video_id: this.vizz.id,
            video_title: this.vizz.title,
            concept_id: this.concept?.id,
            concept_name: this.concept?.primary_name.name,
            number_of_videos_tasted_today: this.currentAccount.dayData.numberOfVideosTasted,
            number_of_videos_tasted_ever: this.currentAccount.personData.numberOfVideosTasted,
            '$set': {
                number_of_videos_tasted: this.currentAccount.personData.numberOfVideosTasted
            }
        }
        this.currentAccount.analytics.logEvent('video', verb, this.vizz.title, allParams)
    }

    private consoleDebug(method: string, force: boolean = false) {
        if(this.debug || force) console.log(`VizzPlayer: ${method}  state = ${this.state}  [${this?.vizz?.id || ''}]`)
    }
}

export default VizzController
