import {observer} from "mobx-react"
import React, {useContext, useMemo, useRef} from "react"
import {SafeAreaView, View} from "react-native"
import BottomSheet from '@gorhom/bottom-sheet'
import HomeController from "../controllers/HomeController"
import {FriendWidget} from "../../social/views/FriendWidget"
import {FeedWidget} from "../../social/views/FeedWidget"
import CurrentAccountContext from "../../vizz_account/lib/CurrentAccount"
import LogoBar from "./home/LogoBar"
import {FeatureFlagModel} from "../../vizz_account/models/FeatureFlagModel"
import {RenderLog} from "../../../app/services/RenderLog"

type Props = {
    homeController: HomeController
    barHeight: number
    onDebugTapped?: (() => void)
}

export const HomeMobile = observer((props: Props) => {
    const currentAccount = useContext(CurrentAccountContext)
    const bottomSheetRef = useRef<BottomSheet>(null)
    const snapPoints = useMemo(() => ['30%', '85%'], [])

    RenderLog.track('HomeMobile')

    return (
        <View style={{flex: 1, backgroundColor: 'transparent'}}>
            <SafeAreaView style={{flex: 1}}>
                <LogoBar
                    height={props.barHeight}
                    onDebugTapped={props.onDebugTapped}
                    homeController={props.homeController}
                    variant={'primarySmall'} />

                <FeedWidget
                    onVideoTapped={(video, position) => props.homeController.openVideo(video, 'tapped-inbox', position)}
                    homeController={props.homeController}
                    onTappedFriendPlayedGame={(book) => props.homeController.openBook(book, 'feed-friend-played', undefined, 'trailer')}
                    horizontal={false}
                    style={{
                        flex: 1,
                        marginTop: props.barHeight + 8,
                        marginStart: 0,
                        marginEnd: 0
                    }}/>
            </SafeAreaView>

            <BottomSheet
                backgroundStyle={{
                    backgroundColor: '#1B1F23',
                    borderTopColor: 'rgba(234,234,223,0.4)',
                    borderTopWidth: 1,
                    shadowColor: 'black',
                    shadowOpacity: 0.75,
                    shadowRadius: 10,
                    borderRadius: 0,
                    paddingTop: 0,
                }}
                handleIndicatorStyle={{backgroundColor: 'white'}}
                ref={bottomSheetRef}
                index={0}
                snapPoints={snapPoints}>
                <FriendWidget style={{flex: 1, marginTop: -10, marginBottom: 10, marginHorizontal: 2}}
                              mode={'sheet'}
                              homeController={props.homeController} />
            </BottomSheet>
        </View>
    )
})
