import {action, computed, makeObservable, observable} from "mobx"
import {CurrentAccount} from "../../vizz_account/lib/CurrentAccount"
import {AppController} from "../../../app/controllers/AppController"
import HomeController from "../../start/controllers/HomeController"
import {ProfileModel} from "../models/ProfileModel"
import StoryModel from "../models/StoryModel"
import {OnboardingController} from "../../onboarding/controllers/OnboardingController"
import SentryService from "../../../app/services/SentryService"
import {AppError} from "../../../app/lib/AppError"
import {AccountService} from "../../../app/services/AccountService"

export enum InboxWidgetState {
    LOADING,
    LOADED
}

export class InboxWidgetController {
    private debug: boolean = false  // don't set this to true in production

    private currentAccount: CurrentAccount
    private homeController: HomeController
    public appController: AppController
    private accountService = new AccountService()

    @observable state: InboxWidgetState = InboxWidgetState.LOADING;         @action private setState(state: InboxWidgetState) { this.state = state }
    @observable notReadMessageStories: StoryModel[] = [];                   @action private setNotReadMessageStories(stories: StoryModel[]) { this.notReadMessageStories = stories }


    constructor(currentAccount: CurrentAccount, homeController: HomeController, appController: AppController) {
        this.consoleDebug(`new()`)

        this.currentAccount = currentAccount
        this.homeController = homeController
        this.homeController.inboxWidgetController = this
        this.appController = appController
        this.accountService.initialize(this.currentAccount)

        makeObservable(this)
    }

    public async initialize() {
        this.consoleDebug(`initialize()`)

        await this.refreshInbox()

        this.setState(InboxWidgetState.LOADED)
    }

    public uninitialize() {
        this.consoleDebug(`uninitialize()`)
    }


    // Public methods

    public async refreshInbox() {
        this.setState(InboxWidgetState.LOADING)

        let notReadMessageStories:StoryModel[] = []
        try {
            notReadMessageStories = await this.currentAccount.api.get('social.not_read_message_stories_path') as StoryModel[]
            notReadMessageStories = notReadMessageStories.filter((s) => s.source && s.source.story_type && s.source.story_type !== 'FriendRequest')
        } catch(error) {
            if (error instanceof AppError) return
            this.consoleDebug(`Error while loading all inbox stories from server: ${error}`)
            this.currentAccount.analytics.logEvent('inbox-widget', 'refresh-failed', undefined, {error: error})
            SentryService.captureError(error)
        }

        this.setNotReadMessageStories(notReadMessageStories)
        this.setState(InboxWidgetState.LOADED)

        this.homeController.onInboxRefresh()
    }

    public async acceptFriendRequest(friendRequestId: number) {
        try {
            await this.currentAccount.api.patch(`friend_request_path(${friendRequestId})`, {
                state: "accepted"
            })
        } catch (error) {
            SentryService.captureError(error)
        }
        await this.refreshInbox()
    }

    public async declineFriendRequest(friendRequestId: number) {
        try {
            await this.currentAccount.api.patch(`friend_request_path(${friendRequestId})`, {
                state: "declined"
            })
        } catch (error) {
            SentryService.captureError(error)
        }
        await this.refreshInbox()
    }

    public addSchool() {
        this.currentAccount.analytics.logEvent("inbox-story", "add-school-tapped")
        OnboardingController.setStep('school', this.currentAccount)
        this.homeController.goToOnboarding()
    }

    public connectRoblox() {
        this.currentAccount.analytics.logEvent("inbox-story", "connect-roblox-tapped")
        void this.accountService.logoutAndReset(this.appController)
    }

    public async callBack(callBackPersonKey: string) {
        this.currentAccount.analytics.logEvent("inbox-story", "call-back-tapped")
        await this.homeController.friendWidgetController?.openChannelDetails([callBackPersonKey], 'callback')
        // Trying to call back a person will also remove their missed call message from the inbox
        await this.currentAccount.api.patch('social.ignore_missed_calls_path', {person_key: callBackPersonKey})
        await this.refreshInbox()
    }

    public async ignoreMissedCallMessage(callBackPersonKey: string) {
        this.currentAccount.analytics.logEvent("inbox-story", "ignore-missed-call-tapped")
        await this.currentAccount.api.patch('social.ignore_missed_calls_path', {person_key: callBackPersonKey})
        await this.refreshInbox()
    }

    @computed
    get callAndChatMessageStories(): any[] {
        let notifications: any[] = []
        this.appController.call.availableCallNotifications.forEach((c) => notifications.push(c))
        this.appController.message.unreadMessages.forEach((m) => notifications.push(m))
        return notifications
    }

    @computed
    get messageStoriesLength(): number {
        return this.callAndChatMessageStories.length + this.notReadMessageStories.length
    }

    get hasUnreadMessageStories() {
        return this.notReadMessageStories.length > 0 || this.appController.message.unreadMessages.length > 0
    }

    // Private helper methods

    public onProfileTapped(profile: ProfileModel) {
        void this.homeController.friendWidgetController?.openChannelDetails([profile.person_key])
    }

    // Private instance utility methods

    private consoleDebug(method: string, force: boolean = false) {
        if (this.debug || force) console.log(`${this.constructor.name}: ${method}  state = ${this.state}`)
    }
}
