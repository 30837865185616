import React, {useContext, useEffect, useRef, useState} from 'react'
import {FriendWidgetController} from "../../controllers/FriendWidgetController"
import {observer} from "mobx-react"
import {SafeAreaView, StyleSheet, Text, View} from "react-native"
import CurrentAccountContext from '../../../vizz_account/lib/CurrentAccount'
import {AddFriendController} from '../../controllers/friend_widget/AddFriendController'
import {SpeechUtils} from '../../../../app/utils/AVUtils'
import {SearchForFriends} from '../../../../social/views/SearchForFriends'
import {FullscreenModal} from "../../../../app/views/components/FullscreenModal"
import {isTablet} from "../../../../app/lib/Appearance"
import {ProfileModel} from "../../models/ProfileModel"
import {ProfileController} from "../../../onboarding/controllers/steps/ProfileController"

type Props = {
    friendWidgetController: FriendWidgetController,
}

export const AddFriend = observer((props: Props) => {

    const currentAccount = useContext(CurrentAccountContext)
    const [controller] = useState<AddFriendController>(() => new AddFriendController(currentAccount, props.friendWidgetController))
    const inputRef = useRef<any>()
    const isMountedRef = useRef(false)
    const [speech] = useState(() => new SpeechUtils(currentAccount, true))
    const modalRef = useRef<any>()

    useEffect(() => {
        isMountedRef.current = true

        inputRef.current?.focus()
        controller.initialize()

        return () => {
            isMountedRef.current = false
            speech.unload()
        }
    }, [])

    // Because this is a modal, the component does not load & unload: it changes visibility
    useEffect(() => {
        if (props.friendWidgetController.addFriendVisible) {
            setTimeout(function () {
                void speech.speak("Search for your friends by their first name or Lava name to add them.")
            }, 1000)
        } else {
            speech.pause()
        }
    }, [props.friendWidgetController.addFriendVisible])

    const afterAddFriend = async () => {
        setTimeout(() => {
            modalRef.current?.dismiss()
        }, 250)
        await controller.friendWidgetController.appController.backgroundRefresh.call('socialPollCallback')
    }

    const onProfileTapped = async (profile: ProfileModel) => {
        if (currentAccount.profile?.username === ProfileController.LAVA_BOT_USERNAME) {
            props.friendWidgetController.hideAddFriend()
            await props.friendWidgetController.openChannelDetails([profile.person_key])
        }
    }

    return (
        <FullscreenModal ref={modalRef} visible={props.friendWidgetController.addFriendVisible}
                         onDidDisappear={() => props.friendWidgetController.hideAddFriend()}>
            <SafeAreaView style={{flex: 1}}>
                <View style={styles.container}>
                    <Text style={[styles.headerLabel, {marginTop: 12}]}>Find Friend</Text>
                    <SearchForFriends
                        style={{flex: 1, marginTop: 20}}
                        onProfileTapped={onProfileTapped}
                        onFinish={afterAddFriend}
                        friendWidgetController={props.friendWidgetController}
                        showRobloxFriends={true}
                    />
                </View>
            </SafeAreaView>
        </FullscreenModal>
    )
})

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: 'transparent',
        paddingVertical: 24,
        paddingHorizontal: isTablet() ? 200 : 10
    },
    headerLabel: {
        color: 'white',
        fontFamily: 'Agrandir-Medium',
        fontSize: 36
    },
    subheaderLabel: {
        color: 'white',
        fontFamily: 'Agrandir-Regular',
        fontSize: 16
    },
    inputField: {
        width: 360,
        marginTop: 24,
    },
    searchResultText: {
        flex: 1,
        color: "white",
        margin: 5
    }
})
