import {Text, View, ViewStyle} from "react-native"
import {OnboardingController} from "../../../controllers/OnboardingController"
import {observer} from "mobx-react"
import React from "react"
import {RobloxLoginResult} from "../../../controllers/steps/profile/RobloxLoginController"

type Props = {
    style?: ViewStyle
    onboardingController: OnboardingController
    onFinish: (result: RobloxLoginResult) => void
    onSkip: () => void
}

export const RobloxLogin = observer((props: Props) => {
    return (
        <View>
            <Text>Not available on web</Text>
        </View>
    )
})