import {ProfileStatusDetail} from "./ProfileStatusDetail"
import {RobloxGameModel} from "../../browse/models/RobloxGameModel"
import {ProfileStatus} from "./ProfileStatus"
import {GameBookModel} from "../../browse/models/GameBookModel"

export interface RobloxFriendModel {
    id: number
    person_key: string
    name: string
    user_id: number
    display_name: string
    avatar_url: string
    avatar_headshot_url: string
    is_online: boolean
    properties: RobloxFriendProperties
    latest_roblox_presence: RobloxPresence
}

export interface RobloxFriendProperties {
    recent_game_books: GameBookModel[]
}

export enum RobloxUserPresenceType {
    OFFLINE = 0,
    ONLINE = 1,
    IN_GAME = 2
}

export interface RobloxPresence {
    id: number
    user_presence_type: RobloxUserPresenceType
    last_location: string
    place_id?: number
    root_place_id?: number
    game_id?: string
    universe_id?: number
    last_online?: string
    updated_at: string
    game?: GameBookModel
    roblox_game?: RobloxGameModel
}

export class RobloxPresenceExtensions {

    static lastChanged(p: RobloxUserPresenceType) {
        switch (p) {
            case RobloxUserPresenceType.OFFLINE: return "Offline"
            case RobloxUserPresenceType.ONLINE: return "Online"
            case RobloxUserPresenceType.IN_GAME: return "Online, Playing"
            default: return "Offline"
        }
    }

    static status(p: RobloxUserPresenceType) {
        switch (p) {
            case RobloxUserPresenceType.ONLINE: return ProfileStatus.ONLINE
            case RobloxUserPresenceType.IN_GAME: return ProfileStatus.ONLINE
            case RobloxUserPresenceType.OFFLINE: return ProfileStatus.OFFLINE
            default: return ProfileStatus.OFFLINE
        }
    }

    static statusDetail(p: RobloxUserPresenceType) {
        switch (p) {
            case RobloxUserPresenceType.ONLINE: return ProfileStatusDetail.ROBLOX
            case RobloxUserPresenceType.IN_GAME: return ProfileStatusDetail.ROBLOX
            case RobloxUserPresenceType.OFFLINE: return undefined
            default: return undefined
        }
    }
}
