import {action, makeObservable, observable} from "mobx"
import {CurrentAccount} from "../../vizz_account/lib/CurrentAccount"
import {AppController} from "../../../app/controllers/AppController"
import BookModel from "../models/BookModel"
import SentryService from "../../../app/services/SentryService"
import {create, persist} from "mobx-persist"
import AsyncStorage from "@react-native-async-storage/async-storage"
import {ArrayUtils} from "../../../app/utils/ArrayUtils"

export enum LibraryWidgetState {
    LOADING,
    LOADED
}

const hydrate = create({
    storage: AsyncStorage,
    jsonify: true,
})

export class LibraryWidgetController {
    private debug: boolean = false  // don't set this to true in production

    private currentAccount: CurrentAccount
    public appController: AppController
    private timeoutHandler: any

    @observable state:LibraryWidgetState = LibraryWidgetState.LOADING
    @observable @persist('list') books:BookModel[] = []
    @observable showFirstBook: boolean = true

    constructor(currentAccount: CurrentAccount, appController: AppController) {
        this.consoleDebug(`new()`)

        this.currentAccount = currentAccount
        this.appController = appController

        makeObservable(this)
    }

    public async initialize() {
        this.consoleDebug(`initialize()`)

        await hydrate('library-widget', this)

        await this.refreshBooks()
    }

    public uninitialize() {
        this.consoleDebug(`uninitialize()`)
        clearTimeout(this.timeoutHandler)
    }


    // Public methods

    public async refreshBooks() {
        this.setState(LibraryWidgetState.LOADING)

        let books = []

        try {
            books = await this.currentAccount.api.get('browse.concept_books_path') as BookModel[]
        } catch (error) {
            SentryService.captureError(error)
            return
        }

        // TODO: Remove this hack
        books = books.filter((b) => b.title != 'Roblox')
        books = ArrayUtils.deduplicateArray(books, 'id')

        this.setBooks(books)
        //this.setShowFirstBook(false)
        // this.timeoutHandler = setTimeout(() => {
        //     this.setShowFirstChannel(true)
        // }, 2000)
        this.setShowFirstBook(true)
        this.setState(LibraryWidgetState.LOADED)
    }


    // Private helper methods

    @action
    private setState(state: LibraryWidgetState) {
        this.consoleDebug(`setState()`)
        this.state = state
    }

    @action
    private setShowFirstBook(state: boolean) {
        this.consoleDebug(`setShowFirstBook()`)
        this.showFirstBook = state
    }

    @action
    private setBooks(channels: any) {
        this.consoleDebug(`setBooks()`)
        this.books = channels
    }


    // Private instance utility methods

    private consoleDebug(method: string, force: boolean = false) {
        if (this.debug || force) console.log(`${this.constructor.name}: ${method}  state = ${this.state}`)
    }
}
