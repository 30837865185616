import React from 'react'
import {VizzModel} from "../../../modules/vizz_maker/models/VizzModel"
import {FlatGrid} from "react-native-super-grid"
import {Image, ListRenderItemInfo, StyleSheet, View, ViewStyle} from "react-native"
import {Subheading, TouchableRipple} from "react-native-paper"
import {PlayButton} from "./PlayButton"

type Props = {
    vizzes: VizzModel[]
    onPlayVizz?: ((vizz: VizzModel) => void)
    style?: ViewStyle
}

const VizzGridView = (props: Props) => {

    const renderGridItem = (info: ListRenderItemInfo<VizzModel>) => {
        const onPlay = () => {
            if (props.onPlayVizz) {
                props.onPlayVizz(info.item)
            }
        }

        return (
            <TouchableRipple onPress={() => {
                onPlay()
            }}>
                <View>
                    <View style={{height: 150}}>
                        <Image style={{flex: 1}} source={{uri: info.item.coverImageUrl}}/>
                        <View
                            style={{...StyleSheet.absoluteFillObject, justifyContent: 'center', alignItems: 'center'}}>
                            <PlayButton size={44} onPress={() => {
                                onPlay()
                            }}/>
                        </View>
                    </View>
                    <Subheading style={{height: 40, marginTop: 8, textAlign: 'center'}}
                                numberOfLines={2}>{info.item.title}</Subheading>
                </View>
            </TouchableRipple>
        )
    }

    return (
        <FlatGrid
            style={{...props.style}}
            itemDimension={200}
            renderItem={renderGridItem}
            data={props.vizzes}
            spacing={16}
        />
    )
}

export default VizzGridView
