import React, {useContext, useEffect, useState} from 'react'
import {FriendWidgetController} from "../../controllers/FriendWidgetController"
import {observer} from "mobx-react"
import {ScrollView, StyleSheet, Text, View} from "react-native"
import {Button, Checkbox, IconButton, Modal, TouchableRipple} from "react-native-paper"
import {ProfileModel} from "../../models/ProfileModel"
import {Color, isPortrait} from "../../../../app/lib/Appearance"
import {MaterialCommunityIcons} from "@expo/vector-icons"
import {ProfileStatus} from "../../models/ProfileStatus"
import {GroupCallController, GroupCallProfileViewModel} from '../../controllers/friend_widget/GroupCallController'
import CurrentAccountContext from '../../../vizz_account/lib/CurrentAccount'
import {IconSource} from "react-native-paper/lib/typescript/components/Icon"

export type SelectFriendsAction = {
    title: string
    icon: IconSource
    color: string
    minCount: number
    onAction: (selectedFriends: ProfileModel[]) => void
}

type Props = {
    friendWidgetController: FriendWidgetController,
    visible: boolean
    actions: SelectFriendsAction[]
    onDismiss: () => void
    excludeProfiles?: ProfileModel[]
}

export const SelectFriends = observer((props: Props) => {

    const currentAccount = useContext(CurrentAccountContext)
    const [controller] = useState<GroupCallController>(() => new GroupCallController(currentAccount, props.friendWidgetController))
    const [friends, setFriends] = useState<GroupCallProfileViewModel[]>([])

    useEffect(() => {
        controller.initialize()
        setFriends(controller.resetFriends(props.excludeProfiles))
    }, [])

    // Because this is a modal, the component does not load & unload: it changes visibility
    useEffect(() => {
        setFriends(controller.resetFriends(props.excludeProfiles))

        if (props.friendWidgetController.groupCallPreselect && props.friendWidgetController.groupCallPreselect.length > 0) {
            props.friendWidgetController.groupCallPreselect.forEach((p) => {
                controller.toggleSelected({profile: p, isSelected: false} as GroupCallProfileViewModel)
            })
        }
    }, [props.visible])

    useEffect(() => {
        setFriends(controller.friends)
    }, [controller.selectedCount])


    const renderRow = (vm: GroupCallProfileViewModel) => {
        const statusColor = vm.profile.status == ProfileStatus.ONLINE ? 'lightgreen' : 'orangered'
        const statusWord = vm.profile.status == ProfileStatus.ONLINE ? 'online' : 'offline'
        const statusIcon =  'checkbox-blank-circle'

        return (
            <TouchableRipple
                onPress={() => controller.toggleSelected(vm)}
                key={`touchable-${vm.profile.person_key}`}>
                <View
                    style={{flex: 1, flexDirection: 'row', height: 44, alignItems: 'center'}}
                    key={vm.profile.person_key}>
                    <Checkbox.Android
                        status={vm.isSelected ? 'checked' : 'unchecked'}
                        color={Color.primary}
                        uncheckedColor={'white'}
                        key={`checkbox-${vm.profile.person_key}`}/>
                    <Text key={`text1-${vm.profile.person_key}`} numberOfLines={1} style={[styles.statusLabel, {fontSize: isPortrait() ? 10 : 14}]}>{vm.profile.pretty_username}</Text>
                    <Text key={`text2-${vm.profile.person_key}`} style={[styles.statusLabel, {color: statusColor, paddingLeft: 10, fontSize: isPortrait() ? 10 : 14}]}>
                        <MaterialCommunityIcons key={`icon-${vm.profile.person_key}`} style={{marginEnd: 4}} name={statusIcon} size={16} color={statusColor} backgroundColor={statusColor} /> {statusWord}
                    </Text>
                </View>
            </TouchableRipple>
        )
    }

    const selectedProfiles = () => {
        return friends
            .filter((f) => f.isSelected)
            .map((f) => f.profile)
    }

    return (
        <Modal style={{justifyContent: 'center', alignItems: 'center'}} visible={props.visible} onDismiss={props.onDismiss}>
            <View style={styles.container}>
                <View style={{flexDirection: 'row-reverse'}}>
                    <IconButton
                        onPress={() => props.onDismiss()}
                        icon={'close'}
                        size={30}
                        color={'white'} />
                    <Text style={[styles.headerLabel, {flexGrow: 1}]}>Select Friends</Text>
                </View>

                <ScrollView style={{flex: 1, marginBottom: 10 }}>
                    {friends.map((f) => renderRow(f))}
                </ScrollView>

                <View style={{flexDirection: 'row', marginBottom: 24}}>
                    {props.actions.map((a) => {
                        return (
                            <Button
                                key={a.title}
                                style={{flexGrow: 1, height: 40, margin: 8, backgroundColor: a.color}}
                                onPress={() => {
                                    props.onDismiss()
                                    a.onAction(selectedProfiles())
                                }}
                                disabled={friends.filter((f) => f.isSelected).length < a.minCount}
                                color={a.color}
                                icon={a.icon}
                                dark={true}
                                mode={'contained'}
                                uppercase={false}>
                                {a.title}
                            </Button>
                        )
                    })}
                </View>

            </View>
        </Modal>
    )
})

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: 'black',
        width: 400,
        padding: 24,
        borderRadius: 6,
        borderWidth: 0.4,
        borderColor: 'rgba(255, 255, 255, 0.3)'
    },
    headerLabel: {
        color: 'white',
        fontFamily: 'Agrandir-Medium',
        fontSize: 36
    },
    subheaderLabel: {
        color: 'white',
        fontFamily: 'Agrandir-Regular',
        fontSize: 16
    },
    statusLabel: {
        color: 'white',
        fontFamily: 'Agrandir-Medium'
    }
})
