import React, {useCallback, useContext, useEffect, useRef, useState} from 'react'
import {VizzModel, VizzCollection} from "../../../modules/vizz_maker/models/VizzModel"
import {ContentTypeFilter, ContentTypeFilterUtil} from "../../utils/EnumUtils"
import {FlatList, Image, ListRenderItemInfo, StyleSheet, View, ViewToken} from "react-native"
import {Headline, Subheading, TouchableRipple} from "react-native-paper"
import {FlatGrid} from "react-native-super-grid"
import {RouteProp, useFocusEffect, useNavigation} from "@react-navigation/native"
import {RootDrawerNavigatorList} from "../../lib/interfaces/root-tab-navigator"
import {StackNavigationProp} from "@react-navigation/stack"
import {Color, isPortrait} from "../../lib/Appearance"
import {MaterialIcons} from '@expo/vector-icons'
import Vizz from "../../../modules/vizz_maker/views/Vizz"
import FixedAspectView from "./../components/FixedAspectView"
import CurrentAccountContext from '../../../modules/vizz_account/lib/CurrentAccount'

export type CollectionsScreenRouteProp = RouteProp<RootDrawerNavigatorList, 'Collections'>

export type CollectionsScreenNavigationProp = StackNavigationProp<
    RootDrawerNavigatorList,
    'Collections'>

const CollectionsScreen = () => {

    const navigation = useNavigation<any>()
    const currentAccount = useContext(CurrentAccountContext)
    const [collections, setCollections] = useState<VizzCollection[]>([])
    const [vizzes, setVizzes] = useState<VizzModel[]>([])
    const [refreshing, setRefreshing] = useState<boolean>(false)
    const [showingVoicePrompt, setShowingVoicePrompt] = useState<boolean>(false)

    useFocusEffect(
        useCallback(() => {
            return () => {
                currentAccount.updatePlayingVizz(null)
            }
        }, [])
    )

    useEffect(() => {
        void refresh()
    }, [])

    const refresh = async() => {
        if (refreshing) return

        try {
            const results = await currentAccount.api.get('vizzes_path', { content_type: ContentTypeFilterUtil.apiParam(ContentTypeFilter.collection)}) as VizzModel[]
            setCollections(VizzCollection.fromVizes(results))

            let vizzes: VizzModel[] = []
            const wow = await currentAccount.api.get('vizzes_path', { content_type: ContentTypeFilterUtil.apiParam(ContentTypeFilter.wow)}) as VizzModel[]
            const howTo = await currentAccount.api.get('vizzes_path', { content_type: ContentTypeFilterUtil.apiParam(ContentTypeFilter.howTo)}) as VizzModel[]

            wow.forEach((v) => vizzes.push(v))
            howTo.forEach((v) => vizzes.push(v))

            setVizzes(vizzes)

            setRefreshing(false)

        } catch {
            console.log('Error loading collections')
        }
    }

    // Collections Grid

    const collectionsGrid = () => {
        return (
            <FlatGrid
                ListHeaderComponent={speakButton}
                style={styles.collection}
                itemDimension={200}
                renderItem={renderGridItem}
                data={collections}
                spacing={16}
            />
        )
    }

    const renderGridItem = (info: ListRenderItemInfo<VizzCollection>) => {
        return (
            <TouchableRipple onPress={() => {
                navigation.navigate('CollectionDetail', {collection: info.item})
            }}>
                <View>
                    <View style={{height: 150}}>
                        {info.item.data.length > 2 &&
                        <View style={[styles.coverImage, {transform: [{rotate: '2deg'}]}]}>
                            <Image style={{flex: 1}} source={{uri: info.item.data[2].coverImageUrl}}/>
                        </View>
                        }
                        {info.item.data.length > 1 &&
                        <View style={[styles.coverImage, {transform: [{ rotate: '-3deg' }]}]}>
                            <Image style={{flex: 1}} source={{uri: info.item.data[1].coverImageUrl}}/>
                        </View>
                        }
                        <View style={[styles.coverImage]}>
                            <Image style={{flex: 1}} source={{uri: VizzCollection.collectionCover(info.item)}}/>
                        </View>
                    </View>
                    <Subheading style={{height: 40, marginTop: 8, textAlign: 'center'}}
                                numberOfLines={2}>{info.item.title}</Subheading>
                </View>
            </TouchableRipple>
        )
    }

    const speakButton = () => {
        return (
            <View style={{flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginTop: 24, marginBottom: 32}}>
                <TouchableRipple style={{borderRadius: 50}} onPress={() => {setShowingVoicePrompt(true)}}>
                    <View style={styles.micButton}>
                        <MaterialIcons name="mic" size={60} color="black"/>
                    </View>
                </TouchableRipple>
                <TouchableRipple style={{borderRadius: 50, position: 'absolute', top: 0, bottom: 0, right: 16}}>
                    <View style={[styles.micButton, {backgroundColor: Color.primary}]}>
                        <MaterialIcons name="camera-alt" size={60} color="white"/>
                    </View>
                </TouchableRipple>
            </View>
        )
    }

    // Vizz List

    const viewabilityConfig = {
        waitForInteraction: true,
        itemVisiblePercentThreshold: 75,
    }

    const onViewableItemsChanged = useRef((result: { viewableItems: Array<ViewToken>, changed: Array<ViewToken> }) => {
        // it's possible that viewableItems items includes some that are not viewable, not sure why
        const definitelyViewableItems = result.viewableItems.filter((item) => item.isViewable)


        if (definitelyViewableItems.length == 0) {
            currentAccount.updatePlayingVizz(null)
            return
        }

        if (definitelyViewableItems[0].item.id != currentAccount.playingVizzId) {
            currentAccount.updatePlayingVizz(definitelyViewableItems[0].item.id)
        }
    })

    const renderListItem = (info: ListRenderItemInfo<VizzModel>) => {
        const item: VizzModel = info.item

        return (
            <View style={styles.vizzItem}>
                <Headline style={{marginBottom: 8, marginTop: 16}}>{item.title}</Headline>
                <FixedAspectView aspectRatio={3/4}>
                    <Vizz vizz={item} />
                </FixedAspectView>
            </View>
        )
    }

    const renderList = () => {
        return (
            <FlatList
                ListHeaderComponent={collectionsGrid}
                style={{flex: 1}}
                viewabilityConfig={viewabilityConfig}
                onViewableItemsChanged={onViewableItemsChanged.current}
                data={vizzes}
                renderItem={renderListItem}
                keyExtractor={item => item.id}
            />
        )
    }

    const showVizz = (key?: string) => {
        setShowingVoicePrompt(false)
        if (key) {
            navigation.navigate('VizzDetail', {vizzId: key, autoPlay: true})
        }

    }

    return (
        <View style={{flex: 1}}>
            {renderList()}
        </View>
    )
}

const styles = StyleSheet.create({
    collection: {
        flex: 1
    },
    coverImage: {
        borderWidth: 2,
        borderColor: 'white',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        height: 150,
        shadowOffset: {
            width: 0.5,
            height: 0.5
        },
        shadowColor: 'black',
        shadowOpacity: 0.6,
        shadowRadius: 4
    },
    micButton: {
        justifyContent: 'center',
        alignItems: 'center',
        shadowRadius: 6,
        shadowColor: 'black',
        shadowOpacity: 0.7,
        width: 100,
        height: 100,
        borderRadius: 50,
        backgroundColor: Color.secondary
    },
    vizzItem: {
        flex: 1,

        paddingVertical: isPortrait() ? "1%" : "3%",
        paddingHorizontal: isPortrait() ? "1%" : "25%"
    }
})

export default CollectionsScreen
