import {CurrentAccount} from "../../../modules/vizz_account/lib/CurrentAccount"

export enum AgeBand {
    CHILD_2_4 = 'child-2-4',
    CHILD_5_8 = 'child-5-8',
    CHILD_9_12 = 'child-9-12',
    CHILD_1 = 'child-1',
    CHILD_2 = 'child-2',
    CHILD_3 = 'child-3',
    CHILD_4 = 'child-4',
    CHILD_5 = 'child-5',
    CHILD_6 = 'child-6',
    CHILD_7 = 'child-7',
    CHILD_8 = 'child-8',
    CHILD_9 = 'child-9',
    CHILD_10 = 'child-10',
    CHILD_11 = 'child-11',
    CHILD_12 = 'child-12',
    TEEN = 'teen',
    GROWN_UP = 'grown-up'
}

export class AgeBandService {

    public static sampleQuestions(currentAccount: CurrentAccount): string[]|null {
        if (!currentAccount.personData.person?.properties.age_band) return null
        //if (currentAccount.personData.numberOfOpens >= 10) return null
        const band = currentAccount.personData.person.properties.age_band as AgeBand

        switch (band) {
            case AgeBand.CHILD_1: {
                return [
                    "Why are butterflies so colorful?",
                    "Why can't cars go as fast as airplanes?",
                    "Who invented ice cream?"
                ]
            }
            case AgeBand.CHILD_2: {
                return [
                    "Why are butterflies so colorful?",
                    "Why can't cars go as fast as airplanes?",
                    "Who invented ice cream?"
                ]
            }
            case AgeBand.CHILD_3: {
                return [
                    "Why are butterflies so colorful?",
                    "Why can't cars go as fast as airplanes?",
                    "Who invented ice cream?"
                ]
            }
            case AgeBand.CHILD_4: {
                return [
                    "Why are butterflies so colorful?",
                    "Why can't cars go as fast as airplanes?",
                    "Who invented ice cream?"
                ]
            }
            case AgeBand.CHILD_5: {
                return [
                    "How do locks work?",
                    "How long has Minecraft been around?",
                    "What's the most rare kind of weather?"
                ]
            }
            case AgeBand.CHILD_6: {
                return [
                    "How do locks work?",
                    "How long has Minecraft been around?",
                    "What's the most rare kind of weather?"
                ]
            }
            case AgeBand.CHILD_7: {
                return [
                    "How do locks work?",
                    "How long has Minecraft been around?",
                    "What's the most rare kind of weather?"
                ]
            }
            case AgeBand.CHILD_8: {
                return [
                    "How do locks work?",
                    "How long has Minecraft been around?",
                    "What's the most rare kind of weather?"
                ]
            }
            case AgeBand.CHILD_9: {
                return [
                    "Who invented ice cream?",
                    "How do you create a new NFL team?",
                    "How long has Minecraft been around?"
                ]
            }
            case AgeBand.CHILD_10: {
                return [
                    "Who invented ice cream?",
                    "How do you create a new NFL team?",
                    "How long has Minecraft been around?"
                ]
            }
            case AgeBand.CHILD_11: {
                return [
                    "Who invented ice cream?",
                    "How do you create a new NFL team?",
                    "How long has Minecraft been around?"
                ]
            }
            case AgeBand.CHILD_12: {
                return [
                    "Who invented ice cream?",
                    "How do you create a new NFL team?",
                    "How long has Minecraft been around?"
                ]
            }
            case AgeBand.TEEN: {
                return [
                    "Why can't fish breathe out of water?",
                    "What's the most rare kind of weather?",
                    "How do locks work?",
                ]
            }
            case AgeBand.GROWN_UP: {
                return [
                    "Why can't fish breathe out of water?",
                    "How do you avoid poisonous mushrooms?",
                    "How do locks work?",
                ]
            }
        }

        return null
    }


}
