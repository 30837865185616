import React from 'react'
import {isLandscape, isPortrait} from "../../../../app/lib/Appearance"
import BookLandscape from './BookLandscape'
import SeriesController from '../../controllers/library_widget/SeriesController'
import BookPortrait from './BookPortrait'

export type BookProps = {
    seriesController: SeriesController
    bookIndex: number
    searchBarHeight: number
    covered: boolean
}

const Book = (props: BookProps) => {
    return (
        <>
        {isLandscape() && <BookLandscape {...props}/>}
        {isPortrait() && <BookPortrait {...props}/>}
        </>
    )
}

export default Book
