import React, {useEffect} from "react"
import {ConceptNode, ConceptStore} from "../../../lib/services/ConceptStore"
import {observer} from "mobx-react"
import {FlatList, ListRenderItemInfo, View} from "react-native"
import {Divider, Headline, Paragraph, TouchableRipple} from "react-native-paper"

type Props = {
    title: string,
    store: ConceptStore
    node: ConceptNode
    includeParent: boolean,
    onSelect: (selectedNode: ConceptNode) => void
}

const SelectSiblingNode = observer((props: Props) => {

    useEffect(() => {
        if (props.node.immediate_descendents.length == 0) {
            void props.store.fetchNode(props.node)
        }
    }, [])

    const renderItem = (info: ListRenderItemInfo<any>) => {
        return (
            <TouchableRipple
                key={info.item.id}
                onPress={() => props.onSelect(info.item)}>
                <view>
                    <View style={{marginTop: 8, marginBottom: 8}}>
                        <Paragraph>{info.item.primaryName}</Paragraph>
                    </View>
                    <Divider/>
                </view>
            </TouchableRipple>
        )
    }

    const data = () => {
        let data = props.store.siblingsForNode(props.node)
        if (props.includeParent && props.node.parent) {
            data.unshift(props.node.parent)
        }

        return data
    }

    return (
        <View style={{backgroundColor: 'white', width: 500, height: 900}}>
            <Headline style={{margin: 16}}>{props.title}</Headline>
            <FlatList
                style={{margin: 16}}
                data={data()}
                renderItem={renderItem}/>
        </View>
    )

})

export default SelectSiblingNode