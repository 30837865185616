import React, {useContext, useState} from 'react'
import {StyleSheet, View, Text, ViewStyle} from "react-native"
import {ActivityIndicator, Button, TouchableRipple} from "react-native-paper"
import {observer} from "mobx-react"
import {Color} from "../../../../app/lib/Appearance"
import CurrentAccountContext from "../../../vizz_account/lib/CurrentAccount"
import AppControllerContext from '../../../../app/controllers/AppController'
import {Entypo} from '@expo/vector-icons'
import {FeatureFlagModel} from '../../../vizz_account/models/FeatureFlagModel'
import {LavaImage} from "../../../../app/views/components/LavaImage"
import PersonModel from '../../../vizz_account/models/PersonModel'

type Props = React.PropsWithChildren<{
    style?: ViewStyle
    imageUrl?: string
    onPlay: () => void
}>

export const Cover = observer((props: Props) => {
    const currentAccount = useContext(CurrentAccountContext)
    const appController = useContext(AppControllerContext)
    const [layout, setLayout] = useState({x: 0, y: 0, width: 0, height: 0})

    const renderLoading = () => {
        return (
            <View style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}>
                <ActivityIndicator color={Color.primary} />
            </View>
        )
    }

    const buttonWidth = () => {
        return Math.max(Math.round(layout.width / 10), 60)
    }

    return (
        <TouchableRipple onPress={props.onPlay} onLongPress={props.onPlay} style={[styles.container, props.style]} onLayout={(e) => setLayout(e.nativeEvent.layout)}>
            <View style={{flex: 1}}>
                <View style={styles.behindCover} />
                <LavaImage style={{width: '100%', height: '100%', opacity: 0.5}} resizeMode={'cover'} source={{uri: props.imageUrl}} />

                <View style={styles.buttonContainer}>
                    <View style={{width: buttonWidth(), height: buttonWidth()}}>
                        <Entypo name={'controller-play'} color={'white'} size={buttonWidth()} />
                    </View>
                </View>

                {currentAccount.hasFeature(FeatureFlagModel.MODERATION) && props.children}
            </View>
        </TouchableRipple>
    )
})

const styles = StyleSheet.create({
    container: {
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        position: 'absolute',
        zIndex: 100,
    },

    behindCover: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        backgroundColor: 'black',
    },

    buttonContainer: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
    },
})
