import React, {useContext, useEffect, useState} from 'react'
import {Alert, ScrollView} from "react-native"
import {Divider, List, Switch} from "react-native-paper"
import * as Updates from 'expo-updates'
import {useNavigation} from "@react-navigation/native"
import CurrentAccountContext from '../../modules/vizz_account/lib/CurrentAccount'
import {DailyQuestionStore} from "../lib/services/DailyQuestionStore"
import {FeedStore} from "../lib/services/FeedStore"
import {observer, Observer} from "mobx-react"
import {DebugAutoUpdate} from './debug/DebugAutoUpdate'
import {ManifestUtils} from '../utils/ManifestUtils'
import {AccountService} from '../services/AccountService'
import LavaComponentsCatalog from "../../modules/lava-components/LavaComponentsCatalog"
import * as StoreReview from "expo-store-review"
import SentryService from "../services/SentryService"

export const DebugScreen = observer(() => {

    const currentAccount = useContext(CurrentAccountContext)
    const navigation = useNavigation<any>()
    const [feedStore] = useState(() => new FeedStore(currentAccount))
    const [deviceKey, setDeviceKey] = useState('')
    const [dailyQuestionStore] = useState(() => new DailyQuestionStore(currentAccount))
    const [account] = useState<AccountService>(() => new AccountService())
    const [isShowingComponentCatalog, setShowingComponentCatalog] = useState(false)

    useEffect(() => {
        const id = currentAccount.personData.deviceKey
        setDeviceKey(id ?? 'Unknown')
        account.initialize(currentAccount)
    }, [])

    const tester = () => {
        console.log('inside tester')
        const pretty_username:string = 'first '

        console.log(`hello ${pretty_username.split(' ').map((s) => s[0].toUpperCase()).join('')}`)
    }

    const clearAllDataAndRestart = async () => {
        await account.logoutAndReset()
    }

    if (isShowingComponentCatalog) {
        return <LavaComponentsCatalog/>
    }

    return (
        <ScrollView style={{backgroundColor: 'white'}}>
            <List.Section>
                <List.Subheader>Deployed</List.Subheader>
                <Divider/>
                <List.Item
                    title={"React Update Number"}
                    description={ManifestUtils.clientUpdateNumber}/>
                <Divider/>
                <List.Item
                    title={"React Env"}
                    description={ManifestUtils.reactEnv}/>
                <Divider/>
                <List.Item
                    title={"Server Update Number"}
                    description={currentAccount.serverUpdateNumber}/>
                <Divider/>
                <List.Item
                    title={"Distinct Device Key"}
                    description={deviceKey}/>
                <Divider/>
                <List.Item
                    title={"Reset"}
                    description={'Clears person data — Restart App after'}
                    onPress={() => {
                        Alert.alert('Reset Data',

                            'Are you sure you want to reset data?',
                            [
                                {text: 'Reset Data', style: 'destructive', onPress: async () => {
                                        await clearAllDataAndRestart()
                                    }},
                                {text: 'Cancel', style: 'cancel'}
                            ]
                            )
                    }}/>
            </List.Section>

            <List.Section>
                <List.Subheader>Design</List.Subheader>
                <Divider/>
                <List.Item
                    title={"Component Catalog"}
                    description={'View the standard component library catalog'}
                    onPress={() => {
                        setShowingComponentCatalog(true)
                    }}/>
            </List.Section>

            <List.Section>
                <List.Subheader>Bundle Info</List.Subheader>
                <Divider/>
                <List.Item
                    title={"Bundle Id"}
                    description={Updates.updateId}/>
                <Divider/>
                <List.Item
                    title={"Release Channel"}
                    description={Updates.channel}/>
                <Divider/>
                <List.Item
                    title={"View Manifest"}
                    description={'View the entire release manifest'}
                    right={props => <List.Icon {...props} icon='chevron-right'/>}
                    onPress={() => navigation.navigate('JsonDebug', {jsonString: Updates.manifest})
                    }/>
            </List.Section>

            <List.Section>
                <List.Subheader>Settings</List.Subheader>
                <Divider/>
                <List.Item
                    title={`Force Create Vizz`}
                    description={'When enabled, search always generates a new vizz (no cache)'}
                    right={() =>
                        <Observer>{() => <Switch
                            value={currentAccount.personData.debugForceVizzCreate}
                            onValueChange={(v) => {
                                currentAccount.personData.debugForceVizzCreate = v
                            }}/>}
                        </Observer>

                    }
                />
            </List.Section>

            <DebugAutoUpdate />

            <List.Section>
                <List.Subheader>Testing</List.Subheader>
                <Divider/>
                <List.Item
                    title={"Throw Error - Real"}
                    onPress={() => tester()}
                />
                <Divider/>
                <List.Item
                    title={"Throw Error - New Object"}
                    onPress={() => { throw new Error(`new error: ${Math.round(Math.random()*1000)}`)}}
                />
                <Divider/>
                <List.Item
                    title={"Throw Error - Implicit Object"}
                    onPress={() => { throw Error(`implicit error: ${Math.round(Math.random()*1000)}`)}}
                />
                <Divider/>
                <List.Item
                    title={"FAST progressive loading"}
                    onPress={() => console.log(`needs to call: showVizz('test_fast_loading')`)}
                />
                <Divider/>
                <List.Item
                    title={"SLOW progressive loading"}
                    onPress={() => console.log(`needs to call: showVizz('test_slow_loading')`)}
                />
                <Divider/>
                <List.Item
                    title={"New Home Screen Lift"}
                    onPress={()=> navigation.navigate('NewHomeScreenLift')}
                />
                <List.Item
                    title={"New Home Screen"}
                    onPress={()=> navigation.navigate('NewHomeScreen')}
                />
                <List.Item
                    title={"Trigger App Rating"}
                    onPress={async() => {
                        try {
                            if (await StoreReview.isAvailableAsync()) {
                                await StoreReview.requestReview()
                            }
                        } catch (error) {
                            SentryService.captureError(error)
                        }
                    }}
                />
            </List.Section>
        </ScrollView>
    )
})