import React, {useContext, useEffect, useState} from 'react'
import {observer} from "mobx-react"
import {DefaultTheme, Provider as PaperProvider} from 'react-native-paper'
import {Alert, KeyboardAvoidingView, LogBox, Platform, View} from "react-native"
import * as ScreenOrientation from 'expo-screen-orientation'
import {Color, isLandscape, isPortrait} from "./app/lib/Appearance"
import CurrentAccountContext from "./modules/vizz_account/lib/CurrentAccount"
import AppControllerContext from "./app/controllers/AppController"
import {GestureHandlerRootView} from 'react-native-gesture-handler'
import {FullscreenLoading} from "./app/views/components/FullscreenLoading"
import {LavaSplashScreen} from "./app/views/LavaSplashScreen"
import AppMessagesContext, {AppError, AppInfoMessage} from "./app/lib/AppMessage"
import SentryService from "./app/services/SentryService"
import {WebNavigation} from "./app/views/WebNavigation"
import {MobileNavigation} from "./app/views/MobileNavigation"
import {ManifestUtils} from "./app/utils/ManifestUtils"
import {AppState} from "./app/controllers/AppControllerState"
import {TaskManagerService} from "./app/services/TaskManagerService"
import {Typography} from './app/lib/Typography'
import ThemeContextProvider from "./modules/lava-components/components/contexts/ThemeContext"
import {ActivityName, TimedActivity} from "./app/lib/services/TimedActivity"
import * as SplashScreen from "expo-splash-screen"
import {LavaToastOverlay} from "./app/views/LavaToast"
import {LavaAlertOverlay} from "./app/views/components/LavaAlert"
import {AccountService} from './app/services/AccountService'
import {CustomSafeAreaProvider} from './app/views/components/CustomSafeAreaProvider'

// Warnings due to a dependency using this feature (we are not): https://github.com/facebook/react-native/issues/33557  Remove after Expo upgrade
LogBox.ignoreLogs(["ViewPropTypes will be removed from React Native"])

// We don't really need to know every time an image is missing, it will be obvious visibly
LogBox.ignoreLogs(['source.uri should not be an empty string'])

// We can't do anything about this, Agora will fix it eventually.
LogBox.ignoreLogs(['Module AgoraRtcNg requires main'])

// Some libraries are using this and causing the warning.
LogBox.ignoreLogs(['Constants.platform.ios.model'])

try {
    SentryService.crashlyticsLog('prevent-hide-splash')
    void SplashScreen.preventAutoHideAsync()
} catch (error) {
    SentryService.captureError(error)
}

TimedActivity.start(ActivityName.APP_INIT)
TimedActivity.log(ActivityName.APP_INIT, 'pre-view')

SentryService.crashlyticsLog('init-sentry')
SentryService.initialize()

SentryService.crashlyticsLog('init-tasks')
TaskManagerService.initializeFromGlobalScope()

SentryService.crashlyticsLog('init-fonts')
void Typography.initialize()

SentryService.crashlyticsLog('pre-app-init-done')

const App = observer(() => {
    const currentAccount = useContext(CurrentAccountContext) // this executes new CurrentAccount()
    const appController = useContext(AppControllerContext) // this executes new AppController()
    const appMessages = useContext(AppMessagesContext)
    const [account] = useState<AccountService>(() => new AccountService())

    const detectScreenLock = async () => {
        try {
            if (isLandscape() && Platform.OS !== 'web') {
                await ScreenOrientation.lockAsync(ScreenOrientation.OrientationLock.LANDSCAPE)
            } else if (isPortrait() && Platform.OS === 'ios'){
                await ScreenOrientation.lockAsync(ScreenOrientation.OrientationLock.PORTRAIT_UP)
            } else if (isPortrait() && Platform.OS === 'android'){
                await ScreenOrientation.lockAsync(ScreenOrientation.OrientationLock.PORTRAIT)
            }
        } catch (error) {
            console.error(error)
        }
    }

    void detectScreenLock()

    useEffect(() => {
        console.debug("account initialize")
        account.initialize(currentAccount)
        void startup()

        return () => {
            void appController.uninitialize()
        }
    }, [])

    const startup = async () => {
        try {

            setTimeout(async () => {
                try {
                    await SplashScreen.hideAsync()
                } catch (error) {
                    SentryService.captureError(error)
                }
            }, 500)

            TimedActivity.log(ActivityName.APP_INIT, 'init-account')
            await currentAccount.initialize('App.tsx')

            SentryService.currentAccount = currentAccount

            await appController.initialize(currentAccount)

            TimedActivity.log(ActivityName.APP_INIT, 'on-cold-start')
            await appController.onColdStart()

            TimedActivity.end(ActivityName.APP_INIT, currentAccount)
        } catch (error) {
            try {
                await SplashScreen.hideAsync()
            } catch (e) {
                console.error(e)
            }

            await appController.onLoadError(error as Error)
            if (appController.errorType(error as Error) == 'unknown') throw error
        }
    }

    const restart = async () => {
        try {
            await appController.uninitialize()
            appController.setState(AppState.INITIALIZING)
        } catch (error) {
            await appController.onLoadError(error as Error)
            if (!appController.internetConnectionError(error as Error)) throw error
        }
        await startup()
    }

    const login = async () => {
        await account.logoutAndReset(appController)
        Alert.alert('Restart the app')
    }

    const rewriteLoadError = (error?: Error) => {
        switch (appController.errorType(error)) {
            case 'internet': return `The app is offline. Check your internet and try again.`
            case 'maintenance': return `The server is not working. Try again in a few minutes.`
            case 'account': return `Your account was not found. Login again.`
            default: return `The app had a problem. ${error == undefined ? '' : error}`
        }
    }

    const renderContent = () => {
        switch (appController.state) {

            case AppState.INITIALIZING:
                return <LavaSplashScreen/>

            case AppState.INIT_ERROR:
                return <FullscreenLoading
                            message={`${rewriteLoadError(appController.error)}`}
                            actionTitle={appController.errorType(appController.error) == 'account' ? 'Log In' : 'Try Again'}
                            onAction={appController.errorType(appController.error) == 'account' ? login : restart}
                        />

            case AppState.APP_UPDATING:
                return <FullscreenLoading spinner={true} message={'Loading...'}/>

            case AppState.EXTENDED_DELAY:
                return <FullscreenLoading spinner={true} message={'Sorry for the delay...'}/>

            case AppState.BINARY_OUTDATED_GOTO_APP_STORE:
                return <FullscreenLoading
                            message={`The installed app is old. Please update it${ManifestUtils.env != 'production' ? ' in TestFlight' : ''}.${!appController.justOpenedAppStore ? '' : 'Trouble updating? Use Old App'}`}
                            actionTitle={ManifestUtils.env != 'production'          ? undefined : 'Update Now'}
                            onAction={ManifestUtils.env != 'production'             ? undefined : () => appController.openAppStore()}
                            secondaryActionTitle={!appController.justOpenedAppStore ? undefined : 'Use Old App'}
                            onSecondaryAction={!appController.justOpenedAppStore    ? undefined : () => appController.skipAndDisableUpdate()}
                        />

            case AppState.UPDATE_AHEAD_CANNOT_DOWNGRADE:
                return <FullscreenLoading
                            spinner={false}
                            message={`This app is having problems.${currentAccount.personData.failureCount <= 1 ? '' : ' Delete & install or "Use Old."'}`}
                            actionTitle={'Try Again'}
                            onAction={() => appController.retryUpdate()}
                            secondaryActionTitle={currentAccount.personData.failureCount <= 1   ? undefined : 'Use Old App'}
                            onSecondaryAction={currentAccount.personData.failureCount <= 1      ? undefined : () => appController.skipAndDisableUpdate()}
                        />

            case AppState.LOADED: // Match both so app will not re-load when suspended
            case AppState.SUSPENDED:
                return (Platform.OS == 'web') ? <WebNavigation /> : <MobileNavigation />

            case AppState.UPDATE_FAILED:
                return <FullscreenLoading
                            message={`The app has failed to install a required update.`}
                            actionTitle={'Try Again'}
                            onAction={() => appController.retryUpdate()}
                            secondaryActionTitle={currentAccount.personData.failureCount <= 1   ? undefined : 'Use Old App'}
                            onSecondaryAction={currentAccount.personData.failureCount <= 1      ? undefined : () => appController.skipAndDisableUpdate()}
                        />

            case AppState.LOW_DISK_SPACE:
                return <FullscreenLoading
                    message={`Your device is out of space. Delete some apps and open again.`}
                />
        }
    }

    return (
        <GestureHandlerRootView style={{flex:1}} >
            <CustomSafeAreaProvider>
                <AppMessagesContext.Provider value={appMessages}>
                    <AppControllerContext.Provider value={appController}>
                        <CurrentAccountContext.Provider value={currentAccount}>
                            <PaperProvider theme={theme}>
                                <ThemeContextProvider mode="light">
                                    <KeyboardAvoidingView
                                        behavior={Platform.OS === "ios" ? "padding" : "height"}
                                        enabled={false}
                                        style={{flex:1}}
                                    >
                                        <View style={{flex: 1}}>
                                            {renderContent()}
                                            <AppError/>
                                            <AppInfoMessage/>
                                            <LavaAlertOverlay/>
                                            <LavaToastOverlay/>
                                        </View>
                                    </KeyboardAvoidingView>
                                </ThemeContextProvider>
                            </PaperProvider>
                        </CurrentAccountContext.Provider>
                    </AppControllerContext.Provider>
                </AppMessagesContext.Provider>
            </CustomSafeAreaProvider>
        </GestureHandlerRootView>
    )
})

const theme = {
    ...DefaultTheme,
    colors: {
        ...DefaultTheme.colors,
        primary: Color.primary,
        accent: Color.secondary,
    },
}

export default App
