import SentryService from "../../services/SentryService"
import {CurrentAccount} from "../../../modules/vizz_account/lib/CurrentAccount"

export enum ActivityName {
    APP_INIT = 'app-open',
    PLAY_VIDEO = 'play-video'
}

export class TimedActivity {

    private static debug: boolean = false

    private static timedActivities = new Map<string, TimedActivity>()

    name: string
    start: Date = new Date()
    events: {name: string, start: Date}[] = []
    end?: Date

    constructor(name: string) {
        this.name = name
    }

    public static start(activityName: ActivityName) {
        this.consoleDebug(`start: ${activityName}`)

        const activity = new TimedActivity(activityName)
        this.timedActivities.set(activityName, activity)
    }

    public static log(activityName: ActivityName, eventName: string) {
        this.consoleDebug(`log: ${activityName} - ${eventName}`)

        const activity = this.timedActivities.get(activityName)
        if (activity) {
            activity.logEvent(eventName)
        }
    }

    public static end(activityName: ActivityName, currentAccount: CurrentAccount) {
        this.consoleDebug(`end: ${activityName}`)

        const activity = this.timedActivities.get(activityName)
        if (activity) {
            activity.finish()
            const summary = activity.generateSummary()
            this.timedActivities.delete(activityName)

            currentAccount.analytics.logEvent(activityName, 'timed', summary.total_time_ms, summary)
        }
    }

    // Private

    private logEvent(name: string) {
        this.events.push({name: name, start: new Date()})
    }

    private finish() {
        this.end = new Date()
    }

    private generateSummary() {
        if (!this.end) {
            SentryService.assertionFailure('generateSummary called without finish')
            this.end = new Date()
        }

        let result: any = {
            activity: this.name,
            started: this.start.toString(),
            finished: this.end.toString(),
            total_time_ms: this.end.getTime() - this.start.getTime()
        }

        let events: any[] = []
        for (let i=0; i<this.events.length; i++) {
            const event = this.events[i]
            let eventEnd
            const nextIndex = i + 1
            if (nextIndex== this.events.length) {
                eventEnd = this.end
            } else {
                const nextEvent = this.events[nextIndex]
                eventEnd = nextEvent.start
            }

            events.push({
                name: event.name,
                duration_ms: eventEnd.getTime() - event.start.getTime()
            })
        }

        result.events = events

        return result
     }

    private static consoleDebug(method: string, force: boolean = false) {
        if(this.debug || force) console.log(`TimedActivity: ${method}`)
    }
}