import {VizzSource} from "./VizzSource";

export interface ConceptNodeResponse {
    id: string
    primary_name: ConceptName
    primary_vizz?: VizzSource
    immediate_ancestors?: ConceptNodeResponse[]
    names: ConceptName[]
    search_phrases: string
    bookmarked: boolean
    top: boolean
    pg13: boolean
    primary_media?: Media
    fallback_media?: Media
    number_of_immediate_descendents?: number
    immediate_descendents?: ConceptNodeResponse[]
    leaf_descendants?: ConceptNodeResponse[]
    parent?: ConceptNodeResponse
    path_to_root?: ConceptNodeResponse[]
    state: ConceptNodeState
}

export interface ConceptDetail {
    id: number
    primary_name: ConceptName
    names: ConceptName[]
    primary_vizz?: VizzSource
    immediate_ancestors: ConceptNodeResponse[]
    immediate_descendents: ConceptNodeResponse[]
    leaf_descendants?: ConceptNodeResponse[]
    primary_media?: Media
    fallback_media?: Media
    path_to_root: ConceptNodeResponse[]
    number_of_immediate_descendents: number
}

export enum ConceptNodeState {
    VISIBLE = 'visible',
    HIDDEN = 'hidden',
    DISABLED = 'disabled'
}

export enum ConceptTag {
    SUGGESTED = 'suggested',
    PRIORITY = 'priority'
}

export interface ConceptName {
    id: number,
    name: string
    primary: boolean
}

export interface Media {
    concept_media_url: string,
    source_url: string
}

export interface ImageSearchResult {
    imageId: string
    name: string
    thumbnailUrl: string
    contentUrl: string
    width: number
    height: number
    thumbnail: ThumbnailDimensions
}

export interface ThumbnailDimensions {
    width: number
    height: number
}
