import {action, computed, makeObservable, observable} from "mobx"
import {CurrentAccount} from "../../../../vizz_account/lib/CurrentAccount"
import InterestModel from "../../../models/steps/InterestModel"
import BookModel from "../../../../browse/models/BookModel"
import SentryService from "../../../../../app/services/SentryService"

export enum InterestsGridState {
    LOADING,
    LOADED
}

export class InterestsGridController {
    private debug: boolean = false  // don't set this to true in production

    private currentAccount: CurrentAccount
    private interestOrder: number = 0
    private maxSelect: number

    @observable state: InterestsGridState = InterestsGridState.LOADING
    @observable interests: InterestModel[] = []

    constructor(currentAccount: CurrentAccount, maxSelect?: number) {
        this.consoleDebug(`new()`)

        this.currentAccount = currentAccount
        this.maxSelect = maxSelect ?? 5

        makeObservable(this)
    }

    public async initialize() {
        try {
            this.consoleDebug(`initialize()`)
            if (this.state != InterestsGridState.LOADING) return

            const channels = await this.currentAccount.api.get('browse.onboarding_concept_books_path') as BookModel[]
            this.setInterests(channels.map((c) => new InterestModel(c)))

            this.setState(InterestsGridState.LOADED)
        } catch (error) {
            SentryService.captureError(error)
        }
    }

    public uninitialize() {
        this.consoleDebug(`uninitialize()`)
    }


    // Public methods

    @computed
    get selectedInterests() {
        return this.interests.filter((i) => i.isSelected).sort((a,b) => (a.order > b.order) ? 1 : -1)
    }

    @action
    public toggleSelectedInterest(interest: InterestModel) {
        if (!interest.isSelected && this.selectedInterests.length >= this.maxSelect) return

        interest.setSelected(!interest.isSelected, this.interestOrder += 1)
        const selectedCount = this.selectedInterests.length
    }

    public async saveInterests() {
        try {
            await this.currentAccount.api.patch('vizz_account.update_person_properties', {
                properties: {picked_interests: this.selectedInterests.map((i) => i.book.id)}
            })
        } catch (error) {
            SentryService.captureError(error)
        }
    }



    // Private helper methods

    @action
    private setState(state: InterestsGridState) {
        this.consoleDebug(`setState()`)
        this.state = state
    }

    @action
    private setInterests(interests: InterestModel[]) {
        this.interests = interests
    }



    // Private instance utility methods

    private consoleDebug(method: string, force: boolean = false) {
        if (this.debug || force) console.log(`${this.constructor.name}: ${method}  state = ${this.state}`)
    }
}