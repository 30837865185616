import React from 'react'
import {observer} from "mobx-react"
import {StyleProp, StyleSheet, Text, View, ViewStyle} from "react-native"
import {FriendWidgetController} from "../../controllers/FriendWidgetController"
import Button from '../../../lava-components/components/atoms/Button/Button'

type Props = {
    friendWidgetController: FriendWidgetController
    style?: StyleProp<ViewStyle>
}

export const PollingDisabled = observer((props: Props) => {

    return (
        <View style={[styles.container, props.style]}>
            <Text style={{padding: 10, color: 'white', textAlign: 'center'}}>Disconnected from server. Tap to try reconnecting.</Text>

            <Button variation={'gravity'} onPress={() => props.friendWidgetController.refreshPoll()}>Try Again</Button>

            <Text style={{padding: 10, color: 'white', textAlign: 'center'}}>If you keep having trouble, tap your profile and reconnect to Roblox or reinstall the app.</Text>
        </View>
    )
})

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        ...StyleSheet.absoluteFillObject
    },
})