
import React from 'react'
import {observer} from "mobx-react"
import {SafeAreaView, StyleSheet} from "react-native"
import VerticalGestureRecognizer from '../VerticalGestureRecognizer'
import SeriesController from '../../controllers/library_widget/SeriesController'
import CardBackground from './book/CardBackground'
import {Color} from '../../../../app/lib/Appearance'
import {ActivityIndicator} from 'react-native-paper'

type Props = {
    seriesController: SeriesController
    searchBarHeight: number
}

const BookLoading = observer((props: Props) => {

    return (
        <VerticalGestureRecognizer
            style={{
                flex: 1,
                ...StyleSheet.absoluteFillObject
            }}
            onSwipeDown={() => props.seriesController.close('swiped-down')}
        >
            <SafeAreaView style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}>
                <ActivityIndicator color={Color.primary} size={40} />
            </SafeAreaView>

            <CardBackground />
        </VerticalGestureRecognizer>
    )
})

export default BookLoading
