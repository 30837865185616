import React from "react"
import {observer} from "mobx-react"
import {Text, TouchableOpacity, View, ViewStyle} from "react-native"
import useTheme from "../../../lava-components/components/hooks/useTheme"
import {robloxGameStyles} from "./RobloxGameDetailConstants"
import {Image} from "expo-image"
import {isLandscape} from "../../../../app/lib/Appearance"

type Props = React.PropsWithChildren<{
    title: string,
    onTitlePress?: () => void
    collapsed?: boolean
    verified?: boolean
    showCollapseIcon?: boolean
    style?: ViewStyle
}>

export const RobloxGameDetailContentSection = observer((props: Props) => {

    const theme = useTheme()

    return (
        <View style={{
            marginLeft: isLandscape() ? theme.spacing.md : 0,
            marginRight: props.collapsed ? theme.spacing.md : 0,
            borderRadius: props.collapsed ? 2 : 0,
            borderColor: props.collapsed ? theme.colors.primarySand40 : 'transparent',
            borderWidth: 0.5,
            overflow: 'hidden',
            justifyContent: 'center',
            backgroundColor: 'rgb(31, 30, 30)',
            height: props.collapsed ? 50 : undefined,
            ...props.style
        }}>
            <View style={{
                flex: 1,
                overflow: 'hidden',
                marginStart: theme.spacing.xxxl * 2,
                marginEnd: 0,
                marginTop: props.collapsed ? 0 : 16,
                marginBottom: 15
            }}>
                <TouchableOpacity style={{height: 50, justifyContent: 'center'}} onPress={props.onTitlePress}>
                    <View style={{flexDirection: 'row', alignItems: 'center'}}>
                        <Text style={robloxGameStyles.sectionHeaderText}>{props.title}</Text>
                        {props.verified && !props.collapsed &&
                        <Image
                            style={{width: 28, height: 28, marginStart: theme.spacing.lg}}
                            resizeMode={'contain'}
                            source={require('../../../lava-components/components/molecules/VideoCard/verified.png')}/>
                        }
                        <View style={{flexGrow: 1}}/>
                        {props.showCollapseIcon &&
                        <Image style={{width: 30, height: 30, marginEnd: 28}}
                               source={require('../../assets/icn-chevron-down.png')}
                               contentFit={'contain'}/>
                        }
                    </View>
                </TouchableOpacity>
                <View style={{flex: 1}}>
                    {props.children}
                </View>
            </View>
        </View>
    )
})