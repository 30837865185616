import * as Application from 'expo-application'
import * as Updates from 'expo-updates'
import {CurrentAccount} from "../../modules/vizz_account/lib/CurrentAccount"
import {EventSubscription, Platform} from "react-native"
import {FeatureFlagModel} from '../../modules/vizz_account/models/FeatureFlagModel'
import {AsyncUtils} from '../utils/AsyncUtils'
import {ManifestUtils} from '../utils/ManifestUtils'
import {AppState} from '../controllers/AppControllerState'
import {DeviceUtils} from "../utils/DeviceUtils"
import {AppController} from "../controllers/AppController"
import SentryService from "./SentryService"

export class AppUpdateService {
    private debug: boolean = false  // don't set this to true in production

    private currentAccount!: CurrentAccount
    private appController: AppController
    private appControllerUpdateState!: (state: AppState) => void
    private appControllerUninitialize!: () => Promise<any>
    private updateListener!: EventSubscription


    constructor(appController: AppController) {
        this.consoleDebug(`new()`)
        this.appController = appController
    }

    public initialize(currentAccount: CurrentAccount, appControllerUpdateState: (state: AppState) => void, appControllerUninitialize: () => Promise<any>) {
        this.consoleDebug(`initialize()`)

        this.currentAccount = currentAccount
        this.appControllerUpdateState = appControllerUpdateState
        this.appControllerUninitialize = appControllerUninitialize

        this.updateListener = Updates.addListener((e) => {
            this.currentAccount.analytics.logEvent('update', 'event_received', undefined, {
                type: e.type,
                manifest: (e as any).manifest,
                message: (e as any).message,
            })
        })
    }

    public uninitialize() {
        this.consoleDebug(`uninitialize()`)
        if (this.updateListener) this.updateListener.remove()
    }

    public async tryToUpdate() {
        this.consoleDebug(`tryToUpdate()`)

        if (this.currentAccount.checkForAutoUpdate == false) { // either server or client can disable the checks
            this.appControllerUpdateState(AppState.LOADED)
            return
        }

        if (ManifestUtils.env == 'development' || __DEV__) {
            this.appControllerUpdateState(AppState.LOADED)
            return
        }

        if (Platform.OS == "web") {
            this.appControllerUpdateState(AppState.LOADED)
            return
        }

        if (!this.currentAccount.hasFeature(FeatureFlagModel.AUTO_UPDATE)) {
            this.appControllerUpdateState(AppState.LOADED)
            return
        }

        if (Application.nativeApplicationVersion != this.currentAccount.serverVersion) {
            this.appControllerUpdateState(AppState.BINARY_OUTDATED_GOTO_APP_STORE)
            return
        }

        let clientIsAheadOfServer = (this.currentAccount.serverUpdateNumber && ManifestUtils.clientUpdateNumber && ManifestUtils.clientUpdateNumber > this.currentAccount.serverUpdateNumber)
        if (clientIsAheadOfServer || this.currentAccount.personData.throwClientAhead) {
            this.appControllerUpdateState(AppState.EXTENDED_DELAY)
            await AsyncUtils.sleep(10000)
            await this.currentAccount.api.get('server_update_number')
            clientIsAheadOfServer = (this.currentAccount.serverUpdateNumber && ManifestUtils.clientUpdateNumber && ManifestUtils.clientUpdateNumber > this.currentAccount.serverUpdateNumber)
        }
        const serverIsAheadOfClient = (this.currentAccount.serverUpdateNumber && ManifestUtils.clientUpdateNumber && ManifestUtils.clientUpdateNumber < this.currentAccount.serverUpdateNumber)

        if (!clientIsAheadOfServer && !serverIsAheadOfClient && !this.currentAccount.personData.throwClientAhead) {
            this.appControllerUpdateState(AppState.LOADED)
            return
        }

        if (clientIsAheadOfServer || this.currentAccount.personData.throwClientAhead) {
            this.currentAccount.analytics.logEvent('update', 'update_ahead', `${ManifestUtils.clientUpdateNumber} > ${this.currentAccount.serverUpdateNumber}`)
            this.currentAccount.personData.failureCount += 1
            this.appControllerUpdateState(AppState.UPDATE_AHEAD_CANNOT_DOWNGRADE)
            return
        }

        if (serverIsAheadOfClient && !this.currentAccount.personData.throwClientAhead) {
            const availableDiskMB = await DeviceUtils.getAvailableDiskSpaceMB()
            if (availableDiskMB < 250 || this.currentAccount.personData.throwExceptionOnDiskSpace) {
                this.currentAccount.analytics.logEvent('update', 'failed', 'low-disk-space', {})
                    this.appControllerUpdateState(AppState.LOW_DISK_SPACE)
                return
            }

            let i = 0
            this.currentAccount.analytics.logEvent('update', 'update_behind', `${ManifestUtils.clientUpdateNumber} < ${this.currentAccount.serverUpdateNumber}`)
            this.appControllerUpdateState(AppState.APP_UPDATING)

            this.currentAccount.analytics.logEvent('update', 'checking')
            // NOTE: This check will only detect a failed update installation if lastUpdateNumberDownloaded is properly set (it's done below)
            const previousUpdateFailedToApply = this.currentAccount.personData.lastUpdateNumberDownloaded > ManifestUtils.clientUpdateNumber

            if (previousUpdateFailedToApply) {
                await this.currentAccount.personData.setDataAsync('lastUpdateNumberDownloaded', ManifestUtils.clientUpdateNumber)
                await this.currentAccount.analytics.asyncLogEvent('update', 'failed-to-apply', undefined, {
                    native_application_version: Application.nativeApplicationVersion,
                    last_update_number_downloaded: this.currentAccount.personData.lastUpdateNumberDownloaded,
                    current_client_update_number: ManifestUtils.clientUpdateNumber,
                    previous_update_failed_to_apply: previousUpdateFailedToApply,
                })

                this.currentAccount.personData.failureCount += 1
                this.appControllerUpdateState(AppState.UPDATE_FAILED)
                return
            }

            i = 0
            let hasUpdate:undefined|boolean = undefined
            while ((i+=1) <= 2) {
                try {
                    if (i > 1) await AsyncUtils.sleep(1000)

                    if (i == 1 && this.currentAccount.personData.throwExceptionOnExpo1stCheck) throw Error('throwExceptionOnExpo1stCheck')
                    if (i == 2 && this.currentAccount.personData.throwExceptionOnExpo2ndCheck) throw Error('throwExceptionOnExpo2ndCheck')

                    const update = await Updates.checkForUpdateAsync() as any // API call to Expo

                    const updateBinaryVersionMatchesRunningBinary = update.manifest?.runtimeVersion == Application.nativeApplicationVersion

                    await this.currentAccount.analytics.asyncLogEvent('update', 'checked', undefined, {
                        has_update: update.isAvailable,
                        manifest: update.manifest,
                        manifest_version: update.manifest?.runtimeVersion,
                        native_application_version: Application.nativeApplicationVersion,
                        version_match: updateBinaryVersionMatchesRunningBinary,
                        last_update_number_downloaded: this.currentAccount.personData.lastUpdateNumberDownloaded,
                        current_client_update_number: ManifestUtils.clientUpdateNumber,
                        previous_update_failed_to_apply: previousUpdateFailedToApply,
                    })
                    // hasUpdate = update.isAvailable && updateBinaryVersionMatchesRunningBinary
                    //
                    // NOTE: We stopped using updateBinaryVersionMatchesRunningBinary for now because EAS should now disallow this. Previously, Expo
                    // would tell us that an update existed even if the update was incompatible. But with the move to EAS the update server is smarter
                    // and it should only tell us about updates which are actually compatible with the current binary version.
                    hasUpdate = update.isAvailable
                    break
                } catch (e) {
                    await this.currentAccount.analytics.asyncLogEvent('update', 'check_failed', `try ${i}`, {
                        error: (e as Error).toString()
                    })
                }
            }

            if (hasUpdate == undefined) {
                this.currentAccount.personData.failureCount += 1
                this.appControllerUpdateState(AppState.UPDATE_FAILED)
                return
            }

            if (!hasUpdate) {
                this.appControllerUpdateState(AppState.BINARY_OUTDATED_GOTO_APP_STORE)
                return
            }

            this.currentAccount.analytics.logEvent('update', 'fetching')

            i = 0
            let fetchedAndRestarting:undefined|boolean = undefined
            while ((i+=1) <= 2) {
                let updateNumber:number
                let result:any

                try {
                    if (i > 1) await AsyncUtils.sleep(1000)

                    if (i == 1 && this.currentAccount.personData.throwExceptionOnExpo1stFetch) throw Error('throwExceptionOnExpo1stFetch')
                    if (i == 2 && this.currentAccount.personData.throwExceptionOnExpo2ndFetch) throw Error('throwExceptionOnExpo2ndFetch')

                    if (this.currentAccount.personData.throwExceptionOnExpoInstall) {
                        updateNumber = this.currentAccount.serverUpdateNumber as number
                        result = { isNew: true, manifest: {} }
                    } else {
                        result = await Updates.fetchUpdateAsync() as any // API call to Expo
                        updateNumber = parseInt(result.manifest?.extra?.expoClient?.extra?.updateNumber)
                    }

                    await this.currentAccount.personData.setDataAsync('lastUpdateNumberDownloaded', updateNumber)

                    await this.currentAccount.analytics.asyncLogEvent('update', 'fetched', updateNumber, {
                        is_new: result.isNew,
                        manifest: result.manifest
                    })

                    fetchedAndRestarting = result.isNew
                    break
                } catch(e) {
                    await this.currentAccount.analytics.asyncLogEvent('update', 'fetch_failed', `try ${i}`, {
                        error: (e as Error).toString()
                    })
                }
            }

            if (fetchedAndRestarting == undefined || fetchedAndRestarting == false) {
                this.currentAccount.personData.failureCount += 1
                this.appControllerUpdateState(AppState.UPDATE_FAILED)
                return
            }

            // If we are in the process of answering a call, wait until the call connects
            // so that we can persist the key and recover after the update.
            // In a moral use case (no call), this will pass through immediately.
            await this.appController.call.waitForPossibleAnswerCall()

            await this.appControllerUninitialize()

            // There appears to be a bug on Android where sometimes Expo Updates incorrectly reports
            // that updates are disabled and fails the restart. Try several times with delay after each attempt.
            for (let i=0; i<5; i++) {
                try {
                    await Updates.reloadAsync()
                    // AppState is still .UPDATING and will stay there until the reloadAsync() completes
                    return
                } catch (error) {
                    SentryService.captureError(error)
                }

                await AsyncUtils.sleep(1000)
            }
        }
    }

    public async emergencyUpdate() {
        if (Platform.OS == "web" || __DEV__) return false

        let i = 0
        let error:Error|undefined = undefined
        while ((i+=1) <= 2) {
            try {
                const update = await Updates.checkForUpdateAsync()
                const isAvailable = update.isAvailable
                if (isAvailable) {
                    const result = await Updates.fetchUpdateAsync()
                    if (result.isNew) {
                        await Updates.reloadAsync()
                        return true
                    }
                }
            } catch (e) {
                console.error(e)
                error = e as Error
            }
        }

        if (error) throw error
    }

    private consoleDebug(method: string, force: boolean = false) {
        if (this.debug || force) console.log(`${this.constructor.name}: ${method}`)
    }
}
