import React, {useContext, useEffect, useLayoutEffect, useRef, useState} from 'react'
import {ScrollView, StyleProp, StyleSheet, Text, View, ViewStyle} from "react-native"
import {observer} from "mobx-react"
import {InboxWidgetController, InboxWidgetState} from "../controllers/InboxWidgetController"
import CurrentAccountContext from "../../vizz_account/lib/CurrentAccount"
import AppControllerContext from '../../../app/controllers/AppController'
import {Widget} from '../../start/views/home/Widget'
import {NativeState} from '../../../app/services/NativeStateService'
import {reaction} from 'mobx'
import {isMessageNotification, MessageNotification} from "../../../app/lib/MessageNotification"
import {CallNotification, isCallNotification} from "../../../app/lib/CallNotification"
import HomeController from '../../start/controllers/HomeController'
import {Call} from '../models/Call'
import {Message} from './inbox_widget/Message'
import {AnimationUtils} from "../../../app/utils/AnimationUtils"

type Props = {
    style?: StyleProp<ViewStyle>
    homeController: HomeController
    disabled?: boolean
    mode: 'widget'|'feed'
}

export const InboxWidget = observer((props: Props) => {
    const currentAccount = useContext(CurrentAccountContext)
    const appController = useContext(AppControllerContext)
    const [controller] = useState<InboxWidgetController>(() => new InboxWidgetController(currentAccount, props.homeController, appController))
    const scrollView = useRef<ScrollView>(null)
    const [layout, setLayout] = useState({x: 0, y: 0, width: 0, height: 0})

    useEffect(() => {
        const cleanupAppStateReaction = reaction(() => appController.nativeState.state, async(state) => {
            if (state == NativeState.FOREGROUND) {
                await controller.refreshInbox()
                await scrollToTop()
            }
        });

        async function init() {
            await controller.initialize()
            await scrollToTop()
        }
        void init()

        return () => {
            controller.uninitialize()
            cleanupAppStateReaction()
        }
    }, [])

    useLayoutEffect(() => {
        AnimationUtils.configureNext()
    }, [controller.messageStoriesLength, ])

    const scrollToTop = async() => {
        scrollView.current?.scrollTo({ x: 0, y: 0, animated: false })
    }

    const onChatNotificationTapped = async (notification: MessageNotification) => {
        const members: string[] = notification.personKeys
        currentAccount.analytics.logEvent('social-chat', 'tapped', `${members.length} friends`, { usernames: members, trigger: "inbox", number_of_friends: members.length})
        props.homeController.friendWidgetController?.setLoadingProfileDetail(true)
        try {
            await props.homeController.friendWidgetController?.openChannelDetails(members)
            await props.homeController.appController.message.markAsRead(notification)
        } catch (error) {
            console.error(error)
        } finally {
            props.homeController.friendWidgetController?.setLoadingProfileDetail(false)
        }
    }

    const onCallNotificationTapped = (call: Call) => {
        currentAccount.analytics.logEvent('social-call', 'tapped', `${call.participants.length} friends`, { profiles: call.participants.map((p) => p.profile), trigger: "inbox", number_of_friends: call.participants.length })
        if (controller.appController.call.activeCallConnection && props.homeController.friendWidgetController?.isShowingMinimizedCall) {
            void props.homeController.friendWidgetController?.maximizeActiveCall()
        } else {
            void controller.appController.call.joinCallFromInboxOrChannel(call, "inbox")
        }
    }

    const InboxContent = () => {
        return (
            <View style={{}} onLayout={(e) => setLayout(e.nativeEvent.layout)}>
                {false &&
                    <Message type={'done'} text={'Done with all messages'} />}

                {controller.messageStoriesLength == 0 &&
                    <Message type={'done'} text={'No new messages'} />}

                {controller.callAndChatMessageStories.map((n, i) => {
                    if (isMessageNotification(n)) {
                        return <Message
                            type={'chat'}
                            key={`msg-${n.id}`}
                            notification={n as MessageNotification}
                            onPress={() => onChatNotificationTapped(n)}/>
                    } else if (isCallNotification(n)) {
                        return <Message
                            type={'call'}
                            key={`msg-${n.id}`}
                            notification={n as CallNotification}
                            onPress={() => onCallNotificationTapped(n.call)}/>
                    }
                })}

                {controller.notReadMessageStories.length > 0 && controller.notReadMessageStories.map((story, index) => {

                    if (story.source.story_type == 'Concept' && story.properties.call_to_action == "add_school"){
                        return <Message
                            type={'add_school'}
                            key={`add_school`}
                            text={story.title}
                            controller={controller} />
                    } else if (story.source.story_type == 'Concept' && story.properties.call_to_action == "connect_roblox"){
                        return <Message
                            type={'connect_roblox'}
                            key={`connect_roblox_${story.id}`}
                            text={story.title}
                            controller={controller} />
                    } else if (story.source.story_type == 'Participant' && story.properties.missed_call){
                        return <Message
                            type={'missed_call'}
                            key={`missed_call_${story.id}`}
                            callBackPersonKey={story.properties.call_back_person_key}
                            text={story.title}
                            controller={controller} />
                    } else if (story.source.story_type == 'FriendRequest') {
                        return <Message
                            type={'friending'}
                            key={`friend-${index}`}
                            text={story.title}
                            friendRequestId={story.source.id}
                            controller={controller} />
                    } else {
                        return <Text key={`text-${index}`}>
                            Message: {story.id} {story.title}
                        </Text>
                    }
                })}
            </View>
        )
    }

    const extraStyle = () => {
        switch (props.mode) {
            case 'widget': return {
                minHeight: layout.height + 36,
                maxHeight: layout.height + 36,
                margin: 0, padding: 0
            }
            case 'feed': return {}
        }

    }

    return (
        controller.messageStoriesLength == 0 ? null :
        <Widget name="Inbox" type={'compact'} style={[props.style, extraStyle()]} loading={controller.state == InboxWidgetState.LOADING} disabled={props.disabled}>
            {props.mode == 'widget' ?
                <ScrollView ref={scrollView} showsVerticalScrollIndicator={false} style={{flex: 1, margin: 0, padding: 0}}>
                    {InboxContent()}
                </ScrollView>
                :
                <View style={{flex: 1, margin: 0, padding: 0}}>
                    {InboxContent()}
                </View>}
        </Widget>
    )
})

const styles = StyleSheet.create({})
