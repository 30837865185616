import React, {useContext} from 'react'
import {View, Text, StyleProp, ViewStyle, StyleSheet, Alert} from 'react-native'
import {State, TapGestureHandler, TapGestureHandlerStateChangeEvent} from "react-native-gesture-handler"
import LavaLogo from "../../assets/img-lava.svg"
import {Style, isLandscape, isPortrait, isTablet} from "../../../../app/lib/Appearance"
import {useSafeAreaInsets} from "react-native-safe-area-context"
import {TouchableRipple} from 'react-native-paper'
import HomeController, {HomeState} from '../../controllers/HomeController'
import {OnboardingController} from '../../../onboarding/controllers/OnboardingController'
import CurrentAccountContext from '../../../vizz_account/lib/CurrentAccount'
import FriendDetails from '../../../social/views/friend_widget/FriendDetails'
import {observer} from 'mobx-react'
import {AsyncUtils} from '../../../../app/utils/AsyncUtils'
import {FeatureFlagModel} from '../../../vizz_account/models/FeatureFlagModel'

export const WIDGET_DISABLED_OPACITY = isPortrait() ? 0.3 : 0.1

type Props = {
    height: number
    onDebugTapped?: (() => void)
    style?: StyleProp<ViewStyle>
    homeController: HomeController
    variant?: 'primaryLarge' | 'secondary' | 'primarySmall'
    disabled?: boolean
}

const LogoBar = observer((props: Props) => {
    const safeAreaInsets = useSafeAreaInsets()
    const currentAccount = useContext(CurrentAccountContext)

    const onDebugTappedGesture = (event: TapGestureHandlerStateChangeEvent) => {
        if (event.nativeEvent.state === State.ACTIVE && props.onDebugTapped) {
            props.onDebugTapped()
        }
    }

    const safeBuffer = safeAreaInsets.top
    const logoHeight = props.height * 0.4

    const profileTapped = async() => {
        props.homeController.setDimBackground(true)
        await AsyncUtils.sleep(100)

        Alert.alert(
            "Edit",
            currentAccount.hasFeature(FeatureFlagModel.OFFLINE) ? `Edit your name or Offline Message?` : 'Would you like to edit your profile?',
            [
                {
                    text: "Cancel",
                    onPress: () => props.homeController.setDimBackground(false),
                    style: "cancel"
                },
                {
                    text: "Yes, Edit",
                    onPress: () => {
                        props.homeController.setDimBackground(false)
                        OnboardingController.setStep('profile', currentAccount)
                        props.homeController.setState(HomeState.ONBOARDING)
                    },
                    style: 'default'
                }
            ]
        )
    }

    return (
        <View style={[styles(props, safeBuffer).heightWithSafeBuffer, {backgroundColor: 'transparent', opacity: props.disabled ? WIDGET_DISABLED_OPACITY : 1}]}>
            <View style={{backgroundColor: 'transparent', height: props.height, flexDirection: 'row', marginHorizontal: Style.Canvas.marginHorizontal}}>
                <View style={{flex: 1, flexDirection: 'row'}}>
                    <TouchableRipple disabled={props.disabled} onPress={profileTapped} onLongPress={profileTapped} style={{backgroundColor: 'transparent', marginLeft: isLandscape() ? 14 : 0, paddingHorizontal: isLandscape() ? 5 : 0}}>
                        <View style={{flex: 1, justifyContent: 'center'}}>
                                {currentAccount.profile ? <FriendDetails profile={currentAccount.profile} forceOnline={true} avatarBackgroundColor={'rgba(0,0,0,0.1)'} style={{height: props.height}} variant={props.variant} /> : <View />}
                        </View>
                    </TouchableRipple>
                </View>

                <TapGestureHandler numberOfTaps={4} onHandlerStateChange={onDebugTappedGesture}>
                    <View style={{backgroundColor: 'transparent', flex: 1, flexDirection: 'row-reverse'}}>
                        <View style={{justifyContent: 'center'}}>
                            <LavaLogo width={logoHeight * 4.19} height={logoHeight}/>
                        </View>
                    </View>
                </TapGestureHandler>
            </View>
        </View>
    )
})

const styles = (props: Props, safeBuffer: number) => StyleSheet.create({
    heightWithSafeBuffer: {
        position: 'absolute',
        top: 5,
        zIndex: 100,
        width: '100%',
        height: props.height + safeBuffer,
        flexDirection: 'column-reverse',
    }
})

export default LogoBar
