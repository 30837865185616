import React, {useContext, useState} from 'react'
import {ScrollView, StatusBar, StyleSheet, View} from "react-native"
import {ActivityIndicator, Button, Headline} from "react-native-paper"
import {Color, isPortrait, isTablet} from "../../lib/Appearance"
import CurrentAccountContext from '../../../modules/vizz_account/lib/CurrentAccount'
import {DebugAutoUpdate} from '../debug/DebugAutoUpdate'
import {useSafeAreaInsets} from 'react-native-safe-area-context'
import {State, TapGestureHandler, TapGestureHandlerStateChangeEvent} from 'react-native-gesture-handler'
import {AsyncUtils} from '../../utils/AsyncUtils'
import {ManifestUtils} from '../../utils/ManifestUtils'
import {LavaImage} from "./LavaImage"
import {LavaSplashFooter} from '../LavaSplashFooter'

type Props = {
    message: string
    spinner?: boolean
    actionTitle?: string
    onAction?: () => void
    secondaryActionTitle?: string
    onSecondaryAction?: () => void
}

export const FullscreenLoading = (props: Props) => {
    const currentAccount = useContext(CurrentAccountContext)
    const [screen, setScreen] = useState({x: 0, y: 0, width: 0, height: 0})
    const [container, setContainer] = useState({x: 0, y: 0, width: 0, height: 0})
    const safeAreaInsets = useSafeAreaInsets()
    const [showDebug, setShowDebug] = useState<boolean>(false)
    const [spinner, setSpinner] = useState<boolean>(false)

    const debug = () => {
        return ManifestUtils.env != 'production'
    }

    const splashAsset = () => {
        return isTablet() ? require('../../assets/splash/splash-ipad.png') : require('../../assets/splash/splash-iphone.png')
    }

    const buttonWidth = () => {
        return isPortrait() ? Math.round(screen.width/2) : Math.max(Math.round(container.width/2), 200)
    }

    const renderAction = () => {
        if (props.actionTitle && props.onAction) {
            return (
                <Button style={{marginTop: 16, width: buttonWidth()}} mode={'contained'} color={'white'} onPress={async() => {
                    setSpinner(true)
                    await AsyncUtils.sleep(500)
                    setSpinner(false)
                    if (props.onAction) props.onAction()
                }}>
                    {props.actionTitle}
                </Button>
            )
        } else {
            return null
        }
    }

    const spinnerOn = () => {
        return (spinner || props.spinner)
    }

    const renderSecondaryAction = () => {
        if (props.secondaryActionTitle && props.onSecondaryAction) {
            return (
                <Button style={{marginTop: 16, width: buttonWidth()}} mode={'contained'} color={Color.darkText} onPress={async() => {
                    setSpinner(true)
                    await AsyncUtils.sleep(500)
                    setSpinner(false)
                    if (props.onSecondaryAction) props.onSecondaryAction()
                }}>
                    {props.secondaryActionTitle}
                </Button>
            )
        } else {
            return null
        }
    }

    const onDebugTappedGesture = (event: TapGestureHandlerStateChangeEvent) => {
        if (event.nativeEvent.state === State.ACTIVE && debug()) {
            setShowDebug(true)
        }
    }

    return (
        <View style={{
            flex: 1,
            backgroundColor: 'black',
            justifyContent: 'center',
            alignItems: 'center'}} onLayout={(e) => setScreen(e.nativeEvent.layout)}>

            {showDebug &&
            <ScrollView style={{flex: 1, position: 'absolute', top: safeAreaInsets.top, height: 130, width: '100%', borderWidth: 1, borderColor: 'black', zIndex: 1000}} contentContainerStyle={{}}>
                <DebugAutoUpdate />
            </ScrollView>}

            <LavaImage
                style={{flex: 1, width: '100%'}}
                resizeMode={'cover'}
                source={splashAsset()}
            />
            <View style={{flex: 1, justifyContent: 'center', alignItems: 'center', ...StyleSheet.absoluteFillObject}}>
                <View style={{marginTop: 256, marginHorizontal: 24, alignItems: 'center'}} onLayout={(e) => setContainer(e.nativeEvent.layout)}>
                    <View style={{flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
                        {spinnerOn() && <ActivityIndicator style={{marginEnd: 16}} size="large" color={'black'}/>}
                        <TapGestureHandler numberOfTaps={4} onHandlerStateChange={onDebugTappedGesture}>
                            <View>
                                <Headline style={{color: 'rgba(70,70,70,1)', textAlign: 'center'}}>{props.message}</Headline>
                            </View>
                        </TapGestureHandler>
                    </View>
                    {renderAction()}
                    {renderSecondaryAction()}
                </View>
            </View>

            <LavaSplashFooter />
            <StatusBar barStyle={'dark-content'}/>
        </View>
    )
}