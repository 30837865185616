import React, {useState} from 'react'
import {LayoutRectangle, ScrollView, TouchableOpacity, View} from "react-native"
import {FriendWidgetController} from '../../controllers/FriendWidgetController'
import {observer} from 'mobx-react'
import {Divider} from 'react-native-paper'
import {Color} from '../../../../app/lib/Appearance'
import {Friend} from "./Friend"
import {FriendGroup} from "./FriendGroup"
import {BottomSheetScrollView} from "@gorhom/bottom-sheet"
import useTheme from "../../../lava-components/components/hooks/useTheme"
import {FriendListActionRow} from "../../../lava-components/components/molecules/Friend/FriendListActionRow"
import Title from '../../../lava-components/components/atoms/Title/Title'
import FriendDetails from './FriendDetails'

type Props = {
    friendWidgetController: FriendWidgetController,
    mode: 'widget'|'sheet'
}

const FriendGrid = observer((props: Props) => {
    const [layout, setLayout] = useState<LayoutRectangle>({x: 0, y: 0, width: 0, height: 0})
    const theme = useTheme()

    const Content = () => {
        return (
            <React.Fragment>
                {(props.friendWidgetController.presencesWithLava.length > 0 || props.friendWidgetController.lavaBotPresence) &&
                    <View style={{flex: 1, justifyContent: 'center'}}>
                        {props.friendWidgetController.presencesWithLava.map((friendPresence, index) => {
                            return (
                                <Friend
                                    key={`friend-${friendPresence.id}`}
                                    presence={friendPresence}
                                    friendWidgetController={props.friendWidgetController}
                                />
                            )
                        })}

                        {props.friendWidgetController.presencesWithoutLava.map((friendPresence) => {
                            return (
                                <Friend
                                    key={`rblx-friend-${friendPresence.id}`}
                                    presence={friendPresence}
                                    friendWidgetController={props.friendWidgetController}
                                />
                            )
                        })}

                        {props.friendWidgetController.outgoingFriendRequestProfiles.map((profile) => {
                            if (!profile) return null
                            return (
                                <FriendDetails
                                    key={profile.id}
                                    profile={profile}
                                    friendRequestSent={true}
                                    style={{
                                        marginBottom: 24
                                    }}
                                />
                            )
                        })}

                        {props.friendWidgetController.friendGroups.map((f) => {
                            return (
                                <FriendGroup
                                    key={`group-call-view-${f.id}`}
                                    presences={f.presences}
                                    friendWidgetController={props.friendWidgetController}
                                />
                            )
                        })}

                        <Divider style={{flex: 1, backgroundColor: Color.lavaGray, opacity: 0.2, marginBottom: 24}}/>

                        {props.friendWidgetController.lavaBotPresence &&
                            <Friend
                                key={`lavabot`}
                                presence={props.friendWidgetController.lavaBotPresence}
                                friendWidgetController={props.friendWidgetController}
                            />}

                        {props.friendWidgetController.friendPresences.length >= 2 &&
                            <View key={'create-group'}>
                                <FriendListActionRow title={'New Group'} icon={'new-group'} onPress={() => props.friendWidgetController.showCreateGroup()}/>
                            </View>}
                    </View>
                }

                <View key={'add-friends'}>
                    <View style={{marginTop: 24}}>
                        <FriendListActionRow title={'Add Friend'} icon={'add-friend'} onPress={() => props.friendWidgetController.showAddFriend()}/>
                    </View>
                    { Boolean(props.friendWidgetController.robloxFriendsCount) &&
                        <TouchableOpacity onPress={() => props.friendWidgetController.showAddFriend()}>
                            <View style={{alignItems: 'center', marginTop: 24}}>
                                <Title variation='subtitle1'>
                                    {props.friendWidgetController.robloxFriendsCount} Roblox friends
                                </Title>
                            </View>
                        </TouchableOpacity>
                    }
                </View>
            </React.Fragment>
        )
    }

    return (
        props.mode == 'sheet' ?
            <BottomSheetScrollView style={{flex: 1}} showsVerticalScrollIndicator={false}
                                   onLayout={(e) => setLayout(e.nativeEvent.layout)}>
                {Content()}
            </BottomSheetScrollView>
            :
            <ScrollView style={{flex: 1}} showsVerticalScrollIndicator={false}
                                   onLayout={(e) => setLayout(e.nativeEvent.layout)}>
                {Content()}
            </ScrollView>
    )
})

export default FriendGrid
