export class ArrayUtils {

    // Source: https://stackoverflow.com/questions/2450954/how-to-randomize-shuffle-a-javascript-array
    public static shuffle<T>(array: T[]): T[] {
        let currentIndex = array.length,  randomIndex
        while (currentIndex != 0) {
            randomIndex = Math.floor(Math.random() * currentIndex)
            currentIndex--
            [array[currentIndex], array[randomIndex]] = [
                array[randomIndex], array[currentIndex]]
        }

        return array
    }

    public static listToSentence(elements: string[]): string {
        if (elements.length === 0) {
            return ''
        } else if (elements.length === 1) {
            return elements[0]
        } else if (elements.length === 2) {
            return `${elements[0]} and ${elements[1]}`
        } else {
            const last = elements.pop();
            return `${elements.join(', ')}, and ${last}`
        }
    }

    public static arraysEqual<T>(a: T[], b: T[]): boolean {
        if (a === b) return true
        if (a.length !== b.length) return false

        for (const obj of a) {
            if (!b.includes(obj)) {
                return false
            }
        }

        return true
    }

    public static nonNullItemsFrom<T>(input: T[]): T[] {
        return input.filter((item) => item != undefined && item != null)
    }

    public static arrayRemovingItem<T>(input: T[], item: T): T[] {
        return input.filter((i) => i !== item)
    }

    public static deduplicateArray<T>(arr: T[], field: keyof T): T[] {
        const seen = new Set()
        const deduplicatedArray: T[] = []

        for (const item of arr) {
            const value = item[field]
            if (!seen.has(value)) {
                seen.add(value)
                deduplicatedArray.push(item)
            }
        }

        return deduplicatedArray
    }

}

export const safeItemAtIndex = <T>(input: T[], index: number): T|undefined => {
    if (input.length <= index) return undefined
    return input[index]
}