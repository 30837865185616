import {VizzState, VizzContentType} from '../lib/models/VizzSource'

export class VizzStateUtil {
    static title(state: VizzState): string {
        switch (state) {
            case VizzState.draft: return "Draft"
            case VizzState.published: return "Published"
            case VizzState.publishedAndCrawlable: return "Published and Crawlable"
            case VizzState.archived: return "Archived"
        }
    }
}

export class VizzContentTypeUtil {
    static title(type: VizzContentType): string {
        switch (type) {
            case VizzContentType.wow: return "Wow"
            case VizzContentType.howTo: return "How To"
            case VizzContentType.collection: return "Collection"
            case VizzContentType.longExplanation: return "Long Explanation"
            case VizzContentType.audioOnly: return "Audio Only"
            case VizzContentType.autoGenerated: return "Auto Generated"
            case VizzContentType.latest: return "Latest"
            case VizzContentType.reviewed: return "Reviewed"
            case VizzContentType.flagged: return "Flagged"
            case VizzContentType.awaitingModeration: return "Awaiting Moderation"
        }
    }
}

export enum ContentTypeFilter {
    all = 'all',
    wow = 'wow',
    howTo = 'howTo',
    collection = 'collection',
    longExplanation = 'longExplanation',
    audioOnly = 'audioOnly',
    autoGenerated = 'autoGenerated',
    latest = 'latest',
    reviewed = 'reviewed',
    flagged = 'flagged',
    awaitingModeration = 'awaitingModeration'
}

export class ContentTypeFilterUtil {
    static title(StateFilterType?: ContentTypeFilter): string|undefined {
        switch (StateFilterType) {
            case ContentTypeFilter.all: return "All"
            case ContentTypeFilter.wow: return "Wow"
            case ContentTypeFilter.howTo: return "How To"
            case ContentTypeFilter.collection: return "Collection"
            case ContentTypeFilter.longExplanation: return "Long Explanation"
            case ContentTypeFilter.audioOnly: return "Audio Only"
            case ContentTypeFilter.autoGenerated: return "Auto Generated"
            case ContentTypeFilter.latest: return "Latest"
            case ContentTypeFilter.reviewed: return "Reviewed"
            case ContentTypeFilter.flagged: return "Flagged"
            case ContentTypeFilter.awaitingModeration: return "Awaiting Moderation"
        }
    }

    static apiParam(StateFilterType?: ContentTypeFilter): VizzContentType|undefined {
        switch (StateFilterType) {
            case ContentTypeFilter.all: return undefined
            case ContentTypeFilter.wow: return VizzContentType.wow
            case ContentTypeFilter.howTo: return VizzContentType.howTo
            case ContentTypeFilter.collection: return VizzContentType.collection
            case ContentTypeFilter.longExplanation: return VizzContentType.longExplanation
            case ContentTypeFilter.audioOnly: return VizzContentType.audioOnly
            case ContentTypeFilter.autoGenerated: return VizzContentType.autoGenerated
            case ContentTypeFilter.latest: return VizzContentType.latest
            case ContentTypeFilter.reviewed: return VizzContentType.reviewed
            case ContentTypeFilter.flagged: return VizzContentType.flagged
            case ContentTypeFilter.awaitingModeration: return VizzContentType.awaitingModeration
        }
    }
}
