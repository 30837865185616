import {observer} from 'mobx-react'
import React, {useContext, useState, useEffect, useCallback} from 'react'
import {View} from 'react-native'
import CurrentAccountContext from "../../../modules/vizz_account/lib/CurrentAccount"
import {LegacyFeedScreenNavigationProp} from "./LegacyFeed"
import {Button, Headline, HelperText, TextInput, Title} from 'react-native-paper'
import CredentialModel from "../../../modules/vizz_account/models/CredentialModel"

type Props = {
    navigation: LegacyFeedScreenNavigationProp
}

const LoginScreen = observer(({navigation}: Props) => {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const currentAccount = useContext(CurrentAccountContext)
    const [hasErrors, setHasErrors] = useState<boolean>(false)

    const login = async () => {
        setHasErrors(false)
        let credential : CredentialModel = { email: email, password: password }
        try {
            await currentAccount.authenticate(credential)
            //navigation.navigate('Home')
            console.log('login was successful')
        } catch(e) {
            console.log(e)
            setHasErrors(true)
        }
    }

    if (currentAccount.isLoggedIn) {
        return (
            <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
                <Title style={{marginBottom: 16}}>
                    You are logged in.
                </Title>
                <Button mode={'contained'} onPress={() => navigation.navigate("Home")}>
                    Home
                </Button>
            </View>
        )
    }
    else {
        return (
            <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
                <View>
                    <Headline>Log in</Headline>
                    <TextInput
                        style={{marginTop: 16}}
                        label="Email"
                        value={email}
                        onChangeText={(text) => {
                            setHasErrors(false)
                            setEmail(text)
                        }}
                        mode={'outlined'}
                        error={hasErrors}
                    />
                    <TextInput
                        style={{marginTop: 16}}
                        label="Password"
                        value={password}
                        secureTextEntry={true}
                        onChangeText={(text) => {
                            setHasErrors(false)
                            setPassword(text)
                        }}
                        mode={'outlined'}
                        error={hasErrors}
                    />
                    <HelperText
                        style={{marginBottom: 16}}
                        type="error"
                        visible={hasErrors}>
                        Email was not found or the password was incorrect.
                    </HelperText>
                    <Button mode={'contained'} onPress={() => login()}>
                        Log In
                    </Button>
                </View>
            </View>
        )
    }
  })

export default LoginScreen

