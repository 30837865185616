import {action, makeObservable, observable} from "mobx"
import HomeController from "../../../start/controllers/HomeController"
import {CurrentAccount} from '../../../vizz_account/lib/CurrentAccount'
import BookModel from "../../models/BookModel"
import {ActivityName, TimedActivity} from "../../../../app/lib/services/TimedActivity"

class SeriesController {
    private debug: boolean = false  // don't set this to true in production

    private currentAccount: CurrentAccount
    public homeController?: HomeController

    @observable closeAll: boolean = false
    @observable books: BookModel[] = []
    @observable activeBookIndex: number|undefined = undefined // while books array is empty, this is undefined
    @observable activeLayerIndex: number = 0


    constructor(currentAccount: CurrentAccount, homeController?: HomeController) {
        this.consoleDebug(`new()`)

        this.currentAccount = currentAccount
        if (homeController) {
            this.homeController = homeController
            this.homeController.seriesController = this
        }

        makeObservable(this)
    }

    public initialize(startingBook?: BookModel, startingBookOpenTrigger?: string, startingBookOpenPosition?: number) {
        this.consoleDebug(`initialize(${startingBook}, ${startingBookOpenTrigger})`)

        TimedActivity.log(ActivityName.PLAY_VIDEO, 'series-init')

        this.setBookAndOpen(0, startingBook, startingBookOpenTrigger ?? '', startingBookOpenPosition)
    }

    public uninitialize() {
        this.consoleDebug(`uninitialize()`)

        if (this.homeController) this.homeController.seriesController = undefined
    }

    @action
    public close(trigger: string) {
        this.consoleDebug(`close(${trigger})`)
        this.closeAll = true
        this.logBookClosed(0, trigger)
    }

    @action
    public setBookAndOpen(index: number, book: BookModel | undefined, trigger: string, position?: number) {
        this.consoleDebug(`setBookAndOpen(${index}, ${book}, ${trigger})`)
        if (book == undefined) return

        this.books[index] = book
        this.activeBookIndex = index
        this.activeLayerIndex = index
        this.logBookOpened(index, trigger, position)
    }

    @action
    public setBook(index: number, book: BookModel) {
        this.consoleDebug(`setBook()`)
        this.books[index] = book
    }

    @action
    public removeBooksAfter(index: number, trigger: string) {
        this.consoleDebug(`removeBooksAfter()`)
        if (index < 0) {
            this.close(trigger)
            return
        }
        this.activeBookIndex = index // we don't pop the array b/c components need to animate off screen & disappearing data causes problems
        this.activeLayerIndex = index
        this.logBookClosed(index+1, trigger)
    }

    // Private instance utility methods

    private logBookClosed(index: number, trigger: string) {
        if (index > this.books.length-1) return
        const book = this.books[index]
        this.currentAccount.analytics.logEvent('concept', 'closed', book.title, {
            concept_id: book?.concept?.id,
            concept_name: book.title,
            trigger: trigger,
            position_in_series: index+1,
        })
    }

    private logBookOpened(index: number, trigger: string, position?: number) {
        this.currentAccount.personData.numberOfConceptsOpened += 1
        this.currentAccount.dayData.numberOfConceptsOpened += 1

        const book = this.books[index]
        this.currentAccount.analytics.logEvent('concept', 'opened', book.title, {
            concept_id: book?.concept?.id,
            concept_name: book.title,
            trigger: trigger,
            position_in_library: position,
            position_in_series: index+1,
            number_of_concepts_opened_today: this.currentAccount.dayData.numberOfConceptsOpened,
            number_of_concepts_opened_ever: this.currentAccount.personData.numberOfConceptsOpened,
        })
    }

    private consoleDebug(method: string, force: boolean = false) {
        if(this.debug || force) console.log(`SeriesController: ${method}`)
    }
}

export default SeriesController
