import {Linking} from "react-native"

class LinkingUtils {

    public static async safeOpenURL(url: string) {
        const canOpen = await Linking.canOpenURL(url)
        if (canOpen) {
            await Linking.openURL(url)
        }
    }

}

export default LinkingUtils