import {action, makeObservable, observable, runInAction} from "mobx"
import {ConceptNodeResponse} from "../../../../../../app/lib/models/Concept"
import {CurrentAccount} from '../../../../../vizz_account/lib/CurrentAccount'
import ChapterModel from "../../../../models/ChapterModel"
import BookController from "../../BookController"

class TableOfContentsController {
    private debug: boolean = false  // don't set this to true in production

    private currentAccount: CurrentAccount
    public bookController: BookController

    @observable chapters: ChapterModel[]
    @observable chapterIndex?: number


    constructor(currentAccount: CurrentAccount, bookController: BookController) {
        this.consoleDebug(`new()`)

        this.currentAccount = currentAccount
        this.bookController = bookController
        this.chapters = []

        makeObservable(this)
    }

    public async initialize() {
        this.consoleDebug(`initialize()`)
        this.setChapters(this.bookController.book?.chapters ?? [])
    }

    @action
    public selectChapter(index: number) {
        this.consoleDebug(`selectChapter()`)

        this.chapterIndex = index
        const book = this.chapters[index]?.book
        const nextSlotIndex = this.bookController.bookIndex+1
        this.bookController.seriesController.setBookAndOpen(nextSlotIndex, book, 'tapped-directory')
    }


    // Private instance init methods

    @action
    public setChapters(chapters: ChapterModel[]) {
        this.consoleDebug(`setChapters()`)
        this.chapters = chapters
    }


    // Private instance utility methods

    private consoleDebug(method: string, force: boolean = false) {
        if(this.debug || force) console.log(`TableOfContentsController: ${method}`)
    }
}

export default TableOfContentsController