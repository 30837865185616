import {LaunchableAppModel, SUPPORTED_APPS} from "../models/LaunchableAppModel"
import {Linking, Platform} from "react-native"
import {action, IReactionDisposer, makeObservable, observable, reaction} from "mobx"
import {CurrentAccount} from "../../vizz_account/lib/CurrentAccount"
import * as Device from 'expo-device'
import {NativeState} from "../../../app/services/NativeStateService"
import {AppController} from "../../../app/controllers/AppController"
import SentryService from "../../../app/services/SentryService"

export class LauncherWidgetController {
    private debug: boolean = false  // don't set this to true in production

    public currentAccount!: CurrentAccount
    private appController!: AppController
    private cleanupAppStateReaction?: IReactionDisposer

    @observable installedApps: LaunchableAppModel[] = []

    constructor() {
        makeObservable(this)
    }

    public async initialize(appController: AppController, currentAccount: CurrentAccount) {
        this.consoleDebug(`initialize()`)

        this.currentAccount = currentAccount
        this.appController = appController

        this.cleanupAppStateReaction = reaction(() => this.appController.nativeState.state, (state) => {
            if (state == NativeState.FOREGROUND) void this.onAppForeground()
        })

        await this.onAppForeground()
    }

    public uninitialize() {
        this.consoleDebug(`uninitialize()`)
        if (this.cleanupAppStateReaction) this.cleanupAppStateReaction()
    }


    public async onAppForeground() {
        if (Platform.OS == 'web') return

        this.consoleDebug(`onAppForeground()`)

        await this.refreshInstalledApps()

        const appSummary = this.installedApps.map((a) => { return {name: a.name, appId: a.appId } } )

        this.currentAccount.analytics.logEvent('launcher', 'appcheck', `${this.installedApps.length} apps`, {
            number_apps: this.installedApps.length,
            apps: appSummary,
        })

        try {
            await this.currentAccount.api.patch("vizz_account.update_person_properties", {properties: {apps: appSummary}})
        } catch (error) {
            SentryService.captureError(error)
        }
    }
    // end: initialization methods


    public async refreshInstalledApps() {
        const installed: LaunchableAppModel[] = []
        for (let app of SUPPORTED_APPS) {
            let isInstalled
            try {
                isInstalled = await this.isAppInstalledAsync(app)
            } catch {
                isInstalled = false
                // no-op, let it be false
            }
            if (isInstalled) {
                if (!app.appIconUrl) {
                    app.appIconUrl = await this.fetchIcon(app)
                }
                installed.push(app)
            }
        }

        this.setInstalledApps(installed)
    }

    @action
    public setInstalledApps(installedApps: LaunchableAppModel[]) {
        this.installedApps = installedApps
    }

    public isAppInstalledAsync(app: LaunchableAppModel | string) { // do await on the return

        // No 3rd party apps on simulator, so return true to allow
        // for easier testing.
        if (!Device.isDevice) return true

        if (typeof app === 'string') {
            const found = SUPPORTED_APPS.filter((a) => a.name == app)[0]
            if (found) app = found
        }

        if (app == undefined) return false
        if (typeof app === 'string') return false
        return Linking.canOpenURL(app.deepLinkUrl + '://') // do await on the return
    }

    public getAppByNameIfInstalled(name: string) {
        const index = this.installedApps.findIndex(a => a.name == name)

        if (index >= 0)
            return this.installedApps[index]
        else
            return undefined
    }

    public openApp(app: LaunchableAppModel) {
        this.currentAccount.analytics.logEvent('launcher', 'tapped', app.name, {name: app.name})
        void Linking.openURL(app.deepLinkUrl + '://')
    }

    public async fetchIcon(app: LaunchableAppModel) {
        try {
            const url = `https://itunes.apple.com/lookup?id=${app.appId}`
            const response = await fetch(url, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            const json = await response.json() as any
            const results = json.results
            if (results.length > 0) {
                const result = results[0]
                return result['artworkUrl512'] as string
            }

            return undefined
        } catch (error) {
            console.error('Error fetching app artwork', error)
            return undefined
        }
    }

    // Private instance utility methods

    private consoleDebug(method: string, force: boolean = false) {
        if (this.debug || force) console.log(`${this.constructor.name}: ${method}`)
    }
}
