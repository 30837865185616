import {action, computed, makeObservable, observable} from "mobx"
import {RobloxGameDetailController} from "./RobloxGameDetailController"
import {GameCodeModel} from "../../models/game/GameCodeModel"
import * as Clipboard from 'expo-clipboard'
import {SpeechUtils, YouTubeUtils} from "../../../../app/utils/AVUtils"
import {CurrentAccount} from "../../../vizz_account/lib/CurrentAccount"
import {Alert} from "react-native"
import SentryService from "../../../../app/services/SentryService"
import PageModel from "../../models/PageModel"
import {GameBookModel} from "../../models/GameBookModel"
import VideoModel from "../../models/VideoModel"
import {ObjectUtils} from "../../../../app/utils/ObjectUtils"

export enum RobloxGameContentState {
    LOADING,
    LOADED
}

export class CodeViewModel {
    code: GameCodeModel

    @observable isRevealed: boolean = false
    @action setRevealed(revealed: boolean) {this.isRevealed = revealed}

    constructor(code: GameCodeModel) {
        this.code = code
        makeObservable(this)
    }
}

export class SecretViewModel {
    secret: PageModel
    parentBook: GameBookModel
    verified: boolean
    isFirstInSection: boolean

    constructor(secret: PageModel, parentBook: GameBookModel, verified: boolean, isfirstInSection: boolean) {
        this.secret = secret
        this.parentBook = parentBook
        this.verified = verified ?? false
        this.isFirstInSection = isfirstInSection
    }
}

export class RobloxGameDetailContentController {
    private debug: boolean = false  // don't set this to true in production

    private currentAccount: CurrentAccount
    private robloxGameDetailController: RobloxGameDetailController
    private speech: SpeechUtils
    private alertOpen: boolean = false
    @observable state: RobloxGameContentState = RobloxGameContentState.LOADING;         @action private setState(state: RobloxGameContentState) { this.state = state }

    @observable codes?: CodeViewModel[]
    @action setCodes(codes: CodeViewModel[]) {this.codes = codes}

    @observable secrets?: SecretViewModel[]
    @action setSecrets(secrets?: SecretViewModel[]) { this.secrets = secrets}

    constructor(currentAccount: CurrentAccount, robloxGameDetailController: RobloxGameDetailController) {
        this.consoleDebug(`new()`)

        this.currentAccount = currentAccount
        this.robloxGameDetailController = robloxGameDetailController
        this.speech = new SpeechUtils(currentAccount, true)

        this.speech.preloadPhrase('codes-copied', `This code is copied to your clipboard. Watch the video on this page if you need help pasting it into the game.`)
        this.speech.preloadPhrase('codes-copied-no-codes-video', `This code is copied to your clipboard.`)
        this.speech.preloadPhrase('codes-flagged', `"Did" you "try" this code and did it not work as you expected? If so, tap: "it did not work." We will double check this code and correct it.`)
        this.speech.preloadPhrase('codes-confirmed', `That's great! Thanks for letting us know.`)
        this.speech.preloadPhrase('codes-add', `Enter a code for this game.`)
        this.speech.preloadPhrase('codes-add-title', `Enter a short description of what this code does.`)

        makeObservable(this)
    }

    public async initialize() {
        try {
            this.consoleDebug(`initialize()`)

            // HACK: Combine the two books into one book
            // by taking any verified secrets and prepending them to the pages
            // in the verified book.
            let combinedBook = this.robloxGameDetailController.unverifiedSecretsBook
            if  (combinedBook ==  undefined) return

            const unverifiedPages = combinedBook.pages ?? []
            const verifiedPages = (this.robloxGameDetailController.verifiedSecretsBook?.pages ?? [])
                .map((page) => {
                    let newPage: any = ObjectUtils.deepCopy(page)
                    newPage.verified = true
                    return newPage
                })

            let allPages = verifiedPages.concat(unverifiedPages)
            allPages = await this.filterViewableSecrets(allPages)
            combinedBook.pages = allPages

            const unverifiedSecrets: SecretViewModel[] =
                allPages
                    .filter((s) => !(s as any).verified)
                    .map((page, index) => new SecretViewModel(page, combinedBook!!, false, index == 0))

            const verifiedSecrets: SecretViewModel[] =
                allPages
                    .filter((s) => (s as any).verified)
                    .map((page, index) => new SecretViewModel(page, combinedBook!!, true, index == 0))

            this.setSecrets(verifiedSecrets.concat(unverifiedSecrets))

            const codes = this.robloxGameDetailController.codesGameBook?.codes ?? []
            this.setCodes(codes.map((c) => new CodeViewModel(c)))

            this.setState(RobloxGameContentState.LOADED)
        } catch (error) {
            SentryService.captureError(error)
        }
    }

    public uninitialize() {
        this.consoleDebug(`uninitialize()`)

        this.speech.unloadPreloadedPhrase('codes-copied')
        this.speech.unloadPreloadedPhrase('codes-copied-no-codes-video')
        this.speech.unloadPreloadedPhrase('codes-flagged')
        this.speech.unloadPreloadedPhrase('codes-confirmed')
        this.speech.unloadPreloadedPhrase('codes-add')
        this.speech.unloadPreloadedPhrase('codes-add-title')
    }


    // Public methods

    @computed
    get codesVideo() {
        const codesBook = this.robloxGameDetailController.codesGameBook
        if (codesBook && codesBook.pages && codesBook.pages.length > 0) {
            return codesBook.pages[0].video
        } else {
            const defaultCodesVideo = {
                story_type: 'Video',
                id: 1958882,
                title: 'How to redeem codes in Roblox games',
                youtube_key: 'a3ktzOrvi98',
                blocked: false,
            } as VideoModel

            return defaultCodesVideo
        }
    }

    @computed
    get evaluatedCodesSupport() {
        return this.robloxGameDetailController.codesGameBook?.evaluated_codes_support ?? false
    }

    public async filterViewableSecrets(secrets: PageModel[]): Promise<PageModel[]> {
        const isViewablePromises: Promise<boolean>[] = secrets.map(async (secret) => {
            if (secret.video.youtube_key == undefined) {
                return true
            } else {
                const videoIsPlayable = await YouTubeUtils.isVideoPlayable(secret.video.youtube_key)
                return videoIsPlayable
            }
        })

        const isViewableArray = await Promise.all(isViewablePromises)
        const filteredSecrets = secrets.filter((_, index) => isViewableArray[index])

        const uniqueSecrets = Object.values(
            filteredSecrets.reduce((accumulator: {[key: string]: PageModel}, currentSecret) => {
                const youtubeKey = currentSecret.video.youtube_key ?? '__UNDEFINED__'

                if (!accumulator[youtubeKey]) {
                    accumulator[youtubeKey] = currentSecret
                }

                return accumulator
            }, {})
        )

        return uniqueSecrets
    }

    public async copyCode(code: GameCodeModel) {
       try {
           await Clipboard.setStringAsync(code.code)

           if (typeof this.codesVideo !== 'undefined') {
                await this.speech.speakPreloadedPhrase('codes-copied')
           } else {
                await this.speech.speakPreloadedPhrase('codes-copied-no-codes-video')
           }

           this.robloxGameDetailController.logEvent('game-details', 'code-copied', code.code, { code: code })
           await this.currentAccount.api.post(`browse.code_usage_index(${code.id})`, {})
       } catch (error) {
           SentryService.captureError(error)
       }
    }

    public async codeWorked(code: GameCodeModel) {
        try {
            void this.currentAccount.api.post(`browse.code_confirmation_index(${code.id})`, {})
            await this.speech.speakPreloadedPhrase('codes-confirmed')
        } catch (error) {
            SentryService.captureError(error)
        }
    }

    public async codeDidntWork(code: GameCodeModel) {
        if (this.alertOpen) return

        await this.speech.speakPreloadedPhrase('codes-flagged')
        this.robloxGameDetailController.logEvent('game-details', 'code-flagging', code.code, { code: code })

        this.alertOpen = true
        Alert.alert(
            `Didn't work?`,
            'Did you try this code and did it not work as you expected?',
            [
                {
                    text: 'It did not work, tell Lava',
                    onPress: async (text) => {
                        try {
                            this.speech.pause()
                            this.robloxGameDetailController.logEvent('game-details', 'flagged-code', code.code, { code: code })
                            await this.currentAccount.api.post(`browse.code_flag_index(${code.id})`, {})
                        } catch (error) {
                            SentryService.captureError(error)
                        }
                    }
                },
                {
                    text: 'Cancel',
                    onPress: () => {
                        this.speech.pause()
                        this.robloxGameDetailController.logEvent('game-details', 'cancelled-flag', code.code, { code: code })
                    }
                }
            ]
        )
        this.alertOpen = false
    }

    public async addCode() {
        if (this.alertOpen) return

        await this.speech.speakPreloadedPhrase('codes-add')
        this.robloxGameDetailController.logEvent('game-details', 'add-code-start')

        this.alertOpen = true
        Alert.prompt(
            `New Code`,
            `Do you know a code that works for ${this.robloxGameDetailController.parentBook.title}? Enter the code:`,
            [
                {
                    text: 'Cancel',
                    onPress: () => {
                        this.speech.pause()
                        this.robloxGameDetailController.logEvent('game-details', 'add-code-cancel')
                    },
                    style: 'cancel'
                },
                {
                    text: 'OK',
                    onPress: async(code) => {
                        await this.speech.speakPreloadedPhrase('codes-add-title')

                        Alert.prompt(
                            `New Code`,
                            `What does this code do?`,
                            [
                                {
                                    text: 'Cancel',
                                    onPress: () => {
                                        this.speech.pause()
                                        this.robloxGameDetailController.logEvent('game-details', 'add-code-cancel')
                                    },
                                },
                                {
                                    text: 'OK',
                                    onPress: async (title) => {
                                        this.speech.pause()
                                        void this.speech.speakPreloadedPhrase('codes-confirmed')
                                        this.robloxGameDetailController.logEvent('game-details', 'add-code-finished', code, {
                                            code: code,
                                            title: title
                                        })
                                        try {
                                            await this.currentAccount.api.post(`browse.codes_path`, {
                                                code: {
                                                    concept_id: this.robloxGameDetailController.codesGameBook?.id,
                                                    code: code,
                                                    title: title,
                                                }
                                            })
                                        } catch (error) {
                                            SentryService.captureError(error)
                                        }
                                    }
                                },
                            ]
                        )
                    }
                },
            ]
        )
        this.alertOpen = false
    }

    // Private instance utility methods

    private consoleDebug(method: string, force: boolean = false) {
        if (this.debug || force) console.log(`${this.constructor.name}: ${method}  state = ${this.state}`)
    }
}
