import React, {useContext, useState} from 'react'
import {Dimensions, View} from "react-native"
import {Color} from "../../lib/Appearance"
import {
    ActivityIndicator,
    Button,
    HelperText,
    TextInput
} from 'react-native-paper'
import VizzAudioService from '../../../modules/vizz_maker/services/VizzAudioService'
import {SpeechToText} from '../components/SpeechToText'
import CurrentAccountContext from '../../../modules/vizz_account/lib/CurrentAccount'

export default function SpeechScreen() {
    const [textToSpeak, onChangeText] = useState<string>('')
    const [loading, setLoading] = useState<boolean>(false)
    const currentAccount = useContext(CurrentAccountContext)
    const [infoMessage, setInfoMessage] = useState<string>('')

    const speak = async() => {
        var startTime = Date.now()
        setLoading(true)
        setInfoMessage("")
        console.log("About to speak: ", textToSpeak)

        const data = await currentAccount.api.post("vizz_maker.text_to_speech_path", {
            text_to_speak: textToSpeak
        })

        console.log("data=", data)
        if (data.audio_url) {
            setLoading(false)
            var audioTrack = new VizzAudioService(data.audio_url)
            await audioTrack.changeState.loadAndPlay()
            var timeTaken = Date.now() - startTime
            setInfoMessage(`That took ${(timeTaken/1000).toFixed(2)}s, of which audio generation took ${data.audio_generation_time.toFixed(2)}s`)
        }
    }

    return(
        <View style={{flex: 1}}>
            <TextInput
                mode={'outlined'}
                label={'Text to be spoken'}
                style={{
                    flex: 1,
                    margin: 16
                }}
                multiline
                numberOfLines={Dimensions.get('window').height / 72}
                editable={!loading}
                disabled={loading}
                onChangeText={text => onChangeText(text)}
                value={textToSpeak}
            />
            {loading ? <ActivityIndicator style={{marginStart: 16}} color={Color.secondary} /> : null}
            <HelperText type={'info'} visible={true}>{infoMessage}</HelperText>

            <Button
                color={Color.primary}
                disabled={loading}
                mode={'contained'}
                onPress={speak}
                style={{
                    margin: 16
                }}
                >
                Speak
            </Button>

            <SpeechToText />
        </View>
    )
}
