import {action, computed, makeObservable, observable, runInAction} from "mobx"
import {createContext} from "react"
import RailsAPIService, {HttpError} from "../../../app/services/RailsAPIService"
import PersonModel from "../models/PersonModel"
import CredentialModel from "../models/CredentialModel"
import AnalyticsService from "../../../app/lib/services/AnalyticsService"
import DayData from "../../../app/lib/services/DayData"
import PersonData from "../../../app/lib/services/PersonData"
import {FeatureFlagModel} from "../models/FeatureFlagModel"
import {ManifestUtils} from "../../../app/utils/ManifestUtils"
import SentryService from "../../../app/services/SentryService"
import {create, persist} from "mobx-persist"
import {ProfileModel} from "../../social/models/ProfileModel"
import AsyncStorage from "@react-native-async-storage/async-storage"
import {AppError} from "../../../app/lib/AppError"
import {Platform} from "react-native"

const hydrate = create({
    storage: AsyncStorage,
    jsonify: true,
})

export class CurrentAccount {
    private debug: boolean = false  // don't set this to true in production

    @observable serverCheckForAutoUpdate: boolean = true
    @observable clientCheckForAutoUpdate: boolean = true
    @observable serverUpdateNumber: number | null = null
    @observable serverVersion: string | null = null
    @observable app: string | null = null
    @observable disabledFeatures: string[] = []
    @observable playingVizzId: string | null = null
    @observable appError?: Error;                              @action public setAppError(error?: Error) { this.appError = error }

    public analytics = new AnalyticsService(this)
    private railsAPI = new RailsAPIService()
    public dayData = new DayData()
    public personData = new PersonData()

    @persist('object')
    @observable profile?: ProfileModel;                        @action setProfile(profile?: ProfileModel) { this.profile = profile }

    private initializeCompleted: boolean = false

    // Public instance methods

    constructor() {
        // We get an initial mobx trigger when serverUpdateNumber changes from undefined
        // to the first update_number response. This reaction triggers an extra API response.
        // To help avoid this, let's assume the server update number is the client update
        // number. If this is wrong it'll correct itself when the first API response comes.
        this.serverUpdateNumber = ManifestUtils.clientUpdateNumber
        this.serverVersion = ManifestUtils.clientVersion as string
        this.serverCheckForAutoUpdate = true
        this.clientCheckForAutoUpdate = true

        makeObservable(this)
    }

    public async initialize(source?: string) {
        this.consoleDebug(`initialize(${source})`)
        if (this.initializeCompleted) return

        await hydrate('account', this)

        this.railsAPI.httpToken = () => { return this.httpToken() } // not sure why we have to wrap this in an anonymous function
        await this.dayData.loadPreviousFromStorage()
        await this.personData.loadPreviousFromStorage()
        await this.analytics.initialize()
        if (!this.person && this.personData.deviceKey) await this.initPerson(this.personData.deviceKey)

        if (this.personData.deviceKey) {
            await SentryService.setDeviceKey(this.personData.deviceKey)
        }

        if (!await this.verifyHttpToken()) {
            // since the httpToken was invalid it cleared the person & deviceKey
            await this.analytics.initialize()
            if (this.personData.deviceKey) await this.initPerson(this.personData.deviceKey)
            if (!await this.verifyHttpToken()) {
                throw new Error('Unable to initialize your account.')
            }
        }

        //this.analytics.logEvent('current-account', 'initialized', source, { deviceKey: this.personData.deviceKey })
        this.initializeCompleted = true
    }

    public forceHttpToken() {
        if (this.railsAPI.httpToken) this.railsAPI.httpToken()
    }


    @action
    public updatePlayingVizz(vizzId: string | null) {
        // Check first since it will trigger a reaction() even if there is no change
        // which could trigger an unwanted restart of a playing vizz
        if (this.playingVizzId != vizzId) this.playingVizzId = vizzId
    }

    @computed
    get person() { return this.personData.get('person') as PersonModel }

    @computed
    get device() { return (this.personData.get('person') as PersonModel)?.device }

    @computed
    get authentication() { return (this.personData.get('person') as PersonModel)?.device?.authentication }

    @computed
    get isLoggedIn() { return (this.personData.get('person') as PersonModel)?.device?.authentication !== undefined}

    public api = {
        // Methods grouped just for readability

        get: async(route: string, queryStringParams: object = {}, retry: boolean = false, signal: AbortSignal|undefined = undefined, showNetworkErrors: boolean = true) => {
            if (this.appError) throw new AppError(`Issue while loading: ${this.appError.message}`)

            let response
            try {
                response = await this.railsAPI.get(route, queryStringParams, retry, signal, showNetworkErrors)
            } catch(error) {
                if (!this.appError) throw error
            }
            if (!this.appError) this.railsApiSideEffects()
            if (!this.appError) return response

            throw new AppError(`Issue while loading: ${(this.appError as Error).message}`)
        },

        post: async(route: string, payload: any, retry: boolean = false, signal: AbortSignal|undefined = undefined, showNetworkErrors: boolean = true) => {
            if (this.appError) throw new AppError(`Issue while loading: ${this.appError.message}`)

            let response
            try {
                response = await this.railsAPI.post(route, payload, retry, signal, showNetworkErrors)
            } catch(error) {
                if (!this.appError) throw error
            }
            if (!this.appError) this.railsApiSideEffects()
            if (!this.appError) return response

            throw new AppError(`Issue while loading: ${(this.appError as Error).message}`)
        },

        patch: async(route: string, payload: any, retry: boolean = false, signal: AbortSignal|undefined = undefined, showNetworkErrors: boolean = true) => {
            if (this.appError) throw new AppError(`Issue while loading: ${this.appError.message}`)

            let response
            try {
                response = await this.railsAPI.patch(route, payload, retry, signal, showNetworkErrors)
            } catch(error) {
                if (!this.appError) throw error
            }
            if (!this.appError) this.railsApiSideEffects()
            if (!this.appError) return response

            throw new AppError(`Issue while loading: ${(this.appError as Error).message}`)
        },

        delete: async(route: string, queryStringParams: object = {}, retry: boolean = false, signal: AbortSignal|undefined = undefined, showNetworkErrors: boolean = true) => {
            if (this.appError) throw new AppError(`Issue while loading: ${this.appError.message}`)

            let response
            try {
                response = await this.railsAPI.delete(route, queryStringParams, retry, signal, showNetworkErrors)
            } catch(error) {
                if (!this.appError) throw error
            }
            if (!this.appError) this.railsApiSideEffects()
            if (!this.appError) return response

            throw new AppError(`Issue while loading: ${(this.appError as Error).message}`)
        },
    }

    public async authenticate(credential: CredentialModel) {
        this.consoleDebug('authenticate()', credential)

        // The person that is returned can be completely different than the person who submitted the login form
        this.personData.person = await this.railsAPI.post("vizz_account.authentication_path", {'credential': credential}) as PersonModel
    }

    public async logout() {
        this.consoleDebug('logout()')
        try {
            await this.railsAPI.delete("vizz_account.authentication_path") as PersonModel
        } catch(e) {
            this.consoleDebug(`Error ${(e as HttpError).code} and ${(e as HttpError).message}. Logging out anyway.`)
        }

        // Regardless of whether the API call returned properly or not, we want the client to be logged out
        this.personData.person = null
    }

    public hasFeature(name: FeatureFlagModel): boolean {
        return ! this.disabledFeatures.includes(name.toLowerCase())
    }

    @computed
    get isIOSReviewer() { return this.hasFeature(FeatureFlagModel.IOS_REVIEWER) && Platform.OS == 'ios' }

    @computed
    get isAndroidReviewer() { return this.hasFeature(FeatureFlagModel.ANDROID_REVIEWER) && Platform.OS == 'android' }

    @computed
    get isAppReviewer() { return this.isIOSReviewer || this.isAndroidReviewer }


    // Private instance methods

    private async initPerson(deviceKey: string) {
        this.consoleDebug('initPerson()')

        // If the .person object currently has an authentication, it's about to be wiped because even if this
        // call finds an existing person on the server, that person will be returned without the authentication.
        // This person will have to be re-authed.
        this.personData.person = await this.railsAPI.post("vizz_account.person_path", { device_key: deviceKey }).catch((e) => { if ((e as HttpError).code == 404) { return null } else { throw e }})
        if (!this.personData.person) {
            await new Promise(resolve => setTimeout(resolve, 500))
            this.personData.person = await this.railsAPI.post("vizz_account.person_path", { device_key: deviceKey }).catch((e) => { if ((e as HttpError).code == 404) { return null } else { throw e }})
        }
        if (!this.personData.person) {
            await new Promise(resolve => setTimeout(resolve, 1000))
            this.personData.person = await this.railsAPI.post("vizz_account.person_path", { device_key: deviceKey }).catch((e) => { if ((e as HttpError).code == 404) { return null } else { throw e }})
        }
        if (!this.personData.person) {
            await new Promise(resolve => setTimeout(resolve, 1000))
            this.personData.person = await this.railsAPI.post("vizz_account.person_path", { device_key: deviceKey }).catch((e) => { if ((e as HttpError).code == 404) { return null } else { throw e }})
        }
        if (!this.personData.person) {
            await new Promise(resolve => setTimeout(resolve, 1000))
            this.personData.person = await this.railsAPI.post("vizz_account.person_path", { device_key: deviceKey }).catch((e) => { if ((e as HttpError).code == 404) { return null } else { throw e }})
        }
    }

    private async verifyHttpToken() {
        this.consoleDebug(`verifyHttpToken()`)

        try {
            let route = (this.personData?.person?.device?.authentication) ?
                'account_authenticated_path' :
                'account_unauthenticated_path'
            await this.railsAPI.get(route)
        } catch(e) {
            if ((e as HttpError).name === "HttpError" && ((e as HttpError).code == 400 || (e as HttpError).code == 401)) { // 400 (bad request) and 401 (unauthorized)
                this.personData.person = null
                this.personData.deviceKey = null
                return false
            } else
                throw e
        }
        return true
    }

    private httpToken(): string {
        const device_key = this.personData?.deviceKey || ''
        const auth_key = this.personData?.person?.device?.authentication?.key || ''
        const auth_id = this.personData?.person?.device?.authentication?.id || ''

        return `${device_key}:${auth_key}:${auth_id}`
    }

    private railsApiSideEffects() {
        if (this.serverCheckForAutoUpdate != this.railsAPI.serverCheckForAutoUpdate)
            runInAction(() => this.serverCheckForAutoUpdate = this.railsAPI.serverCheckForAutoUpdate)

        if (this.serverUpdateNumber != this.railsAPI.serverUpdateNumber)
            runInAction(() => this.serverUpdateNumber = this.railsAPI.serverUpdateNumber)

        if (this.serverVersion != this.railsAPI.serverVersion)
            runInAction(() => this.serverVersion = this.railsAPI.serverVersion)

        if (this.app != this.railsAPI.app)
            runInAction(() => this.app = this.railsAPI.app)

        const lowercaseDisabledFeatures = this.railsAPI.disabledFeatures.map(s => s.toLowerCase())
        const disabledFeaturesChanged = (JSON.stringify(this.disabledFeatures) !== JSON.stringify(lowercaseDisabledFeatures))

        if (disabledFeaturesChanged)
            runInAction(() => this.disabledFeatures = lowercaseDisabledFeatures)

        if (this.personData.person != this.railsAPI.person) {
            this.personData.person = this.railsAPI.person
        }
    }

    @action
    public setClientCheckForAutoUpdate(check: boolean) {
        this.clientCheckForAutoUpdate = check
    }

    @computed
    get checkForAutoUpdate() {
        return this.serverCheckForAutoUpdate && this.clientCheckForAutoUpdate
    }

    private consoleDebug(method: string, details: any = '') {
        if (this.debug) console.log(`${this.constructor.name}: ${method}`, details)
    }
}

const CurrentAccountContext = createContext<CurrentAccount>(new CurrentAccount())
export default CurrentAccountContext
