import {action, computed, IReactionDisposer, makeObservable, observable} from "mobx"
import {GameBookModel} from "../../models/GameBookModel"
import {CurrentAccount} from "../../../vizz_account/lib/CurrentAccount"
import HomeController from "../../../start/controllers/HomeController"
import VideoModel from "../../models/VideoModel"
import {Linking} from "react-native"
import SentryService from "../../../../app/services/SentryService"
import {SpreadDetailState} from "../../views/spread_details/SpreadDetailState"
import {ProfileModelExtensions} from "../../../social/models/ProfileModel"
import {PresenceModel} from "../../../social/models/PresenceModel"
import ChapterModel from "../../models/ChapterModel"
import {ConceptBookModel} from "../../models/ConceptBookModel"
import BookModel from "../../models/BookModel"

export class SpreadDetailController {
    private debug: boolean = false  // don't set this to true in production

    private currentAccount: CurrentAccount
    public homeController: HomeController
    public stateReaction?: IReactionDisposer
    private openTrigger?: string

    @observable parentBook: ConceptBookModel;                           @action setParentBook(book: ConceptBookModel) {this.parentBook = book}
    @observable modalVideo?: VideoModel;                                @action setModalVideo(video?: VideoModel) {this.modalVideo = video}
    @observable state: SpreadDetailState = SpreadDetailState.LOADING;   @action setState(state: SpreadDetailState) {this.state = state}

    constructor(currentAccount: CurrentAccount, homeController: HomeController, parentBook: ConceptBookModel, openTrigger?: string) {
        this.currentAccount = currentAccount
        this.homeController = homeController
        this.parentBook = parentBook
        this.openTrigger = openTrigger
        makeObservable(this)
    }

    public async initialize() {
        this.consoleDebug(`initialize()`)

        try {
            this.logEvent('spread-details', 'opened', this.parentBook.title)
            let book = await this.currentAccount.api.get(`browse.concept_book_path(${this.parentBook.id})`, {
                include_backfill: false,
                preload_next_level: true,
            }) as ConceptBookModel


            const chapterOrder = [ 'Breeds', 'Why Dogs Are Awesome', 'Tricks', 'How to Teach Tricks', 'Care and Basic Training' ]
            if (book.chapters) book.chapters.sort((c1, c2) => chapterOrder.indexOf(c1.title) - chapterOrder.indexOf(c2.title))

            this.setParentBook(book)

            this.setState(SpreadDetailState.LOADED)
        } catch (error) {
            SentryService.captureError(error)
        }
    }

    public uninitialize() {
        this.consoleDebug(`initialize()`)

        this.homeController.appController.video.stopPlayingVideo()
        if (this.stateReaction) this.stateReaction()
    }

    public playVideo(video?: VideoModel) {
        if (!video) {
            this.setModalVideo(undefined)
            return
        }
        this.logEvent('spread-details', 'play', video.title)
        this.setModalVideo(video)
    }

    public stopVideo() {
        this.logEvent('spread-details', 'stop', this.modalVideo?.title)
        this.setModalVideo(undefined)
    }

    public logEvent(thing: string, happened: string, note?: string | undefined, props?: any) {
        if (this.currentAccount) this.currentAccount.analytics.logEvent(thing, happened, note, {
            parent_book_id: this.parentBook.id,
            parent_book_title: this.parentBook.title,
            trigger: this.openTrigger,
            ...props
        })
    }

    @computed
    get previewVideo(): VideoModel | undefined {
        if (this.parentBook.pages && this.parentBook.pages.length > 0 && this.parentBook.pages[0].video) {
            return this.parentBook.pages[0].video
        } else {
            return undefined
        }
    }


    // Private instance utility methods

    private consoleDebug(method: string, force: boolean = false) {
        if (this.debug || force) console.log(`${this.constructor.name}: ${method}`)
    }
}