import {action, computed, makeObservable, observable} from "mobx"
import {ConceptName, ConceptNodeResponse, ConceptNodeState, ConceptTag} from "../models/Concept"
import {CurrentAccount} from "../../../modules/vizz_account/lib/CurrentAccount"


export class ConceptNode {
    id: string = '0'
    @observable
    names: ConceptName[]
    @observable
    searchPhrases: string
    @observable
    isExpanded: boolean = false
    @observable
    isVisible: boolean
    @observable
    isSuggested: boolean = false
    @observable
    isPriority: boolean = false
    @observable
    parent?: ConceptNode
    @observable
    immediate_descendents: ConceptNode[] = []
    @observable
    primaryMediaUrl?: string
    @observable
    primaryMediaSourceUrl?: string
    @observable
    fallbackMediaUrl?: string

    constructor(response: ConceptNodeResponse) {
        makeObservable(this)
        this.id = response.id
        let names: ConceptName[] = response.names.filter((n) => n.primary)
        response.names
            .filter((n) => !n.primary)
            .forEach((n) => names.push(n))
        this.names = names

        this.searchPhrases = response.search_phrases
        this.isVisible = response.state == ConceptNodeState.VISIBLE
        this.isSuggested = response.bookmarked
        this.isPriority = response.top
        this.primaryMediaUrl = response.primary_media?.concept_media_url
        this.primaryMediaSourceUrl = response.primary_media?.source_url
        this.fallbackMediaUrl = response.fallback_media?.concept_media_url
    }

    @computed
    get primaryName(): string {
        const results = this.names.filter((n) => n.primary)
        if (results.length > 0) {
            return results[0].name
        } else {
            return this.names[0].name
        }
    }

    isHiddenChild() {
        if (this.parent) {
            return !this.isVisible && !this.parent.isVisible
        } else {
            return false
        }
    }

    @action
    setExpanded(expanded: boolean) {
        this.isExpanded = expanded
    }

    @action
    setVisible(visible: boolean) {
        this.isVisible = visible
    }

    @action
    setParent(parent?: ConceptNode) {
        this.parent = parent
    }

    @action
    setChildren(children: ConceptNode[]) {
        this.immediate_descendents = children
    }

    @action
    addChild(node: ConceptNode) {
        this.immediate_descendents.push(node)
    }

    @action
    setNames(names: ConceptName[]) {
        this.names = names
    }

    @action
    setSearchPhrases(searchPhrases: string) {
        this.searchPhrases = searchPhrases
    }

    @action
    setSuggested(suggested: boolean) {
        this.isSuggested = suggested
    }

    @action
    setPriority(priority: boolean) {
        this.isPriority = priority
    }

    @action
    setPrimaryMediaUrl(url: string) {
        this.primaryMediaUrl = url
    }
}

export class ConceptStore {

    private currentAccount: CurrentAccount
    @observable
    concepts: ConceptNode[] = []

    @observable
    editingNode?: ConceptNode

    constructor(currentAccount: CurrentAccount) {
        this.currentAccount = currentAccount
        makeObservable(this)
        void this.fetchNode(undefined)
    }

    @action
    setConcepts(concepts: ConceptNode[]) {
        this.concepts = concepts
    }

    @action
    addConcept(node: ConceptNode) {
        this.concepts.push(node)
    }

    @action
    updateAll() {
        this.concepts = ConceptStore.sort(this.concepts)
        this.concepts.forEach(ConceptStore.update)
    }

    static sort(concepts: ConceptNode[]): ConceptNode[] {
        const visible = concepts
            .filter((c) => c.isVisible)
            .sort((a, b) => (a.primaryName > b.primaryName) ? 1 : -1)
        const hidden = concepts
            .filter((c) => !c.isVisible)
            .sort((a, b) => (a.primaryName > b.primaryName) ? 1 : -1)

        return visible.concat(hidden)
    }

    @action
    static update(node: ConceptNode) {
        if (node.immediate_descendents.length == 0) return
        node.setChildren(ConceptStore.sort(node.immediate_descendents))
        node.immediate_descendents.forEach((c) => {
            c.setParent(node)
            if (!node.isVisible) {
                c.setVisible(false)
            }
            ConceptStore.update(c)
        })
    }

    @action
    async updatePrimaryMedia(node: ConceptNode, newImageUrl: string) {

        const media = await this.currentAccount.api.post(`vizz_graph.add_concept_media(${node.id})`, {
            'source_url': newImageUrl
        })


        await this.currentAccount.api.post(`vizz_graph.promote_media_to_primary(${node.id})`, {
            'media_id': media.id
        })

        node.setPrimaryMediaUrl(media.concept_media_url)
    }

    @action
    public async toggleExpanded(node: ConceptNode) {
        if (!node.isExpanded && node.immediate_descendents.length == 0) {
            await this.fetchNode(node)
        }

        node.setExpanded(!node.isExpanded)
    }

    @action
    public setEditingNode(node: ConceptNode | undefined) {
        this.editingNode = node
    }

    siblingsForNode(node: ConceptNode): ConceptNode[] {
        if (node.parent) {
            return node.parent.immediate_descendents.filter((c) => c.id != node.id)
        } else {
            return this.concepts.filter((c) => c.id != node.id)
        }
    }

    @action
    async fetchNode(node?: ConceptNode) {
        let path = ''
        if (node) {
            path = `vizz_graph.concepts(${node.id})`
        } else {
            path = 'vizz_graph.concepts_all'
        }
        const nodes = await this.currentAccount.api.get(path) as ConceptNodeResponse[]

        const conceptNodes = nodes
            .filter((n) => n.state != ConceptNodeState.DISABLED)
            .map((n) => new ConceptNode(n))

        if (node) {
            node.setChildren(conceptNodes)
        } else {
            this.setConcepts(conceptNodes)
        }

        this.updateAll()
    }

    @action
    async moveNodeToNode(node: ConceptNode, toNode: ConceptNode|undefined) {
        console.log(`Moving: ${node.primaryName} to: ${toNode?.primaryName}`)

        await this.fetchNode(toNode)

        await this.currentAccount.api.post(`vizz_graph.change_concept_parent(${node.id})`, {
            'from_concept_id': node.parent?.id ?? null,
            'to_concept_id': toNode?.id ?? null
        })

        if (node.parent) {
            const newChildren = node.parent.immediate_descendents.filter((n) => n.id != node.id)
            node.parent.setChildren(newChildren)
        } else {
            this.concepts = this.concepts.filter((c) => c.id != node.id)
        }

        if (toNode) {
            let newNodeChildren = toNode.immediate_descendents
            newNodeChildren.push(node)
            toNode.setChildren(newNodeChildren)
        } else {
            this.concepts.push(node)
        }

        node.setParent(toNode)

        this.updateAll()
    }

    @action
    async mergeNodeIntoNode(node: ConceptNode, toNode: ConceptNode) {
        await this.currentAccount.api.post('vizz_graph.merge_concepts', {
            'from_id': node.id,
            'to_id': toNode.id
        })

        node.parent?.setChildren([])
        await this.fetchNode(node.parent)
        toNode.parent?.setChildren([])
        await this.fetchNode(toNode.parent)
    }

    @action
    async setNodeVisible(node: ConceptNode, visible: boolean) {
        console.log(`SET VISIBLE ${node.id} : ${visible}`)
        await this.currentAccount.api.patch(`vizz_graph.concepts(${node.id})`, {
            'state': visible ? 'visible' : 'hidden'
        })

        node.setVisible(visible)
        this.updateAll()
    }

    @action
    async disableNode(node: ConceptNode) {
        await this.currentAccount.api.patch(`vizz_graph.concepts(${node.id})`, {
            'state': 'disabled'
        })

        if (node.parent) {
            const newChildren = node.parent.immediate_descendents.filter((n) => n.id != node.id)
            node.parent.setChildren(newChildren)
        } else {
            this.concepts = this.concepts.filter((n) => n.id != node.id)
        }

        node.setParent(undefined)
    }

    @action
    async addName(node: ConceptNode) {
        const newName = await this.currentAccount.api.post('vizz_graph.create_concept_name', {
            'concept_id': node.id,
            'name': ''
        }) as ConceptName

        let names = node.names
        names.push(newName)
        node.setNames(names)
    }

    @action
    async removeName(node: ConceptNode, name: ConceptName) {
        await this.currentAccount.api.delete(`vizz_graph.delete_concept_name(${name.id})`)
        const names = node.names.filter((n) => n.id != name.id)
        node.setNames(names)
    }

    @action
    async makeNamePrimary(node: ConceptNode, name: ConceptName) {
        await this.currentAccount.api.post(`vizz_graph.promote_concept_name_to_primary(${name.id})`, {})
        const names = node.names.map((n) => {
            n.primary = n.id == name.id
            return n
        })
        node.setNames(names)
    }

    @action
    async changeName(node: ConceptNode, name: ConceptName) {
        await this.currentAccount.api.patch(`vizz_graph.change_concept_name(${name.id})`, {
            'name': name.name
        })
        node.setNames(node.names)
    }

    @action
    async changeSearchPhrases(node: ConceptNode, searchPhrases: string) {
        await this.currentAccount.api.patch(`vizz_graph.concepts(${node.id})`, {
            'search_phrases': searchPhrases
        })
        node.setSearchPhrases(node.searchPhrases)
    }

    @action
    async toggleSuggested(node: ConceptNode) {
        if (node.isSuggested) {
            await this.currentAccount.api.delete(`vizz_graph.remove_tag_from_concept(${node.id})`, {
                'tag_name': ConceptTag.SUGGESTED
            })
        } else {
            await this.currentAccount.api.post(`vizz_graph.add_tag_to_concept(${node.id})`, {
                'tag_name': ConceptTag.SUGGESTED
            })
        }

        node.setSuggested(!node.isSuggested)
    }

    @action
    async togglePriority(node: ConceptNode) {
        if (node.isPriority) {
            await this.currentAccount.api.delete(`vizz_graph.remove_tag_from_concept(${node.id})`, {
                'tag_name': ConceptTag.PRIORITY
            })
        } else {
            await this.currentAccount.api.post(`vizz_graph.add_tag_to_concept(${node.id})`, {
                'tag_name': ConceptTag.PRIORITY
            })
        }
        node.setPriority(!node.isPriority)
    }

    @action
    async flagAllDescendants(conceptId: string) {
        await this.currentAccount.api.post(`vizz_graph.flag_all_descendent_vizzes(${conceptId})`, {})
    }

    @action
    async createConcept(name: string, parent: ConceptNode|undefined) {
        const response = await this.currentAccount.api.post(`vizz_graph.concepts`, {
            name: name,
            parent_concept_id: parent?.id ?? null
        }) as ConceptNodeResponse

        const node = new ConceptNode(response)

        if (parent) {
            parent.addChild(node)
        } else {
            this.addConcept(node)
        }
    }
}
