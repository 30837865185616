export interface LaunchableAppModel {
    appId: string
    name: string
    deepLinkUrl: string
    appIconUrl?: string
}

export const SUPPORTED_APPS: LaunchableAppModel[] = [
    {
        appId: '545519333',
        name: "Amazon Prime Video",
        deepLinkUrl: 'aiv'
    },
    {
        appId: '1446075923',
        name: "Disney+",
        deepLinkUrl: 'disneyplus'
    },
    {
        appId: '479516143',
        name: "Minecraft",
        deepLinkUrl: 'minecraft'
    },
    {
        appId: '363590051',
        name: "Netflix",
        deepLinkUrl: 'nflx'
    },
    {
        appId: '431946152',
        name: "Roblox",
        deepLinkUrl: 'robloxmobile',
        appIconUrl: 'https://res.cloudinary.com/explanation/image/upload/v1655497354/app_icons/roblox-icon_y6izrg.jpg'
    },
    {
        appId: '544007664',
        name: "YouTube",
        deepLinkUrl: 'youtube'
    },
    {
        appId: '936971630',
        name: "YouTube Kids",
        deepLinkUrl: 'vnd.youtube.kids'
    },
    {
        appId: '1193350206',
        name: "YouTube TV",
        deepLinkUrl: 'vnd.youtube.tv'
    },
    {
        appId: '1017492454',
        name: "YouTube Music",
        deepLinkUrl: 'youtubemusic'
    },
    {
        appId: '835599320',
        name: "TikTok",
        deepLinkUrl: 'tiktok'
    },
    {
        appId: '454638411',
        name: "Messenger",
        deepLinkUrl: 'fb-messenger'
    },
    {
        appId: '1146560473',
        name: "Messages",
        deepLinkUrl: 'messages'
    },
    {
        appId: '447188370',
        name: "Snapchat",
        deepLinkUrl: 'snapchat'
    },
    {
        appId: '1285713171',
        name: "Messenger Kids",
        deepLinkUrl: 'fb-mk'
    },
    {
        appId: '310633997',
        name: "WhatsApp",
        deepLinkUrl: 'whatsapp'
    },
]