import React, {useContext} from 'react'
import {View, TouchableWithoutFeedback} from "react-native"
import {Text} from "react-native-paper"
import * as Clipboard from 'expo-clipboard'
import CurrentAccountContext from '../../modules/vizz_account/lib/CurrentAccount'
import {ManifestUtils} from '../utils/ManifestUtils'
import {useSafeAreaInsets} from 'react-native-safe-area-context'

export const LavaSplashFooter = () => {
    const currentAccount = useContext(CurrentAccountContext)
    const safeAreaInsets = useSafeAreaInsets()

    return (
        <View
            style={{
                position: 'absolute',
                bottom: safeAreaInsets.bottom + 5,
                width: '100%',
            }}>
                <Text style={{marginTop: 16, color: 'rgba(50,50,50,0.4)', fontSize: 8, textAlign: 'center'}}>Server {currentAccount.serverUpdateNumber} (Client {ManifestUtils.clientUpdateNumber})</Text>
                <TouchableWithoutFeedback onPress={() => void Clipboard.setStringAsync(currentAccount.personData.deviceKey ?? '')}>
                    <Text style={{marginTop: 5, color: 'rgba(50,50,50,0.4)', fontSize: 8, textAlign: 'center'}}>{currentAccount.personData.deviceKey}{' '}</Text>
                </TouchableWithoutFeedback>
        </View>
    )
}