import React, { ReactNode } from 'react';
import {SafeAreaProvider} from 'react-native-safe-area-context'

interface CustomSafeAreaViewProps {
    children: ReactNode;
}

// TODO: Why do we need another provider? Kill this
export const CustomSafeAreaProvider: React.FC<CustomSafeAreaViewProps> = ({ children }) => {
    return (
        <SafeAreaProvider style={{ flex: 1 }}>
            {children}
        </SafeAreaProvider>
    );
};
