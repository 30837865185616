import {Platform} from "react-native"

export const platformName = () => {
    switch (Platform.OS) {
        case 'ios': return 'iOS'
        case 'android': return 'Android'
        case 'web': return 'Web'
        default: return Platform.OS
    }
}
