import React, {useContext, useEffect, useState} from "react"
import {Dimensions,  View, Image, Text, FlatList, ListRenderItemInfo} from "react-native"
import {observer} from "mobx-react"
import {useNavigation} from "@react-navigation/native"
import {Color} from "../../lib/Appearance"
import {Card, Button, TextInput, ActivityIndicator} from "react-native-paper"
import CurrentAccountContext from "../../../modules/vizz_account/lib/CurrentAccount"

const IdentificationResultsScreen = observer(() => {

    const navigation = useNavigation<any>()
    const [identificationResults, setIdentificationResults] = useState<object[]>([])
    const currentAccount = useContext(CurrentAccountContext)
    const [loading, setLoading] = useState<boolean>(false)
    const [phrase, setPhrase] = useState<string>('')

    useEffect(() => {
        void refreshPage()
    }, [])

    const refreshPage = async () => {
        const results = await currentAccount.api.get("vizz_maker.image_identification_results_path")
        setIdentificationResults(results)
    }

    const saveVisionPhrase = async() => {
        setLoading(true)
        await currentAccount.api.post("vizz_maker.add_vision_phrase_path", {
            phrase: phrase
        })
        setPhrase('✓ Added')
        setLoading(false)
    }

    const deleteItem = async (imageUrl: string) => {
        await currentAccount.api.delete('vizz_maker.image_identification_results_path',
            { encoded_image_url: encodeURIComponent(imageUrl) })
        await refreshPage()
    }

    const renderListItem = (info: ListRenderItemInfo<any>) => {
        const item: any = info.item

        return (
            <Card style={{margin: 16}}>
                <Card.Content>
                    <Card.Content>
                        <Image style={{height: Dimensions.get('window').height * 0.5}}
                            source={{uri: (item as any)["image_url"]}}
                            resizeMode={'contain'}/>
                        <Button
                            mode={'contained'}
                            style={{margin: 10}}
                            onPress={() => void deleteItem((item as any)["image_url"])}>
                            Delete
                        </Button>
                    </Card.Content>
                    <Card.Content>
                        <Text>
                            {(item as any)["answer_text"]}
                        </Text>
                        <Button
                            onPress={() => navigation.navigate('VizzDetail', {vizzId: (item as any)["raw_result_json"]["vizzId"]})}>
                            Answer Vizz
                        </Button>
                    </Card.Content>
                    <Card.Content>
                        <Button
                            onPress={() => navigation.navigate('IdentificationResultDetails', {item: item})}
                            >
                            Details
                        </Button>
                    </Card.Content>
                </Card.Content>
            </Card>
        )
    }

    return (
        <FlatList
            data={identificationResults}
            ListHeaderComponent={
                <View>
                    <Text style={{textAlign: "center", fontSize: 20}}>
                        Identification Results
                    </Text>
                    <View style={{flex: 1, flexDirection: "row"}}>
                        <TextInput
                            mode={'outlined'}
                            label={'Phrase'}
                            style={{margin: 10, flex: 1}}
                            editable={!loading}
                            disabled={loading}
                            onChangeText={text => setPhrase(text)}
                            value={phrase}
                        />
                    </View>
                    <View>
                        {loading ? <ActivityIndicator style={{marginStart: 16}} color={Color.secondary} /> : null}
                        <Button
                            color={Color.primary}
                            disabled={loading}
                            mode={'contained'}
                            onPress={saveVisionPhrase}>
                            Add New Vision Phrase
                        </Button>
                    </View>
                </View>
            }
            viewabilityConfig={{
                waitForInteraction: true,
                itemVisiblePercentThreshold: 75,
            }}
            renderItem={renderListItem}
            keyExtractor={item => (item as any)["id"]}
        />
    )
})

export default IdentificationResultsScreen
