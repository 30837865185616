import React, {useCallback, useContext, useEffect, useRef, useState} from 'react'
import {observer} from "mobx-react"
import CurrentAccountContext from "../../vizz_account/lib/CurrentAccount"
import AppMessagesContext from "../../../app/lib/AppMessage"
import {Animated, AppState, AppStateStatus, Dimensions, Easing, StyleSheet, View} from "react-native"
import {useFocusEffect, useNavigation} from "@react-navigation/native"
import {isLandscape, isPortrait, Style} from "../../../app/lib/Appearance"
import {reaction} from "mobx"
import LogoBar from './home/LogoBar'
import Background from './home/Background'
import WidgetContainer from './home/WidgetContainer'
import {Onboarding} from '../../onboarding/views/Onboarding'
import Series from '../../browse/views/library_widget/Series'
import {FriendWidget} from '../../social/views/FriendWidget'
import {LibraryWidget} from '../../browse/views/LibraryWidget'
import {InboxWidget} from '../../social/views/InboxWidget'
import {FeedWidget} from '../../social/views/FeedWidget'
import HomeController, {HomeState} from '../controllers/HomeController'
import AppControllerContext from '../../../app/controllers/AppController'
import {RobloxGamesWidget} from "../../browse/views/RobloxGamesWidget"
import DimBackground from '../../browse/views/DimBackground'
import {ActivityIndicator} from "react-native-paper"
import {AddFriend} from "../../social/views/friend_widget/AddFriend"
import {RobloxGameDetail} from "../../browse/views/roblox_games_widget/RobloxGameDetail"
import {GameBookModel} from "../../browse/models/GameBookModel"
import {ChannelDetail} from "../../social/views/ChannelDetail"
import {MinimizedVideoCall} from "../../social/views/MinimizedVideoCall"
import {HomeMobile} from "./HomeMobile"
import {useSafeAreaInsets} from 'react-native-safe-area-context'
import {ModeratorMenu} from './ModeratorMenu'
import {FullscreenModal} from '../../../app/views/components/FullscreenModal'
import {SpreadDetail} from '../../browse/views/spread_details/SpreadDetail'
import {ConceptBookModel} from '../../browse/models/ConceptBookModel'
import {RenderLog} from "../../../app/services/RenderLog"
import {DraftFriends} from "../../social/views/friend_widget/DraftFriends"

export const Home = observer(() => {
    const currentAccount = useContext(CurrentAccountContext)
    const appController = useContext(AppControllerContext)
    const [controller] = useState<HomeController>(() => new HomeController(currentAccount, appController))
    const safeAreaInsets = useSafeAreaInsets()

    useEffect(() => {
        void controller.initialize()

        return () => {
            controller.uninitialize()
        }
    }, [])

    const appMessage = useContext(AppMessagesContext)
    const navigation = useNavigation<any>()

    // Stuff from Feed

    const [refreshing, setRefreshing] = useState<boolean>(false)
    const isFocused = useRef(false)
    const isAutoPlayEnabled = useRef<boolean>(false)


    useEffect(() => {
        const playingVizz = reaction(() => currentAccount.playingVizzId,
            (vizzId) => {
                if (vizzId && !isAutoPlayEnabled.current) {
                    isAutoPlayEnabled.current = true
                }
            })

        return () => {
            playingVizz()
        }
    }, [])

    useFocusEffect(
        useCallback(() => {
            isFocused.current = true
            const stateChange = AppState.addEventListener('change', handleAppStateChange)

            return () => {
                isFocused.current = false
                stateChange.remove()
                currentAccount.updatePlayingVizz(null)
            }
        }, [])
    )

    const handleAppStateChange = (nextAppState: AppStateStatus) => {
        if (nextAppState == 'active') {
            isAutoPlayEnabled.current = false
            refresh(true)
        }
    }

    useEffect(() => {
        refresh()
    }, [])

    const onDebugTapped = useCallback(() => {
        navigation.navigate('Debug')
    }, [navigation])

    const refresh = (refreshConcepts: boolean = false) => {
        if (refreshing && isFocused) return

        currentAccount.updatePlayingVizz(null)

        try {
            //await feedStore.refresh()
            setRefreshing(false)
        } catch (error) {
            appMessage.setError(error as Error)
        }
    }

    // Inbox animation

    const inboxScale = useRef(new Animated.Value(1))
    const inboxAnimation = useRef(Animated.loop(
        Animated.sequence([
            Animated.timing(inboxScale.current, {
                toValue: 1.01,
                duration: 300,
                easing: Easing.out(Easing.ease),
                useNativeDriver: true
            }),
            Animated.timing(inboxScale.current, {
                toValue: 1.0,
                duration: 700,
                easing: Easing.in(Easing.ease),
                useNativeDriver: true
            })
        ])
    ))


    useEffect(() => {
        const cleanupInboxReaction = reaction(() => controller.isShowingInboxCallout, (isShowing) => {
            if (isShowing) {
                inboxAnimation.current.start()
            } else {
                inboxAnimation.current.stop()
            }
        })

        return () => {
            cleanupInboxReaction()
        }
    }, [])

    const barHeight = Dimensions.get('screen').height * Style.SearchBar.heightPerct

    if (controller.currentBook && controller.eligibleForInvitationReminder) {
        controller.friendWidgetController?.showInvitationReminder()
    }

    if (controller.state == HomeState.ONBOARDING) {
        return (
            <View style={{flex: 1, backgroundColor: 'black'}}>

                {isLandscape() &&
                <View style={{flex: 1}}>
                    {controller.revealPhase > 0 && <LogoBar height={barHeight} onDebugTapped={onDebugTapped} homeController={controller} variant={'primaryLarge'} />}
                </View>
                }

                {isPortrait() &&
                <View style={{flex: 1}}>
                    {controller.revealPhase > 0 && <LogoBar height={barHeight} onDebugTapped={onDebugTapped} homeController={controller} variant={'primarySmall'} />}
                </View>
                }

                <Onboarding homeController={controller}/>
            </View>
        )
    }

    RenderLog.track('Home')

    return (
        <View style={{flex: 1}}>
            {isLandscape() &&
                <View style={{flex: 1}}>
                    <LogoBar height={barHeight} onDebugTapped={onDebugTapped} homeController={controller} variant={'secondary'} />
                    <WidgetContainer style={{flexDirection: 'row', marginTop: safeAreaInsets.top + barHeight + 20}}>
                        <View style={{maxWidth: 350, minWidth: 350, height: '100%'}}>
                            <InboxWidget homeController={controller} style={{marginBottom: 10}} mode={'widget'} />
                            <FriendWidget homeController={controller} mode={'widget'} />
                        </View>

                        <View style={{flexGrow: 1, flexDirection: 'column'}}>
                            <FeedWidget
                                onVideoTapped={(video, position) => controller.openVideo(video, 'tapped-inbox', position)}
                                homeController={controller}
                                onTappedFriendPlayedGame={(book) => controller.openBook(book, 'feed-friend-played', undefined, 'trailer')}
                                horizontal={true}
                                style={{
                                    height: 264,
                                    marginRight: 0,
                                    paddingRight: 0
                                }}/>

                            <View style={{flexGrow: 1}}/>

                            {currentAccount.personData.hasRobloxAccount &&
                                <RobloxGamesWidget
                                    style={{
                                        height: 168,
                                        marginRight: 0,
                                        paddingRight: 0
                                    }}
                                    homeController={controller}
                                    onBookTapped={(book, focus) => {
                                        controller.openBook(book, 'roblox-games-list', undefined, focus)
                                    }}
                                    type={'regular'}
                                />}

                            <View style={{flexGrow: 1}}/>

                            <LibraryWidget onDebugTapped={onDebugTapped}
                                           style={{
                                               marginRight: 0,
                                               paddingRight: 0,
                                               height: 140,
                                               marginBottom: 10,
                                            }}
                                           homeController={controller}
                                           rows={currentAccount.personData.hasRobloxAccount ? 1 : 2}
                                           type={'regular'} />

                            {!currentAccount.personData.hasRobloxAccount &&
                            <View style={{flexGrow: 1}} />}
                        </View>
                    </WidgetContainer>
                </View>
            }

            {isPortrait() &&
                <HomeMobile homeController={controller} barHeight={barHeight} onDebugTapped={onDebugTapped} />}

            {/* Below are all overlays */}

            {controller.dimBackground &&
                <DimBackground active={controller.dimBackground} duration={100}/>}

            {(controller.currentBook && controller.currentBook.type == 'Concept' && !controller.currentBook.title.includes('World of')) && !controller.eligibleForInvitationReminder &&
                <Series
                    firstBook={controller.currentBook}
                    firstBookOpenTrigger={controller.currentBookOpenTrigger}
                    firstBookOpenPosition={controller.currentBookOpenPosition}
                    minTop={0}
                    searchBarHeight={barHeight}
                    onDeactivated={() => controller.closeOverlays()}
                    homeController={controller}
                />}

            {controller.friendWidgetController.addFriendVisible &&
                <AddFriend friendWidgetController={controller.friendWidgetController}/>}

            {controller.friendWidgetController.personKeysForChannelDetail &&
                <ChannelDetail
                    friendWidgetController={controller.friendWidgetController}
                    homeController={controller}/>}

            {controller.gameDetailVisible &&
                <RobloxGameDetail
                    homeController={controller}
                    book={controller.currentBook as GameBookModel}
                    openTrigger={controller.currentBookOpenTrigger}
                    onDismiss={() => controller.closeBook()}
                />}

            {(controller.currentBook && controller.currentBook.type == 'Concept' && controller.currentBook.title.includes('World of')) && !controller.eligibleForInvitationReminder &&
                <SpreadDetail
                    homeController={controller}
                    book={controller.currentBook as ConceptBookModel}
                    openTrigger={controller.currentBookOpenTrigger}
                    onDismiss={() => controller.closeBook()}
            />}

            {controller.appController.call.activeCallConnection && controller.friendWidgetController.isShowingMinimizedCall &&
                <MinimizedVideoCall
                    friendWidgetController={controller.friendWidgetController}
                    homeController={controller}/>}

            {controller.loading &&
                <View style={{
                    flex: 1,
                    backgroundColor: 'rgba(0, 0, 0, 0.7)',
                    justifyContent: 'center',
                    alignItems: 'center', ...StyleSheet.absoluteFillObject
                }}>
                    <ActivityIndicator color={'white'}/>
                </View>}

            {controller.moderatorMenuVisible &&
            <FullscreenModal visible={true} onDidDisappear={() => controller.setModeratorMenuVisible(false)} innerModal={false}>
                <ModeratorMenu homeController={controller} />
            </FullscreenModal>}

            {controller.draftFriendRequests &&
            <DraftFriends draftFriendRequests={controller.draftFriendRequests} onFinish={() => {
                controller.setDraftFriendRequests(undefined)
                controller.checkMomentsAfterForegroundTasks()
            }}/>}

            <Background/>
        </View>
    )

})

const styles = StyleSheet.create({})
