import React from 'react'
import {MessageNotification} from "../../../../app/lib/MessageNotification"
import {StyleSheet, Text, TouchableOpacity, View} from "react-native"
import {MaterialCommunityIcons} from "@expo/vector-icons"
import {Typography} from "../../../../app/lib/Typography"
import {Color} from "../../../../app/lib/Appearance"

type Props = {
    notification: MessageNotification
    onPress: () => void
}

export const ChatMessage = (props: Props) => {

    return (
        <TouchableOpacity onPress={props.onPress} style={{}}>
            <View style={{flex: 1, flexDirection: 'row'}}>
                <View style={{
                    marginEnd: 8,
                    width: 40,
                    height: 40,
                    backgroundColor: 'white',
                    borderRadius: 6,
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                    <MaterialCommunityIcons name="chat" size={24} color={Color.messagesRed}/>
                </View>

                <View style={{flex: 1, justifyContent: 'center'}}>
                    <Text style={[Typography.label]}>{props.notification.displayUsernames.join(', ').replace("_", " ")}</Text>
                    <Text style={[Typography.label]}>“ {props.notification.message.substring(0,50)}{props.notification.message.length > 50 ? '...' : ''}”</Text>
                </View>
            </View>
        </TouchableOpacity>
    )
}

const styles = StyleSheet.create({
    title: {
    }
})