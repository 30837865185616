import AsyncStorage from "@react-native-async-storage/async-storage"
import {create, persist} from 'mobx-persist'
import {action, makeObservable, observable} from "mobx"
import {ConceptNodeResponse} from "../models/Concept"
import {CurrentAccount} from "../../../modules/vizz_account/lib/CurrentAccount"

const hydrate = create({
    storage: AsyncStorage,
    jsonify: true,
})

export class FeedStore {

    @persist('list')
    @observable
    public suggestedConcepts: ConceptNodeResponse[] = []

    @observable
    public broadcastEvent?: string = undefined

    currentAccount: CurrentAccount

    constructor(currentAccount: CurrentAccount) {
        makeObservable(this)
        void hydrate('feed', this)
        this.currentAccount = currentAccount
    }

    @action
    setSuggestedConcepts(suggestedConcepts:ConceptNodeResponse[]) {
        this.suggestedConcepts = suggestedConcepts
    }

    @action
    public reset() {
        this.suggestedConcepts = []
        this.broadcastEvent = 'reset-cache'
    }

    // NETWORK

    public async refresh() {
        let results = await this.currentAccount.api.get('vizz_graph.tagged_with_concepts', {tag_name: 'bookmarked'}) as ConceptNodeResponse[]
        results = results.filter((concept, index, arr) => {
            if (concept && concept?.number_of_immediate_descendents && concept?.number_of_immediate_descendents > 0)
                return true
            else
                return false
        })

        this.setSuggestedConcepts(results)
    }

}
