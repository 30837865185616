import {action, makeObservable, observable} from "mobx"
import React, {createContext, useContext} from "react"
import {Button, Dialog, Paragraph, Portal, Snackbar} from "react-native-paper"
import {View} from "react-native"
import {observer} from "mobx-react"
import AppConstants from "./AppConstants"

export class AppMessage {

    @observable
    error?: Error

    @observable
    info?: string

    constructor() {
        makeObservable(this)
    }

    @action
    public setError(error?: any) {
        console.error(error)

        // AbortError is the result of an abort controller cancelling an async
        // operation and it's not something the user should ever be notified of.
        if (error?.name == 'AbortError') {
            console.log('Abort error reported, skipping display.')
            return
        }
        this.error = error
    }

    @action
    public setInfo(info?: string) {
        this.info = info
    }

}

export const AppError = observer(()  => {
    const appMessages = useContext(AppMessagesContext)

    const dismiss = () => {
        appMessages.setError(undefined)
    }

    const renderDetail = () => {
        if (!AppConstants.isInternalUse()) {
            return null
        }
        return (
            <View>
                <Paragraph>{appMessages.error?.name}</Paragraph>
                <Paragraph>{appMessages.error?.message}</Paragraph>
            </View>
        )
    }

    return (
        <Portal>
            <Dialog visible={appMessages.error != undefined} onDismiss={dismiss}>
                <Dialog.Title>Problem</Dialog.Title>
                <Dialog.Content>
                    <Paragraph>A problem has occurred. Please try again later.</Paragraph>
                    {renderDetail()}
                </Dialog.Content>
                <Dialog.Actions>
                    <Button onPress={dismiss}>Dismiss</Button>
                </Dialog.Actions>
            </Dialog>
        </Portal>
    )
})

export const AppInfoMessage = observer(()  => {
    const appMessages = useContext(AppMessagesContext)

    const dismiss = () => {
        appMessages.setInfo(undefined)
    }

    return (
        <Snackbar
            visible={appMessages.info != undefined}
            onDismiss={dismiss}>
            {appMessages.info}
        </Snackbar>
    )
})

const AppMessagesContext = createContext<AppMessage>(new AppMessage())
export default AppMessagesContext