import React, {useContext, useEffect, useRef, useState} from 'react'
import {Platform, ScrollView, StyleSheet, Text, TouchableWithoutFeedback, View, ViewStyle} from "react-native"
import {OnboardingController, OnboardingState} from "../../controllers/OnboardingController"
import {observer} from "mobx-react"
import CurrentAccountContext from "../../../vizz_account/lib/CurrentAccount"
import AppControllerContext from "../../../../app/controllers/AppController"
import {reaction} from "mobx"
import {ReferralController, ReferralState} from "../../controllers/steps/ReferralController"
import {ActivityIndicator, IconButton} from "react-native-paper"
import {PrimaryButton} from "../../../../app/views/components/PrimaryButton"
import {Color, isPortrait, isTablet} from '../../../../app/lib/Appearance'
import {AppIcon} from '../../../launcher/views/AppIcon'
import {AsyncUtils} from '../../../../app/utils/AsyncUtils'
import {LavaImage} from "../../../../app/views/components/LavaImage"

type Props = {
    style?: ViewStyle
    onboardingController: OnboardingController
}

export const Referral = observer((props: Props) => {
    const currentAccount = useContext(CurrentAccountContext)
    const appController = useContext(AppControllerContext)
    const [controller] = useState<ReferralController>(() => new ReferralController(currentAccount, appController, props.onboardingController))
    const scrollViewRef = useRef<any>(null)


    useEffect(() => {
        const neededReaction = reaction(() => props.onboardingController.state, async (state) => {

            if (currentAccount.personData.referralOnboardingNeeded) {
                if (state == OnboardingState.REFERRAL) await controller.initialize()
                if (state == OnboardingState.REFERRAL) await controller.startStep()
            }

        }, {fireImmediately: true})

        const doneScrollReaction = reaction(() => controller.doneMessaging, async (state) => {
            try {
                console.log(`### scrolling to end`)
                await AsyncUtils.sleep(1000)
                scrollViewRef.current?.scrollToEnd({ animated: true })
            } catch (error) {
                // ignore any erors, this is just a best-effort feature
            }
        })

        return () => {
            neededReaction()
            doneScrollReaction()
            void controller.uninitialize()
        }
    }, [])

    return (
        <React.Fragment>
            {props.onboardingController.state == OnboardingState.REFERRAL && controller.state == ReferralState.LOADING &&
            <View style={styles.container}>
                <ActivityIndicator color={Color.primary} size={40} />
            </View>}

            {props.onboardingController.state == OnboardingState.REFERRAL && controller.state == ReferralState.ALREADY_INSTALLED &&
            <View style={styles.container}>
                <Text style={props.onboardingController.styles.messageText}>These friends already have the Lava app!</Text>

                <View style={{flexDirection: 'row', alignItems: 'center'}}>
                    <ScrollView style={{flex: 1}} contentContainerStyle={{flexGrow: 1, justifyContent: 'center'}} horizontal={true} showsHorizontalScrollIndicator={false}>
                        {controller.installedFriends.map((f) => {
                            return (
                                <View key={f.user_id} style={{alignItems: 'center', width: 150, marginHorizontal: 5}}>
                                    <View style={{
                                        width: 150,
                                        height: 246,
                                        marginHorizontal: 0,
                                        marginBottom: 10,
                                        borderRadius: 2,
                                        borderWidth: 1,
                                        borderColor: 'rgba(234, 234, 223, 0.4)',
                                    }}>
                                        <LavaImage
                                            style={{
                                                flex: 1,
                                                marginHorizontal: 0,
                                                marginVertical: 20,
                                                backgroundColor: 'transparent'
                                            }}
                                            resizeMode={'cover'}
                                            source={{uri: f.avatar_url}}/>
                                    </View>
                                    <Text style={{fontSize: 16, color: 'white', fontWeight: 'bold', marginTop: 0, overflow: 'hidden'}} numberOfLines={1}>{f.display_name}</Text>
                                    <Text style={{fontSize: 13, color: 'white', marginTop: 2}} numberOfLines={1}>@{f.name}</Text>
                                </View>
                            )
                        })}
                    </ScrollView>
                </View>

                <View style={{marginTop: 30, flexDirection: 'row'}}>
                    <PrimaryButton
                        style={{margin: 8}}
                        onPress={() => controller.promptForMessage() }
                        iconType={'FontAwesome'}
                        icon={'thumbs-up'}
                        iconSide={'right'}
                        title={'Next'} />
                </View>
            </View>}

            {props.onboardingController.state == OnboardingState.REFERRAL && controller.state == ReferralState.NOT_INSTALLED &&
            <View style={styles.container}>
                <Text style={[props.onboardingController.styles.messageText]}>These friends do not have the Lava app</Text>

                <View style={{flexDirection: 'row', alignItems: 'center'}}>
                    <ScrollView style={{flex: 1}} contentContainerStyle={{flexGrow: 1, justifyContent: 'center'}} horizontal={true} showsHorizontalScrollIndicator={false}>
                        {controller.neededFriends.map((f) => {
                            return (
                                <View key={f.user_id} style={{alignItems: 'center', width: 150, marginHorizontal: 5}}>
                                    <View style={{
                                        width: 150,
                                        height: 246,
                                        marginHorizontal: 0,
                                        marginBottom: 10,
                                        borderRadius: 2,
                                        borderWidth: 1,
                                        borderColor: 'rgba(234, 234, 223, 0.4)',
                                    }}>
                                        <LavaImage
                                            style={{
                                                flex: 1,
                                                marginHorizontal: 0,
                                                marginVertical: 20,
                                                backgroundColor: 'transparent'
                                            }}
                                            resizeMode={'cover'}
                                            source={{uri: f.avatar_url}}/>
                                    </View>
                                    <Text style={{fontSize: 16, color: 'white', fontWeight: 'bold', marginTop: 0, overflow: 'hidden'}} numberOfLines={1}>{f.display_name}</Text>
                                    <Text style={{fontSize: 13, color: 'white', marginTop: 2}} numberOfLines={1}>@{f.name}</Text>
                                </View>
                            )
                        })}
                    </ScrollView>
                </View>

                {controller.messageSendMode == 'copypaste' &&
                <View style={{marginTop: 10, height: 100, borderWidth: 3}}>
                    {controller.headline &&
                    <View style={{flex: 1, flexDirection: 'row'}}>
                        <PrimaryButton
                            style={{margin: 8}}
                            onPress={() => controller.messageRobloxAnswer('no')}
                            iconType={'Foundation'}
                            icon={'x'}
                            iconSide={'right'}
                            title={'No'} />

                        <PrimaryButton
                            style={{margin: 8}}
                            onPress={() => controller.messageRobloxAnswer('yes')}
                            iconType={'FontAwesome'}
                            icon={'thumbs-up'}
                            iconSide={'right'}
                            title={'Yes'} />
                    </View>}
                </View>}

                {controller.messageSendMode == 'preshare' &&
                <View style={{marginTop: 10, height: 100}}>
                    <View style={{flex: 1, flexDirection: 'row'}}>
                        <PrimaryButton
                            style={{margin: 8}}
                            onPress={() => controller.basicShareSheet()}
                            iconType={'Feather'}
                            icon={Platform.OS == 'ios' ? 'share' : 'share-2'}
                            iconSide={'right'}
                            title={'Share'} />
                    </View>
                </View>}

                {controller.messageSendMode == 'sharesheet' &&
                <View style={{marginTop: 10, height: 100}}>
                    {controller.showMessageButtons &&
                    <View style={{flex: 1, flexDirection: 'row'}}>
                        <PrimaryButton
                            style={{margin: 8}}
                            onPress={() => controller.promptForMessageImageShare()}
                            iconType={'FontAwesome'}
                            icon={'thumbs-up'}
                            iconSide={'right'}
                            title={'Done'} />
                    </View>}
                </View>}

                {controller.messageSendMode == 'imageshare' &&
                <View style={{marginTop: 10, height: 100}}>
                    {controller.showMessageButtons &&
                    <View style={{flex: 1, flexDirection: 'row'}}>
                        <PrimaryButton
                            style={{margin: 8}}
                            onPress={() => controller.nextStep()}
                            iconType={'FontAwesome'}
                            icon={'thumbs-up'}
                            iconSide={'right'}
                            title={'Done'} />
                    </View>}
                </View>}
            </View>}

            {props.onboardingController.state == OnboardingState.REFERRAL && (controller.state == ReferralState.TUTORIAL || controller.state == ReferralState.MESSAGE_FRIENDS) &&
            <View style={styles.container}>
                {controller.doneMessaging == 'done' &&
                <IconButton
                    style={{position: 'absolute', top: 16, right: 20, margin: 0}}
                    onPress={() => controller.nextStep()}
                    icon={'close'}
                    size={30}
                    color={'white'} />}

                {(isTablet() ||
                (isPortrait() && !controller.headline)) &&
                <Text style={props.onboardingController.styles.messageText}>These friends need Lava. Let's message each real friend.</Text>}

                <View style={{flexDirection: 'row', alignItems: 'center'}}>
                    <ScrollView style={{flex: 1}} contentContainerStyle={{flexGrow: 1, justifyContent: 'center'}} horizontal={true} showsHorizontalScrollIndicator={false}>
                        {controller.neededFriends.map((f) => {
                            return (
                                <View key={f.user_id} style={{alignItems: 'center', width: 150, marginHorizontal: 5}}>
                                    <View style={{
                                        width: 150,
                                        height: 246,
                                        marginHorizontal: 0,
                                        marginBottom: 10,
                                        borderRadius: 2,
                                        borderWidth: 1,
                                        borderColor: 'rgba(234, 234, 223, 0.4)',
                                    }}>
                                        <LavaImage
                                            style={{
                                                flex: 1,
                                                marginHorizontal: 0,
                                                marginVertical: 20,
                                                backgroundColor: 'transparent'
                                            }}
                                            resizeMode={'cover'}
                                            source={{uri: f.avatar_url}}/>
                                    </View>
                                    <Text style={{fontSize: 16, color: 'white', fontWeight: 'bold', marginTop: 0, overflow: 'hidden'}} numberOfLines={1}>{f.display_name}</Text>
                                    <Text style={{fontSize: 13, color: 'white', marginTop: 2}} numberOfLines={1}>@{f.name}</Text>
                                </View>
                            )
                        })}
                    </ScrollView>
                </View>

                {controller.message && !controller.pasteImage &&
                <View style={{width: 375, height: 80, padding: 8, marginTop: 15, backgroundColor: 'white', borderColor: 'black', borderWidth: 1}}>
                    <Text>{controller.message}</Text>
                </View>}

                {controller.pasteImage &&
                <View style={{width: 375, height: 96, padding: 8, marginTop: 15}}>
                    <TouchableWithoutFeedback onPress={() => controller.onPasteStepAfter(controller.pasteImage)}>
                        <LavaImage
                            style={{width: 375, height: 96}}
                            resizeMode={'contain'}
                            source={controller.pasteImage}
                        />
                    </TouchableWithoutFeedback>

                    <Text style={{position: 'absolute', top: 15, left: 74, fontSize: 14, width: 300}}>{controller.message}</Text>
                </View>}

                {controller.headline &&
                <View style={{marginTop: 30}}>
                    <Text style={props.onboardingController.styles.messageText}>{controller.headline}</Text>

                    <ScrollView horizontal={true} showsHorizontalScrollIndicator={false} style={{maxHeight: 80, minHeight: 80, height: 80, marginTop: -10}} ref={scrollViewRef}>
                        <View style={{flexDirection: 'row', alignItems: 'center', justifyContent: 'center', height: 80}}>
                            {controller.messengerKidsApp &&
                            <AppIcon key={controller.messengerKidsApp.appId} app={controller.messengerKidsApp} style={{width: 80, height: 80, marginEnd: 10}} onPress={() => controller.messengerKidsApp && controller.openApp(controller.messengerKidsApp)}/>}

                            {controller.whatsApp &&
                            <AppIcon key={controller.whatsApp.appId} app={controller.whatsApp} style={{width: 80, height: 80, marginEnd: 10}} onPress={() => controller.whatsApp && controller.openApp(controller.whatsApp)}/>}

                            {controller.snapchatApp &&
                            <AppIcon key={controller.snapchatApp.appId} app={controller.snapchatApp} style={{width: 80, height: 80, marginEnd: 10}} onPress={() => controller.snapchatApp && controller.openApp(controller.snapchatApp)}/>}

                            {controller.messengerApp &&
                            <AppIcon key={controller.messengerApp.appId} app={controller.messengerApp} style={{width: 80, height: 80, marginEnd: 10}} onPress={() => controller.messengerApp && controller.openApp(controller.messengerApp)}/>}

                            {controller.messagesApp &&
                            <AppIcon key={controller.messagesApp.appId} app={controller.messagesApp} style={{width: 80, height: 80, marginEnd: 10}} onPress={() => controller.messagesApp && controller.openApp(controller.messagesApp)}/>}

                            {controller.doneMessaging == 'skip' &&
                            <PrimaryButton
                                style={{marginLeft: 20, marginRight: 20}}
                                onPress={() => controller.nextStep() }
                                iconType={'FontAwesome'}
                                icon={'thumbs-up'}
                                iconSide={'right'}
                                title={'Skip'} />}

                            {controller.doneMessaging == 'done' &&
                            <PrimaryButton
                                style={{marginLeft: 20, marginRight: 20}}
                                onPress={() => controller.nextStep() }
                                iconType={'FontAwesome'}
                                icon={'thumbs-up'}
                                iconSide={'right'}
                                title={'Done'} />}
                        </View>
                    </ScrollView>
                </View>}
            </View>}
        </React.Fragment>
    )
})

const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center'
    }
})