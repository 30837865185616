import * as Font from "expo-font"
import {Platform, StyleSheet} from "react-native"
import {Color, sizeClass, SizeClass} from "./Appearance"

export class Typography {

    public static async initialize() {
        if (Platform.OS == 'web') return

        try {
            await Font.loadAsync({
                'Agrandir-Bold': require('../assets/fonts/Agrandir-Bold.otf'),
                'Agrandir-BoldItalic': require('../assets/fonts/Agrandir-BoldItalic.otf'),
                'Agrandir-Heavy': require('../assets/fonts/Agrandir-Heavy.otf'),
                'Agrandir-HeavyItalic': require('../assets/fonts/Agrandir-HeavyItalic.otf'),
                'Agrandir-Italic': require('../assets/fonts/Agrandir-Italic.otf'),
                'Agrandir-Medium': require('../assets/fonts/Agrandir-Medium.otf'),
                'Agrandir-MediumItalic': require('../assets/fonts/Agrandir-MediumItalic.otf'),
                'Agrandir-Narrow': require('../assets/fonts/Agrandir-Narrow.otf'),
                'Agrandir-Regular': require('../assets/fonts/Agrandir-Regular.otf')
            })
        } catch (error) {
            console.error('Error loading fonts', error)
        }
    }

    public static get headline1() {
        return this.fontStyleForName('headline1')
    }

    public static get headline2() {
        return this.fontStyleForName('headline2')
    }

    public static get label() {
        return this.fontStyleForName('label')
    }

    private static fontStyleForName(name: string) {
        switch (sizeClass()) {
            case SizeClass.PORTRAIT: return (phoneFontStyle as any)[name]
            case SizeClass.LANDSCAPE: return (tabletFontStyle as any)[name]
        }
    }

}

const tabletFontStyle = StyleSheet.create({
    headline1: {
        color: Color.lavaGray,
        fontFamily: 'Agrandir-Bold',
        fontSize: 20,
        lineHeight: 26,
        letterSpacing: 0.06
    },
    headline2: {
        color: Color.lavaGray,
        fontFamily: 'Agrandir-Regular',
        fontSize: 20,
        lineHeight: 26,
        letterSpacing: 0.06
    },
    label: {
        color: Color.lavaGray,
        fontFamily: 'Agrandir-Medium',
        fontSize: 12,
        lineHeight: 16,
        letterSpacing: 0.06
    },
})

const phoneFontStyle = StyleSheet.create({
    headline1: {
        color: Color.lavaGray,
        fontFamily: 'Agrandir-Bold',
        fontSize: 16,
        lineHeight: 20,
        letterSpacing: 0.06
    },
    headline2: {
        color: Color.lavaGray,
        fontFamily: 'Agrandir-Regular',
        fontSize: 14,
        lineHeight: 16,
        letterSpacing: 0.06
    },
    label: {
        color: Color.lavaGray,
        fontFamily: 'Agrandir-Bold',
        fontSize: 11,
        lineHeight: 13,
        letterSpacing: 0.06
    }
})
