import React, {useState} from "react"
import {View, Image, StyleSheet} from "react-native"
import {isPortrait, isTablet} from "../../../../../app/lib/Appearance"

const CardBackground = () => {
    const [layout, setLayout] = useState({x: 0, y: 0, width: 0, height: 0})

    return (
        <View style={{flex: 1, borderRadius: 10, overflow: 'hidden', zIndex: -10, ...StyleSheet.absoluteFillObject}} onLayout={(e) => setLayout(e.nativeEvent.layout)}>
            {isTablet() && <Image style={{width: layout.width, height: layout.height}} resizeMode={'cover'} source={require('../../../assets/bg-concept-tablet.png')}/>}
            {isPortrait() && <Image style={{width: layout.width, height: layout.height}} resizeMode={'cover'} source={require('../../../assets/bg-concept-phone.png')}/>}
        </View>
    )
}

export default CardBackground