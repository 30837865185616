import React, {useContext, useEffect, useState} from 'react'
import {Dimensions, ScrollView, StyleSheet, View} from "react-native"
import {Color} from "../../lib/Appearance"
import {observer} from "mobx-react"
import CurrentAccountContext from "../../../modules/vizz_account/lib/CurrentAccount"
import {RootDrawerNavigatorList} from "../../lib/interfaces/root-tab-navigator"
import {StackNavigationProp} from "@react-navigation/stack"
import {RouteProp} from '@react-navigation/native'
import {
    ActivityIndicator,
    Button,
    Headline,
    HelperText,
    Menu,
    Subheading,
    Switch,
    Text,
    TextInput,
    ToggleButton
} from 'react-native-paper'
import {VizzModel} from "../../../modules/vizz_maker/models/VizzModel"
import Vizz from "../../../modules/vizz_maker/views/Vizz"
import {MaterialIcons} from '@expo/vector-icons'
import FixedAspectView from "../components/FixedAspectView"
import VizzArticleView from "../components/VizzArticleView"
import {VizzContentType, VizzState} from "../../lib/models/VizzSource"
import {VizzContentTypeUtil, VizzStateUtil} from "../../utils/EnumUtils"

type EditVizzScreenRouteProp = RouteProp<RootDrawerNavigatorList, 'EditVizz'>

type EditVizzScreenNavigationProp = StackNavigationProp<
    RootDrawerNavigatorList,
    'EditVizz'>

type Props = {
    route: EditVizzScreenRouteProp,
    navigation: EditVizzScreenNavigationProp
}

enum PreviewStyle {
    player = 'player',
    article = 'article'
}

const EditVizzScreen = observer((props: Props) => {

    const currentAccount = useContext(CurrentAccountContext)
    const [vizzKey, setVizzKey] = useState<string|undefined>(props.route.params?.vizzKey)
    const navigation = props.navigation
    const [value, onChangeText] = useState<string>('')
    const [searchKeywords, setSearchKeywords] = useState<string>('')
    const [loading, setLoading] = useState<boolean>(false)
    const [responseMessage, setResponseMessage] = useState<string>('')
    const [vizzContentType, setVizzContentType] = useState<VizzContentType>(VizzContentType.wow)
    const [perSlideAudio, setPerSlideAudio] = useState<boolean>(false)
    const [vizzContentTypeMenuActive, setVizzContentTypeMenuActive] = useState<boolean>(false)
    const [vizzState, setVizzState] = useState<VizzState>(VizzState.draft)
    const [vizzStateMenuActive, setVizzStateMenuActive] = useState<boolean>(false)

    const [previewVizz, setPreviewVizz] = useState<VizzModel | undefined>(undefined)
    const [previewHelperText, setPreviewHelperText] = useState<string>('')
    const [previewStyle, setPreviewStyle] = useState<string>(PreviewStyle.player)

    useEffect(() => {
        void refreshSource()
    }, [])

    useEffect(() => {
        void refreshSource()
    }, [vizzKey])

    const refreshSource = async (onlyUpdatePreview?: boolean) => {
        if (!vizzKey) return

        setLoading(true)

        const vizz = await currentAccount.api.get(`vizz_maker.vizz_path(${vizzKey})`, { raw: true })

        if (!currentAccount.isLoggedIn) {
            navigation.navigate('LogIn')
            return
        }

        setLoading(false)
        if (vizz) {
            if (!onlyUpdatePreview) {
                onChangeText(vizz.source_markdown)
                setVizzState(vizz.state)
                setVizzContentType(vizz.content_type)
                setPerSlideAudio(vizz.use_per_slide_audio)
                setSearchKeywords(vizz.search_keywords ?? '')
            } else {
                setResponseMessage("Preview updated without saving markdown.")
            }

            if (vizz.processed_json) {
                setPreviewVizz(undefined)
                setPreviewVizz(vizz.processed_json)
                setPreviewHelperText('')
            } else {
                setPreviewVizz(undefined)
            }
        }
    }

    const save = async () => {
        setLoading(true)
        setResponseMessage("")
        try {
            if (vizzKey) {
                const data = await currentAccount.api.patch(`vizz_maker.vizz_path(${vizzKey})`, {
                    content: value,
                    state: vizzState,
                    content_type: vizzContentType,
                    use_per_slide_audio: perSlideAudio,
                    search_keywords: searchKeywords
                })
                setResponseMessage(data.message)
                await refreshSource()
            } else {
                const data = await currentAccount.api.post('vizz_maker.vizzes_path', {
                    content: value,
                    state: vizzState,
                    content_type: vizzContentType,
                    search_keywords: searchKeywords
                })
                setResponseMessage(data.message)
                setVizzKey(data.key)
                navigation.navigate("EditVizz", { vizzKey: data.key })
            }
        } catch(err) {
            console.log(`Error ${err}`)
            setLoading(false)
            setResponseMessage("Error saving vizz")
        }
    }

    const onTogglePerSlideAudioSwitch = () => setPerSlideAudio(!perSlideAudio)

    const vizzPreviewView = (vizz: VizzModel) => {
        switch (previewStyle) {
            case PreviewStyle.player: {
                return (
                    <FixedAspectView aspectRatio={3/4}>
                        <Vizz vizz={vizz} />
                    </FixedAspectView>
                )
            }
            case PreviewStyle.article: {
                return (
                    <ScrollView style={{backgroundColor: 'white', height: 1}}>
                        <VizzArticleView vizz={vizz}/>
                    </ScrollView>
                )
            }
        }
    }

    return (
        <View style={styles.container}>
            <View style={{flex: 1, margin: 16}}>
                <View style={{flexDirection: 'row', alignItems: 'center', marginBottom: 16}}>
                    <Headline style={{marginEnd: 16}}>
                        {vizzKey ? 'Edit Vizz' : 'Create Vizz'}
                    </Headline>

                    <View style={{flex:1, flexGrow: 1}}/>

                    {vizzContentType ?
                    <Menu
                        visible={vizzContentTypeMenuActive}
                        onDismiss={() => {setVizzContentTypeMenuActive(false)}}
                        anchor={<Button
                            style={{marginEnd: 16}}
                            mode={'outlined'}
                            compact={true}
                            uppercase={false}
                            icon={() => <MaterialIcons name="arrow-drop-down" size={24} color={Color.primary} />}
                            onPress={() => {setVizzContentTypeMenuActive(true)}}>
                            {VizzContentTypeUtil.title(vizzContentType)}
                        </Button>}>
                        {Object.values(VizzContentType).map((t) => {
                            return (
                                <Menu.Item
                                    key={t}
                                    title={VizzContentTypeUtil.title(t as VizzContentType)}
                                    icon={ () => {return t as VizzContentType == vizzContentType ?
                                        <MaterialIcons name="radio-button-checked" size={24} color={Color.primary} /> :
                                        <MaterialIcons name="radio-button-unchecked" size={24} />}}
                                    onPress={() => {
                                        setVizzContentType(t as VizzContentType)
                                        setVizzContentTypeMenuActive(false)
                                    }}
                                />
                            )
                        })}
                    </Menu>
                    : null}

                    {vizzState ?
                    <Menu
                        visible={vizzStateMenuActive}
                        onDismiss={() => {setVizzStateMenuActive(false)}}
                        anchor={<Button
                            mode={'outlined'}
                            compact={true}
                            uppercase={false}
                            icon={() => <MaterialIcons name="arrow-drop-down" size={24} color={Color.primary} />}
                            onPress={() => {setVizzStateMenuActive(true)}}>
                            {VizzStateUtil.title(vizzState)}
                        </Button>}>
                        {Object.values(VizzState).map((t) => {
                            return (
                                <Menu.Item
                                    key={t}
                                    title={VizzStateUtil.title(t as VizzState)}
                                    icon={ () => {return t as VizzState == vizzState ?
                                        <MaterialIcons name="radio-button-checked" size={24} color={Color.primary} /> :
                                        <MaterialIcons name="radio-button-unchecked" size={24} />}}
                                    onPress={() => {
                                        setVizzState(t as VizzState)
                                        setVizzStateMenuActive(false)
                                    }}
                                />
                            )
                        })}
                    </Menu>
                    : null}

                </View>
                <View style={{flex: 1}}>
                    <TextInput
                        mode={'outlined'}
                        label={'Vizz Markdown'}
                        style={styles.textInput}
                        multiline
                        numberOfLines={Dimensions.get('window').height / 36}
                        editable={!loading}
                        disabled={loading}
                        onChangeText={text => onChangeText(text)}
                        value={value}
                    />
                    <TextInput
                        mode={'outlined'}
                        label={'Search Keywords'}
                        style={styles.searchKeywordsInput}
                        multiline
                        numberOfLines={1}
                        editable={!loading}
                        disabled={loading}
                        onChangeText={text => setSearchKeywords(text)}
                        value={searchKeywords}
                    />
                </View>
                <View style={styles.buttonContainer}>
                    <Button
                        color={Color.primary}
                        disabled={loading}
                        mode={'contained'}
                        onPress={save}>
                        Save
                    </Button>
                    {loading ? <ActivityIndicator style={{marginStart: 16}} color={Color.secondary} /> : null}
                    <HelperText type={'info'} visible={true}>{responseMessage}</HelperText>
                    <Switch style={{margin: 5}} value={perSlideAudio} onValueChange={onTogglePerSlideAudioSwitch} />
                    <Text>
                        Use Per Slide Audio
                    </Text>
                </View>
            </View>
            <View style={{flex:1, margin: 16}}>
                <View style={{flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginBottom: 16}}>
                    <ToggleButton.Row onValueChange={(value) => setPreviewStyle} value={previewStyle}>
                        <ToggleButton
                            icon={() => <MaterialIcons name="ondemand-video" size={24} />}
                            value={PreviewStyle.player}
                            onPress={() => {setPreviewStyle(PreviewStyle.player)}}
                        />
                        <ToggleButton
                            icon={() => <MaterialIcons name="article" size={24} />}
                            value={PreviewStyle.article}
                            onPress={() => {setPreviewStyle(PreviewStyle.article)}}
                        />
                    </ToggleButton.Row>
                </View>
                <View style={styles.vizzPreview}>
                    {previewVizz ? vizzPreviewView(previewVizz) :
                        <View style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}>
                            <Subheading style={{color: 'white'}}>Vizz Preview Unavailable</Subheading>
                        </View>
                    }
                </View>

                <View style={styles.buttonContainer}>
                    <Button
                        disabled={loading}
                        mode={'contained'}
                        onPress={() => void refreshSource(true)}>
                        Refresh Preview
                    </Button>
                    {previewHelperText.length > 0 ? <ActivityIndicator style={{marginStart: 16, marginEnd: 8}} color={Color.secondary} /> : null}
                    <HelperText type={'info'} visible={true}>{previewHelperText}</HelperText>
                </View>
            </View>
        </View>
    )
})

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
    },
    header: {
        fontSize: 36
    },
    textInput: {
        flex: 1,
        marginBottom: 16
    },
    searchKeywordsInput: {
        flex: 0,
        justifyContent: 'flex-end',
        marginBottom: 10
    },
    vizzPreview: {
        flex: 1,
        backgroundColor: 'black',
        justifyContent: 'center',
        marginBottom: 16
    },
    buttonContainer: {
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center'
    }
})

export default EditVizzScreen
