import React from "react"
import {observer} from "mobx-react"
import {Text, TouchableOpacity, View, ViewStyle} from "react-native"
import useTheme from "../../../lava-components/components/hooks/useTheme"
import {robloxGameStyles} from "./SpreadDetailConstants"
import {Image} from "expo-image"
import {isLandscape} from "../../../../app/lib/Appearance"
import Title from "../../../lava-components/components/atoms/Title/Title"

type Props = React.PropsWithChildren<{
    title: string
    onTitlePress?: () => void
    collapsed?: boolean
    verified?: boolean
    showCollapseIcon?: boolean
    style?: ViewStyle
}>

export const SpreadDetailContentSection = observer((props: Props) => {

    const theme = useTheme()

    return (
        <View
            style={{
                marginLeft: 0,
                marginRight: props.collapsed ? theme.spacing.md : 0,
                borderRadius: props.collapsed ? 2 : 0,
                borderColor: props.collapsed ? theme.colors.primarySand40 : 'transparent',
                borderWidth: 0.5,
                overflow: 'hidden',
                height: props.collapsed ? 50 : undefined,
                ...props.style
        }}>
            <View style={{
                height: 240,
                overflow: 'hidden',
                marginStart: 0,
                marginEnd: 0,
                marginTop: 0,
                marginBottom: 15,
            }}>
                <View style={{flexDirection: 'row', alignItems: 'center'}}>
                    <Title numberOfLines={1} variation="title3" style={{marginBottom: theme.spacing.xl, color: theme.colors.primarySand}}>
                        {props.title}
                    </Title>
                </View>
                <View style={{flex: 1}}>
                    {props.children}
                </View>
            </View>
        </View>
    )
})