import React, {useContext, useEffect, useRef, useState} from 'react'
import {FriendWidgetController} from "../../controllers/FriendWidgetController"
import {observer} from "mobx-react"
import {Keyboard, ScrollView, StyleSheet, Text, View} from "react-native"
import {TextInput} from "react-native-paper"
import {PrimaryButton} from "../../../../app/views/components/PrimaryButton"
import CurrentAccountContext from '../../../vizz_account/lib/CurrentAccount'
import {SignupController} from '../../controllers/friend_widget/SignupController'
import {SpeechUtils} from '../../../../app/utils/AVUtils'

type Props = {
    friendWidgetController: FriendWidgetController
}

export const Signup = observer((props: Props) => {

    const currentAccount = useContext(CurrentAccountContext)
    const [speech] = useState(() => new SpeechUtils(currentAccount, true))
    const [controller] = useState<SignupController>(() => new SignupController(currentAccount, props.friendWidgetController))
    const [nickname, setNickname] = useState(currentAccount.profile?.nickname ?? '')
    const [error, setError] = useState('')
    const [avatarImageUri, setAvatarImageUri] = useState<string|undefined>()
    const isMountedRef = useRef(false)

    useEffect(() => {
        isMountedRef.current = true

        controller.initialize()
        void speech.speak("Pick a Lava name your friends will see and tap save.")

        return () => {
            isMountedRef.current = false
        }
    }, [])

    const submitEnabled = () => {
        return nickname.length > 0
    }

    const createAccount = async() => {
        Keyboard.dismiss()

        let message = await controller.createAccount(nickname, avatarImageUri)

        if (!isMountedRef.current) return
        setError(message)
    }

    const changeText = (text: string) => {
        setError('')
        const cleanText = text.replace(/[^A-Za-z]/g, '')
        setNickname(cleanText)
    }

    return (
        <View style={styles.container}>
            <ScrollView style={{flex: 1}} keyboardShouldPersistTaps={'handled'}>
                <View style={{flexDirection: 'column'}}>
                    <View style={{marginTop: 10, paddingLeft: 10}}>
                        <TextInput
                            style={styles.inputField}
                            mode={'flat'}
                            autoCapitalize={'none'}
                            label="Lava Name"
                            value={nickname}
                            onChangeText={text => changeText(text)}
                        />
                        <Text style={styles.inputHint}>Lava name your friends will see (only letters)</Text>
                    </View>

                </View>

                {/*<View style={{marginTop: 24}}>*/}
                {/*    <Text style={styles.avatarLabel}>Select Avatar</Text>*/}
                {/*    <View style={{marginTop: 16, flexDirection: 'row'}}>*/}
                {/*        <View style={{*/}
                {/*            width: 160,*/}
                {/*            height: 160,*/}
                {/*            borderRadius: 6,*/}
                {/*            justifyContent: 'center',*/}
                {/*            alignItems: 'center',*/}
                {/*            overflow: 'hidden',*/}
                {/*            backgroundColor: 'rgba(255, 255, 255, 0.2)'*/}
                {/*        }}>*/}
                {/*            {avatarImageUri ?*/}
                {/*                <Image*/}
                {/*                    style={{flex: 1, width: '100%', height: '100%'}}*/}
                {/*                    resizeMode={'cover'}*/}
                {/*                    source={{uri: avatarImageUri}}/>*/}
                {/*                :*/}
                {/*                <MaterialCommunityIcons name="account" size={90} color="white" />*/}
                {/*            }*/}
                {/*        </View>*/}

                {/*        <View style={{marginStart: 16}}>*/}
                {/*            <Button style={{borderColor: 'white'}} icon={'camera'} mode={'outlined'} onPress={async() => { await setAvatarImage(takePhotoUrl())}}>*/}
                {/*                Take Photo*/}
                {/*            </Button>*/}
                {/*            <Button style={{marginTop: 16, borderColor: 'white'}} icon={'image'} mode={'outlined'} onPress={async() => { await setAvatarImage(pickImageUrl())}}>*/}
                {/*                Camera Roll*/}
                {/*            </Button>*/}
                {/*        </View>*/}
                {/*    </View>*/}
                {/*</View>*/}

                <View style={{marginTop: 10, flexDirection: 'column', paddingLeft: 10}}>
                    <PrimaryButton
                        disabled={!submitEnabled()}
                        title={'Save'}
                        onPress={createAccount}/>
                    <View style={{flexGrow: 1}}>
                        <Text style={{color: 'orangered', marginTop: 30}}>{error}</Text>
                    </View>
                </View>
            </ScrollView>
        </View>
    )
})

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: 'transparent'
    },
    headerLabel: {
        color: 'white',
        fontFamily: 'Agrandir-Medium',
        fontSize: 36
    },
    subheaderLabel: {
        color: 'white',
        fontFamily: 'Agrandir-Regular',
        fontSize: 16
    },
    inputField: {
        width: 320
    },
    inputHint: {
        marginTop: 8,
        color: 'rgba(200, 200, 200, 1)',
        fontFamily: 'Agrandir-Regular',
        fontSize: 12
    },
    avatarLabel: {
        color: 'white',
        fontFamily: 'Agrandir-Medium',
        fontSize: 16
    }
})
