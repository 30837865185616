import {Call} from "../../modules/social/models/Call"

export interface CallNotification {
    id: string
    call: Call
    displayUsernames: string[]
    timestamp: Date
}

export const isCallNotification = (object: any): object is CallNotification => {
    return 'call' in object
}