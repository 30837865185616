import React, {useEffect, useState} from 'react'
import {Image, StyleSheet, Text, View, ViewStyle} from "react-native"
import {observer} from "mobx-react"
import {Typography} from "../../../../app/lib/Typography"
import {PrimaryButton} from '../../../../app/views/components/PrimaryButton'
import {InboxWidgetController} from '../../controllers/InboxWidgetController'
import {Button} from 'react-native-paper'

type Props = {
    controller: InboxWidgetController
    style?: ViewStyle
    title?: string
}

export const AddSchoolMessage = observer((props: Props) => {
    const [disabled, setDisabled] = useState<boolean>(false)

    useEffect(() => {
        return () => {
        }
    }, [])

    return (
        <View style={[styles.container, props.style]}>
            <Text style={{color: "white"}}>
                {props.title}
            </Text>

            <View style={{flexDirection: 'row-reverse'}}>
                <Button
                    style={{marginHorizontal: 8, marginTop: 8}}
                    mode={'contained'}
                    color={'green'}
                    disabled={disabled}
                    dark={true}
                    compact={true}
                    onPress={() => { setDisabled(true); props.controller.addSchool() }}>
                    View My School
                </Button>
            </View>
        </View>

    )
})

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
})
