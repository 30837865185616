import React, {useContext, useEffect} from 'react'
import {observer} from "mobx-react"
import {StyleSheet, View, ViewStyle} from "react-native"
import {Portal} from "react-native-paper"
import {FriendWidgetState} from "../controllers/FriendWidgetController"
import {Color, isLandscape} from "../../../app/lib/Appearance"
import {Signup} from "./friend_widget/Signup"
import CurrentAccountContext from "../../vizz_account/lib/CurrentAccount"
import FriendGrid from './friend_widget/FriendGrid'
import {PollingDisabled} from "./friend_widget/PollingDisabled"
import {InvitationReminder} from './friend_widget/InvitationReminder'
import AppControllerContext from '../../../app/controllers/AppController'
import {Widget} from '../../start/views/home/Widget'
import {SelectFriends, SelectFriendsAction} from './friend_widget/SelectFriends'
import {useSafeAreaInsets} from "react-native-safe-area-context"
import HomeController from '../../start/controllers/HomeController'
import {NotificationDisabled} from './friend_widget/NotificationDisabled'
import {MicrophoneDisabled} from './friend_widget/MicrophoneDisabled'
import {Loading} from "../../../app/views/components/Loading"

type Props = {
    style?: ViewStyle
    homeController: HomeController
    disabled?: boolean
    mode: 'widget'|'sheet'
}

export const FriendWidget = observer((props: Props) => {
    const controller = props.homeController.friendWidgetController
    const safeAreaInsets = useSafeAreaInsets()

    useEffect(() => {
        void controller.initialize()

        return () => {
            controller.uninitialize()
        }
    }, [])

    const renderContent = () => {
        switch (controller.state) {
            case FriendWidgetState.LOADING: return null
            case FriendWidgetState.NOTIFICATIONS_DISABLED: return <NotificationDisabled friendWidgetController={controller} style={props.mode == 'sheet' ? { justifyContent: 'flex-start' } : {}} />
            case FriendWidgetState.MICROPHONE_DISABLED: return <MicrophoneDisabled friendWidgetController={controller} style={props.mode == 'sheet' ? { justifyContent: 'flex-start' } : {}} />
            case FriendWidgetState.SETUP_PROFILE: return <Signup friendWidgetController={controller} />
            case FriendWidgetState.POLLING_DISABLED: return <PollingDisabled friendWidgetController={controller} style={props.mode == 'sheet' ? { justifyContent: 'flex-start' } : {}} />
            case FriendWidgetState.FRIENDS: return <FriendGrid friendWidgetController={controller} mode={props.mode} />
        }
    }

    const renderSelectFriends = () => {
        let actions: SelectFriendsAction[]

        actions = [
            {
                title: 'Call Group',
                icon: 'video',
                color: Color.faceTimeGreen.toString(),
                onAction: (profiles) => controller.openChannelDetails(profiles.map((p) => p.person_key), 'call'),
                minCount: 2,
            },
            {
                title: 'Text Group',
                icon: 'chat',
                color: Color.messagesRed.toString(),
                onAction: (profiles) => controller.openChannelDetails(profiles.map((p) => p.person_key), 'chat'),
                minCount: 2,
            }
        ]

        return  <SelectFriends
            visible={controller.groupCallVisible}
            onDismiss={() => controller.hideCreateGroup()}
            actions={actions}
            friendWidgetController={controller} />
    }

    return (
        <Widget type={props.mode == 'sheet' ? 'sheet' : 'compact'} style={[props.style, {flexGrow: 1, backgroundColor: "#1B1F23", marginBottom: -1*(safeAreaInsets.bottom+4)}]} loading={controller.state == FriendWidgetState.LOADING} disabled={props.disabled}>
            <View style={{flex: 1, flexDirection: isLandscape() ? 'row' : 'column'}}>
                {renderContent()}
            </View>

            <Portal>
                {controller.invitationReminderVisible && <InvitationReminder friendWidgetController={controller} />}
                {renderSelectFriends()}
                {controller.isLoadingProfileDetail && <Loading/>}
            </Portal>
        </Widget>
    )
})
