import React, {useState} from 'react'
import {StyleProp, StyleSheet, View, ViewStyle} from "react-native"
import {observer} from "mobx-react"
import {FeedWidgetController} from '../../controllers/FeedWidgetController'
import {ProfileModel} from '../../models/ProfileModel'
import {ReferralStory} from './ReferralStory'
import VideoModel from '../../../browse/models/VideoModel'
import StoryModel from '../../models/StoryModel'
import {NewSchoolPersonStory} from './NewSchoolPersonStory'
import BookModel from '../../../browse/models/BookModel'
import useTheme from '../../../lava-components/components/hooks/useTheme'
import {Video} from '../../../browse/views/Video'
import {GenericStory} from './GenericStory'
import NewVideoStory from "../../../lava-components/components/molecules/NewVideoStory/NewVideoStory"
import {isPortrait} from "../../../../app/lib/Appearance"
import {GamePlayedStory} from "../../../lava-components/components/molecules/GamePlayedStory/GamePlayedStory"
import {ProfileStatus} from "../../models/ProfileStatus"

type Props = {
    style?: StyleProp<ViewStyle>
    type: 'game_played' | 'video' | 'referral_invitation' | 'referral_reminder' | 'referral_completion' | 'new_school_person' | 'generic'

    controller?: FeedWidgetController
    robloxFriend?: ProfileModel

    story?: StoryModel
    position?: number
    onVideoTapped?: (video: VideoModel, position: number) => void
    onBookTapped?: (book: BookModel) => void
    horizontal: boolean
}

export const Story = observer((props: Props) => {
    const theme = useTheme()
    const [layout, setLayout] = useState({x: 0, y: 0, width: 0, height: 0})

    return (
        <View
            onLayout={(e) => setLayout(e.nativeEvent.layout)}
            style={[styles.container, props.style, {
                marginTop: 0,
                marginEnd: props.horizontal ? 7 : 10,
                marginStart: props.horizontal ? 0 : 10,
                marginBottom: props.horizontal ? 0 : 7
            }]}>
            {props.type == 'game_played' && props.controller && props.robloxFriend && props.story?.title && props.story.source?.story_type == 'Book' && props.onBookTapped &&
                <GamePlayedStory
                    minWidth={props.horizontal ? undefined : layout.width}
                    maxWidth={props.horizontal ? undefined : layout.width}
                    title={props.story.title}
                    friends={[
                        {
                            username: props.robloxFriend.first_name!,
                            avatarUrl: props.robloxFriend.avatar_image_url,
                            status: props.robloxFriend.status == ProfileStatus.ONLINE ? 'online' : 'offline',
                            variant: 'primaryLarge'
                        }
                    ]}
                    games={[
                        {
                            title: props.story.source.title,
                            imageUrl: props.story.source.image_url ?? '',
                            onTapped: () => props.onBookTapped && props.story?.source && props.story.source.story_type == 'Book' && props.onBookTapped(props.story.source)
                        }
                    ]}
                    timeAgo={''}
                    onFriendsTapped={() => {
                        void props.controller?.homeController.friendWidgetController?.openChannelDetails([props.robloxFriend?.person_key ?? ''])
                    }}
                />}

            {props.type == 'video' && props.story && props.position != undefined && props.onVideoTapped && props.story.source?.story_type == 'Video' &&
                <NewVideoStory
                    width={isPortrait() ? layout.width : undefined}
                    book={{
                        title: 'Lava',
                        imageUrl: 'https://res.cloudinary.com/explanation/image/upload/w_200,h_200/v1688759619/logos/lava-logo_dqi5zt.png'
                    }}
                    video={{
                        title: props.story.source.title,
                        imageUrl: props.story.source.thumbnail_url ?? ''
                    }}
                    title={props.story.title ?? 'Video'}
                    onTapped={() => {
                        if (props.story && props.story.source?.story_type == 'Video' && props.position != undefined && props.onVideoTapped)
                            props.onVideoTapped(props.story.source, props.position)
                    }}
                />

            }

            {props.type == 'referral_invitation' && props.story?.title && props.story?.body && props.story.source?.story_type == 'Concept' && props.story.properties.event == "referral_invitation" && props.controller &&
                <ReferralStory title={props.story.title} body={props.story.body} controller={props.controller}
                               pickButton={true} countPhrase={'first'}/>}

            {props.type == 'referral_reminder' && props.story?.title && props.story?.body && props.story.source?.story_type == 'Referral' &&
                <ReferralStory title={props.story.title} body={props.story.body} controller={props.controller}
                               pickButton={true} countPhrase={'additional'}/>}

            {props.type == 'referral_completion' && props.story?.title && props.story?.body && props.story.source?.story_type == 'Referral' && props.story.properties.event == "referral_completion" && props.controller &&
                <ReferralStory title={props.story.title} body={props.story.body} controller={props.controller}
                               pickButton={props.story.properties.call_to_action == 'onboarding_robux'}
                               countPhrase={'additional'}/>}

            {props.type == 'new_school_person' && props.story?.title && props.story?.body && props.story.source?.story_type == 'School' &&
                <NewSchoolPersonStory title={props.story.title} controller={props.controller} body={props.story.body}
                                      newPersonProfile={props.story.properties.new_user.profile}
                                      newPersonRobloxProfile={props.story.properties.new_user.roblox_profile}/>}

            {props.type == 'generic' && props.story?.title && props.story?.body && props.story.source?.story_type == 'Profile' &&
                <GenericStory title={props.story.title} body={props.story.body} controller={props.controller}/>}

        </View>
    )
})

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
})
