import {StyleSheet} from "react-native"

export const EXPANDED_WIDTH = 544
export const COLLAPSED_WIDTH = 216

export enum RobloxGameDetailSidePanelState {
    COLLAPSED,
    EXPANDED
}

export const robloxGameStyles = StyleSheet.create({
    sectionHeaderText: {
        fontFamily: 'Agrandir-Regular',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: 16,
        lineHeight: 21,
        letterSpacing: 0.06,
        color: '#EAEADF'
    }
})