import {observer} from 'mobx-react'
import React, {useContext} from 'react'
import {View} from 'react-native'
import CurrentAccountContext from "../../../modules/vizz_account/lib/CurrentAccount"
import {LegacyFeedScreenNavigationProp} from "./LegacyFeed"
import {Button, Title} from 'react-native-paper'

type Props = {
    navigation: LegacyFeedScreenNavigationProp
}

const LogoutScreen = observer(({navigation}: Props) => {
    const currentAccount = useContext(CurrentAccountContext)

    const logout = () => {
        console.log(`about to log out`)
        void currentAccount.logout()
    }

    return (
        <View style={{flex: 1, height: 64, alignItems: 'center',  justifyContent: 'center'}}>
            <Title style={{marginBottom: 16}}>User is {currentAccount.isLoggedIn ? '' : 'NOT '}currently logged in</Title>
            <Button
                mode={'contained'}
                onPress={() => logout()}
            >
                Log Out
            </Button>
        </View>
    );
  })

export default LogoutScreen
