import {Animated, Easing, LayoutAnimation, LayoutAnimationConfig} from "react-native"
import CompositeAnimation = Animated.CompositeAnimation

export class AnimationUtils {

    public static configureNext(config: LayoutAnimationConfig = LayoutAnimation.Presets.easeInEaseOut) {
        LayoutAnimation.configureNext(config)
    }

    public static animateProgress(duration: number, onUpdate: (increment: number) => void) {
        const updateInterval = 0.1
        const progressIncrement = updateInterval / (duration / 1000)

        return setInterval(() => {
            onUpdate(progressIncrement)
        }, updateInterval * 1000)
    }

}

export class PulseAnimator {
    public scale = new Animated.Value(1)
    private animation?: CompositeAnimation

    public start() {
        const animation = Animated.loop(
            Animated.sequence([
                Animated.timing(this.scale, {
                    toValue: 1.15,
                    duration: 500,
                    easing: Easing.out(Easing.quad),
                    useNativeDriver: true
                }),
                Animated.timing(this.scale, {
                    toValue: 1,
                    duration: 500,
                    easing: Easing.in(Easing.quad),
                    useNativeDriver: true
                })
            ])
        )
        animation.start()
        this.animation = animation
    }

    public stop() {
        this.animation?.stop()
        this.scale.setValue(1)
    }
}