import React from "react";
import {View} from "react-native"
import {Helmet} from 'react-helmet'
import {VizzModel, VizzHelpers} from "../../../modules/vizz_maker/models/VizzModel"
import AppConstants from "../../lib/AppConstants"

type Props = {
    vizz: VizzModel
}

export const MetaTags = (props: Props) => {
    let vizz = props.vizz;

    return (
        <View>
            <Helmet meta={[
                {
                    property: 'description',
                    content: VizzHelpers.fullScript(vizz)
                },
                {
                    property: 'twitter:card',
                    content: 'summary_large_image',
                },
                {
                    property: 'twitter:site',
                    content: '@askvizz',
                },
                {
                    property: 'twitter:title',
                    content: vizz.title,
                },
                {
                    property: 'twitter:description',
                    content: VizzHelpers.fullScript(vizz),
                },
                {
                    property: 'twitter:image',
                    content: vizz.seoImageUrl ?? vizz.coverImageUrl,
                },
                ]}
            />
            <Helmet meta={[
                {
                    property: 'og:url',
                    content: AppConstants.baseUrl() + '/vizz/' + vizz.id,
                },
                {
                    property: 'og:type',
                    content: 'article',
                },
                {
                    property: 'og:title',
                    content: vizz.title,
                },
                {
                    property: 'og:description',
                    content: VizzHelpers.fullScript(vizz),
                },
                {
                    property: 'og:image',
                    content: vizz.seoImageUrl ?? vizz.coverImageUrl,
                },
                ]}
            />
            <Helmet>
                <script type="application/ld+json">{`
                    {
                        "@context": "https://schema.org",
                        "@type": "Article",
                        "headline": "${vizz.title}",
                        "articleBody": "${VizzHelpers.fullScript(vizz)}",
                        "image": [
                            "${vizz.seoImageUrl ?? vizz.coverImageUrl}"
                        ],
                        "datePublished": "2021-04-22T08:00:00+08:00",
                        "dateModified": "2021-04-22T09:20:00+08:00",
                        "author": {
                            "@type": "Organization",
                            "name": "Vizz",
                            "logo": {
                                "@type": "ImageObject",
                                "url": "${AppConstants.baseUrl() + require('../../assets/app-logo.png')}"
                            }
                        },
                        "publisher": {
                            "@type": "Organization",
                            "name": "Vizz",
                            "logo": {
                                "@type": "ImageObject",
                                "url": "${AppConstants.baseUrl() + require('../../assets/app-logo.png')}"
                            }
                        }
                    }
                `}</script>
            </Helmet>
            {vizz.title.toLowerCase().indexOf("how do i") > -1 && <Helmet>
                <script type="application/ld+json">{`
                    {
                        "@context": "https://schema.org/",
                        "@type": "HowTo",
                        "name": "${vizz.title}",
                        "description": "${VizzHelpers.fullScript(vizz)}",
                        "image": "${vizz.seoImageUrl ?? vizz.coverImageUrl}",
                        "totalTime": "PT${Math.round(vizz.slides.map(s => s.duration).reduce((a, b) => a + b, 0))}S",
                        "step": [${vizz.slides.map(slide =>
                            `{
                                "@type": "HowToStep",
                                "text": "` + slide.media.map(m => m.text).join(" ") + `",
                                "image": "` + slide.media[0].assetUrl + `"
                            }`
                        ).join(",")}]
                    }
                `}</script>
            </Helmet>}
        </View>
    )
}
