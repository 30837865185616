import React from 'react'
import {ScrollView} from "react-native";
import {Paragraph} from "react-native-paper";
import {RouteProp} from "@react-navigation/native";
import {RootStackNavigatorList} from "../../lib/interfaces/root-stack-navigator";

type JsonDebugScreenRouteProp = RouteProp<RootStackNavigatorList, 'JsonDebug'>

type Props = {
    route: JsonDebugScreenRouteProp
}

const JsonDebugScreen = (props: Props) => {

    return (
        <ScrollView style={{flex: 1}}>
            <Paragraph>
                {JSON.stringify(props.route.params.jsonString, null, 4)}
            </Paragraph>
        </ScrollView>
    )
}

export default JsonDebugScreen
