import React, {useContext, useEffect, useLayoutEffect, useRef, useState} from 'react'
import {Divider, TouchableRipple} from 'react-native-paper'
import {Alert, Animated, Easing, StyleProp, StyleSheet, Text, View, ViewStyle} from "react-native"
import {FriendWidgetController} from '../../controllers/FriendWidgetController'
import {ProfileModel} from '../../models/ProfileModel'
import {Color} from "../../../../app/lib/Appearance"
import CurrentAccountContext from '../../../vizz_account/lib/CurrentAccount'
import {FriendAvatarSize} from "./FriendAvatar"
import CallButton from '../../assets/btn-video-call.svg'
import ChatButton from '../../assets/btn-chat.svg'
import {AnimationUtils} from "../../../../app/utils/AnimationUtils"
import {observer} from "mobx-react"
import FriendDetails from './FriendDetails'

type Props = {
    profile: ProfileModel,
    friendWidgetController?: FriendWidgetController,
    style?: StyleProp<ViewStyle>,
    hideBottomDivider?: boolean,
    isExpanded?: boolean,
    onDemoCall?: () => void,
}

const LegacyFriend = observer((props: Props) => {
    const currentAccount = useContext(CurrentAccountContext)
    const [pulsingOn, setPulsingOn] = useState<boolean>(true)
    const pulsingScale = useRef(new Animated.Value(1))
    const pulsingAnimation = useRef(Animated.loop(
        Animated.sequence([
            Animated.timing(pulsingScale.current, {
                toValue: 1.1,
                duration: 300,
                easing: Easing.out(Easing.ease),
                useNativeDriver: true
            }),
            Animated.timing(pulsingScale.current, {
                toValue: 1.0,
                duration: 700,
                easing: Easing.in(Easing.ease),
                useNativeDriver: true
            })
        ])
    ))

    useEffect(() => {
        if (props.onDemoCall && pulsingOn) {
            pulsingAnimation.current.start()
        } else {
            pulsingAnimation.current.stop()
        }

    }, [pulsingOn])


    if (props.friendWidgetController) {
        useLayoutEffect(() => {
            AnimationUtils.configureNext()
        }, [props.profile.status])
    }

    const isExpanded = () => {
        if (props.isExpanded) return props.isExpanded
    }

    const startCall = () => {
        if (props.onDemoCall) {
            setPulsingOn(false)
            props.onDemoCall()
        }
        if (!props.friendWidgetController) return
        currentAccount.analytics.logEvent('social-call', 'tapped', `2 friends`, {
            profiles: [currentAccount.profile, currentAccount.profile],
            trigger: "friendlist",
            number_of_friends: 2
        })
    }

    const startMessageChat = () => {
        if (!props.friendWidgetController) return
        currentAccount.analytics.logEvent('social-chat', 'tapped', `2 friends`, {
            profiles: [props.profile, currentAccount.profile],
            trigger: "friendlist",
            number_of_friends: 2
        })
    }

    const renderActions = () => {
        if (!isExpanded()) return null

        return (
            <View style={{flexDirection: 'row', alignItems: 'center', marginTop: 4, marginStart: FriendAvatarSize()}}>

                <Animated.View style={{transform: [{scaleX: pulsingScale.current}, {scaleY: pulsingScale.current}]}} onTouchStart={() => {}}>
                    <TouchableRipple  onPress={startCall}>
                        <View style={{alignItems: 'center'}}>
                            <CallButton width={42} height={42}/>
                            <Text style={styles.actionLabel}>Call</Text>
                        </View>
                    </TouchableRipple>
                </Animated.View>

                <View style={{flexGrow: 2, maxWidth: 26}}/>
                <TouchableRipple onPress={startMessageChat} style={{opacity: props.onDemoCall ? 0.5 : 1.0}}>
                    <View style={{alignItems: 'center'}}>
                        <ChatButton width={42} height={42}/>
                        <Text style={styles.actionLabel}>Chat</Text>
                    </View>
                </TouchableRipple>
            </View>
        )
    }

    return (
        <TouchableRipple>
            <View style={[props.style, {flex: 1, marginBottom: 14}]}>
                <FriendDetails profile={props.profile} />
                {renderActions()}

                {!props.hideBottomDivider && <Divider style={{flex: 1, backgroundColor: Color.lavaGray, opacity: 0.2, marginTop: 14}}/>}
            </View>
        </TouchableRipple>
    )
})

const styles = StyleSheet.create({
    actionLabel: {
        marginTop: 5,
        textAlign: 'center',
        fontSize: 10,
        fontFamily: 'Agrandir-Regular',
        color: Color.lavaGray
    }
})

export default LegacyFriend
