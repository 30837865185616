import {action, IReactionDisposer, makeObservable, observable, reaction} from "mobx"
import {AppController} from "../../../../app/controllers/AppController"
import {NativeState} from "../../../../app/services/NativeStateService"
import {ProfileModel} from "../../../social/models/ProfileModel"
import {CurrentAccount} from "../../../vizz_account/lib/CurrentAccount"
import {OnboardingController} from "../OnboardingController"
import {ROBLOX_SECURITY_COOKIE_KEY} from "./profile/RobloxCookieWatcher"
import {FeatureFlagModel} from "../../../vizz_account/models/FeatureFlagModel"
import SentryService from "../../../../app/services/SentryService"
import {Alert} from "react-native"
import {AccountService} from "../../../../app/services/AccountService"
import {HttpError} from "../../../../app/services/RailsAPIService"
import AppConstants from "../../../../app/lib/AppConstants"

export enum ProfileState {
    LOADING,
    FIRST_NAME,
    ROBLOX_ASK,
    ROBLOX_LOGIN,
    LAVA_NAME,
    SYNCING_ROBLOX_DATA,
    WAITING_FOR_FOREGROUND,
    APPROVE_PROFILE,
    OFFLINE_MESSAGE,
    SUBMITTING
}

export class ProfileController {
    private debug: boolean = false  // don't set this to true in production

    private currentAccount: CurrentAccount
    public appController: AppController
    public onboardingController: OnboardingController

    private cleanupAppStateReaction?: IReactionDisposer
    private backToLavaTimeout?: any
    private robloxUserId?: any
    private account: AccountService

    @observable state: ProfileState = ProfileState.LOADING;         @action public setState(state: ProfileState) { this.state = state }
    @observable firstName?: string;                                 @action public setFirstName(name?: string) { this.firstName = name }
    @observable nickname?: string;                                  @action public setNickname(name?: string) { this.nickname = name }
    @observable username?: string;                                  @action public setUsername(name?: string) { this.username = name }
    @observable errorMessage?: string;                              @action public setErrorMessage(message?: string) { this.errorMessage = message }
    @observable errorMessageDetails?: string;                       @action public setErrorMessageDetails(message?: string) { this.errorMessageDetails = message }
    @observable loadingRobloxInfo: boolean = false;                 @action public setLoadingRobloxInfo(loading: boolean) { this.loadingRobloxInfo = loading }
    @observable areRobloxCookiesValid?: boolean;                    @action public setAreRobloxCookiesValid(areRobloxCookiesValid: boolean) { this.areRobloxCookiesValid = areRobloxCookiesValid }
    @observable offlineMessage: boolean = true;                     @action public setOfflineMessage(offline: boolean) { this.offlineMessage = offline }

    public static LAVA_BOT_USERNAME = 'Lava_Bot'

    constructor(currentAccount: CurrentAccount, appController: AppController, onboardingController: OnboardingController) {
        this.consoleDebug(`new()`)

        this.currentAccount = currentAccount
        this.appController = appController
        this.onboardingController = onboardingController
        this.account = new AccountService()

        makeObservable(this)
    }

    public async initialize() {
        this.consoleDebug(`initialize()`)
        if (this.state != ProfileState.LOADING) return

        this.onboardingController.speech.preloadPhrase('profile-first', `Ready? Let's get you connected. What is your "first" name?`)
        this.onboardingController.speech.preloadPhrase('roblox-login-start', `Now, lets connect your Roblox account to Lava. Tap "Connect to Roblox".`)
        this.onboardingController.speech.preloadPhrase('profile-lava-name', `Type a Lava name that your friends will see.`)
        this.onboardingController.speech.preloadPhrase('roblox-login-form', `Enter your Roblox username and password, then tap \"Log In\"`)
        this.onboardingController.speech.preloadPhrase('roblox-login-waiting', `On this Roblox screen, tap the black button: "Confirm Login". Then switch back to the Lava app.`)
        this.onboardingController.speech.preloadPhrase('roblox-login-reminder', `After connecting, go back to Lava.`)
        this.onboardingController.speech.preloadPhrase('roblox-login-foreground', `Go back to Lava to finish setup.`)
        this.onboardingController.speech.preloadPhrase('roblox-login-slow', `It's connecting. Sometimes Roblox is slow, so this can take a minute.`)
        this.onboardingController.speech.preloadPhrase('roblox-login-approve', `This is how your friends will see you when you're "online". Make any changes or, if it looks good, tap the thumbs up.`)
        this.onboardingController.speech.preloadPhrase('roblox-login-offline', `When your "offline", this is how your friends will see you. You can change this at any time.`) // Make any changes to your offline message or turn it off.
        this.onboardingController.speech.preloadPhrase('roblox-login-approve-mention-roblox', `Would you like to make any changes? Also, you can connect your profile to Roblox.`)

        this.account.initialize(this.currentAccount)

        this.cleanupAppStateReaction = reaction(() => this.appController.nativeState.state, async(state) => {
            if (state == NativeState.FOREGROUND) {
                if (this.state == ProfileState.WAITING_FOR_FOREGROUND) {
                    if (this.backToLavaTimeout) { clearTimeout(this.backToLavaTimeout); this.backToLavaTimeout = undefined }
                    await this.beginApproveRobloxLavaName()
                }
                if (this.state == ProfileState.SYNCING_ROBLOX_DATA) {
                    await this.onboardingController.speech.speakPreloadedPhrase('roblox-login-slow')
                }
            }
        })

        const myProfile = await this.refreshMyProfile()
        this.onboardingController.homeController.setRevealPhase(0)

        if (myProfile && this.firstName && this.nickname)
            this.setState(ProfileState.APPROVE_PROFILE)
        else
            this.setState(ProfileState.FIRST_NAME)

        if (myProfile) {
            void this.checkCookiesOnServer()
        }
    }

    public uninitialize() {
        this.consoleDebug(`uninitialize()`)
        if (this.cleanupAppStateReaction) this.cleanupAppStateReaction()

        this.onboardingController.speech.unloadPreloadedPhrase('profile-first')
        this.onboardingController.speech.unloadPreloadedPhrase('roblox-login-start')
        this.onboardingController.speech.unloadPreloadedPhrase('profile-lava-name')
        this.onboardingController.speech.unloadPreloadedPhrase('roblox-login-form')
        this.onboardingController.speech.unloadPreloadedPhrase('roblox-login-waiting')
        this.onboardingController.speech.unloadPreloadedPhrase('roblox-login-reminder')
        this.onboardingController.speech.unloadPreloadedPhrase('roblox-login-foreground')
        this.onboardingController.speech.unloadPreloadedPhrase('roblox-login-slow')
        this.onboardingController.speech.unloadPreloadedPhrase('roblox-login-approve')
        this.onboardingController.speech.unloadPreloadedPhrase('roblox-login-approve-mention-roblox')
        this.onboardingController.speech.unloadPreloadedPhrase('roblox-login-offline')

        if (this.backToLavaTimeout) { clearTimeout(this.backToLavaTimeout); this.backToLavaTimeout = undefined }
    }


    // Public methods

    public async startStep() {
        this.consoleDebug(`startStep()`)

        if (this.state == ProfileState.FIRST_NAME) {
            this.currentAccount.analytics.logEvent('profile-onboarding','started')
            await this.onboardingController.speech.speakPreloadedPhrase('profile-first', () => {
                this.onboardingController.speech.unloadPreloadedPhrase('profile-first')
            })
        } else if (this.state == ProfileState.APPROVE_PROFILE) {
            await this.beginApproveRobloxLavaName(false)
        }
    }

    public onChangeFirstName(text: string) {
        const cleanText = text.replace(/[^A-Za-z]/g, '')
        this.setFirstName(cleanText.charAt(0).toUpperCase() + cleanText.slice(1)) // capitalize first letter
    }

    public onChangeNickname(text: string) {
        const cleanText = text.replace(/[^A-Za-z0-9_~]/g, '')
        this.setNickname(cleanText.charAt(0).toUpperCase() + cleanText.slice(1)) // capitalize first letter
    }

    public async onFirstNameSubmitted() {
        this.consoleDebug(`onFirstNameSubmitted()`)

        if (!this.firstName || this.firstName.length < 2) return

        this.onboardingController.speech.pause()
        this.setErrorMessage(undefined)

        if (this.currentAccount.isAppReviewer)
            void this.fallbackToLavaName()
        else
            void this.beginConnectingToRoblox()

        this.currentAccount.analytics.logEvent('profile-onboarding','first-name', this.firstName, {
            first_name: this.firstName,
            '$set': { first_name: this.firstName }
        })
    }

    public beginRobloxOption() {
        this.currentAccount.analytics.logEvent('profile-onboarding','roblox-ask')
        this.setState(ProfileState.ROBLOX_ASK)
        void this.onboardingController.speech.speak(`You need a username to use Lava. Do you play Roblox and want to use your Roblox username?`)
    }

    public async robloxAnswer(answer: string) {
        this.onboardingController.speech.pause()

        this.currentAccount.analytics.logEvent('profile-onboarding', 'roblox', answer, { use_roblox: answer })

        if (answer == 'yes') {
            this.currentAccount.analytics.logEvent('profile-onboarding','roblox-answered', 'yes')
            this.setErrorMessage(undefined)
            await this.beginConnectingToRoblox()
        }
        if (answer == 'no') {
            this.currentAccount.analytics.logEvent('profile-onboarding','roblox-answered', 'no')
            void this.fallbackToLavaName()
        }
    }

    public async beginConnectingToRoblox() {
        this.setState(ProfileState.ROBLOX_LOGIN)
        await this.onboardingController.speech.speakPreloadedPhrase('roblox-login-start')
    }

    public async fallbackToLavaName() {
        try {
            let response = await this.currentAccount.api.post('social.profile_create_path', {
                first_name: this.firstName,
            }) as ProfileModel

            this.setNickname(response.nickname)

            await this.appController.message.onProfileUpdate(response)
            this.currentAccount.setProfile(response)

            this.currentAccount.analytics.logEvent('profile-onboarding','nickname', this.nickname, {
                name: `${this.firstName}_${this.nickname}`,
                nickname: this.nickname,
            })

            this.setErrorMessage(undefined)
            this.setState(ProfileState.LAVA_NAME)
            await this.onboardingController.speech.speakPreloadedPhrase('profile-lava-name')

        } catch (error) {
            if (!(error instanceof Error)) return //TODO: Also fall back to Onboarding network errors screen

            SentryService.captureError(error)
            this.setErrorMessage("There was an error saving your name.")
            this.setErrorMessageDetails(error.message)
            this.setState(ProfileState.FIRST_NAME)
        }
    }

    public async onRobloxLoginResult(cookies: any) {
        this.consoleDebug(`onRobloxLoginResult(${cookies})`)

        if (this.nickname == undefined || this.nickname.length == 0) return

        this.onboardingController.speech.pause()


        this.setLoadingRobloxInfo(true)
        this.setErrorMessage(undefined)
        this.setErrorMessageDetails(undefined)

        if (this.appController.nativeState.state == NativeState.FOREGROUND) void this.onboardingController.speech.speakPreloadedPhrase('roblox-login-slow')
        this.setState(ProfileState.SYNCING_ROBLOX_DATA)

        if (this.appController.nativeState.state == NativeState.BACKGROUND) {
            await this.onboardingController.speech.speakPreloadedPhrase('roblox-login-foreground', () => {
                // it's important we wait to queue until after it's done speaking it the first time
                this.backToLavaTimeout = setTimeout(async() => {
                    if (this.appController.nativeState.state != NativeState.BACKGROUND) return
                    await this.onboardingController.speech.speakPreloadedPhrase('roblox-login-foreground')
                    //await NotificationService.sendLocalNotificationNow('Finish setup', 'Tap here to go back to Lava')
                }, 4000)
            })
            //await NotificationService.sendLocalNotificationNow('Finish setup', 'Tap here to go back to Lava')
        }

        this.consoleDebug('Setting cookie on person properties')
        const authCookie = cookies[ROBLOX_SECURITY_COOKIE_KEY]

        // Get robloxUserId
        let robloxResponse, robloxResult, robloxError
        try { //TODO: We should not use fetch, we should make railsAPI work for these
            robloxResponse = await fetch(`https://users.roblox.com/v1/users/authenticated`, {
                headers: {
                    'Cookie': `${authCookie.name}=${authCookie.value}`
                }
            })
            robloxResult = await robloxResponse.json()
        } catch (error) {
            this.setLoadingRobloxInfo(false)
            robloxError = `${(error as any).toString()} ${JSON.stringify(error as any)}}`
            this.setErrorMessage("There was an error. Please Try Again.")
            this.setErrorMessageDetails(robloxError)
            this.currentAccount.analytics.logEvent('profile-onboarding', 'roblox-error', 'query-user', { message: robloxError })
            return
        }

        // Roblox user existence was verified, and the ID is in robloxResult.id
        this.robloxUserId = robloxResult.id

        try {
            if (await this.account.registerAndLogin(robloxResult.id, cookies)) {
                this.currentAccount.personData.hasRobloxAccount = true

                this.currentAccount.analytics.logEvent('profile-onboarding', 'roblox-registered', robloxResult.id, {
                    roblox_user_id: robloxResult.id
                })

            } else {
                if (await this.account.login(robloxResult.id, cookies)) {
                    const myProfile = await this.refreshMyProfile()
                    this.currentAccount.personData.hasRobloxAccount = true

                    this.currentAccount.analytics.logEvent('profile-onboarding', 'roblox-login', `${this.firstName}_${this.nickname} found`, {
                        person: this.currentAccount.personData.person,
                        firstName: this.firstName,
                        nickname: this.nickname,
                    })

                    if (this.appController.nativeState.state != NativeState.FOREGROUND) {
                        this.setState(ProfileState.WAITING_FOR_FOREGROUND)
                    } else {
                        await this.beginApproveRobloxLavaName()
                    }
                    return
                }
                return // login never returns false, just throws an exception
            }
        } catch(error) {
            if (!(error instanceof Error)) return //TODO: Also fall back to Onboarding network errors screen

            SentryService.captureError(error)
            this.setErrorMessage("There was an error. Please Try Again.")
            this.setErrorMessageDetails(error.message)
            this.currentAccount.analytics.logEvent('profile-onboarding', 'roblox-error', 'login-and-register', { message: error.message })
            return
        }

        let userBasicResponse, userBasicResult
        // get all basic info
        try {  //TODO: We should not use fetch, we should make railsAPI work for these
            // https://users.roblox.com/v1/users/3850921803
            userBasicResponse = await fetch(`https://users.roblox.com/v1/users/${this.robloxUserId}`, {
                headers: {
                    'Cookie': `${authCookie.name}=${authCookie.value}`
                }
            })
            userBasicResult = await userBasicResponse.json()
        } catch (e) {
            robloxError = `${(e as any).toString()} ${JSON.stringify(e as any)}`
            this.setErrorMessage("There was an error. Please Try Again.")
            this.setErrorMessageDetails(robloxError)
            this.currentAccount.analytics.logEvent('profile-onboarding', 'roblox-error', 'fetching-user', { message: robloxError })
        }

        // get friends
        // https://friends.roblox.com/v1/users/3850892291/friends
        let friendsResponse, friendsResult
        try {  //TODO: We should not use fetch, we should make railsAPI work for these
            friendsResponse = await fetch(`https://friends.roblox.com/v1/users/${this.robloxUserId}/friends?userSort=StatusFrequents`, {
                headers: {
                    'Cookie': `${authCookie.name}=${authCookie.value}`
                }
            })
            friendsResult = (await friendsResponse.json()).data
        } catch (e) {
            robloxError = `${(e as any).toString()} ${JSON.stringify(e as any)}`
            this.setErrorMessage("There was an error. Please Try Again.")
            this.setErrorMessageDetails(robloxError)
            this.currentAccount.analytics.logEvent('profile-onboarding', 'roblox-error', 'fetching-friends', { message: robloxError })
        }

        let isCookieWorking = "false"
        try {
            robloxResponse = await fetch(`https://users.roblox.com/v1/users/authenticated`, {
                headers: {
                    'Cookie': `${authCookie.name}=${authCookie.value}`
                }
            })
            isCookieWorking = "true"
        } catch (e) {
            robloxError = `${(e as any).toString()} ${JSON.stringify(e as any)}}`
        }

        this.currentAccount.analytics.logEvent("profile-onboarding", "front-end-cookie-check", isCookieWorking, { robloxError: robloxError })

        try {
            await this.currentAccount.api.post('roblox_profiles_path', {
                roblox_user_id: this.robloxUserId,
                data: {
                    roblox_user: userBasicResult,
                    roblox_friends: friendsResult,
                    is_cookie_working: isCookieWorking
                }
            })
        } catch(error) {
            if (!(error instanceof Error)) return //TODO: Also fall back to Onboarding network errors screen

            this.setLoadingRobloxInfo(false)
            this.setErrorMessage("There was an error. Please Try Again.")
            this.setErrorMessageDetails(error.message)
            this.currentAccount.analytics.logEvent('profile-onboarding', 'roblox-error', 'saving-profiles', { message: error.message })
        }

        let response
        let message
        let instruction
        let max = 3
        for (let retryLoopCount = 1; retryLoopCount <= max; retryLoopCount++) {
            try {
                this.autoformatNickname(retryLoopCount)

                let response = await this.currentAccount.api.post('social.profile_create_path', {
                    first_name: this.firstName,
                    nickname: this.nickname,
                }) as ProfileModel


                await this.appController.message.onProfileUpdate(response)
                this.currentAccount.setProfile(response)

                this.currentAccount.analytics.logEvent('profile-onboarding', 'nickname', this.nickname, {
                    name: `${this.firstName}_${this.nickname}`,
                    nickname: this.nickname,
                    '$set': {nickname: this.nickname, name: `${this.firstName}_${this.nickname}`}
                })

                this.setErrorMessage(undefined)
                this.setErrorMessageDetails(undefined)

                if (this.appController.nativeState.state != NativeState.FOREGROUND) {
                    this.setState(ProfileState.WAITING_FOR_FOREGROUND)
                } else {
                    await this.beginApproveRobloxLavaName()
                }
                return

            } catch (error) {
                if (!(error instanceof Error)) return //TODO: Also fall back to Onboarding network errors screen
                const isConflict = (error instanceof HttpError) && error.status == "conflict"

                if (!isConflict || retryLoopCount == max) {
                    SentryService.captureError(error)
                    this.setLoadingRobloxInfo(false)
                    this.setErrorMessage(error.message)
                    this.currentAccount.analytics.logEvent('profile-onboarding', 'roblox-error', error.message, { message: error.message })
                    return
                }
            }
        }
    }

    private autoformatNickname(attempt: number) {
        let nicknames = [ this.nickname, this.username ]

        for (let i = 0; i < nicknames.length; i++) {
            let name = nicknames[i]

            if (name && this.firstName && name.toLowerCase().startsWith(this.firstName.toLowerCase())) {
                this.setNickname(name.substring(this.firstName.length)) // strip the first name off the beginning
            }
            if (this.nickname) break
        }

        if (!this.nickname) this.setNickname(this.firstName)
        this.setNickname(this.nickname!.substring(0,20))
        this.setNickname(this.nickname!.charAt(0).toUpperCase() + this.nickname!.slice(1)) // capitalize first letter

        if (attempt > 1) {
            this.setNickname(this.nickname!.substring(0,17))
            this.setNickname(this.nickname + Math.round(Math.random() * 1000).toString())
        }
    }

    private async checkCookiesOnServer() {
        this.consoleDebug(`checkCookiesOnServer()`)

        let areRobloxCookiesValid
        try {
            const response = await this.currentAccount.api.get("social.are_cookies_valid_path") as any
            areRobloxCookiesValid = response.are_cookies_valid
        } catch (error) {
            areRobloxCookiesValid = false
            SentryService.captureError(error) //TODO: Show Onboarding network errors screen. Also, cleanup response codes from this GET
        }

        this.setAreRobloxCookiesValid(areRobloxCookiesValid)
    }

    private async beginApproveRobloxLavaName(skipIfActivated: boolean = true) {
        await this.checkCookiesOnServer()

        if (skipIfActivated && this.currentAccount.person.activated_at) {
            this.skipRestOfOnboarding()
            return
        }

        if (this.currentAccount.personData.hasRobloxAccount && this.areRobloxCookiesValid)
            await this.onboardingController.speech.speakPreloadedPhrase('roblox-login-approve')
        else {
            await this.onboardingController.speech.speakPreloadedPhrase('roblox-login-approve-mention-roblox', () => {
                this.onboardingController.speech.unloadPreloadedPhrase('roblox-login-approve-mention-roblox')
            })
        }

        this.setState(ProfileState.APPROVE_PROFILE)
    }

    private skipRestOfOnboarding() {
        OnboardingController.setStep('done', this.currentAccount, this.onboardingController)
    }

    public async onApprovedRobloxLavaName() {
        if (!this.firstName || this.firstName.length < 2) return
        if (!this.nickname || this.nickname.length < 2) return

        this.onboardingController.speech.pause()

        if (this.firstName != this.currentAccount.profile?.first_name || this.nickname != this.currentAccount.profile?.nickname) {
            let response, message, instruction
            try {
                response = await this.currentAccount.api.patch('social.profile_update_path', {
                    first_name: this.firstName,
                    nickname: this.nickname
                })
                message = response?.message
                instruction = response?.instruction
            } catch(error) {
                if (!(error instanceof Error)) return //TODO: Also fall back to Onboarding network errors screen

                this.setErrorMessage("There was an error. Please Try Again.")
                this.setErrorMessageDetails(error.message)
                this.currentAccount.analytics.logEvent('profile-onboarding', 'updating-error', undefined, { message: error.message })
            }

            if (response?.success) {
                if (response.profile) {
                    await this.appController.message.onProfileUpdate(response.profile)
                    this.currentAccount.setProfile(response.profile)
                }

                this.currentAccount.analytics.logEvent('profile-onboarding','saved', `${this.firstName}_${this.nickname}`, {
                    name: `${this.firstName}_${this.nickname}`,
                    first_name: this.firstName,
                    nickname: this.nickname,
                    '$set': { first_name: this.firstName, nickname: this.nickname, name: `${this.firstName}_${this.nickname}` }
                })
            } else {
                this.setErrorMessage(message)
                void this.onboardingController.speech.speak(message)
                this.currentAccount.analytics.logEvent('profile-onboarding', 'error', message, { message: message })
            }
        }

        if (this.currentAccount.profile?.roblox_profile && this.currentAccount.hasFeature(FeatureFlagModel.OFFLINE)) {
            await this.onboardingController.speech.speakPreloadedPhrase('roblox-login-offline')

            try {
                const robloxProfileResponse = await fetch(`${AppConstants.baseUrl()}/roblox_profiles/${this.currentAccount.person.roblox_profile?.user_id || this.robloxUserId || this.currentAccount.profile?.roblox_profile?.user_id}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'authorization': `Basic bGF2YTpiYTkwNzYzY2RmMTZiMjU0MjcyMWM4YzU4NjI5YjkwZDJjOTNhNGMzYTJiZThkZGFlOWI5MWUxYWIyMDYxMzRmOTAwNTY2ZjYxMDY3NzJiOTI1OWFhODk5MmUxYTcwOWU5NmQ3MmIwNjUwNGI1Mjk5YTQ5YzgwNjQ2OGM1NGU1OA==`
                    }
                }) as any

                const robloxProfileResponseJson = await robloxProfileResponse.json()
                this.setOfflineMessage(robloxProfileResponseJson.data.lava_offline_enabled)
            } catch (error) {
                this.consoleDebug(`onApprovedRobloxLavaName() error: ${error}`)
                SentryService.captureError(error)
            }

            this.setState(ProfileState.OFFLINE_MESSAGE)
        } else this.nextStep()
    }

    public async onApproveOfflineMessage() {
        this.setState(ProfileState.SUBMITTING)

        this.onboardingController.speech.pause()
        this.currentAccount.analytics.logEvent('profile-onboarding','offline-message', this.offlineMessage ? 'on' : 'off', {
            offline_message: this.offlineMessage ? 'on' : 'off'
        })

        try{
            const offlineMessageUpdateResponse = await fetch(`${AppConstants.baseUrl()}/roblox_profiles/${this.currentAccount.person.roblox_profile?.user_id || this.robloxUserId || this.currentAccount.profile?.roblox_profile?.user_id }`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': `Basic bGF2YTpiYTkwNzYzY2RmMTZiMjU0MjcyMWM4YzU4NjI5YjkwZDJjOTNhNGMzYTJiZThkZGFlOWI5MWUxYWIyMDYxMzRmOTAwNTY2ZjYxMDY3NzJiOTI1OWFhODk5MmUxYTcwOWU5NmQ3MmIwNjUwNGI1Mjk5YTQ5YzgwNjQ2OGM1NGU1OA==`
                },
                body: JSON.stringify({
                    lava_offline_enabled: this.offlineMessage
                })
            }) as any
        } catch (error) {
            this.consoleDebug(`onApproveOfflineMessage() error: ${error}`)
            SentryService.captureError(error)
        }

        this.nextStep()
    }

    public nextStep() {
        this.consoleDebug(`nextStep()`)

        this.onboardingController.speech.pause()
        if (this.firstName) this.currentAccount.personData.firstName = this.firstName

        this.currentAccount.personData.lastInvitationReminderTimestamp = (new Date()).toString()
        this.currentAccount.analytics.logEvent('profile-onboarding', 'finished', `${this.firstName}_${this.nickname}`, {
            first_name: this.firstName,
            nickname: this.nickname,
            name: `${this.firstName}_${this.nickname}`
        })

        this.onboardingController.nextStep()
    }

    public async resetApp() {
        Alert.alert('Log Out', 'Are you sure you want to log out so you can log back in?',
            [
                {text: 'Yes, Log Out', onPress: async () => {
                        await this.account.logoutAndReset(this.appController )
                        Alert.alert('Reset Data',
                            'The app could not be restarted. Kill the app and restart now.',
                            [
                                {text: 'Okay', style: 'cancel'}
                            ]
                        )
                    }},
                {text: 'No, Cancel', style: 'cancel'}
            ]
        )
    }


    // Private helper methods

    private async refreshMyProfile() {
        try {
            const myProfile = await this.currentAccount.api.get("social.profile_show_path") as ProfileModel
            if (myProfile) {
                await this.appController.message.onProfileUpdate(myProfile)
                this.currentAccount.setProfile(myProfile)
                this.setFirstName(myProfile?.first_name!)
                this.setNickname(myProfile?.nickname)
            }

            return myProfile
        } catch (error) {
            if (!(error instanceof Error)) return undefined //TODO: Also fall back to Onboarding network errors screen
            const is404 = (error instanceof HttpError) && error.status == "not_found"

            if (!is404) SentryService.captureError(error) //TODO: Show Onboarding network errors screen
        }

        return undefined
    }


    // Private instance utility methods

    private consoleDebug(method: string, force: boolean = false) {
        if (this.debug || force) console.log(`${this.constructor.name}: ${method}  state = ${this.state}`)
    }
}
