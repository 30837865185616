import {action, makeObservable, observable} from "mobx"
import {CurrentAccount} from "../../../modules/vizz_account/lib/CurrentAccount"
import {AppController} from "../../../app/controllers/AppController"
import {GameBookModel} from "../../browse/models/GameBookModel"

export enum ModeratorMenuState {
    LOADING,
    LOADED
}

export class ModeratorMenuController {
    private debug: boolean = false  // don't set this to true in production

    private currentAccount: CurrentAccount
    public appController: AppController

    @observable state: ModeratorMenuState = ModeratorMenuState.LOADING; @action private setState(state: ModeratorMenuState) { this.state = state }
    @observable gameBooks?: GameBookModel[];                            @action public setGameBooks(gameBooks: GameBookModel[]) { this.gameBooks = gameBooks }


    constructor(currentAccount: CurrentAccount, appController: AppController) {
        this.consoleDebug(`new()`)

        this.currentAccount = currentAccount
        this.appController = appController

        makeObservable(this)
    }

    public async initialize() {
        this.consoleDebug(`initialize()`)

        const gameBooks = await this.currentAccount.api.get('browse.game_books_path') as GameBookModel[]
        this.setGameBooks(gameBooks)

        this.setState(ModeratorMenuState.LOADED)
    }

    public uninitialize() {
        this.consoleDebug(`uninitialize()`)
    }


    // Public methods



    // Private instance utility methods

    private consoleDebug(method: string, force: boolean = false) {
        if (this.debug || force) console.log(`${this.constructor.name}: ${method}  state = ${this.state}`)
    }
}
