import BookModel from "../../../browse/models/BookModel"
import {action, computed, makeObservable, observable} from "mobx"

export default class Interest {
    @observable isSelected: boolean = false
    public order: number = 0
    book: BookModel

    constructor(channel: BookModel) {
        this.book = channel
        makeObservable(this)
    }

    @action
    setSelected(selected: boolean, order: number) {
        this.isSelected = selected
        this.order = order
    }
}