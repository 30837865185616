export interface PresenceModel {
    id: number
    person_id: number
    person_key: string

    roblox_user_id?: number
    roblox_profile_id?: number
    roblox_profile_avatar_headshot_url?: string
    roblox_profile_username?: string
    roblox_profile_displayname?: string

    profile_first_name?: string
    profile_nickname: string
    profile_username: string

    pretty_username: string

    lava_installed: boolean
    broadcasted_status: 'online'|'offline'
    broadcasted_status_updated_at: string
    last_online_msg?: string

    // status_pushed_at: string

    robloxing: boolean
    playing: boolean
    watching: boolean
    talking: boolean
    activity_updated_at?: string

    call_id?: number

    concept_id?: number
    concept_name?: string
    concept_media_url?: string
    roblox_profile_game_instance_key?: string
    roblox_profile_roblox_place_id?: number

    video_id?: number
    video_title?: string
    video_thumbnail_url?: string
    video_concept_id?: number

    message_id?: number
    message_text?: string
    message_read?: boolean
    message_sent_at?: string
}

export const comparePresenceLists = (l1: PresenceModel[], l2: PresenceModel[]): boolean => {
    const list1 = l1 as any[]
    const list2 = l2 as any[]

    if (list1.length !== list2.length) {
        return false
    }

    for (let i = 0; i < list1.length; i++) {
        const item1 = list1[i]
        const item2 = list2[i]

        for (const key in item1) {
            if (item1[key] !== item2[key]) {
                return false
            }
        }

        for (const key in item2) {
            if (item2[key] !== item1[key]) {
                return false
            }
        }
    }

    return true
}