import React, {useContext, useLayoutEffect, useState} from 'react'
import {ProfileModel} from "../../social/models/ProfileModel"
import {observer} from "mobx-react"
import {ScrollView, TouchableOpacity, View} from "react-native"
import useTheme from "../../lava-components/components/hooks/useTheme"
import Title from "../../lava-components/components/atoms/Title/Title"
import CurrentAccountContext from "../../vizz_account/lib/CurrentAccount"
import {SimpleLineIcons} from '@expo/vector-icons'
import {AnimationUtils} from "../../../app/utils/AnimationUtils"
import {isTablet} from "../../../app/lib/Appearance"
import Avatar from "../../lava-components/components/atoms/Avatar/Avatar"

export const FRIEND_PICKER_VERTICAL_WIDTH = 62

type Props = {
    friends: ProfileModel[]
    onTappedFriend?: (friend: ProfileModel) => void
}

export const FriendPickerVertical = observer((props: Props) => {

    const currentAccount = useContext(CurrentAccountContext)
    const theme = useTheme()
    const [expanded, setExpanded] = useState(false)

    const avatarSize = isTablet() ? 52 : 40
    const initialNumberOfOptions = isTablet() ? 3 : 1

    useLayoutEffect(() => {
        AnimationUtils.configureNext()
    }, [expanded])

    const friends = () => {
        return expanded ? props.friends : props.friends.slice(0, initialNumberOfOptions)
    }

    const ExpandButton = () => {
        return (
            <TouchableOpacity onPress={() => { setExpanded(true); currentAccount.analytics.logEvent('video-sharing', 'expand', `${props.friends.length} friends`) } }>
                <View style={{justifyContent: 'center', alignItems: 'center'}}>
                    <View
                        style={{
                            backgroundColor: theme.colors.secondaryContainers,
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: avatarSize,
                            height: avatarSize,
                            borderRadius: avatarSize / 2,
                            borderWidth: 1,
                            borderColor: theme.colors.sand,
                            marginBottom: theme.spacing.md,
                            shadowColor: "black",
                            shadowOffset: {width: 0, height: 10,},
                            shadowOpacity: 1.0,
                            shadowRadius: 15.19
                        }}
                    >
                        <SimpleLineIcons name="options" size={24} color="white"/>
                    </View>
                </View>
            </TouchableOpacity>
        )
    }

    const Content = () => {
        return (
            <View>
                {currentAccount.personData.sharingOnboardingNeeded && <Title variation={'title2'} numberOfLines={1}
                                                                              style={{
                                                                                  shadowColor: "black",
                                                                                  shadowOffset: {width: 0, height: 10,},
                                                                                  shadowOpacity: 1.0,
                                                                                  shadowRadius: 15.19,
                                                                                  elevation: 23
                                                                              }}>Send:</Title>}

                {friends().map((friend) => {
                    return (
                        <TouchableOpacity key={`friend-${friend.id}`} onPress={() => {
                            if (props.onTappedFriend) props.onTappedFriend(friend)
                        }}>
                            <View style={{
                                marginVertical: theme.spacing.xxl,
                                alignItems: 'center',
                                shadowColor: "black",
                                shadowOffset: {width: 0, height: 10,},
                                shadowOpacity: 1.0,
                                shadowRadius: 15.19,
                                elevation: 23
                            }}>
                                <Avatar
                                    style={{
                                        marginBottom: theme.spacing.md,
                                        shadowColor: "black",
                                        shadowOffset: {width: 0, height: 10,},
                                        shadowOpacity: 1.0,
                                        shadowRadius: 15.19,
                                        borderWidth: 1,
                                        borderColor: theme.colors.sand
                                    }}
                                    variant={isTablet() ? 'primaryLarge' : 'primarySmall'}
                                    username={friend.pretty_username}
                                    avatarUrl={friend.avatar_image_url}
                                    onPress={() => {
                                        if (props.onTappedFriend) props.onTappedFriend(friend)
                                    }}
                                />
                                <Title variation={'subtitle1'} numberOfLines={1}>{friend.first_name}</Title>
                            </View>
                        </TouchableOpacity>
                    )
                })}

                {!expanded &&
                props.friends.length > initialNumberOfOptions &&
                <ExpandButton/>}
            </View>
        )
    }

    return (
        <React.Fragment>
            {expanded ?
                <View style={{
                    width: FRIEND_PICKER_VERTICAL_WIDTH,
                    shadowColor: "black",
                    shadowOffset: {width: 0, height: -10,},
                    shadowOpacity: 1.0,
                    shadowRadius: 15.19,
                    elevation: 23
                }}>
                    <ScrollView>
                        <Content/>
                    </ScrollView>
                </View>
                :
                <View style={{
                    width: FRIEND_PICKER_VERTICAL_WIDTH,
                    alignItems: 'center',
                    shadowColor: "black",
                    shadowOffset: {width: 0, height: -10,},
                    shadowOpacity: 1.0,
                    shadowRadius: 15.19,
                    elevation: 23
                }}>
                    <Content/>
                </View>
            }
        </React.Fragment>
    )
})