import {AppState, Platform} from "react-native"
import BackgroundTimer from "react-native-background-timer"

export class AsyncUtils {

    public static async sleep(ms: number) {
        const state = AppState.currentState
        if (Platform.OS === 'android' && state === 'background') {
            // JS setTimeout will not run on Android if the app is in the background, so we defer to a
            // third party lib. that has a native implementation of the function that works.
            await new Promise<void>(r => BackgroundTimer.setTimeout(r, 1500))
        } else {
            await new Promise(r => setTimeout(r, ms))
        }
    }
}

export const backgroundSafeSetTimeout = (callback: () => void, ms: number): any => {
    if (Platform.OS === 'android') {
        return BackgroundTimer.setTimeout(callback, ms)
    } else {
        return setTimeout(callback, ms)
    }
}

export const backgroundSafeClearTimeout = (timeoutHandle: any): any => {
    if (Platform.OS === 'android') {
        return BackgroundTimer.clearTimeout(timeoutHandle)
    } else {
        return clearTimeout(timeoutHandle)
    }
}

export const backgroundSafeSetInterval = (callback: () => void, ms: number): any => {
    if (Platform.OS === 'android') {
        return BackgroundTimer.setInterval(callback, ms)
    } else {
        return setInterval(callback, ms)
    }
}

export const backgroundSafeClearInterval = (timeoutHandle: any): any => {
    if (Platform.OS === 'android') {
        return BackgroundTimer.clearInterval(timeoutHandle)
    } else {
        return clearInterval(timeoutHandle)
    }
}
