import React, {useContext, useEffect, useState} from 'react'
import {Dimensions,  View, Image, Text, FlatList, ListRenderItemInfo} from "react-native"
import {observer} from "mobx-react"
import {RootDrawerNavigatorList} from "../../lib/interfaces/root-tab-navigator"
import {StackNavigationProp} from "@react-navigation/stack"
import {RouteProp} from '@react-navigation/native'
import {
    Card,
    Button
} from 'react-native-paper'
import CurrentAccountContext from '../../../modules/vizz_account/lib/CurrentAccount'

type IdentificationResultDetailsScreenRouteProp = RouteProp<RootDrawerNavigatorList, 'IdentificationResultDetails'>

type IdentificationResultDetailsScreenNavigationProp = StackNavigationProp<
    RootDrawerNavigatorList,
    'IdentificationResultDetails'>

type Props = {
    route: IdentificationResultDetailsScreenRouteProp,
    navigation: IdentificationResultDetailsScreenNavigationProp
}

const IdentificationResultDetailsScreen = observer((props: Props) => {

    const [identificationResults, setIdentificationResults] = useState<object[]>([])
    const currentAccount = useContext(CurrentAccountContext)

    useEffect(() => {
        refreshPage()
    }, [])

    const refreshPage = () => {
        setIdentificationResults([props.route.params.item])
    }

    const deleteItem = async(imageUrl: string) => {
        await currentAccount.api.delete('vizz_maker.image_identification_results_path',
            { encoded_image_url: encodeURIComponent(imageUrl) })

        props.navigation.navigate("IdentificationResults")
    }

    const renderListItem = (info: ListRenderItemInfo<any>) => {
        const item: any = info.item

        return (
            <Card style={{margin: 16}}>
                <Card.Content>
                    <Card.Content>
                        <Image style={{height: Dimensions.get('window').height * 0.5}}
                            source={{uri: (item as any)["image_url"]}}
                            resizeMode={'contain'}/>
                        <Button
                            mode={'contained'}
                            style={{margin: 10}}
                            onPress={() => void deleteItem((item as any)["image_url"])}>
                            Delete
                        </Button>
                    </Card.Content>
                    <Card.Content>
                        <Text>
                            {(item as any)["answer_text"]}
                        </Text>
                        <Button
                            onPress={() => props.navigation.navigate('VizzDetail', {vizzId: (item as any)["raw_result_json"]["vizzId"]})}>
                            Answer Vizz
                        </Button>
                    </Card.Content>
                    <Card.Content>
                        <Text>
                            raw_result_json = {JSON.stringify((item as any)["raw_result_json"], null, 4)}
                        </Text>
                        <Text>
                            plant_id_results_json = {JSON.stringify((item as any)["plant_id_results_json"], null, 4)}
                        </Text>
                        <Text>
                            food_id_results_json = {JSON.stringify((item as any)["food_id_results_json"], null, 4)}
                        </Text>
                    </Card.Content>
                </Card.Content>
            </Card>
        )
    }

    return (
        <FlatList
            data={identificationResults}
            ListHeaderComponent={
                <View>
                    <Text style={{textAlign: "center", fontSize: 20}}>
                        Identification Result Details for id {(props.route.params.item as any)["id"]}
                    </Text>
                </View>
            }
            viewabilityConfig={{
                waitForInteraction: true,
                itemVisiblePercentThreshold: 75,
            }}
            renderItem={renderListItem}
            keyExtractor={item => (item as any)["id"]}
        />
    )
})

export default IdentificationResultDetailsScreen
