// This technique of Storage.ts & Storage.native.ts is explained in expo docs:
// https://docs.expo.io/versions/v37.0.0/react-native/platform-specific-code/
// The correct package is dynamically selected with this import:
// import Storage from './services/Storage'

import AsyncStorage from '@react-native-async-storage/async-storage'
import JSONHelpers from "./JSONHelpers"

const SecureStorage = {

    get: async (key: string) => {
        try {
            let value = await AsyncStorage.getItem(key)
            if (value != null && JSONHelpers.isJSON(value)) {
                value = JSON.parse(value)
            }
            return value
        } catch (e) {
            return undefined
        }
    },

    set: async (key: string, value: string | null | object) => {
        try {
            if (typeof value === 'object') {
                value = JSON.stringify(value)
            }
            if (value == null || value == 'null') {
                await AsyncStorage.removeItem(key)
            } else {
                await AsyncStorage.setItem(key, value)
            }
            return true
        } catch (e) {
            return false
        }
    }
}

export default SecureStorage
