import {FullscreenModal} from "../../../../app/views/components/FullscreenModal"
import {Animated, Dimensions, ScrollView, StyleSheet, View, Text} from "react-native"
import React, {useContext, useEffect, useRef, useState} from "react"
import {SpreadDetailSidePanel} from "./SpreadDetailSidePanel"
import {observer} from "mobx-react"
import {SpreadDetailController,} from "../../controllers/roblox_games_widget/SpreadDetailController"
import CurrentAccountContext from "../../../vizz_account/lib/CurrentAccount"
import HomeController from "../../../start/controllers/HomeController"
import {isLandscape, isPortrait, isTablet} from "../../../../app/lib/Appearance"
import useTheme from "../../../lava-components/components/hooks/useTheme"
import {ActivityIndicator} from "react-native-paper"
import {SpreadDetailState} from "./SpreadDetailState"
import {SpreadDetailContent} from "./SpreadDetailContent"
import {COLLAPSED_WIDTH, EXPANDED_WIDTH, SpreadDetailSidePanelState} from "./SpreadDetailConstants"
import {VideoLightbox} from "../video/VideoLightbox"
import {reaction} from "mobx"
import {useSafeAreaInsets} from "react-native-safe-area-context"
import Title from "../../../lava-components/components/atoms/Title/Title"
import {ConceptThumbnail} from "../../../../app/views/web_navigation/feed/concept_navigator/ConceptThumbnail"
import {ConceptBookModel} from "../../models/ConceptBookModel"

const {width} = Dimensions.get('screen')

export type RobloxGameDetailScrollingContentProvider = {
    onScroll: (xCoordinate: number) => void
    onScrollEnded: () => void
    forcedScrollOffset: number
}

type Props = {
    book: ConceptBookModel
    onDismiss: () => void
    homeController: HomeController
    openTrigger?: string
}

export const SpreadDetail = observer((props: Props) => {

    const currentAccount = useContext(CurrentAccountContext)
    const [controller] = useState(() => new SpreadDetailController(currentAccount, props.homeController, props.book, props.openTrigger))
    const theme = useTheme()
    const sidePanelWidth = useRef(new Animated.Value(EXPANDED_WIDTH))
    const [sidePanelState, setSidePanelState] = useState(SpreadDetailSidePanelState.EXPANDED)
    const [animatedPanelWidth] = useState(new Animated.Value(EXPANDED_WIDTH))
    const [playPreviewVideo,setPlayPreviewVideo] = useState(false)
    const safeAreaInsets = useSafeAreaInsets()
    const mobileScrollViewRef = useRef<any>()

    useEffect(() => {
        void controller.initialize()
        controller.stateReaction = reaction(() => controller.state, (newState, oldState) => {
            if (newState == SpreadDetailState.LOADED) {
                if (props.homeController.focus === 'secrets' || props.homeController.focus === 'codes') {
                    collapseSidePanel()
                }
                if (props.homeController.focus === 'trailer') {
                    setPlayPreviewVideo(true)
                }
            }
        })

        return () => {
            controller.uninitialize()
        }
    }, [])

    useEffect(() => {
        const animationListener = animatedPanelWidth.addListener(({value}) => {
            sidePanelWidth.current.setValue(value)
        })

        return () => {
            animatedPanelWidth.removeListener(animationListener)
        }
    }, [animatedPanelWidth])

    const collapseSidePanel = () => {
        if (isPortrait()) return

        if (sidePanelState === SpreadDetailSidePanelState.EXPANDED) {
            setSidePanelState(SpreadDetailSidePanelState.COLLAPSED)
            setPlayPreviewVideo(false)
            Animated.timing(animatedPanelWidth, {
                toValue: COLLAPSED_WIDTH,
                duration: 200,
                useNativeDriver: true
            }).start()
        }
    }

    const onDismiss = () => {
        controller.logEvent('spread-details', 'closed', props.book.title, {book: {title: props.book.title, id: props.book.id}})

        if (playPreviewVideo) {
            setPlayPreviewVideo(false)
        }
        if (controller.modalVideo) {
            controller.stopVideo()
        }
        props.onDismiss()
    }

    const Header = () => {
        return (
            <View style={{flexDirection: 'row', height: isTablet() ? 78 : 78, alignItems: 'center'}}>
                <Title numberOfLines={1} variation="title1" style={{marginLeft: theme.spacing.xl*3, marginTop: theme.spacing.xl, color: 'white'}}>
                    Dogs
                </Title>
            </View>
        )
    }

    return (
        <FullscreenModal visible={props.homeController.conceptDetailVisible} onDidDisappear={onDismiss} innerModal={!!controller.modalVideo}>
            <View style={{
                flex: 1,
                borderWidth: 0,
                borderColor: 'red',
                paddingTop: 0,
                paddingBottom: 0,
                paddingLeft: safeAreaInsets.left,
                paddingRight: safeAreaInsets.right,
            }} >
                <View style={{
                    height: 120,
                    opacity: 0.5,
                    backgroundColor: 'black',
                    paddingTop: safeAreaInsets.top,
                    flexDirection: 'row',
                }}>
                    <View style={{
                        width: EXPANDED_WIDTH,
                    }}>
                        <Title numberOfLines={1} variation="title1" style={{marginLeft: theme.spacing.xl*3, marginTop: theme.spacing.xl, color: 'white'}}>
                            Pets
                        </Title>
                    </View>

                    <View style={{
                        flexGrow: 1,
                        overflow: 'hidden',
                        marginRight: 60,
                    }}>

                        <ScrollView horizontal={true} showsHorizontalScrollIndicator={false} style={{width: '95%'}} contentContainerStyle={{ overflow: 'hidden', flexDirection: 'row' }}>
                            <ConceptThumbnail
                                imageUrl={'https://p425.p0.n0.cdn.getcloudapp.com/items/kpuREOyq/406bb000-762f-4721-9e7b-3de5ef457c8b.jpg?v=aa7f8df61b1c0da652cd890bb07f5971'}
                                label={'Dogs'}
                                width={110}
                                disabled={false}
                                selected={true}
                                key={`toc-concept-1`}
                                style={{marginRight: 20}}
                            />

                            <ConceptThumbnail
                                imageUrl={'https://p425.p0.n0.cdn.getcloudapp.com/items/z8udvENL/2705ada3-18c5-494e-9ad2-b3a1197ab661.png?v=41c659cbbb47a0b1eb32d67b40f9b0bd'}
                                label={'Cats'}
                                width={110}
                                disabled={false}
                                selected={false}
                                key={`toc-concept-2`}
                                style={{marginRight: 20}}
                            />

                            <ConceptThumbnail
                                imageUrl={'https://p425.p0.n0.cdn.getcloudapp.com/items/Jrumzywg/9978aedc-05d5-4f18-81ee-98e62fd56c52.jpg?v=dd8242710151c86c6cfaf0e773fce51f'}
                                label={'Lizards'}
                                width={110}
                                disabled={false}
                                selected={false}
                                key={`toc-concept-3`}
                                style={{marginRight: 20}}
                            />

                            <ConceptThumbnail
                                imageUrl={'https://p425.p0.n0.cdn.getcloudapp.com/items/NQu5q4el/f8c1ee52-103f-4a41-be73-920f0fa97078.jpg?v=0d5b47d833c9200da0b940d8e787b6a0'}
                                label={'Birds'}
                                width={110}
                                disabled={false}
                                selected={false}
                                key={`toc-concept-4`}
                                style={{marginRight: 20}}
                            />

                            <ConceptThumbnail
                                imageUrl={'https://p425.p0.n0.cdn.getcloudapp.com/items/xQuNeOD1/e445f764-5280-4df8-a603-80bf5bc88673.jpg?v=59527640d9cf672071aab9fc7ff2a342'}
                                label={'Fish'}
                                width={110}
                                disabled={false}
                                selected={false}
                                key={`toc-concept-5`}
                                style={{marginRight: 20}}
                            />

                            <ConceptThumbnail
                                imageUrl={'https://p425.p0.n0.cdn.getcloudapp.com/items/nOuQB1kd/1e8b9248-35d9-4520-a320-93b41223af7c.jpg?v=30f46cbf1da60c6cc97c7d9829ed0505'}
                                label={'Horses'}
                                width={110}
                                disabled={false}
                                selected={false}
                                key={`toc-concept-6`}
                                style={{marginRight: 20}}
                            />

                            <ConceptThumbnail
                                imageUrl={'https://p425.p0.n0.cdn.getcloudapp.com/items/z8udvlZw/f6c42a57-f0db-4f05-8d25-cb71ed572457.jpg?v=53b9b0394ad10dfa294c36d44b67051b'}
                                label={'Rabbits'}
                                width={110}
                                disabled={false}
                                selected={false}
                                key={`toc-concept-7`}
                                style={{marginRight: 20}}
                            />
                        </ScrollView>
                    </View>
                </View>
                <View style={{
                    flexGrow: 1,
                    borderTopWidth: 1,
                    borderTopColor: '#505050',
                }}>
                    {Header()}

                    {controller.state == SpreadDetailState.LOADED && isLandscape() &&
                        <View style={{flex: 1, flexDirection: 'row', marginTop: theme.spacing.lg}}>
                            <Animated.View style={{width: sidePanelWidth.current}}>
                                <SpreadDetailSidePanel
                                    controller={controller}
                                    sidePanelWidth={sidePanelWidth.current}
                                    animatedPanelWidth={animatedPanelWidth}
                                    sidePanelState={sidePanelState}
                                    setSidePanelState={setSidePanelState}
                                    playPreviewVideo={playPreviewVideo}
                                    setPlayPreviewVideo={setPlayPreviewVideo}
                                />
                            </Animated.View>

                            <View style={{flex: 1, marginLeft: theme.spacing.xl * 2}}>
                                <SpreadDetailContent
                                    spreadDetailController={controller}
                                    collapseSidePanel={collapseSidePanel}
                                    sidePanelState={sidePanelState}
                                    secretVideoPlayable={props.homeController.focus === "secrets"}
                                    codesVideoPlayable={props.homeController.focus === "codes"}
                                />
                                <View />
                            </View>
                        </View>
                    }

                    {controller.state == SpreadDetailState.LOADED && isPortrait() &&
                        <ScrollView style={{flex: 1}} ref={mobileScrollViewRef}>
                            <SpreadDetailSidePanel
                                controller={controller}
                                animatedPanelWidth={animatedPanelWidth}
                                sidePanelWidth={new Animated.Value(width)}
                                sidePanelState={SpreadDetailSidePanelState.COLLAPSED}
                                setSidePanelState={setSidePanelState}
                                playPreviewVideo={playPreviewVideo}
                                setPlayPreviewVideo={setPlayPreviewVideo}
                                />
                            <View style={{flex: 1}}>
                                <SpreadDetailContent
                                    spreadDetailController={controller}
                                    sidePanelState={SpreadDetailSidePanelState.COLLAPSED}
                                    secretVideoPlayable={props.homeController.focus === "secrets"}
                                    codesVideoPlayable={props.homeController.focus === "codes"}
                                    scrollMobileToEnd={() => mobileScrollViewRef.current?.scrollToEnd()}
                                />
                            </View>
                        </ScrollView>
                    }



                    {controller.state == SpreadDetailState.LOADING &&
                        <View style={{flex: 1, justifyContent: 'center', alignItems: 'center', ...StyleSheet.absoluteFillObject}}>
                            <ActivityIndicator color={'white'} />
                        </View>}
                </View>
            </View>
           <VideoLightbox
                video={controller.modalVideo}
                onDismiss={() => controller.stopVideo()} />
        </FullscreenModal>
    )
})
