import React, {StyleSheet, View} from "react-native"
import Title from "../../atoms/Title/Title"
import {LavaImage} from "../../atoms/LavaImage/LavaImage"
import {TouchableRipple} from "react-native-paper"
import Avatar from "../../atoms/Avatar/Avatar"

export type FriendListActionRowProps =  {
    title: string
    icon: 'add-friend'|'new-group'
    onPress?: () => void
}

export const FriendListActionRow = (props: FriendListActionRowProps) => {

    const ActionIcon = () => {
        switch (props.icon) {
            case 'add-friend':
                return <LavaImage source={require('./assets/icn-add-friend.png')}/>
            case 'new-group':
                return <LavaImage source={require('./assets/icn-new-group.png')}/>
        }
    }

    return(
        <TouchableRipple onPress={props.onPress}>
            <View style={styles.container}>
                <View>
                    <Avatar icon={ActionIcon()} variant={'secondary'} />
                </View>
                <View style={styles.textContent}>
                    <Title style={styles.text} variation="subtitle2" numberOfLines={1}>{props.title}</Title>
                </View>
            </View>
        </TouchableRipple>
    )
}

const styles = StyleSheet.create({
    container: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    text: {
        color: '#FFFFFF99'
    },
    textContent: {
        marginLeft: 12,
        paddingRight: 20,
    }
})
