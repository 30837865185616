// TODO: Figure out how to make Rails send back camel case
import {VizzModel} from "../../../modules/vizz_maker/models/VizzModel"

export enum VizzState {
    draft = 'draft',
    published = 'published',
    publishedAndCrawlable = 'published_and_crawlable',
    archived = 'archived'
}

export enum VizzContentType {
    howTo = 'how_to',
    wow = 'wow',
    collection = 'collection',
    longExplanation = 'long_explanation',
    audioOnly = 'audio_only',
    autoGenerated = 'auto_generated',
    latest = 'latest',
    reviewed = 'reviewed',
    flagged = 'flagged',
    awaitingModeration = 'awaiting_moderation'
}

export interface VizzSource {
    title: string
    key: string
    source_markdown: string
    search_keywords: string
    source_json: any
    processed_json: VizzModel
    state: VizzState
    content_type?: VizzContentType
    use_per_slide_audio: boolean
    updated_at: string
}

