import React, {useContext, useEffect, useRef, useState} from 'react'
import {StyleSheet, View, ViewStyle, Text, KeyboardAvoidingView} from "react-native"
import {observer} from "mobx-react"
import {SMSController, SMSState} from "../../controllers/steps/SMSController"
import CurrentAccountContext from "../../../vizz_account/lib/CurrentAccount"
import {OnboardingController, OnboardingState} from '../../controllers/OnboardingController'
import {reaction} from "mobx"
import {State, TapGestureHandler, TapGestureHandlerStateChangeEvent} from 'react-native-gesture-handler'
import {PrimaryButton} from '../../../../app/views/components/PrimaryButton'
import {Button, TextInput} from 'react-native-paper'
import PhoneInput from "react-native-phone-input/dist"
import {isLandscape} from '../../../../app/lib/Appearance'

type Props = {
    style?: ViewStyle
    onboardingController: OnboardingController
}

export const SMS = observer((props: Props) => {
    const currentAccount = useContext(CurrentAccountContext)
    const [controller] = useState<SMSController>(() => new SMSController(currentAccount, props.onboardingController))
    const phoneInput = useRef<any>()

    useEffect(() => {
        const neededReaction = reaction(() => props.onboardingController.state, async(state) => {

            if (currentAccount.personData.smsOnboardingNeeded) await controller.initialize()

            if (state == OnboardingState.SMS) {
                if (currentAccount.personData.smsOnboardingNeeded) {
                    await controller.startStep()
                    phoneInput.current?.focus()
                } else
                    controller.nextStep()
            }
        })

        return () => {
            neededReaction()
            controller.uninitialize()
        }
    }, [])

    const onDebugTappedGesture = (event: TapGestureHandlerStateChangeEvent) => {
        if (event.nativeEvent.state === State.ACTIVE) {
            controller.prevStep()
        }
    }

    return (
        <React.Fragment>
            {props.onboardingController.state == OnboardingState.SMS && controller.state == SMSState.PHONE &&
            <View style={[{flex: 1, alignItems: 'center', justifyContent: 'center'}, props.style]}>
                <KeyboardAvoidingView behavior={'position'} keyboardVerticalOffset={80}>
                    <View style={{alignItems: 'center', justifyContent: 'center'}}>
                        <TapGestureHandler numberOfTaps={4} onHandlerStateChange={onDebugTappedGesture}>
                            <Text style={[props.onboardingController.styles.messageText]}>Enter the cell phone for a parent. We'll text a code.</Text>
                        </TapGestureHandler>

                        <View style={{marginTop: 10, marginStart: 0}}>
                            <View style={{
                                backgroundColor: 'white',
                                justifyContent: 'center',
                                marginTop: 10,
                                height: 64,
                                padding: 16,
                                overflow: 'hidden',
                                borderRadius: 12
                            }}>

                                <PhoneInput
                                    style={{width: 320}}
                                    ref={phoneInput}
                                    initialCountry={'us'}
                                    autoFormat={true}
                                    confirmText={'Select'}
                                    onChangePhoneNumber={(text) => {
                                        const cleanPhone = text
                                            .replace(/ /g, '')
                                            .replace(/-/g, '')
                                        controller.setPhoneNumber(text)
                                        controller.setErrorMessage('')
                                    }}

                                />
                            </View>
                        </View>
                        <Text style={{color: 'orangered', marginTop: 10}}>{controller.errorMessage} </Text>

                        <View style={{marginTop: 20, flexDirection: 'column'}}>
                            <PrimaryButton
                                disabled={controller.phoneNumber.length < 7}
                                title={'Text a code'}
                                onPress={() => {
                                    if (phoneInput.current) { // TODO: when returning to this step, the ref is not valid and I can't figure out why
                                        if (phoneInput.current.isValidNumber())
                                            void controller.onSubmitPhoneNumber()
                                        else
                                            controller.setErrorMessage('The phone number is not valid.')
                                    } else
                                        void controller.onSubmitPhoneNumber()
                                }}/>

                            <Button
                                style={{margin: 20}}
                                disabled={false}
                                color={'white'}
                                onPress={() => controller.skipStep()}>
                                    {"Skip, I'll do this later"}
                            </Button>
                        </View>
                    </View>
                </KeyboardAvoidingView>
            </View>}

            {props.onboardingController.state == OnboardingState.SMS && controller.state == SMSState.CODE &&
            <View style={[{flex: 1, alignItems: 'center', justifyContent: 'center'}, props.style]}>
                <KeyboardAvoidingView behavior={'position'} keyboardVerticalOffset={80}>
                    <View style={{alignItems: 'center', justifyContent: 'center'}}>
                        <TapGestureHandler numberOfTaps={4} onHandlerStateChange={onDebugTappedGesture}>
                            <Text style={[props.onboardingController.styles.messageText]}>Enter the code sent to {controller.phoneNumber}</Text>
                        </TapGestureHandler>

                        <TextInput
                            style={{width: 320}}
                            mode={'flat'}
                            autoCapitalize={'characters'}
                            textContentType={'oneTimeCode'}
                            keyboardType={'number-pad'}
                            label="Code"
                            maxLength={4}
                            value={controller.verificationCode}
                            autoFocus={true}
                            autoCorrect={false}
                            onChangeText={text => { controller.setVerificationCode(text); controller.setErrorMessage(''); controller.setVerifyCodeButtonText(undefined) }}
                            onSubmitEditing={() => controller.onSubmitVerificationCode()}
                        />
                        <Text style={{color: 'orangered', marginTop: 10}}>{controller.errorMessage} </Text>

                        <PrimaryButton
                            disabled={controller.verificationCode.length < 4 || controller.verifyCodeButtonText != undefined}
                            style={{marginTop: 10, marginBottom: 20}}
                            iconType={'FontAwesome'}
                            icon={'thumbs-up'}
                            iconSide={'left'}
                            title={controller.verifyCodeButtonText}
                            onPress={() => controller.onSubmitVerificationCode()}
                        />

                        <Button
                            style={{marginVertical: 10}}
                            disabled={false}
                            color={'white'}
                            onPress={() => controller.onChangePhoneNumber()}>
                                {"< Change Phone Number"}
                        </Button>

                        <Button
                            style={{margin: 20}}
                            disabled={false}
                            color={'white'}
                            onPress={() => controller.skipStep()}>
                                {"Skip, I'll do this later"}
                        </Button>
                    </View>
                </KeyboardAvoidingView>
            </View>}

            {props.onboardingController.state == OnboardingState.SMS && controller.state == SMSState.WHO &&
            <View style={[{flex: 1, alignItems: 'center', justifyContent: 'center'}, props.style]}>
                <View style={{alignItems: 'center', justifyContent: 'center'}}>
                    <TapGestureHandler numberOfTaps={4} onHandlerStateChange={onDebugTappedGesture}>
                        <Text style={[props.onboardingController.styles.messageText]}>Whose phone is this?</Text>
                    </TapGestureHandler>

                    <View style={{marginTop: 10, alignItems: 'center', flexDirection: isLandscape() ? 'row' : 'column'}}>
                        <PrimaryButton
                            style={{margin: 8}}
                            onPress={() => controller.onAnswerWho('Mom')}
                            iconType={'Fontisto'}
                            icon={'female'}
                            iconSide={'right'}
                            title={'Mom'}
                        />

                        <PrimaryButton
                            style={{margin: 8}}
                            onPress={() => controller.onAnswerWho('Dad')}
                            iconType={'Fontisto'}
                            icon={'male'}
                            iconSide={'right'}
                            title={'Dad'}
                        />

                        <PrimaryButton
                            style={{margin: 8}}
                            onPress={() => controller.onAnswerWho('Guardian')}
                            iconType={'Ionicons'}
                            icon={'person'}
                            iconSide={'right'}
                            title={'Other'}
                        />
                    </View>
                </View>
            </View>}
        </React.Fragment>
    )
})

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
})