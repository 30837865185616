import * as Notifications from 'expo-notifications'
import * as BackgroundFetch from 'expo-background-fetch'
import {Platform} from 'react-native'
import * as TaskManager from 'expo-task-manager'
import {ProfileStatusService} from '../../modules/social/services/ProfileStatusService'
import PushCommandModel from "../lib/models/PushCommandModel"
import {Task} from '../lib/Task'
import {RawState} from './NativeStateService'

export class TaskManagerService {

    /**
     * Must be called at the top level of the JS code, e.g. global scope.
     */
    public static initializeFromGlobalScope() {
        if (Platform.OS == 'web') return

        // Task Handlers (for background execution, must be defined in global scope)
        TaskManager.defineTask(Task.NOTIFICATIONS, async (taskBody) => {
            if (taskBody.error) {
                console.error('Background notification error', taskBody.error)
            } else if (taskBody.data) {
                const data = (taskBody.data as any)

                const rawCommand = data.command
                if (!rawCommand) {
                    await ProfileStatusService.occasionalUpdateStatus(RawState.WAKEUP, data)
                    return
                }

                const command = rawCommand as PushCommandModel

                if (command.shouldIssueProfileUpdate) {
                    await ProfileStatusService.occasionalUpdateStatus(RawState.WAKEUP, data)
                }
            }
        })

        void Notifications.registerTaskAsync(Task.NOTIFICATIONS)

        TaskManager.defineTask(Task.APP_REFRESH, async (taskBody) => {
            if (taskBody.error) {
                console.error('Background app refresh error', taskBody.error)
            } else if (taskBody.data) {
                await ProfileStatusService.occasionalUpdateStatus(RawState.REFRESH, taskBody.data)
            }
            // Does not fire very reliably. We have a secondary custom BackgroundRefresh in appController

            return BackgroundFetch.BackgroundFetchResult.NewData // This return value is to let iOS know what the result
                                                                // of your background fetch was, so the platform can better
                                                                // schedule future background fetches.
                                                                // https://docs.expo.dev/versions/latest/sdk/background-fetch/#backgroundfetchresult
        })

        // Do this more frequently on Android because it allows for it, and because it's our only
        // background update mechanism on that platform.
        const minInterval = Platform.OS === 'android' ? (30) : (60 * 15)

        void BackgroundFetch.registerTaskAsync(Task.APP_REFRESH, {
            minimumInterval: minInterval,
            stopOnTerminate: false, // android only,
            startOnBoot: true, // android only
        })
    }
}