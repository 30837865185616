import {CurrentAccount} from "../../../modules/vizz_account/lib/CurrentAccount"
import {FeatureFlagModel} from "../../../modules/vizz_account/models/FeatureFlagModel"
import {HttpError} from "../../services/RailsAPIService"
import SentryService from "../../services/SentryService"

class LavaBotService {
    private debug: boolean = false  // don't set this to true in production
    private currentAccount!: CurrentAccount

    constructor() {
        this.consoleDebug(`new()`)
    }

    public initialize(currentAccount: CurrentAccount) {
        this.consoleDebug(`initialize()`)
        this.currentAccount = currentAccount
    }

    public async greet() {
        if ((typeof this.currentAccount === "undefined") || this.currentAccount.personData.onboardingNeeded) return

        try {
            await this.currentAccount.api.get("channel_chat_path(0, -1)") // channel 0 = lava_bot  and  chat -1 = last chat
        } catch(error) {
            if (!(error instanceof Error)) return
            const isNotFound = (error instanceof HttpError) && error.status == "not_found"

            if (!isNotFound) SentryService.captureError(error)
        }
    }

    // private

    private consoleDebug(method: string, force: boolean = false) {
        if(this.debug || force) console.log(`${this.constructor.name}: ${method}`)
    }
}

export default LavaBotService
