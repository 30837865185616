import React, {useEffect} from 'react'
import {Alert, StyleSheet, Text, View, ViewStyle} from "react-native"
import {observer} from "mobx-react"
import {RobloxFriendModel} from '../../models/RobloxFriendModel'
import {Typography} from "../../../../app/lib/Typography"
import {FeedWidgetController} from '../../controllers/FeedWidgetController'
import {Button} from 'react-native-paper'
import {LavaImage} from "../../../../app/views/components/LavaImage"

type Props = {
    title: string
    body: string
    controller?: FeedWidgetController
    referralTarget?: RobloxFriendModel
    pickButton?: boolean
    countPhrase?: string
    style?: ViewStyle
}

export const ReferralStory = observer((props: Props) => {

    useEffect(() => {
        return () => {
        }
    }, [])


    const tapped = (countPhrase?: string) => {
        if (props.controller == undefined) return

        props.controller.homeController.setDimBackground(true)

        Alert.alert(
            "Refer Again?",
            `Would you like to see which friends need Lava?`,
            [
                {
                    text: "Cancel",
                    onPress: () => { props.controller && props.controller.homeController.setDimBackground(false) },
                    style: "cancel"
                },
                {
                    text: "Show Me",
                    onPress: () => {
                        if (props.controller == undefined) return

                        props.controller.homeController.setDimBackground(false)
                        props.controller.onboardingRobuxStep(countPhrase)
                    },
                    style: 'default'
                }
            ]
        )
    }

    return (
        <View style={[styles.container, props.style]}>
            <View style={{flexDirection: 'row'}}>
                <LavaImage style={{width: 50, height: 55, marginRight: 10}} source={{uri: "https://res.cloudinary.com/explanation/image/upload/v1670619480/assets/robux-logo-white_jpv5ta.png"}} />
                <Text style={[Typography.headline2, {color: 'white', fontSize: 18, flexGrow: 1, paddingTop: 13}]}>
                    {props.title}
                </Text>
            </View>
            <View style={{flexGrow: 1}}>
                {props.referralTarget &&
                <View style={{alignItems: 'center'}}>
                    <LavaImage style={{width: 100, height: 100, marginTop: -20}} source={{uri: props.referralTarget.avatar_url}} />
                </View>}

                <Text style={[Typography.label, {color: 'white', marginTop: 8, fontSize: 13, maxWidth: 280}, props.referralTarget ? {} : { marginTop: 20 }]}>{props.body}</Text>

                {props.pickButton && props.controller &&
                <View style={{alignItems: 'center'}}>
                    <Button
                        style={{marginTop: 20}}
                        mode={'contained'}
                        color={'green'}
                        dark={true}
                        compact={false}
                        onPress={() => tapped(props.countPhrase)}>
                        Get 100 Robux
                    </Button>
                </View>}
            </View>
        </View>
    )
})

const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: 'column',
        padding: 18,
        backgroundColor: 'rgba(22,30,44,1)',
        borderRadius: 8,
    },
})
