import React, {useContext, useEffect, useRef, useState} from 'react'
import {FriendWidgetController} from "../../controllers/FriendWidgetController"
import {observer} from "mobx-react"
import {StyleSheet, Text, View} from "react-native"
import {IconButton, Modal} from "react-native-paper"
import {PrimaryButton} from "../../../../app/views/components/PrimaryButton"
import CurrentAccountContext from '../../../vizz_account/lib/CurrentAccount'
import {AddFriendController} from '../../controllers/friend_widget/AddFriendController'
import {SpeechUtils} from '../../../../app/utils/AVUtils'

type Props = {
    friendWidgetController: FriendWidgetController,
}

export const InvitationReminder = observer((props: Props) => {

    const currentAccount = useContext(CurrentAccountContext)
    const [controller] = useState<AddFriendController>(() => new AddFriendController(currentAccount, props.friendWidgetController))
    const inputRef = useRef<any>()
    const isMountedRef = useRef(false)
    const [speech] = useState(() => new SpeechUtils(currentAccount, true))

    useEffect(() => {
        isMountedRef.current = true

        inputRef.current?.focus()
        controller.initialize()

        return () => {
            isMountedRef.current = false
            speech.unload()
        }
    }, [])

    useEffect(() => {
        if (props.friendWidgetController.invitationReminderVisible) {
            void speech.speak("Tell your friends to get the Lava app and search for you. Then you can FaceTime together!")
        } else {
            speech.pause()
        }
    }, [props.friendWidgetController.invitationReminderVisible])

    return (
        <Modal style={{justifyContent: 'center', alignItems: 'center'}} visible={true} onDismiss={() => props.friendWidgetController.hideInvitationReminder()}>
            <View style={styles.container}>
                <View style={{flexDirection: 'row-reverse'}}>
                    <IconButton
                        onPress={() => props.friendWidgetController.hideInvitationReminder()}
                        icon={'close'}
                        size={30}
                        color={'white'} />
                    <Text style={[styles.headerLabel, {flexGrow: 1}]}>TELL YOUR FRIENDS</Text>
                </View>

                <View style={{marginTop: 20, maxWidth: 400}}>
                    <View style={{flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                        <Text style={{color: 'white', fontSize: 20, marginTop: 30, marginBottom: 10, fontFamily: 'Agrandir-Bold'}}>
                            Get the Lava app and search for you:
                        </Text>
                        <Text style={{color: 'white', fontSize: 24, marginTop: 30, marginBottom: 10, fontFamily: 'Agrandir-Bold'}}>
                            {currentAccount.profile?.pretty_username}
                        </Text>
                        <PrimaryButton
                            style={{margin: 30}}
                            onPress={() => props.friendWidgetController.hideInvitationReminder()}
                            title={'OK'}/>

                    </View>
                </View>
            </View>
        </Modal>
    )
})

const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexGrow: 1,
        backgroundColor: 'black',
        padding: 24,
        marginVertical: 48,
        borderRadius: 6,
        borderWidth: 0.4,
        borderColor: 'rgba(255, 255, 255, 0.3)'
    },
    headerLabel: {
        color: 'white',
        fontFamily: 'Agrandir-Bold',
        fontSize: 20,
        marginTop: 20
    },
    subheaderLabel: {
        color: 'white',
        fontFamily: 'Agrandir-Regular',
        fontSize: 16
    },
    inputField: {
        width: 360,
        marginTop: 24,
    }
})
