import React, {useContext, useState} from "react"
import {Button, View} from "react-native"
import {ActivityIndicator, HelperText, Text} from 'react-native-paper'
import {Color} from "../../lib/Appearance"
import {Audio} from 'expo-av'
import {Camera} from 'expo-camera'
import * as FileSystem from 'expo-file-system'
import CurrentAccountContext from "../../../modules/vizz_account/lib/CurrentAccount"

export const SpeechToText = () => {

    const currentAccount = useContext(CurrentAccountContext)
    const [recording, setRecording] = useState<Audio.Recording>()
    const [isRecording, setIsRecording] = useState<boolean>(false)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [recognizedText, setRecognizedText] = useState<string>('Recognized Text:')

    const recordingOptions = {
        // android not currently in use. Not getting results from speech to text with .m4a
        // but parameters are required
        android: {
            extension: '.awb',
            outputFormat: Audio.AndroidOutputFormat.AMR_WB,
            audioEncoder: Audio.AndroidAudioEncoder.AMR_WB,
            sampleRate: 16000,
            numberOfChannels: 1,
            bitRate: 128000,
        },
        ios: {
            extension: '.wav',
            audioQuality: Audio.IOSAudioQuality.HIGH,
            sampleRate: 44100,
            numberOfChannels: 1,
            bitRate: 128000,
            linearPCMBitDepth: 16,
            linearPCMIsBigEndian: false,
            linearPCMIsFloat: false,
        },
        web: {}
    }

    const setUpAudio = async () => {
        await Camera.requestMicrophonePermissionsAsync()
        await Audio.setAudioModeAsync({
            allowsRecordingIOS: true
        })
    }

    const startRecording = async() => {
        setIsRecording(true)
        setRecognizedText("")
        await setUpAudio()

        try {
            console.log('Requesting permissions..')
            await Camera.requestMicrophonePermissionsAsync()
            await Audio.setAudioModeAsync({
              allowsRecordingIOS: true,
              playsInSilentModeIOS: true,
            })
        } catch(err) {
            console.log("Permissions error", err)
        }

        const r = new Audio.Recording()
        try {
          await r.prepareToRecordAsync(recordingOptions)
          await r.startAsync()
          setRecording(r)
          console.log("Started recording.")
        } catch (error) {
            console.log("Error while recording", error)
        }
    }

    const stopRecording = async() => {
        setIsRecording(false)
        if(recording){
            console.log('Stopping recording...')
            try{
                await recording.stopAndUnloadAsync()
            } catch (err) {
                console.log(`Error while stopping recording: ${err}`)
            }
            const uri = recording.getURI()
            console.log('Recording stopped and stored at', uri)
            setRecording(undefined)
            await getTranscription(uri)
        }
        else {
            console.log("Stop Recording called but recording was undefined")
        }
    }

    const getTranscription = async(audioUri: string|null) => {
        if (audioUri) {
            var transcriptionStartTime = Date.now()
            setIsLoading(true)

            try {
                const data = await uploadAudioAsync(audioUri)
                console.log(data)
                var timeTaken = Date.now() - transcriptionStartTime
                var extraInfo = `(That took ${(timeTaken/1000).toFixed(2)}s of which External::SpeechToText took ${data.timeTaken.toFixed(2)}s)`
                setRecognizedText(`${data.transcript} ${extraInfo}`)
            } catch(error) {
                console.log('There was an error uploading the audio file', error)
                resetRecording()
            }
            setIsLoading(false)
        }
    }

    const uploadAudioAsync = async (uri: string) => {
        let uriParts = uri.split('.')
        let fileType = uriParts[uriParts.length - 1]

        const data: any = {
            file: {
                uri: uri,
                name: uri.substring(uri.lastIndexOf('/')+1),
                type: `audio/x-${fileType}`,
            }
        }

        return await currentAccount.api.post('vizz_maker.speech_to_text_path', data)
    }

    const resetRecording = () => {
        void deleteRecordingFile()
        setRecording(undefined)
    }

    const deleteRecordingFile = async () => {
        if (recording !== undefined && recording.getURI() !== undefined && recording.getURI() !== null){
            try {
                const info = await FileSystem.getInfoAsync((recording.getURI() as string))
                await FileSystem.deleteAsync(info.uri)
            } catch(error) {
                console.log("There was an error deleting the recording file", error)
            }
        }
    }

    return (

        <View style={{flex: 1, alignItems: "center", marginTop: 10}}>
            {isLoading && <ActivityIndicator color="#ffffff" />}
            {!isRecording && <Text>Tap 🎤 and then Speak</Text>}
            {isRecording && <Text>Recording...</Text>}

            <Button
                title={isRecording ? 'Stop Recording' : '🎤 Start Recording'}
                onPress={isRecording ? stopRecording : startRecording}
                color={Color.primary}
                />
            <HelperText type={'info'} visible={true}>{recognizedText}</HelperText>

        </View>
    )
}
