import React, {ForwardedRef, useContext, useEffect, useRef, useState} from 'react'
import {StyleSheet, View, ViewStyle} from "react-native"
import {ActivityIndicator, TouchableRipple} from "react-native-paper"
import {Observer, observer} from "mobx-react"
import {InterestsGridController, InterestsGridState} from "../../controllers/steps/interests/InterestsGridController"
import {Color, isLandscape, isPortrait} from "../../../../app/lib/Appearance"
import CurrentAccountContext from "../../../vizz_account/lib/CurrentAccount"
import {FlatGrid} from 'react-native-super-grid'
import {ConceptThumbnail} from '../../../../app/views/web_navigation/feed/concept_navigator/ConceptThumbnail'
import {MaterialCommunityIcons} from "@expo/vector-icons"
import InterestModel from '../../models/steps/InterestModel'
import {IReactionDisposer, reaction} from 'mobx'

type Props = {
    style?: ViewStyle
    onToggleSelectedInterest?: (interest: InterestModel) => void
    onLoad?: (interestsGridController: InterestsGridController) => void
    maxSelect?: number
    freezeSelection?: boolean
}

export const InterestsGrid = observer((props: Props, ref: ForwardedRef<any>) => {
    const currentAccount = useContext(CurrentAccountContext)
    const [controller] = useState<InterestsGridController>(() => new InterestsGridController(currentAccount, props.maxSelect))
    const [showedHint, setShowedHint] = useState<boolean>(false)
    const flatGridRef = useRef<any>(null)
    let loadedReaction:IReactionDisposer|undefined = undefined

    useEffect(() => {
        void controller.initialize()

        loadedReaction = reaction(() => controller.state, async (state) => {
            if (state == InterestsGridState.LOADED && props.onLoad) props.onLoad(controller)
        })

        return () => {
            controller.uninitialize()
            if (loadedReaction) loadedReaction()
        }
    }, [])

    const scrollHint = () => {
        if (isPortrait() && !showedHint) {
            // @ts-ignore: typescript isn't recognizing this forwarded ref
            flatGridRef.current?.scrollToIndex({index: 1, viewOffset: 0, animated: true})
            setShowedHint(true)
        }
    }

    const renderLoading = () => {
        return (
            <View style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}>
                <ActivityIndicator color={Color.primary} />
            </View>
        )
    }

    const renderGrid = (columns: number, width: number) => {
        const spacing = 24
        const containerWidth = width * columns + (spacing * (columns + 1))
        return (
            <FlatGrid
                ref={flatGridRef}
                style={{width: containerWidth, flexGrow: 0}}
                scrollEnabled={columns < 4}
                data={controller.interests}
                renderItem={(item) => renderGridItem(item.item, width)}
                spacing={spacing}
                maxItemsPerRow={columns}
            />

        )
    }

    const onPress = (interest: InterestModel) => {
        if (!props.onToggleSelectedInterest || props.freezeSelection) return

        scrollHint()
        controller.toggleSelectedInterest(interest)
        props.onToggleSelectedInterest(interest)
    }

    const renderGridItem = (interest: InterestModel, width: number) => {
        const book = interest.book
        return (
            <Observer>{() =>
                <TouchableRipple
                    key={interest.book.id}
                    onPress={() => onPress(interest)}
                    onLongPress={() => onPress(interest)}
                    disabled={!props.onToggleSelectedInterest}
                >
                    <View style={{flex: 1}}>
                        <ConceptThumbnail
                            imageUrl={book.image_url}
                            label={book.title}
                            width={width}
                            disabled={false}
                            new={false}/>
                        {interest.isSelected && <View style={{
                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                            justifyContent: 'center',
                            alignItems: 'center', ...StyleSheet.absoluteFillObject
                        }}>
                            <MaterialCommunityIcons name="check-circle" size={64} color={Color.lavaGray}/>
                        </View>}
                    </View>
                </TouchableRipple>
            }
            </Observer>
        )
    }

    return (
        <View style={[styles.container, props.style, {}]}>
            {controller.state == InterestsGridState.LOADING && renderLoading()}
            {controller.state == InterestsGridState.LOADED &&
            <View>
                {isLandscape() ? renderGrid(4, 150) : renderGrid(2, 135)}
            </View>
            }
        </View>
    )
})

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
})