import React, {useContext, useEffect, useState} from 'react'
import {StyleSheet, View, ViewStyle, Text, Image} from "react-native"
import {observer} from "mobx-react"
import {PitchController, PitchState} from "../../controllers/steps/PitchController"
import CurrentAccountContext from "../../../../modules/vizz_account/lib/CurrentAccount"
import {OnboardingController, OnboardingState} from '../../controllers/OnboardingController'
import {reaction} from "mobx"
import {State, TapGestureHandler, TapGestureHandlerStateChangeEvent} from 'react-native-gesture-handler'
import {ConceptThumbnail} from '../../../../app/views/web_navigation/feed/concept_navigator/ConceptThumbnail'
import {isTablet} from '../../../../app/lib/Appearance'
import {FeatureFlagModel} from '../../../vizz_account/models/FeatureFlagModel'

type Props = {
    style?: ViewStyle
    onboardingController: OnboardingController
}

export const Pitch = observer((props: Props) => {
    const currentAccount = useContext(CurrentAccountContext)
    const [controller] = useState<PitchController>(() => new PitchController(currentAccount, props.onboardingController))
    const [imageWidth, setImageWidth] = useState<number>(0)
    const [imageHeight, setImageHeight] = useState<number>(0)

    useEffect(() => {
        const neededReaction = reaction(() => props.onboardingController.state, async(state) => {

            if (currentAccount.personData.pitchOnboardingNeeded) {
                if (state == OnboardingState.PITCH) await controller.initialize()
                if (state == OnboardingState.PITCH) await controller.startStep()
            }
        }, { fireImmediately: true })

        if (isTablet()) {
            setImageWidth(501)
            setImageHeight(360)
        } else {
            setImageWidth(375)
            setImageHeight(269)
        }

        return () => {
            neededReaction()
            controller.uninitialize()
        }
    }, [])

    if (currentAccount.hasFeature(FeatureFlagModel.DAILY_CONTENT))
        return (
            <React.Fragment>
                {props.onboardingController.state == OnboardingState.PITCH && controller.state == PitchState.LOADED &&
                <View style={[{flex: 1, alignItems: 'center', justifyContent: 'center'}, props.style]}>

                    <Text style={[props.onboardingController.styles.messageText]}>Favorite things</Text>

                    <Image style={{width: imageWidth, height: imageHeight}} resizeMode={'contain'} source={require('../../assets/pitch-interests.jpg')} />
                </View>}

                {props.onboardingController.state == OnboardingState.PITCH && controller.state == PitchState.NOTIFICATIONS &&
                <View style={[{flex: 1, alignItems: 'center', justifyContent: 'center'}, props.style]}>

                    <Text style={[props.onboardingController.styles.messageText]}>Friends</Text>

                    <Image style={{width: imageWidth, height: imageHeight}} resizeMode={'contain'} source={require('../../assets/pitch-friends.jpg')} />
                </View>}

                {props.onboardingController.state == OnboardingState.PITCH && controller.state == PitchState.SECRETS &&
                <View style={[{flex: 1, alignItems: 'center', justifyContent: 'center'}, props.style]}>

                    <Text style={[props.onboardingController.styles.messageText]}>Things you're into</Text>

                    <Image style={{width: imageWidth, height: imageHeight}} resizeMode={'contain'} source={require('../../assets/pitch-interests.jpg')} />
                </View>}

                {props.onboardingController.state == OnboardingState.PITCH && controller.state == PitchState.FOURTH &&
                <View style={[{flex: 1, alignItems: 'center', justifyContent: 'center'}, props.style]}>

                    <Text style={[props.onboardingController.styles.messageText]}>Finding friends</Text>

                    <Image style={{width: imageWidth, height: imageHeight}} resizeMode={'contain'} source={require('../../assets/pitch-friends.jpg')} />
                </View>}

                {props.onboardingController.state == OnboardingState.PITCH && controller.state == PitchState.FINAL &&
                <View style={[{flex: 1, alignItems: 'center', justifyContent: 'center'}, props.style]}>

                    <Text style={[props.onboardingController.styles.messageText]}>Organized all the videos</Text>

                    <Image style={{width: imageWidth, height: imageHeight}} resizeMode={'contain'} source={require('../../assets/pitch-interests.jpg')} />
                </View>}

            </React.Fragment>
        )
    else
        return (
            <React.Fragment>
                {props.onboardingController.state == OnboardingState.PITCH && controller.state == PitchState.LOADED &&
                <View style={[{flex: 1, alignItems: 'center', justifyContent: 'center'}, props.style]}>

                    <Text style={[props.onboardingController.styles.messageText]}>Call Roblox friends</Text>

                    <Image style={{width: imageWidth, height: imageHeight}} resizeMode={'contain'} source={require('../../assets/pitch-home.png')} />
                </View>}

                {props.onboardingController.state == OnboardingState.PITCH && controller.state == PitchState.NOTIFICATIONS &&
                <View style={[{flex: 1, alignItems: 'center', justifyContent: 'center'}, props.style]}>

                    <Text style={[props.onboardingController.styles.messageText]}>Notifications when online</Text>

                    <Image style={{width: imageWidth, height: imageHeight}} resizeMode={'contain'} source={require('../../assets/pitch-notifications.png')} />
                </View>}

                {props.onboardingController.state == OnboardingState.PITCH && controller.state == PitchState.SECRETS &&
                <View style={[{flex: 1, alignItems: 'center', justifyContent: 'center'}, props.style]}>

                    <Text style={[props.onboardingController.styles.messageText]}>Game secrets and codes</Text>

                    <Image style={{width: imageWidth, height: imageHeight}} resizeMode={'contain'} source={require('../../assets/pitch-secrets.png')} />
                </View>}

            </React.Fragment>
        )
})

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
})
