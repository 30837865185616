import {observer} from "mobx-react"
import {StyleSheet, TouchableOpacity, View} from "react-native"
import React from "react"
import VideoCall from "./VideoCall"
import HomeController from "../../start/controllers/HomeController"
import {FriendWidgetController} from "../controllers/FriendWidgetController"
import {isTablet} from "../../../app/lib/Appearance"
import {MaterialCommunityIcons} from "@expo/vector-icons"

type Props = {
    friendWidgetController: FriendWidgetController
    homeController: HomeController
}

export const MinimizedVideoCall = observer((props: Props) => {

    return (
        <React.Fragment>
            <View
                style={{
                    position: 'absolute',
                    bottom: 44,
                    right: 44,
                    borderWidth: 1,
                    borderColor: 'rgb(160, 160, 160)',
                    width: isTablet() ? 200 : 100,
                    height: isTablet() ? 150 : 75,
                    zIndex: 1000,
                    backgroundColor: 'black',
                    shadowOpacity: 1,
                    shadowRadius: 30,
                    shadowColor: 'black',
                    shadowOffset: {width: 2, height: 2}
                }}>
                    <View style={{
                        backgroundColor: 'black',
                        shadowOpacity: 1,
                        shadowRadius: 30,
                        shadowColor: 'black',
                        shadowOffset: {width: 2, height: 2},
                        height: '100%',
                        width: '100%',
                    }}>
                        <View style={{
                            backgroundColor: 'black',
                            shadowOpacity: 1,
                            shadowRadius: 30,
                            shadowColor: 'black',
                            shadowOffset: {width: 2, height: 2},
                            height: '100%',
                            width: '100%',
                        }}>
                            <View style={{
                                overflow: 'hidden',
                                height: '100%',
                                width: '100%',
                            }}>
                                <VideoCall friendWidgetController={props.friendWidgetController}
                                        homeController={props.homeController}
                                        mode={'compact'} />
                                <View style={StyleSheet.absoluteFillObject}
                                    onTouchEnd={() => props.friendWidgetController.maximizeActiveCall()}/>
                            </View>
                        </View>
                    </View>
            </View>
            <View style={{
                zIndex: 1001,
                position: 'absolute',
                bottom: 34,
                right: 34,
                width: 44,
                height: 44
            }}>
                <TouchableOpacity style={{
                    flex: 1,
                    borderRadius: 22,
                    backgroundColor: 'red',
                    justifyContent: 'center',
                    alignItems: 'center',
                    shadowOpacity: 1,
                    shadowRadius: 4,
                    shadowColor: 'black',
                    shadowOffset: {width: 1, height: 1}
                }} onPress={() => props.friendWidgetController.leaveMinimizedCall()}>
                    <MaterialCommunityIcons name="phone-hangup" size={24} color="white"/>
                </TouchableOpacity>
            </View>
        </React.Fragment>
    )
})