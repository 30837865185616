import React, {useMemo, useState} from 'react'
import {StyleProp, StyleSheet, Text, View, ViewStyle} from "react-native"
import {ActivityIndicator} from "react-native-paper"
import {observer} from "mobx-react"
import {Color, isPortrait, Style} from "../../../../app/lib/Appearance"
import {State, TapGestureHandler, TapGestureHandlerStateChangeEvent} from "react-native-gesture-handler"
import useTheme from "../../../lava-components/components/hooks/useTheme"

export const WIDGET_DISABLED_OPACITY = isPortrait() ? 0.3 : 0.1

type Props = React.PropsWithChildren<{
    type: 'compact' | 'regular' | 'sheet'
    style?: StyleProp<ViewStyle>
    name?: string
    loading?: boolean
    onQuadTap?: () => void
    disabled?: boolean
}>


export const Widget = observer((props: Props) => {
    const [layout, setLayout] = useState({x: 0, y: 0, width: 0, height: 0})
    const theme = useTheme()

    const onQuadTap = (event: TapGestureHandlerStateChangeEvent) => {
        if (event.nativeEvent.state === State.ACTIVE && props.onQuadTap) {
            props.onQuadTap()
        }
    }

    const outerStyle = () => {
        switch (props.type) {
            case 'regular':
                return styles.outerContainer
            case 'compact':
                return {
                    ...styles.outerContainer,
                    paddingHorizontal: 17,
                    paddingTop: 14,
                    borderColor: 'rgba(234,234,223,0.2)',
                    borderWidth: 1,
                    backgroundColor: Color.lavaGreen,
                }
            case 'sheet':
                return {
                    backgroundColor: Color.lavaGreen,
                    padding: 16
                }
        }

    }

    const styles = useMemo(() => StyleSheet.create({
        outerContainer: {
            borderRadius: theme.roundness.md,
            marginHorizontal: Style.Canvas.marginHorizontal,
        },

        titleContainer: {
            paddingBottom: 7,
        },

        title: {
            fontFamily: Style.Widget.fontFamily,
            color: 'rgba(234,234,223, 1.0)',
        },

        innerContainer: {
            flex: 1,
        },

        loadingOverlay: {
            borderRadius: theme.roundness.md,
            position: 'absolute',
            top: 0,
            left: 0,
            justifyContent: 'center',
            alignItems: 'center',
        }
    }), [theme])

    return (
        <View style={[
            outerStyle(),
            {opacity: props.disabled ? WIDGET_DISABLED_OPACITY : 1},
            props.style ? props.style : {}]}
              onLayout={(event) => setLayout(event.nativeEvent.layout)}>
            {props.name &&
                <View style={styles.titleContainer}>
                    <TapGestureHandler numberOfTaps={4} onHandlerStateChange={onQuadTap}>
                        <Text
                            style={[styles.title, props.type == 'compact' ? {fontSize: 12} : {fontSize: 14}]}>{props.name}</Text>
                    </TapGestureHandler>
                </View>}

            <View style={[styles.innerContainer]}>
                {props.children}
            </View>

            {props.loading &&
                <View style={[styles.loadingOverlay, {width: layout.width, height: layout.height}]}>
                    <ActivityIndicator color={Color.primary} size={40}/>
                </View>}

            {props.disabled &&
                <View style={{flex: 1, ...StyleSheet.absoluteFillObject}}/>}
        </View>
    )
})
