import React, {useContext, useState} from 'react'
import {
    Button,
    Dialog,
    IconButton,
    Modal,
    Paragraph,
    Portal,
    Subheading,
    Title,
    ToggleButton,
    TouchableRipple,
    TextInput
} from 'react-native-paper'
import {observer} from "mobx-react"
import {Image, ScrollView, StyleSheet, View} from "react-native"
import {MaterialIcons} from '@expo/vector-icons'
import {Color} from "../../lib/Appearance"
import EditConceptNode from "./edit_concepts/EditConceptNode"
import {ConceptNode, ConceptStore} from "../../lib/services/ConceptStore"
import SelectSiblingNode from "./edit_concepts/SelectSiblingNode"
import {EditConceptsDimensions} from "./edit_concepts/EditConceptsDimensions"
import CurrentAccountContext from '../../../modules/vizz_account/lib/CurrentAccount'


const EditConceptsScreen = observer(() => {
    const currentAccount = useContext(CurrentAccountContext)
    const [store] = useState(() => new ConceptStore(currentAccount))
    const [pendingDisableNode, setPendingDisableNode] = useState<ConceptNode|undefined>(undefined)
    const [moveNodeIn, setMoveNodeIn] = useState<ConceptNode|undefined>(undefined)
    const [mergeNode, setMergeNode] = useState<ConceptNode|undefined>(undefined)
    const [imageModalNode, setImageModalNode] = useState<ConceptNode|undefined>(undefined)
    const [conceptImageUrl, setConceptImageUrl] = useState<string>("")
    const [showCreateNode, setShowCreateNode] = useState(false)
    const [createNodeParent, setCreateNodeParent] = useState<ConceptNode|undefined>(undefined)
    const [newNodeName, setNewNodeName] = useState<string>('')

    const renderConceptTre = () => {
        let views: any[] = []
        store.concepts.forEach((node) => renderNodes(node, 0, views))
        return views
    }

    const renderNodes = (node: ConceptNode, level: number, views: any[]) => {
        views.push(renderNode(node, level))

        if (node.isExpanded && node.immediate_descendents.length > 0) {
            node.immediate_descendents.forEach((n) => renderNodes(n, level + 1, views))
        }
    }

    const renderNode = (node: ConceptNode, level: number) => {
        const key = `${node.parent ?? ''}_${node.id}`
        return (
            <View key={key}>
                <TouchableRipple onPress={() => {
                    void store.toggleExpanded(node)
                }}>
                    <View style={{
                        height: 60,
                        flexDirection: 'row',
                        alignItems: 'center',
                        marginStart: level * EditConceptsDimensions.defaultIndent
                    }}>
                        <View style={{width: 24}}>
                            <MaterialIcons
                                name={node.isExpanded ? "keyboard-arrow-down" : "keyboard-arrow-right"}
                                size={24} color="black"/>
                        </View>
                        <Subheading
                            style={{
                                marginEnd: 16,
                                color: !node.isVisible ? 'rgba(0, 0, 0, 0.3)' : 'black',
                                textDecorationLine: !node.isVisible ? 'line-through' : 'none'
                            }}>
                            {node.primaryName}
                        </Subheading>

                        <Button onPress={() => {setImageModalNode(node)}}>
                            <Image
                                source={{ uri: node.primaryMediaUrl ?? node.fallbackMediaUrl }}
                                style={{height: 30, width: 40, opacity: 1}}
                            />
                        </Button>

                        {!node.isHiddenChild() &&
                        <IconButton
                            icon={'plus'}
                            color={Color.primary}
                            onPress={() => {
                                setCreateNodeParent(node)
                                setShowCreateNode(true)
                            }
                            }/>
                        }

                        {!node.isHiddenChild() &&
                        <IconButton
                            icon={'pencil'}
                            color={Color.primary}
                            onPress={() => store.setEditingNode(node)}/>
                        }

                        {!node.isHiddenChild() &&
                        <IconButton
                            disabled={level == 0}
                            icon={'arrow-collapse-left'}
                            color={Color.primary}
                            onPress={() => onMoveNodeOut(node)}/>
                        }

                        {!node.isHiddenChild() &&
                        <IconButton
                            icon={'arrow-collapse-right'}
                            color={Color.primary}
                            onPress={() => setMoveNodeIn(node)}/>
                        }

                        {!node.isHiddenChild() &&
                        <IconButton
                            style={{marginEnd: 16}}
                            icon={'merge'}
                            color={Color.primary}
                            onPress={() => setMergeNode(node)}/>
                        }

                        {!node.isHiddenChild() &&
                        <ToggleButton.Row style={{marginEnd: 8}}
                                          onValueChange={value => {
                                              void store.setNodeVisible(node, value == 'visible')
                                          }} value={node.isVisible ? 'visible' : 'hidden'}>
                            <ToggleButton size={20} icon='eye-outline' value="visible"/>
                            <ToggleButton size={20} icon='eye-off-outline' value="hidden"/>
                        </ToggleButton.Row>
                        }

                        {!node.isHiddenChild() &&
                        <IconButton
                            icon={node.isSuggested ? 'star' : 'star-outline'}
                            color={Color.primary}
                            onPress={() => store.toggleSuggested(node)
                            }/>
                        }
                        {!node.isHiddenChild() &&
                        <IconButton
                            icon={node.isPriority ? 'arrow-up-bold' : 'arrow-up-bold-outline'}
                            color={Color.primary}
                            onPress={() => store.togglePriority(node)
                            }/>
                        }
                        {!node.isHiddenChild() &&
                        <IconButton
                            icon={'flag'}
                            color={Color.primary}
                            onPress={() => onFlagDescendants(node)}
                        />
                        }

                        {!node.isHiddenChild() &&
                        <IconButton
                            icon={'delete'}
                            color={'red'}
                            onPress={() => onDisableNode(node)}/>
                        }

                        <Subheading
                            style={{
                                marginEnd: 16,
                                color: !node.isVisible ? 'rgba(0, 0, 0, 0.3)' : 'black',
                                textDecorationLine: !node.isVisible ? 'line-through' : 'none'
                            }}>
                            {node.id}
                        </Subheading>
                    </View>
                </TouchableRipple>
                {store.editingNode == node ? (<EditConceptNode store={store} node={node} level={level}/>) : null}
            </View>
        )
    }

    const onMoveNodeOut = (node: ConceptNode) => {
        const moveToNode = node.parent?.parent ?? undefined
        void store.moveNodeToNode(node, moveToNode)
    }

    const onMoveNodeIn = (node: ConceptNode, toNode: ConceptNode) => {
        void store.moveNodeToNode(node, toNode)
        setMoveNodeIn(undefined)
    }

    const onMergeNode = (node: ConceptNode, toNode: ConceptNode) => {
        void store.mergeNodeIntoNode(node, toNode)
        setMergeNode(undefined)
    }

    const onDisableNode = (node: ConceptNode) => {
        setPendingDisableNode(node)
    }

    const deleteNode = () => {
        const node = pendingDisableNode
        if (node) {
            void store.disableNode(node)
            setPendingDisableNode(undefined)
        }
    }

    const onFlagDescendants = (node: ConceptNode) => {
        void store.flagAllDescendants(node.id)
        alert(`All descendants of ${node.primaryName} have been flagged.`)
    }

    const onCreateNode = async() => {
        if (newNodeName.length == 0) {
            alert('Concept name is required.')
            return
        }

        await store.createConcept(newNodeName, createNodeParent)

        setShowCreateNode(false)
        setNewNodeName('')
        setCreateNodeParent(undefined)
    }

    const renderDialogsPortal = () => {
        return (
            <Portal>
                <Modal style={{justifyContent: 'center', alignItems: 'center'}} visible={showCreateNode}
                       onDismiss={() => {
                           setShowCreateNode(false)
                           setNewNodeName('')
                           setCreateNodeParent(undefined)
                       }}>
                    <View style={{padding: 24, backgroundColor: 'white'}}>
                        <Title style={{marginBottom: 16}}>Add a new concept under: {createNodeParent?.primaryName ?? 'Root'}</Title>
                        <TextInput
                            style={{marginBottom: 16}}
                            mode={'outlined'}
                            placeholder={'New Concept Name'}
                            value={newNodeName}
                            onChangeText={setNewNodeName}
                        />
                        <Button onPress={() => void onCreateNode()}>
                            Create New Concept
                        </Button>
                    </View>
                </Modal>

                <Modal style={{justifyContent: 'center', alignItems: 'center'}} visible={moveNodeIn != undefined}
                       onDismiss={() => setMoveNodeIn(undefined)}>
                    {moveNodeIn ?
                        <SelectSiblingNode
                            title={`Indent "${moveNodeIn.primaryName}" to be under:`}
                            store={store}
                            node={moveNodeIn}
                            includeParent={false}
                            onSelect={(n) => onMoveNodeIn(moveNodeIn, n)}/>
                        : null}
                </Modal>

                <Modal style={{justifyContent: 'center', alignItems: 'center'}} visible={mergeNode != undefined}
                       onDismiss={() => setMergeNode(undefined)}>
                    {mergeNode ?
                        <SelectSiblingNode
                            title={`Merge "${mergeNode.primaryName}" into:`}
                            store={store}
                            node={mergeNode}
                            includeParent={true}
                            onSelect={(n) => onMergeNode(mergeNode, n)}/>
                        : null}
                </Modal>

                <Modal style={{justifyContent: 'center', alignItems: 'center', backgroundColor: Color.black, borderWidth: 1, borderColor: Color.black}} visible={imageModalNode != undefined}
                       onDismiss={() => setImageModalNode(undefined)}>
                    {imageModalNode &&
                    <View>
                        <IconButton
                            onPress={() => setImageModalNode(undefined)}
                            style={{alignSelf: "flex-end"}}
                            icon={'close'}
                            color={Color.primary}/>
                        <TextInput
                            value={imageModalNode.primaryMediaSourceUrl}
                            style={{
                                margin: 10,
                                borderColor: Color.white,
                                borderWidth: 1,
                                padding: 10,
                                color: Color.white
                            }}
                        />
                        <Image
                            source={{uri: imageModalNode.primaryMediaUrl}}
                            style={{height: 195, width: 260}}
                        />
                        <TextInput
                            placeholder="New Image URL"
                            value={conceptImageUrl}
                            style={{
                                margin: 10,
                                borderColor: Color.white,
                                borderWidth: 1,
                                padding: 10,
                                color: Color.white
                            }}
                            onChangeText={setConceptImageUrl}
                        />
                        <Button onPress={() => updateConceptImage()}>
                            Update Concept Image
                        </Button>

                    </View>
                    }
                </Modal>

                <Dialog visible={pendingDisableNode != undefined} onDismiss={() => setPendingDisableNode(undefined)}>
                    <Dialog.Title>DisableNode</Dialog.Title>
                    <Dialog.Content>
                        <Paragraph>{`Are you sure you want to disable ${pendingDisableNode?.primaryName} and all of its children?`}</Paragraph>
                    </Dialog.Content>
                    <Dialog.Actions>
                        <Button onPress={() => setPendingDisableNode(undefined)}>Cancel</Button>
                        <Button onPress={deleteNode}>Disable Node</Button>
                    </Dialog.Actions>
                </Dialog>
            </Portal>

        )
    }

    const updateConceptImage = async() => {
        if (conceptImageUrl.length == 0 || imageModalNode == undefined) {
            return
        }

        const nodeToUpdate = imageModalNode
        console.log(`Setting primary_media for ${nodeToUpdate} to ${conceptImageUrl}`)
        await store.updatePrimaryMedia(nodeToUpdate, conceptImageUrl)
        setImageModalNode(undefined)
    }

    return (
        <View style={{flex: 1, margin: 16}}>
            <View style={{flexDirection: 'row', alignItems: 'center'}}>
                <Title style={{marginEnd: 24}}>Edit Concepts</Title>
                <Button mode={'contained'} compact={true} icon={'plus'} onPress={() => {setShowCreateNode(true)}}>
                    Create Root Concept
                </Button>
            </View>
            <ScrollView>
                {renderConceptTre()}
            </ScrollView>

            {renderDialogsPortal()}
        </View>
    )
})

const styles = StyleSheet.create({
    visibilityButton: {
        fontSize: 12,
        fontWeight: '500'
    }
})

export default EditConceptsScreen