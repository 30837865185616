import {Audio, InterruptionModeIOS} from "expo-av"
import {IReactionDisposer, reaction} from "mobx"
import {AppController} from "../controllers/AppController"
import NativeStateService, {NativeState} from "./NativeStateService"
import {CurrentAccount} from "../../modules/vizz_account/lib/CurrentAccount"
import {ProfileStatusService} from "../../modules/social/services/ProfileStatusService"
import {ManifestUtils} from "../utils/ManifestUtils"
import {AsyncUtils, backgroundSafeClearInterval, backgroundSafeSetInterval} from "../utils/AsyncUtils"
import SentryService from "./SentryService"

class BackgroundRefreshService {
    private debug: boolean = false  // don't set this to true in production

    private currentAccount!: CurrentAccount
    private nativeState!: NativeStateService

    private cleanupAppStateReaction?: IReactionDisposer
    private callbacks: (() => any)[] = []
    private callbackNames: string[] = []
    private silentAudio?: Audio.Sound
    private pollingInterval?: any

    constructor() {
        this.consoleDebug(`new()`)
    }

    public initialize(appController: AppController, currentAccount: CurrentAccount, nativeState: NativeStateService) {
        this.consoleDebug(`initialize()`)

        this.currentAccount = currentAccount
        this.nativeState = nativeState

        this.cleanupAppStateReaction = reaction(() => this.nativeState.state, (state) => {
            if (state == NativeState.FOREGROUND) void this.restartBackgrounding()
        })

        this.addCallback('socialPollCallback', async () => {
            const ignore = await ProfileStatusService.frequentUpdateStatus(this.currentAccount, appController) // does not start working until personData.profileOnboardingNeeded = false
        })

        void this.restartBackgrounding() // the background process is slow to start, don't await it
    }

    public uninitialize() {
        this.consoleDebug(`uninitialize()`)
        if (this.cleanupAppStateReaction) this.cleanupAppStateReaction()
        this.callbacks = []
    }

    public addCallback(name: string, callback: (() => any)) {
        this.consoleDebug(`addCallback(${name})`)

        const index = this.callbackNames.indexOf(name)
        if (index >= 0) {
            this.callbacks[index] = callback
        } else {
            this.callbackNames.push(name)
            this.callbacks.push(callback)
        }
    }

    public async call(name: string) {
        const index = this.callbackNames.indexOf(name)

        if (index >= 0) await this.callbacks[index]()
    }


    // Private methods

    public async restartBackgrounding() {
        this.consoleDebug(`restartBackgrounding()`)

        try {
            if (this.currentAccount) {
                await Audio.setAudioModeAsync({
                    playsInSilentModeIOS: true,
                    allowsRecordingIOS: false,
                    interruptionModeIOS: InterruptionModeIOS.MixWithOthers,
                    staysActiveInBackground: true,
                })

                if (this.silentAudio) await this.silentAudio.unloadAsync()

                const {sound} = await Audio.Sound.createAsync(ManifestUtils.env == 'production' ? require('../assets/snd-silence2.mp3') : require('../assets/snd-silence.mp3'))
                await sound.setIsLoopingAsync(true)

                for (let i = 0; i < 30; i++) {
                    this.consoleDebug(`restartBackgrounding() attempt #${i}`)

                    await sound.playAsync()
                    await AsyncUtils.sleep(100)

                    const status = (await sound.getStatusAsync()) as any
                    if (status.isPlaying) {
                        break
                    }
                }

                this.silentAudio = sound
            } else if (this.silentAudio) {
                await this.silentAudio.unloadAsync()
                this.silentAudio = undefined
            }

            this.restartPolling()
        } catch (error) {
            this.currentAccount.analytics.logEvent('background-refresh-service', 'error-restart-backgrounding', undefined, {
                error: (error as any).message,
                appStateCurrent: this.nativeState.state,
                appStateRaw: this.nativeState.rawState,
            })

            SentryService.captureError(error, {source: 'background-refresh-service'})
        }
    }

    private restartPolling() {
        this.consoleDebug(`restartPolling()`)

        if (this.pollingInterval) backgroundSafeClearInterval(this.pollingInterval)
        const normalPollingInterval = 2500 // milliseconds
        const slowerPollingInterval = 25000 // milliseconds

        this.pollingInterval = backgroundSafeSetInterval(() => {
            this.runCallbacks()
        }, (ManifestUtils.env == "development") ? slowerPollingInterval : normalPollingInterval)
    }

    private runCallbacks() {
        this.consoleDebug(`runCallbacks()`)

        this.callbacks.forEach(async (promise, i) => {
            await promise()
        })
    }

    // Private instance utility methods

    private consoleDebug(method: string, force: boolean = false) {
        if (this.debug || force) console.log(`${this.constructor.name}: ${method}`)
    }
}

export default BackgroundRefreshService
