import React, {useContext, useEffect, useState} from 'react'
import {RouteProp} from "@react-navigation/native"
import {StackNavigationProp} from "@react-navigation/stack"
import {RootStackNavigatorList} from "../../lib/interfaces/root-stack-navigator"
import {Dimensions, ScrollView, StyleSheet, View} from "react-native"
import {Headline, Modal, Portal} from "react-native-paper"
import VizzGridView from "../components/VizzGridView"
import {VizzModel} from "../../../modules/vizz_maker/models/VizzModel"
import FixedAspectView from "../components/FixedAspectView"
import Vizz from "../../../modules/vizz_maker/views/Vizz"
import {reaction} from "mobx"
import CurrentAccountContext from '../../../modules/vizz_account/lib/CurrentAccount'

type CollectionDetailScreenRouteProp = RouteProp<RootStackNavigatorList, 'CollectionDetail'>

type CollectionDetailScreenNavigationProp = StackNavigationProp<
    RootStackNavigatorList,
    'CollectionDetail'>

type Props = {
    route: CollectionDetailScreenRouteProp
    navigation: CollectionDetailScreenNavigationProp
}

const CollectionDetailScreen = (props: Props) => {

    const currentAccount = useContext(CurrentAccountContext)
    const [modalVizz, setModalVizz] = useState<VizzModel|null>(null)

    useEffect(() => {
        const dispose = reaction(() => currentAccount.playingVizzId,
            (playingVizzId) => {
                if (playingVizzId == undefined) {
                    setModalVizz(null)
                }
            })
        return () => {
            dispose()
        }
    }, [])

    useEffect(() => {
        if (modalVizz) {
            window.setTimeout(() => {
                currentAccount.updatePlayingVizz(modalVizz?.id)
            }, 100)
        } else {
            currentAccount.updatePlayingVizz(null)
        }
    }, [modalVizz])

    const onPlayVizz = (vizz: VizzModel) => {
        setModalVizz(vizz)
    }

    return (
        <View style={styles.container}>
            <ScrollView style={{flex: 1}}>
                <Headline style={{margin: 16}}>{props.route.params.collection.title}</Headline>
                <VizzGridView
                    style={{flex: 1}}
                    vizzes={props.route.params.collection.data}
                    onPlayVizz={onPlayVizz}/>
            </ScrollView>

            <Portal>
                <Modal
                    style={{justifyContent: 'center', alignItems: 'center'}}
                    onDismiss={() => {
                        setModalVizz(null)
                    }}
                    visible={modalVizz != null}>
                    <View style={{width: Dimensions.get('window').width * 0.75}}>
                        <FixedAspectView aspectRatio={3 / 4}>
                            {modalVizz && <Vizz vizz={modalVizz} />}
                        </FixedAspectView>
                    </View>
                </Modal>
            </Portal>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1
    }
})

export default CollectionDetailScreen
