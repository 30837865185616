import React, {useEffect, useRef, useState} from 'react'
import {Animated, Easing, Dimensions, ViewStyle} from 'react-native'


type Props = React.PropsWithChildren<{
    top: number
    style?: ViewStyle
    active?: boolean
    duration?: number
    covered?: number
    onMoveToTop?: (() => void)
    onAtTop?: ((top: number) => void)
}>

const VerticalSlide = (props: Props) => {
    const [top, setTop] = useState<number>(props.top)
    const [height, setHeight] = useState<number>(0)
    const topY = useRef(new Animated.Value(Dimensions.get("screen").height))
    const opacity = useRef(new Animated.Value(1))
    const [covered, setCovered] = useState<number>(Math.max(props.covered ?? 0, 0))

    const DEFAULT_MILLISEC_TO_FADE = 500
    const OPACITY_WHEN_COVERED = [1.0, 0.5, 0.3, 0.1, 0.1]
    const duration = () => { return (props.duration) ? props.duration : DEFAULT_MILLISEC_TO_FADE }


    // Logic for triggering covered fade

    useEffect(() => {
        const newCovered = Math.max(props.covered ?? 0, 0)
        if (newCovered != covered) adjustCovered(newCovered)
    }, [props.covered])

    const adjustCovered = (newCovered: number) => {
        Animated.timing(opacity.current, {
            toValue: OPACITY_WHEN_COVERED[newCovered],
            duration: duration(),
            easing: Easing.out(Easing.quad),
            useNativeDriver: true,
        }).start((result) => {
            if (result.finished) {
                setCovered(newCovered)
            }
        })
    }


    // Logic for triggering vertical position change

    useEffect(() => {
        if (props.active == false) setTop(Dimensions.get("screen").height)
    }, [props.active])

    useEffect(() => {
        setTop(props.top)
        if (newHeight() > height) setHeight(newHeight)
    }, [props.top])

    useEffect(() => {
        moveToTop()
    }, [top])

    const newHeight = () => { return Dimensions.get('screen').height - props.top }

    const moveToTop = () => {
        if (props.onMoveToTop) props.onMoveToTop()

        // animate the card up
        Animated.timing(topY.current, {
            toValue: top,
            duration: duration(),
            easing: Easing.out(Easing.quad),
            useNativeDriver: true,
        }).start((result) => {
            if (result.finished) {
                setHeight(newHeight())
                if (props.onAtTop) props.onAtTop(top)
            }
        })
    }



    return (
        <Animated.View
            style={[props.style, {
                margin: 0,
                position: 'absolute',
                width: '100%',
                height: height,
                backgroundColor: 'black',
                transform: [
                    {translateY: topY.current}
                ]
            }]}>
                <Animated.View style={{
                    flex: 1,
                    opacity: opacity.current,
                }}>
                    {props.children}
                </Animated.View>
        </Animated.View>
    )
}

export default VerticalSlide