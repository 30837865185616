
import {StyleSheet, View} from "react-native"
import {FriendDot, FriendDotProps} from "./FriendDot"
import FriendGroup from "./FriendGroup"
import Title from "../../atoms/Title/Title"
import {LavaImage} from "../../atoms/LavaImage/LavaImage"
import {TouchableRipple} from "react-native-paper"
import React from "react"

export type FriendRowProps =  {
    friendCircles: FriendDotProps[]
    inRoblox?: boolean
    activity?: string
    lastSeen?: string
    gameImageUrl?: string
    videoImageUrl?: string
    onPress?: () => void
    onActivityPress?: () => void
    onProfileLongPress?: () => void
    style?:  undefined
}

const renderNames = (names: string[]) => names.join(", ")

export const FriendRow = (props: FriendRowProps) => {
    return(
        <TouchableRipple onPress={props.onPress} style={{marginBottom: 24}}>
            <View  style={[{flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }]}>
                <View style={[{flexDirection: 'row', alignItems: 'center' }]}>
                    <View style={styles.avatarContainer}>
                        { props.friendCircles.length === 1 && <FriendDot {...props.friendCircles[0]} variant="secondary"/> }
                        { props.friendCircles.length !== 1 &&  <FriendGroup friendCircles={props.friendCircles} variation="friends"/> }
                    </View>
                    <View style={styles.textContainer}>
                        <Title style={[styles.text]} variation="subtitle2" numberOfLines={2}>
                            {renderNames(props.friendCircles.map(f => f.username || ""))}
                        </Title>
                            {props.friendCircles.length == 1 &&
                                <View style={{display: 'flex', flexDirection: 'row', marginTop: 2}}>
                                    { (props.friendCircles.findIndex(f => f.status === 'online') > -1 || props.activity) &&
                                        <Title style={styles.text} variation="subtitle2">{props.friendCircles.findIndex(f => f.status === 'online') > -1 && 'Online'}{props.activity && ","} {props.activity}</Title>
                                    }
                                    {props.inRoblox && <LavaImage source={require("./assets/roblox.png")} style={{width:16, height:16}}/>}
                                </View>
                            }
                            { props.lastSeen &&
                                <Title style={styles.text} variation="subtitle2" numberOfLines={2}>
                                    {props.lastSeen}
                                </Title>
                            }
                    </View>
                </View>
                {props.gameImageUrl && <TouchableRipple onPress={props.onActivityPress}>
                    <View style={styles.game}>
                        <LavaImage source={props.gameImageUrl} style={{flex: 1}}/>
                        <LavaImage source={require('../NewGameCard/game-overlay.png')} contentFit={'fill'} style={{flex: 1, ...StyleSheet.absoluteFillObject}} />
                    </View>
                </TouchableRipple>}
                {props.videoImageUrl && <TouchableRipple onPress={props.onActivityPress}>
                    <View>
                        <LavaImage source={props.videoImageUrl} style={styles.video}/>
                        <View style={styles.playContent}>
                            <LavaImage source={require("./assets/video-play.png")} style={{height:24, width:24}}/>
                        </View>
                    </View>
                </TouchableRipple>}
            </View>
        </TouchableRipple>
    )
}

const styles = StyleSheet.create({
    avatarContainer: {
        display: 'flex',
        justifyContent: 'center',
    },
    textContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        marginLeft: 12,
        paddingRight: 20,
    },
    text: {
        color: '#FFFFFF99',

    },
    playContent: {
        ...StyleSheet.absoluteFillObject,
        alignItems: 'center',
        justifyContent: 'center'
    },
    gradient: {
        top:1,
        left:1,
        height: 50,
        width: 50,
        position: 'absolute',
        zIndex: 14,
        borderRadius: 2,
        transform: [{rotate:'90deg'}],
        opacity: 0.9
    },
    game: {
        height:50,
        width:50,
        borderRadius: 2,
        borderWidth: 0.5,
        borderColor: 'rgba(234, 234, 223, 0.2)',
        opacity: 0.9,
        borderStyle: 'solid',
        overflow: 'hidden'
    },
    video: {
        height:42,
        width:56,
        borderRadius: 2,
        borderWidth: 0.5,
        borderColor: 'rgba(234, 234, 223, 0.4)',
        opacity: 0.9,
        borderStyle: 'solid',
    }
})
