import {requestTrackingPermissionsAsync} from "expo-tracking-transparency"
import {action, makeObservable, observable} from "mobx"
import {CurrentAccount} from "../../../vizz_account/lib/CurrentAccount"
import {FeatureFlagModel} from "../../../vizz_account/models/FeatureFlagModel"
import {OnboardingController} from "../OnboardingController"

export enum IDFAState {
    LOADING,
    LOADED
}

export class IDFAController {
    private debug: boolean = false  // don't set this to true in production

    private currentAccount!: CurrentAccount
    public onboardingController!: OnboardingController

    @observable state: IDFAState = IDFAState.LOADING

    constructor(currentAccount: CurrentAccount, onboardingController: OnboardingController) {
        if (!currentAccount.personData.deviceKey) return

        this.consoleDebug(`new()`)

        this.currentAccount = currentAccount
        this.onboardingController = onboardingController

        makeObservable(this)
    }

    public async initialize() {
        this.consoleDebug(`initialize()`)
        if (this.state != IDFAState.LOADING) return

        this.setState(IDFAState.LOADED)
    }

    public uninitialize() {
        this.consoleDebug(`uninitialize()`)
    }


    // Public methods

    public async startStep() {
        this.consoleDebug(`startStep()`)

        if (this.currentAccount.isIOSReviewer) {
            this.currentAccount.analytics.logEvent('idfa-onboarding','started')
            const { status } = await requestTrackingPermissionsAsync()
            this.currentAccount.analytics.logEvent('idfa-onboarding','finished', (status === 'granted' ? 'granted' : 'not-granted'))
        } else {
            this.currentAccount.analytics.logEvent('idfa-onboarding','autoskipped')
        }
        this.nextStep()
    }

    public nextStep() {
        this.consoleDebug(`nextStep()`)
        this.onboardingController.nextStep()
    }


    // Private helper methods

    @action
    private setState(state: IDFAState) {
        this.consoleDebug(`setState()`)
        this.state = state
    }


    // Private instance utility methods

    private consoleDebug(method: string, force: boolean = false) {
        if (this.debug || force) console.log(`${this.constructor.name}: ${method}  state = ${this.state}`)
    }
}
