export class ObjectUtils {

    public static deepCopy<T>(obj: T): T {
        return JSON.parse(JSON.stringify(obj))
    }

}

export const pp = (obj: any) => {
    console.log(JSON.stringify(obj, null, 4))
}

export const pretty = (obj: any) => {
    return JSON.stringify(obj, null, 4)
}