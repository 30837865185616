import React, {useContext, useEffect, useState} from 'react'
import {StyleSheet, View, ViewStyle, Text, ScrollView, Linking} from "react-native"
import {observer} from "mobx-react"
import {AudioController, AudioState} from "../../controllers/steps/AudioController"
import CurrentAccountContext from "../../../vizz_account/lib/CurrentAccount"
import {OnboardingController, OnboardingState} from '../../controllers/OnboardingController'
import {PrimaryButton} from '../../../../app/views/components/PrimaryButton'
import {reaction} from "mobx"
import {ManifestUtils} from '../../../../app/utils/ManifestUtils'
import {useSafeAreaInsets} from 'react-native-safe-area-context'
import {DebugAutoUpdate} from '../../../../app/views/debug/DebugAutoUpdate'
import {State, TapGestureHandler, TapGestureHandlerStateChangeEvent} from 'react-native-gesture-handler'
import {isTablet} from '../../../../app/lib/Appearance'
import {Button} from 'react-native-paper'

type Props = {
    style?: ViewStyle
    onboardingController: OnboardingController
}

export const Audio = observer((props: Props) => {
    const currentAccount = useContext(CurrentAccountContext)
    const [controller] = useState<AudioController>(() => new AudioController(currentAccount, props.onboardingController))
    const [showDebug, setShowDebug] = useState<boolean>(false)
    const safeAreaInsets = useSafeAreaInsets()

    useEffect(() => {
        const neededReaction = reaction(() => props.onboardingController.state, async(state) => {

            if (currentAccount.personData.audioOnboardingNeeded) {
                await controller.initialize()
                if (state == OnboardingState.AUDIO) await controller.startStep()
            }

        }, { fireImmediately: true })

        return () => {
            neededReaction()
            controller.uninitialize()
        }
    }, [])

    return (
        props.onboardingController.state == OnboardingState.AUDIO && controller.state == AudioState.LOADED ?
        <View style={[{flex: 1, alignItems: 'center', justifyContent: 'center'}, props.style]}>
            {ManifestUtils.env != 'production' && showDebug &&
            <ScrollView style={{flex: 1, position: 'absolute', top: safeAreaInsets.top, height: 130, width: '100%', borderWidth: 1, borderColor: 'black', zIndex: 1000}} contentContainerStyle={{}}>
                <DebugAutoUpdate />
            </ScrollView>}

            <View style={{}}>
                <Text style={props.onboardingController.styles.messageText}>Sound check,{"\n"}can you hear this?</Text>
                <View style={{marginTop: 10, alignItems: 'center', justifyContent: 'center', flexDirection: 'row'}}>
                    <PrimaryButton
                        style={{margin: 8}}
                        onPress={() => controller.replayAudio()}
                        iconType={'Foundation'}
                        icon={'refresh'}
                        iconSide={'right'}
                        fixedState={'enabled'}
                        title={'Play Again'}/>

                    <PrimaryButton
                        style={{margin: 8}}
                        onPress={() => controller.nextStep()}
                        iconType={'FontAwesome'}
                        icon={'thumbs-up'}
                        iconSide={'right'} />
                </View>

                <View style={{marginTop: 30, alignItems: 'center', justifyContent: 'center', flexDirection: 'row'}}>
                    <Button
                        onPress={() => controller.nextStep()}
                        mode={'text'}
                        color={'#FFFFFF80'}
                    >Log in</Button>

                </View>
            </View>

            <View style={{position: 'absolute', bottom: 0, marginBottom: 10, flexDirection: isTablet() ? 'row' : 'row'}}>
                <Text
                    style={{color: "gray", marginHorizontal: 30}}
                    onPress={() => {
                        void Linking.openURL('https://www.explanation.com/app-privacy');
                    }}>
                    Privacy Policy
                </Text>
                <Text style={{color: 'rgba(30,30,30,1)', marginHorizontal: 10}}>
                    v{ManifestUtils.clientUpdateNumber}
                </Text>
                <Text
                    style={{color: "gray", marginHorizontal: 30}}
                    onPress={() => {
                        void Linking.openURL('https://www.explanation.com/app-terms');
                    }}>
                    Terms of Service
                </Text>
            </View>
        </View> : null
    )
})

const styles = StyleSheet.create({
})
