import PersonModel from "../../vizz_account/models/PersonModel"
import {PresenceModel} from "./PresenceModel"
import {ProfileStatus} from "./ProfileStatus"
import {ProfileStatusDetail} from "./ProfileStatusDetail"
import {RobloxFriendModel} from "./RobloxFriendModel"

export interface ProfileModel {
    id: number
    person_id: number
    person_key: string
    username: string
    nickname: string
    first_name: string | null
    avatar_media_id?: number
    avatar_image_url?: string
    created_at: string
    updated_at: string
    pretty_username: string
    person?: PersonModel
    profile_type?: ProfileType
    chat_token?: string
    roblox_profile?: RobloxFriendModel
    story_type?: 'Profile'


    // TODO: We should remove all of these fields. They don't exist on the backend but we still have some
    // places on the front-end where legacy code is creating ProfileModel's and using these fields.
    last_changed?: string
    last_changed_detail?: string
    status: ProfileStatus
    status_detail?: ProfileStatusDetail
}

export enum ProfileType {
    LAVA,
    ROBLOX,
    PARENT
}

export class ProfileModelExtensions {
    public static prettyUsername(model: ProfileModel): string {
        if (model.pretty_username && model.pretty_username.length > 0) {
            return  model.pretty_username
        }
        return model.username.replace('_', ' ')
    }

    public static joinRobloxGameUrl(presence?: PresenceModel) {
        if (!presence) return

        const friendRobloxUserId = presence.roblox_user_id
        if (!friendRobloxUserId) return

        const uuid = ProfileModelExtensions.randomUUID()
        const url = `https://www.roblox.com/games/start?userID=${friendRobloxUserId}&joinAttemptId=${uuid}&joinAttemptOrigin=JoinUser`

        return url
    }

    public static randomUUID() {
        // Generate random UUID
        let uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
            let r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8)
            return v.toString(16)
        })

        return uuid
    }
}
