import {ConceptNodeResponse} from "../../../app/lib/models/Concept"

export enum VizzEndBehavior {
    STOP = 'stop',
    LOOP = 'loop'
}

export interface VizzModel {
    id: string
    title: string
    loadingMessage?: string
    loadingMessageUrl?: string
    loadingMessageDuration?: number
    slides: VizzSlideModel[]
    slidesCount: number
    endBehavior: VizzEndBehavior
    primaryColor: string
    primaryDarkColor: string
    foregroundColor: string
    doneProcessing: boolean
    canStartPlaying: boolean

    coverImageUrl?: string

    seoImageUrl?: string
    audioUrl?: string
    audioDuration?: number
    audioBackgroundUrl?: string

    voiceEngine: string
    voice: string
    firstConcept?: ConceptNodeResponse
}

export class VizzHelpers {

    static fullScript(vizz: VizzModel): string {
        let parts: string[] = []
        vizz.slides.forEach((s) => {
            s.media.forEach((m) => parts.push(m.text ?? ''))
        })
        return parts.join(' ')
    }

}

export enum AssetType {
    Image = "image",
    Video = "video"
}

export enum SlideEndBehavior {
    HOLD = 'hold',
    LOOP = 'loop',
    ADVANCE = 'advance'
}

export enum SlideZoom {
    IN = 'in',
    OUT = 'out'
}

export enum SlidePan {
    UP = 'up',
    DOWN = 'down',
    LEFT = 'left',
    RIGHT = 'right'
}

export interface VizzSlideModel {
    id: string
    zoom?: SlideZoom
    panVertical?: SlidePan
    panHorizontal?: SlidePan
    slideEndBehavior: SlideEndBehavior
    duration: number
    audioDuration: number
    audioUrl?: string
    media: VizzMedia[]
}

export interface VizzMedia {
    assetUrl: string,
    videoAudioEnabled?: boolean,
    assetType: AssetType,
    text?: string
    duration: number
    audioDuration?: number
    videoDuration?: number
    videoPosterUrl?: string
}

// TODO: HACK - For collections prototype, remove post-haste
export class VizzCollection {

    public id: string
    public title: string
    public data: VizzModel[]

    constructor(id: string, title: string, vizzes: VizzModel[]) {
        this.id = id
        this.title = title
        this.data = vizzes
    }

    static idToTitle: any = {
        'CANDY': 'Candy',
        'CONSTRUCTION': 'Construction Vehicles',
        'BABY': 'Baby Animals',
        'PLANTS': 'Poisonous Plants',
        'PAW': 'Paw Patrol',
        'PLANETS': 'Planets',
        'BIRDS': 'Birds',
        'PRINCESSES': 'Princesses',
        'OUTSIDE': 'Outside Games',
        'CAMOUFLAGE': 'Animal Camouflage',
        'TRUCKS': 'Trucks',
        'UNICORNS': 'Unicorns',
        'PLANES': 'Planes',
        'CATS': 'Cats',
        'FARM': 'Farm Animals',
        'DOLLS': 'American Girl Dolls',
        'CRYSTALS': 'Crystals',
        'FLAGS': 'Flags',
        'FRUIT': 'Fruit',
        'BLIPPY': 'Blippy',
        'DINOSAURS': 'Dinosaurs',
        'MICKEY': 'Mickey Mouse and Friends',
        'SUPERHEROES': 'Superheroes',
        'BASKETBALL': 'Basketball Players',
        'DOGS': 'Dogs',
    }

    public static fromVizes(vizzesIn: VizzModel[]): VizzCollection[] {
        let idToVizzes: any = {}

        let vizzes = vizzesIn.map(x => Object.assign({}, x));

        vizzes
            .filter((v) => v.title.includes(':'))
            .forEach((v) => {
                const parts = v.title.split(':')
                const id = parts[0].trim()
                v.title = parts[1].trim()

                let vizzesArr = idToVizzes[id]
                if (vizzesArr) {
                    vizzesArr.push(v)
                } else {
                    const newArr = [v]
                    idToVizzes[id] = newArr
                }
            })

        return Object.keys(idToVizzes)
            .sort()
            .map((k) => {
            const title = VizzCollection.idToTitle[k] ?? 'Collection'
            const vizzes = idToVizzes[k]
            return new VizzCollection(k, title, vizzes)
        })
    }

    public static collectionCover(collection: VizzCollection): string {
        const matches = collection.data.filter((v) => v.title.includes('??'))
        if (matches.length > 0) {
            return matches[0]?.coverImageUrl ?? ''
        } else {
            return collection.data[0]?.coverImageUrl ?? ''
        }
    }
}

// TODO: Remove this when we have real concepts/topics on the back end.
export const VizzCollectionTitleFix = (vizz: VizzModel) => {
    if (vizz.title.includes(':')) {
        const parts = vizz.title.split(':')
        vizz.title = parts[1]
    }
    return vizz
}