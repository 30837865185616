import {CurrentAccount} from "../../../vizz_account/lib/CurrentAccount"
import {FriendWidgetController, FriendWidgetState} from "../FriendWidgetController"
import * as ImagePicker from 'expo-image-picker'

export class SignupController {
    private debug: boolean = false  // don't set this to true in production

    private currentAccount: CurrentAccount
    public friendWidgetController: FriendWidgetController

    constructor(currentAccount: CurrentAccount, friendWidgetController: FriendWidgetController) {
        this.consoleDebug(`new()`)

        this.currentAccount = currentAccount
        this.friendWidgetController = friendWidgetController
    }

    public initialize() {
        this.consoleDebug(`initialize()`)
    }

    async createAccount(nickname: string, avatarImageUrl?: string) {
        this.consoleDebug(`createAccount()`)

        if (nickname.trim().length == 0) {
            let message = "Lava name cannot be blank."
            this.currentAccount.analytics.logEvent('social-profile', 'error', message, { message: message })
            return message
        }

        let data:any = {
            nickname: nickname
        }

        let response
        let message
        try {
            response = await this.currentAccount.api.patch('social.profile_update_path', {
                nickname: nickname,
            })
            message = response?.message
        } catch(e) {
            message = 'Unexpected error'
        }

        if (response?.success) {
            this.currentAccount.analytics.logEvent('social-profile', 'finished', nickname, response)
            this.currentAccount.personData.profileOnboardingNeeded = false
            this.friendWidgetController.setState(FriendWidgetState.LOADING)
        } else {
            this.currentAccount.analytics.logEvent('social-profile', 'error', message, { message: message })
        }

        return message
    }

    public async pickImageUrl() {
        this.consoleDebug(`pickImageUrl()`)

        let result = await ImagePicker.launchImageLibraryAsync({
            mediaTypes: ImagePicker.MediaTypeOptions.Images,
            allowsEditing: true,
            quality: 1,
        });

        if (!result.canceled) {
            return result.assets.at(0)?.uri
        } else {
            return
        }
    }

    public async takePhotoUrl() {
        this.consoleDebug(`takePhotoUrl()`)

        const permResult = await ImagePicker.requestCameraPermissionsAsync()
        if (!permResult.granted) return

        let result = await ImagePicker.launchCameraAsync({
            mediaTypes: ImagePicker.MediaTypeOptions.Images,
            allowsEditing: true,
            quality: 1,
        });

        if (!result.canceled) {
            return result.assets.at(0)?.uri
        } else {
            return
        }
    }

    // Private instance utility methods

    private consoleDebug(method: string, force: boolean = false) {
        if(this.debug || force) console.log(`${this.constructor.name}: ${method}`)
    }
}