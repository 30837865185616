import React, {useContext, useEffect, useState} from 'react'
import {observer} from "mobx-react"
import CurrentAccountContext from '../../../vizz_account/lib/CurrentAccount'
import {reaction} from 'mobx'
import {GameBookModel} from "../../models/GameBookModel"
import GameBookController from "../../controllers/roblox_games_widget/GameBookController"
import HomeController from "../../../start/controllers/HomeController"
import PagesLandscape from "../library_widget/book/PagesLandscape"
import {View} from "react-native"
import {RobloxGameDetailSidePanelState} from './RobloxGameDetailConstants'
import PageModel from "../../models/PageModel"

type Props = {
    parentBook: GameBookModel
    book: GameBookModel
    pageIndex: number
    homeController: HomeController
    showRelated: boolean
    sidePanelState: RobloxGameDetailSidePanelState
    onPageChanged: (page: PageModel) => void
}

const GameBook = observer((props: Props) => {
    const currentAccount = useContext(CurrentAccountContext)
    const [bookController] = useState<GameBookController>(() => new GameBookController(currentAccount, props.homeController, props.parentBook, props.book, props.pageIndex, props.showRelated))

    useEffect(() => {
        void bookController.initialize()

        const cleanupBookChange = reaction(() => bookController.gameBook, () => {
            void bookController.initialize()
        })

        const cleanUpPageChange = reaction(() => bookController.currentPageIndex, (index) => {
            const book = bookController.book
            if (index && book && book.pages) {
                props.onPageChanged(book.pages[index])
            }
        })

        return () => {
            cleanupBookChange()
            void bookController.uninitialize()
            cleanUpPageChange()
        }
    }, [])

    return (
        <View style={{flex: 1}}>
            <PagesLandscape
                bookController={bookController}
                searchBarHeight={0}
                covered={false}
                sidePanelState={props.sidePanelState}
            />
        </View>
    )
})

export default GameBook