import React, {useState} from "react"
import {ImageSourcePropType, StyleSheet, View} from "react-native"
import {isPortrait, isTablet} from "../../../../app/lib/Appearance"
import {LavaImage} from "../../../../app/views/components/LavaImage"

type Props = {
    tableImage?: ImageSourcePropType
    mobileImage?: ImageSourcePropType
}

const Background = (props: Props) => {
    const [layout, setLayout] = useState({x: 0, y: 0, width: 0, height: 0})

    return (
        <View style={{flex: 1, zIndex: -10, backgroundColor: 'black', ...StyleSheet.absoluteFillObject}}
              onLayout={(e) => setLayout(e.nativeEvent.layout)}>
            {isTablet() &&
            <LavaImage style={{width: layout.width, height: layout.height, backgroundColor: 'black'}} resizeMode={'cover'}
                   source={props.tableImage ?? require('../../assets/bg-home-tablet.png')}/>}
            {isPortrait() &&
            <LavaImage style={{width: layout.width, height: layout.height, backgroundColor: 'black'}} resizeMode={'cover'}
                   source={props.mobileImage ?? require('../../assets/bg-home-phone.png')}/>}
        </View>
    )
}

export default Background