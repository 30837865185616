import React, {useContext, useEffect, useState} from 'react'
import {StyleSheet, View, StyleProp, ViewStyle, Text, FlatList, ListRenderItemInfo, SafeAreaView} from "react-native"
import {ActivityIndicator, TouchableRipple} from "react-native-paper"
import {observer} from "mobx-react"
import {ModeratorMenuController, ModeratorMenuState} from "../controllers/ModeratorMenuController"
import {Color} from "../../../app/lib/Appearance"
import CurrentAccountContext from "../../../modules/vizz_account/lib/CurrentAccount"
import AppControllerContext from '../../../app/controllers/AppController'
import {GameBookModel} from '../../browse/models/GameBookModel'
import HomeController from '../controllers/HomeController'

type Props = {
    style?: StyleProp<ViewStyle>
    homeController: HomeController
}

export const ModeratorMenu = observer((props: Props) => {
    const currentAccount = useContext(CurrentAccountContext)
    const appController = useContext(AppControllerContext)
    const [controller] = useState<ModeratorMenuController>(() => new ModeratorMenuController(currentAccount, appController))

    useEffect(() => {
        void controller.initialize()

        return () => {
            controller.uninitialize()
        }
    }, [])

    const renderLoading = () => {
        return (
            <View style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}>
                <ActivityIndicator color={Color.primary} />
            </View>
        )
    }

    const renderListItem = (info: ListRenderItemInfo<GameBookModel>) => {
        const gameBook: GameBookModel = info.item

        return (
            <TouchableRipple onPress={() => { props.homeController.setModeratorMenuVisible(false); props.homeController.openBook(gameBook, 'moderator-menu', 0)}}>
                <View style={{borderWidth: 1, backgroundColor: 'grey', padding: 5, margin: 5, width: '100%'}}>
                    <Text style={{color: 'black'}}>{gameBook.title}</Text>
                </View>
            </TouchableRipple>
        )
    }

    return (
        <SafeAreaView style={[styles.container, props.style]}>
            {controller.state == ModeratorMenuState.LOADING && renderLoading()}
            {controller.state == ModeratorMenuState.LOADED && controller.gameBooks &&
            <FlatList
                style={{width: '100%', marginTop: 80}}
                data={controller.gameBooks}
                viewabilityConfig={{waitForInteraction: true, itemVisiblePercentThreshold: 75}}
                renderItem={renderListItem}
                keyExtractor={item => (item.id ?? '').toString()}
            />

            }
        </SafeAreaView>
    )
})

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: 'black',
        justifyContent: 'center',
        alignItems: 'center', ...StyleSheet.absoluteFillObject
    },
})
