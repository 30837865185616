import React, {useContext, useEffect, useRef, useState} from "react"
import {Dimensions, ListRenderItemInfo, ScrollView, View} from "react-native"
import PageModel from "../../../../models/PageModel"
import {Video} from "../../../Video"

import {TouchableRipple} from "react-native-paper"
import CurrentAccountContext from "../../../../../vizz_account/lib/CurrentAccount"
import BookController from "../../../../controllers/library_widget/BookController"
import {isTablet, Style} from "../../../../../../app/lib/Appearance"
import {PageController, PageState} from "../../../../controllers/library_widget/book/pages/PageController"
import {observer} from "mobx-react"
import {ActivityName, TimedActivity} from "../../../../../../app/lib/services/TimedActivity"
import GameBookController from "../../../../controllers/roblox_games_widget/GameBookController"
import {ProfileModel} from "../../../../../social/models/ProfileModel"
import {SpeechUtils} from "../../../../../../app/utils/AVUtils"
import {LavaAlert} from "../../../../../../app/views/components/LavaAlert"
import {RobloxGameDetailSidePanelState} from "../../../roblox_games_widget/RobloxGameDetailConstants"
import VideoCard from "../../../../../lava-components/components/molecules/VideoCard/VideoCard"
import {FeatureFlagModel} from "../../../../../vizz_account/models/FeatureFlagModel"

type Props = {
    bookController: BookController | GameBookController
    pageItem: ListRenderItemInfo<PageModel>
    width: number
    rightMargin: number
    focused: boolean
    nearlyFocused: boolean
    autoPlayOnFocus: boolean
    scrollToThisPageCallback: () => void
    scrollToNextPageCallback: () => void
    activeIndex?: number
    sidePanelState: RobloxGameDetailSidePanelState
}

const Page = observer((props: Props) => {
    const currentAccount = useContext(CurrentAccountContext)
    const [controller] = useState<PageController>(() => new PageController(currentAccount, props.bookController, props.pageItem.item.video, props.pageItem.index))
    const [speech] = useState(() => new SpeechUtils(currentAccount, true))

    const videoRef = useRef<any>(null)

    const [layout, setLayout] = useState({x: 0, y: 0, width: 0, height: 0})
    const [interactable, setInteractable] = useState<boolean>(props.focused)

    const pageWidth = props.width - props.rightMargin
    const reliableFocused = useRef<boolean>(false)

    useEffect(() => {
        return () => {
            controller.uninitialize()
        }
    }, [])

    useEffect(() => {
        if (videoRef.current && props.sidePanelState === RobloxGameDetailSidePanelState.EXPANDED) {
            videoRef.current.autoPause()
        }
    }, [props.sidePanelState, videoRef.current])

    useEffect(() => {
        setInteractable(props.focused)

        if (props.focused && props.autoPlayOnFocus && videoRef.current) {
            TimedActivity.log(ActivityName.PLAY_VIDEO, 'video-autoplay')
            videoRef.current.autoPlay()
            reliableFocused.current = true
            void controller.initialize()
        }
        if (!props.focused && videoRef.current) {
            reliableFocused.current = false
            videoRef.current.autoPause()
        }
    }, [props.focused])

    const scrollTapped = () => {
        setInteractable(true)
        props.scrollToThisPageCallback()
    }

    const relatedHeight = () => {
        return (Dimensions.get('screen').height * (isTablet() ? Style.Page.suggestedVideosHighTabletPerct : Style.Page.suggestedVideosHighMobilePerct))
    }

    const renderRelatedVideosLoading = () => {
        return (
            <View style={{height: (Dimensions.get('screen').height * Style.Page.suggestedVideosHighTabletPerct), justifyContent: 'center', alignItems: 'center'}}>
            </View>
        )
    }

    const shouldRenderVideo = () => {
        return props.focused || props.nearlyFocused
    }

    // TODO: Move this into a controller, but we have two controller types
    // so we need to figure out where to put it once this is an API call
    const onShareTapped = async (friend: ProfileModel) => {
        if (currentAccount.personData.sharingOnboardingNeeded) {
            LavaAlert.show(
                `You tapped to send this video to ${friend.first_name}. Would you like to send it?`,
                [
                    {
                        text: 'Cancel', onPress: () => {
                            currentAccount.analytics.logEvent('video-sharing', 'modal', 'Cancel')
                        }
                    },
                    {
                        text: 'OK', icon: 'thumbs-up', onPress: () => {
                            currentAccount.personData.sharingOnboardingNeeded = false
                            void share(friend)
                            currentAccount.analytics.logEvent('video-sharing', 'modal', 'OK')
                        }
                    }
                ])
            return
        }

        await share(friend)
    }

    const share = async (friend: ProfileModel) => {
        if (!props.pageItem.item.video.youtube_key) return

        videoRef.current.autoPause()

        const url = `https://www.youtube.com/watch?v=${props.pageItem.item.video.youtube_key}`
        await props.bookController.shareVideo(friend.person_key, url)
        await speech.speakAndWait('The video has been shared')
        if (reliableFocused.current) videoRef.current.autoPlay()

        currentAccount.analytics.logEvent('video-sharing', 'shared', friend.pretty_username, {friend: friend, video: props.pageItem.item.video})
    }

    const remove = (id: number) => {
        console.log(`### block ${id}`)
        LavaAlert.showModel({
            title: 'Remove this video?',
            buttons: [
                {
                    text: 'Cancel',
                    variation: 'secondary'
                },
                {
                    text: 'Yes, Remove',
                    onPress: () => {

                    }
                }
            ]
        })
    }

    const verify = (id: number) => {
        if (!currentAccount.personData.person || !currentAccount.profile) return

        const person = currentAccount.personData.person
        const profile = currentAccount.profile
        const verifiedAt = new Date()

        LavaAlert.showModel({
            title: 'Verify this video?',
            buttons: [
                {
                    text: 'Cancel',
                    variation: 'secondary'
                },
                {
                    text: 'Yes, Verify',
                    onPress: async() => {
                        try {
                            await currentAccount.api.patch(`browse.video_path(${id})`, { video: {
                                verified_at: new Date(),
                                verified_by_person_id: person.id,
                            }});

                            (props.bookController as GameBookController).setBookPageVerified(
                                id,
                                person,
                                profile,
                                verifiedAt.toISOString(),
                            )
                        } catch(e) {

                        }
                    }
                }
            ]
        })
    }

    return (
        <View style={{
            flex: 1,
            marginRight: props.rightMargin,
            width: pageWidth,
            justifyContent: 'flex-start',
        }} onLayout={(e) => setLayout(e.nativeEvent.layout)}>

            {shouldRenderVideo() ?
                <Video
                    ref={videoRef}
                    video={props.pageItem.item.video}
                    friendsForSharing={props.bookController.friendsForSharing}
                    onTappedFriend={onShareTapped}
                    autoPlayable={true}
                    preRollSource={ ((props.pageItem.item as any).verified && !currentAccount.hasFeature(FeatureFlagModel.MODERATION)) ? require('../../../../assets/mov-verifiedsecrets-splash.mp4') : undefined}
                    onVerify={() => verify(props.pageItem.item.video.id)}
                    onRemove={() => remove(props.pageItem.item.video.id)}
                />
                : <View style={{backgroundColor: 'black'}} />}

            {!interactable &&
                <TouchableRipple
                    onPress={scrollTapped}
                    onLongPress={scrollTapped}
                    style={{position: 'absolute', top: 0, width: '100%', height: '100%'}}
                ><View /></TouchableRipple>}

            {controller.state == PageState.RELATED_LOADING && renderRelatedVideosLoading()}
            {controller.state == PageState.RELATED_LOADED && interactable &&
                <View style={{flexDirection: 'row', marginTop: 10, height: '100%'}}>
                    <ScrollView style={{flex: 1}} horizontal={true} showsHorizontalScrollIndicator={false}>
                        {controller.relatedVideoList.videos.map((video, index) => {
                            return (
                                <VideoCard
                                    key={`video-${index}`}
                                    style={{marginRight: 28}}
                                    videoComponent={video && <Video
                                        video={video}
                                    />}
                                    onPress={async () => {
                                        controller.addVideo(video, 'related-videos')
                                        controller.relatedVideoList.suggestVideosAfter(video) // not async, we don't want to block on this
                                        props.scrollToNextPageCallback()
                                    }}
                                    variation={'related'}
                                    showPlayButton={false}
                                    title={video.title}
                                    thumbnailUrl={video.thumbnail_url ?? ''}
                                    isVerified={false}
                                    creatorName={video.channel_title}
                                    duration={video.duration_number}
                                    views={video.view_count}
                                    uploadedOn={video.published_at}
                                    playable={false}
                                />
                            )
                        })}
                    </ScrollView>
                </View>
            }
        </View>
    )
})

export default Page
