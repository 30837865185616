import {FriendWidgetController} from "../controllers/FriendWidgetController"
import {observer} from "mobx-react"
import HomeController from "../../start/controllers/HomeController"

type Props = {
    friendWidgetController: FriendWidgetController
    homeController: HomeController
    mode: 'full' | 'compact'
}

const VideoCall = observer((props: Props) => {
    return null
})

export default VideoCall