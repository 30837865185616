import {action, computed, IReactionDisposer, makeObservable, observable} from "mobx"
import {GameBookModel} from "../../models/GameBookModel"
import {CurrentAccount} from "../../../vizz_account/lib/CurrentAccount"
import HomeController from "../../../start/controllers/HomeController"
import VideoModel from "../../models/VideoModel"
import {Linking} from "react-native"
import SentryService from "../../../../app/services/SentryService"
import {RobloxGameDetailState} from "../../views/roblox_games_widget/RobloxGameDetailState"
import {ProfileModelExtensions} from "../../../social/models/ProfileModel"
import {PresenceModel} from "../../../social/models/PresenceModel"

export class RobloxGameDetailController {
    private debug: boolean = false  // don't set this to true in production

    private currentAccount: CurrentAccount
    public homeController: HomeController
    public stateReaction?: IReactionDisposer
    private openTrigger?: string

    @observable parentBook: GameBookModel; @action setParentBook(book: GameBookModel) {this.parentBook = book}
    @observable activeSecretsBook?: GameBookModel; @action setActiveSecretsBook(book?: GameBookModel) {this.activeSecretsBook = book}

    @observable trailerVideo?: VideoModel
    @action setTrailerVideo(video?: VideoModel) {this.trailerVideo = video}

    @observable state: RobloxGameDetailState = RobloxGameDetailState.LOADING
    @action setState(state: RobloxGameDetailState) {this.state = state}

    constructor(currentAccount: CurrentAccount, homeController: HomeController, parentBook: GameBookModel, openTrigger?: string) {
        this.currentAccount = currentAccount
        this.homeController = homeController
        this.parentBook = parentBook
        this.openTrigger = openTrigger
        makeObservable(this)
    }

    public async initialize() {
        try {
            this.logEvent('game-details', 'opened', this.parentBook.title)
            let updatedBook = await this.currentAccount.api.get(`browse.game_book_path(${this.parentBook.id})`, {
                first_video_id: this.parentBook.first_page_video_id,
            }) as GameBookModel

            // Fetch codes chapter
            updatedBook = JSON.parse(JSON.stringify(updatedBook)) // copy this object so we can modify it
            for (let chapter of updatedBook.chapters ?? []) {
                if (chapter.title.includes('Code')) {
                    chapter.book = await this.currentAccount.api.get(`browse.game_book_path(${chapter.book_id})`) as GameBookModel
                    break
                }
            }

            this.setParentBook(updatedBook)
            this.setState(RobloxGameDetailState.LOADED)
        } catch (error) {
            SentryService.captureError(error)
        }
    }

    public uninitialize() {
        this.homeController.appController.video.stopPlayingVideo()
        if (this.stateReaction) this.stateReaction()
    }

    public logEvent(thing: string, happened: string, note?: string | undefined, props?: any) {
        if (this.currentAccount) this.currentAccount.analytics.logEvent(thing, happened, note, {
            parent_book_id: this.parentBook.id,
            parent_book_title: this.parentBook.title,
            trigger: this.openTrigger,
            ...props
        })
    }


    // GAME

    @computed
    get previewVideo(): VideoModel | undefined {
        if (this.parentBook.pages && this.parentBook.pages.length > 0 && this.parentBook.pages[0].video) {
            return this.parentBook.pages[0].video
        } else {
            return undefined
        }
    }

    @computed
    get gameDescription() {
        return this.parentBook.roblox_universe?.description
    }

    public async playGame() {
        if (!this.parentBook.roblox_universe) return

        const uuid = ProfileModelExtensions.randomUUID()
        const url = `https://www.roblox.com/games/start?placeId=${this.parentBook.roblox_universe.root_roblox_place_id}&joinAttemptId=${uuid}&af_deeplink=true&af_dp=https%3A%2F%2Fwww.roblox.com%2Fgames%2Fstart%3Fplaceid%3D${this.parentBook.roblox_universe.root_roblox_place_id}%26joinAttemptId%3D${uuid}&campaign=None&is_retargeting=true&media_source=share`

        try {
            await Linking.openURL(url)
        } catch (e) {
            console.debug("There was an error navigating to the Roblox game", e)
        }
    }

    // SECRETS

    @computed
    get secretsBook() {
        const matches = (this.parentBook.chapters ?? []).filter((c) => c.title.includes('Secret'))
        if (matches.length > 0) {
            return matches[0].book
        } else {
            return null
        }
    }

    @computed
    get verifiedSecretsBook(): GameBookModel | undefined {
        if (!this.secretsBook) return undefined
        const matches = (this.secretsBook.chapters ?? []).filter((c) => c.title.includes('Verified'))
        if (matches.length > 0 && matches[0].book) {
            return matches[0].book as GameBookModel
        } else {
            return undefined
        }
    }

    @computed
    get unverifiedSecretsBook(): GameBookModel | undefined {
        if (!this.secretsBook) return undefined
        const matches = (this.secretsBook.chapters ?? []).filter((c) => c.title.includes('Unverified'))
        if (matches.length > 0 && matches[0].book) {
            return matches[0].book as GameBookModel
        } else {
            return undefined
        }
    }

    // CODES

    @computed
    get codesGameBook(): GameBookModel | undefined {
        const matches = (this.parentBook.chapters ?? []).filter((c) => c.title.includes('Code'))
        if (matches.length > 0) {
            return matches[0].book as GameBookModel
        } else {
            return undefined
        }
    }

    // FRIENDS

    @computed
    get friendPresencesInGame() {
        return this.homeController.friendWidgetController?.friendPresences.
            filter((presence) => {
                return presence.concept_id && presence.concept_id == this.parentBook.id
            }
            )
    }

    async joinFriendPresence(presence: PresenceModel) {
        try {
            const url = ProfileModelExtensions.joinRobloxGameUrl(presence)
            if (!url) return

            await Linking.openURL(url)
        } catch (error) {
            SentryService.captureError(error)
        }
    }

    // Private instance utility methods

    private consoleDebug(method: string, force: boolean = false) {
        if (this.debug || force) console.log(`${this.constructor.name}: ${method}`)
    }
}
