import {action, computed, makeObservable, observable} from "mobx"
import {CurrentAccount} from "../../modules/vizz_account/lib/CurrentAccount"
import {AppController} from "../../app/controllers/AppController"
import {ProfileModel} from "../../modules/social/models/ProfileModel"
import SentryService from "../../app/services/SentryService"
import PersonModel from "../../modules/vizz_account/models/PersonModel"
import { SchoolModel } from "../../modules/social/models/SchoolModel"

export enum SearchForFriendsState {
    LOADING,
    LOADED
}

export class SearchForFriendsController {
    private debug: boolean = false  // don't set this to true in production

    private currentAccount: CurrentAccount
    private onFinish: () => void
    public appController: AppController

    @observable state: SearchForFriendsState = SearchForFriendsState.LOADING
    @observable friendSearchResults: ProfileModel[]
    @observable requestedFriendName: string
    @observable queryChanged: boolean = false;                              @action public setQueryChanged(bool: boolean) { this.queryChanged = bool }
    @observable school?: SchoolModel | null
    @observable schoolPeople?: PersonModel[]
    @observable robloxPeople?: PersonModel[]

    @computed
    get schoolFriends() {
        if (!this.schoolPeople) return []
        return this.schoolPeople
            .filter((f) => f.profile !== undefined)
            .map((f) => f.profile!)
    }

    @computed
    get robloxFriends() {
        if (!this.robloxPeople) return []
        return this.robloxPeople
            .filter((f) => f.roblox_profile !== undefined)
    }

    constructor(currentAccount: CurrentAccount, appController: AppController, onFinish: () => void) {
        this.consoleDebug(`new()`)

        this.currentAccount = currentAccount
        this.appController = appController
        this.onFinish = onFinish
        this.friendSearchResults = []
        this.requestedFriendName = ''

        makeObservable(this)
    }

    public async initialize() {
        this.consoleDebug(`initialize()`)

        try {
            if (this.currentAccount.person.school_id) {
                const school = await this.currentAccount.api.get('school_path') as SchoolModel | null
                this.setSchool(school)

                const schoolPeople = await this.currentAccount.api.get('school_people_path') as PersonModel[]
                this.setSchoolPeople(schoolPeople)
            }

            const robloxPeople = await this.currentAccount.api.get('roblox_people_path') as PersonModel[]
            this.setRobloxPeople(robloxPeople)
        } catch (error) {
            console.error(error)
            SentryService.captureError(error)
        }

        this.setState(SearchForFriendsState.LOADED)
    }

    public uninitialize() {
        this.consoleDebug(`uninitialize()`)
    }


    // Public methods
    async addFriend(profile: ProfileModel) {
        this.consoleDebug(`addFriend()`)
        let response
        try {
            response = await this.currentAccount.api.post('friend_requests_path', { keys: profile.person_key })
            // already_reported is a success case
            this.currentAccount.analytics.logEvent('social-addfriend-request', 'finished', profile.username, { username: profile.username })
            this.setRequestedFriendName(profile.username.replace("_", " "))
        } catch(error) {
            if (!(error instanceof Error)) return
            SentryService.captureError(error)
        }

        return response
    }

    async searchForFriend(username: string) {
        this.consoleDebug(`searchForFriend()`)

        if (username.trim().length < 2) {
            this.setFriendSearchResults([])
            return ''
        }

        let message
        let response
        try {
            response = await this.currentAccount.api.get('social.profile_search_path', { 'username': username })
            message = response?.message
        } catch(e) {
            message = `Unexpected error: ${e}`
            SentryService.captureError(e)
        }

        if (response?.success) {
            this.currentAccount.analytics.logEvent('social-addfriend-search', 'finished', username, { username: username, search_results: response.search_results})
            this.setFriendSearchResults(response.search_results as any[])
        } else {
            this.currentAccount.analytics.logEvent('social-addfriend-search', 'error', message, { message: message, username: username })
        }

        return message
    }


    // Private helper methods

    @action
    private setState(state: SearchForFriendsState) {
        this.consoleDebug(`setState()`)
        this.state = state
    }

    @action
    private setFriendSearchResults(friendSearchResults: any[]) {
        this.consoleDebug(`setFriendSearchResults()`)
        this.friendSearchResults = friendSearchResults
    }

    @action
    private setRequestedFriendName(requestedFriendName: string) {
        this.consoleDebug(`setRequestedFriendName()`)
        this.requestedFriendName = requestedFriendName
    }

    @action
    setSchool(school: SchoolModel | null) {
        this.school = school
    }

    @action
    setSchoolPeople(schoolPeople: PersonModel[]) {
        this.schoolPeople = schoolPeople
    }

    @action
    setRobloxPeople(robloxPeople: PersonModel[]) {
        this.robloxPeople = robloxPeople
    }

    // Private instance utility methods

    private consoleDebug(method: string, force: boolean = false) {
        if (this.debug || force) console.log(`${this.constructor.name}: ${method}  state = ${this.state}`)
    }
}
