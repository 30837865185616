import {makeObservable, observable, runInAction} from "mobx"
import {CurrentAccount} from "../../../vizz_account/lib/CurrentAccount"
import {ProfileModel, ProfileType} from "../../models/ProfileModel"
import {FriendWidgetController} from "../FriendWidgetController"

export interface GroupCallProfileViewModel {
    profile: ProfileModel
    isSelected: boolean
}

export class GroupCallController {
    private debug: boolean = false  // don't set this to true in production

    private currentAccount: CurrentAccount
    public friendWidgetController: FriendWidgetController
    public friends: GroupCallProfileViewModel[]

    @observable selectedCount: number = 0

    constructor(currentAccount: CurrentAccount, friendWidgetController: FriendWidgetController) {
        this.consoleDebug(`new()`)

        this.currentAccount = currentAccount
        this.friendWidgetController = friendWidgetController
        this.friends = []

        makeObservable(this)
    }

    public initialize() {
        this.consoleDebug(`initialize()`)
    }

    public resetFriends(excludeProfiles?: ProfileModel[]) {
        let excludeProfilesMap: any = undefined
        if (excludeProfiles) {
            excludeProfilesMap = {}
            excludeProfiles.forEach((p) => excludeProfilesMap[p.person_id] = p)
        }

        const friends: GroupCallProfileViewModel[] = this.friendWidgetController.friends
            // TODO: For now Roblox profiles can't make calls or chat, filter them out
            .filter((p) => p.profile_type == ProfileType.LAVA)
            .filter((p) => {
                if (excludeProfilesMap && excludeProfilesMap[p.person_id]) {
                    return false
                }
                return true
            })
            .map((f) => {
            return {profile: f, isSelected: false}
        })

        this.friends = friends
        return this.friends
    }

    public toggleSelected(vm: GroupCallProfileViewModel) {
        let selectedCount = 0
        const friends = this.friends.map((f) => {
            if (vm.profile.person_key == f.profile.person_key) {
                f.isSelected = !f.isSelected
            }
            if (f.isSelected) selectedCount += 1
            return f
        })

        runInAction(() => this.selectedCount = selectedCount)
        this.friends = friends
    }

    // Private instance utility methods

    private consoleDebug(method: string, force: boolean = false) {
        if(this.debug || force) console.log(`${this.constructor.name}: ${method}`)
    }
}