import {action, computed, makeObservable, observable} from "mobx"
import {CurrentAccount} from "../../../../vizz_account/lib/CurrentAccount"
import FriendModel from "../../../models/steps/FriendModel"
import SentryService from "../../../../../app/services/SentryService"
import {IncomingFriendRequestModel} from "../../../../social/models/IncomingFriendRequestModel"
import PersonModel from "../../../../vizz_account/models/PersonModel"

export enum FriendsGridState {
    LOADING,
    LOADED
}

export class FriendsGridController {
    private debug: boolean = false  // don't set this to true in production

    private currentAccount: CurrentAccount
    private interestOrder: number = 0
    private maxSelect: number
    private flatGridRef: any
    private pendingIncomingFriendRequests: IncomingFriendRequestModel[] = []

    @observable state: FriendsGridState = FriendsGridState.LOADING;     @action private setState(state: FriendsGridState) { this.state = state }
    @observable friends: FriendModel[] = [];                            @action private setFriends(friends: FriendModel[]) { this.friends = friends }
    @observable realFriends:FriendModel[] = [];                         @action public setRealFriends(friends: FriendModel[]) { this.realFriends = friends }

    constructor(currentAccount: CurrentAccount, flatGridRef: any, maxSelect?: number) {
        this.consoleDebug(`new()`)

        this.currentAccount = currentAccount
        this.maxSelect = maxSelect ?? 5
        this.flatGridRef = flatGridRef

        makeObservable(this)
    }

    public async initialize() {
        this.consoleDebug(`initialize()`)
        if (this.state != FriendsGridState.LOADING) return

        await this.search()
        try {
            this.pendingIncomingFriendRequests = await this.currentAccount.api.get('friend_requests_path') as IncomingFriendRequestModel[]
        } catch (error) {
            SentryService.captureError(error)
            this.pendingIncomingFriendRequests = []
        }

        this.setState(FriendsGridState.LOADED)
    }

    public uninitialize() {
        this.consoleDebug(`uninitialize()`)
    }


    // Public methods

    sections(showSections: boolean) {
        return showSections ?
            [
                {
                    title: 'Real Friends',
                    data: this.realFriends,
                },
                {
                    title: 'Suggested Friends',
                    data: this.suggestedFriends
                },
                {
                    title: 'All Friends',
                    data: this.remainingFriends
                }
            ] : [
                {
                    title: 'All Friends',
                    data: this.friends
                }
            ]
    }

    @computed
    get pendingIncomingFriendKeys() {
        return this.pendingIncomingFriendRequests.map((fr) => fr.source_person.key)
    }

    @computed
    get suggestedFriends() {
        return this.friends.
            filter((f) => this.pendingIncomingFriendKeys.includes(f.robloxFriend.person_key)).
            filter((f) => !this.realFriends.includes(f))
    }

    @computed
    get suggestedFriendRequestsToDecline() {
        return this.pendingIncomingFriendRequests.filter((fr) => this.suggestedFriends.map((f) => f.robloxFriend.person_key).includes(fr.source_person.key))
    }

    @computed
    get remainingFriends() {
        return this.friends.
            filter((f) => !this.realFriends.includes(f)).
            filter((f) => !this.pendingIncomingFriendKeys.includes(f.robloxFriend.person_key))
    }

    public async search(text?: string) {
        try {
            let response = await this.currentAccount.api.get('roblox_people_path', { query: text })

            this.currentAccount.analytics.logEvent('friends-onboarding','searched', `${text ?? '[all]'} (${response.length})`, {
                roblox_friends_count: response.length,
            })

            const people = response as PersonModel[]
            let friends = people.map((person: PersonModel) => new FriendModel(person.roblox_profile!))

            this.realFriends.forEach(friend => {
                const indexToRemove = friends.findIndex((f) => f.robloxFriend.id == friend.robloxFriend.id)
                if (indexToRemove >= 0) friends.splice(indexToRemove, 1)
            }) // remove all existing real friends

            friends.splice(2, 0, ...this.realFriends) // add them back in a specific spot
            this.setFriends(friends)

            // @ts-ignore: typescript isn't recognizing this forwarded ref
            this.flatGridRef.current?.scrollToIndex({index: 0, viewOffset: 0, animated: true})
        } catch (error) {
            SentryService.captureError(error)
        }
    }

    @computed
    get selectedFriends() {
        return this.friends.filter((i) => i.isSelected).sort((a,b) => (a.order > b.order) ? 1 : -1)
    }

    @action
    public toggleSelectedFriend(friend: FriendModel) {
        if (!friend.isSelected && this.selectedFriends.length >= this.maxSelect) return

        friend.setSelected(!friend.isSelected, this.interestOrder += 1)

        if (friend.isSelected) {
            this.setRealFriends( this.realFriends.concat([ friend ]) )
        } else {
            const indexToRemove = this.realFriends.findIndex((f) => f.robloxFriend.id == friend.robloxFriend.id)
            let modifiedFriendsList = this.realFriends
            modifiedFriendsList.splice(indexToRemove, 1)

            this.setRealFriends( modifiedFriendsList )
        }
    }


    // Private instance utility methods

    private consoleDebug(method: string, force: boolean = false) {
        if (this.debug || force) console.log(`${this.constructor.name}: ${method}  state = ${this.state}`)
    }
}
