import React, {useContext, useEffect, useRef, useState} from 'react'
import {KeyboardAvoidingView, Platform, StyleSheet, Text, View, ViewStyle} from "react-native"
import {observer} from "mobx-react"
import {ProfileController, ProfileState} from "../../controllers/steps/ProfileController"
import CurrentAccountContext from "../../../vizz_account/lib/CurrentAccount"
import {OnboardingController, OnboardingState} from '../../controllers/OnboardingController'
import {reaction} from "mobx"
import {State, TapGestureHandler, TapGestureHandlerStateChangeEvent} from 'react-native-gesture-handler'
import {PrimaryButton} from '../../../../app/views/components/PrimaryButton'
import {ActivityIndicator, Button, Switch, TextInput} from 'react-native-paper'
import AppControllerContext from '../../../../app/controllers/AppController'
import {RobloxLogin} from "./profile/RobloxLogin"
import FriendDetails from '../../../social/views/friend_widget/FriendDetails'
import {Color, isLandscape, isPortrait} from '../../../../app/lib/Appearance'
import {AdminPin} from '../../../../app/lib/AdminPin'
import {Animated, Easing} from 'react-native'
import {useSafeAreaInsets} from 'react-native-safe-area-context'
import {AsyncUtils} from '../../../../app/utils/AsyncUtils'
import {LavaImage} from '../../../lava-components/components/atoms/LavaImage/LavaImage'
import {FeatureFlagModel} from '../../../vizz_account/models/FeatureFlagModel'

type Props = {
    style?: ViewStyle
    onboardingController: OnboardingController
}

export const Profile = observer((props: Props) => {
    const currentAccount = useContext(CurrentAccountContext)
    const appController = useContext(AppControllerContext)
    const [controller] = useState<ProfileController>(() => new ProfileController(currentAccount, appController, props.onboardingController))
    const [profilePosition, setProfilePosition] = useState({ x: 0, y: 0 })
    const profileViewRef = useRef<View>(null)
    const safeAreaInsets = useSafeAreaInsets()

    const onProfileViewLayout = () => {
        profileViewRef.current?.measure((x, y, width, height, pageX, pageY) => {
            setProfilePosition({ x: pageX, y: pageY });
        })
    }


    useEffect(() => {
        const neededReaction = reaction(() => props.onboardingController.state, async(state) => {

            if (currentAccount.personData.profileOnboardingNeeded) {
                if (state == OnboardingState.PROFILE) await controller.initialize()
                if (state == OnboardingState.PROFILE) await controller.startStep()
            }

        }, { fireImmediately: true })

        return () => {
            neededReaction()
            controller.uninitialize()
        }
    }, [])

    const onDebugTapGesture = (event: TapGestureHandlerStateChangeEvent) => {
        if (event.nativeEvent.state === State.ACTIVE) {
            void currentAccount.api.patch("vizz_account.person_path", { ignored: true })
            alert('You tapped 4 times.')
        }
    }

    const infoOpacity = useRef(new Animated.Value(1))
    const profilePositionX = useRef(new Animated.Value(0))
    const profilePositionY = useRef(new Animated.Value(0))
    const profileScale = useRef(new Animated.Value(1))

    const onApprovedRobloxName = () => {
        const xAdjust = isPortrait() ? 26 : -29
        const yAdjust = isPortrait() ? 0 : -3
        const scaleAdjust = isPortrait() ? 0.7 : 1

        Animated.sequence([
            Animated.timing(infoOpacity.current, {
                toValue: 0,
                duration: 200,
                easing: Easing.cubic,
                useNativeDriver: true,
            }),
            Animated.parallel([
                Animated.timing(profilePositionX.current, {
                    toValue: -1 * (profilePosition.x + xAdjust),
                    duration: 200,
                    easing: Easing.cubic,
                    useNativeDriver: true,
                }),
                Animated.timing(profilePositionY.current, {
                    toValue: -1 * (profilePosition.y + yAdjust - safeAreaInsets.top),
                    duration: 200,
                    easing: Easing.cubic,
                    useNativeDriver: true,
                }),
                Animated.timing(profileScale.current, {
                    toValue: scaleAdjust,
                    duration: 200,
                    easing: Easing.cubic,
                    useNativeDriver: true,
                }),
            ])
        ]).start(async() => {
            props.onboardingController.homeController.setRevealPhase(1)
            await AsyncUtils.sleep(50)
            void controller.onApprovedRobloxLavaName()
            if (!currentAccount.hasFeature(FeatureFlagModel.OFFLINE)) controller.setState(ProfileState.SUBMITTING)
        })
    }

    return (
        <React.Fragment>
            {props.onboardingController.state == OnboardingState.PROFILE && controller.state == ProfileState.LOADING &&
            <View style={{flex: 1, alignItems: 'center', justifyContent: 'center'}}>
                <ActivityIndicator color={Color.primary} size={40} />
            </View>}

            {props.onboardingController.state == OnboardingState.PROFILE && controller.state == ProfileState.FIRST_NAME &&
                <KeyboardAvoidingView
                    behavior={'padding'}
                    keyboardVerticalOffset={40}
                    style={{
                        flex:1,
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}
                >
                    <View style={{alignItems: 'center', justifyContent: 'center'}}>
                        <Text style={[props.onboardingController.styles.messageText]}>What is your first name?</Text>

                        <TextInput
                            style={{width: 320}}
                            maxLength={11}
                            mode={'flat'}
                            autoCapitalize={'words'}
                            textContentType={'givenName'}
                            keyboardType={'default'}
                            label="First name"
                            value={controller.firstName}
                            autoFocus={true}
                            autoCorrect={false}
                            onSubmitEditing={() => controller.onFirstNameSubmitted()}
                            onChangeText={text => { controller.onChangeFirstName(text); controller.setErrorMessage(undefined) }}
                        />
                        <Text style={{color: 'orangered', marginTop: 10}}>{controller.errorMessage} </Text>

                        <PrimaryButton
                            style={{marginTop: 10, marginBottom: 20}}
                            disabled={!controller.firstName || controller.firstName.length < 2}
                            resetWhen={controller.errorMessage == undefined}
                            onPress={() => controller.onFirstNameSubmitted()}
                            iconType={'FontAwesome'}
                            icon={'thumbs-up'}
                            iconSide={'right'}
                        />
                    </View>
                </KeyboardAvoidingView>
            }

            {props.onboardingController.state == OnboardingState.PROFILE && controller.state == ProfileState.ROBLOX_ASK &&
            <View style={[{flex: 1, alignItems: 'center', justifyContent: 'center'}, props.style]}>
                <Text style={[props.onboardingController.styles.messageText]}>Do you want to use your Roblox username?</Text>

                <View style={{marginTop: 10, flexDirection: 'row'}}>
                    <PrimaryButton
                        style={{margin: 8}}
                        onPress={() => controller.robloxAnswer('no')}
                        iconType={'Foundation'}
                        icon={'x'}
                        iconSide={'right'}
                        title={'No'}/>

                    <PrimaryButton
                        style={{margin: 8}}
                        onPress={() => controller.robloxAnswer('yes')}
                        iconType={'MaterialCommunityIcons'}
                        icon={'check-bold'}
                        iconSide={'right'}
                        title={"Yes"}/>
                </View>
                <Button
                    style={{marginVertical: 10}}
                    disabled={false}
                    color={'white'}
                    onPress={() => controller.setState(ProfileState.FIRST_NAME) }>
                        {"< Change First Name"}
                </Button>
            </View>}

            {props.onboardingController.state == OnboardingState.PROFILE && controller.state == ProfileState.ROBLOX_LOGIN &&
            <View style={[{flex: 1, alignItems: 'center', justifyContent: 'center'}, props.style]}>
                <RobloxLogin onboardingController={props.onboardingController}
                    onFinish={(result) => {
                        controller.setNickname(result.displayName)
                        controller.setUsername(result.username)
                        void controller.onRobloxLoginResult(result.cookie)
                    }}
                    onSkip={async() => {
                        const unlocked = await AdminPin.unlock()
                        if (!unlocked) {
                            alert('Incorrect pin')
                            return
                        } else {
                            currentAccount.analytics.logEvent('profile-onboarding','secret-nickname')
                            void controller.fallbackToLavaName()
                        }
                    }}

                />
            </View>}

            {props.onboardingController.state == OnboardingState.PROFILE && controller.state == ProfileState.LAVA_NAME &&
            <View style={[{flex: 1, alignItems: 'center', justifyContent: 'center'}, props.style]}>
                <KeyboardAvoidingView behavior={'position'} keyboardVerticalOffset={80}>
                    <View style={{alignItems: 'center', justifyContent: 'center'}}>

                        <Text style={[props.onboardingController.styles.messageText]}>What is your Lava name?</Text>

                        <TextInput
                            style={{width: 320}}
                            maxLength={20}
                            mode={'flat'}
                            autoCapitalize={'words'}
                            textContentType={'nickname'}
                            keyboardType={'default'}
                            autoCorrect={false}
                            autoFocus={true}
                            label="Lava Nickname"
                            left={<TextInput.Affix text={controller.firstName + ' '} textStyle={{color: "black", fontWeight: "bold"}}/>}
                            value={controller.nickname}
                            onChangeText={text => { controller.onChangeNickname(text); controller.setErrorMessage(undefined) }}
                            onSubmitEditing={() => controller.onApprovedRobloxLavaName()}
                        />
                        <Text style={{color: 'orangered', marginTop: 10}}>{controller.errorMessage} </Text>

                        <PrimaryButton
                            disabled={controller.nickname == undefined || controller.nickname.length < 2}
                            resetWhen={controller.errorMessage == undefined}
                            style={{marginTop: 10, marginBottom: 20}}
                            onPress={() => controller.onApprovedRobloxLavaName()}
                            iconType={'FontAwesome'}
                            icon={'thumbs-up'}
                            iconSide={'right'}
                        />

                        <Button
                            style={{marginVertical: 10}}
                            disabled={false}
                            color={'white'}
                            onPress={() => controller.setState(ProfileState.FIRST_NAME) }>
                                {"< Back"}
                        </Button>
                    </View>
                </KeyboardAvoidingView>
            </View>}

            {props.onboardingController.state == OnboardingState.PROFILE && (
                controller.state == ProfileState.SYNCING_ROBLOX_DATA || controller.state == ProfileState.WAITING_FOR_FOREGROUND
            ) &&
            <View style={[{flex: 1, alignItems: 'center', justifyContent: 'center'}, props.style]}>
                <Text style={props.onboardingController.styles.messageText}>Connecting to Roblox...</Text>
                <ActivityIndicator color={'white'}/>
                <Text style={{color: 'orangered', marginTop: 10}}>{controller.errorMessage} </Text>
                <Text style={{color: '#303030', marginTop: 10, maxWidth: 800, marginHorizontal: 30}}>{controller.errorMessageDetails} </Text>
                <Button
                    style={{marginTop: 10, paddingTop: 0}}
                    disabled={false}
                    color={'white'}
                    onPress={() => {
                        controller.setState(ProfileState.ROBLOX_LOGIN)
                    }}>
                        {"Try Again"}
                    </Button>
            </View>
            }

            {props.onboardingController.state == OnboardingState.PROFILE && controller.state == ProfileState.APPROVE_PROFILE &&
            <View style={[{flex: 1, alignItems: 'center', justifyContent: 'center'}, props.style]}>
                <KeyboardAvoidingView behavior={'position'} keyboardVerticalOffset={80}>
                    <View style={{alignItems: 'center', justifyContent: 'center'}}>

                        <TapGestureHandler numberOfTaps={4} onHandlerStateChange={onDebugTapGesture}>
                            <Animated.View style={{opacity: infoOpacity.current}}>
                                <Text style={[props.onboardingController.styles.messageText]}>When you're online</Text>
                            </Animated.View>
                        </TapGestureHandler>

                        {currentAccount.profile &&
                        <Animated.View style={{ transform: [{ translateX: profilePositionX.current }, { translateY: profilePositionY.current }, { scale: profileScale.current }]}}>
                            <View ref={profileViewRef} onLayout={onProfileViewLayout} style={{flexDirection: 'row', width: 230, opacity: 1}}>
                                <FriendDetails profile={currentAccount.profile} forceOnline={true} />
                            </View>
                        </Animated.View>}

                        <Animated.View style={{opacity: infoOpacity.current, alignItems: 'center', justifyContent: 'center'}}>
                            <View style={{marginTop: 30, flexDirection: 'row'}}>
                                <TextInput
                                    style={{width: isLandscape() ? 150 : 120, marginRight: 10}}
                                    maxLength={11}
                                    mode={'flat'}
                                    autoCapitalize={'words'}
                                    textContentType={'givenName'}
                                    keyboardType={'default'}
                                    label="First name"
                                    value={controller.firstName}
                                    autoCorrect={false}
                                    onChangeText={text => { controller.onChangeFirstName(text); controller.setErrorMessage(undefined) }}
                                />
                                <TextInput
                                    style={{width: isLandscape() ? 250 : 180}}
                                    maxLength={20}
                                    mode={'flat'}
                                    autoCapitalize={'words'}
                                    textContentType={'nickname'}
                                    keyboardType={'default'}
                                    autoCorrect={false}
                                    label="Lava Nickname"
                                    value={controller.nickname}
                                    onChangeText={text => { controller.onChangeNickname(text); controller.setErrorMessage(undefined) }}
                                    onSubmitEditing={() => onApprovedRobloxName()}
                                />
                            </View>

                            <Text style={{color: 'orangered', marginTop: 10}}>{controller.errorMessage} </Text>

                            <PrimaryButton
                                disabled={!controller.firstName || !controller.nickname || controller.firstName.length < 2 || controller.nickname.length < 2}
                                resetWhen={controller.errorMessage == undefined}
                                style={{marginTop: 10, marginBottom: 20}}
                                onPress={() => onApprovedRobloxName()}
                                title={'Looks Good!'}
                                iconType={'FontAwesome'}
                                icon={'thumbs-up'}
                                iconSide={'right'}
                            />

                            {(!currentAccount.personData.hasRobloxAccount || controller.areRobloxCookiesValid != undefined) &&
                            <React.Fragment>
                                {controller.areRobloxCookiesValid == true &&
                                <Button
                                    style={{marginVertical: 20}}
                                    disabled={false}
                                    color={'white'}
                                    onPress={() => controller.resetApp()}>
                                        {"Disconnect from Roblox"}
                                </Button>}

                                {controller.areRobloxCookiesValid == false &&
                                <PrimaryButton
                                    style={{marginVertical: 10}}
                                    title={'Connect to Roblox'}
                                    fixedState={'enabled'}
                                    onPress={() => controller.resetApp()} />}

                            </React.Fragment>}

                            {controller.areRobloxCookiesValid == undefined &&
                            <React.Fragment>
                                <Button
                                    style={{marginVertical: 20}}
                                    disabled={true}
                                    color={'white'}
                                    onPress={() => {}}>
                                        ...
                                </Button>
                            </React.Fragment>}
                        </Animated.View>

                    </View>
                </KeyboardAvoidingView>
            </View>}

            {props.onboardingController.state == OnboardingState.PROFILE && controller.state == ProfileState.OFFLINE_MESSAGE &&
            <View style={[{flex: 1, alignItems: 'center', justifyContent: 'center'}, props.style]}>
                <KeyboardAvoidingView behavior={'position'} keyboardVerticalOffset={80}>
                    <View style={{alignItems: 'center', justifyContent: 'center', width: '100%', flex: 1, marginTop: isPortrait() ? 65 : 15}}>

                        <TapGestureHandler numberOfTaps={4} onHandlerStateChange={onDebugTapGesture}>
                            <Text style={[props.onboardingController.styles.messageText]}>When you're offline</Text>
                        </TapGestureHandler>

                        <View style={{backgroundColor: 'rgb(242,242,242)', width: 300, alignItems: 'center', paddingVertical: 10, borderRadius: 6, overflow: 'hidden'}}>
                            <LavaImage style={{width: 100, height: 100, backgroundColor: 'rgb(163,163,163)', borderRadius: 100/2}} source={{uri: currentAccount.profile?.avatar_image_url ?? currentAccount.profile?.roblox_profile?.avatar_headshot_url}} />
                            <Text style={{backgroundColor: 'transparent', fontSize: 13, color: 'rgb(30, 30, 30)', marginTop: 3}}>
                                <View style={{width: 12, height: 12, borderRadius: 12, backgroundColor: 'rgb(78, 173, 17)'}} />{' '}
                                {currentAccount.profile?.nickname}
                            </Text>
                            <Text style={{backgroundColor: 'transparent', fontSize: 13, color: 'rgb(80, 80, 80)'}}>
                                😴 Lava: Offline
                            </Text>
                        </View>

                        <View style={{backgroundColor: 'rgb(40, 40, 40)', width: 300, flexGrow: 1, marginTop: 20, borderRadius: 6}}>
                            <View style={{backgroundColor: 'rgb(60, 60, 60)', alignItems: 'center', paddingVertical: 7}}>
                                <Text style={{color: 'white', fontSize: 12}}>Chat</Text>
                            </View>

                            <View style={{paddingRight: 0, flexDirection: 'row', marginTop: 40, width: 300}}>

                                <View style={{backgroundColor: 'transparent', width: 50, flexDirection: 'row', justifyContent: 'center', alignItems: 'flex-end'}}>
                                    <LavaImage style={{width: 30, height: 30, backgroundColor: 'rgb(163,163,163)', borderRadius: 30/2}} source={{uri: currentAccount.profile?.avatar_image_url ?? currentAccount.profile?.roblox_profile?.avatar_headshot_url}} />
                                </View>

                                <View style={{backgroundColor: 'rgb(60, 60, 60)', width: 240, flexDirection: 'row', padding: 10, marginBottom: 15, borderWidth: 1, borderColor: 'rgb(100, 100, 100)', borderTopLeftRadius: 10, borderTopRightRadius: 10, borderBottomRightRadius: 10}}>
                                    <View style={{width: 220, marginLeft: 0, marginRight: 0, overflow: 'hidden'}}>
                                        <Text style={{color: 'white'}}>I'm offline.{"\n"}
                                        Chat me and I'll reply later.</Text>
                                    </View>
                                </View>

                            </View>
                        </View>

                        <View style={{backgroundColor: 'transparent', marginBottom: 10}}>
                            <Text style={{color: 'orangered', marginTop: 10}}>{controller.errorMessage} </Text>
                            <View style={{flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
                                <Text style={{color: 'white', fontSize: 15, marginRight: 5}}>Offline message: </Text>
                                <Switch value={controller.offlineMessage} onValueChange={(newValue) => controller.setOfflineMessage(newValue)} color={'rgb(222, 184, 221)'} style={{borderColor: 'rgb(100, 100, 100)', borderWidth: 1, borderRadius: 15}} />
                            </View>
                            <PrimaryButton
                                disabled={!controller.firstName || !controller.nickname || controller.firstName.length < 2 || controller.nickname.length < 2}
                                resetWhen={controller.errorMessage == undefined}
                                style={{marginTop: 10, marginBottom: 20}}
                                onPress={() => controller.onApproveOfflineMessage()}
                                title={'Looks Good!'}
                                iconType={'FontAwesome'}
                                icon={'thumbs-up'}
                                iconSide={'right'}
                            />
                        </View>
                    </View>
                </KeyboardAvoidingView>
            </View>}

            {props.onboardingController.state == OnboardingState.PROFILE && controller.state == ProfileState.SUBMITTING &&
            <View style={[{flex: 1, alignItems: 'center', justifyContent: 'center'}, props.style]}></View>}
        </React.Fragment>
    )
})

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
})
