import {action, computed, makeObservable, observable, reaction, runInAction} from "mobx"
import {AppController} from "../../../app/controllers/AppController"
import {LibraryWidgetController} from "../../browse/controllers/LibraryWidgetController"
import SeriesController from "../../browse/controllers/library_widget/SeriesController"
import BookModel from "../../browse/models/BookModel"
import PageModel from "../../browse/models/PageModel"
import VideoModel from "../../browse/models/VideoModel"
import {LauncherWidgetController} from "../../launcher/controllers/LauncherWidgetController"
import {FriendWidgetController} from "../../social/controllers/FriendWidgetController"
import {InboxWidgetController} from "../../social/controllers/InboxWidgetController"
import {CurrentAccount} from '../../vizz_account/lib/CurrentAccount'
import {SearchButtonsController} from "./home/SearchButtonsController"
import {RobloxGameModel} from "../../browse/models/RobloxGameModel"
import {FeedWidgetController} from "../../social/controllers/FeedWidgetController"
import {ActivityName, TimedActivity} from "../../../app/lib/services/TimedActivity"
import {GameBookModel} from "../../browse/models/GameBookModel"
import {DeepLinkService} from "../../../app/lib/DeepLinkService"
import * as Linking from 'expo-linking'
import SentryService from "../../../app/services/SentryService"
import {create, persist} from "mobx-persist"
import AsyncStorage from "@react-native-async-storage/async-storage"
import {ProfileStatusService} from "../../social/services/ProfileStatusService"
import {SocialStatusUpdateResponse} from "../../social/models/SocialStatusUpdateResponse"
import {AsyncUtils} from "../../../app/utils/AsyncUtils"
import * as StoreReview from 'expo-store-review'
import {LavaAlert} from "../../../app/views/components/LavaAlert"
import {OutgoingFriendRequestModel} from "../../social/models/OutgoingFriendRequestModel"


export enum HomeState {
    ONBOARDING,
    HOME,
}

const hydrate = create({
    storage: AsyncStorage,
    jsonify: true,
})

class HomeController {
    private debug: boolean = false  // don't set this to true in production

    private currentAccount: CurrentAccount
    public appController: AppController
    public inboxWidgetController?: InboxWidgetController
    public feedWidgetController?: FeedWidgetController
    friendWidgetController: FriendWidgetController
    public libraryWidgetController?: LibraryWidgetController
    @observable launcherWidgetController: LauncherWidgetController|undefined = undefined
    public searchButtonsController?: SearchButtonsController
    public seriesController?: SeriesController
    public deepLinkService = new DeepLinkService()
    private momentsAfterForegroundTimeout?: any

    @observable animateNotification: boolean = false;                       @action public setAnimateNotification(animate: boolean) { this.animateNotification = animate }
    @observable moderatorMenuVisible: boolean = false;                      @action public setModeratorMenuVisible(bool: boolean) { this.moderatorMenuVisible = bool }

    @observable state: HomeState = HomeState.ONBOARDING

    @observable voiceSearchActive: boolean = false
    @observable scannerSearchActive: boolean = false
    @observable loading: boolean = false
    @observable currentBook: BookModel|undefined = undefined
    @observable currentBookOpenTrigger: string|undefined = undefined
    @observable currentBookOpenPosition: number|undefined = undefined
    @observable focus: 'secrets'|'codes'|'trailer'|undefined = undefined
    @observable currentVideo: VideoModel|undefined = undefined
    @observable isShowingInboxCallout: boolean = false
    @observable dimBackground: boolean = false;                             @action public setDimBackground(bool: boolean) { this.dimBackground = bool }
    @observable revealPhase: number = 0;                                    @action public setRevealPhase(num: number) { this.revealPhase = num }
    @observable draftFriendRequests?: OutgoingFriendRequestModel[];                      @action setDraftFriendRequests(requests: OutgoingFriendRequestModel[]|undefined) {this.draftFriendRequests = requests}

    @observable @persist('list') recentGameBooks?: GameBookModel[]
    @action setRecentGameBooks(books: GameBookModel[]) {this.recentGameBooks = books}

    @observable isRobloxGameDetailActive: boolean = false
    @action setRobloxGameDetailActive(active: boolean) { this.isRobloxGameDetailActive = active }

    @observable hasLoadedRobloxGames: boolean = false
    @action setHasLoadedRobloxGames(hasLoaded: boolean) { this.hasLoadedRobloxGames = hasLoaded }

    private checkUnreadInboxOnNextRefresh: boolean = false
    private cleanUpAppStateHandler?: any
    private linkEventSubscription?: any
    private hasHandledFirstNotification: boolean = false

    constructor(currentAccount: CurrentAccount, appController: AppController) {
        this.consoleDebug(`new()`)

        this.friendWidgetController = new FriendWidgetController(currentAccount, appController, this)
        this.currentAccount = currentAccount
        this.appController = appController

        makeObservable(this)
    }


    // Public instance init methods

    public async initialize() {
        this.consoleDebug(`initialize()`)

        await hydrate('home', this)

        this.cleanUpAppStateHandler = reaction(() => this.appController.nativeState.state, (state) => {
            if (state == 'foreground')
                this.onAppForeground()
            else if (state == 'background')
                this.onAppBackground()
        })

        if (this.inboxWidgetController?.appController.nativeState.state == 'foreground') this.onAppForeground()

        if (!this.launcherWidgetController) runInAction(() => this.launcherWidgetController = this.appController.launcherWidgetController)

        await this.configureDeepLinking()

        this.onAppForeground()
    }

    public uninitialize() {
        this.consoleDebug(`uninitialize()`)
        if (this.cleanUpAppStateHandler) this.cleanUpAppStateHandler()
        if (this.launcherWidgetController) this.launcherWidgetController.uninitialize()
        this.appController.notification.removeNotificationOpenedListener('homeController')
    }


    // Private instance init methods

    private onAppForeground() {
        this.checkUnreadInboxOnNextRefresh = true
        if (!this.currentAccount.personData.onboardingNeeded) this.checkMomentsAfterForegroundTasks()
    }

    private onAppBackground() {
        if (this.momentsAfterForegroundTimeout) {
            clearTimeout(this.momentsAfterForegroundTimeout)
        }
    }

    private async configureDeepLinking() {
        // Define the routing behavior for various paths

        this.deepLinkService.on('/concept_books/:id', async (params) => {
            const conceptId = params.id
            if (conceptId) {
                try {
                    this.setLoading(true)
                    const book = await this.currentAccount.api.get(`browse.concept_book_path(${conceptId})`) as BookModel
                    this.openBook(book, 'push-notification')
                } catch (error) {
                    SentryService.captureError(error)
                } finally {
                    this.setLoading(false)
                }
            }
        })

        this.deepLinkService.on('/game_books/:id', async (params) => {
            const gameId = params.id
            if (gameId) {
                try {
                    this.setLoading(true)
                    const book = await this.currentAccount.api.get(`browse.game_book_path(${gameId})`) as GameBookModel
                    this.openBook(book, 'push-notification')
                } catch (error) {
                    SentryService.captureError(error)
                } finally {
                    this.setLoading(false)
                }
            }
        })

        this.deepLinkService.on('/person/:key', async (params) => {
            const personKey = params.key
            if (personKey) {
                try {
                    this.setLoading(true)
                    await this.friendWidgetController?.openChannelDetails([personKey])
                } catch (error) {
                    SentryService.captureError(error)
                } finally {
                    this.setLoading(false)
                }
            }
        })

        // Respond to the push notification being opened
        this.appController.notification.addNotificationOpenedListener('homeController', async (payload: any, actionIdentifier?: string) => {
            if (!this.hasHandledFirstNotification) {
                // Add a pause to make sure we are ready to handle this notification
                // TODO: Remove this if it doesn't help
                await AsyncUtils.sleep(1000)
                this.hasHandledFirstNotification = true
            }

            this.currentAccount.analytics.logEvent('app', 'responded-to-notification', undefined, {
                notification: payload
            })

            // First check the response for chat or deep link
            const chatPersonKeys = await this.appController.message.handleNotificationResponse(payload)
            const deepLink = payload.deepLink

            // Now handle the payload based on what we found above, either chat or deep link
            if (chatPersonKeys.length > 0) {
                try {
                    this.setLoading(true)
                    void this.friendWidgetController?.openChannelDetails(chatPersonKeys)
                } catch (error) {
                    SentryService.captureError(error)
                } finally {
                    this.setLoading(false)
                }
            } else if (deepLink) {
                const linkStr = deepLink as string

                this.currentAccount.analytics.logEvent('notification', 'tapped', linkStr)

                if (linkStr.startsWith('http')) {
                    console.log(`Opening deep link http url`)
                    await Linking.openURL(linkStr)
                } else if (linkStr.includes('call')) {
                    // Special case for Android calls
                    const urlParts = linkStr.split('/')
                    const callKey = urlParts[urlParts.length - 1]
                    if (callKey) {
                        try {
                            this.setLoading(true)
                            await this.appController.call.handleAnswerCallFromAndroidPushNotification(callKey, actionIdentifier)
                        } catch (error) {
                            SentryService.captureError(error)
                        } finally {
                            this.setLoading(false)
                        }
                    }
                } else {
                    const matched = this.deepLinkService.route(linkStr)
                    console.log(`Checked deep link route, matched: ${linkStr} = ${matched}`)
                }
            }
        })

        // Respond to opened links
        const processLink = (url: string) => {
            let { hostname, path, queryParams } = Linking.parse(url)
            if (url.startsWith('com.explanation.Pumice')) path = url.replace('com.explanation.Pumice://', '')

            if (path) {
                const matched = this.deepLinkService.route(path)
                console.log(`Checked deep link route, matched: ${url} = ${matched}`)
            }
        }

        this.linkEventSubscription = Linking.addEventListener('url', (event) => {
            processLink(event.url)
        })

        const initialUrl = await Linking.getInitialURL()
        if (initialUrl) processLink(initialUrl)

        // Now that we've set up routing, process any initial notifications
        await this.appController.notification.checkInitialNotification()
    }

    // Public methods

    public onInboxRefresh() {
        if (this.checkUnreadInboxOnNextRefresh) {
            this.checkUnreadInboxOnNextRefresh = false
            if (this.inboxWidgetController?.hasUnreadMessageStories) {
                this.setShowingInboxCallout(true)
            }
        }
    }

    public async search(searchText: string, searchMethod: string, abortController?: AbortController) {
        this.setLoading(true)
        this.searchButtonsController?.setSearchText(searchText)

        try {
            const book = await this.currentAccount.api.post("browse.search_library_path", {
                search_phrase: searchText,
                search_method: searchMethod,
                force_create: this.currentAccount.personData.debugForceVizzCreate
            }, false, abortController?.signal) as BookModel

            this.openBook(book, `search-${searchMethod}`)
        } catch (error) {
            if ((error as Error).name == 'AbortError') {
                return
            }
        } finally {
            this.setLoading(false)
        }
    }

    public openBookForRobloxGame(game: RobloxGameModel) {
        void this.closeOverlays()

        let name = game.name
        name = name.replace(/!/g, '')
        name = name.replace(/\?/g, '')

        const book = {
            "id": 652047,
            "title": name,
            "title_override": true,
            "image_url": game.image_url,
            "pages": [],
            "chapters": [],
            "found_with_search_query": `Roblox ${name} secrets?`,
            "search_query_method": "auto",
            "search_query_type": "question",
            "type": "Concept"
        } as BookModel

        this.openBook(book, 'roblox-game', 0)
    }

    public openBook(book: BookModel | undefined, trigger: string, position?: number, focus?:'secrets'|'codes'|'trailer'|undefined) {
        TimedActivity.start(ActivityName.PLAY_VIDEO)
        TimedActivity.log(ActivityName.PLAY_VIDEO, 'open-book')

        this.setVoiceSearchActive(false)
        this.setScannerSearchActive(false)
        void this.closeOverlays()

        if (book == undefined) return
        this.setCurrentBookOpenPosition(position)

        if(trigger === 'push-notification'){
            if((book as GameBookModel).active_codes_count > 0){
                this.setFocus('codes')
            } else {
                this.setFocus('secrets')
            }
        } else {
            this.setFocus(focus)
        }
        this.setCurrentBookOpenTrigger(trigger)
        this.setCurrentBook(book)
    }

    public openBookFromPieces(trigger: string, id: number, title: string, type: 'Game'|'Concept', image_url?: string, first_video_id?: number) {
        if (type == 'Concept') {

        }
        const book = {
            id: id,
            title: title,
            type: type,
            image_url: image_url,
            first_video_id: first_video_id
        } as BookModel

        this.openBook(book, trigger)
    }

    public openVideo(video: VideoModel | undefined, trigger: string, position: number) {
        this.consoleDebug(`openVideo(${video}, ${trigger})`)

        void this.closeOverlays()

        if (video == undefined) return

        const page = {
            id: 'video' + video.id,
            title: video.title,
            video: video,
        } as PageModel

        const book = {
            id: undefined,
            title: page.title,
            pages: [page],
            chapters: [],
            type: 'Concept'
        } as BookModel

        this.setCurrentBookOpenPosition(position)
        this.setCurrentBookOpenTrigger(trigger)
        this.setCurrentBook(book)
    }

    @computed
    get gameDetailVisible(): boolean {
        return this.currentBook != undefined &&
            this.currentBook.type == 'Game' &&
            !this.eligibleForInvitationReminder
    }

    @computed
    get conceptDetailVisible(): boolean {
        return this.currentBook != undefined &&
            this.currentBook.type == 'Concept' &&
            !this.eligibleForInvitationReminder
    }

    @computed
    public get eligibleForInvitationReminder(): boolean {
        return false
    }

    public async closeOverlays(trigger?: string) {
        this.currentAccount.updatePlayingVizz(null)
        this.setLoading(false)
        this.setCurrentBook(undefined)
        this.setCurrentBookOpenTrigger(undefined)
        this.setCurrentBookOpenPosition(undefined)

        this.setCurrentVideo(undefined)

        this.friendWidgetController?.hideAddFriend()
        this.friendWidgetController?.hideInvitationReminder()
        this.friendWidgetController?.hideCreateGroup()

        await this.friendWidgetController?.closeChannelDetails()

        if (this.appController.call.activeCallConnection) {
            this.friendWidgetController?.setShowingMinimizedCall(true)
        }

        if (trigger != 'search-text') this.searchButtonsController?.setSearchText('')

        await this.inboxWidgetController?.refreshInbox()
    }

    public closeBook() {
        this.currentAccount.updatePlayingVizz(null)
        this.setLoading(false)
        this.setCurrentBook(undefined)
        this.setCurrentBookOpenTrigger(undefined)
        this.setCurrentBookOpenPosition(undefined)
        this.setCurrentVideo(undefined)
        void this.inboxWidgetController?.refreshInbox()
    }

    public goToOnboarding() {
        this.setState(HomeState.ONBOARDING)
    }


    @action
    public setVoiceSearchActive(voiceSearchActive: boolean) {
        this.consoleDebug(`setVoiceSearchActive(${voiceSearchActive})`)
        this.voiceSearchActive = voiceSearchActive
    }

    @action
    public setScannerSearchActive(scannerSearchActive: boolean) {
        this.consoleDebug(`setScannerSearchActive(${scannerSearchActive})`)
        this.scannerSearchActive = scannerSearchActive
    }

    // MOVE THESE INTO SERIES & PHASE-OUT CHANNEL
    @action
    public setLoading(loading: boolean) {
        this.consoleDebug(`setLoadingSearchResults(${loading})`)
        this.loading = loading
    }

    @action
    public setCurrentBook(currentBook: BookModel|undefined) {
        this.consoleDebug(`setCurrentBook(${currentBook})`)
        this.currentBook = currentBook
    }

    @action
    public setCurrentBookOpenTrigger(currentBookOpenTrigger: string|undefined) {
        this.consoleDebug(`setCurrentBookOpenTrigger(${currentBookOpenTrigger})`)
        this.currentBookOpenTrigger = currentBookOpenTrigger
    }

    @action
    public setCurrentBookOpenPosition(currentBookOpenPosition: number|undefined) {
        this.consoleDebug(`setCurrentBookOpenPosition(${currentBookOpenPosition})`)
        this.currentBookOpenPosition = currentBookOpenPosition
    }

    @action
    public setFocus(focus: 'secrets'|'codes'|'trailer'|undefined) {
        this.consoleDebug(`setFocus(${focus})`)
        this.focus = focus
    }

    @action
    public setCurrentVideo(currentVideo: VideoModel|undefined) {
        this.consoleDebug(`setCurrentVideo(${currentVideo})`)
        this.currentVideo = currentVideo
    }

    @action
    public setShowingInboxCallout(showing: boolean) {
        this.isShowingInboxCallout = showing
    }

    @action
    public setState(state: HomeState) {
        this.consoleDebug(`setState()`)
        this.state = state
    }

    public async loadInitialRecentGames() {
        if (this.recentGameBooks && this.recentGameBooks.length > 0) return

        // TODO: We should move polling out of friendWidgetController and into homeController, then this method could simply do a one-off polling refresh
        const statusResponse = await ProfileStatusService.frequentUpdateStatus(this.currentAccount, this.appController) as SocialStatusUpdateResponse // does not start working until personData.profileOnboardingNeeded = false

        if (statusResponse != undefined) {
            const myRecentGameBooks = statusResponse.recent_game_books as GameBookModel[]
            if (myRecentGameBooks && myRecentGameBooks.length > 0) {
                this.setRecentGameBooks(myRecentGameBooks)
                this.setHasLoadedRobloxGames(true)
            }
        }
    }

    public checkMomentsAfterForegroundTasks() {
        this.momentsAfterForegroundTimeout = setTimeout(() => {
            this.consoleDebug('Checking moments after foreground tasks')

            if (!this.friendWidgetController.personKeysForChannelDetail &&
                !this.gameDetailVisible &&
                !this.friendWidgetController.addFriendVisible &&
                !this.friendWidgetController.groupCallVisible) {
                void this.executeMomentsAfterForegroundTasks()
            }
        }, 6500)
    }

    private async executeMomentsAfterForegroundTasks() {
        this.consoleDebug('Running moments after foreground tasks')

        try {
            const draftFriendRequests = await this.currentAccount.api.get('friend_requests_path', {state: 'draft'}) as OutgoingFriendRequestModel[]
            if (draftFriendRequests.length > 0) {
                this.consoleDebug('Show draft friend requests')
                this.setDraftFriendRequests(draftFriendRequests)
                return
            }
        } catch (error) {
            SentryService.captureError(error)
        }
    }

    private async showRateApp() {
        if (this.currentAccount.isAppReviewer) return

        LavaAlert.showModel({
            title: 'Do you love Lava so far?',
            buttons: [
                {text: 'Not yet', icon: 'thumbs-down', variation: 'primary', onPress: () => this.ratedAppNegatively()},
                {text: 'Yes, 5 stars!', icon: 'thumbs-up', variation: 'primary', onPress: () => this.ratedAppPositively()}
            ],
            speak: true
        })
    }

    private async ratedAppPositively() {
        this.currentAccount.analytics.logEvent('rate-app', 'thumbs-up')

        try {
            if (await StoreReview.isAvailableAsync()) {
                await StoreReview.requestReview()
            }
        } catch (error) {
            SentryService.captureError(error)
        }
    }

    private async ratedAppNegatively() {
        this.currentAccount.analytics.logEvent('rate-app', 'thumbs-down')
    }

    // END

    // Private instance utility methods

    private consoleDebug(method: string, force: boolean = false) {
        if (this.debug || force) console.log(`${this.constructor.name}: ${method}`)
    }
}

export default HomeController
