import {observer} from 'mobx-react'
import React from 'react'
import {Text, View, Linking} from 'react-native'
import {Color, Styles} from '../../lib/Appearance'
import {PrimaryButton} from '../components/PrimaryButton'

const LandingScreen = observer(() => {

    const handleMoreInfo = async () => {
        await Linking.openURL('https://about.lava.co');
    }

    return (
        <View style={{ flex: 1, backgroundColor: '#000000' }}>
            <View style={{alignSelf: 'flex-end', padding: 20}}>
            </View>
            <View style={{ padding: 20 }}>
                <PrimaryButton title="About Lava" onPress={handleMoreInfo} />
            </View>
            <View style={{flex: 1, padding: 20, backgroundColor: Color.black, alignSelf: 'stretch'}}>
                <View style={{flex: 1, flexDirection: "row", alignSelf: "stretch"}}>
                    <Text style={[Styles.footerText, Styles.textAlignLeft]}>&copy; 2023</Text>
                    <Text style={[Styles.footerText, Styles.textAlignCenter]}>Lava.co&trade;</Text>
                    <Text style={[Styles.footerText, Styles.textAlignRight]}>All rights reserved.</Text>
                </View>
            </View>
        </View>
    )
})

export default LandingScreen
