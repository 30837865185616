import React from 'react'
import {StyleSheet, Text, View} from "react-native"
import {MaterialCommunityIcons} from "@expo/vector-icons"
import {Typography} from "../../../../app/lib/Typography"
import {Button} from "react-native-paper"
import {CallNotification} from "../../../../app/lib/CallNotification"

type Props = {
    notification: CallNotification
    onPress: () => void
}

export const CallMessage = (props: Props) => {

    const message = () => {
        return props.notification.displayUsernames.length > 1 ? 'Group video call' : 'Video call'
    }

    return (
        <View style={{flex: 1}}>
            <View style={{flex: 1, flexDirection: 'row'}}>
                <View style={{
                    marginEnd: 8,
                    width: 40,
                    height: 40,
                    backgroundColor: 'white',
                    borderRadius: 6,
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                    <MaterialCommunityIcons name="video" size={24} color="green"/>
                </View>

                <View style={{flex: 1, marginTop: 2}}>
                    <Text style={[Typography.label]}>{props.notification.displayUsernames.join(', ').replace(/_/g, ' ')}</Text>
                    <Text style={[Typography.label]}>{message()}</Text>
                </View>

                <View style={{width: 60, justifyContent: 'center', alignItems: 'center'}}>
                    <Button
                        style={{width: 60}}
                        mode={'contained'}
                        color={'green'}
                        dark={true}
                        compact={true}
                        onPress={props.onPress}>
                        Join
                    </Button>
                </View>
            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    title: {}
})