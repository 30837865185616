import {action, makeObservable, observable} from "mobx"
import {CurrentAccount} from "../../../../vizz_account/lib/CurrentAccount"
import {AppController} from "../../../../../app/controllers/AppController"

export enum IndexState {
    LOADING,
    LOADED
}

export class IndexController {
    private debug: boolean = false  // don't set this to true in production

    private currentAccount: CurrentAccount
    public appController: AppController

    @observable state: IndexState = IndexState.LOADING

    constructor(currentAccount: CurrentAccount, appController: AppController) {
        this.consoleDebug(`new()`)

        this.currentAccount = currentAccount
        this.appController = appController

        makeObservable(this)
    }

    public async initialize() {
        this.consoleDebug(`initialize()`)

        this.setState(IndexState.LOADED)
    }

    public uninitialize() {
        this.consoleDebug(`uninitialize()`)
    }


    // Public methods



    // Private helper methods

    @action
    private setState(state: IndexState) {
        this.consoleDebug(`setState()`)
        this.state = state
    }


    // Private instance utility methods

    private consoleDebug(method: string, force: boolean = false) {
        if (this.debug || force) console.log(`${this.constructor.name}: ${method}  state = ${this.state}`)
    }
}
