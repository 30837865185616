import {RobloxFriendModel} from "../../models/RobloxFriendModel"
import {observer} from "mobx-react"
import {StyleSheet, Text, View, ViewStyle} from "react-native"
import {LavaImage} from "../../../../app/views/components/LavaImage"
import {MaterialCommunityIcons} from "@expo/vector-icons"
import {Color} from "../../../../app/lib/Appearance"
import {TouchableRipple} from "react-native-paper"
import React from "react"

type Props = {
    robloxFriend: RobloxFriendModel
    onPress?: () => void
    disabled?: boolean
    isSelected?: boolean
    style?: ViewStyle
}

export const FriendCard = observer((props: Props) => {
    const width = 170
    const innerWidth = 156

    return (
        <TouchableRipple
            onPress={props.onPress}
            onLongPress={props.onPress}
            disabled={props.disabled ?? false}
        >
            <View style={{backgroundColor: 'transparent', alignItems: 'center', width: width, height: 235, ...props.style}}>
                <View style={{width: innerWidth}}>
                    <LavaImage style={{
                        marginTop: (width - innerWidth) / 2,
                        width: innerWidth,
                        height: innerWidth,
                        borderColor: 'rgba(234,234,223,0.4)',
                        borderWidth: 1,
                        backgroundColor: '#BED5ED60',
                        borderRadius: 20,
                        overflow: 'hidden'
                    }}
                               source={{uri: props.robloxFriend.avatar_url}}
                               resizeMode={'center'}
                    />
                    <Text style={{fontSize: 16, color: 'white', fontWeight: 'bold', marginTop: 10, overflow: 'hidden'}}
                          numberOfLines={1}>{props.robloxFriend.display_name}</Text>
                    <Text style={{fontSize: 13, color: 'white', marginTop: 2}} numberOfLines={1}>@{props.robloxFriend.name}</Text>
                    {props.isSelected && <View style={{
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        justifyContent: 'center',
                        paddingBottom: 50,
                        alignItems: 'center', ...StyleSheet.absoluteFillObject
                    }}>
                        <MaterialCommunityIcons name="check-circle" size={64} color={Color.lavaGray}/>
                    </View>}
                </View>
            </View>
        </TouchableRipple>
    )
})

