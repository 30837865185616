import {action, makeObservable, observable} from "mobx"
import {CurrentAccount} from "../../../../../vizz_account/lib/CurrentAccount"
import VideoListService from "../../../../services/VideoListService"
import VideoModel from "../../../../models/VideoModel"
import BookController from "../../BookController"
import GameBookController from "../../../roblox_games_widget/GameBookController"
import SentryService from "../../../../../../app/services/SentryService"
import {YouTubeUtils} from "../../../../../../app/utils/AVUtils"
import {HttpError} from "../../../../../../app/services/RailsAPIService"
import {AsyncUtils} from "../../../../../../app/utils/AsyncUtils"


export enum PageState {
    RELATED_LOADING,
    RELATED_LOADED
}

export class PageController {
    private debug: boolean = false  // don't set this to true in production

    private currentAccount: CurrentAccount
    private video: VideoModel
    private bookController: BookController | GameBookController
    private pageIndex: number

    public relatedVideoList: VideoListService

    @observable state: PageState = PageState.RELATED_LOADING

    constructor(currentAccount: CurrentAccount, bookController: BookController | GameBookController, video: VideoModel, pageIndex: number) {
        this.consoleDebug(`new()`)

        this.currentAccount = currentAccount
        this.video = video
        this.bookController = bookController
        this.pageIndex = pageIndex
        this.relatedVideoList = new VideoListService(this.currentAccount,
            async (data) => {
                try {
                    const relatedVideos = await this.currentAccount.api.get(`browse.videos_path()`, {id: data}) as VideoModel[]
                    const filteredRelatedVideos = await this.filterRelatedVideos(relatedVideos)
                    return filteredRelatedVideos
                } catch (error) {
                    if (!(error instanceof Error)) return []
                    const isNotFound = (error instanceof HttpError) && error.status == "not_found"

                    if (!isNotFound) SentryService.captureError(error)

                    return []
                }
            }
        )

        makeObservable(this)
    }

    public async initialize() {
        this.consoleDebug(`initialize()`)

        await AsyncUtils.sleep(500)
        await this.loadRelatedVideos()
    }

    public uninitialize() {
        this.consoleDebug(`uninitialize()`)
    }


    // Public methods

    public addVideo(video: VideoModel, trigger?: string) {
        this.consoleDebug(`openVideo()`)
        this.bookController.addVideoAfterIndex(video, this.pageIndex)
    }

    public async loadRelatedVideos() {
        if (await this.bookController.shouldShowRelatedVideos() && this.video.youtube_key)
            await this.relatedVideoList.initialize(this.video.youtube_key)

        this.setState(PageState.RELATED_LOADED)
    }

    public async filterRelatedVideos(videos: VideoModel[]): Promise<VideoModel[]> {
        const isViewablePromises = videos.map(async (video) => {
            if (video.youtube_key != undefined) {
                const videoIsPlayable = await YouTubeUtils.isVideoPlayable(video.youtube_key)
                return videoIsPlayable
            }
            return false
        })

        const isViewableArray = await Promise.all(isViewablePromises)
        const filteredVideos = videos.filter((_, index) => isViewableArray[index])
        return filteredVideos
    }

    // Private helper methods

    @action
    private setState(state: PageState) {
        this.consoleDebug(`setState()`)
        this.state = state
    }


    // Private instance utility methods

    private consoleDebug(method: string, force: boolean = false) {
        if (this.debug || force) console.log(`${this.constructor.name}: ${method}  state = ${this.state}`)
    }
}
