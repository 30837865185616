import {observer} from "mobx-react"
import React, {useContext, useEffect, useState} from "react"
import {ScrollView, StyleSheet, View} from "react-native"
import {DraftFriendsController} from "../../controllers/friend_widget/DraftFriendsController"
import CurrentAccountContext from "../../../vizz_account/lib/CurrentAccount"
import useTheme from "../../../lava-components/components/hooks/useTheme"
import {OutgoingFriendRequestModel} from "../../models/OutgoingFriendRequestModel"
import Title from "../../../lava-components/components/atoms/Title/Title"
import {isPortrait, isTablet} from "../../../../app/lib/Appearance"
import {FriendCard} from "./FriendCard"
import Button from "../../../lava-components/components/atoms/Button/Button"
import {PrimaryButton} from "../../../../app/views/components/PrimaryButton"

type Props = {
    draftFriendRequests: OutgoingFriendRequestModel[]
    onFinish: () => void
}

export const DraftFriends = observer((props: Props) => {

    const currentAccount = useContext(CurrentAccountContext)
    const [controller] = useState(() => new DraftFriendsController(currentAccount, props.draftFriendRequests, props.onFinish))
    const theme = useTheme()

    useEffect(() => {
        void controller.initialize()
        return () => controller.uninitialize()
    }, [])

    const ApproveRequest = () => {
        const request = controller.requestsRequiringAction.at(0)
        if (!(request?.destination_person?.roblox_profile)) return null

        return (
            <View style={{alignItems: 'center'}}>
                <Title style={{marginBottom: 44}} variation={'title1'}>Is this a real friend?</Title>
                <View style={{flexDirection: isPortrait() ? 'column' : 'row', alignItems: 'center'}}>
                    <FriendCard robloxFriend={request?.destination_person?.roblox_profile}/>
                    <View style={{flexDirection: 'row', marginStart: isTablet() ? 54 : undefined,marginTop: isPortrait() ? 24 : undefined}}>
                        <Button style={{marginEnd: 22}} variation={'secondary'}
                                onPress={() => controller.actOnRequest(request, false)}>No</Button>
                        <Button variation={'primary'}
                                onPress={() => controller.actOnRequest(request, true)}>Yes</Button>
                    </View>
                </View>
            </View>
        )

    }

    const FriendsWithLava = () => {
        const requests = controller.approvedWithLava
        const title = requests.length > 1 ? 'These friends already have Lava.' :
            'This friend already has Lava.'

        return (
            <View style={{alignItems: 'center'}}>
                <Title style={{marginBottom: 44}} variation={'title1'}>{title}</Title>
                <View style={{width: '100%', height: 235, alignItems: 'center'}}>
                    <ScrollView style={{flex: 1}} horizontal={true}>
                        {requests.map((r) => {
                            if (!r.destination_person?.roblox_profile) return null
                            return <FriendCard style={{marginHorizontal: 8}} key={r.id} robloxFriend={r.destination_person.roblox_profile} />
                        })}
                    </ScrollView>
                </View>
                <PrimaryButton
                    style={{marginTop: 40}}
                    onPress={() => controller.onFriendsWithLavaAction()}
                    iconType={'FontAwesome'}
                    icon={'thumbs-up'}
                    iconSide={'right'}
                />
            </View>
        )
    }

    const FriendsWithoutLava = () => {
        const requests = controller.approvedWithoutLava
        const title = requests.length > 1 ? 'These friends need Lava.' :
            'This friend needs Lava.'

        return (
            <View style={{alignItems: 'center'}}>
                <Title style={{marginBottom: 44}} variation={'title1'}>{title}</Title>
                <View style={{width: '100%', height: 235, alignItems: 'center'}}>
                    <ScrollView style={{flex: 1}} horizontal={true}>
                        {requests.map((r) => {
                            if (!r.destination_person?.roblox_profile) return null
                            return <FriendCard style={{marginHorizontal: 8}} key={r.id} robloxFriend={r.destination_person.roblox_profile} />
                        })}
                    </ScrollView>
                </View>
                <PrimaryButton
                    style={{marginTop: 40}}
                    onPress={() => controller.onFriendsWithoutLavaAction()}
                    iconType={'FontAwesome'}
                    icon={'thumbs-up'}
                    iconSide={'right'}
                />
            </View>
        )

    }

    return (
        <View style={{
            flex: 1,
            backgroundColor: 'rgba(0, 0, 0, 0.75)',
            justifyContent: 'center',
            alignItems: 'center', ...StyleSheet.absoluteFillObject
        }}>
            <View style={{
                maxWidth: '98%',
                backgroundColor: theme.colors.secondaryBk,
                borderWidth: 0.5,
                borderColor: theme.colors.tertiarySand20,
                borderRadius: 2,
                padding: 32
            }}>
                {controller.state === 'approval' && ApproveRequest()}
                {controller.state === 'friends-with-lava' && FriendsWithLava()}
                {controller.state === 'friends-need-lava' && FriendsWithoutLava()}
            </View>
        </View>
    )
})