import {AppController} from "../../../app/controllers/AppController"
import {AsyncUtils} from "../../../app/utils/AsyncUtils"
import {DeviceUtils} from "../../../app/utils/DeviceUtils"
import {CurrentAccount} from "../../vizz_account/lib/CurrentAccount"
import {ProfileStatus} from "../models/ProfileStatus"
import {SocialStatusUpdateResponse} from "../models/SocialStatusUpdateResponse"
import {FeatureFlagModel} from "../../vizz_account/models/FeatureFlagModel"
import {RawState} from "../../../app/services/NativeStateService"
import SentryService from "../../../app/services/SentryService"
import {HttpError} from "../../../app/services/RailsAPIService"
import {ErrorUtils} from "../../../app/utils/ErrorUtils"

class ControlFlowError extends Error {}

export class ProfileStatusService {
    private static debug: boolean = false  // don't set this to true in production

    private static statusWasOnline?: boolean
    private static isFrequentUpdateInProgress: boolean = false

    public static async isScreenOn() {
        return await DeviceUtils.getBrightnessAsync() > 0
    }

    public static async isContinuousScreenWithoutRinging(currentAccount: CurrentAccount, duration: number) {
        let continuousScreenOn = await this.isScreenOn()
        let newScreenOn

        for (let i = 0; i < duration; i+=500) {
            const startTime = performance.now()

            if (await currentAccount.personData.getFromStorage('incomingCallKey') != null) throw new ControlFlowError('Call happened while measuring. Measurement is invalid.')
            if (!continuousScreenOn) break

            const sleepDuration = 500 - (performance.now() - startTime)
            if (sleepDuration > 0 && duration > 500) await AsyncUtils.sleep(sleepDuration)
            if (duration < 500) break

            if (continuousScreenOn != (newScreenOn = await this.isScreenOn())) {
                if (newScreenOn == false) { // return faster by being conservative, helps catch a final "user offline" event when app is killed after call end
                    continuousScreenOn = false
                    break
                } else
                    throw new ControlFlowError('Screen turned on while measuring. Measurement is invalid.')
            }
        }

        return continuousScreenOn
    }

    public static async occasionalUpdateStatus(rawState: RawState, data?: any) { // Do not run this method too frequently, it's slow.
        this.consoleDebug(`occasionalUpdateStatus(${rawState})`)

        const startTime = performance.now()

        const currentAccount = new CurrentAccount()

        try {
            await currentAccount.initialize('occasionalUpdateStatus')

            const loopStartTime = performance.now()
            const continuousScreenOnWithoutRinging = await this.isContinuousScreenWithoutRinging(currentAccount, 18000)
            const loopEndTime = performance.now()

            this.consoleDebug(`Active: ${continuousScreenOnWithoutRinging}`)

            const profileStatus = continuousScreenOnWithoutRinging ? ProfileStatus.ONLINE : ProfileStatus.OFFLINE
            const ignoreResponse = await this.setStatusAndGetFriends(undefined, currentAccount, profileStatus, rawState, data)

            this.consoleDebug(`Done updating status`)

            // currentAccount.analytics.logEvent('background', 'update-status', rawState, {
            //     data: data,
            //     continuous_screen_on_without_ringing: continuousScreenOnWithoutRinging,
            //     incoming_call_key: await currentAccount.personData.getFromStorage('incomingCallKey'),
            //     duration: (performance.now() - startTime)/1000,
            //     loop_duration: (loopEndTime - loopStartTime)/1000,
            // })
        } catch(e) {
            // currentAccount.analytics.logEvent('background', 'update-status', `${rawState} - failed`, {
            //     data: data,
            //     continuous_screen_on_without_ringing: e,
            //     incoming_call_key: await currentAccount.personData.getFromStorage('incomingCallKey'),
            //     duration: (performance.now() - startTime)/1000,
            // })

            // don't bother to recover from exception, just wait for next run of this function
        }
    }

    public static async frequentUpdateStatus(currentAccount: CurrentAccount, appController: AppController): Promise<any> {
        let response = undefined
        if (!currentAccount.hasFeature(FeatureFlagModel.SOCIAL_POLLING)) return
        if (this.isFrequentUpdateInProgress) {
            this.consoleDebug('Frequent update already in progress, aborting. ')
            return response
        }

        let hasIncomingCall = false
        let hasActiveCall = false

        try {
            this.isFrequentUpdateInProgress = true

            hasIncomingCall = await currentAccount.personData.getFromStorage('incomingCallKey') != null
            hasActiveCall = await currentAccount.personData.getFromStorage('activeCallKey') != null

            const isScreenOn = await this.isContinuousScreenWithoutRinging(currentAccount, 2500)
            const isOnline = (isScreenOn || hasActiveCall) // if activeCall, keep user online even if screen is off
            const statusJustChanged = (this.statusWasOnline != isOnline)

            if (isOnline || statusJustChanged) {
                const profileStatus = isOnline ? ProfileStatus.ONLINE : ProfileStatus.OFFLINE

                response = await ProfileStatusService.setStatusAndGetFriends(appController, currentAccount, profileStatus, appController.nativeState.rawState, { isScreenOn: isScreenOn, hasActiveCall: hasActiveCall, statusJustChanged: statusJustChanged })

                this.statusWasOnline = isOnline
            } else {
                if (hasIncomingCall) throw new ControlFlowError('Has an incoming call')
            }

            // If we made it here, polling is working
            if (appController.lastPollFailed) {
                appController.setLastPollFailed(false)
            }
        } catch(error) {
            if (error instanceof ControlFlowError) {
                if (hasIncomingCall) // do not set this user's status one way or the other, but we still want to complete the poll because it can impact the call state
                    response = await ProfileStatusService.setStatusAndGetFriends(appController, currentAccount, undefined, appController.nativeState.rawState, { error: error })
            } else {
                appController.setLastPollFailed(true)

                if (!(error instanceof HttpError)) return // not sure what this could ever be

                if (error.status == "not_found" || error.status == "unauthorized") // when a person is deleted
                    void appController.onLoadError(new Error('Unable to initialize your account.'))

                else if (!ErrorUtils.isInternetProblemError(error))
                    SentryService.captureError(error)
            }
        } finally {
            this.isFrequentUpdateInProgress = false
        }

        return response
    }

    public static async setStatusAndGetFriends(appController: AppController|undefined, currentAccount: CurrentAccount, profileStatus: ProfileStatus|undefined, statusFromRawState: RawState, data?: any): Promise<undefined | SocialStatusUpdateResponse> {
        this.consoleDebug(`setStatusAndGetFriends(${profileStatus}, ${statusFromRawState})`)

        if (currentAccount.personData.profileOnboardingNeeded) return

        let talking, callId, watching, videoId

        if (appController == undefined) { // when the app wakes up from the background, it will be here
            talking = false
            callId = undefined
            watching = false
            videoId = undefined
        } else {
            talking = appController.call.activeCallConnection != undefined
            callId = appController.call.activeCallConnection?.call?.id
            watching = appController.video.playingVideo != undefined
            videoId = appController.video.playingVideo?.id
        }

        let response: SocialStatusUpdateResponse|undefined = undefined
        response = await currentAccount.api.patch('social.profile_update_path', {
            status: profileStatus,
            status_from_app_state: statusFromRawState,
            talking: talking,
            call_id: callId,
            watching: watching,
            video_id: videoId,
            data: JSON.stringify(data),
        }) as SocialStatusUpdateResponse

        return response
    }

    private static consoleDebug(method: string, force: boolean = false) {
        if (this.debug || force) console.log(`ProfileStatusService: ${method}`)
    }
}