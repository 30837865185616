import {action, makeObservable, observable} from "mobx"
import {CurrentAccount} from "../../vizz_account/lib/CurrentAccount"
import VideoModel from "../models/VideoModel"
import SentryService from "../../../app/services/SentryService"


class VideoListService {
    private debug: boolean = false  // don't set this to true in production

    private currentAccount: CurrentAccount
    private getVideos: (data: any) => Promise<VideoModel[]>
    @observable videos: VideoModel[] = []

    constructor(currentAccount: CurrentAccount, getVideos: ((data: any) => Promise<VideoModel[]>)) {
        this.consoleDebug(`new()`)

        this.currentAccount = currentAccount
        this.getVideos = getVideos
        makeObservable(this)
    }

    public async initialize(data: any) {
        this.consoleDebug(`initialize()`)

        await this.refresh(data)
    }

    public async refresh(data: any) {
        this.consoleDebug(`refresh()`)

        this.setVideos(await this.getVideos(data))
    }

    public suggestVideosAfter(video: VideoModel) {
        this.consoleDebug(`suggestVideosAfter()`)

        const index = this.videos.indexOf(video)
        const NUMBER_TO_SUGGEST = 2

        if (index < 0) return
        for (let i = 1; i <= NUMBER_TO_SUGGEST; i++) {
            if (this.videos[index+i]) {
                // we don't want to block on these calls so we fire them both off quickly and do nothing with the response
                void (async () => {
                    try {
                        await this.currentAccount.api.post(`social.create_video_stories_path`, { video_id: this.videos[index+i].id })
                    } catch (error) {
                        SentryService.captureError(error)
                    }
                })()
            }
        }
    }


    // Private methods

    @action
    private setVideos(videos: VideoModel[]) {
        this.consoleDebug(`setVideos()`)
        this.videos = videos
    }


    // Private instance utility methods

    private consoleDebug(method: string, force: boolean = false) {
        if(this.debug || force) console.log(`${this.constructor.name}: ${method}`)
    }
}

export default VideoListService
