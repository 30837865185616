import * as Notifications from 'expo-notifications'
import {Audio} from "expo-av"
import {CurrentAccount} from "../../modules/vizz_account/lib/CurrentAccount"
import {Camera} from 'expo-camera'

export class PermissionUtils {

    // public static async isNotificationsEnabled() {
    // }

    // public async canRequestNotifications() {
    // }

    // public static async requestNotifications(currentAccount: CurrentAccount) {
    // }

    public static async isAudioEnabled() {
        const result = await Audio.getPermissionsAsync()
        return (result.status === 'granted')
    }

    public static async canRequestAudio() {
        const result = await Audio.getPermissionsAsync()
        return (result.status !== 'granted' && result.canAskAgain)
    }

    public static async requestAudio(currentAccount: CurrentAccount) {
        try {
            currentAccount.analytics.logEvent('audio-permission', 'started')
            let startingEnabled = await this.isAudioEnabled()
            let finalEnabled = startingEnabled
            if (!startingEnabled) {
                const { status } = await Audio.requestPermissionsAsync()
                finalEnabled = (status === 'granted')

                if (finalEnabled) {
                    currentAccount.analytics.logEvent('audio-permission', 'granted')
                }
            }
            if (!finalEnabled) throw Error('Not enabled')
            return true
        } catch (err) {
            currentAccount.analytics.logEvent('audio-permission', 'rejected')
            currentAccount.analytics.logEvent('audio-permission', 'finished')
            console.error(err)
            return false
        }
    }

    public static async isCameraEnabled() {
        const result = await Camera.getCameraPermissionsAsync()
        return (result.status === 'granted')
    }

    public async canRequestCamera() {
        const result = await Camera.getCameraPermissionsAsync()
        return (result.status !== 'granted' && result.canAskAgain)
    }

    public static async requestCamera(currentAccount: CurrentAccount) {
        try {
            currentAccount.analytics.logEvent('camera-permission', 'started')
            let startingEnabled = await this.isCameraEnabled()
            let finalEnabled = startingEnabled
            if (!startingEnabled) {
                const { status } = await Camera.requestCameraPermissionsAsync()
                finalEnabled = (status === 'granted')

                if (finalEnabled) {
                    currentAccount.analytics.logEvent('camera-permission', 'granted')
                }
            }
            if (!finalEnabled) throw Error('Not enabled')
            return true
        } catch (err) {
            currentAccount.analytics.logEvent('camera-permission', 'rejected')
            currentAccount.analytics.logEvent('camera-permission', 'finished')
            console.error(err)
            return false
        }
    }
}
