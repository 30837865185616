import React, {useEffect} from 'react'
import {StyleSheet, Text, View, ViewStyle} from "react-native"
import {observer} from "mobx-react"
import {Typography} from "../../../../app/lib/Typography"
import {FeedWidgetController} from '../../controllers/FeedWidgetController'
import {LavaImage} from "../../../../app/views/components/LavaImage"

type Props = {
    title: string
    body: string
    controller?: FeedWidgetController
    style?: ViewStyle
}

export const GenericStory = observer((props: Props) => {

    useEffect(() => {
        return () => {
        }
    }, [])

    return (
        <View style={[styles.container, props.style]}>
            <View style={{flexDirection: 'row'}}>
                <LavaImage style={{width: 50, height: 50, marginRight: 10}} source={{uri: "https://res.cloudinary.com/explanation/image/upload/w_200,h_200/v1688759619/logos/lava-logo_dqi5zt.png"}} />
                <Text style={[Typography.headline2, {color: 'white', fontSize: 18, flexGrow: 1, paddingTop: 13}]}>
                    {props.title}
                </Text>
            </View>
            <View style={{flexGrow: 1}}>
                <Text style={[Typography.label, {color: 'white', marginTop: 8, fontSize: 13, maxWidth: 250}, { marginTop: 20 }]}>{props.body}</Text>
            </View>
        </View>
    )
})

const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: 'column',
        padding: 18,
        backgroundColor: 'rgba(22,30,44,1)',
        borderRadius: 8,
    },
})
