import React, {useCallback, useRef, useState} from 'react'
import {SafeAreaView} from 'react-native'
import {StyleSheet, Text, View, Animated, PanResponder} from 'react-native'
import {FlatList} from 'react-native-gesture-handler'

declare module 'react-native' {
    namespace Animated {
      interface Value {
        _value: number;
      }
    }
  }
  
const Feed = ({index}:any) => (
    <View style={styles.feed}>
        <Text style={styles.text}>News feed {index}</Text>
    </View>
)

const Friend = ({index}:any) => (
    <View style={styles.friend}>
        <Text style={styles.text}>Friend {index}</Text>
    </View>
)

const viewabilityConfig = {
    waitForInteraction: true,
    viewAreaCoveragePercentThreshold: 0
}

export const Home = () => {
    const [_, setState] = useState(false)
    const focusItemRef = useRef<number>(0)
    const flatListRef = useRef<FlatList>(null)
    const marginLeft = useRef(new Animated.Value(0)).current
    const marginTop = useRef(new Animated.Value(20)).current;
    const prevOffset = useRef(0);
    const scrollDirection = useRef('left');
    const onViewableItemsChanged = useCallback(({ viewableItems}:any ) => focusItemRef.current = viewableItems[0].index, []);
    const viewabilityConfigCallbackPairs = useRef([{viewabilityConfig,  onViewableItemsChanged }])

    const panResponder = useRef(
      PanResponder.create({
        onStartShouldSetPanResponder: () => marginTop._value > -340,
        onMoveShouldSetPanResponderCapture: () => marginTop._value > -340,
        onPanResponderGrant: () => { setState(false)},
        onPanResponderMove: (event, gestureState) => {
          if (marginTop._value === -340) {
            // Disable dragging when marginTop is already -320
            setState(true)
            return;
          }
          if (gestureState.dy >= -340 && gestureState.dy <= 0) {
            marginTop.setValue(0 + gestureState.dy);
            marginLeft.setValue(-gestureState.dy);
          }
        },
        onPanResponderRelease: (event, gestureState) => {
          if (marginTop._value <= -340) {
            // Already at marginTop -320, no need to animate
              setState(true)
            return;
          }
          if (gestureState.dy <= -10) {
            // Dragged enough to bring marginTop to -320
            Animated.timing(marginTop, {
              toValue: -340,
              duration: 200,
              useNativeDriver: false,
            }).start(() => {});

            Animated.timing(marginLeft, {
              toValue: 340,
              useNativeDriver: false,
              duration:100
          }).start(() => {
              flatListRef.current?.scrollToIndex({
                  animated:true,
                  index:0
              })
              setState(true)
          })
        
          } else {
            // Not dragged enough, reset marginTop to 0
            Animated.timing(marginTop, {
              toValue: 20,
              duration: 200,
              useNativeDriver: false,
            }).start();
          }
        },
      })
    ).current;

    const feedPanResponder = useRef(
      PanResponder.create({
        onStartShouldSetPanResponder: () => marginLeft._value > 1,
        onMoveShouldSetPanResponderCapture: () => marginLeft._value > 1,
        onPanResponderGrant: () => {setState(false)},
        onPanResponderMove: (event, gestureState) => {
          if(marginLeft._value < 1) {
              setState(true)
              return;
          }
          if(gestureState.dx >= -340 && gestureState.dx < 0){
              marginLeft.setValue(340 + gestureState.dx)
              if(gestureState.dx <= -10){
                Animated.timing(marginTop, {
                  toValue: 20,
                  duration: 50,
                  useNativeDriver: false,
                }).start();
              }
          }
        },
        onPanResponderRelease: (event, gestureState) => {
          if (marginLeft._value < 1) {
              setState(true)
              return;
          }
          if (gestureState.dx <= -10) {
              Animated.timing(marginLeft, {
                toValue: 0,
                duration: 100,
                useNativeDriver: false,
              }).start(() => setState(true))
            }
        },
      })
    ).current;

    return (
        <SafeAreaView style={{flex:1}}>
            <View style={{ flex: 1 }}>
                <Animated.View style={[styles.feedContent, {marginLeft: marginLeft}]} {...feedPanResponder.panHandlers}>
                    <FlatList 
                      horizontal={true}
                      data={[1,2,3,4,5,6,7,8,9,10,11,12]}
                      renderItem={({index})=> <Feed index={index}/>}
                      ref={flatListRef}
                      showsHorizontalScrollIndicator={false}
                      scrollEnabled={true}
                      contentContainerStyle={{ flexGrow: 1 }}
                      viewabilityConfigCallbackPairs={viewabilityConfigCallbackPairs.current}
                      scrollEventThrottle={16}
                    />
                </Animated.View>
                <Animated.View
                  style={{
                    marginTop: marginTop,
                    width:320,
                    flex: 1,
                  }}
                  {...panResponder.panHandlers}
                >
                    <FlatList
                        data={[1,2,3,4,5,6,7,8,9,10,11,12]}
                        renderItem={({index})=> <Friend index={index}/>}
                        scrollEnabled={marginTop._value <= - 320}
                        contentContainerStyle={{ flexGrow: 1 }}
                    />
                </Animated.View>
            </View>
        </SafeAreaView>
    )

}

const styles = StyleSheet.create({
    container:{
        flex:1,
        backgroundColor:'gray',
        flexDirection: 'column'
    },
    sideBar: {
        width:320,
        padding:20,
        backgroundColor: 'green',
        marginTop: 340,
        height: '100%',
        flex: 1
    },
    feedContent: {
        height:320,
        backgroundColor: 'transparent',
    },
    text: {
        color: '#fff'
    },
    feed: {
        height: 320,
        width: 320,
        backgroundColor:'blue',
        marginRight:20,
        justifyContent:'center',
        alignItems:'center'
    },
    friend: {
        height: 320,
        justifyContent:'center',
        alignItems:'center',
        backgroundColor: 'red'
    }
})