import {observer} from "mobx-react"
import {Widget} from "../../start/views/home/Widget"
import {FlatList, ListRenderItemInfo, View, ViewStyle} from "react-native"
import React, {useContext, useEffect, useState} from "react"
import HomeController from "../../start/controllers/HomeController"
import CurrentAccountContext from "../../vizz_account/lib/CurrentAccount"
import {RobloxGamesWidgetController, RobloxGameViewModel} from "../controllers/RobloxGamesWidgetController"
import theme from "../../lava-components/components/config/theme"
import {Color} from "../../../app/lib/Appearance"
import {ActivityIndicator} from "react-native-paper"
import {GameBookModel} from "../models/GameBookModel"
import NewGameCard from "../../lava-components/components/molecules/NewGameCard/NewGameCard"

type Props = {
    homeController: HomeController
    style?: ViewStyle
    onBookTapped: (book: GameBookModel, focus?: 'secrets'|'codes'|'trailer'|undefined) => void
    type: 'compact'|'regular'
    disabled?: boolean
}

export const RobloxGamesWidget = observer((props: Props) => {
    const currentAccount = useContext(CurrentAccountContext)
    const [controller] = useState(() => new RobloxGamesWidgetController(currentAccount, props.homeController))

    useEffect(() => {
        void controller.initialize()

        return () => {
            void controller.uninitialize()
        }
    }, [])


    const renderGame = (info: ListRenderItemInfo<RobloxGameViewModel>) => {
        const { game, isRecent} = info.item
        return (
            <NewGameCard
                key={`roblox-game-${game.id}`}
                placement={'game'}
                containerStyle={{marginEnd: theme.spacing.xxl}}
                name={game.title}
                imageUrl={game.image_url}
                variation={'active'}
                callout={controller.hasCodes(game) ? 'Codes' : undefined}
                hasPlayed={isRecent}
                onPress={() => props.onBookTapped(game, isRecent ? controller.hasCodes(game) ? 'codes' : 'secrets' : 'trailer')}
                />
        )
    }

    return (
        <Widget
            type={props.type}
            style={[props.style]}
            disabled={props.disabled}
            loading={props.homeController.recentGameBooks == undefined && controller.gameBooks == undefined}
            onQuadTap={()=> props.homeController.openBook({id: 644846, title: "Ghostbusters", type: "Concept"}, 'tapped-home')}
        >
            {(controller.combinedGameBooks && controller.combinedGameBooks.length > 0) ?
            <View style={{flex: 1, flexDirection: 'row'}}>
                <FlatList
                style={{flex: 1}}
                data={controller.combinedGameBooks}
                renderItem={renderGame}
                contentContainerStyle={{alignItems: 'center'}}
                horizontal={true}
                showsHorizontalScrollIndicator={false}/>
                </View>
            :
            <View style={{flex: 1, justifyContent: 'center', alignItems: 'flex-start'}}>
                <ActivityIndicator color={Color.primary} size={40} />
            </View>
            }
        </Widget>
    )
})
