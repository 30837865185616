import {Dimensions, Platform} from "react-native"
import {ManifestUtils} from "../utils/ManifestUtils"

const { detect } = require('detect-browser')

export default class AppConstants {

    static baseUrl(domain?: string): string {
        let mode = 'production'
        if (__DEV__) mode = 'dev'

        if (Platform.OS == 'web') {
            if (/[a-z]*\.{0,1}lava.dev/.exec(window.location.hostname) != null) mode = 'staging'

            if (mode == 'dev') {
                // actual hostname may be localhost or localtest.me, but we need to force localtest.me so we get subdomain support.
                const hostname = "localtest.me"
                // We are running inside of expo so we need to change the port
                // since expo serves the front-end from 19006 whereas rails
                // is running on 3000
                return `http://${hostname}:3000`
            }
            if (mode == 'staging' || mode == 'production') {
                return `https://${AppConstants.railsApiDomain(domain)}`
            }
        } else { // native
            if (__DEV__) {
                return `https://${ManifestUtils.tunnelHost}`
            } else {
                return `https://${AppConstants.railsApiDomain(domain)}`
            }
        }

        return '' // should never hit this but it makes typescript hapyp
    }

    static railsApiDomain(domain?: string): string {
        if (domain) return domain
        return ManifestUtils.railsApiDomain
    }

    static isRunningInSafari(): boolean {
        if (Platform.OS != 'web') return false
        const browser = detect()
        return browser?.name == 'safari'
    }

    static isRunningOnWeb(): boolean {
        return Platform.OS == 'web'
    }

    static isRunningOnMobileWeb(): boolean {
        return Platform.OS == 'web' && Dimensions.get('window').width < 600
    }

    public static isInternalUse() {
        const env = ManifestUtils.reactEnv
        return env == 'development' || env == 'staging'
    }

    public static expoSlug() {
        return ManifestUtils.expoSlug
    }
}
