import React, {useContext, useEffect, useState} from 'react'
import {View, Text, FlatList, ListRenderItemInfo} from "react-native"
import {observer} from "mobx-react"
import {useNavigation} from '@react-navigation/native'
import {Color} from '../../lib/Appearance'
import {Card, Button, TextInput, ActivityIndicator} from 'react-native-paper'
import CurrentAccountContext from '../../../modules/vizz_account/lib/CurrentAccount'

const PronunciationsScreen = observer(() => {

    const navigation = useNavigation<any>()
    const [pronunciations, setPronunciations] = useState<object[]>([])
    const currentAccount = useContext(CurrentAccountContext)
    const [loading, setLoading] = useState<boolean>(false)
    const [phrase, setPhrase] = useState<string>('')
    const [phonetic, setPhonetic] = useState<string>('')

    useEffect(() => {
        void refreshPage()
    }, [])

    const refreshPage = async () => {
        const results = await currentAccount.api.get("vizz_maker.pronunciations_path")
        setPronunciations(results)
    }

    const deleteItem = async (pronunciation: any) => {
        await currentAccount.api.delete(`vizz_maker.pronunciations_path(${pronunciation.id})`)
        await refreshPage()
    }

    const save = async () => {
        setLoading(true)
        const results = await currentAccount.api.post("vizz_maker.pronunciations_path", {
            phrase: phrase,
            phonetic: phonetic
        })

        setPhrase('')
        setPhonetic('')
        setLoading(false)
        await refreshPage()
    }

    const renderListItem = (info: ListRenderItemInfo<any>) => {
        const item: any = info.item

        return (
            <Card style={{margin: 16}}>
                <Card.Content>
                    <View style={{flex: 1, flexDirection: "row"}}>
                        <Text style={{flex: 1, alignSelf: "center"}}>
                            {(item as any)["phrase"]} &raquo; {(item as any)["phonetic"]}
                        </Text>
                        <Button
                            mode="contained"
                            onPress={() => deleteItem(item)}>
                            Delete
                        </Button>
                    </View>
                </Card.Content>
            </Card>
        )
    }

    return (
        <FlatList
            data={pronunciations}
            ListHeaderComponent={
                <View>
                    <Text style={{textAlign: "center", fontSize: 20}}>
                        Pronunciations
                    </Text>

                    <Card style={{margin: 16}}>
                        <Card.Content>
                            <View style={{flex: 1, flexDirection: "row"}}>
                                <TextInput
                                    mode={'outlined'}
                                    label={'Phrase'}
                                    style={{margin: 10, flex: 1}}
                                    editable={!loading}
                                    disabled={loading}
                                    onChangeText={text => setPhrase(text)}
                                    value={phrase}
                                />
                                <TextInput
                                    mode={'outlined'}
                                    label={'Phonetic'}
                                    style={{margin: 10, flex: 1}}
                                    editable={!loading}
                                    disabled={loading}
                                    onChangeText={text => setPhonetic(text)}
                                    value={phonetic}
                                />
                            </View>
                            <View>
                                {loading ? <ActivityIndicator style={{marginStart: 16}} color={Color.secondary} /> : null}
                                <Button
                                    color={Color.primary}
                                    disabled={loading}
                                    mode={'contained'}
                                    onPress={save}>
                                    Add New
                                </Button>
                            </View>
                        </Card.Content>
                    </Card>
                </View>
            }
            viewabilityConfig={{
                waitForInteraction: true,
                itemVisiblePercentThreshold: 75,
            }}
            renderItem={renderListItem}
            keyExtractor={item => (item as any)["id"]}
        />
    )
})

export default PronunciationsScreen
