import React, {useEffect} from 'react'
import {StyleSheet, Text, View, ViewStyle} from "react-native"
import {observer} from "mobx-react"
import {InboxWidgetController} from '../../controllers/InboxWidgetController'
import {Button} from 'react-native-paper'

type Props = {
    controller: InboxWidgetController
    style?: ViewStyle
    title?: string
    callBackPersonKey: string
}

export const MissedCallMessage = observer((props: Props) => {

    useEffect(() => {
        return () => {
        }
    }, [])

    return (
        <View style={[styles.container, props.style]}>
            <Text style={{color: "white"}}>
                {props.title}
            </Text>

            <View style={{flexDirection: 'row-reverse'}}>
                <Button
                    style={{marginHorizontal: 8, marginTop: 8}}
                    mode={'contained'}
                    color={'green'}
                    dark={true}
                    compact={true}
                    onPress={() => { void props.controller.callBack(props.callBackPersonKey) }}>
                    Call Back
                </Button>

                <Button
                    style={{marginHorizontal: 8, marginTop: 8}}
                    mode={'contained'}
                    color={'gray'}
                    dark={true}
                    compact={true}
                    onPress={() => { void props.controller.ignoreMissedCallMessage(props.callBackPersonKey) }}>
                    Ignore
                </Button>
            </View>
        </View>

    )
})

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
})
