import React, {useContext, useEffect, useState} from 'react'
import {Dimensions, Keyboard, KeyboardAvoidingView, ScrollView, StyleSheet, Text, View, ViewStyle} from "react-native"
import {observer} from "mobx-react"
import {FriendsController, FriendsState} from "../../controllers/steps/FriendsController"
import CurrentAccountContext from "../../../vizz_account/lib/CurrentAccount"
import {OnboardingController, OnboardingState} from '../../controllers/OnboardingController'
import {reaction} from "mobx"
import {PrimaryButton} from '../../../../app/views/components/PrimaryButton'
import {Color, isLandscape, isPortrait, isTablet} from '../../../../app/lib/Appearance'
import {FriendsGrid} from './friends/FriendsGrid'
import {ActivityIndicator, Divider, TextInput} from 'react-native-paper'
import WidgetContainer from '../../../start/views/home/WidgetContainer'
import {Widget} from '../../../start/views/home/Widget'
import LegacyFriend from '../../../social/views/friend_widget/LegacyFriend'
import {Typography} from '../../../../app/lib/Typography'

type Props = {
    style?: ViewStyle
    onboardingController: OnboardingController
}

export const Friends = observer((props: Props) => {
    const currentAccount = useContext(CurrentAccountContext)
    const [controller] = useState<FriendsController>(() => new FriendsController(currentAccount, props.onboardingController))

    useEffect(() => {
        const neededReaction = reaction(() => props.onboardingController.state, async(state) => {

            if (currentAccount.personData.friendsOnboardingNeeded) {
                if (state == OnboardingState.FRIENDS) await controller.initialize()
                if (state == OnboardingState.FRIENDS) await controller.startStep()
            }

        }, { fireImmediately: true })

        return () => {
            neededReaction()
            controller.uninitialize()
        }
    }, [])

    const renderFriends = () => {
        return (
            <ScrollView style={{flex: 1, marginTop: 12}} showsVerticalScrollIndicator={false}>
                {controller.realFriends.map((friend, i) =>
                <LegacyFriend
                    key={friend.profileModel.person_key}
                    profile={friend.profileModel}
                    hideBottomDivider={i == controller.realFriends.length-1}
                />
                )}
            </ScrollView>
        )
    }

    return (
        <React.Fragment>
            {props.onboardingController.state == OnboardingState.FRIENDS &&
            <View style={[{flex: 1}, {flexDirection: isLandscape() ? 'row' : 'column'}, props.style]}>

                <View style={{flex: 1, justifyContent: 'center', flexDirection: 'row'}}>
                    {isLandscape() &&
                    <WidgetContainer style={{flexDirection: 'row', maxWidth: 320, minWidth: 320}}>
                        <Widget name={'Real Friends'} type={'compact'} style={{flex: 1, marginLeft: 10, marginRight: 20, marginVertical: 75}} loading={false}>
                            {renderFriends()}
                        </Widget>
                    </WidgetContainer>}

                    {(controller.state == FriendsState.LOADING || controller.state == FriendsState.LOADED) &&
                    <View style={{flexGrow: 1, alignItems: 'center', justifyContent: 'center'}}>
                        {isPortrait() &&
                        <WidgetContainer style={{flexDirection: 'row', width: '90%'}}>
                            <Widget name={'Real Friends'} type={'compact'} style={{flex: 1, marginVertical: 75}} loading={false}>
                                {renderFriends()}
                            </Widget>
                        </WidgetContainer>}
                    </View>}

                    {controller.state == FriendsState.DELAY &&
                    <View style={{flexGrow: 1, alignItems: 'center', justifyContent: 'center'}}>
                        <ActivityIndicator color={Color.primary} size={40} />
                    </View>}

                    {controller.state == FriendsState.SELECT && controller.mode == 'browse' &&
                    <View style={{flexGrow: 1, marginTop: 15}}>
                        <Text style={[props.onboardingController.styles.messageText, {marginBottom: 5, marginTop: isPortrait() ? 40 : 0}]}>Tap your real friends</Text>

                        <FriendsGrid
                            style={[{flexGrow: 1}]}
                            maxSelect={controller.maxFriends}
                            collapsed={false}
                            freezeSelection={controller.freezeSelections}
                            onLoad={(friendsGridController) => controller.setFriendsGridController(friendsGridController)}
                            onToggleSelectedFriend={(friend) => controller.onToggleSelectedFriend(friend)}
                            showSections={controller.isWaitingForSelectingMoreFriends}
                        />

                        {isPortrait() && controller.state == FriendsState.SELECT && controller.realFriends.length > 0 && !controller.isHidingDoneButton &&
                        <View style={{marginTop: 16, justifyContent: 'center', alignItems: 'center'}}>
                            <PrimaryButton
                                resetWhen={controller.isWaitingForSelectingMoreFriends}
                                disabled={controller.realFriends.length == 0}
                                onPress={() => controller.nextStep()}
                                iconType={'FontAwesome'}
                                icon={'thumbs-up'}
                                iconSide={'right'}
                            />
                        </View>}
                    </View>}

                    {controller.state == FriendsState.SELECT && controller.mode == 'search' &&
                    <KeyboardAvoidingView
                        keyboardVerticalOffset={50}
                        behavior={'height'}
                        style={{
                            flexGrow: 1,
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <View style={[{width: isTablet() ? '50%' : '90%', flexDirection: 'row', marginBottom: 10, marginTop: isPortrait() ? 65 : 0}]}>
                            <TextInput
                                style={{flexGrow: 1, marginHorizontal: 20}}
                                mode={'flat'}
                                autoCapitalize={'none'}
                                keyboardType={'default'}
                                label={`Search by Roblox name`}
                                value={controller.friendSearchQuery}
                                autoFocus={false}
                                autoCorrect={false}
                                onChangeText={text => {
                                    void controller.onFriendSearchQueryChanged(text)
                                }}
                            />
                            {controller.isSearching && <ActivityIndicator color={Color.primary} size={40}/>}
                        </View>

                        <FriendsGrid
                                style={[isLandscape() ? {width: Dimensions.get('window').width-320-130} : { width: Dimensions.get('window').width} ]}
                                maxSelect={controller.maxFriends}
                                collapsed={false} // back when we had a prominent search
                                freezeSelection={controller.freezeSelections}
                                onLoad={(friendsGridController) => controller.setFriendsGridController(friendsGridController)}
                                onToggleSelectedFriend={(friend) => { Keyboard.dismiss(); controller.onToggleSelectedFriend(friend) }}
                                onInteraction={() => { Keyboard.dismiss(); controller.onFriendGridInteraction() } }
                                showSections={controller.isWaitingForSelectingMoreFriends}
                        />

                        {isPortrait() && controller.state == FriendsState.SELECT && controller.realFriends.length > 0 &&
                        <View style={{marginTop: 16, justifyContent: 'center', alignItems: 'center'}}>
                            <PrimaryButton
                                resetWhen={controller.isWaitingForSelectingMoreFriends}
                                disabled={controller.realFriends.length == 0}
                                onPress={() => controller.nextStep()}
                                iconType={'FontAwesome'}
                                icon={'thumbs-up'}
                                iconSide={'right'}
                            />
                        </View>}
                    </KeyboardAvoidingView>}

                    {isLandscape() && controller.state == FriendsState.SELECT &&
                    <View style={[{width: 130, paddingLeft: 20, flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'center'}]}>
                        {controller.realFriends.length > 0 && !controller.isHidingDoneButton &&
                        <PrimaryButton
                            resetWhen={controller.isWaitingForSelectingMoreFriends}
                            disabled={controller.realFriends.length == 0}
                            onPress={() => controller.nextStep()}
                            iconType={'FontAwesome'}
                            icon={'thumbs-up'}
                            iconSide={'right'}
                        />}
                    </View>}
                </View>
            </View>}
        </React.Fragment>
    )
})

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
})
