import React from 'react'
import {StyleSheet, Text, View, ViewStyle} from "react-native"
import {Avatar} from "react-native-paper"
import {AvatarImageSource} from 'react-native-paper/lib/typescript/components/Avatar/AvatarImage'
import {LavaImage} from "../../../../app/views/components/LavaImage"

export enum FriendAvatarType {
     OFFLINE,
     ONLINE,
     PENDING
}

type Props = {
     avatarImage?: string | AvatarImageSource
     size?: number
     initials?: string
     type: FriendAvatarType
     lavaBadge?: boolean
     style?: ViewStyle
     imageStyle?: ViewStyle
     backgroundColor?: string
}

export const FriendAvatarSize = () => {
    return 60
}

export const FriendAvatar = (props: Props) => {
    let source:AvatarImageSource
    if (!props.avatarImage)
        source = require('../../assets/img-profile-blank.png') as AvatarImageSource
    if (typeof props.avatarImage === 'string')
        source = {uri: props.avatarImage} as AvatarImageSource
    else
        source = props.avatarImage as AvatarImageSource

    const FriendAvatarInnerImageSize = () => {
        return (props.size ?? FriendAvatarSize()) * 0.8
    }

    const overlayImage = () => {
        switch (props.type) {
            case FriendAvatarType.OFFLINE: return <LavaImage style={styles(props).overlayImage} source={require('../../assets/img-profile-offline.png')} />
            case FriendAvatarType.ONLINE: return  <LavaImage style={styles(props).overlayImage} source={require('../../assets/img-profile-online.png')} />
            case FriendAvatarType.PENDING: return  <LavaImage style={styles(props).overlayImage} source={require('../../assets/img-profile-pending.png')} />
        }
    }

    return (
        <View style={[styles(props).container, props.style]}>
            <Avatar.Image style={[{backgroundColor: props.backgroundColor ?? 'black'}, props.imageStyle]} size={FriendAvatarInnerImageSize()} source={source}/>
            {overlayImage()}
            {props.initials && <View style={styles(props).overlayInitials}><Text style={{textAlign: 'center', fontWeight: 'bold', fontSize: 18, color: 'rgba(255,255,255,0.8)'}}>{props.initials}</Text></View>}
            {props.lavaBadge && <Avatar.Image style={{position: 'absolute', right: -5, bottom: -5, backgroundColor: 'black'}} size={31} source={require('../../assets/img-lava-l.png')}/>}
        </View>
    )
}

const styles = (props: Props) => StyleSheet.create({
     container: {
          width: props.size ?? FriendAvatarSize(),
          height: props.size ?? FriendAvatarSize(),
          justifyContent: 'center',
          alignItems: 'center'
     },
     overlayImage: {
          flex: 1,
          width: props.size ?? FriendAvatarSize(),
          height: props.size ?? FriendAvatarSize(),
          ...StyleSheet.absoluteFillObject
     },
     overlayInitials: {
        flex: 1,
        width: props.size ?? FriendAvatarSize(),
        height: props.size ?? FriendAvatarSize(),
        justifyContent: 'center',
        ...StyleSheet.absoluteFillObject
    }
})