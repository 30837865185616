import {CallParticipant} from "./CallParticipant"

export interface Call {
    id: number
    key: string
    started_by_person_id: number
    started_by_person_key: string
    participants: CallParticipant[]
    created_at: string
    ended_at?: string
    voice_filter: string
    voice_filter_changed_by_person_key?: string
}

export class CallUtils {

    public static equal(c1?: Call, c2?: Call) {
        if (!c1 && !c2) return true
        if (!c1 || !c2) return false


        const call1VerifiedCount = c1.participants.filter(participant => participant.person_verified).length
        const call2VerifiedCount = c2.participants.filter(participant => participant.person_verified).length

        return c1.key == c2.key &&
            c1.participants.length == c2.participants.length &&
            c1.voice_filter == c2.voice_filter &&
            call1VerifiedCount == call2VerifiedCount
    }

    public static participantMatchingPerson(call: Call, personId: number): CallParticipant|undefined {
        const matchingParticipants = call.participants.filter((p) => {
            return p.person_id == personId
        })
        return matchingParticipants.length > 0 ? matchingParticipants[0] : undefined
    }

    public static participantProfilesExcludingPerson(call: Call, personToExclueId: number) {
        return call.participants
            .filter((p) => p.person_id != personToExclueId)
            .map((p) => p.profile)
    }

    public static participantPersonKeysExcludingPerson(call: Call, personToExclueId: number) {
        return call.participants
            .filter((p) => p.person_id != personToExclueId)
            .map((p) => p.person_key)
    }
}