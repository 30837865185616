import {computed, makeObservable} from "mobx"
import DateUtils from "../../utils/DateUtils"
import PersonModel from "../../../modules/vizz_account/models/PersonModel"
import Data from "./Data"

class PersonData extends Data {
    constructor() {
        super({
            'PersonData': [
                { 'person': { 'object': null }},
                { 'lastUpdateNumberDownloaded': {'number': 0 }},
                { 'dateOfThisOpen': { 'string': DateUtils.today_string() }},
                { 'dateOfFirstOpen': { 'string': DateUtils.today_string() }},
                { 'dateOfLastOpen': { 'string': DateUtils.today_string() }},
                { 'numberOfOpens': { 'number': 0 }},
                { 'numberOfDaysOpened': { 'number': 1 }},
                { 'lastDeviceType': { 'string': null }},
                { 'lastDeviceCategory': { 'string': null }},
                { 'distinctId': { 'string': null }},
                { 'deviceKey': { 'string': null }},
                { 'installationKey': { 'string': null }},
                { 'reportedInstallConversion': { 'boolean': false }},
                { 'numberOfVideosTasted': { 'number': 0 }},
                { 'numberOfVideosStarted': { 'number': 0 }},
                { 'numberOfCommunicationsStarted': { 'number': 0 }},
                { 'numberOfSearches': { 'number': 0 }},
                { 'numberOfConceptsOpened': { 'number': 0 }},
                { 'isChild': { 'boolean': null }},
                { 'ageBand': { 'string': null }},
                { 'firstName': {'string': null}},

                { 'migration': { 'number': 0 }},

                { 'onboardingNeeded': { 'boolean': true }},
                { 'idfaOnboardingNeeded': { 'boolean': true }},
                { 'audioOnboardingNeeded': { 'boolean': true }},
                { 'pitchOnboardingNeeded': { 'boolean': true }},
                { 'voiceOnboardingNeeded': { 'boolean': true }},
                { 'sampleOnboardingNeeded': { 'boolean': true }},
                { 'socialOnboardingNeeded': { 'boolean': true }},
                { 'friendsOnboardingNeeded': { 'boolean': true }},
                { 'dailyOnboardingNeeded': { 'boolean': true }},
                { 'ageOnboardingNeeded': { 'boolean': true }},
                { 'smsOnboardingNeeded': { 'boolean': true }},
                { 'interestsOnboardingNeeded': { 'boolean': true }},
                { 'profileOnboardingNeeded': { 'boolean': true }},
                { 'referralOnboardingNeeded': { 'boolean': true }},
                { 'robuxOnboardingNeeded': { 'boolean': true }},
                { 'schoolOnboardingNeeded': { 'boolean': true }},
                { 'scannerOnboardingNeeded': { 'boolean': true }},
                { 'sharingOnboardingNeeded': { 'boolean': true }},

                { 'cohort': { 'string': null }},
                { 'debugForceVizzCreate': { 'boolean': false }},
                { 'lastInvitationReminderTimestamp': {'string': null}},
                { 'numberOfInvitationRemindersSeen': {'number': 0}},
                { 'activeCallKey': {'string': null}},
                { 'throwExceptionOnAppForeground': { 'boolean': false }},
                { 'throwClientAhead': { 'boolean': false }},
                { 'throwExceptionOnExpo1stCheck': { 'boolean': false }},
                { 'throwExceptionOnExpo2ndCheck': { 'boolean': false }},
                { 'throwExceptionOnExpo1stFetch': { 'boolean': false }},
                { 'throwExceptionOnExpo2ndFetch': { 'boolean': false }},
                { 'throwExceptionOnExpoInstall': { 'boolean': false }},
                { 'throwExceptionOnDiskSpace': { 'boolean': false }},
                { 'failureCount': { 'number': 0 }},
                { 'incomingCallKey': { 'string': null }},
                { 'hasRobloxAccount': { 'boolean': false }},
                { 'hasMadeCall': { 'boolean': false }},
            ]
        })
        makeObservable(this)
    }

    public async loadPreviousFromStorage(profile?: string) {
        await super.loadPreviousFromStorage(profile)
    }

    @computed
    get person() { return this.get('person') as PersonModel }
    set person(val: PersonModel|null) { this.set('person', val) }

    @computed
    get lastUpdateNumberDownloaded() { return this.get('lastUpdateNumberDownloaded') as number }
    set lastUpdateNumberDownloaded(val: number) { this.set('lastUpdateNumberDownloaded', val) }

    public daysSinceLastOpen() { return DateUtils.daysBetween(DateUtils.today_string(), this.dateOfLastOpen) }
    public daysSinceFirstOpen() { return DateUtils.daysBetween(DateUtils.today_string(), this.dateOfFirstOpen) }

    @computed
    get dateOfThisOpen() { return this.get('dateOfThisOpen') as string }
    set dateOfThisOpen(val: string) { this.set('dateOfThisOpen', val) }

    @computed
    get dateOfFirstOpen() { return this.get('dateOfFirstOpen') as string }
    set dateOfFirstOpen(val: string) { this.set('dateOfFirstOpen', val) }

    @computed
    get dateOfLastOpen() { return this.get('dateOfLastOpen') as string }
    set dateOfLastOpen(val: string) { this.set('dateOfLastOpen', val) }

    @computed
    get numberOfOpens() { return this.get('numberOfOpens') as number }
    set numberOfOpens(val: number) { this.set('numberOfOpens', val) }

    @computed
    get numberOfDaysOpened() { return this.get('numberOfDaysOpened') as number }
    set numberOfDaysOpened(val: number) { this.set('numberOfDaysOpened', val) }

    @computed
    get lastDeviceType() { return this.get('lastDeviceType') as string }
    set lastDeviceType(val: string) { this.set('lastDeviceType', val) }

    @computed
    get lastDeviceCategory() { return this.get('lastDeviceCategory') as string }
    set lastDeviceCategory(val: string) { this.set('lastDeviceCategory', val) }

    @computed
    get deviceKey() { // renamed distinctId to deviceKey
        return (this.get('distinctId') ?? this.get('deviceKey')) as string }
    set deviceKey(val: string|null) { this.set('deviceKey', val) }

    @computed
    get installationKey() { return this.get('installationKey') as string }
    set installationKey(val: string|null) { this.set('installationKey', val) }

    @computed
    get reportedInstallConversion() { return this.get('reportedInstallConversion') as boolean }
    set reportedInstallConversion(val: boolean) { this.set('reportedInstallConversion', val) }

    @computed
    get numberOfVideosTasted() { return this.get('numberOfVideosTasted') as number }
    set numberOfVideosTasted(val: number) { this.set('numberOfVideosTasted', val) }

    @computed
    get numberOfVideosStarted() { return this.get('numberOfVideosStarted') as number }
    set numberOfVideosStarted(val: number) { this.set('numberOfVideosStarted', val) }

    @computed
    get numberOfCommunicationsStarted() { return this.get('numberOfCommunicationsStarted') as number }
    set numberOfCommunicationsStarted(val: number) { this.set('numberOfCommunicationsStarted', val) }

    @computed
    get numberOfSearches() { return this.get('numberOfSearches') as number }
    set numberOfSearches(val: number) { this.set('numberOfSearches', val) }

    @computed
    get numberOfConceptsOpened() { return this.get('numberOfConceptsOpened') as number }
    set numberOfConceptsOpened(val: number) { this.set('numberOfConceptsOpened', val) }

    @computed
    get isChild() { return this.get('isChild') as boolean }
    set isChild(val: boolean) { this.set('isChild', val) }

    @computed
    get ageBand() { return this.get('ageBand') as string }
    set ageBand(val: string) { this.set('ageBand', val) }

    @computed
    get firstName() { return this.get('firstName') as string }
    set firstName(val: string) { this.set('firstName', val) }

    @computed
    get migration() { return this.get('migration') as number }
    set migration(val: number) { this.set('migration', val) }

    @computed
    get onboardingNeeded() { return this.get('onboardingNeeded') as boolean }
    set onboardingNeeded(val: boolean) { this.set('onboardingNeeded', val) }

    @computed
    get voiceOnboardingNeeded() { return this.get('voiceOnboardingNeeded') as boolean }
    set voiceOnboardingNeeded(val: boolean) { this.set('voiceOnboardingNeeded', val) }

    @computed
    get friendsOnboardingNeeded() { return this.get('friendsOnboardingNeeded') as boolean }
    set friendsOnboardingNeeded(val: boolean) { this.set('friendsOnboardingNeeded', val) }

    @computed
    get idfaOnboardingNeeded() { return this.get('idfaOnboardingNeeded') as boolean }
    set idfaOnboardingNeeded(val: boolean) { this.set('idfaOnboardingNeeded', val) }

    @computed
    get audioOnboardingNeeded() { return this.get('audioOnboardingNeeded') as boolean }
    set audioOnboardingNeeded(val: boolean) { this.set('audioOnboardingNeeded', val) }

    @computed
    get pitchOnboardingNeeded() { return this.get('pitchOnboardingNeeded') as boolean }
    set pitchOnboardingNeeded(val: boolean) { this.set('pitchOnboardingNeeded', val) }

    @computed
    get sampleOnboardingNeeded() { return this.get('sampleOnboardingNeeded') as boolean }
    set sampleOnboardingNeeded(val: boolean) { this.set('sampleOnboardingNeeded', val) }

    @computed
    get socialOnboardingNeeded() { return this.get('socialOnboardingNeeded') as boolean }
    set socialOnboardingNeeded(val: boolean) { this.set('socialOnboardingNeeded', val) }

    @computed
    get dailyOnboardingNeeded() { return this.get('dailyOnboardingNeeded') as boolean }
    set dailyOnboardingNeeded(val: boolean) { this.set('dailyOnboardingNeeded', val) }

    @computed
    get ageOnboardingNeeded() { return this.get('ageOnboardingNeeded') as boolean }
    set ageOnboardingNeeded(val: boolean) { this.set('ageOnboardingNeeded', val) }

    @computed
    get smsOnboardingNeeded() { return this.get('smsOnboardingNeeded') as boolean }
    set smsOnboardingNeeded(val: boolean) { this.set('smsOnboardingNeeded', val) }

    @computed
    get interestsOnboardingNeeded() { return this.get('interestsOnboardingNeeded') as boolean }
    set interestsOnboardingNeeded(val: boolean) { this.set('interestsOnboardingNeeded', val) }

    @computed
    get profileOnboardingNeeded() { return this.get('profileOnboardingNeeded') as boolean }
    set profileOnboardingNeeded(val: boolean) { this.set('profileOnboardingNeeded', val) }

    @computed
    get referralOnboardingNeeded() { return this.get('referralOnboardingNeeded') as boolean }
    set referralOnboardingNeeded(val: boolean) { this.set('referralOnboardingNeeded', val) }

    @computed
    get robuxOnboardingNeeded() { return this.get('robuxOnboardingNeeded') as boolean }
    set robuxOnboardingNeeded(val: boolean) { this.set('robuxOnboardingNeeded', val) }

    @computed
    get schoolOnboardingNeeded() { return this.get('schoolOnboardingNeeded') as boolean }
    set schoolOnboardingNeeded(val: boolean) { this.set('schoolOnboardingNeeded', val) }

    @computed
    get scannerOnboardingNeeded() { return this.get('scannerOnboardingNeeded') as boolean }
    set scannerOnboardingNeeded(val: boolean) { this.set('scannerOnboardingNeeded', val) }

    @computed
    get sharingOnboardingNeeded() { return this.get('sharingOnboardingNeeded') as boolean }
    set sharingOnboardingNeeded(val: boolean) { this.set('sharingOnboardingNeeded', val) }

    @computed
    get cohort() { return this.get('cohort') as string }
    set cohort(val: string) { this.set('cohort', val) }

    @computed
    get debugForceVizzCreate() { return this.get('debugForceVizzCreate') as boolean }
    set debugForceVizzCreate(val: boolean) { this.set('debugForceVizzCreate', val) }

    @computed
    get lastInvitationReminderTimestamp() { return this.get('lastInvitationReminderTimestamp') as string }
    set lastInvitationReminderTimestamp(val: string) { this.set('lastInvitationReminderTimestamp', val) }

    @computed
    get numberOfInvitationRemindersSeen() { return this.get('numberOfInvitationRemindersSeen') as number }
    set numberOfInvitationRemindersSeen(val: number) { this.set('numberOfInvitationRemindersSeen', val) }

    @computed
    get activeCallKey() { return this.get('activeCallKey') as string }
    set activeCallKey(val: string|null) { this.set('activeCallKey', val) }

    @computed
    get throwExceptionOnAppForeground() { return this.get('throwExceptionOnAppForeground') as boolean }
    set throwExceptionOnAppForeground(val: boolean) { this.set('throwExceptionOnAppForeground', val) }

    @computed
    get throwClientAhead() { return this.get('throwClientAhead') as boolean }
    set throwClientAhead(val: boolean) { this.set('throwClientAhead', val) }

    @computed
    get throwExceptionOnExpo1stCheck() { return this.get('throwExceptionOnExpo1stCheck') as boolean }
    set throwExceptionOnExpo1stCheck(val: boolean) { this.set('throwExceptionOnExpo1stCheck', val) }

    @computed
    get throwExceptionOnExpo2ndCheck() { return this.get('throwExceptionOnExpo2ndCheck') as boolean }
    set throwExceptionOnExpo2ndCheck(val: boolean) { this.set('throwExceptionOnExpo2ndCheck', val) }

    @computed
    get throwExceptionOnExpo1stFetch() { return this.get('throwExceptionOnExpo1stFetch') as boolean }
    set throwExceptionOnExpo1stFetch(val: boolean) { this.set('throwExceptionOnExpo1stFetch', val) }

    @computed
    get throwExceptionOnExpo2ndFetch() { return this.get('throwExceptionOnExpo2ndFetch') as boolean }
    set throwExceptionOnExpo2ndFetch(val: boolean) { this.set('throwExceptionOnExpo2ndFetch', val) }

    @computed
    get throwExceptionOnExpoInstall() { return this.get('throwExceptionOnExpoInstall') as boolean }
    set throwExceptionOnExpoInstall(val: boolean) { this.set('throwExceptionOnExpoInstall', val) }

    @computed
    get throwExceptionOnDiskSpace() { return this.get('throwExceptionOnDiskSpace') as boolean }
    set throwExceptionOnDiskSpace(val: boolean) { this.set('throwExceptionOnDiskSpace', val) }

    @computed
    get failureCount() { return this.get('failureCount') as number }
    set failureCount(val: number) { this.set('failureCount', val) }

    @computed
    get incomingCallKey() { return this.get('incomingCallKey') as string }
    set incomingCallKey(val: string|null) { this.set('incomingCallKey', val) }

    @computed
    get hasRobloxAccount() { return this.get('hasRobloxAccount') as boolean }
    set hasRobloxAccount(val: boolean) { this.set('hasRobloxAccount', val) }

    @computed
    get hasMadeCall() { return this.get('hasMadeCall') as boolean }
    set hasMadeCall(val: boolean) { this.set('hasMadeCall', val) }
}

export default PersonData
