import {PresenceModel} from "./PresenceModel"
import {ProfileModel} from "./ProfileModel"

export const LAVA_BOT_PROFILE = {
    id: 2356,
    username: "Lava_Bot",
    avatar_media_id: 531310,
    created_at: "2022-09-28T06:03:26.818-07:00",
    updated_at: "2022-12-13T11:49:34.434-08:00",
    person_id: 6742,
    status: "online",
    first_name: "Lava",
    nickname: "Bot",
    pretty_username: "Lava Bot",
    avatar_image_url: "https://res.cloudinary.com/explanation/image/upload/v1671214709/media/s7wc6blulx4lorhzdjh8",
    person_key: "7b2aadff-37b3-4448-8513-061aa289943c",
    last_changed: "Online now"
} as ProfileModel

export const LAVA_BOT_PRESENCE = {
    id: 2356,
    person_id: 6742,
    person_key: "7b2aadff-37b3-4448-8513-061aa289943c",

    profile_first_name: "Lava",
    profile_nickname: "Bot",
    profile_username: "Lava_Bot",
    pretty_username: "Lava Bot",

    roblox_profile_avatar_headshot_url: "https://res.cloudinary.com/explanation/image/upload/v1671214709/media/s7wc6blulx4lorhzdjh8",
    lava_installed: true,
    broadcasted_status: "online",
    broadcasted_status_updated_at: "2022-09-28T06:03:26.818-07:00",

    robloxing: false,
    playing: false,
    watching: false,
    talking: false,
    activity_updated_at: "2022-12-13T11:49:34.434-08:00",

    concept_id: undefined,
    concept_name: undefined,
    concept_media_url: undefined,

    video_id: undefined,
    video_title: undefined,
    video_thumbnail_url: undefined,
    video_concept_id: undefined,

    message_text: undefined,
    message_read: undefined,
    message_sent_at: undefined

} as PresenceModel

