import {AVPlaybackSource, AVPlaybackStatus} from "expo-av/src/AV"
import VizzAudioService from "../../modules/vizz_maker/services/VizzAudioService"
import {CurrentAccount} from "../../modules/vizz_account/lib/CurrentAccount"
import {AsyncUtils} from "./AsyncUtils"
import SentryService from "../services/SentryService"
import {ErrorUtils} from "./ErrorUtils"

type AVPlayerStatusSuccess = {
    isPlaying: boolean
    isBuffering: boolean
    playableDurationMillis?: number
    positionMillis: number
}

type AVStatusObservable = {
    setOnPlaybackStatusUpdate(onPlaybackStatusUpdate: ((status: AVPlaybackStatus) => void) | null): void
}

/*
* A class that allows you to wait for an AV object to start playing before proceeding with another task.
* */
export class AVPlaybackWaiter {

    av: AVStatusObservable
    promise?: Promise<boolean>

    constructor(av: AVStatusObservable) {
        this.av = av
    }

    public async waitForPlaybackStart() {
        return this.promise = new Promise<boolean>((resolve, reject) => {
            window.setTimeout(() => {
                reject()
            }, 10000)

            this.av.setOnPlaybackStatusUpdate((status) => {
                if ('isPlaying' in status) {
                    const success = status as AVPlayerStatusSuccess
                    if (status.positionMillis > 0) {
                        resolve(true)
                        this.av.setOnPlaybackStatusUpdate(null)
                    }
                }
            })
        })
    }

    public async waitForLoad() {
        return this.promise = new Promise<boolean>((resolve, reject) => {
            window.setTimeout(() => {
                reject()
            }, 10000)

            this.av.setOnPlaybackStatusUpdate((status) => {
                if ('isPlaying' in status) {
                    const success = status as AVPlayerStatusSuccess
                    if (status.isLoaded) {
                        resolve(true)
                        this.av.setOnPlaybackStatusUpdate(null)
                    }
                }
            })
        })
    }
}
export class SoundUtils {

    public static playSound(source: AVPlaybackSource, onFinish: (() => void) | undefined = undefined) {
        console.log(`[SoundUtils] playSound ${source}`)

        const audio = new VizzAudioService(source)

        const work = async () => {
            await audio.changeState.loadAndPlay()

            await audio.setOnFinishListener(async() => {
                if (onFinish) onFinish()
                await audio.changeState.pauseAndUnload()
            })
        }
        void work()

        return audio
    }

}

class SpeechPlaybackTimeoutError extends Error {}

export class SpeechUtils {

    private account: CurrentAccount
    private unloaded: boolean = false
    private vizzAudio?: VizzAudioService
    private soundLoadedWithPhrase?: string
    private preloadedPhrases: { [key: string]: string } = {}
    private preloadedAudioUrls: { [key: string]: string } = {}
    private preloadedVizzAudios: { [phrase: string]: VizzAudioService } = {}
    private currentPhrase?: string
    private silencePlaybackTimeoutErrors: boolean

    constructor(account: CurrentAccount, silencePlaybackTimeoutErrors: boolean) {
        this.account = account
        this.silencePlaybackTimeoutErrors = silencePlaybackTimeoutErrors
    }

    public unload() {
        this.unloaded = true
        this.soundLoadedWithPhrase = undefined
        void this.vizzAudio?.changeState.pauseAndUnload()
    }

    public pause() {
        void this.vizzAudio?.changeState.pause()
    }

    public restart() {
        void this.vizzAudio?.changeState.seekAndPlay(0)
    }

    public preloadPhrase(key: string, phrase: string) {
        if (this.preloadedPhrases[key] && this.preloadedPhrases[key] == phrase) return
        this.preloadedPhrases[key] = phrase
        void this.getAndCacheAudioUrlForPhrase(phrase)
    }

    public async speakPreloadedPhrase(key: string, onFinish: (() => void) | undefined = undefined) {
        await this.speak(this.preloadedPhrases[key], onFinish)
    }

    public async speakPreloadedPhraseAndWait(key: string, onFinish: (() => void) | undefined = undefined) {
        await this.speakAndWait(this.preloadedPhrases[key], onFinish)
    }

    public unloadPreloadedPhrase(key: string) {
        const vizzAudio = this.preloadedVizzAudios[this.preloadedPhrases[key]]
        if (vizzAudio) {
            void vizzAudio.changeState.pauseAndUnload()
            delete this.preloadedVizzAudios[this.preloadedPhrases[key]]
        }
    }

    public async speak(phrase: string, onFinish: (() => void) | undefined = undefined) {
        await this.speakAndWait(phrase, onFinish, false) // false = only waits for playing to start
    }

    public async speakAndWait(phrase: string, onFinish: (() => void) | undefined = undefined, waitForAllOperations: boolean = true) {
        if (this.unloaded) return
        if (this.soundLoadedWithPhrase && this.soundLoadedWithPhrase != phrase) {
            this.pause()
        }

        this.currentPhrase = phrase

        function abortTimeout(ms: number) {
            return new Promise<undefined>((_, reject) => {
                setTimeout(() => {
                    reject(new SpeechPlaybackTimeoutError('Timed out waiting for speech'));
                }, ms)
            })
        }

        try {
            if (this.soundLoadedWithPhrase && this.soundLoadedWithPhrase == phrase) {
                await this.vizzAudio?.changeState.seekAndPlay(0)
            } else {
                // If audio doesn't start within 10 seconds, abort.
                const newVizzAudio = await Promise.race([
                    this.getAndCacheVizzAudioForPhrase(phrase),
                    abortTimeout(10000)
                ])

                if (this.currentPhrase != phrase) return // another call to speak may have been executed so we short circuit this call
                if (newVizzAudio != undefined) {
                    if (this.vizzAudio) {
                        await this.vizzAudio.changeState.pauseAndUnload()
                        delete this.preloadedVizzAudios[phrase]
                    }
                    this.vizzAudio = newVizzAudio

                    await this.vizzAudio.changeState.play()
                    this.soundLoadedWithPhrase = phrase
                }
            }
            if (this.vizzAudio) { // outside the conditional b/c we may be re-using an old sound object
                const audio = this.vizzAudio

                if (!waitForAllOperations) {
                    if (onFinish) {
                        await audio.setOnFinishListener(onFinish)
                     }
                } else {
                    await new Promise( async (resolve, reject) => {
                        try {
                            await audio.setOnFinishListener(() =>  {
                                resolve('Done playing')
                            })
                        } catch (error) {
                            reject(error)
                        }
                    })
                }
            }
        } catch (e) {
            this.vizzAudio = undefined
            console.error(e)
            if (e instanceof SpeechPlaybackTimeoutError) {
                if (this.silencePlaybackTimeoutErrors) {
                    if (onFinish) onFinish()
                } else {
                    throw e
                }
            }

            if (!ErrorUtils.isInternetProblemError(e)) {
                SentryService.captureError(e)
            }
        }
    }

    private async getAndCacheVizzAudioForPhrase(phrase: string) {
        const existingVizzAudio = this.preloadedVizzAudios[phrase]
        if (existingVizzAudio) return existingVizzAudio

        const audioUrl = await this.getAndCacheAudioUrlForPhrase(phrase)
        if (!audioUrl) return undefined

        const vizzAudio = new VizzAudioService({uri: audioUrl})
        this.preloadedVizzAudios[phrase] = vizzAudio
        await this.preloadedVizzAudios[phrase].changeState.loadAndPause()

        return this.preloadedVizzAudios[phrase]
    }

    private async getAndCacheAudioUrlForPhrase(phrase: string) {
        let audioUrl: string|undefined = undefined
        let tries = 0
        const retryLimit = 50
        const waitForMS = 100

        const existingAudioUrl = this.preloadedAudioUrls[phrase]
        if (existingAudioUrl) return existingAudioUrl

        while (!audioUrl && tries <= retryLimit) {
            let data
            try {
                data = await this.account.api.post("vizz_maker.text_to_speech_path", {
                    text_to_speak: phrase
                }, true, undefined)
            } catch (error) {
                SentryService.captureError(error)
            }

            if (this.unloaded) return undefined

            if (data && data.audio_url) {
                audioUrl = data.audio_url
            } else {
                await AsyncUtils.sleep(waitForMS)
                tries++
            }
        }

        if (!audioUrl) return undefined
        this.preloadedAudioUrls[phrase] = audioUrl

        return audioUrl
    }

}

export class YouTubeUtils {

    public static async isVideoPlayable(youtubeKey: string) {
        let response

        try{
            response = await fetch("https://www.youtube.com/youtubei/v1/player?key=AIzaSyA8eiZmM1FaDVjRy-df2KTyQ_vz_yYM39w", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'User-Agent': 'Mozilla/5.0 (Macintosh; Intel Mac OS X 13_3_1) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/16.4 Safari/605.1.15'
                },
                body: JSON.stringify({
                    videoId: youtubeKey,
                    context: {
                        "client":{
                            "hl":"en",
                            "gl":"US",
                            "deviceMake":"Apple",
                            "deviceModel":"",
                            "userAgent":"Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/91.0.4472.114 Safari/537.36,gzip(gfe)",
                            "clientName":"WEB_EMBEDDED_PLAYER",
                            "clientVersion":"1.20210718.0.1",
                            "osName":"Macintosh",
                            "osVersion":"10_15_7",
                            "platform":"DESKTOP",
                            "clientFormFactor":"UNKNOWN_FORM_FACTOR",
                            "browserName":"Chrome",
                            "browserVersion":"91.0.4472.114",
                            "screenWidthPoints":2381,
                            "screenHeightPoints":1340,
                            "screenPixelDensity":1,
                            "screenDensityFloat":0.75,
                            "userInterfaceTheme":"USER_INTERFACE_THEME_LIGHT",
                            "connectionType":"CONN_CELLULAR_4G",
                            "playerType":"UNIPLAYER",
                            "tvAppInfo":{
                                "livingRoomAppMode":"LIVING_ROOM_APP_MODE_UNSPECIFIED"
                            },
                            "clientScreen":"EMBED"
                        },
                    }
                })
            })

            let data = await response.json()

            if (data.playabilityStatus.status == "OK" && data.playabilityStatus.playableInEmbed){
                return true
            } else {
                return false
            }
        } catch (e) {
            console.log(e)
            SentryService.captureError(e)
            return true // Let the video play
        }
    }

}
