import {observer} from "mobx-react"
import {Animated, StyleSheet, TouchableOpacity, View, Text} from "react-native"
import useTheme from "../../../lava-components/components/hooks/useTheme"
import Title from "../../../lava-components/components/atoms/Title/Title"
import {TouchableRipple} from "react-native-paper"
import {SpreadDetailController} from "../../controllers/roblox_games_widget/SpreadDetailController"
import React, {useEffect, useRef, useState} from "react"
import {LavaImage} from "../../../../app/views/components/LavaImage"
import Button from "../../../lava-components/components/atoms/Button/Button"
import {EXPANDED_WIDTH, SpreadDetailSidePanelState} from "./SpreadDetailConstants"
import {Video} from "../Video"
import {isPortrait} from "../../../../app/lib/Appearance"

type Props = {
    controller: SpreadDetailController
    sidePanelWidth: Animated.Value
    animatedPanelWidth: Animated.Value
    sidePanelState?: SpreadDetailSidePanelState
    setSidePanelState?: React.Dispatch<React.SetStateAction<SpreadDetailSidePanelState>>
    playPreviewVideo?: boolean
    setPlayPreviewVideo?:React.Dispatch<React.SetStateAction<boolean>>
}

export const SpreadDetailSidePanel = observer((props: Props) => {

    const theme = useTheme()
    const [imageLayout, setImageLayout] = useState({x: 0, y: 0, width: 0, height: 0})
    const videoRef = useRef<any>(null)
    const {animatedPanelWidth, sidePanelWidth, sidePanelState, setSidePanelState,playPreviewVideo,setPlayPreviewVideo} = props

    useEffect(() => {
        if (playPreviewVideo && props.controller.previewVideo) {
            videoRef.current?.autoPlay()
        }
    }, [props.controller.previewVideo, playPreviewVideo])

    useEffect(() => {
        const animationListener = animatedPanelWidth.addListener(({value}) => {
            sidePanelWidth.setValue(value)
        })

        return () => {
            animatedPanelWidth.removeListener(animationListener)
        }
    }, [animatedPanelWidth])

    const expandSidePanel = () => {
        if (sidePanelState === SpreadDetailSidePanelState.COLLAPSED && setSidePanelState) {
            setSidePanelState(SpreadDetailSidePanelState.EXPANDED)
            Animated.timing(animatedPanelWidth, {
                toValue: EXPANDED_WIDTH,
                duration: 200,
                useNativeDriver: true
            }).start()
        }
    }

    return (
        <View style={{flex: 1}} onTouchEnd={expandSidePanel}>
            <View style={{flex: 1, marginHorizontal: 28}}>

                <TouchableOpacity
                    onPress={() => props.controller.playVideo(props.controller.previewVideo)}
                    style={{marginBottom: 1}}
                >
                    <Title numberOfLines={1} variation="title3" style={{marginBottom: theme.spacing.xxl, color: theme.colors.primarySand}}>
                        Dog Trailer
                    </Title>

                    <Animated.View style={{height: Animated.divide(props.sidePanelWidth, 2), borderRadius: 1}} onLayout={(e) => setImageLayout(e.nativeEvent.layout)}>
                        <Animated.Image style={{flex: 1, width: Animated.subtract(props.sidePanelWidth, new Animated.Value(56))}} source={{uri: props.controller.previewVideo?.thumbnail_url}}
                            resizeMode={'cover'} />
                        <View style={{alignItems: 'center', justifyContent: 'center', ...StyleSheet.absoluteFillObject}}>
                            <Animated.Image style={{
                                width: Animated.multiply(imageLayout.width, (80 / EXPANDED_WIDTH)),
                                height: Animated.multiply(imageLayout.width, (80 / EXPANDED_WIDTH))
                            }} source={require('../../assets/icn-preview-play.png')}/>
                        </View>
                    </Animated.View>

                </TouchableOpacity>

                <Title numberOfLines={1} variation="title3" style={{marginTop: theme.spacing.xxl*3, color: theme.colors.primarySand}}>
                    Common Dog Questions
                </Title>

                <Title numberOfLines={2} variation="subtitle1" style={{marginTop: theme.spacing.xl*2, color: theme.colors.primarySand20}}>
                    <Text style={{textDecorationLine: 'underline'}}>What was the first dog breed?</Text>
                </Title>

                <Title numberOfLines={2} variation="subtitle1" style={{marginTop: theme.spacing.xl*2, color: theme.colors.primarySand20}}>
                    <Text style={{textDecorationLine: 'underline'}}>What are the different dog-related jobs people get paid to do?</Text>
                </Title>

                <Title numberOfLines={2} variation="subtitle1" style={{marginTop: theme.spacing.xl*2, color: theme.colors.primarySand20}}>
                    <Text style={{textDecorationLine: 'underline'}}>Why do Dalmatians have spots?</Text>
                </Title>

                <Title numberOfLines={2} variation="subtitle1" style={{marginTop: theme.spacing.xl*2, color: theme.colors.primarySand20}}>
                    <Text style={{textDecorationLine: 'underline'}}>What's the oldest age a dog has ever lived to be?</Text>
                </Title>

                <Title numberOfLines={2} variation="subtitle1" style={{marginTop: theme.spacing.xl*2, color: theme.colors.primarySand20}}>
                    <Text style={{textDecorationLine: 'underline'}}>Can a Chihuahua and a Great Dane have puppies?</Text>
                </Title>

            </View>

            <View style={{
                position: 'absolute',
                top: 0,
                bottom: 0,
                right: 0,
                width: 0.5,
                backgroundColor: theme.colors.primarySand40
            }} />

        </View>
    )
})