import {Call, CallUtils} from "./Call"
import {comparePresenceLists, PresenceModel} from "./PresenceModel"

export interface ChannelModel {
    id: number
    people_digest: string
    people_count: number
    presences: PresenceModel[]
    active_call_id: number
    active_call?: Call  // Don't trust this to be accurate yet
}

export const compareChannelLists = (list1: ChannelModel[], list2: ChannelModel[]): boolean => {
    if (list1.length !== list2.length) {
        return false
    }

    for (let i = 0; i < list1.length; i++) {
        const channel1 = list1[i]
        const channel2 = list2[i]

        if (
            channel1.id !== channel2.id ||
            channel1.people_digest !== channel2.people_digest ||
            channel1.people_count !== channel2.people_count ||
            channel1.active_call_id !== channel2.active_call_id ||
            !CallUtils.equal(channel1.active_call, channel2.active_call)
        ) {
            return false
        }

        if (!comparePresenceLists(channel1.presences, channel2.presences)) {
            return false
        }
    }

    return true
}