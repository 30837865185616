import {action, autorun, computed, makeObservable, observable} from "mobx"
import VizzAudioService from "../../../services/VizzAudioService"
import {AssetType, SlideEndBehavior, VizzMedia, VizzSlideModel} from "../../../models/VizzModel"

export enum SlidePlaybackState {
    STOPPED = 'stopped',
    PLAYING = 'playing',
    LOOP_TRANSITIONING = 'loopTransitioning',
    HOLDING = 'holding',
    ADVANCING = 'advancing'
}

export class SlideController {
    private debug: boolean = false  // don't set this to true in production

    public slide: VizzSlideModel
    public activeIndex: number
    public playerState: SlidePlaybackState
    public audioSuspended: boolean = false

    slideTimeout?: number
    mediaTimeout?: number
    suspendAudioTimeout?: number
    audioTrack?: VizzAudioService

    constructor(slide: VizzSlideModel) {
        this.consoleDebug(`new()`)

        this.slide = slide
        this.activeIndex = 0
        this.playerState = SlidePlaybackState.STOPPED

        if (this.slide.audioUrl) {
            this.audioTrack = new VizzAudioService(this.slide.audioUrl, 'slide-foreground '+this.slide.id)
        }

        makeObservable(this, {
            playerState: observable,
            activeIndex: observable,
            activeMedia: computed,
            audioSuspended: observable,
            setAudioSuspended: action,
            setActiveMediaIndex: action,
            setPlayerState: action
        })

        autorun(() => {
        })
    }

    public get activeMedia(): VizzMedia {
        this.consoleDebug(`activeMedia()`)
        return this.slide.media[this.activeIndex]
    }

    public async cleanUp() {
        this.consoleDebug(`cleanUp()`)

        if (this.slideTimeout) clearTimeout(this.slideTimeout)
        if (this.mediaTimeout) clearTimeout(this.mediaTimeout)
        if (this.suspendAudioTimeout) clearTimeout(this.suspendAudioTimeout)
        await this.audioTrack?.changeState.pauseAndUnload()
        this.setPlayerState(SlidePlaybackState.STOPPED)
    }

    public async start() {
        this.consoleDebug(`start()`)

        this.setActiveMediaIndex(0)
        this.setPlayerState(SlidePlaybackState.PLAYING)
        await this.audioTrack?.changeState.loadAndPlay()

        // If we have no slide-audio, it means we're handling a single-audio file for the entire vizz
        // and we will potentially have multiple media
        if (!this.audioTrack) {
            this.scheduleMediaTimer()
        }
        await this.scheduleSlideTimer()
        this.setAudioSuspended(false)
    }

    // State Management

    public scheduleMediaTimer() {
        this.consoleDebug(`scheduleMediaTimer()`)

        this.mediaTimeout = window.setTimeout(() => {
            const nextIndex = this.nextMediaIndex()
            if (nextIndex) {
                this.setActiveMediaIndex(nextIndex)
                this.scheduleMediaTimer()
                this.setAudioSuspended(false)
            }
        }, this.activeMedia.duration * 1000.0)

        const videoDuration = this.activeMedia.videoDuration ?? 0
        const audioDuration = this.activeMedia.audioDuration ?? 0
        if (this.activeMedia.assetType == AssetType.Video &&
            videoDuration > audioDuration) {
            this.suspendAudioTimeout = window.setTimeout(() => {
                this.setAudioSuspended(true)
            }, audioDuration * 1000.0)
        }
    }

    public async scheduleSlideTimer() {
        this.consoleDebug(`scheduleSlideTimer()`)

        const onFinishSlide = () => {
            void this.audioTrack?.changeState.pauseAndUnload() // not sure we need to unload audio when reaching the end of the slide, maybe just pause?

            switch (this.slide.slideEndBehavior) {
                case SlideEndBehavior.ADVANCE: {
                    this.setPlayerState(SlidePlaybackState.ADVANCING)
                    break
                }
                case SlideEndBehavior.LOOP: {
                    this.setPlayerState(SlidePlaybackState.LOOP_TRANSITIONING)
                    break
                }
                case SlideEndBehavior.HOLD: {
                    this.setPlayerState(SlidePlaybackState.HOLDING)
                    break
                }
                default: {
                    this.setPlayerState(SlidePlaybackState.ADVANCING)
                    break
                }
            }
        }

        const videoDuration = this.activeMedia.videoDuration ?? 0
        const audioDuration = this.activeMedia.audioDuration ?? 0

        if (this.audioTrack &&
             (this.activeMedia.assetType != AssetType.Video ||
              this.activeMedia.assetType == AssetType.Video && audioDuration >= videoDuration)
           ) {
            await this.audioTrack.setOnFinishListener(onFinishSlide)
        } else {
            // it would be better to have an onFinishListener() for our video player
            this.slideTimeout = window.setTimeout(onFinishSlide, this.slide.duration * 1000.0)
        }
    }

    setPlayerState(state: SlidePlaybackState) {
        this.consoleDebug(`setPlayerState(${state})`)
        this.playerState = state
    }

    setActiveMediaIndex(index: number) {
        this.consoleDebug(`setActiveMediaIndex(${index})`)
        this.activeIndex = index
    }

    nextMediaIndex(): (number | null) {
        this.consoleDebug(`nextMediaIndex()`)
        let index = this.activeIndex
        if (index >= 0 && index < this.slide.media.length - 1) {
            return index + 1
        } else {
            return null
        }
    }

    setAudioSuspended(audioSuspended: boolean) {
        this.consoleDebug(`setAudioSuspended()`)
        this.audioSuspended = audioSuspended
    }

    private consoleDebug(method: string, force: boolean = false) {
        if(this.debug || force) console.log(`VizzSlidePlayer: ${method}  state = ${this.playerState}  [${this.activeIndex}]`)
    }
}
