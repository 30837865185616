import {LinearGradient} from 'expo-linear-gradient'
import React from 'react'
import {StyleProp, StyleSheet, TextStyle, View, ViewStyle} from "react-native"
import {Text} from "react-native-paper"
import {Color, FontSize, isTablet} from "../../../../lib/Appearance"
import {MaterialIcons} from '@expo/vector-icons'
import {LavaImageBackground} from "../../../components/LavaImageBackground"

type Props = {
    imageUrl?: string
    placeholder?: boolean
    label?: string
    type?: string
    style?: StyleProp<ViewStyle>
    labelStyle?: StyleProp<TextStyle>
    width: number
    disabled?: boolean
    selected?: boolean
    new?: boolean
}

export const ConceptThumbnail = (props: Props) => {
    const height = props.width * (3/4)
    const padding = 10

    return (
        <View style={{}}>
            {props.new && <View style={{position: 'absolute', zIndex: 100, left: 0, top: 0, backgroundColor: 'red', width: 35, height: 25, borderRadius: 15, justifyContent: 'center', alignItems: 'center'}}><Text style={{color: 'white', fontSize: 9, fontWeight: 'bold'}}>NEW</Text></View>}
            <View style={[
                styles.conceptContainer,
                props.placeholder ? styles.placeholder : {},
                (props.imageUrl || props.label) ? styles.conceptFull : {},
                props.style,
                {
                    backgroundColor: props.disabled? 'black' : 'white',
                    width: props.width-padding, height: height-padding,
                    justifyContent: props.imageUrl ? 'flex-start' : 'center',
                }]}>

                <View style={[
                    styles.imageContainer,
                    {flex: 1, width: '100%', overflow: 'visible'},
                    props.disabled ? { top: 0 } : {}
                    ]}>

                    {props.imageUrl &&
                        <LavaImageBackground
                            style={{
                                flex: 1,
                                width: '100%',
                                opacity: props.disabled ? 0.3 : 1.0,
                                backgroundColor: Color.secondaryBackground}}
                            source={{uri: props.imageUrl, cache: 'force-cache'}}
                            resizeMode={'cover'}>
                            {props.disabled && false && <View style={{flex: 1, backgroundColor: 'black', opacity: 0.7, justifyContent: 'center'}}>
                            </View>}
                            {!props.disabled && <LinearGradient
                                colors={['rgba(255, 255, 255, 1)', 'rgba(255, 255, 255, 0.5)', 'transparent']}
                                locations={[0, 0.1, 1]}
                                style={{ backgroundColor: 'transparent', position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 }} />}
                        </LavaImageBackground>
                    }

                    {props.disabled && <View style={{position: 'absolute', width: '100%', height: '100%', justifyContent: 'center'}}>
                        <Text style={{textAlign: 'center', fontFamily: 'Agrandir-Bold', color: 'white', textShadowColor: 'rgba(0, 0, 0, 0.8)', textShadowOffset: {width: 0, height: 0}, textShadowRadius: 20}}>Coming soon</Text>
                    </View>}
                </View>

                {props.label && !props.disabled &&
                    <LinearGradient
                        colors={['rgba(255, 255, 255, 1)', 'rgba(255, 255, 255, 0)']}
                        locations={[0, 1]}
                        style={{ width: '100%' }}>
                        <Text
                            style={[styles.labelContainer, props.labelStyle, props.new ? { paddingLeft: 30 } : {}]}
                            numberOfLines={props.imageUrl ? 2 : 3}
                        >{props.label}{"\n"}
                        {props.type == 'sample' ? "(Sample)" : ''}</Text>
                    </LinearGradient>
                }

                {props.selected &&
                <View style={{position: 'absolute', width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center'}}>
                    <View style={{position: 'absolute', width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center'}}>
                        <MaterialIcons name="brightness-1" size={props.width / 1.7} color='black' />
                    </View>
                    <View style={{position: 'absolute', width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center'}}>
                        <MaterialIcons name="check-circle" size={props.width / 2} color='white' />
                    </View>
                </View>
                }
            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    conceptFull: {
        borderWidth: 1,
        borderColor: 'black',
    },
    conceptContainer: {
        alignItems: 'center',
        marginVertical: 0,
        marginHorizontal: 0,

        borderRadius: 20,
        overflow: 'hidden',
    },
    placeholder: {
        backgroundColor: 'rgba(200, 200, 200, 0.4)'
    },
    imageContainer: {
        width: '100%',
        position: 'absolute',
        top: 15,
        left: 0,
        right: 0,
        bottom: 0
    },
    labelContainer: {
        textAlign: 'center',
        width: '100%',
        marginTop: isTablet() ? 4 : 4,
        paddingHorizontal: 4,
        fontSize: FontSize.small,
        color: 'black',
        fontFamily: 'Agrandir-Medium'
    }
})