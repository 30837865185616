import React from 'react'
import {StyleSheet, View, ViewStyle, Image} from "react-native"
import Avatar, {AvatarProps} from "../../atoms/Avatar/Avatar"
import theme from "../../config/theme"

export type FriendDotProps = AvatarProps & {
    status: 'online' | 'offline',
    friendRequestSent?: boolean,
    dotStyle?: ViewStyle
}

export const FriendDot = ({ variant, dotStyle, status, friendRequestSent, ...props}: FriendDotProps) => {
    const variants = {
        primaryLarge: {
            width: 16,
            height: 16,
            borderRadius: 16/2,
            bottom: 2
        },
        secondary: {
            width: 14,
            height: 14,
            borderRadius: 14/2,
            bottom: 2
        },
        primarySmall: {
            width: 12,
            height: 12,
            borderRadius: 12/2,
            bottom: 2
        },
    }

    return(
        <View style={status === 'online' && {alignSelf: 'flex-start', position: 'relative'}}>
            <Avatar
                variant={variant}
                {...props}
            />
            {status === 'online' && <View style={[variants[variant], {...styles.dot, ...dotStyle}]}/>}
            {status === 'offline' && friendRequestSent && <Image source={require('./assets/FriendRequest.png')} style={styles.badge} />}
        </View>
    )
}

const styles = StyleSheet.create({
    dot: {
        backgroundColor: theme.colors.dark.tertiaryOnline,
        right: -2,
        position: 'absolute',
        borderWidth: 2,
        borderStyle: 'solid',
        borderColor: '#1B1F23'
    },
    badge: {
        width: 18,
        height: 18,
        position: 'absolute',
        right: -2,
        bottom: 0,
        borderWidth: 2,
        borderRadius: 10,
        borderColor: '#1B1F23'
    },
})
