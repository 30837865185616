import React from 'react'
import {createStackNavigator} from '@react-navigation/stack'
import {RootStackNavigatorList} from '../lib/interfaces/root-stack-navigator'
import {NavigationContainer} from '@react-navigation/native'
import {Home} from "../../modules/start/views/Home"
import {Home as NewHomeScreenLift} from "../../modules/start/views/NewHomeScreenLift"
import {DebugScreen} from "./Debug"
import {observer} from 'mobx-react'
import {Home as NewHomeScreen} from "../../modules/start/views/NewHomeScreen"
import {RenderLog} from "../services/RenderLog"


export const MobileNavigation = observer(() => {
    const Stack = createStackNavigator<RootStackNavigatorList>()

    RenderLog.track('MobileNavigation')

    return (
        <NavigationContainer>
            <Stack.Navigator
                initialRouteName={'StartHome'}>
                <Stack.Screen
                    name='StartHome'
                    options={{headerShown: false}}
                    component={Home}/>
                <Stack.Screen
                    name="Debug"
                    options={{title: "Debug"}}
                    component={DebugScreen}/>
                <Stack.Screen
                    name="NewHomeScreenLift"
                    options={{title: "NewHomeScreenLift"}}
                    component={NewHomeScreenLift}/>
                <Stack.Screen
                    name="NewHomeScreen"
                    options={{title: "NewHomeScreen"}}
                    component={NewHomeScreen}/>
            </Stack.Navigator>
        </NavigationContainer>
    )
})