import React, {useEffect} from 'react'
import {StyleSheet, Text, View, ViewStyle} from "react-native"
import {observer} from "mobx-react"
import {RobloxFriendModel} from '../../models/RobloxFriendModel'
import {ProfileModel} from '../../models/ProfileModel'
import {FeedWidgetController} from '../../controllers/FeedWidgetController'
import {Button} from 'react-native-paper'
import {Typography} from '../../../../app/lib/Typography'
import {LavaImage} from "../../../../app/views/components/LavaImage"

type Props = {
    controller?: FeedWidgetController
    title: string
    body: string
    newPersonProfile: ProfileModel
    newPersonRobloxProfile?: RobloxFriendModel
    style?: ViewStyle
}

export const NewSchoolPersonStory = observer((props: Props) => {

    useEffect(() => {
        return () => {
        }
    }, [])

    return (
        <View style={[styles.container, props.style]}>
            <View style={{flexDirection: 'row'}}>
                <Text numberOfLines={2} style={[Typography.headline2, {color: 'white', fontSize: 18, flexGrow: 1}]}>
                    {props.title}
                </Text>
            </View>
            <View style={{flexGrow: 1}}>
                <View style={{flexDirection: 'row'}}>
                    {props.newPersonRobloxProfile &&
                    <LavaImage style={{width: 100, height: 100, marginTop: 0 }} source={{uri: props.newPersonRobloxProfile.avatar_url}} />}

                    <Text style={[Typography.label, {color: 'white', marginTop: 20, fontSize: 13, marginBottom: 20, flexGrow: 1 }, props.newPersonRobloxProfile ? { maxWidth: 140 } : {} ]}>{props.body}</Text>
                </View>

                <View style={props.newPersonRobloxProfile ? {alignItems: 'flex-end', marginTop: -15} : { alignItems: 'center'}}>
                    <Button
                        mode={'contained'}
                        color={'green'}
                        dark={true}
                        compact={false}
                        onPress={() => { props.controller && props.controller.onNewSchoolPersonTapped() }}>
                        Add Friend
                    </Button>
                </View>
            </View>
        </View>
    )
})

const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: 'column',
        padding: 18,
        backgroundColor: 'rgba(22,30,44,1)',
        borderRadius: 8,
        maxWidth: 280,
    },
})
