import {observer} from "mobx-react"
import React, {forwardRef, useImperativeHandle, useRef} from "react"
import {Platform, View, ViewStyle, BackHandler} from "react-native"
import Background from "../../../modules/start/views/home/Background"
import {IconButton} from "react-native-paper"
import {useSafeAreaInsets} from "react-native-safe-area-context"
import {LavaModal} from "./LavaModal"
import { productSk2Map } from "react-native-iap/lib/typescript/src/types/appleSk2"

type Props = React.PropsWithChildren<{
    visible: boolean,
    onWillAppear?: () => void
    onDidDisappear?: () => void
    onDismiss?: () => void,
    style?: ViewStyle,
    innerModal?: boolean
    animation?: 'slide' | 'scale'
}>

export const FullscreenModal = observer(forwardRef((props: Props, ref) => {
    const {top} = useSafeAreaInsets()
    const modalRef = useRef<any>()

    if (Platform.OS === 'android') {
        BackHandler.addEventListener('hardwareBackPress', () => {
            console.debug("adding hardware event listener", {props})
            if(props.visible === true){
                console.debug("visible is true")
                modalRef.current?.dismiss()
                if (props.onDismiss) props.onDismiss()
                return true
            }else{
                return false
            }
        })
    }

    useImperativeHandle(ref, () => ({
        dismiss: () => {
            modalRef.current?.dismiss()
        }
    }))

    return (
        <LavaModal
            ref={modalRef}
            onWillAppear={props.onWillAppear}
            onDidDisappear={props.onDidDisappear}
            animation={props.animation}
            active={props.visible}>
            <View style={{flex: 1, backgroundColor: 'black'}}>
                <View style={{flex: 1}}>
                    {props.children}
                    <View style={{
                        position: 'absolute',
                        top: top,
                        right: 0,
                        margin: 0,
                        zIndex: props.innerModal ? -10 : 10
                    }}>
                        <IconButton
                            onPress={() => {
                                modalRef.current.dismiss()
                                if (props.onDismiss) props.onDismiss()
                            }}
                            icon={'close'}
                            size={30}
                            color={'white'}/>
                    </View>
                </View>
                <Background
                    mobileImage={require('../../assets/bg-modal.png')}
                    tableImage={require('../../assets/bg-modal.png')}/>
            </View>
        </LavaModal>
    )
}))
