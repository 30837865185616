import React, {useContext, useEffect, useState} from 'react'
import {VizzModel, VizzHelpers} from "../../../modules/vizz_maker/models/VizzModel"
import {observer} from "mobx-react"
import {ActivityIndicator, Dimensions, SafeAreaView, ScrollView, Share, StyleSheet, Text, View} from "react-native"
import Vizz from "../../../modules/vizz_maker/views/Vizz"
import {StackNavigationProp } from "@react-navigation/stack"
import { useHeaderHeight } from "@react-navigation/elements"
import {RootDrawerNavigatorList} from "../../lib/interfaces/root-tab-navigator"
import {RouteProp, useNavigation} from '@react-navigation/native'
import {Color} from "../../lib/Appearance"
import {MetaTags} from "../components/MetaTags"
import {Ionicons} from '@expo/vector-icons'
import {IconButton} from "react-native-paper"
import {StatusBar} from "expo-status-bar"
import CurrentAccountContext from '../../../modules/vizz_account/lib/CurrentAccount'

type VizzDetailScreenRouteProp = RouteProp<RootDrawerNavigatorList, 'VizzDetail'>

type VizzDetailScreenNavigationProp = StackNavigationProp<
    RootDrawerNavigatorList,
    'VizzDetail'>

type Props = {
    route: VizzDetailScreenRouteProp
    navigation: VizzDetailScreenNavigationProp
}

const VizzDetailScreen = observer((props: Props) => {

    const headerHeight = useHeaderHeight()
    const navigation = useNavigation()
    const currentAccount = useContext(CurrentAccountContext)
    const [vizz, setVizz] = useState<(VizzModel | undefined)>(undefined)
    const [refreshing, setRefreshing] = useState<boolean>(false)

    const [playerHeight, setPlayerHeight] = useState<number>(0)
    const [primaryColor, setPrimaryColor] = useState<string>('#FFFFFF')
    const [foregroundColor, setForegroundColor] = useState<string>('#000000')

    const [videoWidth, setVideoWidth] = useState<number>(0)
    const [videoHeight, setVideoHeight] = useState<number>(0)

    const targetHeight = () => {
        // Dimensions.height may not be accurate on Android but that should be okay
        // in this situation. This layout calculator should work even if it's
        // slightly off. But if we want to make perfect, we may need this:
        // https://github.com/Sunhat/react-native-extra-dimensions-android
        return Math.round((Dimensions.get("window").height - headerHeight) * 0.65)
    }

    const calcVideoWidth = () => {
        return Math.min(
            targetHeight() / 3 * 4,
            Dimensions.get("window").width
        )
    }

    const updateVideoSize = () => {
        setVideoWidth(calcVideoWidth())
        setVideoHeight(calcVideoWidth() / 4 * 3)
    }

    const normalize = (num: number) => {
        const max = Math.round(num * 1.3)
        const adjusted = Math.round(
            (calcVideoWidth() / 375) * num
        )
        return Math.min(max, adjusted)
    }

    useEffect(() => {
        updateVideoSize()
        const cleanUpDimen = Dimensions.addEventListener("change", updateVideoSize)

        return () => {
            cleanUpDimen.remove()
        }
    }, [])

    useEffect(() => {
        navigation.setOptions({
            headerStyle: {
                backgroundColor: 'white',
            },
            headerTintColor: Color.darkText
        })
        void refresh()
    }, [])

    const shareButton = () => {
        if (vizz) {
            return (
                <IconButton icon={() => <Ionicons name="share-outline" size={24} color={vizz.foregroundColor} />}
                            onPress={onShare} />
            )
        }

        return null
    }

    const onShare = async () => {
        try {
            const result = await Share.share({
                message: 'Check out what I found on Vizz!',
                url: `https://lava.co/vizz/${vizz?.id ?? ''}`
            })
            if (result.action === Share.sharedAction) {
                if (result.activityType) {
                    // shared with activity type of result.activityType
                } else {
                    // shared
                }
            } else if (result.action === Share.dismissedAction) {
                // cancelled
            }
        } catch (error) {
            //alert(error.message)
        }
    }

    useEffect(() => {
        if (vizz) {
            setPrimaryColor(vizz.primaryColor)
            setForegroundColor(vizz.foregroundColor)

            navigation.setOptions({
                title: vizz.title,
                headerStyle: {
                    backgroundColor: vizz.primaryColor,
                },
                headerTintColor: vizz.foregroundColor,
                headerRight: () => shareButton()
            })
        }
    }, [vizz])

    const refresh = async () => {
        if (refreshing) return

        const vizz = await currentAccount.api.get(`vizz_maker.vizz_path(${props.route.params.vizzId})`, {seconds: 0})
        setRefreshing(false)
        setVizz(vizz)
    }

    const statusBarStyle = () => {
        return foregroundColor == '#000000' ? 'dark' : 'light'
    }

    return (
        <SafeAreaView style={[styles.container, {backgroundColor: primaryColor}]}>
            <StatusBar style={statusBarStyle()} />

            {vizz != undefined ?
                <View style={{flex: 1}}>
                    <MetaTags vizz={vizz}/>
                    <ScrollView
                        style={styles.scrollView}
                        contentContainerStyle={styles.scrollContainer}
                        alwaysBounceHorizontal={false}>
                        <View style={{width: videoWidth}}>
                            <View style={[styles.vizzPlayer, {height: videoHeight}]}>
                                <Vizz vizz={vizz} preload={true} play={props.route.params.autoPlay} />
                            </View>
                            <Text style={[styles.title, {
                                fontSize: normalize(48),
                                paddingTop: normalize(10),
                                paddingStart: normalize(12),
                                paddingEnd: normalize(12),
                                paddingBottom: normalize(5),
                                lineHeight: normalize(54)
                            }]}>
                                {vizz.title}
                            </Text>
                            <Text style={[styles.body, {
                                color: foregroundColor,
                                paddingStart: normalize(12),
                                paddingEnd: normalize(12),
                                paddingTop: normalize(4),
                                paddingBottom: normalize(24),
                                lineHeight: normalize(26),
                                fontSize: normalize(16)
                            }]}>
                                {VizzHelpers.fullScript(vizz)}
                            </Text>
                        </View>
                    </ScrollView>
                </View>
                :
                <View style={styles.loading}>
                    <ActivityIndicator size="large" color={Color.primary}/>
                </View>
            }
        </SafeAreaView>
    )
})

const styles = StyleSheet.create({
    container: {
        flex: 1
    },
    scrollContainer: {
        alignItems: 'center'
    },
    scrollView: {
        flex: 1
    },
    vizzPlayer: {
        width: '100%'
    },
    loading: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center'
    },
    title: {
        color: Color.secondary,
        fontWeight: '800'
    },
    body: {
        fontStyle: 'normal',
        fontWeight: '500'
    }
})

export default VizzDetailScreen
