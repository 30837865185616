import * as Brightness from "expo-brightness"
import {Platform} from "react-native"
import * as FileSystem from "expo-file-system"
import {activateKeepAwakeAsync, deactivateKeepAwake} from 'expo-keep-awake'
import * as BackgroundStatusUpdates from "../../modules/background-status-updates"
import SentryService from "../services/SentryService"

export class DeviceUtils {

    public static async getBrightnessAsync(): Promise<number> {
        if (Platform.OS == 'web') return 1.0
        if (Platform.OS == 'ios') return await Brightness.getBrightnessAsync()
        if (Platform.OS == 'android') {
            try {
                return BackgroundStatusUpdates.isScreenOn() ? 1.0 : 0.0
            } catch (error) {
                SentryService.captureError(error)
                return 1.0
            }
        }

        return 1.0
    }

    public static async getAvailableDiskSpaceMB() {
        if (Platform.OS == 'web') return 0
        const availableDisk = await FileSystem.getFreeDiskStorageAsync()
        return availableDisk / 1048576
    }

    public static activateKeepAwake = () => {
        if (Platform.OS == 'web') return

        void activateKeepAwakeAsync()
    }

    public static deactivateKeepAwake = () => {
        if (Platform.OS == 'web') return

        void deactivateKeepAwake()
    }
}
