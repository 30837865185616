import React, {useContext, useEffect, useRef, useState} from 'react'
import {observer} from "mobx-react"
import {Platform, StyleProp, StyleSheet, Text, View, ViewStyle} from "react-native"
import {FriendWidgetController} from "../../controllers/FriendWidgetController"
import {SpeechUtils} from '../../../../app/utils/AVUtils'
import CurrentAccountContext from '../../../vizz_account/lib/CurrentAccount'
import AppControllerContext from '../../../../app/controllers/AppController'
import Button from '../../../lava-components/components/atoms/Button/Button'
import {platformName} from "../../../../app/lib/utils/CrossPlatformUtils"
import {openSettings} from "expo-linking"

type Props = {
    friendWidgetController: FriendWidgetController
    style?: StyleProp<ViewStyle>
}

export const NotificationDisabled = observer((props: Props) => {
    const appController = useContext(AppControllerContext)
    const currentAccount = useContext(CurrentAccountContext)
    const [speech] = useState(() => new SpeechUtils(currentAccount, true))
    const [canAsk, setCanAsk] = useState<boolean>(false)
    const isMounted = useRef(false)

    useEffect(() => {
        isMounted.current = true

        async function init() {
            const canAsk = await appController.notification.canAsk()
            setCanAsk(canAsk)
        }

        void init()

        return () => {
            isMounted.current = false
            teardown()
        }
    }, [])

    const allow = async() => {
        if (isMounted.current) void speech.speak(Platform.OS == 'ios'
            ? "Tap Allow again, it's the button on the right." :
            "Tap Allow again.")
        if (isMounted.current) props.friendWidgetController.homeController?.setDimBackground(true)
        if (isMounted.current) await appController.notification.enableNotifications()
        if (isMounted.current) setCanAsk(await appController.notification.canAsk())
        teardown()
    }

    const teardown = () => {
        props.friendWidgetController.homeController?.setDimBackground(false)
        void props.friendWidgetController.determineAndSetState()
    }

    return (
        <View style={[styles.container, props.style]}>
            <Text style={styles.text}>To call and chat friends, allow notifications.</Text>

            {canAsk && <Button variation={'gravity'} onPress={allow}>Allow</Button>}

            {!canAsk && Platform.OS === 'ios' && <Text style={styles.text}>Go to iOS Settings, find Lava, and tap Notifications.</Text>}
            {!canAsk && Platform.OS === 'android' && <Text style={styles.text}>Go to Android Settings, tap Permissions, and tap Notifications.</Text>}
            {!canAsk && <Button variation={'gravity'} onPress={() => openSettings()}>{platformName()} Settings</Button>}
        </View>
    )
})

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        ...StyleSheet.absoluteFillObject
    },
    text: {
        padding: 10,
        color: 'white',
        textAlign: 'center'
    }
})
