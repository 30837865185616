import React from 'react'
import useTheme from "../../../modules/lava-components/components/hooks/useTheme"
import {StyleSheet, View} from "react-native"
import {ActivityIndicator} from "react-native-paper"

type Props = {
    transparent?: boolean
}

export const Loading = (props: Props) => {
    const theme = useTheme()

    return (
        <View style={{
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: props.transparent ? 'transparent' : 'rgba(0, 0, 0, 0.75)',
            ...StyleSheet.absoluteFillObject
        }}>
            <ActivityIndicator color={theme.colors.gravity} />
        </View>
    )
}