import {observer} from "mobx-react"
import {Animated, ScrollView, StyleSheet, TouchableOpacity, View} from "react-native"
import useTheme from "../../../lava-components/components/hooks/useTheme"
import Title from "../../../lava-components/components/atoms/Title/Title"
import {TouchableRipple} from "react-native-paper"
import {RobloxGameDetailController} from "../../controllers/roblox_games_widget/RobloxGameDetailController"
import React, {useEffect, useRef, useState} from "react"
import {LavaImage} from "../../../../app/views/components/LavaImage"
import Button from "../../../lava-components/components/atoms/Button/Button"
import {EXPANDED_WIDTH, RobloxGameDetailSidePanelState} from "./RobloxGameDetailConstants"
import {Video} from "../Video"
import {isPortrait} from "../../../../app/lib/Appearance"

type Props = {
    controller: RobloxGameDetailController
    sidePanelWidth: Animated.Value
    animatedPanelWidth: Animated.Value
    sidePanelState?: RobloxGameDetailSidePanelState
    setSidePanelState?: React.Dispatch<React.SetStateAction<RobloxGameDetailSidePanelState>>
    playPreviewVideo?: boolean
    setPlayPreviewVideo?:React.Dispatch<React.SetStateAction<boolean>>
}

export const RobloxGameDetailSidePanel = observer((props: Props) => {

    const theme = useTheme()
    const [imageLayout, setImageLayout] = useState({x: 0, y: 0, width: 0, height: 0})
    const videoRef = useRef<any>(null)
    const {animatedPanelWidth, sidePanelWidth, sidePanelState, setSidePanelState,playPreviewVideo,setPlayPreviewVideo} = props
    const [isGameDescriptionCollapsed, setGameDescriptionCollapsed] = useState(isPortrait())

    useEffect(() => {
        if (playPreviewVideo && props.controller.previewVideo) {
            videoRef.current?.autoPlay()
        }
    }, [props.controller.previewVideo, playPreviewVideo])

    useEffect(() => {
        const animationListener = animatedPanelWidth.addListener(({value}) => {
            sidePanelWidth.setValue(value)
        })

        return () => {
            animatedPanelWidth.removeListener(animationListener)
        }
    }, [animatedPanelWidth])

    const imageHeight = () => {
        return imageLayout.width * 9 / 16
    }

    const expandSidePanel = () => {
        if (sidePanelState === RobloxGameDetailSidePanelState.COLLAPSED && setSidePanelState) {
            setSidePanelState(RobloxGameDetailSidePanelState.EXPANDED)
            Animated.timing(animatedPanelWidth, {
                toValue: EXPANDED_WIDTH,
                duration: 200,
                useNativeDriver: true
            }).start()
        }
    }

    const FriendsInGame = () => {
        if ((props.controller.friendPresencesInGame ?? []).length == 0) return null
        return (
            <View>
                <Title style={{marginTop: theme.spacing.xxxl * 2, marginBottom: theme.spacing.xl}} variation={'title2'}>Friends in Game</Title>

                {props.controller.friendPresencesInGame?.map((presence) => {
                    return (
                        <View key={`friend-${presence.person_key}`} style={{flexDirection: 'row', alignItems: 'center', marginTop: theme.spacing.md}}>
                            <Title numberOfLines={3} variation="subtitle1" style={{marginEnd: theme.spacing.md, flexGrow: 1}}>
                                {presence.pretty_username}
                            </Title>
                            <Button
                                style={{backgroundColor: theme.colors.tertiaryOnline}}
                                onPress={() => props.controller.joinFriendPresence(presence)}
                                roundness={'flat'}
                                variation={'gravity'}
                            >Join
                            </Button>
                        </View>
                    )
                })}
            </View>
        )
    }

    return (
        <View style={{flex: 1}} onTouchEnd={expandSidePanel}>
            <View style={{flex: 1, marginHorizontal: 28}}>
                {props.controller.previewVideo ?
                    <React.Fragment>
                        <Animated.View style={{height: Animated.divide(props.sidePanelWidth, 2), borderRadius: 1}} onLayout={(e) => setImageLayout(e.nativeEvent.layout)}>
                            {
                                playPreviewVideo ?
                                    <Video
                                        ref={videoRef}
                                        video={props.controller.previewVideo}
                                    /> :
                                    <TouchableOpacity
                                        onPress={() => [setPlayPreviewVideo?.(true), expandSidePanel()]}
                                        style={{marginBottom: 1}}
                                    >
                                        <Animated.View style={{height: Animated.divide(props.sidePanelWidth, 2), borderRadius: 1}} onLayout={(e) => setImageLayout(e.nativeEvent.layout)}>
                                            <Animated.Image style={{flex: 1, width: Animated.subtract(props.sidePanelWidth, new Animated.Value(56))}} source={{uri: props.controller.previewVideo.thumbnail_url}}
                                                resizeMode={'cover'} />
                                            <View style={{alignItems: 'center', justifyContent: 'center', ...StyleSheet.absoluteFillObject}}>
                                                <Animated.Image style={{
                                                    width: Animated.multiply(imageLayout.width, (80 / EXPANDED_WIDTH)),
                                                    height: Animated.multiply(imageLayout.width, (80 / EXPANDED_WIDTH))
                                                }} source={require('../../assets/icn-preview-play.png')}/>
                                            </View>
                                        </Animated.View>

                                    </TouchableOpacity>

                            }
                        </Animated.View>
                    </React.Fragment>
                    :
                    <View style={{height: imageHeight(), width: '100%'}}
                        onLayout={(e) => setImageLayout(e.nativeEvent.layout)}>
                        <LavaImage
                            style={{flex: 1}}
                            resizeMode={'cover'}
                            source={{uri: props.controller.parentBook.image_url}} />
                    </View>
                }

                {props.controller.gameDescription &&
                    <ScrollView style={{flexGrow: 1, marginTop: theme.spacing.xxl, marginBottom: theme.spacing.xxxl * 2}}>
                        {isGameDescriptionCollapsed ?
                            <TouchableRipple onPress={() => setGameDescriptionCollapsed(false)}>
                                <View>
                                    <Title variation={'subtitle3'} numberOfLines={3}>
                                        {props.controller.gameDescription}
                                    </Title>
                                    <View style={{flexDirection: 'row'}}>
                                        <Title style={{flex: 1}} variation={'subtitle3'} numberOfLines={1}>
                                            ...
                                        </Title>
                                       <Title style={{flex: 1, textDecorationLine: 'underline', textAlign: 'right'}} variation={'subtitle3'} numberOfLines={1}>Show more</Title>
                                    </View>
                                </View>
                            </TouchableRipple>
                            :
                            <Title variation={'subtitle3'}>
                                {props.controller.gameDescription}
                            </Title>}
                    </ScrollView>
                }

                {FriendsInGame()}

                <View style={{marginBottom: isPortrait() ? theme.spacing.xxxl * 2 : undefined}}>
                    <View style={{flexDirection: 'row', alignItems: 'center', marginTop: theme.spacing.xxl, marginBottom: theme.spacing.xl}}>
                        <Title variation={'title2'}>Play Game </Title>
                        <LavaImage style={{width: 18, height: 18, marginLeft: 5}} resizeMode={'contain'} source={require('../../../social/assets/icn-roblox2.png')} />
                    </View>
                    <TouchableRipple onPress={() => {
                        void props.controller.playGame()
                        expandSidePanel()
                    }}>
                        <View style={{
                            width: '100%',
                            height: 45,
                            backgroundColor: '#418F4E',
                            opacity: 1.0,
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}>
                            <LavaImage source={require('../../assets/btn-play.png')} />
                        </View>
                    </TouchableRipple>
                </View>
            </View>

            <View style={{
                position: 'absolute',
                top: 0,
                bottom: 0,
                right: 0,
                width: 0.5,
                backgroundColor: theme.colors.primarySand40
            }} />

        </View>
    )
})
