import React, {useContext, useEffect, useState} from 'react'
import {SafeAreaView, ScrollView, StyleSheet, Text, View, ViewStyle} from "react-native"
import {observer} from "mobx-react"
import {OnboardingController, OnboardingState} from "../controllers/OnboardingController"
import CurrentAccountContext from "../../../modules/vizz_account/lib/CurrentAccount"
import HomeController from '../../start/controllers/HomeController'
import {Audio} from './steps/Audio'
import {IDFA} from './steps/IDFA'
import {Pitch} from './steps/Pitch'
import {Sample} from './steps/Sample'
import {TouchableRipple} from 'react-native-paper'
import {Age} from './steps/Age'
import {SMS} from './steps/SMS'
import {Profile} from './steps/Profile'
import {ManifestUtils} from '../../../app/utils/ManifestUtils'
import {School} from "./steps/School"
import {Referral} from "./steps/Referral"
import {Friends} from './steps/Friends'
import {FeatureFlagModel} from '../../vizz_account/models/FeatureFlagModel'
import {Interests} from './steps/Interests'

type Props = {
    style?: ViewStyle
    homeController: HomeController
}

export const Onboarding = observer((props: Props) => {
    const currentAccount = useContext(CurrentAccountContext)
    const [controller] = useState<OnboardingController>(() => new OnboardingController(currentAccount, props.homeController))

    useEffect(() => {
        controller.initialize()

        return () => {
            controller.uninitialize()
        }
    }, [])

    return (
        <React.Fragment>
            {controller.state != OnboardingState.BLANK &&
            <SafeAreaView style={{flex: 1, zIndex: 100, ...StyleSheet.absoluteFillObject}}>
                <View style={[styles.container, props.style]}>
                    {currentAccount.personData.idfaOnboardingNeeded && <IDFA onboardingController={controller} />}
                    {currentAccount.personData.audioOnboardingNeeded && <Audio onboardingController={controller} />}
                    {currentAccount.personData.pitchOnboardingNeeded && <Pitch onboardingController={controller} />}
                    {currentAccount.personData.profileOnboardingNeeded && <Profile onboardingController={controller} />}
                    {currentAccount.personData.ageOnboardingNeeded && <Age onboardingController={controller} />}
                    {currentAccount.personData.smsOnboardingNeeded && <SMS onboardingController={controller} />}
                    {currentAccount.personData.interestsOnboardingNeeded && currentAccount.hasFeature(FeatureFlagModel.DAILY_CONTENT) && <Interests onboardingController={controller} />}
                    {currentAccount.personData.sampleOnboardingNeeded && <Sample onboardingController={controller} />}
                    {currentAccount.personData.friendsOnboardingNeeded && <Friends onboardingController={controller} />}
                    {currentAccount.personData.referralOnboardingNeeded && <Referral onboardingController={controller} />}
                    {currentAccount.personData.schoolOnboardingNeeded && <School onboardingController={controller} />}

                    {ManifestUtils.env == 'development' && controller.state != OnboardingState.LOADING &&
                    <View style={{flexDirection: 'row', backgroundColor: 'red'}}>
                        <ScrollView horizontal={true} showsHorizontalScrollIndicator={false}>
                            <View style={{flexDirection: 'row'}}>
                                <TouchableRipple onPress={() => OnboardingController.setStep('idfa', currentAccount, controller)}>
                                    <Text> IDFA: {currentAccount.personData.idfaOnboardingNeeded ? 'O' : 'X'}, </Text>
                                </TouchableRipple>
                                <TouchableRipple onPress={() =>  OnboardingController.setStep('audio', currentAccount, controller)}>
                                    <Text>Audio: {currentAccount.personData.audioOnboardingNeeded ? 'O' : 'X'}, </Text>
                                </TouchableRipple>
                                <TouchableRipple onPress={() =>  OnboardingController.setStep('pitch', currentAccount, controller)}>
                                    <Text>Pitch: {currentAccount.personData.pitchOnboardingNeeded ? 'O' : 'X'}, </Text>
                                </TouchableRipple>
                                <TouchableRipple onPress={() =>  OnboardingController.setStep('profile', currentAccount, controller)}>
                                    <Text>Profile: {currentAccount.personData.profileOnboardingNeeded ? 'O' : 'X'}, </Text>
                                </TouchableRipple>
                                <TouchableRipple onPress={() =>  OnboardingController.setStep('age', currentAccount, controller)}>
                                    <Text>Age: {currentAccount.personData.ageOnboardingNeeded ? 'O' : 'X'}, </Text>
                                </TouchableRipple>
                                <TouchableRipple onPress={() =>  OnboardingController.setStep('sms', currentAccount, controller)}>
                                    <Text>SMS: {currentAccount.personData.smsOnboardingNeeded ? 'O' : 'X'}, </Text>
                                </TouchableRipple>
                                <TouchableRipple onPress={() =>  OnboardingController.setStep('interests', currentAccount, controller)}>
                                    <Text>Interests: {currentAccount.personData.interestsOnboardingNeeded ? 'O' : 'X'}, </Text>
                                </TouchableRipple>
                                <TouchableRipple onPress={() =>  OnboardingController.setStep('sample', currentAccount, controller)}>
                                    <Text>Sample: {currentAccount.personData.sampleOnboardingNeeded ? 'O' : 'X'}, </Text>
                                </TouchableRipple>
                                <TouchableRipple onPress={() =>  OnboardingController.setStep('friends', currentAccount, controller)}>
                                    <Text>Friends: {currentAccount.personData.friendsOnboardingNeeded ? 'O' : 'X'}, </Text>
                                </TouchableRipple>
                                <TouchableRipple onPress={() =>  OnboardingController.setStep('referral', currentAccount, controller)}>
                                    <Text>Referral: {currentAccount.personData.referralOnboardingNeeded ? 'O' : 'X'}, </Text>
                                </TouchableRipple>
                                <TouchableRipple onPress={() =>  OnboardingController.setStep('school', currentAccount, controller)}>
                                    <Text>School: {currentAccount.personData.schoolOnboardingNeeded ? 'O' : 'X'}, </Text>
                                </TouchableRipple>
                            </View>
                        </ScrollView>
                    </View>}
                </View>
            </SafeAreaView>}
        </React.Fragment>
    )
})

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: 'transparent',
    },
})
