import {ImageBackground, ImageProps} from "react-native"
import React, {PropsWithChildren} from "react"

type Props = {} & PropsWithChildren & ImageProps

export const LavaImageBackground = (props: Props) => {
    let source = props.source as any
    if (source.uri) {
        source.cache = 'force-cache'
    }
    return (
        <ImageBackground
            {...props}
        >
            {props.children}
        </ImageBackground>
    )
}