import {action, computed, makeObservable, observable} from "mobx"
import {AppController} from "../../../../app/controllers/AppController"
import {CurrentAccount} from '../../../vizz_account/lib/CurrentAccount'
import {FeatureFlagModel} from "../../../vizz_account/models/FeatureFlagModel"
import BookModel from "../../models/BookModel"
import PageModel from "../../models/PageModel"
import VideoModel from "../../models/VideoModel"
import SeriesController from "./SeriesController"
import TableOfContentsController from "./book/title/TableOfContentsController"
import {ActivityName, TimedActivity} from "../../../../app/lib/services/TimedActivity"
import {GameBookModel} from "../../models/GameBookModel"
import {ProfileType} from "../../../social/models/ProfileModel"
import SentryService from "../../../../app/services/SentryService"
import {YouTubeUtils} from "../../../../app/utils/AVUtils"

export enum BookControllerState {
    UNINITIALIZED = 'uninitialized',
    INITIALIZING = 'initializing',
    INITIALIZED = 'initialized',
}

class BookController {
    private debug: boolean = false  // don't set this to true in production

    private currentAccount: CurrentAccount

    public appController: AppController
    public seriesController: SeriesController
    public tableOfContents: TableOfContentsController
    public bookIndex: number
    private bookFetchTimeout?: any

    @observable currentPageIndex: number | null = null
    @observable state: BookControllerState = BookControllerState.UNINITIALIZED

    constructor(currentAccount: CurrentAccount, appController: AppController, seriesController: SeriesController, bookIndex: number) {
        this.consoleDebug(`new()`)

        this.currentAccount = currentAccount
        this.appController = appController
        this.seriesController = seriesController
        this.tableOfContents = new TableOfContentsController(currentAccount, this)
        this.bookIndex = bookIndex

        makeObservable(this)
    }

    public async initialize() {
        try {
            this.consoleDebug(`initialize()`)

            TimedActivity.log(ActivityName.PLAY_VIDEO, 'init-book')

            this.updateState(BookControllerState.INITIALIZING)

            // Another thread could be attempting to uninitialize during every line below

            let pages: PageModel[] = []

            if (this.book.id) {   // Book.id was made optional because the front-end currently constructs books
                // on-the-fly in voice search. Right now it's from the Inbox. You tap a suggested
                // video and that video doesen't live in a concept. Should we eventually assign
                // every video to a concept? Probably. Until that is done we create a fake book in
                // VoiceOnlySearchOverlay and pass that in
                let book = await this.currentAccount.api.get(`browse.concept_book_path(${this.book.id})`, {
                    first_video_id: this.book.first_page_video_id,
                    search_query_type: this.book.search_query_type, // check search_service.rb for options
                    search_query_method: this.book.search_query_method, // check search_service.rb for options
                    search_query_appropriateness: this.book.search_query_appropriateness,
                    backfill_query: this.book.found_with_search_query,
                    include_backfill: (await this.shouldShowRelatedVideos()),
                    limit: 1
                }) as BookModel

                this.bookFetchTimeout = setTimeout(async (book_id, firstPageVideoId, searchQueryType, searchQueryMethod, searchQueryAppropriateness, foundWithSearchQuery, shouldShowRelatedVideos) => {
                    let fullBook = await this.currentAccount.api.get(`browse.concept_book_path(${book_id})`, {
                        first_video_id: firstPageVideoId,
                        search_query_type: searchQueryType, // check search_service.rb for options
                        search_query_method: searchQueryMethod, // check search_service.rb for options
                        search_query_appropriateness: searchQueryAppropriateness,
                        backfill_query: foundWithSearchQuery,
                        include_backfill: shouldShowRelatedVideos,
                        limit: 100
                    }) as BookModel

                    if (!book.pages) book.pages = []

                    // Append Pages, Unique by ID
                    book.pages = Array.from(new Map([book.pages, fullBook.pages].flat().filter(item => item != undefined).map(item => [item ? item['id'] : undefined, item])).values()) as PageModel[]

                    book.pages = await this.filterViewablePages(book.pages)

                    if (this.book.title_override) book.title = this.book.title //TODO: Remove this hack
                    this.seriesController.setBook(this.bookIndex, book)
                }, 1000, this.book.id, this.book.first_page_video_id, this.book.search_query_type, this.book.search_query_method, this.book.search_query_appropriateness, this.book.found_with_search_query, await this.shouldShowRelatedVideos())

                if (this.book.title_override) book.title = this.book.title //TODO: Remove this hack
                this.seriesController.setBook(this.bookIndex, book)
            }

            await this.tableOfContents.initialize()

            if (this.book.pages && (this.book.pages?.length ?? 0) > 0) {
                this.setCurrentPageIndex(0)
            }

            this.updateState(BookControllerState.INITIALIZED)   // It's important for this to be the last line so we do
        } catch (error) {
            SentryService.captureError(error)
        }
    }

    public async uninitialize() {
        if (this.bookFetchTimeout) clearTimeout(this.bookFetchTimeout)
        this.appController.video.stopPlayingVideo()
    }


    public async filterViewablePages(pages: PageModel[]): Promise<PageModel[]> {
        const isViewablePromises = pages.map(async (page) => {
            if (page.video.youtube_key == undefined) {
                return true
            } else {
                const videoIsPlayable = await YouTubeUtils.isVideoPlayable(page.video.youtube_key)
                return videoIsPlayable
            }
        })

        const isViewableArray = await Promise.all(isViewablePromises)
        const filteredPages = pages.filter((_, index) => isViewableArray[index])

        console.log(`filteredPages.length = ${filteredPages.length}`)

        return filteredPages
    }


    @computed
    get active() {
        return this.seriesController.activeBookIndex == this.bookIndex
    }


    // Public helper methods

    @computed
    get book(): BookModel | GameBookModel {
        return this.seriesController.books[this.bookIndex]
    }

    public async shouldShowRelatedVideos() {
        if (!this.currentAccount.hasFeature(FeatureFlagModel.SUGGESTIONS_REQUIRE_YOUTUBE)) return true

        const hasYouTube = (await this.seriesController.homeController?.launcherWidgetController?.isAppInstalledAsync('YouTube'))
        const hasRoblox = (await this.seriesController.homeController?.launcherWidgetController?.isAppInstalledAsync('Roblox'))
        return hasYouTube || hasRoblox
    }

    public bringBookToFront() {
        this.consoleDebug(`bringBookToFront()`)
        this.seriesController.removeBooksAfter(this.bookIndex, 'tapped-background')
    }

    @action
    public setCurrentPageIndex(id: number | null) {
        this.consoleDebug(`setCurrentPageIndex(${id})`)

        this.currentPageIndex = id

        if (this.currentPageIndex != null && this.book.pages != undefined) {
            const pages = this.book.pages ?? []
            const video = pages[this.currentPageIndex].video
            void this.recordPlayedVideo(video, this.currentPageIndex + 1)
        }
    }

    public async recordPlayedVideo(video: VideoModel, position: number) {
        try {
            this.consoleDebug(`recordPlayedVideo(${video})`)

            let book = await this.currentAccount.api.post(`browse.viewing_path`, {
                video_id: video.id,
                viewed: true,
                played: true,
            }) as BookModel

            this.currentAccount.personData.numberOfVideosStarted += 1
            this.currentAccount.dayData.numberOfVideosStarted += 1

            this.currentAccount.analytics.logEvent('video', 'started', video.title, {
                video_id: video.id,
                video_title: video.title,
                book_title: this.book.title,
                concept_name: this.book.concept?.primary_name.name,
                book_id: this.book.id,
                position_in_book: position,
                number_of_concepts_opened_today: this.currentAccount.dayData.numberOfConceptsOpened,
                number_of_concepts_opened_ever: this.currentAccount.personData.numberOfConceptsOpened,
                number_of_videos_started_today: this.currentAccount.dayData.numberOfVideosStarted,
                number_of_videos_started_ever: this.currentAccount.personData.numberOfVideosStarted,
            })
        } catch (error) {
            SentryService.captureError(error)
        }
    }

    @action
    public addVideoAfterIndex(video: VideoModel, afterIndex: number) {
        const page = {
            id: 'video' + video.id + Math.floor(Math.random() * 10000).toString(),
            title: video.title,
            video: video,
        } as PageModel

        this.book.pages?.splice(afterIndex + 1, 0, page)
        //When we are scrolling the videos we are adding next video to the pages[].
        //It is because if we click on an item in the suggested list it is going to add that item to the book list
        //So with this we are going to lost the next video in the pages[].
        //As a result we are adding next video to pages[] to keep the track of the next video in the list
        //And if we found that same video is there in the list, we are removing the existing one.
        const videoToDeleteIndex = this.book.pages?.findIndex(p => p.video.youtube_key === video.youtube_key)
        if (videoToDeleteIndex && videoToDeleteIndex > -1) {
            this.book.pages = [
                ...(this.book.pages?.slice(0, videoToDeleteIndex + 1) || []),
                ...(this.book.pages?.slice(videoToDeleteIndex + 2) || [])
            ]
        }
    }

    public async resetBookState() {
        try {
            await this.currentAccount.api.delete(`browse.reset_book_path(${this.book.id})`)
        } catch (error) {
            SentryService.captureError(error)
        }
    }

    // Private helper methods

    @action
    private updateState(state: BookControllerState) {
        this.consoleDebug(`updateState(${state})`)
        this.state = state
    }

    @computed
    get friendsForSharing() {
        return this.seriesController.homeController?.friendWidgetController?.friends
            .filter((f) => f.profile_type == ProfileType.LAVA)
    }

    public async shareVideo(personKey: string, videoUrl: string) {
        const message = `Check out this video I found on Lava! ${videoUrl}`
        await this.appController.message.sendMessage(personKey, message)
    }

    // Private instance utility methods

    private consoleDebug(method: string, force: boolean = false) {
        if (this.debug || force) console.log(`BookController: ${method}   state = ${this.state}`)
    }
}

export default BookController
