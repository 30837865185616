import React, {useContext, useEffect, useState} from 'react'
import {observer} from "mobx-react"
import {SafeAreaView, StyleSheet} from "react-native"
import VerticalGestureRecognizer from '../VerticalGestureRecognizer'
import CurrentAccountContext from '../../../vizz_account/lib/CurrentAccount'
import BookController from '../../controllers/library_widget/BookController'
import CardBackground from './book/CardBackground'
import TitlePage from './book/TitlePage'
import {reaction} from 'mobx'
import {isTablet} from '../../../../app/lib/Appearance'
import {Index} from './book/Index'
import AppControllerContext from '../../../../app/controllers/AppController'
import {IconButton} from 'react-native-paper'
import {RobloxGameDetailSidePanelState} from '../roblox_games_widget/RobloxGameDetailConstants'
import {BookProps} from './Book'
import PagesPortrait from './book/PagesPortrait'

const BookPortrait = observer((props: BookProps) => {
    const currentAccount = useContext(CurrentAccountContext)
    const appController = useContext(AppControllerContext)
    const [bookController] = useState<BookController>(() => new BookController(currentAccount, appController, props.seriesController, props.bookIndex))

    const [layout, setLayout] = useState({x: 0, y: 0, width: 0, height: 0})

    useEffect(() => {
        void bookController.initialize()

        const cleanupBookChange = reaction(() => props.seriesController.books[props.bookIndex].id, () => {
            void bookController.initialize()
        })

        return () => {
            cleanupBookChange()
            void bookController.uninitialize()
        }
    }, [])

    return (
        <VerticalGestureRecognizer
            style={{
                flex: 1,
                ...StyleSheet.absoluteFillObject,
                paddingTop: 50
            }}
            onLayout={(e) => setLayout(e.nativeEvent.layout)}
        >

            <TitlePage bookController={bookController} />

            <SafeAreaView style={{flex: 1, flexDirection: isTablet() ? 'row' : 'column-reverse'}}>
                <Index searchBarHeight={props.searchBarHeight} />
                <PagesPortrait
                    bookController={bookController}
                    searchBarHeight={props.searchBarHeight}
                    sidePanelState={RobloxGameDetailSidePanelState.COLLAPSED}
                    covered={props.covered} />
            </SafeAreaView>

            <CardBackground />
            <IconButton
                style={{position: 'absolute', top: 30, right: 20, margin: 0}}
                onPress={() => props.seriesController.removeBooksAfter(props.bookIndex - 1, 'swiped-down')}
                icon={'close'}
                size={30}
                color={'white'} />
        </VerticalGestureRecognizer>
    )
})

export default BookPortrait
