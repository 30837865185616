import React, {useContext, useEffect, useState} from 'react'
import {Dimensions,  View, Image, Text, FlatList, ListRenderItemInfo} from "react-native"
import {observer} from "mobx-react"
import {useNavigation} from '@react-navigation/native'
import {Card,Button} from 'react-native-paper'
import CurrentAccountContext from '../../../modules/vizz_account/lib/CurrentAccount'
import Vizz from '../../../modules/vizz_maker/views/Vizz'
import FixedAspectView from '../components/FixedAspectView'

const RecentVizzesScreen = observer(() => {

    const navigation = useNavigation<any>()
    const [recentVizzes, setRecentVizzes] = useState<object[]>([])
    const currentAccount = useContext(CurrentAccountContext)

    useEffect(() => {
        void refreshPage()
    }, [])

    const refreshPage = async() => {
        const results = await currentAccount.api.get("vizz_maker.all_vizzes_path", {
            "limit": 50,
            "only_done": true
        })

        setRecentVizzes(results)
    }

    const renderListItem = (info: ListRenderItemInfo<any>) => {
        const item: any = info.item

        return (
            <Card style={{margin: 16}}>
                <Card.Content>
                    <Card.Content style={{width: Dimensions.get('window').width * 0.5}}>
                        <FixedAspectView aspectRatio={3 / 4}>
                            <Vizz vizz={(item as any)["processed_json"]} style={{flex: 1}} />
                        </FixedAspectView>
                    </Card.Content>
                    <Card.Content>
                        <Text>
                            {(item as any)["title"]}
                        </Text>
                        <Button
                            onPress={() => navigation.navigate('EditVizz', {vizzKey: (item as any)["key"]})}>
                            Edit Vizz
                        </Button>
                    </Card.Content>
                    <Card.Content>
                        <Text>
                            {(item as any)["content_type"]}
                        </Text>
                    </Card.Content>
                </Card.Content>
            </Card>
        )
    }

    return (
        <FlatList
            data={recentVizzes}
            ListHeaderComponent={
                <View>
                    <Text style={{textAlign: "center", fontSize: 20}}>
                        Recent Vizzes
                    </Text>
                </View>
            }
            viewabilityConfig={{
                waitForInteraction: true,
                itemVisiblePercentThreshold: 75,
            }}
            renderItem={renderListItem}
            keyExtractor={item => (item as any)["id"]}
        />
    )
})

export default RecentVizzesScreen
