import React, {useContext, useEffect, useRef, useState} from 'react'
import {Animated, Easing, Image, ScrollView, StyleSheet, Text, View, ViewStyle} from "react-native"
import {observer} from "mobx-react"
import {SampleController, SampleState} from "../../controllers/steps/SampleController"
import CurrentAccountContext from "../../../vizz_account/lib/CurrentAccount"
import {OnboardingController, OnboardingState} from '../../controllers/OnboardingController'
import {reaction} from "mobx"
import {Widget} from '../../../start/views/home/Widget'
import {isLandscape, isPortrait} from '../../../../app/lib/Appearance'
import WidgetContainer from '../../../start/views/home/WidgetContainer'
import LegacyFriend from '../../../social/views/friend_widget/LegacyFriend'
import {ProfileStatus} from '../../../social/models/ProfileStatus'
import {PrimaryButton} from '../../../../app/views/components/PrimaryButton'
import {AsyncUtils} from '../../../../app/utils/AsyncUtils'
import AppControllerContext from '../../../../app/controllers/AppController'
import {VideoCallFake} from '../../../social/views/VideoCallFake'

type Props = {
    style?: ViewStyle
    onboardingController: OnboardingController
}

export const Sample = observer((props: Props) => {
    const currentAccount = useContext(CurrentAccountContext)
    const appController = useContext(AppControllerContext)
    const [controller] = useState<SampleController>(() => new SampleController(currentAccount, appController, props.onboardingController))
    const notificationY = useRef(new Animated.Value(0))


    useEffect(() => {
        const neededReaction = reaction(() => props.onboardingController.state, async(state) => {

            if (currentAccount.personData.sampleOnboardingNeeded) {
                if (state == OnboardingState.SAMPLE) await controller.initialize()
                if (state == OnboardingState.SAMPLE) await controller.startStep()
            }

        }, { fireImmediately: true })

        const animateReaction = reaction(() => controller.animateNotification, async(animate) => {

            if (animate) {
                Animated.timing(notificationY.current, {
                    toValue: 150,
                    duration: 500.0,
                    easing: Easing.out(Easing.quad),
                    useNativeDriver: true,
                }).start(async(result) => {
                    if (result.finished) {
                        await AsyncUtils.sleep(3000)
                        Animated.timing(notificationY.current, {
                            toValue: 0,
                            duration: 500.0,
                            easing: Easing.out(Easing.quad),
                            useNativeDriver: true,
                        }).start((result) => {
                            if (result.finished) controller.setAnimateNotification(false)
                        })
                    }
                })
            }
        })

        return () => {
            neededReaction()
            animateReaction()
            controller.uninitialize()
        }
    }, [])

    const renderFriends = () => {
        return (
            <ScrollView style={{flex: 1, marginTop: 12}} showsVerticalScrollIndicator={false}>
                {controller.sampleProfile &&
                <LegacyFriend
                    key={`lava-bot-0`}
                    profile={controller.sampleProfile}
                    isExpanded={controller.sampleProfileStatus == ProfileStatus.ONLINE}
                    onDemoCall={() => controller.startCameraPermission()}
                />}
            </ScrollView>
        )
    }

    return (
        <React.Fragment>
            {props.onboardingController.state == OnboardingState.SAMPLE && controller.state == SampleState.INTRO &&
            <View style={[{flex: 1, alignItems: 'center', justifyContent: 'center'}, props.style]}>
                {isLandscape() &&
                <WidgetContainer style={{flexDirection: 'row'}}>
                    <Widget name={'Friends'} type={'compact'} style={{maxWidth: 290, minWidth: 290, marginLeft: 10, marginRight: 20, marginVertical: 75}} loading={false}>
                        {renderFriends()}
                    </Widget>

                    <View style={{flexGrow: 1, alignItems: 'center', marginVertical: 75}}>
                        <Text style={[props.onboardingController.styles.messageText, {marginTop: isPortrait() ? 20 : 0}]}>{controller.headline}</Text>

                        {controller.showNotificationPermission &&
                        <View style={{width: '100%', flexDirection: 'row', justifyContent: 'center', marginBottom: 20}}>
                            <PrimaryButton
                                onPress={() => controller.skipNotificationPermission()}
                                title={'Skip'}
                                iconSide={'right'}
                                iconType={'MaterialCommunityIcons'}
                                icon={'close-thick'}
                            />
                            <PrimaryButton
                                style={{marginLeft: 8}}
                                onPress={() => controller.requestNotificationPermission()}
                                title={'Allow'}
                                iconSide={'right'}
                                iconType={'FontAwesome'}
                                icon={'thumbs-up'}
                            />
                        </View>}
                    </View>
                </WidgetContainer>}

                {isPortrait() &&
                <WidgetContainer style={{flexDirection: 'column', marginTop: 30, width: '90%'}}>
                    <View style={{height: 110, marginTop: 30, alignItems: 'center', justifyContent: 'center'}}>
                        <Text style={props.onboardingController.styles.messageText}>Get notified when friends come online.</Text>
                    </View>

                    {controller.showNotificationPermission &&
                    <View style={{width: '100%', flexDirection: 'row', justifyContent: 'center', marginBottom: 20}}>
                        <PrimaryButton
                            onPress={() => controller.skipNotificationPermission()}
                            title={'Skip'}
                            iconSide={'right'}
                            iconType={'MaterialCommunityIcons'}
                            icon={'close-thick'}
                        />
                        <PrimaryButton
                            style={{marginLeft: 8}}
                            onPress={() => controller.requestNotificationPermission()}
                            title={'Allow'}
                            iconSide={'right'}
                            iconType={'FontAwesome'}
                            icon={'thumbs-up'}
                        />
                    </View>}

                    <Widget name={'Friends'} type={'compact'} style={{flexGrow: 1}} loading={false}>
                        {renderFriends()}
                    </Widget>
                </WidgetContainer>}
            </View>}

            {props.onboardingController.state == OnboardingState.SAMPLE && controller.state == SampleState.ONLINE &&
            <View style={[{flex: 1, alignItems: 'center', justifyContent: 'center'}, props.style]}>
                <Animated.View
                    style={{
                        position: 'absolute',
                        zIndex: 200,
                        top: -150,
                        transform: [
                            {translateY: notificationY.current}
                        ]
                }}>
                    {isLandscape() && <Image style={{height: 70}} resizeMode={'contain'} source={require('../../assets/riley-tablet-notification.png')} />}
                    {isPortrait() && <Image style={{height: 85}} resizeMode={'contain'} source={require('../../assets/riley-phone-notification.png')} />}
                </Animated.View>

                {isLandscape() &&
                <WidgetContainer style={{flexDirection: 'row'}}>
                    <Widget name={'Friends'} type={'compact'} style={{maxWidth: 290, minWidth: 290, marginLeft: 10, marginRight: 20, marginVertical: 75}} loading={false}>
                        {renderFriends()}
                    </Widget>

                    <View style={{flexGrow: 1, alignItems: 'center', marginVertical: 75}}>
                        <Text style={props.onboardingController.styles.messageText}>{controller.headline}</Text>

                        {controller.showAudioPermission &&
                        <View style={{width: '100%', flexDirection: 'row', justifyContent: 'center', marginBottom: 20}}>
                            <PrimaryButton
                                onPress={() => controller.skipAudioPermission()}
                                title={'Skip'}
                                iconSide={'right'}
                                iconType={'MaterialCommunityIcons'}
                                icon={'close-thick'}
                            />
                            <PrimaryButton
                                style={{marginLeft: 8}}
                                onPress={() => controller.requestAudioPermission()}
                                title={'OK'}
                                iconSide={'right'}
                                iconType={'FontAwesome'}
                                icon={'thumbs-up'}
                            />
                        </View>}

                        {controller.showCameraPermission &&
                        <View style={{width: '100%', flexDirection: 'row', justifyContent: 'center', marginBottom: 20}}>
                            <PrimaryButton
                                onPress={() => controller.skipCameraPermission()}
                                title={'Skip'}
                                iconSide={'right'}
                                iconType={'MaterialCommunityIcons'}
                                icon={'close-thick'}
                            />
                            <PrimaryButton
                                style={{marginLeft: 8}}
                                onPress={() => controller.requestCameraPermission()}
                                title={'OK'}
                                iconSide={'right'}
                                iconType={'FontAwesome'}
                                icon={'thumbs-up'}
                            />
                        </View>}
                    </View>
                </WidgetContainer>}

                {isPortrait() &&
                <WidgetContainer style={{flexDirection: 'column', marginTop: 30, width: '90%'}}>
                    <View style={{height: 110, marginTop: 30, alignItems: 'center', justifyContent: 'center'}}>
                        <Text style={props.onboardingController.styles.messageText}>{controller.headline}</Text>
                    </View>

                    {controller.showAudioPermission &&
                    <View style={{width: '100%', flexDirection: 'row', justifyContent: 'center', marginBottom: 20}}>
                        <PrimaryButton
                            onPress={() => controller.skipAudioPermission()}
                            title={'Skip'}
                            iconSide={'right'}
                            iconType={'MaterialCommunityIcons'}
                            icon={'close-thick'}
                        />
                        <PrimaryButton
                            style={{marginLeft: 8}}
                            onPress={() => controller.requestAudioPermission()}
                            title={'OK'}
                            iconSide={'right'}
                            iconType={'FontAwesome'}
                            icon={'thumbs-up'}
                        />
                    </View>}

                    {controller.showCameraPermission &&
                    <View style={{width: '100%', flexDirection: 'row', justifyContent: 'center', marginBottom: 20}}>
                        <PrimaryButton
                            onPress={() => controller.skipCameraPermission()}
                            title={'Skip'}
                            iconSide={'right'}
                            iconType={'MaterialCommunityIcons'}
                            icon={'close-thick'}
                        />
                        <PrimaryButton
                            style={{marginLeft: 8}}
                            onPress={() => controller.requestCameraPermission()}
                            title={'OK'}
                            iconSide={'right'}
                            iconType={'FontAwesome'}
                            icon={'thumbs-up'}
                        />
                    </View>}

                    <Widget name={'Friends'} type={'compact'} style={{flexGrow: 1}} loading={false}>
                        {renderFriends()}
                    </Widget>
                </WidgetContainer>}
            </View>}

            {props.onboardingController.state == OnboardingState.SAMPLE && controller.state == SampleState.CALLING &&
            <View style={{flex: 1, flexDirection: 'column'}}>

                <View style={{flexGrow: 1}}>
                    <VideoCallFake flexDirection={isLandscape() ? 'row' : 'column'} cameraEnabled={controller.cameraEnabled} />
                </View>

                <View style={{width: '100%', height: 120, alignItems: 'center', justifyContent: 'center'}}>
                    <View style={{width: 200, height: 100}}>
                        <PrimaryButton
                            style={{margin: 8}}
                            onPress={() => {}}
                            iconType={'MaterialCommunityIcons'}
                            icon={'phone-hangup'}
                            iconSide={'right'}
                            title={'End'}
                        />
                    </View>
                </View>

            </View>}
        </React.Fragment>
    )
})

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
})
