import {observer} from "mobx-react"
import React, {useContext, useEffect, useRef, useState} from "react"
import {
    Dimensions,
    FlatList,
    LayoutRectangle,
    ListRenderItemInfo,
    NativeScrollEvent,
    NativeSyntheticEvent,
    View
} from "react-native"
import {isTablet, Style} from "../../../../../app/lib/Appearance"
import {AsyncUtils} from "../../../../../app/utils/AsyncUtils"
import CurrentAccountContext from "../../../../vizz_account/lib/CurrentAccount"
import BookController from "../../../controllers/library_widget/BookController"
import Page from "./pages/Page"
import PageModel from "../../../models/PageModel"
import {reaction} from "mobx"
import GameBookController from "../../../controllers/roblox_games_widget/GameBookController"
import {RobloxGameDetailSidePanelState} from "../../roblox_games_widget/RobloxGameDetailConstants"
import {PageController} from "../../../controllers/library_widget/book/pages/PageController"

export type PagesProps = {
    bookController: BookController | GameBookController
    searchBarHeight: number
    covered: boolean
    sidePanelState: RobloxGameDetailSidePanelState
}

const PagesLandscape = observer((props: PagesProps) => {
    const currentAccount = useContext(CurrentAccountContext)
    const flatList = useRef<FlatList>(null)
    const [flatListLayoutParams, setFlatListLayoutParams] = useState<LayoutRectangle>({x: 0, y: 0, width: 0, height: 0})
    const [activeIndex, setActiveIndex] = useState<number>(props.bookController.currentPageIndex ?? 0)
    const scrollPause = useRef<any>(0)
    const layoutHeight = useRef<any>(0)

    const thisBook = () => props.bookController.book
    const rightPageMargin = isTablet() ? 50 : 8
    const width = isTablet() ? '100%' : '100%'

    useEffect(() => {
        const currentPageIndexReaction = reaction(() => props.bookController.currentPageIndex, (index) => {
            if (index) {
                setActiveIndex(index) // TODO: Eliminate this extra state. Ideally we let the reaction drive everything
                void scrollToPageIndex(index)
            }
        }, { fireImmediately: true })

        return () => {
            currentPageIndexReaction()
        }
    }, [])

    const heightOfSuggestedVideos = () => {
        return Math.round(Dimensions.get('screen').height * (isTablet() ? Style.Page.suggestedVideosHighTabletPerct : Style.Page.suggestedVideosHighMobilePerct))
    }

    const pageWidth = () => {
        const defaultPageWidth = Math.round(isTablet() ? Dimensions.get('screen').width * (1 - Style.Book.minimumNextPageVisibleTabletPerct) : Dimensions.get('screen').width * (1 - Style.Book.minimumNextPageVisibleMobilePerct))
        const idealVideoHeight = Math.round(defaultPageWidth * (9 / 16))
        const maxVideoHeight = (layoutHeight.current - heightOfSuggestedVideos())

        return Math.round(Math.min(maxVideoHeight, idealVideoHeight) * (16 / 9))
    }

    const pageOffsets = () => {
        // Originally we were using this with: snapToInterval={pageWidth()}, but switching to snapToOffsets={} due to
        // this issue: https://github.com/facebook/react-native/issues/21441
        return [...Array((thisBook().pages ?? []).length)].map((x, i) => i * pageWidth())
    }

    const itemLayout = (data: any, index: any) => {
        return {length: pageWidth(), offset: pageWidth() * index, index}
    }

    const indexForOffset = (xOffset: number) => {
        return Math.round(xOffset / pageWidth())
    }

    const pageScrolled = (event: NativeSyntheticEvent<NativeScrollEvent>) => {
        const xOffset = event.nativeEvent.contentOffset.x
        const index = indexForOffset(xOffset)

        if (scrollPause.current != 0) {
            clearTimeout(scrollPause.current)
            scrollPause.current = 0
        }
        scrollPause.current = setTimeout((index: number) => {
            // other methods watch to know if the page was just scrolled by checking this pointer so we want to be sure we don't keep this pointer active
            scrollPause.current = 0

            const newPage = (thisBook().pages ?? [])[index]

            if (index != props.bookController.currentPageIndex) {
                let pageVideoIndex = thisBook().pages?.findIndex(p => p.video.youtube_key === newPage.video.youtube_key)
                if(pageVideoIndex && pageVideoIndex < 0){
                    const controller = new PageController(currentAccount, props.bookController, newPage.video, index)
                    controller.addVideo(newPage.video, 'related-videos')
                    controller.relatedVideoList.suggestVideosAfter(newPage.video)
                }

                let direction = (!props.bookController.currentPageIndex || index > props.bookController.currentPageIndex) ? 'advanced' : 'reversed'

                currentAccount.analytics.logEvent('feed', direction, newPage?.title, {
                    page_id: newPage?.id,
                    page_title: newPage?.title,
                })

                props.bookController.setCurrentPageIndex(index)
                //if (newVizz?.id) currentAccount.updatePlayingVizz(newVizz.id)
                setActiveIndex(index)
            }
        }, 100, index)
    }

    const scrollToFirstPage = () => {
        flatList.current?.scrollToIndex({
            index: 0,
            animated: true
        })
    }

    const scrollToPageIndex = async (index: number) => {
        // If we try to scroll to a page immediately after it's added, sometimes the scroll fails.
        // The only reliable method for detecting if a page has been added is to notice if the scroll
        // has started to work. Try scrolling up to 5 times until we notice the scroll has begun.
        for (let i = 0; i < 10; i++) {
            if (scrollPause.current != 0) break // this means the scrolling has begun
            if (pageWidth() > 0) {
                flatList.current?.scrollToOffset({
                    offset: index * pageWidth(),
                    animated: true
                })
            }
            if (scrollPause.current != 0) break
            await AsyncUtils.sleep(200)
        }
    }

    const renderItem = (item: ListRenderItemInfo<PageModel>) => (
        <Page
            bookController={props.bookController}
            pageItem={item}
            width={pageWidth() > 0 ? pageWidth() : Dimensions.get('screen').width}
            rightMargin={rightPageMargin}
            focused={activeIndex == item.index && !props.covered}
            nearlyFocused={(activeIndex >= item.index - 3 && activeIndex <= item.index + 3) && activeIndex != item.index && !props.covered}
            autoPlayOnFocus={true}
            scrollToThisPageCallback={() => scrollToPageIndex(item.index)}
            scrollToNextPageCallback={() => scrollToPageIndex(item.index + 1)}
            sidePanelState={props.sidePanelState}
        />
    )

    return (
        <View style={isTablet() ?
            {
                flexGrow: 1,
            }
            :
            {
                width: '100%',
                height: Math.round(Dimensions.get('screen').width * (9 / 16)) + heightOfSuggestedVideos(),
            }
        }
            onLayout={(e) => { if (e.nativeEvent.layout.height > 0) layoutHeight.current = e.nativeEvent.layout.height }}>

            {(thisBook().pages ?? []).length > 0 &&
                <View style={{
                    flex: 1,
                    flexDirection: 'row',
                    width: width,
                    marginTop: 10,
                }}>
                    <FlatList
                        style={{flex: 1, width: width}}
                        ref={flatList}
                        getItemLayout={itemLayout}
                        horizontal={true}
                        showsHorizontalScrollIndicator={false}
                        pagingEnabled={false}
                        scrollEnabled={true}
                        onScroll={pageScrolled}
                        onLayout={(e) => setFlatListLayoutParams(e.nativeEvent.layout)}
                        viewabilityConfig={{
                            waitForInteraction: false,
                            viewAreaCoveragePercentThreshold: 60,
                        }}
                        decelerationRate={0}
                        snapToOffsets={pageOffsets()}
                        snapToAlignment={'start'}
                        keyExtractor={(page) => page.id}
                        data={thisBook().pages}
                        renderItem={renderItem}
                    />
                </View>
            }
        </View>
    )
})

export default PagesLandscape