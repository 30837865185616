import {action, makeObservable, observable} from "mobx"
import {SpreadDetailController} from "./SpreadDetailController"
import {GameCodeModel} from "../../models/game/GameCodeModel"
import {SpeechUtils} from "../../../../app/utils/AVUtils"
import {CurrentAccount} from "../../../vizz_account/lib/CurrentAccount"
import SentryService from "../../../../app/services/SentryService"
import PageModel from "../../models/PageModel"
import {GameBookModel} from "../../models/GameBookModel"

export enum RobloxGameContentState {
    LOADING,
    LOADED
}

export class CodeViewModel {
    code: GameCodeModel

    @observable isRevealed: boolean = false
    @action setRevealed(revealed: boolean) {this.isRevealed = revealed}

    constructor(code: GameCodeModel) {
        this.code = code
        makeObservable(this)
    }
}

export class SecretViewModel {
    secret: PageModel
    parentBook: GameBookModel
    verified: boolean
    isFirstInSection: boolean

    constructor(secret: PageModel, parentBook: GameBookModel, verified: boolean, isfirstInSection: boolean) {
        this.secret = secret
        this.parentBook = parentBook
        this.verified = verified ?? false
        this.isFirstInSection = isfirstInSection
    }
}

export class SpreadDetailContentController {
    private debug: boolean = false  // don't set this to true in production

    private currentAccount: CurrentAccount
    private spreadDetailController: SpreadDetailController
    private speech: SpeechUtils
    @observable state: RobloxGameContentState = RobloxGameContentState.LOADING;         @action private setState(state: RobloxGameContentState) { this.state = state }

    constructor(currentAccount: CurrentAccount, spreadDetailController: SpreadDetailController) {
        this.consoleDebug(`new()`)

        this.currentAccount = currentAccount
        this.spreadDetailController = spreadDetailController
        this.speech = new SpeechUtils(currentAccount, true)

        makeObservable(this)
    }

    public async initialize() {
        try {
            this.consoleDebug(`initialize()`)
            this.setState(RobloxGameContentState.LOADED)
        } catch (error) {
            SentryService.captureError(error)
        }
    }

    public uninitialize() {
        this.consoleDebug(`uninitialize()`)
        this.speech.unloadPreloadedPhrase('codes-add-title')
    }


    // Private instance utility methods

    private consoleDebug(method: string, force: boolean = false) {
        if (this.debug || force) console.log(`${this.constructor.name}: ${method}  state = ${this.state}`)
    }
}
