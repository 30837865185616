import React from 'react'
import {observer} from "mobx-react"
import {AssetType, VizzModel, VizzMedia, VizzSlideModel} from "../../../modules/vizz_maker/models/VizzModel"
import {Animated, Image, StyleSheet, View} from "react-native"
import FixedAspectView from "./FixedAspectView"
import {Card, Divider, Headline, Paragraph, Title} from "react-native-paper"
import {Video} from "expo-av"
import {ResizeMode} from "expo-av/build/Video.types"

type Props = {
    vizz: VizzModel
}

const VizzArticleView = observer((props: Props) => {

    const slideView = (slide: VizzSlideModel) => {
        return (
            <Card style={{marginBottom: 16}} key={slide.id}>
                <Card.Content>
                    {slide.media.map((media) => {
                        return mediaView(media)
                    })}
                </Card.Content>
            </Card>
        )
    }

    const mediaView = (media: VizzMedia) => {
        return (
            <View style={{marginVertical: 8}} key={media.assetUrl}>
                    {assetView(media)}

                    <Paragraph style={{marginTop: 8, marginBottom: 8}}>
                        {media.text}
                    </Paragraph>
            </View>
        )
    }

    const assetView = (media: VizzMedia) => {
        switch (media.assetType) {
            case AssetType.Image: {
                return (
                    <FixedAspectView aspectRatio={3/4}>
                        <Image
                            style={{flex: 1}}
                            resizeMode={'cover'}
                            source={{uri: media.assetUrl}}/>
                    </FixedAspectView>
                )
            }
            case AssetType.Video: {
                return (
                    <FixedAspectView aspectRatio={3/4}>
                        <Video
                            style={{flex: 1}}
                            resizeMode={ResizeMode.COVER}
                            useNativeControls={true}
                            source={{uri: media.assetUrl}}/>
                    </FixedAspectView>
                )
            }
        }
    }


    return (
        <View style={styles.container}>
            <FixedAspectView aspectRatio={3/4}>
                <Image
                    style={{flex: 1}}
                    resizeMode={'cover'}
                    source={{uri: props.vizz.coverImageUrl}}/>
            </FixedAspectView>

            <Headline style={{marginTop: 16, marginBottom: 16}}>{props.vizz.title}</Headline>

            {props.vizz.slides.map(slideView)}
        </View>
    )
})

const styles = StyleSheet.create({
    container: {
        margin: 16
    }
})

export default VizzArticleView;
