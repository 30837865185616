import {action, makeObservable, observable} from "mobx"
import {ProfileModel} from "../../../social/models/ProfileModel"
import {ProfileStatus} from "../../../social/models/ProfileStatus"
import {RobloxFriendModel} from "../../../social/models/RobloxFriendModel"

export default class FriendModel {
    @observable isSelected: boolean = false
    public order: number = 0
    public robloxFriend: RobloxFriendModel
    public profileModel: ProfileModel

    constructor(robloxFriend: RobloxFriendModel) {
        this.robloxFriend = robloxFriend
        this.profileModel = {
            id: robloxFriend.id,
            person_id: 0,
            person_key: robloxFriend.person_key,
            pretty_username: robloxFriend.display_name,
            username: robloxFriend.display_name,
            nickname: robloxFriend.display_name,
            first_name: robloxFriend.display_name,
            last_changed: 'Offline',
            status: ProfileStatus.OFFLINE,
            avatar_image_url: robloxFriend.avatar_headshot_url ?? '',
            created_at: '123',
            updated_at: '123',
        }
        makeObservable(this)
    }

    @action
    setSelected(selected: boolean, order: number) {
        this.isSelected = selected
        this.order = order
    }
}
