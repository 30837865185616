import React, {useContext, useEffect, useState} from 'react'
import {StyleSheet, View, ViewStyle} from "react-native"
import {observer} from "mobx-react"
import {IDFAController, IDFAState} from "../../controllers/steps/IDFAController"
import CurrentAccountContext from "../../../../modules/vizz_account/lib/CurrentAccount"
import {OnboardingController, OnboardingState} from '../../controllers/OnboardingController'
import {reaction} from "mobx"

type Props = {
    style?: ViewStyle
    onboardingController: OnboardingController
}

export const IDFA = observer((props: Props) => {
    const currentAccount = useContext(CurrentAccountContext)
    const [controller] = useState<IDFAController>(() => new IDFAController(currentAccount, props.onboardingController))

    useEffect(() => {
        const neededReaction = reaction(() => props.onboardingController.state, async(state) => {

            if (currentAccount.personData.idfaOnboardingNeeded) {
                await controller.initialize()
                if (state == OnboardingState.IDFA) await controller.startStep()
            }

        }, { fireImmediately: true })

        return () => {
            neededReaction()
            controller.uninitialize()
        }
    }, [])

    return (
        props.onboardingController.state == OnboardingState.IDFA && controller.state == IDFAState.LOADED ?
        <View style={[styles.container, props.style]}>
            {/* Screen should just be black, only UI is the iOS permission dialog which the controller triggers */}
        </View> : null
    )
})

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
})
