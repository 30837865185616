export interface MessageNotification {
    id: string
    personKeys: string[]
    displayUsernames: string[]
    message: string
    timestamp: Date
    channel?: any
}

export const isMessageNotification = (object: any): object is MessageNotification => {
    return 'message' in object
}
