import {action, makeObservable, observable} from "mobx"
import {CurrentAccount} from "../../../modules/vizz_account/lib/CurrentAccount"
import {AppController} from "../../../app/controllers/AppController"
import HomeController from "../../start/controllers/HomeController"
import {ProfileModel} from "../models/ProfileModel"
import StoryModel from "../models/StoryModel"
import {OnboardingController} from "../../onboarding/controllers/OnboardingController"
import SentryService from "../../../app/services/SentryService"
import {create, persist} from "mobx-persist"
import AsyncStorage from "@react-native-async-storage/async-storage"

export enum FeedWidgetState {
    LOADING,
    LOADED
}

const hydrate = create({
    storage: AsyncStorage,
    jsonify: true,
})

export class FeedWidgetController {
    private debug: boolean = false  // don't set this to true in production

    private currentAccount: CurrentAccount
    public homeController: HomeController
    public appController: AppController

    @observable state: FeedWidgetState = FeedWidgetState.LOADING;           @action private setState(state: FeedWidgetState) { this.state = state }
    @observable @persist('list') stories: StoryModel[] = [];                                 @action private setStories(stories: StoryModel[]) { this.stories = stories }
    @observable @persist totalStoriesCount: number = 0;                              @action private setTotalStoriesCount(count: number) { this.totalStoriesCount = count }
    private isRefreshing: boolean = false


    constructor(currentAccount: CurrentAccount, homeController: HomeController, appController: AppController) {
        this.consoleDebug(`new()`)

        this.currentAccount = currentAccount
        this.homeController = homeController
        this.homeController.feedWidgetController = this
        this.appController = appController

        makeObservable(this)
    }

    public async initialize() {
        this.consoleDebug(`initialize()`)

        await hydrate('feed', this)

        await this.refreshFeed()

        this.setState(FeedWidgetState.LOADED)
    }

    public uninitialize() {
        this.consoleDebug(`uninitialize()`)
    }


    // Public methods

    public async refreshFeed() {
        if (this.isRefreshing) return

        this.isRefreshing = true

        this.setState(FeedWidgetState.LOADING)

        let stories:StoryModel[] = []
        let totalStoriesCount:number = 0
        try {
            const response = await this.currentAccount.api.get('social.feed_stories_path') as any
            stories = response.stories as StoryModel[]
            stories = stories.filter((s) => s.source && s.source.story_type)
            totalStoriesCount = response.total_stories_count as number

            stories = this.removeConsecutiveDuplicates(stories)
            this.setStories(stories)
            this.setTotalStoriesCount(totalStoriesCount)

            this.setState(FeedWidgetState.LOADED)
        } catch(e) {
            this.consoleDebug(`Error while loading all inbox stories from server: ${e}`)
            this.currentAccount.analytics.logEvent('feed-widget', 'refresh-failed', undefined, {error: e})
            SentryService.captureError(e)
        } finally {
            this.isRefreshing = false
        }
    }

    private removeConsecutiveDuplicates(stories: StoryModel[]): StoryModel[] {
        return stories.filter((story, index, self) => {
            if (index === 0) {
                return true // Always include the first story
            }

            const prevStory = self[index - 1]
            return !(story.title === prevStory.title && story.source.id === prevStory.source.id)
        })
    }

    // Private helper methods

    public onProfileTapped(profile: ProfileModel) {
        void this.homeController.friendWidgetController?.openChannelDetails([profile.person_key])
    }

    public onNewSchoolPersonTapped() {
        this.currentAccount.analytics.logEvent('feed-story', 'tapped', `Person joined school`,
        {
            story_type: 'new_school_person'
        })
        this.homeController.friendWidgetController?.showAddFriend()
    }

    public onboardingRobuxStep(countPhrase?: string) {
        this.currentAccount.analytics.logEvent('feed-story', 'tapped', `${countPhrase} more robux`,
        {
            story_type: `${countPhrase}-more-robux`
        })
        OnboardingController.setStep("referral", this.currentAccount)
        this.homeController.goToOnboarding()
    }

    // Private instance utility methods

    private consoleDebug(method: string, force: boolean = false) {
        if (this.debug || force) console.log(`${this.constructor.name}: ${method}  state = ${this.state}`)
    }
}
