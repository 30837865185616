import {action, computed, makeObservable, observable} from "mobx"
import {CurrentAccount} from "../../../vizz_account/lib/CurrentAccount"
import InterestModel from "../../models/steps/InterestModel"
import {OnboardingController} from "../OnboardingController"
import {InterestsGridController} from "../steps/interests/InterestsGridController"
import SentryService from "../../../../app/services/SentryService"

export enum InterestsState {
    LOADING,
    LOADED
}

export class InterestsController {
    private debug: boolean = false  // don't set this to true in production

    private currentAccount: CurrentAccount
    public onboardingController: OnboardingController
    private introRepeatTimeout: any

    @observable state: InterestsState = InterestsState.LOADING;         @action private setState(state: InterestsState) { this.state = state }
    @observable interestsGridController?: InterestsGridController;      @action public setInterestsGridController(controller: InterestsGridController) { this.interestsGridController = controller }
    @observable freezeSelections:boolean = false;                       @action public setFreezeSelections(freeze: boolean) { this.freezeSelections = freeze }

    constructor(currentAccount: CurrentAccount, onboardingController: OnboardingController) {
        this.consoleDebug(`new()`)

        this.currentAccount = currentAccount
        this.onboardingController = onboardingController

        makeObservable(this)
    }

    public async initialize() {
        this.consoleDebug(`initialize()`)
        if (this.state != InterestsState.LOADING) return

        this.onboardingController.speech.preloadPhrase('interests', `To get started, tap up to five things that you're interested in.`)
        this.onboardingController.speech.preloadPhrase('interests-repeat', `Tap up to five things that you're interested in.`)
        this.onboardingController.speech.preloadPhrase('interests-done', `Nice choices! Now, Lava also helps you call and text friends. Let me show you.`)

        this.setState(InterestsState.LOADED)
    }

    public uninitialize() {
        this.consoleDebug(`uninitialize()`)

        if (this.introRepeatTimeout) { clearTimeout(this.introRepeatTimeout); this.introRepeatTimeout = undefined }

        this.onboardingController.speech.unloadPreloadedPhrase('interests')
        this.onboardingController.speech.unloadPreloadedPhrase('interests-repeat')
        this.onboardingController.speech.unloadPreloadedPhrase('interests-done')
    }


    // Public methods

    @computed
    get interests() {
        if (!this.interestsGridController) return undefined
        return this.interestsGridController.interests as InterestModel[]
    }

    @computed
    get selectedInterests() {
        if (!this.interestsGridController) return [] as InterestModel[]
        return this.interestsGridController.selectedInterests as InterestModel[]
    }

    public onToggleSelectedInterest(interest: InterestModel) {
        this.consoleDebug(`onToggleSelectedInterest() - ${interest.isSelected} and ${interest.book.title}`, true)

        if (this.introRepeatTimeout) { clearTimeout(this.introRepeatTimeout); this.introRepeatTimeout = undefined }

        if (interest.isSelected) void this.onboardingController.speech.speak(interest.book.title)
    }

    public async startStep() {
        this.consoleDebug(`startStep()`)

        this.currentAccount.analytics.logEvent('interests-onboarding','started')
        await this.onboardingController.speech.speakPreloadedPhrase('interests', () => {
            this.introRepeatTimeout = setTimeout(async() => {
                await this.onboardingController.speech.speakPreloadedPhrase('interests-repeat')
            }, 6000)
        })
    }

    public prevStep() {
        this.consoleDebug(`prevStep()`)
        OnboardingController.setStep('pitch', this.currentAccount, this.onboardingController)
    }

    public nextStep() {
        try {
            this.consoleDebug(`nextStep()`)

            this.setFreezeSelections(true) // do not allow toggling selections any further

            this.onboardingController.speech.pause()
            if (this.introRepeatTimeout) { clearTimeout(this.introRepeatTimeout); this.introRepeatTimeout = undefined }

            void this.currentAccount.api.patch('vizz_account.update_person_properties', {
                properties: {picked_interests: this.selectedInterests.map((i) => i.book.id)}
            })

            this.currentAccount.analytics.logEvent('interests-onboarding','finished', this.selectedInterests.map((i) => i.book.title).join(", "), {
                concept_ids: this.selectedInterests.map((i) => i.book.id)
            })

            void this.onboardingController.speech.speakPreloadedPhrase('interests-done', () => {
                this.onboardingController.nextStep()
            })
        } catch (error) {
            SentryService.captureError(error)
        }
    }


    // Private helper methods


    // Private instance utility methods

    private consoleDebug(method: string, force: boolean = false) {
        if (this.debug || force) console.log(`${this.constructor.name}: ${method}  state = ${this.state}`)
    }
}