import {KeyboardAvoidingView, Platform, ScrollView, StyleSheet, Text, View, ViewStyle} from "react-native"
import {OnboardingController, OnboardingState} from "../../controllers/OnboardingController"
import {observer} from "mobx-react"
import React, {useContext, useEffect, useLayoutEffect, useState} from "react"
import CurrentAccountContext from "../../../vizz_account/lib/CurrentAccount"
import AppControllerContext from "../../../../app/controllers/AppController"
import {reaction} from "mobx"
import {SchoolController, SchoolState} from "../../controllers/steps/SchoolController"
import {ActivityIndicator, Button, TextInput, TouchableRipple} from "react-native-paper"
import {Typography} from "../../../../app/lib/Typography"
import {PrimaryButton} from "../../../../app/views/components/PrimaryButton"
import {SearchForFriends} from "../../../../social/views/SearchForFriends"
import {Color, isPortrait, isTablet} from "../../../../app/lib/Appearance"
import {AnimationUtils} from "../../../../app/utils/AnimationUtils"

type Props = {
    style?: ViewStyle
    onboardingController: OnboardingController
}

export const School = observer((props: Props) => {
    const currentAccount = useContext(CurrentAccountContext)
    const appController = useContext(AppControllerContext)
    const [controller] = useState<SchoolController>(() => new SchoolController(currentAccount, appController, props.onboardingController))

    useEffect(() => {
        const neededReaction = reaction(() => props.onboardingController.state, async (state) => {

            if (currentAccount.personData.schoolOnboardingNeeded) {
                if (state == OnboardingState.SCHOOL) await controller.initialize()
                if (state == OnboardingState.SCHOOL) await controller.startStep()
            }

        }, {fireImmediately: true})

        return () => {
            neededReaction()
            controller.uninitialize()
        }
    }, [])

    useLayoutEffect(() => {
        if (props.onboardingController.state == OnboardingState.SCHOOL) {
            AnimationUtils.configureNext()
        }
    }, [controller.results])

    return (
        <React.Fragment>
            {props.onboardingController.state == OnboardingState.SCHOOL && controller.state == SchoolState.LOADING &&
            <View style={styles.container}>
                <ActivityIndicator color={Color.primary} size={40} />
            </View>}

            {props.onboardingController.state == OnboardingState.SCHOOL && controller.state == SchoolState.SEARCH &&
            <KeyboardAvoidingView behavior={Platform.OS === 'ios' ? 'padding' : 'height'} style={{
                flex: 1,
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                {controller.isSearching == undefined &&
                <Text style={props.onboardingController.styles.messageText}>Your school{controller.mentionedHomeschool && '   (or type homeschool)'}</Text>}

                <View style={{flexDirection: 'column', alignItems: 'center'}}>

                    <View style={{width: isTablet() ? '40%' : '90%', marginTop: isPortrait() ? 65 : 0, flexDirection: 'row'}}>
                        <TextInput
                            style={{flexGrow: 1, marginRight: 20}}
                            mode={'flat'}
                            autoCapitalize={'words'}
                            keyboardType={'default'}
                            label="School"
                            value={controller.schoolInput}
                            autoFocus={true}
                            autoCorrect={false}
                            onChangeText={text => {
                                controller.onSearchTextChanged(text)
                            }}
                        />
                        {controller.isSearching && <ActivityIndicator color={Color.primary} size={40}/>}
                    </View>

                    {controller.isSearching != undefined &&
                    <View style={{flex: 1, minWidth: 375, maxWidth: 375}}>
                        <ScrollView keyboardShouldPersistTaps={'handled'}>
                            {controller.results.map((result) => {
                                return (
                                    <TouchableRipple key={result.google_place_id}
                                                     onPress={() => controller.selectSchool(result)}>
                                        <View style={{
                                            margin: 8,
                                            padding: 16,
                                            borderRadius: 6,
                                            backgroundColor: 'rgba(255, 255, 255, 0.05)'
                                        }}>
                                            <Text style={Typography.headline2}>{result.name}</Text>
                                            <Text style={Typography.label}>{result.pretty_location}</Text>
                                        </View>
                                    </TouchableRipple>
                                )
                            })}
                        </ScrollView>
                        <View style={{marginBottom: 30}}>
                            <TouchableRipple onPress={() => controller.schoolNotFound()}>
                                <View style={{
                                    margin: 8,
                                    paddingHorizontal: 16,
                                    paddingVertical: 8,
                                    borderRadius: 6,
                                    backgroundColor: 'rgba(255, 255, 255, 0.05)',
                                    borderColor: '#FFFFFF50',
                                    borderWidth: 1,
                                    height: 80,
                                    alignItems: 'flex-end',
                                    justifyContent: 'center',
                                }}>
                                    <Text style={[Typography.headline1, {textAlign: 'right'}]}>
                                        {controller.results.length > 0 && `I don't see my school,${"\n"}Or, `}
                                        I'm homeschooled {' >'}
                                    </Text>
                                </View>
                            </TouchableRipple>
                        </View>
                    </View>}
                </View>

            </KeyboardAvoidingView>}

            {props.onboardingController.state == OnboardingState.SCHOOL && controller.state == SchoolState.CONFIRM && controller.selectedSchool &&
            <View style={styles.container}>
                <Text style={[props.onboardingController.styles.messageText]}>Is this the right school?</Text>
                <Text style={[props.onboardingController.styles.messageText, {fontWeight: '800'}]}>{controller.selectedSchool.name},{'\n'}{controller.selectedSchool.pretty_location}</Text>

                <PrimaryButton
                    onPress={() => {
                        void controller.confirmSchool()
                    }}
                    style={{marginTop: 10, marginBottom: 20}}
                    iconType={'FontAwesome'}
                    icon={'thumbs-up'}
                    iconSide={'left'}
                    title={'Yes'}
                />
                <Button
                    style={{marginVertical: 10}}
                    disabled={false}
                    color={'white'}
                    onPress={() => controller.setState(SchoolState.SEARCH)}>
                    {"< Back"}
                </Button>
            </View>}

            {props.onboardingController.state == OnboardingState.SCHOOL && controller.state == SchoolState.ADD_FRIENDS &&
            <View style={styles.container}>
                <Text style={[props.onboardingController.styles.messageText, {marginTop: isPortrait() ? 40 : 0}]}>Find Friends</Text>
                <SearchForFriends style={{borderColor: 'rgba(255, 255, 255, 0.2)', borderWidth: 1, maxWidth: 500, padding: isTablet() ? 30 : 8}} showDoneButton={true} onFinish={(invited) => {controller.doneAddingFriends()}} />
            </View>}

            {props.onboardingController.state == OnboardingState.SCHOOL && controller.state == SchoolState.SUBMIT &&
            <View style={styles.container}>
            </View>}

        </React.Fragment>
    )
})

const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
    }
})
