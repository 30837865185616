import {CurrentAccount} from "../../modules/vizz_account/lib/CurrentAccount"

export type NotificationOpenedListener = (payload: any) => void

export class NotificationService {

    constructor() {
    }

    public async initialize(currentAccount: CurrentAccount) {
    }

    public uninitialize() {
    }

    // LISTENERS

    public async checkInitialNotification() {
    }

    public addNotificationOpenedListener(id: string, listener: NotificationOpenedListener) {
    }

    public removeNotificationOpenedListener(id: string) {
    }

    // PERMISSIONS

    public async alreadyEnabled() {
        return true
    }

    public async canAsk() {
        return false
    }

    public async enableNotifications() {
    }

    public async refreshPushTokens() {
    }

    // UTIL

    public static async sendLocalNotificationNow(title: string, subtitle: string) {
    }

    public async clearBadge() {
    }
}
