import {CurrentAccount} from "../../../modules/vizz_account/lib/CurrentAccount"
import {Platform} from "react-native"
import * as Device from "expo-device"
import * as Updates from 'expo-updates'
import DateUtils from "../../utils/DateUtils"
import {UserCohorts} from "./UserCohorts"
import * as Application from 'expo-application'
import * as BackgroundFetch from 'expo-background-fetch'
import {ManifestUtils} from "../../utils/ManifestUtils"
import SentryService from "../../services/SentryService"
import * as FileSystem from "expo-file-system"
import DeviceModel, {DeviceAttributes} from "../../../modules/vizz_account/models/DeviceModel"
import FingerprintModel, {FingerprintAttributes} from "../../../modules/vizz_account/models/FingerprintModel"
import {HttpError} from "../../services/RailsAPIService"
import {MaintenanceModeError} from "../MaintenanceModeError"
import {RawState} from "../../services/NativeStateService"

class AnalyticsService {
    private debug: boolean = false  // don't set this to true in production
    private currentAccount: CurrentAccount

    constructor(currentAccount: CurrentAccount) {
        this.currentAccount = currentAccount
    }

    public async initialize() {
        this.consoleDebug(`initialize()`)
        await this.initDevice()
    }

    public logEvent(thing: string, happened: string, note?: string | number, props?: any) {
        try {
            SentryService.crashlyticsLog(`${thing}-${happened}`)
        } catch {
            // Eat this error, just want to avoid messing up analytics
        }

        const properties = this.inflateProps(props)
        this.consoleDebug(`logEvent()`, `${thing}-${happened}`, properties)

        void this.trackEvent(thing, happened, note, properties)
    }

    public async asyncLogEvent(thing: string, happened: string, note?: string | number, props?: any) {
        const properties = this.inflateProps(props)
        this.consoleDebug(`logEvent()`, `${thing}-${happened}`, properties)

        await this.trackEvent(thing, happened, note, properties)
    }

    public async logAppOpened(rawState: RawState) {
        const deviceCategory = await this.getDeviceCategory()
        const background_fetch_status = await BackgroundFetch.getStatusAsync()

        this.currentAccount.personData.dateOfLastOpen = this.currentAccount.personData.dateOfThisOpen
        this.currentAccount.personData.dateOfThisOpen = DateUtils.today_string()
        this.currentAccount.personData.numberOfOpens += 1
        this.currentAccount.personData.lastDeviceType = deviceCategory // deprecating this
        this.currentAccount.personData.lastDeviceCategory = deviceCategory

        if (this.currentAccount.personData.daysSinceLastOpen() > 0) { // new day
            this.currentAccount.personData.numberOfDaysOpened += 1
            this.currentAccount.dayData.numberOfVideosTasted = 0
            this.currentAccount.dayData.numberOfSearches = 0
        }

        let availableDiskBytes: number|undefined = undefined
        if (Platform.OS != 'web') {
            availableDiskBytes = await FileSystem.getFreeDiskStorageAsync()
        }

        this.logEvent('app', 'opened', ManifestUtils.clientUpdateNumber, {
            number_of_opens: this.currentAccount.personData.numberOfOpens,
            date_of_this_open: this.currentAccount.personData.dateOfThisOpen,
            date_of_last_open: this.currentAccount.personData.dateOfLastOpen,
            date_of_first_open: this.currentAccount.personData.dateOfFirstOpen,
            number_of_days_opened: this.currentAccount.personData.numberOfDaysOpened,
            days_since_last_open: this.currentAccount.personData.daysSinceLastOpen(),
            days_since_first_open: this.currentAccount.personData.daysSinceFirstOpen(),
            number_of_videos_tasted: this.currentAccount.personData.numberOfVideosTasted,
            number_of_searches: this.currentAccount.personData.numberOfSearches,
            voice_onboarding_needed: this.currentAccount.personData.voiceOnboardingNeeded,
            age_onboarding_needed: this.currentAccount.personData.ageOnboardingNeeded,
            idfa_onboarding_needed: this.currentAccount.personData.idfaOnboardingNeeded,
            audio_onboarding_needed: this.currentAccount.personData.audioOnboardingNeeded,
            sample_onboarding_needed: this.currentAccount.personData.sampleOnboardingNeeded,
            reported_install_conversion: this.currentAccount.personData.reportedInstallConversion,
            is_child: this.currentAccount.personData.isChild,
            is_emergency_open: Updates.isEmergencyLaunch,
            update_id: Updates.updateId,
            release_channel: Updates.channel,
            server_update_number: this.currentAccount.serverUpdateNumber,
            server_version: this.currentAccount.serverVersion,
            client_update_number: ManifestUtils.clientUpdateNumber,
            client_version: Application.nativeApplicationVersion,
            device_type: this.currentAccount.personData.lastDeviceType, // deprecating
            device_category: this.currentAccount.personData.lastDeviceCategory,
            cohort: this.currentAccount.personData.cohort,
            background_fetch_status: background_fetch_status == null ? 'unknown' : BackgroundFetch.BackgroundFetchStatus[background_fetch_status],
            available_disk_bytes: availableDiskBytes ?? 'undefined',
            native_raw_state: rawState,
        })

        // void FirebaseService.logAppOpen(this.appController, this.currentAccount)
    }

    public async recordInstallConversion() {
        const hasRoblox = false // await this.launcherWidgetController.isAppInstalledAsync('Roblox')

        const reportInstallConversion = this.currentAccount.personData.reportedInstallConversion
        const considerResult = !reportInstallConversion &&
            ManifestUtils.clientVersion != "1.0.27" &&
            this.currentAccount.personData.lastDeviceType == 'tablet' &&
            hasRoblox

        this.currentAccount.analytics.logEvent('firebase', 'consider-install', `${considerResult}`, {
            reportInstallConversion: reportInstallConversion,
            clientVersion: ManifestUtils.clientVersion ?? 'missing',
            lastDeviceType: this.currentAccount.personData.lastDeviceType,
            hasRoblox: hasRoblox
        })
        if (!reportInstallConversion) {
            //void FirebaseService.logAppInstall(this, this.currentAccount)
            this.currentAccount.personData.reportedInstallConversion = true
        }
    }

    // Private instance methods

    private inflateProps(props?: any) {
        let properties:{ [key: string]: any } = props ?? {}

        properties.environment = ManifestUtils.reactEnv
        properties.platform = Platform.OS
        properties.date_of_this_open = this.currentAccount.personData.dateOfThisOpen
        properties.date_of_first_open = this.currentAccount.personData.dateOfFirstOpen
        properties.date_of_last_open = this.currentAccount.personData.dateOfLastOpen
        properties.days_since_first_open = this.currentAccount.personData.daysSinceFirstOpen()
        properties.days_since_last_open = this.currentAccount.personData.daysSinceLastOpen()
        properties.number_of_opens = this.currentAccount.personData.numberOfOpens
        properties.number_of_days_opened = this.currentAccount.personData.numberOfDaysOpened
        properties.device_type = this.currentAccount.personData.lastDeviceType // deprecating this
        properties.device_category = this.currentAccount.personData.lastDeviceCategory
        properties.cohort = this.currentAccount.personData.cohort

        return properties
    }

    private async initDevice() {
        this.consoleDebug(`initDevice()`)

        try {
            const deviceCategory = await this.getDeviceCategory()

            if (this.currentAccount.personData.deviceKey) {
                if (!this.currentAccount.personData.installationKey) this.currentAccount.personData.installationKey = this.currentAccount.personData.deviceKey
                await this.currentAccount.api.patch('vizz_account.device_path', {
                    device: {
                        time_zone_offset_in_minutes: -1 * (new Date).getTimezoneOffset(),
                        server_update_number: this.currentAccount.serverUpdateNumber,
                        client_update_number: ManifestUtils.clientUpdateNumber,
                        installation_key: this.currentAccount.personData.installationKey,
                        platform: Platform.OS, // we should not need to re-set platform here since it does not change, but some devices are still missing it
                    }
                }, false, undefined, true)
                return
            }

            this.consoleDebug(`   No distinct id found, registering...`)

            const fingerprintAttributes:FingerprintAttributes = (Platform.OS == 'web' || !Device.isDevice) ? {} : {
                os_name: Device.osName,
                os_version: Device.osVersion,
                model_id: Device.modelId,
            } as FingerprintModel

            const deviceAttributes:DeviceAttributes = {
                environment: ManifestUtils.reactEnv,
                time_zone_offset_in_minutes: -1 * (new Date).getTimezoneOffset(),
                category: deviceCategory,
                platform: Platform.OS,
                server_update_number: this.currentAccount.serverUpdateNumber,
                client_update_number: ManifestUtils.clientUpdateNumber,
                installation_key: this.currentAccount.personData.installationKey ?? undefined,
            }

            const device = await this.currentAccount.api.post('vizz_account.device_path', {
                device: deviceAttributes,
                fingerprint: fingerprintAttributes,
            }, false, undefined, true) as DeviceModel

            let deviceKey = device.key
            if (deviceKey) {
                this.currentAccount.personData.deviceKey = deviceKey
                if (!this.currentAccount.personData.installationKey) this.currentAccount.personData.installationKey = device.installation_key

                this.currentAccount.personData.cohort = UserCohorts.SOCIAL_BROWSE
                let properties:{ [key: string]: any } = {}

                if (Platform.OS != 'web' && Device.isDevice) {
                    properties = {
                        brand: Device.brand,
                        manufacturer: Device.manufacturer,
                        modelName: Device.modelName,
                        modelId: Device.modelId,
                        designName: Device.designName,
                        productName: Device.productName,
                        totalMemory: Device.totalMemory,
                        supportedCpuArchitectures: Device.supportedCpuArchitectures,
                        osName: Device.osName,
                        osVersion: Device.osVersion,
                        osBuildId: Device.osBuildId,
                        osBuildFingerprint: Device.osBuildFingerprint,
                        platformApiLevel: Device.platformApiLevel,
                    }
                }
                properties.cohort = this.currentAccount.personData.cohort
                properties['$set'] = { // PostHog is flaky. :( These do not always get set on the Person.
                    // duplicate all of these $set's above in app-opened to be cautious.
                    environment: ManifestUtils.reactEnv,
                    device_key: this.currentAccount.personData.deviceKey,
                    number_of_opens: this.currentAccount.personData.numberOfOpens,
                    date_of_last_open: DateUtils.today_string(),
                    date_of_first_open: this.currentAccount.personData.dateOfFirstOpen,
                    number_of_days_opened: this.currentAccount.personData.numberOfDaysOpened,
                    number_of_videos_tasted: this.currentAccount.personData.numberOfVideosTasted,
                    number_of_searches: this.currentAccount.personData.numberOfSearches,
                    is_child: this.currentAccount.personData.isChild,
                    server_update_number: this.currentAccount.serverUpdateNumber,
                    client_update_number: ManifestUtils.clientUpdateNumber,
                    last_device_type: this.currentAccount.personData.lastDeviceType,
                    last_device_category: this.currentAccount.personData.lastDeviceCategory,
                    cohort: this.currentAccount.personData.cohort,
                }

                if (!deviceKey || !this.currentAccount.personData.deviceKey)
                    return Promise.reject(new Error('Analytics device ID not registered. Are we offline?'))
                else
                    this.logEvent('app', 'installed', ManifestUtils.clientUpdateNumber, properties)
                return
            }
        } catch(error) {
            const isUnauthorized = (error instanceof HttpError) && error.status == "unauthorized"
            const isBadRequest = (error instanceof HttpError) && error.status == "bad_request"
            const isUnprocessableEntity = (error instanceof HttpError) && error.status == "unprocessable_entity"

            // unauthorized can happen if the user is logged out but somehow localStorage has old credentials.
            // The user experience will be graceful so it doesn't need to go to Sentry
            if (isUnauthorized || isBadRequest || isUnprocessableEntity) {
                throw new Error('Unable to initialize your device.')
            } else {
                SentryService.captureError(error)
                throw new MaintenanceModeError()
            }
        }
    }

    private async trackEvent(thing: string, happened: string, note: string | number | undefined, props: any) {
        try {
            this.consoleDebug(`trackEvent()`)
            if (!this.currentAccount.personData.deviceKey) return

            await this.currentAccount.api.post('analytics.track_event', {
                'device_key': this.currentAccount.personData.deviceKey,
                'thing': thing,
                'happened': happened,
                'note': note,
                'properties': props,
                'occurred_unix_timestamp': Date.now(),
            })
        } catch (error) {
            SentryService.captureError(error)
        }
    }

    private async getDeviceCategory(): Promise<'phone'|'tablet'|'desktop'|'tv'|'unknown'> {
        const deviceType = await Device.getDeviceTypeAsync()

        switch (deviceType) {
            case Device.DeviceType.PHONE:
                return 'phone'
            case Device.DeviceType.TABLET:
                return 'tablet'
            case Device.DeviceType.DESKTOP:
                return 'desktop'
            case Device.DeviceType.TV:
                return 'tv'
        }

        return 'unknown'
    }

    private consoleDebug(method: string, details?: string, object?: any) {
        if (!this.debug) return
        if (object)
            console.log(`${this.constructor.name}: ${method}  ${details ? details : ''}`, object)
        else
            console.log(`${this.constructor.name}: ${method}  ${details ? details : ''}`)
    }
}

export default AnalyticsService
