import React from 'react'
import {Image, StatusBar, StyleSheet, View} from "react-native"
import {isTablet} from "../lib/Appearance"
import {ActivityIndicator} from "react-native-paper"
import {LavaSplashFooter} from './LavaSplashFooter'

export const LavaSplashScreen = () => {

    const splashAsset = () => {
        return isTablet() ? require('../assets/splash/splash-ipad.png') : require('../assets/splash/splash-iphone.png')
    }

    return (
        <View
            style={{
                flex: 1,
                backgroundColor: 'black',
            }}>
            <Image
                style={{
                    flex: 1,
                    width: '100%'
                }}
                resizeMode={'cover'}
                source={splashAsset()}/>

            <View style={{flex: 1, justifyContent: 'center', alignItems: 'center', ...StyleSheet.absoluteFillObject}}>
                <ActivityIndicator style={{marginTop: 256}} size={'large'} color={'black'}/>
            </View>

            <LavaSplashFooter />
            <StatusBar barStyle={'dark-content'}/>
        </View>
    )
}