import {action, IReactionDisposer, makeObservable, observable, reaction} from "mobx"
import {Platform, Share, ShareContent} from "react-native"
import {CurrentAccount} from "../../../vizz_account/lib/CurrentAccount"
import {AppController} from "../../../../app/controllers/AppController"
import {OnboardingController} from "../OnboardingController"
import {RobloxFriendModel} from "../../../social/models/RobloxFriendModel"
import {AsyncUtils} from "../../../../app/utils/AsyncUtils"
import SentryService from "../../../../app/services/SentryService"
import {LaunchableAppModel} from "../../../launcher/models/LaunchableAppModel"
import * as Clipboard from 'expo-clipboard'
import {NativeState} from "../../../../app/services/NativeStateService"
import {ProfileModel} from "../../../social/models/ProfileModel"

export enum ReferralState {
    LOADING,
    ALREADY_INSTALLED,
    NOT_INSTALLED,
    TUTORIAL,
    MESSAGE_FRIENDS
}

export class ReferralController {
    private debug: boolean = false  // don't set this to true in production

    public appController: AppController
    public onboardingController: OnboardingController
    private currentAccount: CurrentAccount
    private pasteTipTimeout?: any
    private suggestedMessage = `hey, get Lava Friends from the app store, we can call each other while playing Roblox, it's awesome!`
    private cleanupAppStateReaction?: IReactionDisposer
    private backgroundStartTime?: number

    @observable state: ReferralState = ReferralState.LOADING;   @action setState(state: ReferralState) { this.state = state }
    @observable neededFriends: RobloxFriendModel[] = [];        @action setNeededFriends(friends: RobloxFriendModel[]) { this.neededFriends = friends }
    @observable installedFriends: RobloxFriendModel[] = [];     @action setInstalledFriends(friends: RobloxFriendModel[]) { this.installedFriends = friends }
    @observable selectedFriend?: RobloxFriendModel;             @action setSelectedFriend(selectedFriend?: RobloxFriendModel) { this.selectedFriend = selectedFriend }
    @observable headline?: string;                              @action setHeadline(headline?: string) { this.headline = headline }
    @observable message?: string;                               @action setMessage(message?: string) { this.message = message }
    @observable linkInMessage!: string;                         @action setLinkInMessage(linkInMessage: string) { this.linkInMessage = linkInMessage }
    @observable pasteImage?: any;                               @action setPasteImage(path?: any) { this.pasteImage = path }
    @observable messagesApp?: LaunchableAppModel;               @action setMessagesApp(app?: LaunchableAppModel) { this.messagesApp = app }
    @observable messengerApp?: LaunchableAppModel;              @action setMessengerApp(app?: LaunchableAppModel) { this.messengerApp = app }
    @observable messengerKidsApp?: LaunchableAppModel;          @action setMessengerKidsApp(app?: LaunchableAppModel) { this.messengerKidsApp = app }
    @observable whatsApp?: LaunchableAppModel;                  @action setWhatsApp(app?: LaunchableAppModel) { this.whatsApp = app }
    @observable snapchatApp?: LaunchableAppModel;               @action setSnapchatApp(app?: LaunchableAppModel) { this.snapchatApp = app }
    @observable doneMessaging?: 'done' | 'skip';                @action setDoneMessaging(text: 'done' | 'skip') { this.doneMessaging = text }
    @observable messageSendMode?: 'preshare' | 'sharesheet' | 'copypaste' | 'imageshare';   @action setMessageSendMode(mode: 'preshare' | 'sharesheet' | 'copypaste' | 'imageshare') { this.messageSendMode = mode }
    @observable showMessageButtons: boolean = false;            @action setShowMessageButtons(bool: boolean) { this.showMessageButtons = bool }

    private images = {
        unfocused: require('../../assets/referral-input-unfocused.jpg'),
        focused: require('../../assets/referral-input-focused.gif'),
        paste: require('../../assets/referral-input-paste.png'),
        pasted: require('../../assets/referral-input-pasted.png'),
    }

    constructor(currentAccount: CurrentAccount, appController: AppController, onboardingController: OnboardingController) {
        this.consoleDebug(`new()`)

        this.currentAccount = currentAccount
        this.appController = appController
        this.onboardingController = onboardingController

        makeObservable(this)
    }

    public async initialize() {
        try {
            this.consoleDebug(`initialize()`)
            if (this.state != ReferralState.LOADING) return

            const response = await this.currentAccount.api.get('social.referrals_path') as any
            this.setInstalledFriends(response.installed_roblox_profiles as RobloxFriendModel[])
            this.setNeededFriends(response.needed_roblox_profiles as RobloxFriendModel[])

            const myProfile = await this.currentAccount.api.get("social.profile_show_path") as ProfileModel
            this.setLinkInMessage(` https://app.lava.co/?s=onboard_friends&r=${myProfile.username}`)

            let apps = []
            this.setMessagesApp( this.onboardingController.homeController?.launcherWidgetController?.getAppByNameIfInstalled('Messages') ); apps.push('iMessage')
            this.setMessengerApp( this.onboardingController.homeController?.launcherWidgetController?.getAppByNameIfInstalled('Messenger') ); apps.push('Messenger')
            this.setMessengerKidsApp( this.onboardingController.homeController?.launcherWidgetController?.getAppByNameIfInstalled('Messenger Kids') ); apps.push('Messenger Kids')
            this.setWhatsApp( this.onboardingController.homeController?.launcherWidgetController?.getAppByNameIfInstalled('WhatsApp') ); apps.push('WhatsApp')
            this.setSnapchatApp( this.onboardingController.homeController?.launcherWidgetController?.getAppByNameIfInstalled('Snapchat') ); apps.push('Snapchat')

            this.currentAccount.analytics.logEvent('referral-onboarding','started', `${this.neededFriends.length} of ${this.installedFriends.length + this.neededFriends.length} needed`, {
                apps: apps
            })
            if (this.installedFriends.length == 0 && this.neededFriends.length == 0) return

            if (this.installedFriends.length > 0 && this.neededFriends.length > 0)
                this.onboardingController.speech.preloadPhrase('referral-installed', `Great! These real friends already have the Lava app.`)

            if (this.installedFriends.length > 0 && this.neededFriends.length == 0)
                this.onboardingController.speech.preloadPhrase('referral-installed', `Great! These real friends already have the Lava app so you'll be able to call and chat each other.`)

            this.onboardingController.speech.preloadPhrase('referral-message-send-sharesheet', `These real friends do not have Lava. "You" can help. Tap the share button to message them.`)
            //this.onboardingController.speech.preloadPhrase('referral-message-send-copypaste', `These real friends do not have Lava. "You" can help. Would you like to message your friends so they can get Lava?`)
            this.onboardingController.speech.preloadPhrase('referral-message-send-more', `Any other "friends"?`)
            this.onboardingController.speech.preloadPhrase('referral-message-send-imageshare', `Since you have Messenger Kids, would you like to share with any friends on there?`)
            this.onboardingController.speech.preloadPhrase('referral-friends', `These friends need Lava so you can call each other. Let's message each real friend now.`)
            this.onboardingController.speech.preloadPhrase('referral-message', `Here is an example message that you can send. We copied this message to your clipboard so you won't have to type it out. Let me show you how to paste it.`)
            this.onboardingController.speech.preloadPhrase('referral-paste1', `First, tap in the spot pretending you're about to write a message.`)
            this.onboardingController.speech.preloadPhrase('referral-paste2', `Tap again on the blinking cursor.`)
            this.onboardingController.speech.preloadPhrase('referral-paste3', `Now tap "Paste"`)
            this.onboardingController.speech.preloadPhrase('referral-app', `That's how you paste! Now, tap to open the app you use to message friends. Or, tap Skip to tell your friends later.`)
            this.onboardingController.speech.preloadPhrase('referral-paste-tip', `Find a conversation with your friend and tap it.`)
            this.onboardingController.speech.preloadPhrase('referral-paste-tip2', `Tap in the spot to write messages, and then tap again to "paste".`)

            this.cleanupAppStateReaction = reaction(() => this.appController.nativeState.state, async(state) => {
                if (state == NativeState.BACKGROUND && this.state == ReferralState.MESSAGE_FRIENDS) {
                    this.backgroundStartTime = performance.now()

                    this.pasteTipTimeout = 'running'

                    await this.onboardingController.speech.speakPreloadedPhrase('referral-paste-tip', async() => {
                        if (this.pasteTipTimeout == undefined) return
                        await AsyncUtils.sleep(3500)
                        if (this.pasteTipTimeout == undefined) return
                        await this.onboardingController.speech.speakPreloadedPhrase('referral-paste-tip2', async() => {
                            if (this.pasteTipTimeout == undefined) return
                            await AsyncUtils.sleep(4000)
                            if (this.pasteTipTimeout == undefined) return
                            await this.onboardingController.speech.speakPreloadedPhrase('referral-paste-tip2', async() => {
                                if (this.pasteTipTimeout == undefined) return
                                await AsyncUtils.sleep(1000)
                                if (this.pasteTipTimeout == undefined) return
                            })
                        })
                    })
                }

                if (state == NativeState.FOREGROUND) {
                    this.pasteTipTimeout = undefined

                    if (this.backgroundStartTime) {
                        let seconds = Math.round((performance.now() - this.backgroundStartTime) / 1000)
                        this.currentAccount.analytics.logEvent('referral-onboarding','foregrounded', `after ${seconds} sec`)
                    }
                }
            })
        } catch (error) {
            SentryService.captureError(error)
        }
    }

    public async uninitialize() {
        this.consoleDebug(`uninitialize()`)

        this.onboardingController.speech.unloadPreloadedPhrase('referral-installed')
        this.onboardingController.speech.unloadPreloadedPhrase('referral-message-send-sharesheet')
        //this.onboardingController.speech.unloadPreloadedPhrase('referral-message-send-copypaste')
        this.onboardingController.speech.unloadPreloadedPhrase('referral-message-send-imageshare')
        this.onboardingController.speech.unloadPreloadedPhrase('referral-message-send-more')
        this.onboardingController.speech.unloadPreloadedPhrase('referral-friends')
        this.onboardingController.speech.unloadPreloadedPhrase('referral-message')
        this.onboardingController.speech.unloadPreloadedPhrase('referral-paste1')
        this.onboardingController.speech.unloadPreloadedPhrase('referral-paste2')
        this.onboardingController.speech.unloadPreloadedPhrase('referral-paste3')
        this.onboardingController.speech.unloadPreloadedPhrase('referral-app')
        this.onboardingController.speech.unloadPreloadedPhrase('referral-paste-tip')
        this.onboardingController.speech.unloadPreloadedPhrase('referral-paste-tip2')

        if (this.cleanupAppStateReaction) this.cleanupAppStateReaction()
    }

    public async startStep() {
        this.consoleDebug(`startStep()`)

        if (!this.currentAccount.personData.schoolOnboardingNeeded) { // when you tapped a feed story to invite your friends, we take you back to this step & hit this condition
            await this.messageRobloxAnswer('no')
            this.setDoneMessaging('done')
        } else {
            if (this.installedFriends.length > 0) {
                this.currentAccount.analytics.logEvent('referral-onboarding', 'already-installed', `${this.installedFriends.length} friends`, {
                    installed_friends: this.installedFriends
                })
                await this.onboardingController.speech.speakPreloadedPhrase('referral-installed')
                this.setState(ReferralState.ALREADY_INSTALLED)
            } else {
                await this.promptForMessage()
            }
        }
    }

    public async promptForMessage() {
        if (this.neededFriends.length > 0) {
            this.setState(ReferralState.NOT_INSTALLED)

            if (this.messageSendMode == 'copypaste') // currently no way to get into copypaste mode so we'll probably remove this soon
                await this.promptForMessageCopyPaste()
            else
                await this.promptForMessageShareSheet()
        } else {
            this.currentAccount.analytics.logEvent('referral-onboarding', 'empty')
            this.nextStep()
        }
    }

    public async promptForMessageCopyPaste() {
        this.currentAccount.analytics.logEvent('referral-onboarding', 'copypaste')
        await this.onboardingController.speech.speakPreloadedPhrase('referral-message-send-copypaste', async() => {
            this.setShowMessageButtons(true)
        })
    }

    public async promptForMessageShareSheet() {
        await this.onboardingController.speech.speakPreloadedPhrase('referral-message-send-sharesheet', async() => {
            this.setMessageSendMode('preshare')
        })
    }

    public async basicShareSheet() {
        this.currentAccount.analytics.logEvent('referral-onboarding', 'sharesheet')
        this.setMessageSendMode('sharesheet')

        let params = {} as ShareContent
        if (Platform.OS == 'ios') {
            params = {
                message: this.suggestedMessage,
                url: this.linkInMessage.trim()
            }
        } else {
            params = {
                title: 'Share this link',
                message: this.suggestedMessage + this.linkInMessage
            }
        }

        const result = await Share.share(params)

        if (Platform.OS == 'android') {
            await AsyncUtils.sleep(1000)
            this.setShowMessageButtons(true)
            return
        }

        if (result.action === Share.sharedAction) {
            this.currentAccount.analytics.logEvent('referral-onboarding', 'shared')
            void this.onboardingController.speech.speakPreloadedPhrase('referral-message-send-more')
            await this.basicShareSheet()
        } else {
            this.currentAccount.analytics.logEvent('referral-onboarding', 'dismissed')
            await this.promptForMessageImageShare()
        }
    }

    public async promptForMessageImageShare() {
        this.nextStep() // short circuit image share
        return

        this.setShowMessageButtons(false)
        //this.currentAccount.analytics.logEvent('referral-onboarding', 'offered')
        await this.onboardingController.speech.speakPreloadedPhrase('referral-message-send-imageshare', async() => {
            await this.imageShareSheet()
        })
    }

    public async imageShareSheet() {
        this.currentAccount.analytics.logEvent('referral-onboarding', 'imageshare')
        this.setMessageSendMode('imageshare')

        const result = await Share.share({
            message: this.suggestedMessage,
            url: this.linkInMessage.trim(),
        })

        if (Platform.OS == 'android') {
            await AsyncUtils.sleep(1000)
            this.setShowMessageButtons(true)
            return
        }

        if (result.action === Share.sharedAction) {
            void this.onboardingController.speech.speakPreloadedPhrase('referral-message-send-more')
            await this.imageShareSheet()
        } else {
            this.nextStep()
        }
    }

    public async messageRobloxAnswer(answer: 'no' | 'yes') {
        this.onboardingController.speech.pause()
        this.currentAccount.analytics.logEvent('referral-onboarding', 'roblox-messaging', answer)

        if (answer == 'no') {
            this.nextStep()
            return
        }

        this.setHeadline(undefined)
        this.setState(ReferralState.TUTORIAL)

        await this.onboardingController.speech.speakPreloadedPhrase('referral-friends', async() => {

            this.setMessage(this.suggestedMessage)
            await this.onboardingController.speech.speakPreloadedPhrase('referral-message', async() => {

                this.setMessage(undefined)
                this.setPasteImage(this.images.unfocused)
                await this.onboardingController.speech.speakPreloadedPhrase('referral-paste1')
            })
        })
    }

    public async onPasteStepAfter(previousImage?: any) {

        if (previousImage == this.images.unfocused) {
            this.setPasteImage(this.images.focused)
            await this.onboardingController.speech.speakPreloadedPhrase('referral-paste2')
        }

        if (previousImage == this.images.focused) {
            this.setPasteImage(this.images.paste)
            await this.onboardingController.speech.speakPreloadedPhrase('referral-paste3')
        }

        if (previousImage == this.images.paste) {
            this.setPasteImage(this.images.pasted)
            this.setMessage(this.suggestedMessage)
            await this.onboardingController.speech.speakPreloadedPhrase('referral-app', async() => {
                this.setHeadline('Tap to open your app.')
                this.setDoneMessaging('skip')
                this.setState(ReferralState.MESSAGE_FRIENDS)
            })
        }
    }

    public async openApp(app: LaunchableAppModel) {
        this.currentAccount.analytics.logEvent('referral-onboarding', 'opened', `${app.name} app`, { app: app.name })

        if (app.name == 'Messenger Kids')
            await Clipboard.setStringAsync(this.suggestedMessage)
        else
            await Clipboard.setStringAsync(this.suggestedMessage + this.linkInMessage)

        this.onboardingController.homeController?.launcherWidgetController?.openApp(app)

        // the reaction setup in initialize() will trigger the next audio as soon as the app is backgrounded

        await AsyncUtils.sleep(2500)
        this.setDoneMessaging('done')
    }

    public nextStep() {
        this.consoleDebug(`nextStep()`)

        this.onboardingController.speech.pause()
        this.pasteTipTimeout = undefined

        this.currentAccount.analytics.logEvent('referral-onboarding', 'finished', this.selectedFriend?.display_name, { selected_friend: this.selectedFriend })
        this.onboardingController.nextStep()
    }

    // Private instance utility methods

    private consoleDebug(method: string, force: boolean = false) {
        if (this.debug || force) console.log(`${this.constructor.name}: ${method}  state = ${this.state}`)
    }
}