import React, {useEffect, useRef} from 'react'
import VideoCard from '../../../lava-components/components/molecules/VideoCard/VideoCard'
import useTheme from "../../../lava-components/components/hooks/useTheme"
import {RobloxGameDetailContentController} from '../../controllers/roblox_games_widget/RobloxGameDetailContentController'
import {RobloxGameDetailController} from '../../controllers/roblox_games_widget/RobloxGameDetailController'
import {Video} from '../Video'

interface CodeVideoProps {
    playable: boolean | undefined
    controller: RobloxGameDetailContentController
    robloxGameDetailController: RobloxGameDetailController
    stopPlaying: boolean
}

export const CodeVideo: React.FC<CodeVideoProps> = ({playable, controller, robloxGameDetailController, stopPlaying}) => {
    const theme = useTheme()
    const videoRef = useRef<any>(null)

    useEffect(() => {
        if(videoRef && controller.codesVideo && playable){
            if(stopPlaying){
                videoRef.current?.autoPause()
            } else {
                videoRef.current?.autoPlay()
            }
        }
    },[videoRef, controller.codesVideo, playable, stopPlaying])

    return (
        <VideoCard
            key='video-card'
            style={{
                marginBottom: theme.spacing.xl,
                marginEnd: theme.spacing.xl,
            }}
            variation={'secrets'}
            showPlayButton={!(playable && !stopPlaying)}
            title={controller.codesVideo?.title}
            creatorName={controller.codesVideo?.channel_title}
            thumbnailUrl={controller.codesVideo?.thumbnail_url ?? ''}
            duration={controller.codesVideo?.duration_number}
            views={controller.codesVideo?.view_count}
            uploadedOn={controller.codesVideo?.published_at}
            playable={playable && !stopPlaying}
            videoComponent={controller.codesVideo && <Video
                video={controller.codesVideo}
                ref={videoRef}
            /> }
            onPress={() => {
                robloxGameDetailController.logEvent('game-details', 'codes-video', 'played')
                robloxGameDetailController.setTrailerVideo(controller.codesVideo)
            }}
        />
    )
}