export class ErrorUtils {

    static isInternetProblemError(input: any) {
        if (input instanceof Error) {
            const error = input as Error
            return error.message.includes('AbortError') || error.name === 'AbortError' || error.message.includes('Network request failed')
        }
        return false
    }

}