import React, {} from 'react'
import {RouteProp} from "@react-navigation/native";
import {observer} from "mobx-react";
import SingleVizzWeb from "./SingleVizzWeb";
import {StackNavigationProp} from "@react-navigation/stack";
import {RootStackNavigatorList} from "../../lib/interfaces/root-stack-navigator";

type SingleVizzWebScreenRouteProp = RouteProp<RootStackNavigatorList, 'SingleVizzWeb'>

type SingleVizzWebScreenNavigationProp = StackNavigationProp<
    RootStackNavigatorList,
    'SingleVizzWeb'>

type Props = {
    route: SingleVizzWebScreenRouteProp
    navigation: SingleVizzWebScreenNavigationProp
}

const SingleVizzWebScreen = observer((props: Props) => {

    return (
        <SingleVizzWeb vizzId={props.route.params.vizzId}/>
    )
})
export default SingleVizzWebScreen