import {toJS} from "mobx"

interface ConstructorNamedParams {
    startingObject?: any
    railsAPICall: (count: number) => Promise<any>
    isDone: (obj: any) => boolean
    maxRetriesPerUpdate?: number
    maxRetriesTotal?: number
    afterUpdate?: (obj: any) => Promise<void>
    afterTimeout?: () => Promise<void>
}

interface StartNamedParams {
    afterUpdate?: (obj: any) => Promise<void>
    afterTimeout?: () => Promise<void>
}

export default class PollingAPIService {
    private debug: boolean = false

    // For initialization
    private maxRetriesPerUpdate: number
    private maxRetriesTotal: number
    private railsAPICall: (count: number) => Promise<any>
    private isDone: ((obj: any) => boolean)
    public afterUpdate?: ((obj: any) => Promise<void>)
    public afterTimeout?: (() => Promise<void>)
    private progressiveVizz: boolean = false

    // Internal
    private retriesAttempted: number = 0
    private retriesTotal: number = 0
    private pollingTimeout?: any
    private objectToUpdate: any


    constructor({
        startingObject,
        railsAPICall,
        isDone,
        maxRetriesPerUpdate = 10,
        maxRetriesTotal = 120,
        afterUpdate,
        afterTimeout,
    }: ConstructorNamedParams) {
        this.consoleDebug(`new()`)

        this.objectToUpdate = startingObject
        this.railsAPICall = railsAPICall
        this.isDone = isDone
        this.maxRetriesPerUpdate = maxRetriesPerUpdate
        this.maxRetriesTotal = maxRetriesTotal
        this.afterUpdate = afterUpdate
        this.afterTimeout = afterTimeout

        //TODO: Remove this
        if (('slides' in this.objectToUpdate) && !this.objectToUpdate.doneProcessing) {
            this.progressiveVizz = true
        }
    }


    // Public instance methods

    public start({afterUpdate = undefined, afterTimeout = undefined}: StartNamedParams) {
        this.consoleDebug(`start()`)
        if (afterUpdate) this.afterUpdate = afterUpdate
        if (afterTimeout) this.afterTimeout = afterTimeout

        this.retriesAttempted = 0
        void this.poll()
    }

    public stop() {
        this.consoleDebug(`stop()`)

        this.pollingTimeout = this.resetTimeout(this.pollingTimeout)
        this.afterTimeout = undefined
        this.afterUpdate = undefined
        this.retriesAttempted = 0
    }


    // Private instance methods

    private async poll() {
        // This method will loop maxRetriesPerUpdate times or until doneProcessing is true. But note that every time it gets new data
        // it resets the retries count so it will make more than maxRetriesPerUpdate total API calls. It just won't make more than
        // maxRetriesPerUpdate calls without getting any data.

        this.pollingTimeout = this.resetTimeout(this.pollingTimeout) // there should not be another poll running, but just in case


        // if doneProcessing already equals true, then startVizzUpdatePolling() was mistakenly called and cannot do anything
        if (this.isDone(this.objectToUpdate) || await this.getUpdate(this.retriesTotal += 1)) {
            if (this.afterUpdate) await this.afterUpdate(this.objectToUpdate)
        }

        if (this.isDone(this.objectToUpdate)) return

        if (this.retriesAttempted > this.maxRetriesPerUpdate ||
            this.retriesTotal > this.maxRetriesTotal) {

            if (this.afterTimeout) await this.afterTimeout()
            this.retriesAttempted = 0
            return
        }

        this.pollingTimeout = setTimeout(async () => {
            await this.poll()
        }, 1000)
    }

    private async getUpdate(updateCount: number) {
        this.consoleDebug(`getUpdate()`)
        let objectToUpdate = await this.railsAPICall(updateCount)
        this.retriesAttempted += 1

        objectToUpdate.id = this.objectToUpdate.id // Hack: We added :: to the ID of our our active vizz
        if (JSON.stringify(toJS(this.objectToUpdate)) !== JSON.stringify(objectToUpdate)) { // new data!
            this.objectToUpdate = objectToUpdate
            this.retriesAttempted = 0
            return Promise.resolve(true)
        } else {
            return Promise.resolve(false)
        }
    }

    private resetTimeout(timeout?: number) {
        if (timeout) clearTimeout(timeout)
        return undefined
    }

    private consoleDebug(method: string) {
        if(this.debug) console.log(`PollingAPI: ${method}`)
    }
}