import React from 'react'
import {StyleSheet, View} from "react-native"
import {RouteProp, useNavigation} from "@react-navigation/native"
import {RootDrawerNavigatorList} from "../../lib/interfaces/root-tab-navigator"
import {StackNavigationProp} from "@react-navigation/stack"
import {Color} from "../../lib/Appearance"
import {observer} from "mobx-react"

export type FeedScreenRouteProp = RouteProp<RootDrawerNavigatorList, 'Home'>

export type FeedScreenNavigationProp = StackNavigationProp<
    RootDrawerNavigatorList,
    'Home'>

const FeedWithSearchScreen = observer(() => {

    const navigation = useNavigation<any>()

    // const currentAccount = useContext(CurrentAccountContext)
    // const feedStore = useFeedStore()
    // const [SuggestedConcepts,setSuggestedConcepts] = useState<ConceptNodeResponse[]>([])
    // const [refreshing, setRefreshing] = useState<boolean>(false)
    // const [showingVoicePrompt, setShowingVoicePrompt] = useState<boolean>(false)
    // const conceptsCarousel = useRef<FlatList>(null)
    // const feedList = useRef<FlatList>(null)
    // const isFocused = useRef(false)
    // const [askVizzText, setAskVizzText] = useState('')
    // const [flatListLayout, setFlatListLayout] = useState<LayoutRectangle>({x: 0, y: 0, width: 0, height: 0})
    // const isAutoPlayEnabled = useRef<boolean>(false)
    // const [hasCameraPermission, setHasCameraPermission] = useState<boolean|null>(null)
    // const playVizzOnNextLoadId = useRef<string|undefined>(undefined)
    // const [textSearchActive, setTextSearchActive] = useState(false)
    // const searchInput = useRef<any>(null)
    // const [conceptNav1ConceptId, setConceptNav1ConceptId] = useState<number|undefined>()
    // const [conceptNav1Title, setConceptNav1Title] = useState<string|undefined>()
    // const [conceptNav1Covered, setConceptNav1Covered] = useState<boolean>(false)
    // const [conceptNav1VizzId, setConceptNav1VizzId] = useState<string|undefined>()
    // const [conceptNav2ConceptId, setConceptNav2ConceptId] = useState<number|undefined>()
    // const [conceptNav2Title, setConceptNav2Title] = useState<string|undefined>()
    // const [conceptNav2VizzId, setConceptNav2VizzId] = useState<string|undefined>()
    //
    // useEffect(() => {
    //     const eventBlock = reaction(() => feedStore.broadcastEvent,
    //         (broadcastEvent) => {
    //             if (broadcastEvent == 'reset-cache') {
    //                 refresh()
    //             }
    //         })
    //
    //     const playingVizz = reaction(() => currentAccount.playingVizzId,
    //         (vizzId) => {
    //             if (vizzId && !isAutoPlayEnabled.current) {
    //                 isAutoPlayEnabled.current = true
    //             }
    //         })
    //
    //     const vizzesBlock = reaction(() => feedStore.vizzes, (vizzes) => {
    //         if (playVizzOnNextLoadId.current && vizzes.length > 0 && vizzes[0].id == playVizzOnNextLoadId.current) {
    //             feedList.current?.scrollToIndex({index: 0, animated: true})
    //             currentAccount.updatePlayingVizz(playVizzOnNextLoadId.current)
    //             playVizzOnNextLoadId.current = undefined
    //         }
    //     })
    //
    //     // window.setTimeout(() => {
    //     //     props.navigation.navigate('ConceptNavigator', {conceptId: 641354})
    //     // }, 1000)
    //
    //     return () => {
    //         eventBlock()
    //         playingVizz()
    //         vizzesBlock()
    //     }
    // }, [])
    //
    // useFocusEffect(
    //     useCallback(() => {
    //         isFocused.current = true
    //         AppState.addEventListener('change', handleAppStateChange)
    //
    //         return () => {
    //             isFocused.current = false
    //             AppState.removeEventListener('change', handleAppStateChange);
    //             console.log('return of useCallBack() useFocusEffect()')
    //             currentAccount.updatePlayingVizz(null)
    //         }
    //     }, [])
    // )
    //
    // const handleAppStateChange = (nextAppState: AppStateStatus) => {
    //     if (nextAppState == 'active') {
    //         (async () => {
    //             isAutoPlayEnabled.current = false
    //             conceptsCarousel.current?.scrollToOffset({offset: 0, animated: false})
    //             await feedStore.setSelectedConcept(undefined)
    //             await refresh(true)
    //         })()
    //     }
    // };
    //
    // useEffect(() => {
    //     refresh()
    // }, [])
    //
    // useEffect(() => {
    //     currentAccount.api.get('vizz_graph.tagged_with_concepts', {tag_name: 'suggested'}).then(concepts => {
    //         setSuggestedConcepts(concepts)
    //     })
    // }, [])
    //
    // const updateCamera = async () => {
    //     if (Platform.OS != 'web') {
    //         const { status } = await Camera.requestCameraPermissionsAsync()
    //         setHasCameraPermission(status === PermissionStatus.GRANTED)
    //     }
    // }
    //
    // const refresh = (refreshConcepts: boolean = false) => {
    //     updateCamera()
    //
    //     console.log('Feed: Try refresh')
    //     if (refreshing && isFocused) return
    //
    //     (async () => {
    //         console.log('Feed: Do refresh')
    //         currentAccount.updatePlayingVizz(null)
    //
    //         try {
    //             await feedStore.refresh(refreshConcepts)
    //             setRefreshing(false)
    //             showSwipeHint()
    //         } catch (error) {
    //             console.log(`Error loading collections ${error}`)
    //         }
    //     })()
    // }
    //
    // // Header
    //
    // const headerButtonSize = () => {
    //     return isTablet() ? 64 : 64
    // }
    //
    // const headerIconSize = () => {
    //     return isTablet() ? 36 : 36
    // }
    //
    // const header = () => {
    //     return (
    //         <View style={{flexDirection: 'row', justifyContent: 'center', margin: 16}}>
    //             {(Platform.OS != 'web') &&
    //             <TouchableRipple
    //                              onLongPress={onCameraTapped}
    //                              onPress={onCameraTapped}>
    //                 {Platform.OS == 'ios' && hasCameraPermission ?
    //                     <Camera style={[styles.micButton, {width: headerButtonSize(), height: headerButtonSize(), overflow: 'hidden', backgroundColor: Color.primary}]}>
    //                         <MaterialIcons style={{opacity: 0.5}} name="camera-alt" size={headerIconSize()} color="white"/>
    //                     </Camera>
    //                     :
    //                     <View style={[styles.micButton, {width: headerButtonSize(), height: headerButtonSize(), overflow: 'hidden', backgroundColor: Color.primary}]}>
    //                         <MaterialIcons style={{opacity: 0.5}} name="camera-alt" size={headerIconSize()} color="white"/>
    //                     </View>
    //                 }
    //             </TouchableRipple>
    //             }
    //
    //             <TextInput
    //                 style={[{marginHorizontal: 16, paddingRight: 35, borderColor: Color.primary, borderWidth: 1},
    //                     isTablet() ?
    //                     {width: "30%"} : {flex: 1}]}
    //                 ref={searchInput}
    //                 label="Ask Lava"
    //                 multiline={false}
    //                 numberOfLines={1}
    //                 value={askVizzText}
    //                 onFocus={() => {
    //                     LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut)
    //                     setTextSearchActive(true)
    //                 }}
    //                 onBlur={() => {
    //                     LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut)
    //                     setTextSearchActive(false)
    //                 }}
    //                 onChangeText={text => setAskVizzText(text)}
    //                 right={
    //                     textSearchActive ?
    //                         <TextInput.Icon
    //                             name="card-search"
    //                             size={80}
    //                             color={Color.primary}
    //                             style={{marginRight: 30}}
    //                             onPress={onSearchTapped}
    //                             onLongPress={onSearchTapped}
    //                         />
    //                         : null
    //                 }
    //             />
    //
    //             {(Platform.OS != 'web') &&
    //             <TouchableRipple style={{borderRadius: headerButtonSize() / 2}}
    //                              onLongPress={onVoiceTapped}
    //                              onPress={onVoiceTapped}>
    //                 <View style={[styles.micButton, {width: headerButtonSize(), height: headerButtonSize()}]}>
    //                     <MaterialIcons name="mic" size={headerIconSize()} color="black"/>
    //                 </View>
    //             </TouchableRipple>
    //             }
    //         </View>
    //     )
    // }
    //
    // const onCameraTapped = () => {
    //     props.navigation.navigate('ObjectIdentifier', {onResult: handleObjectIdentifierResult})
    // }
    //
    // const onSearchTapped = () => {
    //     if (askVizzText.length > 0) {
    //         searchInput.current?.blur()
    //         setShowingVoicePrompt(true)
    //     }
    // }
    //
    // const onVoiceTapped = () => {
    //     currentAccount.updatePlayingVizz(null)
    //     setAskVizzText('')
    //     setShowingVoicePrompt(true)
    // }
    //
    // const handleObjectIdentifierResult = (vizz?: Vizz, conceptId?: number) => {
    //     if (conceptId) {
    //         showConceptNav1(conceptId, vizz?.id, '')
    //     } else if (vizz) {
    //         props.navigation.navigate('VizzDetail', {vizzId: vizz.id, autoPlay: true})
    //     }
    // }
    //
    // // Concepts Grid
    //
    // const conceptThumbnailWidth = () => {
    //     return Dimensions.get('screen').width / (isTablet() ? 5 : 2)
    // }
    //
    // const renderCollectionsGrid = () => {
    //     return (
    //         <FlatGrid
    //             style={styles.collection}
    //             itemDimension={conceptThumbnailWidth()}
    //             renderItem={renderGridItem}
    //             data={SuggestedConcepts}
    //             spacing={0}
    //         />
    //     )
    // }
    //
    // const renderGridItem = (node: ListRenderItemInfo<ConceptNodeResponse>) => {
    //     return (
    //         <TouchableRipple
    //             onPress={() => onGridItemTapped(node)}
    //             onLongPress={() => onGridItemTapped(node)}
    //             style={{marginVertical: 8, alignContent: 'center', alignItems: 'center'}}
    //         >
    //             <ConceptThumbnail
    //                     imageUrl={node.item.primary_media?.concept_media_url}
    //                     label={node.item.primary_name.name}
    //                     width={conceptThumbnailWidth()-16}
    //             />
    //         </TouchableRipple>
    //     )
    // }
    //
    // const onGridItemTapped = (info: ListRenderItemInfo<ConceptNodeResponse>) => {
    //     (async () => {
    //         showConceptNav1(info.item.id, undefined, info.item.primary_name.name)
    //     })()
    // }
    //
    // const scrollToSelectedConcept = () => {
    //     const index = feedStore.collections
    //         .map((c) => c.id)
    //         .indexOf(feedStore.selectedConcept ?? '')
    //
    //     if (index && index > -1) {
    //         // Carousel flat list neeeds time to render before we can scroll
    //         // to the appropriate item.
    //         window.setTimeout(() => {
    //             conceptsCarousel.current?.scrollToIndex({
    //                 index: index,
    //                 animated: true,
    //                 viewPosition: 0.5
    //             })
    //         }, 500)
    //     }
    // }
    //
    // // Vizz List
    //
    // const viewabilityConfig = {
    //     waitForInteraction: false,
    //     itemVisiblePercentThreshold: 90,
    // }
    //
    // const onViewableItemsChanged = useRef((result: { viewableItems: Array<ViewToken>, changed: Array<ViewToken> }) => {
    //     if (!isAutoPlayEnabled.current) {
    //         return
    //     }
    //
    //     // it's possible that viewableItems items includes some that are not viewable, not sure why
    //     const definitelyViewableItems = result.viewableItems.filter((item) => item.isViewable)
    //
    //
    //     if (definitelyViewableItems.length == 0) {
    //         console.log('definitelyViewableItems == 0')
    //         currentAccount.updatePlayingVizz(null)
    //         return
    //     }
    //
    //     //console.log(`onViewableItemsChanged: ${definitelyViewableItems[0].item.id} =? ${currentAccount.playingVizzId}`)
    //     if (definitelyViewableItems[0].item.id != currentAccount.playingVizzId) {
    //         //console.log(`about to updatePlayingVizz: ${currentAccount.playingVizzId}`)
    //         currentAccount.updatePlayingVizz(definitelyViewableItems[0].item.id)
    //         console.log(`\nCHANGED playing vizz to ${currentAccount.playingVizzId}`)
    //     } else {
    //         console.log("did not update updatePlayingVizz")
    //     }
    // })
    //
    // const vizzWidth = () => {
    //     const width = flatListLayout.width - 32
    //     const proposedHeight = width * 3/4
    //     if (proposedHeight > (flatListLayout.height - 32)) {
    //         return (flatListLayout.height - 32) * (4/3)
    //     } else {
    //         return width
    //     }
    // }
    //
    // // const renderListItem = (info: ListRenderItemInfo<Vizz>) => {
    // //     const item: Vizz = info.item
    //
    // //     return (
    // //         <View style={{flex: 1, height: flatListLayout.height, justifyContent: 'center', alignItems: 'center'}}>
    // //             <View style={{width: vizzWidth()}}>
    // //                 <FixedAspectView aspectRatio={3 / 4}>
    // //                     <VizzView vizz={item} playbackState={playbackState} showInlineTitle={true} />
    // //                 </FixedAspectView>
    // //             </View>
    // //         </View>
    // //     )
    // // }
    //
    // const onListItemTapped = (info: ListRenderItemInfo<Vizz>) => {
    //     currentAccount.updatePlayingVizz(info.item.id)
    // }
    //
    // // const renderList = () => {
    // //     return (
    // //         <FlatList
    // //             ref={feedList}
    // //             onLayout={(event) => {
    // //                 setFlatListLayout(event.nativeEvent.layout)
    // //             }}
    // //             pagingEnabled={true}
    // //             style={{flex: 1}}
    // //             viewabilityConfig={viewabilityConfig}
    // //             onViewableItemsChanged={onViewableItemsChanged.current}
    // //             data={feedStore.vizzes}
    // //             renderItem={renderListItem}
    // //             keyExtractor={item => item.id}
    // //         />
    // //     )
    // // }
    //
    // const showSwipeHint = () => {
    //     if (feedStore.vizzes.length < 4) return
    //
    //     feedList.current?.scrollToIndex({
    //         index: 3,
    //         animated: false
    //     })
    //
    //     window.setTimeout(() => {
    //         feedList.current?.scrollToIndex({
    //             index: 0,
    //             animated: true
    //         })
    //         setTimeout(() => {
    //             isAutoPlayEnabled.current = true
    //         }, 1000)
    //     }, 300)
    // }
    //
    // const showVizz = (key?: string, concept?: ConceptNodeResponse) => {
    //     setShowingVoicePrompt(false)
    //     console.log(`Show Vizz ${key} ${concept?.id} `)
    //     if (concept) {
    //         showConceptNav1(concept.id, key, concept.primary_name.name)
    //     } else if (key) {
    //         props.navigation.navigate('VizzDetail', {vizzId: key, autoPlay: true})
    //     }
    // }
    //
    // // CONCEPT NAV
    //
    // const showConceptNav1 = (conceptId: number, vizzId?: string, title?: string) => {
    //     setConceptNav1ConceptId(conceptId)
    //     setConceptNav1Title(title)
    //     setConceptNav1VizzId(vizzId)
    // }
    //
    // const dismissConceptNav1 = () => {
    //     setConceptNav1Covered(false)
    //     setConceptNav1ConceptId(undefined)
    // }
    //
    // const renderConceptNav1 = () => {
    //     if (conceptNav1ConceptId == undefined) return null
    //
    //     return (
    //         <View style={{flex: 1, ...StyleSheet.absoluteFillObject}}>
    //             <ConceptNavigator
    //                 title={conceptNav1Title ?? ''}
    //                 conceptNavigator1StartingId={conceptNav1ConceptId}
    //                 conceptId={conceptNav1ConceptId}
    //                 vizzId={conceptNav1VizzId}
    //                 onDismiss={dismissConceptNav1}
    //                 covered={conceptNav1Covered}
    //                 onNewConceptNavigator={showConceptNav2}
    //                 referringUrl={'/'} />
    //         </View>
    //     )
    // }
    //
    // const showConceptNav2 = (conceptId: number, vizzId?: string, title?: string) => {
    //     if (conceptId == 0) return
    //     setConceptNav2ConceptId(conceptId)
    //     setConceptNav2Title(title)
    //     setConceptNav2VizzId(vizzId)
    //     setConceptNav1Covered(true)
    // }
    //
    // const dismissConceptNav2 = () => {
    //     setConceptNav2ConceptId(undefined)
    // }
    //
    // const renderConceptNav2 = () => {
    //     if (conceptNav1ConceptId == undefined || conceptNav2ConceptId == undefined) return null
    //
    //     return (
    //         <View style={{flex: 1, ...StyleSheet.absoluteFillObject}}>
    //             <ConceptNavigator
    //                 title={conceptNav2Title ?? ''}
    //                 conceptNavigator1StartingId={conceptNav1ConceptId}
    //                 conceptId={conceptNav2ConceptId}
    //                 vizzId={conceptNav2VizzId}
    //                 onStartDismiss={() => setConceptNav1Covered(false)}
    //                 onDismiss={dismissConceptNav2}
    //                 covered={false}
    //                 referringUrl={'/'}
    //             />
    //         </View>
    //     )
    // }
    //
    // return (
    //     <SafeAreaView style={{flex: 1}}>
    //         <View style={{flex: 1}}>
    //             {header()}
    //             {renderCollectionsGrid()}
    //         </View>
    //         {renderConceptNav1()}
    //         {renderConceptNav2()}
    //         {showingVoicePrompt && <VoicePrompt
    //             onFinish={showVizz}
    //             searchText={askVizzText.length > 0 ? askVizzText : undefined}/>}
    //         <StatusBar style="dark" />
    //     </SafeAreaView>
    // )

    // Old code, probably delete soon
    return (<View/>)
})

const styles = StyleSheet.create({
    collection: {
        flex: 1
    },
    coverImage: {
        borderWidth: 1,
        borderColor: 'black',
        borderRadius: 20,
        overflow: 'hidden',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        height: 150,
    },
    micButton: {
        justifyContent: 'center',
        alignItems: 'center',
        shadowRadius: 4,
        shadowColor: 'black',
        shadowOpacity: 0.5,
        borderRadius: 40,
        backgroundColor: Color.secondary
    },
    checkmark: {
        position: 'absolute',
        justifyContent: 'center',
        alignItems: 'center',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0
    },
    carouselItemImage: {
        flex: 1,
        shadowOffset: {
            width: 0.5,
            height: 0.5
        },
        shadowColor: 'black',
        shadowOpacity: 0.6,
        shadowRadius: 4
    }
})

export default FeedWithSearchScreen
