import React, {useEffect, useRef, useState} from 'react'
import {Animated, Platform, StyleSheet, View} from 'react-native'
import {ResizeMode, Video} from 'expo-av'
import {AssetType, SlidePan, SlideZoom, VizzMedia, VizzSlideModel} from "../../../models/VizzModel"
import {observer} from "mobx-react"
import {SlideController, SlidePlaybackState} from "../../../controllers/vizz/slides/SlideController"
import {reaction} from "mobx"
import {ActivityIndicator} from "react-native-paper"
import {AVPlaybackWaiter} from '../../../../../app/utils/AVUtils'
import {PlayButton} from '../../../../../app/views/components/PlayButton'
import VizzController, {VizzSlideState} from '../../../controllers/VizzController'
import {LavaImage} from "../../../../../app/views/components/LavaImage"

type Props = {
    slide: VizzSlideModel
    vizzController: VizzController
    width: number
    height: number
    index?: number         // Swiper automatically passes this
}

const Slide = observer((props: Props) => {

    const [slidePlayer] = useState<SlideController>(() => new SlideController(props.slide))
    const scaleAnimationValue = useRef(new Animated.Value(1))
    const translateXAnimationValue = useRef(new Animated.Value(0))
    const translateYAnimationValue = useRef(new Animated.Value(0))
    const loopTransitionOpacityValue = useRef(new Animated.Value(0))
    const video = useRef<Video>(null)
    const [videoPosterVisible, setVideoPosterVisible] = useState<boolean>(false)
    let isMounted = true

    const shouldLoopVideo = () => {
        return (slidePlayer.activeMedia.assetType == AssetType.Video &&
            (slidePlayer.activeMedia.audioDuration ?? 0) > (slidePlayer.activeMedia.videoDuration ?? 0))
    }

    useEffect(() => {
        isMounted = true

        playIfCurrentSlide(props.vizzController.currentSlideIndex)
        applyInlineVideoFix()

        return () => {
            isMounted = false
        }
    }, [])

    function playIfCurrentSlide(activeIndex: number | null, prevIndex: number | null = null) {
        if (activeIndex == null) return

        if (activeIndex == props.index) {
            void startSlide()

            if (prevIndex && prevIndex == 0) props.vizzController.slideControl.restartCurrent()
            // I'm not sure the above two conditions are fully right. As it advances from slide
            // 0 to slide 1, the prevIndex will be 0 and we shouldn't restart, should we?
            // It works so not debugging this now but this is something to think through when
            // we refactor VizzSlideView and VizzSlidePlayer
        } else
            void slidePlayer.cleanUp()
    }

    useEffect(() => {
        const vizzR1Cleanup = reaction(() => props.vizzController.currentSlideIndex, (activeIndex, prevIndex) => {
            playIfCurrentSlide(activeIndex, prevIndex)
        })

        const vizzR2Cleanup = reaction(() => props.vizzController.state, (vizzState) => {
            if (vizzState == 'stopped') {
                void slidePlayer.cleanUp()
            }
        })

        const slideR1Cleanup = reaction(() => slidePlayer.playerState, (state) => {
            void onStateChange(state)
        })

        const slideR2Cleanup = reaction(() => slidePlayer.activeMedia, (media) => {
            if (slidePlayer.activeMedia.assetType == AssetType.Video) {
                void startVideo()
            }
        })

        const slideR3Cleanup = reaction(() => slidePlayer.audioSuspended, (audioSuspended) => {
            if (audioSuspended)
                props.vizzController.slideControl.pause()
            else
                props.vizzController.slideControl.resume()
        })

        return () => {
            vizzR1Cleanup()
            vizzR2Cleanup()
            slideR1Cleanup()
            slideR2Cleanup()
            slideR3Cleanup()
            void slidePlayer.cleanUp()
            scaleAnimationValue.current?.stopAnimation()
            translateXAnimationValue.current?.stopAnimation()
            translateYAnimationValue.current?.stopAnimation()
            loopTransitionOpacityValue.current?.stopAnimation()
            void video.current?.unloadAsync()
        }
    }, [])

    // Control

    const onStateChange = async(state: SlidePlaybackState) => {
        switch (state) {
            case SlidePlaybackState.STOPPED: {
                void stopVideo()
                stopAnimation()
                break
            }
            case SlidePlaybackState.PLAYING: {
                play()
                break
            }
            case SlidePlaybackState.LOOP_TRANSITIONING: {
                performLoopTransition()
                break
            }
            case SlidePlaybackState.HOLDING: {
                hold()
                break
            }
            case SlidePlaybackState.ADVANCING: {
                await props.vizzController.slideControl.handleEnd()
                break
            }
        }
    }

    const restartSlide = async() => {
        if (!isMounted) return
        await slidePlayer.cleanUp()
        if (!isMounted) return
        await startSlide()
    }

    const startSlide = async() => {
        try {
            await slidePlayer.start()
        } catch(e) {
            if (isMounted) props.vizzController.slideControl.noaudio()
        }
    }

    const play = () => {
        switch (slidePlayer.activeMedia.assetType) {
            case AssetType.Image: {
                startAnimation()
                break
            }
            case AssetType.Video: {
                void startVideo()
                break
            }
        }
    }

    const performLoopTransition = () => {
        props.vizzController.slideControl.pause()
        Animated.timing(loopTransitionOpacityValue.current, {
            toValue: 1,
            duration: 500,
            useNativeDriver: true,
        }).start(({finished}) => {
            if (!finished) return

            void startSlide()
            props.vizzController.slideControl.restartCurrent()

            Animated.timing(loopTransitionOpacityValue.current, {
                toValue: 0,
                duration: 500,
                useNativeDriver: true,
            }).start()
        })
    }

    const hold = () => {
        props.vizzController.slideControl.pause()
    }

    // Helpers

    const startAnimation = () => {
        resetAnimations()
        animateImage()
    }

    const stopAnimation = () => {
        resetAnimations()
    }

    const resetAnimations = () => {
        scaleAnimationValue.current.setValue(1)
        translateXAnimationValue.current.setValue(0)
        translateYAnimationValue.current.setValue(0)
    }

    const animateImage = () => {
        if (props.slide.zoom) {
            const zoomFactor = (props.slide.duration * 0.015)
            switch (props.slide.zoom) {
                case SlideZoom.IN: {
                    Animated.timing(scaleAnimationValue.current, {
                        toValue: 1 + zoomFactor,
                        duration: props.slide.duration * 1000.0,
                        useNativeDriver: true,
                    }).start()
                    break
                }
                case SlideZoom.OUT: {
                    scaleAnimationValue.current.setValue(1 + zoomFactor)
                    Animated.timing(scaleAnimationValue.current, {
                        toValue: 1,
                        duration: props.slide.duration * 1000.0,
                        useNativeDriver: true,
                    }).start()

                    // ??? For now, zoom out will look incorrect when paired with pan,
                    // so exit this function early if we're doing a zoom out.
                    return
                }
            }
        }

        if (props.slide.panVertical) {
            const panDistance = 50
            const targetValue = props.slide.panVertical == SlidePan.DOWN ? panDistance : (panDistance * -1)
            Animated.timing(translateYAnimationValue.current, {
                toValue: targetValue,
                duration: props.slide.duration * 1000.0,
                useNativeDriver: true,
            }).start()
        }

        if (props.slide.panHorizontal) {
            const panDistance = 50
            const targetValue = props.slide.panHorizontal == SlidePan.RIGHT ? panDistance : (panDistance * -1)
            Animated.timing(translateXAnimationValue.current, {
                toValue: targetValue,
                duration: props.slide.duration * 1000.0,
                useNativeDriver: true,
            }).start()
        }
    }

    const startVideo = async () => {
        try {
            const activeVideo = video?.current

            setVideoPosterVisible(true)
            if (!activeVideo) await new Promise(resolve => setTimeout(resolve, 200))
            if (!activeVideo) await new Promise(resolve => setTimeout(resolve, 500))
            if (!activeVideo) await new Promise(resolve => setTimeout(resolve, 1000))
            if (!activeVideo) await new Promise(resolve => setTimeout(resolve, 1000))
            if (!activeVideo) return

            await activeVideo.setProgressUpdateIntervalAsync(5)
            const avWaiter = new AVPlaybackWaiter(activeVideo)

            await avWaiter.waitForLoad()
            try {
                await activeVideo.playFromPositionAsync(0)
                await avWaiter.waitForPlaybackStart()
            } catch (e) {
                let error = e as Error
                if (error.name == 'NotAllowedError') {
                    console.log(error.toString())
                } else throw e
            }

            setVideoPosterVisible(false)

            props.vizzController.slideControl.resume()
        } catch {
            console.log(`Expo AV error, continuing...`)
            // No-op: On iOS, this generates an exception that may be an Expo bug
            // See https://forums.expo.io/t/bug-unhandled-promise-rejection-error-seeking-interrupted/39283
        }
    }

    const stopVideo = async () => {
        try {
            await video.current?.stopAsync()
        } catch {
            console.log(`Expo AV error, continuing...`)
            // No-op: On iOS, this generates an exception that may be an Expo bug
            // See: https://github.com/expo/expo/issues/3115
        }
    }

    const applyInlineVideoFix = () => {
        if (Platform.OS != 'web') return

        const videos = window.document.getElementsByTagName('video')
        for (let i = 0; i < videos.length; i++) {
            const el = videos.item(i)
            el?.setAttribute('playsInline', 'true')
        }
    }

    // View

    const imageContent = (media: VizzMedia) => {
        return (
            <Animated.Image
                source={{uri: media.assetUrl, cache: 'force-cache'}}
                resizeMode={'cover'}
                style={[
                    styles.media,
                    {
                        transform: [
                            {scale: scaleAnimationValue.current},
                            {translateX: translateXAnimationValue.current},
                            {translateY: translateYAnimationValue.current}
                        ],
                    },
                ]}
            />
        )
    }

    const videoContent = (media: VizzMedia) => {
        return (
            <View style={{flex: 1}}>
                <Video
                    ref={video}
                    style={styles.media}
                    source={{uri: media.assetUrl}}
                    isMuted={!media.videoAudioEnabled}
                    isLooping={shouldLoopVideo()}
                    resizeMode={ResizeMode.STRETCH}
                />
                {videoPosterVisible ?
                    <LavaImage
                        style={styles.videoPoster}
                        source={{uri: media.videoPosterUrl}}
                        resizeMode={'cover'}
                    />
                    : null
                    }
            </View>
        )
    }

    const mainContent = (media: VizzMedia) => {
        switch (media.assetType) {
            case AssetType.Image: {
                return imageContent(media)
            }
            case AssetType.Video: {
                return videoContent(media)
            }
        }
    }

    const normalize = (num: number) => {
        const max = Math.round(num * 1.3)
        const adjusted = Math.round(
            (props.width / 375) * num
        )
        return Math.min(max, adjusted)
    }

    return (
        <View style={styles.container}>
            {mainContent(slidePlayer.activeMedia)}
            <Animated.View style={[styles.loopOverlay, {opacity: loopTransitionOpacityValue.current}]}/>
            <View style={styles.cover}>
                {props.vizzController.currentSlideState == VizzSlideState.BUFFERING &&
                    <ActivityIndicator style={styles.activity} size="large" color="white" />
                }
                {props.vizzController.currentSlideState == VizzSlideState.TIMEOUT &&
                    <PlayButton size={normalize(100)} onPress={() => { console.log('re-do slide.handleEnd()'); void props.vizzController.slideControl.handleEnd() } } disabled={true} />
                }
                {props.vizzController.currentSlideState == VizzSlideState.NOAUDIO &&
                    <PlayButton size={normalize(100)} onPress={() => { if (props.index) { console.log('restartSlide()'); void restartSlide() } }} disabled={true} />
                }
            </View>
        </View>
    )
})

const styles = StyleSheet.create({
    container: {
        overflow: 'hidden',
        flex: 1
    },
    media: {
        flex: 1
    },
    loopOverlay: {
        flex: 1,
        backgroundColor: 'black',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0
    },
    videoPoster: {
        flex: 1,
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0
    },
    cover: {
        ...StyleSheet.absoluteFillObject,
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 200,
    },
    activity: {
        padding: 24,
        borderRadius: 10,
        backgroundColor: 'rgba(0, 0, 0, 0.3)'
    }
})

export default Slide
