import React from 'react'
import {StyleSheet, View} from 'react-native'
import {observer} from "mobx-react"
import {ProgressBar, TouchableRipple} from "react-native-paper"
import {Swiper} from './Swiper'
import {VizzSlideModel} from '../../models/VizzModel'
import {Color} from '../../../../app/lib/Appearance'
import VizzController, {VizzSlideState} from '../../controllers/VizzController'
import Slide from './slides/Slide'

type Props = {
    vizzController: VizzController
    width: number
    height: number
    showProgress?: boolean
    disableStop?: boolean
}

const Slides = observer((props: Props) => {

    if (props.vizzController.vizz.slides.length == 0)
        return (null)
    else
        return (
            <View style={StyleSheet.absoluteFillObject}>
                <Swiper
                    vertical
                    ref={props.vizzController.swiperRef}
                    controlsEnabled={!props.showProgress}
                    controlsProps={{
                        dotActiveStyle: {backgroundColor: Color.secondary},
                        prevTitleStyle: {color: Color.secondary, fontWeight: 'bold'},
                        nextTitleStyle: {color: Color.secondary, fontWeight: 'bold'},
                        prevTitle: '',
                        nextTitle: ''
                    }}
                    springConfig={{
                        overshootClamping: true,
                        speed: 35
                    }}
                    onIndexChanged={(index: number) => props.vizzController.slideControl.changedTo(index)}>
                    {props.vizzController.vizz.slides.map((slide: VizzSlideModel) => {
                        return <Slide slide={slide} key={slide.id} vizzController={props.vizzController} width={props.width} height={props.height} />
                    })}
                </Swiper>
                {props.vizzController.currentSlideState != VizzSlideState.TIMEOUT &&
                 props.vizzController.currentSlideState != VizzSlideState.NOAUDIO &&
                 !props.disableStop &&
                    <TouchableRipple
                        style={styles.tappableOverlay}
                        onPress={() => props.vizzController.changeState.stop('user-tapped')}
                        onLongPress={() => props.vizzController.changeState.stop('user-tapped')}>
                        <View />
                    </TouchableRipple>
                }
                {props.showProgress &&
                <ProgressBar style={styles.progress} color={'white'} progress={props.vizzController.playbackProgress} />}
            </View>
        )
})

const styles = StyleSheet.create({
    tappableOverlay: {
        ...StyleSheet.absoluteFillObject,
        position: 'absolute',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1
    },
    progress: {
        position: 'absolute',
        left: 16,
        right: 16,
        bottom: 16
    }
})

export default Slides
