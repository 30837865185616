import React, {useContext, useEffect, useRef, useState} from 'react'
import {StyleProp, StyleSheet, View, ViewStyle} from 'react-native'
import {observer} from "mobx-react"
import {VizzModel} from "../models/VizzModel"
import CurrentAccountContext from '../../vizz_account/lib/CurrentAccount'
import Slides from './vizz/Slides'
import {Swiper} from './vizz/Swiper'
import {ConceptViewModel} from '../../../app/views/web_navigation/feed/ConceptNavigator'
import {reaction} from 'mobx'
import VizzController, {VizzControllerState} from '../controllers/VizzController'
import Cover from './vizz/Cover'

type Props = {
    style?: StyleProp<ViewStyle>
    vizz: VizzModel
    concept?: ConceptViewModel
    showInlineTitle?: boolean
    preload?: boolean
    disableStop?: boolean
    showProgress?: boolean
    play?: boolean
    onPlayedToEnd?: (() => void)
    onStarted?: (() => void)
    onChangeState?: ((state: string) => void)
}

const Vizz = observer((props: Props) => {
    const suffix = useRef<number>(Math.round(Math.random()*10000))
    const swiperRef = useRef<Swiper>(null)
    const currentAccount = useContext(CurrentAccountContext)
    // v This is equiv to useRef. But we use a useState because it supports lazy loading.
    // If we use useRef and newVizzPlayer() will be instantiated and discarded on every refresh which is bad.
    const [controller] = useState<VizzController>(() => new VizzController(props.vizz, swiperRef, currentAccount, suffix.current.toString(), props.concept))
    const [dimensions, setDimensions] = useState({width: 0, height: 0})

    useEffect(() => {
        if (props.onPlayedToEnd) controller.onVizzEnd = props.onPlayedToEnd
        if (props.onStarted) controller.onVizzStart = props.onStarted


        const cleanupVizzPlayerStateReaction = reaction(() => controller.state, (state) => {
            if (!props.onChangeState) return

            if (state == VizzControllerState.PLAYING) props.onChangeState('playing')
            if (state == VizzControllerState.STOPPED) props.onChangeState('stopped')
        })

        return () => {
            void controller.changeState.uninitialize()
            cleanupVizzPlayerStateReaction()
        }
    }, [])

    useEffect(() => {
        if (props.preload != false) controller.changeState.loadEventually()

    }, [props.preload])

    useEffect(() => {
        if (props.play == true) controller.changeState.playEventually()
        if (props.play == false) void controller.changeState.stop()
    }, [props.play])

    return (
        <View nativeID={`${suffix.current.toString()}${props.vizz.id}`} style={[styles.container, props.style]} onLayout={(event) => {
            const {width, height} = event.nativeEvent.layout
            setDimensions({width: width, height: height})
        }}>
            <Slides vizzController={controller} width={dimensions.width} height={dimensions.height} showProgress={props.showProgress} disableStop={props.disableStop} />
            <Cover vizzController={controller} width={dimensions.width} height={dimensions.height} showInlineTitle={props.showInlineTitle} />
        </View>
    )
})

const styles = StyleSheet.create({
    container: {
        flex: 1,
        overflow: 'hidden',
        backgroundColor: 'black',
    },
    cover: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
    },
})

export default Vizz
