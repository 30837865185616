import React, {useContext, useEffect, useState} from 'react'
import {StyleSheet, View, ViewStyle, Text, Dimensions} from "react-native"
import {ActivityIndicator} from "react-native-paper"
import {observer} from "mobx-react"
import {IndexController, IndexState} from "../../../controllers/library_widget/book/IndexController"
import {Color, isTablet, Style} from "../../../../../app/lib/Appearance"
import CurrentAccountContext from "../../../../vizz_account/lib/CurrentAccount"
import AppControllerContext from '../../../../../app/controllers/AppController'

type Props = {
    style?: ViewStyle
    searchBarHeight: number
}

export const Index = observer((props: Props) => {
    const currentAccount = useContext(CurrentAccountContext)
    const appController = useContext(AppControllerContext)
    const [controller] = useState<IndexController>(() => new IndexController(currentAccount, appController))

    useEffect(() => {
        void controller.initialize()

        return () => {
            controller.uninitialize()
        }
    }, [])

    const renderLoading = () => {
        return (
            <View style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}>
                <ActivityIndicator color={Color.primary} />
            </View>
        )
    }

    return (
        <View style={[styles.container, props.style,
            isTablet() ?
                {
                    width: Math.round(Dimensions.get('screen').width * Style.Book.indexWidthPerct),
                    height: '100%',
                }
            :
                {
                    width: '100%',
                    marginBottom: props.searchBarHeight,
                    flexGrow: 1,
                }
            ]}>
            {controller.state == IndexState.LOADING && renderLoading()}
            {controller.state == IndexState.LOADED &&
            <View></View>
            }
        </View>
    )
})

const styles = StyleSheet.create({
    container: {
    },
})
