import {makeObservable, computed} from "mobx"
import Data from "./Data"

class DayData extends Data {
    constructor() {
        super({
            'DayData': [
                { 'numberOfVideosTasted': { 'number': 0 }},
                { 'numberOfVideosStarted': { 'number': 0 }},
                { 'numberOfSearches': { 'number': 0 }},
                { 'numberOfConceptsOpened': { 'number': 0 }},
            ]
        })
        makeObservable(this)
    }

    @computed
    get numberOfVideosTasted() { return this.get('numberOfVideosTasted') as number }
    set numberOfVideosTasted(val: number) { this.set('numberOfVideosTasted', val) }

    @computed
    get numberOfVideosStarted() { return this.get('numberOfVideosStarted') as number }
    set numberOfVideosStarted(val: number) { this.set('numberOfVideosStarted', val) }

    @computed
    get numberOfSearches() { return this.get('numberOfSearches') as number }
    set numberOfSearches(val: number) { this.set('numberOfSearches', val) }

    @computed
    get numberOfConceptsOpened() { return this.get('numberOfConceptsOpened') as number }
    set numberOfConceptsOpened(val: number) { this.set('numberOfConceptsOpened', val) }
}

export default DayData
