import {CurrentAccount} from "../../modules/vizz_account/lib/CurrentAccount"
import {AppController} from "../controllers/AppController"
import {AgeBand} from "../lib/services/AgeBand"

export class FirebaseService {

    public static async logAppOpen(appController: AppController, currentAccount: CurrentAccount) {
    }

    public static async logAppInstall(appController: AppController, currentAccount: CurrentAccount) {
    }

    public static async logChildAppOpen(appController: AppController, currentAccount: CurrentAccount, ageBand: AgeBand) {
    }
}
