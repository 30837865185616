import Constants from "expo-constants"

export class ManifestUtils {

    static get env() {
        return Constants.expoConfig?.extra?.reactEnv ?? Constants.expoConfig?.extra?.expoClient?.extra?.reactEnv ?? 'development'
    }

    static get clientUpdateNumber():number {
        return parseInt(Constants.expoConfig?.extra?.updateNumber ?? Constants.expoConfig?.extra?.expoClient?.extra?.updateNumber ?? 0)
    }

    static get tunnelHost() {
        return Constants.expoConfig?.extra?.tunnelHost ?? Constants.expoConfig?.extra?.expoClient?.extra?.tunnelHost ?? 'localtest.me:3000'
    }

    static get railsApiDomain() {
        return Constants.expoConfig?.extra?.railsApiDomain ?? Constants.expoConfig?.extra?.expoClient?.extra?.railsApiDomain ?? 'lava.co'
    }

    static get railsApiRoutes() {
        return Constants.expoConfig?.extra?.railsApiRoutes ?? Constants.expoConfig?.extra?.expoClient?.extra?.railsApiRoutes
    }

    static get reactEnv() {
        return Constants.expoConfig?.extra?.reactEnv ?? Constants.expoConfig?.extra?.expoClient?.extra?.reactEnv ?? 'development'
    }

    static get expoSlug() {
        return Constants.expoConfig?.extra?.expoSlug ?? Constants.expoConfig?.extra?.expoClient?.extra?.expoSlug ?? 'lava-dev'
    }

    static get clientVersion() {
        return Constants.expoConfig?.version ?? Constants.expoConfig?.extra?.expoClient?.extra?.version
    }
}