import React, {useContext, useEffect, useState} from 'react'
import {ScrollView, StyleSheet, View} from "react-native"
import {Color} from "../../lib/Appearance"
import CurrentAccountContext from "../../../modules/vizz_account/lib/CurrentAccount"
import {useNavigation} from "@react-navigation/native"
import {ActivityIndicator, Button, Chip, DataTable, Headline, HelperText, Menu, TextInput} from 'react-native-paper'
import {VizzSource, VizzState} from "../../lib/models/VizzSource"
import {ContentTypeFilter, ContentTypeFilterUtil, VizzStateUtil} from '../../utils/EnumUtils'
import {format} from 'date-fns'
import {MaterialIcons} from '@expo/vector-icons'
import SecureStorage from "../../lib/utils/SecureStorage"

enum StateFilterType {
    myAll = 'myAll',
    myPublished = 'myPublished',
    myDraft = 'myDraft',
    all = 'all',
    allPublished = 'allPublished',
    allDraft = 'allDraft'
}

class StateFilterTypeUtil {
    static title(stateFilterType: StateFilterType|undefined): string|undefined {
        switch (stateFilterType) {
            case StateFilterType.all: return "All Vizzes"
            case StateFilterType.allPublished: return "All Published"
            case StateFilterType.allDraft: return "All Draft"
            case StateFilterType.myAll: return "My Vizzes"
            case StateFilterType.myPublished: return "My Published"
            case StateFilterType.myDraft: return "My Drafts"
        }
    }
}

export default function MakerDashboardScreen() {

    const currentAccount = useContext(CurrentAccountContext)
    const navigation = useNavigation<any>()
    const [loading, setLoading] = useState<boolean>(false)
    const [responseMessage, setResponseMessage] = useState<string>('')
    const [searchQuery, setSearchQuery] = useState<string>('')
    const [numberOfResults, setNumberOfResults] = useState<number>(0)
    const [selectedContentTypeFilter, setSelectedContentTypeFilter] = useState<ContentTypeFilter|undefined>(undefined)
    const [selectedStateFilter, setSelectedStateFilter] = useState<StateFilterType|undefined>(undefined)
    const [stateFilterMenuActive, setStateFilterMenuActive] = useState<boolean>(false)
    const [vizzes, setVizzes] = useState<VizzSource[]>([])

    const requestLimit = 200
    const numberOfItemsPerPage = 20
    const [page, setPage] = React.useState(0)
    const from = page * numberOfItemsPerPage
    const to = Math.min((page + 1) * numberOfItemsPerPage, vizzes.length)

    const currentPageVizzes = () => {
        const offset = page * numberOfItemsPerPage
        const results =  vizzes.slice(offset, offset + numberOfItemsPerPage)
        return results
    }

    useEffect(() => {
        ensureLoggedIn()

        async function init() {
            const vizzFilterFromStorage = await SecureStorage.get('makerSelectedVizzContentTypeFilter')

            if (typeof vizzFilterFromStorage === 'string' && vizzFilterFromStorage != null) {
                setSelectedContentTypeFilter(vizzFilterFromStorage as ContentTypeFilter)
            }
            else {
                setSelectedContentTypeFilter(ContentTypeFilter.all)
            }

            const filterFromStorage = await SecureStorage.get('makerSelectedStateFilter')

            if (typeof filterFromStorage === 'string' && filterFromStorage != null) {
                setSelectedStateFilter(filterFromStorage as StateFilterType)
            }
            else {
                setSelectedStateFilter(StateFilterType.myAll)
            }
        }
        void init()

    }, [])

    useEffect(() => {
        if (selectedContentTypeFilter && selectedStateFilter) {
            try {
                void SecureStorage.set('makerSelectedVizzContentTypeFilter', selectedContentTypeFilter)
                void SecureStorage.set('makerSelectedStateFilter', selectedStateFilter)
                void refresh()
            } catch(e) {
                console.log(`Error while updating filter selection ${e}`)
            }
        }
    }, [selectedStateFilter, selectedContentTypeFilter])

    const ensureLoggedIn = () => {
        if (!currentAccount.isLoggedIn) {
            navigation.navigate('LogIn')
            return
        }

        setLoading(false)
    }

    const refresh = async () => {
        setLoading(true)

        if (searchQuery.length > 0 && !isNaN(parseInt(searchQuery))) {
            // reset filters if the search is for an ID
            setSelectedStateFilter(StateFilterType.all)
            setSelectedContentTypeFilter(ContentTypeFilter.all)
        }

        try {
            let encodedSearchQuery = searchQuery.replace(/'/g, "%27")
            let vizzesResult: VizzSource[] = []
            switch (selectedStateFilter) {
                case StateFilterType.all: {
                    vizzesResult = await currentAccount.api.get(`vizz_maker.all_vizzes_path`, {
                        content_type: ContentTypeFilterUtil.apiParam(selectedContentTypeFilter),
                        search_query: encodedSearchQuery,
                        limit: requestLimit
                    }) as VizzSource[]
                    break
                }
                case StateFilterType.allDraft: {
                    vizzesResult = await currentAccount.api.get(`vizz_maker.all_vizzes_path`, {
                        state: VizzState.draft, content_type: ContentTypeFilterUtil.apiParam(selectedContentTypeFilter),
                        search_query: encodedSearchQuery,
                        limit: requestLimit
                    }) as VizzSource[]
                    break
                }
                case StateFilterType.allPublished: {
                    vizzesResult = await currentAccount.api.get(`vizz_maker.all_vizzes_path`, {
                        state: VizzState.published, content_type: ContentTypeFilterUtil.apiParam(selectedContentTypeFilter),
                        search_query: encodedSearchQuery,
                        limit: requestLimit
                    }) as VizzSource[]
                    break
                }
                case StateFilterType.myAll: {
                    vizzesResult = await currentAccount.api.get(`vizz_maker.my_all_path`, {
                        content_type: ContentTypeFilterUtil.apiParam(selectedContentTypeFilter),
                        search_query: encodedSearchQuery,
                        limit: requestLimit
                        }) as VizzSource[]
                    break
                }
                case StateFilterType.myDraft: {
                    vizzesResult = await currentAccount.api.get(`vizz_maker.my_all_path`, {
                        state: VizzState.draft, content_type: ContentTypeFilterUtil.apiParam(selectedContentTypeFilter),
                        search_query: encodedSearchQuery,
                        limit: requestLimit
                        }) as VizzSource[]
                    break
                }
                case StateFilterType.myPublished: {
                    vizzesResult = await currentAccount.api.get(`vizz_maker.my_all_path`, {
                        state: VizzState.published,
                        content_type: ContentTypeFilterUtil.apiParam(selectedContentTypeFilter),
                        search_query: encodedSearchQuery,
                        limit: requestLimit
                        }) as VizzSource[]
                    break
                }
            }

            setNumberOfResults(vizzesResult.length)
            setVizzes(vizzesResult)
            setPage(0)
            setLoading(false)
            setResponseMessage("")
        } catch (err) {
            console.log(`Error fetching vizzes ${err}`)
            setResponseMessage("Error fetching vizzes")
        }
    }

    const viewVizz = (vizzId: string) => {
        navigation.navigate("VizzDetail", {vizzId: vizzId})
    }

    const editVizz = (vizzKey: string) => {
        navigation.navigate("EditVizz", {vizzKey: vizzKey})
    }

    const clearAllFlagged = async () => {
        const confirmed = confirm('Are you sure you want to clear all flags?')
        if (confirmed) {
            await currentAccount.api.post('vizz_maker.clear_all_flagged', {})
            await refresh()
        }

    }

    return (
        <ScrollView style={styles.container}>
            <View style={{flexDirection: 'row', alignItems: 'center', marginBottom: 16}}>
                <Headline style={{flexGrow: 1}}>Maker Dashboard</Headline>
                <View style={{flexDirection: 'row', alignItems: 'center', marginBottom: 16}}>
                    <Button
                        color={Color.white}
                        disabled={loading}
                        mode={'contained'}
                        style={{marginRight: 10}}
                        onPress={() => navigation.navigate('RecentVizzes')}>
                        Recent Vizzes
                    </Button>
                    <Button
                        color={Color.white}
                        disabled={loading}
                        mode={'contained'}
                        style={{marginRight: 10}}
                        onPress={() => navigation.navigate('Pronunciations')}>
                        Pronunciations
                    </Button>
                    <Button
                        color={Color.white}
                        disabled={loading}
                        mode={'contained'}
                        style={{marginRight: 10}}
                        onPress={() => navigation.navigate('IdentificationResults')}>
                        Identification Results
                    </Button>
                    <Button
                        color={Color.primary}
                        disabled={loading}
                        mode={'contained'}
                        style={{marginRight: 10}}
                        onPress={() => navigation.navigate('EditVizz')}>
                        New Blank Vizz
                    </Button>
                    <Button
                        color={Color.primary}
                        disabled={loading}
                        mode={'contained'}
                        onPress={() => navigation.navigate('GenerateVizz')}>
                        Generate Vizz
                    </Button>
                </View>
            </View>

            <View style={{flexDirection: 'row', flexGrow: 1, alignItems: 'center'}}>
                {Object.values(ContentTypeFilter).map((f) => {
                    return (
                        <Chip
                            key={f}
                            selected={f == selectedContentTypeFilter}
                            onPress={() => {setSelectedContentTypeFilter(f as ContentTypeFilter)}}>
                            {ContentTypeFilterUtil.title(f as ContentTypeFilter)}
                        </Chip>
                    )
                })}
                <Button
                    color={Color.white}
                    disabled={loading}
                    mode={'contained'}
                    style={{marginLeft: 10}}
                    onPress={clearAllFlagged}>
                    Clear All Flagged
                </Button>

                <View style={{flex: 1, flexGrow: 1}}/>
                <Menu
                    visible={stateFilterMenuActive}
                    onDismiss={() => {setStateFilterMenuActive(false)}}
                    anchor={<Button
                        mode={'outlined'}
                        compact={true}
                        uppercase={false}
                        icon={() => <MaterialIcons name="arrow-drop-down" size={24} color={Color.primary} />}
                        onPress={() => {setStateFilterMenuActive(true)}}>
                        {StateFilterTypeUtil.title(selectedStateFilter)}
                    </Button>}>
                    {Object.values(StateFilterType).map((f) => {
                        return (
                            <Menu.Item
                                key={f}
                                title={StateFilterTypeUtil.title(f as StateFilterType)}
                                icon={ () => {return f as StateFilterType == selectedStateFilter ?
                                    <MaterialIcons name="radio-button-checked" size={24} color={Color.primary} /> :
                                    <MaterialIcons name="radio-button-unchecked" size={24} />}}
                                onPress={() => {
                                    setSelectedStateFilter(f as StateFilterType)
                                    setStateFilterMenuActive(false)
                                }}
                                 />
                        )
                    })}
                </Menu>
            </View>

            <View style={{margin: 10, flex: 1, flexDirection: "row", alignItems: "center"}}>
                <TextInput
                    value={searchQuery}
                    mode='outlined'
                    onChangeText={(value) => setSearchQuery(value)}
                    onSubmitEditing={() => void refresh()}
                    label='Search query'>
                </TextInput>
                <Button mode='contained' style={{margin: 10}} onPress={() => {
                        void refresh()
                    }}>Search</Button>
            </View>
            <View style={{flex: 1, flexDirection: "row"}}>
                {loading ? <ActivityIndicator style={{marginStart: 16}} color={Color.secondary}/> : null}
                <HelperText style={{margin: 20}} type={'info'} visible={true}>{numberOfResults} results</HelperText>
            </View>

            <DataTable>
                <DataTable.Pagination
                    page={page}
                    numberOfPages={Math.ceil(vizzes.length / numberOfItemsPerPage)}
                    onPageChange={page => setPage(page)}
                    label={`${from + 1}-${to} of ${vizzes.length}`}
                    showFastPaginationControls
                    numberOfItemsPerPage={numberOfItemsPerPage}
                    selectPageDropdownLabel={'Rows per page'}
                />

                <DataTable.Header>
                    <DataTable.Title>Title</DataTable.Title>
                    <DataTable.Title>Status</DataTable.Title>
                    <DataTable.Title>Last Modified</DataTable.Title>
                    <DataTable.Title> </DataTable.Title>
                    <DataTable.Title> </DataTable.Title>
                </DataTable.Header>

                {currentPageVizzes().map((vizzSource) => {
                    return (
                        <DataTable.Row
                            key={vizzSource.key}>
                            <DataTable.Cell>{vizzSource.title}</DataTable.Cell>
                            <DataTable.Cell>{ VizzStateUtil.title(vizzSource.state)}</DataTable.Cell>
                            <DataTable.Cell>{format(new Date(vizzSource.updated_at), "MMM d, yyyy 'at' hh:mm a")}</DataTable.Cell>
                            <DataTable.Cell>
                                <Button mode={'text'} onPress={() => {
                                    viewVizz(vizzSource.processed_json.id)
                                }}>Preview</Button>
                            </DataTable.Cell>
                            <DataTable.Cell>
                                <Button mode={'text'} onPress={() => {
                                    editVizz(vizzSource.key)
                                }}>Edit</Button>
                            </DataTable.Cell>
                        </DataTable.Row>
                    )
                })}

            </DataTable>


            <View style={styles.buttonContainer}>
                {loading ? <ActivityIndicator style={{marginStart: 16}} color={Color.secondary}/> : null}
                <HelperText type={'info'} visible={true}>{responseMessage}</HelperText>
            </View>

        </ScrollView>
    )
}
const styles = StyleSheet.create({
    container: {
        margin: 16
    },
    header: {
        fontSize: 36
    },
    textInput: {
        flex: 1,
        marginBottom: 16
    },
    buttonContainer: {
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        margin: 8
    }
})
