import React, {useEffect, useRef} from 'react'
import {StyleSheet, View} from 'react-native'
import {observer} from "mobx-react"
import {Headline} from "react-native-paper"
import {LinearGradient} from "expo-linear-gradient"
import {TiledImageLoading} from "./TiledImageLoading"
import {reaction} from "mobx"
import VizzController, {VizzControllerState} from '../../controllers/VizzController'
import VizzAudioService from '../../services/VizzAudioService'
import {SoundUtils} from '../../../../app/utils/AVUtils'
import {PlayButton} from '../../../../app/views/components/PlayButton'
import {LavaImage} from "../../../../app/views/components/LavaImage"

type Props = {
    vizzController: VizzController
    width: number
    height: number
    showInlineTitle?: boolean
}

const Cover = observer((props: Props) => {

    const hasPlayedIntroSound = useRef(false)
    const introSound = useRef<VizzAudioService>()

    useEffect(() => {
        const disposeR1 = reaction(() => props.vizzController.vizz, (vizz) => {
            if (props.vizzController.state == VizzControllerState.LOADING && props.vizzController.vizz.loadingMessageUrl && !hasPlayedIntroSound.current) {
                hasPlayedIntroSound.current = true
                introSound.current = SoundUtils.playSound({uri: props.vizzController.vizz.loadingMessageUrl})
                props.vizzController.recordIntroPlayed()
            }
        })

        const disposeR2 = reaction(() => props.vizzController.state, (state) => {
            if (state != VizzControllerState.LOADING) {
                void introSound.current?.changeState.pauseAndUnload()
            }
        })

        return () => {
            disposeR1()
            disposeR2()
        }
    }, [])

    const normalize = (num: number) => {
        const max = Math.round(num * 1.3)
        const adjusted = Math.round(
            (props.width / 375) * num
        )
        return Math.min(max, adjusted)
    }

    const renderCover = (opacity: number = 1.0) => {
        if (!props.vizzController.vizz.coverImageUrl) return null
        return (
            <View style={styles.cover}>
                <View style={[styles.coverImage, {backgroundColor: 'black', zIndex: 200}]}></View>

                {props.vizzController.vizz.coverImageUrl &&
                    <LavaImage style={[styles.coverImage, {zIndex: 201, opacity: opacity}]} source={{uri: props.vizzController.vizz.coverImageUrl}} resizeMode={'cover'}/>
                }


                {props.showInlineTitle &&
                <LinearGradient style={[styles.titleGradient]}
                                colors={['rgba(0, 0, 0, 0)', 'rgba(0, 0, 0, 0.6)']}>

                    <Headline style={[styles.title]}>{props.vizzController.vizz.title}</Headline>
                </LinearGradient>
                }
            </View>
        )
    }

    const renderUninitialized = () => {
        return (
            <React.Fragment>
                {props.vizzController.vizz.coverImageUrl && renderCover()}
                <View style={[styles.playContainer]}>
                    <PlayButton size={normalize(100)} onPress={() => props.vizzController.changeState.loadAndPlay()} disabled={true} />
                </View>
            </React.Fragment>
        )
    }

    const renderStopped = () => {
        return (
            <React.Fragment>
                {props.vizzController.vizz.coverImageUrl && renderCover()}
                <View style={[styles.playContainer]}>
                    <PlayButton size={normalize(100)} onPress={props.vizzController.changeState.playFromStart} />
                </View>
            </React.Fragment>
        )
    }

    const renderLoading = () => {
        if (!props.vizzController.vizz.coverImageUrl) {
            return null
        }
        return (
            <React.Fragment>
                <TiledImageLoading
                imageUrl={props.vizzController.vizz.coverImageUrl}
                numColumns={8}
                numRows={6}
                durationMS={2000} />
            </React.Fragment>
        )
    }

    const renderGenerating = () => {
        if (!props.vizzController.vizz.coverImageUrl) {
            return null
        }
        return (
            <TiledImageLoading
                imageUrl={props.vizzController.vizz.coverImageUrl}
                numColumns={8}
                numRows={6}
                durationMS={8000} />
        )
    }

    switch (props.vizzController.state) {
        case VizzControllerState.UNINITIALIZED: return renderUninitialized()
        case VizzControllerState.INITIALIZING: return renderLoading()
        case VizzControllerState.INITIALIZED: return renderLoading()
        case VizzControllerState.LOADING: return renderGenerating()
        case VizzControllerState.STOPPED: return renderStopped()
        case VizzControllerState.PLAYING: return null
        default: return null
    }
})

const styles = StyleSheet.create({
    cover: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 100
    },

    coverImage: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
    },

    titleGradient: {
        ...StyleSheet.absoluteFillObject,
        justifyContent: 'flex-end',
        alignItems: 'flex-start'
    },

    title: {
        color: 'white',
        marginStart: 16,
        marginBottom: 32,
    },

    playContainer: {
        ...StyleSheet.absoluteFillObject,
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 200,
    }
})

export default Cover
