/* eslint-disable */

import {createDrawerNavigator} from '@react-navigation/drawer'
import {createStackNavigator} from '@react-navigation/stack'
import {RootDrawerNavigatorList} from '../lib/interfaces/root-tab-navigator'
import {RootStackNavigatorList} from '../lib/interfaces/root-stack-navigator'
import {NavigationContainer, useNavigation} from '@react-navigation/native'
import React, {useContext} from 'react'
import CurrentAccountContext from '../../modules/vizz_account/lib/CurrentAccount'
import {Platform, Text, View} from 'react-native'
import VizzDetailScreen from "./web_navigation/VizzDetail"
import LoginScreen from './web_navigation/Login'
import LogoutScreen from './web_navigation/Logout'
import SpeechScreen from './web_navigation/Speech'
import MakerDashboardScreen from "./web_navigation/MakerDashboard"
import EditVizzScreen from "./web_navigation/EditVizz"
import IdentificationResultDetailsScreen from "./web_navigation/IdentificationResultDetails"
import JsonDebugScreen from "./web_navigation/JsonDebug"
import CollectionDetail from "./web_navigation/CollectionDetail"
import SingleVizzWebScreen from "./web_navigation/SingleVizzWebScreen"
import EditConceptsScreen from "./web_navigation/EditConcepts"
import {DebugScreen} from "../views/Debug"
import LandingScreen from './web_navigation/Landing'
import {FeedScreenNavigationProp, FeedScreenRouteProp} from "./web_navigation/Feed"
import {Color} from '../lib/Appearance'
import {observer} from 'mobx-react'
import {IconButton} from "react-native-paper"
import GenerateVizzScreen from './web_navigation/GenerateVizz'
import RecentVizzesScreen from './web_navigation/RecentVizzes'
import IdentificationResultsScreen from './web_navigation/IdentificationResults'
import PronunciationsScreen from './web_navigation/Pronunciations'
import CollectionsScreen from './web_navigation/Collections'
import CollectionFeedScreen from './web_navigation/CollectionFeed'
import FeedWithSearchScreen from './web_navigation/FeedWithSearch'


export const WebNavigation = observer(() => {
    const currentAccount = useContext(CurrentAccountContext)

    const Drawer = createDrawerNavigator<RootDrawerNavigatorList>()
    const Stack = createStackNavigator<RootStackNavigatorList>()

    type Props = {
        route: FeedScreenRouteProp
        navigation: FeedScreenNavigationProp
    }

    const FirstScreen = observer(() => {
        const currentAccount = useContext(CurrentAccountContext)
        const navigation = useNavigation<any>()

        if (currentAccount.isLoggedIn) {
            return (<View><Text>You are logged in, app is disabled.</Text></View>)
        } else {
            return (<LandingScreen />)
        }
    })

    const linking = {
        prefixes: ['http://', 'https://'],
        config: {
            screens: {
                Maker: {
                    path: "m",
                    screens: {
                        MakerDashboard: "dashboard",
                        EditVizz: "edit-vizz/:vizzKey?",
                        RecentVizzes: "recent-vizzes",
                        IdentificationResults: "identification-results",
                        Pronunciations: "pronunciations",
                        GenerateVizz: "generate-vizz",
                        Speech: "speech",
                        VizzDetail: "vizz/:vizzId",
                        EditConcepts: 'concepts'
                    }
                },
                Auth: {
                    path: "a",
                    screens: {
                        LogIn: "login",
                        LogOut: 'logout'
                    }
                },
                CollectionFeed: {
                    path: 'homev3',
                    screens: {
                        FeedList: '',
                        VizzDetail: "vizz/:vizzId",
                        SingleVizzWeb: "web-vizz/:vizzId",
                        ConceptNavigator: "concepts/:conceptId"
                    }
                },
                Collections: {
                    path: 'homev2',
                    screens: {
                        CollectionsList: 'all',
                        VizzDetail: "vizz/:vizzId"
                    }
                },
                HomeWithSearch: {
                    path: 'homewithsearch',
                    screens: {
                        FeedList: '',
                        VizzDetail: "vizz/:vizzId",
                        SingleVizzWeb: "web-vizz/:vizzId",
                        ConceptNavigator: "concepts/:conceptId"
                    }
                },
                Home: {
                    path: '',
                    screens: {
                        FeedList: '',
                        VizzDetail: "vizz/:vizzId",
                        SingleVizzWeb: "web-vizz/:vizzId",
                        ConceptNavigator: "concepts/:conceptId"
                    }
                }
            }
        }
    }

    const drawerButton = (navigation: any) => {
        return (
            <IconButton icon={'menu'} color={'white'} onPress={() => {navigation.toggleDrawer()}}/>
        )
    }

    const stackNav = (navigation: any, initialRoute: any) => {
        const currentAccount = useContext(CurrentAccountContext)

        return (
            <Stack.Navigator
                initialRouteName={initialRoute}
                // headerMode={'float'}
                screenOptions={{
                    headerStyle: {
                        backgroundColor: Color.primary,
                    },
                    // headerTitle: AppLogo,
                    headerTintColor: '#000'
                }}>
                <Stack.Screen
                    name='LogIn'
                    options={{title: "Log In"}}
                    component={LoginScreen}/>
                <Stack.Screen
                    name="LogOut"
                    options={{title: "Log Out", headerRight: () => drawerButton(navigation)}}
                    component={LogoutScreen}/>
                <Stack.Screen name="VizzDetail" component={VizzDetailScreen}/>
                <Stack.Screen
                    name="MakerDashboard"
                    options={{title: "Maker Dashboard", headerRight: () => drawerButton(navigation)}}
                    component={MakerDashboardScreen}/>
                <Stack.Screen name="EditVizz" options={{title: "Edit Vizz"}} component={EditVizzScreen}/>
                <Stack.Screen
                    name="Speech"
                    options={{title: "Speech Demo", headerRight: () => drawerButton(navigation)}}
                    component={SpeechScreen}/>
                <Stack.Screen name="RecentVizzes" options={{title: "Recent Vizzes"}} component={RecentVizzesScreen}/>
                <Stack.Screen name="IdentificationResults" options={{title: "Identification Results"}} component={IdentificationResultsScreen}/>
                <Stack.Screen name="IdentificationResultDetails" options={{title: "Identification Result Details"}} component={IdentificationResultDetailsScreen}/>
                <Stack.Screen name="Pronunciations" options={{title: "Pronunciations"}} component={PronunciationsScreen}/>
                <Stack.Screen name="GenerateVizz" options={{title: "Generate Vizz"}} component={GenerateVizzScreen}/>
                <Stack.Screen name="CollectionDetail" options={{title: "Collection Detail"}} component={CollectionDetail}/>
                <Stack.Screen
                    name="Debug"
                    options={{title: "Debug", headerRight: () => drawerButton(navigation)}}
                    component={DebugScreen}/>

                <Stack.Screen name="SingleVizzWeb"
                              component={SingleVizzWebScreen}
                              options={{headerShown: false}}
                />
                <Stack.Screen
                    name="EditConcepts"
                    options={{title: "Edit Concepts", headerRight: () => drawerButton(navigation)}}
                    component={EditConceptsScreen}/>

                {/*Feeds*/}

                {/*Feeds Screens need to be refactored to use useNavigation hook so navigation props are not required.*/}
                <Stack.Screen name="CollectionsList"
                              options={{
                                  headerShown: currentAccount.isLoggedIn || !(Platform.OS == 'web'),
                                  title: "Home v2",
                                  headerRight: () => drawerButton(navigation)}}
                              component={CollectionsScreen}/>

                <Stack.Screen name="CollectionFeedList"
                              options={{
                                  headerShown: currentAccount.isLoggedIn || !(Platform.OS == 'web'),
                                  title: "Home v3",
                                  headerRight: () => drawerButton(navigation)}}
                              component={CollectionFeedScreen}/>

                <Stack.Screen name="FeedWithSearchList"
                              options={{
                                  headerShown: false,
                                  title: "Home w/ Search" }}
                              component={FeedWithSearchScreen}/>

                <Stack.Screen name="FeedList"
                              options={{
                                  headerShown: false,
                                  title: "Home" }}
                              component={FirstScreen}/>
                {/*Modals*/}

                <Stack.Screen
                    name="JsonDebug"
                    options={{
                        headerTitle: "View Json",
                        title: "View Json",
                        headerRight: () => null
                    }}
                    component={JsonDebugScreen}/>
            </Stack.Navigator>
        )
    }

    return (
        <NavigationContainer linking={linking}>
            <Drawer.Navigator screenOptions={{headerShown: false}}>
                <Drawer.Screen name="Home" options={{drawerLabel: 'Home'}}>
                    {props => stackNav(props.navigation, 'FeedList')}
                </Drawer.Screen>

                <Drawer.Screen name="HomeWithSearch" options={{drawerLabel: 'Home w/ Search'}}>
                    {props => stackNav(props.navigation, 'FeedWithSearchList')}
                </Drawer.Screen>

                <Drawer.Screen name="CollectionFeed" options={{drawerLabel: 'Home v3'}}>
                    {props => stackNav(props.navigation, 'CollectionFeedList')}
                </Drawer.Screen>

                <Drawer.Screen name="Collections" options={{drawerLabel: 'Home v2'}}>
                    {props => stackNav(props.navigation, 'CollectionsList')}
                </Drawer.Screen>

                <Drawer.Screen name="LegacyFeed" options={{drawerLabel: 'Home v1'}}>
                    {props => stackNav(props.navigation, 'LegacyFeedList')}
                </Drawer.Screen>

                <Drawer.Screen name="Maker" options={{drawerLabel: 'Maker Dashboard'}}>
                    {props => stackNav(props.navigation, 'MakerDashboard')}
                </Drawer.Screen>

                <Drawer.Screen name="Speech" options={{drawerLabel: 'Speech Demo'}}>
                    {props => stackNav(props.navigation, 'Speech')}
                </Drawer.Screen>

                <Drawer.Screen name="Debug" options={{drawerLabel: 'Debug'}}>
                    {props => stackNav(props.navigation, 'Debug')}
                </Drawer.Screen>

                {currentAccount.isLoggedIn && <Drawer.Screen name="Auth" options={{drawerLabel: 'Log Out'}}>
                    {props => stackNav(props.navigation, 'LogOut')}
                </Drawer.Screen>}

                {!currentAccount.isLoggedIn && <Drawer.Screen name="Auth" options={{drawerLabel: 'Log In'}}>
                    {props => stackNav(props.navigation, 'LogIn')}
                </Drawer.Screen>}

            </Drawer.Navigator>
        </NavigationContainer>
    )
})