const Route = require('route-parser')

export class DeepLinkService {

    routes: any[] = []

    public on(template: string, onMatch: ((params: any) => void)) {
        const route = new Route(template)
        this.routes.push({
            route: route,
            onMatch: onMatch
        })
    }

    public route(path: string) {
        if (path.length >= 1 && path[0] != '/') path = '/' + path

        for (const routeInfo of this.routes) {
            const match = routeInfo.route.match(path)
            if (match) {
                routeInfo.onMatch(match)
                return true
            }
        }
        return false
    }
}