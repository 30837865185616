import {action, makeObservable} from "mobx"
import {CurrentAccount} from "../../modules/vizz_account/lib/CurrentAccount"
import VideoModel from "../../modules/browse/models/VideoModel"
import {DeviceUtils} from "../utils/DeviceUtils"


export class VideoService {
    private debug: boolean = false  // don't set this to true in production

    private currentAccount!: CurrentAccount

    public playingVideo?: VideoModel;       @action public setPlayingVideo(video?: VideoModel) { this.playingVideo = video }


    constructor() {
        makeObservable(this)
    }

    public async initialize(currentAccount: CurrentAccount) {
        this.currentAccount = currentAccount
    }

    public uninitialize() {
    }

    public startPlayingVideo(video: VideoModel) {
        this.setPlayingVideo(video)
        DeviceUtils.activateKeepAwake()
    }

    public stopPlayingVideo() {
        this.setPlayingVideo(undefined)
        DeviceUtils.deactivateKeepAwake()
    }

    private logEvent(thing: string, happened: string, note: string|undefined, props: any) {
        if (this.currentAccount) this.currentAccount.analytics.logEvent(thing, happened, note, {
            playingVideo: this.playingVideo,
            ...props
        })
    }

    private consoleDebug(method: string, force: boolean = false) {
        if (this.debug || force) console.log(`[${this.constructor.name}][${this.currentAccount.person.id}] ${method}`)
    }
}
