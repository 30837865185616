import React, {forwardRef} from "react"
import {View, StyleSheet, Platform, GestureResponderEvent} from "react-native"

const DisableControlOverlayView = (props: any, ref: any) => {
    const onTouchStart = (eve: GestureResponderEvent) => {}
    const onTouchMove = (eve: GestureResponderEvent) => {}
    const onTouchEnd = (eve: GestureResponderEvent) => {}

    return (
        <View style={styles.container} pointerEvents="box-none">
            <View
                onStartShouldSetResponderCapture={
                    Platform.OS === "ios"
                        ? (evt) => {
                            return true
                        }
                        : undefined
                }
                onResponderGrant={Platform.OS === "ios" ? onTouchStart : undefined}
                onResponderMove={Platform.OS === "ios" ? onTouchMove : undefined}
                onResponderRelease={Platform.OS === "ios" ? onTouchEnd : undefined}
                style={[styles.disableTitle, {width: props.width}]}
            ></View>
            <View
                onStartShouldSetResponderCapture={
                    Platform.OS === "ios"
                        ? (evt) => {
                            return true
                        }
                        : undefined
                }
                onResponderGrant={Platform.OS === "ios" ? onTouchStart : undefined}
                onResponderMove={Platform.OS === "ios" ? onTouchMove : undefined}
                onResponderRelease={Platform.OS === "ios" ? onTouchEnd : undefined}
                style={styles.disableBottom}
            ></View>
            <View
                onStartShouldSetResponderCapture={
                    Platform.OS === "ios"
                        ? (evt) => {
                            return true
                        }
                        : undefined
                }
                onResponderGrant={Platform.OS === "ios" ? onTouchStart : undefined}
                onResponderMove={Platform.OS === "ios" ? onTouchMove : undefined}
                onResponderRelease={Platform.OS === "ios" ? onTouchEnd : undefined}
                style={styles.disableBottomIcon}
            ></View>
            <View
                onStartShouldSetResponderCapture={
                    Platform.OS === "ios"
                        ? (evt) => {
                            return true
                        }
                        : undefined
                }
                onResponderGrant={Platform.OS === "ios" ? onTouchStart : undefined}
                onResponderMove={Platform.OS === "ios" ? onTouchMove : undefined}
                onResponderRelease={Platform.OS === "ios" ? onTouchEnd : undefined}
                style={styles.disableVolume}
            ></View>
        </View>
    )
}

export default forwardRef(DisableControlOverlayView)

const styles = StyleSheet.create({
    container: {
        position: "absolute",
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        zIndex: 9999,
    },
    disableTitle: {
        position: "absolute",
        left: 0,
        top: 0,
        height: 60,
        zIndex: 9999,
    },
    disableBottom: {
        position: "absolute",
        right: 0,
        bottom: 0,
        width: 165,
        height: Platform.OS === "ios" ? 40 : 40,
        zIndex: 9999,
    },
    disableVolume: {
        position: "absolute",
        left: 60,
        bottom: 0,
        width: 165,
        height: Platform.OS === "ios" ? 40 : 40,
        zIndex: 9999,
    },
    disableBottomIcon: {
        position: "absolute",
        right: 0,
        bottom: Platform.OS === "ios" ? 55 : 45,
        width: 60,
        height: 50,
        zIndex: 9999,
    },
})
