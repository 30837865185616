import React, {useContext, useEffect, useState} from 'react'
import {StyleSheet, View, StyleProp, ViewStyle, Text} from "react-native"
import {observer} from "mobx-react"
import CurrentAccountContext from "../../../vizz_account/lib/CurrentAccount"
import AppControllerContext from '../../../../app/controllers/AppController'
import {MessageNotification} from '../../../../app/lib/MessageNotification'
import {ChatMessage} from './ChatMessage'
import {CallNotification} from '../../../../app/lib/CallNotification'
import {CallMessage} from './CallMessage'
import {FriendingMessage} from './FriendingMessage'
import {InboxWidgetController} from '../../controllers/InboxWidgetController'
import {AddSchoolMessage} from './AddSchoolMessage'
import {ConnectRobloxMessage} from './ConnectRobloxMessage'
import {MissedCallMessage} from './MissedCallMessage'

type Props = {
    style?: StyleProp<ViewStyle>
    type: 'done'|'chat'|'call'|'friending'|'add_school'|'connect_roblox'|'missed_call'
    text?: string
    notification?: MessageNotification|CallNotification
    onPress?: () => void
    controller?: InboxWidgetController
    friendRequestId?: number
    callBackPersonKey?: string
}

export const Message = observer((props: Props) => {
    const currentAccount = useContext(CurrentAccountContext)
    const appController = useContext(AppControllerContext)

    return (
        <View style={[styles.container, props.style, {borderTopColor: 'rgba(234,234,223,0.2)', borderTopWidth: 1, paddingVertical: 13}]}>

            {props.type == 'done' &&
            <View style={{height: 47, justifyContent: 'center'}}>
                <Text style={{color: 'white', textAlign: 'center', fontSize: 13}}>{props.text}</Text>
            </View>}

            {props.type == 'chat' && props.notification && props.onPress &&
            <ChatMessage
                notification={props.notification as MessageNotification}
                onPress={props.onPress} />}

            {props.type == 'call' && props.notification && props.onPress &&
            <CallMessage
                notification={props.notification as CallNotification}
                onPress={props.onPress} />}

            {props.type == 'missed_call' && props.callBackPersonKey && props.controller &&
            <MissedCallMessage
                controller={props.controller}
                title={props.text}
                callBackPersonKey={props.callBackPersonKey} />}

            {props.type == 'add_school' && props.controller &&
            <AddSchoolMessage
                controller={props.controller}
                title={props.text} />}

            {props.type == 'connect_roblox' && props.controller &&
            <ConnectRobloxMessage
                controller={props.controller}
                title={props.text} />}

        </View>
    )
})

const styles = StyleSheet.create({
    container: {
    },
})
