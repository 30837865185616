import {RobloxGameDetailController} from "../../controllers/roblox_games_widget/RobloxGameDetailController"
import {observer} from "mobx-react"
import {FlatList, LogBox, Text, View, ViewabilityConfigCallbackPair} from "react-native"
import React, {useContext, useEffect, useLayoutEffect, useRef, useState} from "react"
import {
    CodeViewModel,
    RobloxGameDetailContentController,
    SecretViewModel
} from "../../controllers/roblox_games_widget/RobloxGameDetailContentController"
import CurrentAccountContext from "../../../vizz_account/lib/CurrentAccount"
import Title from "../../../lava-components/components/atoms/Title/Title"
import useTheme from "../../../lava-components/components/hooks/useTheme"
import {ActivityIndicator, TouchableRipple} from "react-native-paper"
import Button from "../../../lava-components/components/atoms/Button/Button"
import {GameBookModel} from "../../models/GameBookModel"
import GameBook from "./GameBook"
import {AnimationUtils} from "../../../../app/utils/AnimationUtils"
import {RobloxGameDetailContentSection} from "./RobloxGameDetailContentSection"
import {Color, isLandscape, isPortrait, isTablet} from "../../../../app/lib/Appearance"
import {RobloxGameDetailSidePanelState, robloxGameStyles} from "./RobloxGameDetailConstants"
import {SecretVideo} from "./SecretVideo"
import {CodeVideo} from "./CodeVideo"

// TODO: This seems like a harmless warning as per: https://github.com/mobxjs/mobx/issues/2913
LogBox.ignoreLogs(['[mobx] Out of bounds read'])

type Props = {
    robloxGameDetailController: RobloxGameDetailController
    collapseSidePanel?: () => void
    sidePanelState: RobloxGameDetailSidePanelState
    secretVideoPlayable?: boolean
    codesVideoPlayable?: boolean
    scrollMobileToEnd?: () => void
}

export const RobloxGameDetailContent = observer((props: Props) => {

    const currentAccount = useContext(CurrentAccountContext)
    const [controller] = useState(() => new RobloxGameDetailContentController(currentAccount, props.robloxGameDetailController))
    const theme = useTheme()

    const [watchingSecretsBook, setWatchingSecretsBook] = useState<GameBookModel | undefined>(undefined)
    const [pageIndex, setPageIndex] = useState(0)
    const [selectedCode, selectCode] = useState('')
    const [isTouchOutSide, setIsTouchOutSide] = useState(false)
    const [showVerifiedHeader, setShowVerifiedHeader] = useState(false)
    const [isVerifiedListItemVisible, setVerifiedListItemVisible] = useState(false)
    const [isSecretListScrolling, setSecretListScrolling] = useState(false)
    const [isCodesListScrolling, setCodesListScrolling] = useState(false)

    useEffect(() => {
        void controller.initialize()
        return () => {
            controller.uninitialize()
        }
    }, [])

    useEffect(() => {
        if (!watchingSecretsBook) {
            setShowVerifiedHeader(isVerifiedListItemVisible)
        }
    }, [watchingSecretsBook])

    useLayoutEffect(() => {
        AnimationUtils.configureNext()
    }, [watchingSecretsBook])

    const onViewableSecretsChanged = (info: any) => {
        const verifiedAndVisibleItems = info.viewableItems.filter((i: any) => i.isViewable && i.item.verified)
        const verified = verifiedAndVisibleItems && verifiedAndVisibleItems.length > 0
        setVerifiedListItemVisible(verified)
        setShowVerifiedHeader(verified)
    }

    const secretsViewabilityConfigCallbackPairs = useRef<ViewabilityConfigCallbackPair[]>([
        {
            viewabilityConfig: {
                waitForInteraction: false,
                minimumViewTime: 0,
                viewAreaCoveragePercentThreshold: 1
            },
            onViewableItemsChanged: onViewableSecretsChanged
        },
    ])

    const SecretsSection = () => {
        let height = isTablet() ? 200 : 240
        const secrets = controller.secrets

        return (
            <RobloxGameDetailContentSection
                style={{flex: 1}}
                title={showVerifiedHeader ? 'Verified Secrets' : 'Unverified Secrets'}
                showCollapseIcon={watchingSecretsBook != undefined}
                onTitlePress={() => setWatchingSecretsBook(undefined)}
                verified={showVerifiedHeader}>
                {!secrets &&
                    <View style={{flex: 1, alignItems: 'center', justifyContent: 'center'}}>
                        <ActivityIndicator color={Color.primary} size={40} />
                    </View>}

                {secrets && secrets.length == 0 &&
                    <View style={{height: height, marginTop: theme.spacing.xl}}>
                        <Title variation={'subtitle1'}>We don't currently have any secrets for this game. </Title>
                    </View>
                }


                {secrets && secrets.length > 0 &&
                    <View style={{overflow: 'visible', display: !watchingSecretsBook ? 'flex' : 'none', flex: !watchingSecretsBook ? 1 : 0}}>
                        <FlatList
                            style={{height: height, overflow: 'visible'}}
                            horizontal={true}
                            showsHorizontalScrollIndicator={false}
                            data={secrets}
                            onScroll={()=> !isSecretListScrolling && setSecretListScrolling(true)}
                            renderItem={(info) => {
                                const vm = info.item
                                const isFirstItem = vm.isFirstInSection && info.index == 0
                                const isFirstItemOfSecondSection = vm.isFirstInSection && info.index > 0
                                const playable = isFirstItem && props.secretVideoPlayable && !isPortrait()

                                return (
                                    <View style={{overflow: 'visible'}} key={`secret-${vm.secret.id}`}>
                                        {isFirstItemOfSecondSection && showVerifiedHeader && <View style={{
                                            backgroundColor: 'rgb(31, 30, 30)',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            height: 50,
                                            position: 'absolute',
                                            paddingLeft: 42,
                                            paddingRight: 42,
                                            left: -14,
                                            top: -50
                                        }}>
                                            <Text style={robloxGameStyles.sectionHeaderText}>Unverified Secrets</Text>
                                        </View>}
                                        <View style={{flex: 1, flexDirection: 'row'}}>
                                            {isFirstItemOfSecondSection &&
                                            <View style={{
                                                width: 0.5,
                                                flex: 1,
                                                backgroundColor: theme.colors.primarySand40,
                                                marginEnd: 28,
                                                marginTop: -32,
                                                marginBottom: 36
                                            }}/>}

                                            <SecretVideo
                                                secretView={vm}
                                                playable={playable}
                                                stopPlaying={isTouchOutSide || isSecretListScrolling}
                                                videoPreRollSource={vm.verified ? require('../../assets/mov-verifiedsecrets-splash.mp4') : undefined}
                                                onSecretSelected={onSecretSelected}
                                            />
                                        </View>
                                    </View>
                                )
                            }}
                            viewabilityConfigCallbackPairs={secretsViewabilityConfigCallbackPairs.current}
                        />
                    </View>
                }

                    <View style={{display: watchingSecretsBook ? 'flex' : 'none', flex: watchingSecretsBook ? 1 : 0}}>
                        {
                            watchingSecretsBook &&
                            <GameBook
                                parentBook={props.robloxGameDetailController.parentBook}
                                book={watchingSecretsBook}
                                homeController={props.robloxGameDetailController.homeController}
                                pageIndex={pageIndex}
                                showRelated={false}
                                sidePanelState={props.sidePanelState}
                                onPageChanged={(page) => setShowVerifiedHeader((page as any).verified)}
                            />
                        }
                    </View>
            </RobloxGameDetailContentSection>
        )
    }

    const onSecretSelected = (viewModel: SecretViewModel) => {
        const book = viewModel.parentBook

        const index = (book.pages ?? []).findIndex((p) => p.id == viewModel.secret.id)
        if (index > -1) {
            setPageIndex(index)
        }

        setWatchingSecretsBook(book)

        setShowVerifiedHeader(viewModel.verified)

        const scrollMobileToEnd = props.scrollMobileToEnd
        if (isPortrait() && scrollMobileToEnd) {
            setTimeout(() => {
                scrollMobileToEnd()
            }, 500)
        }
    }

    // Codes

    const Code: React.FC<{viewModel: CodeViewModel}> = ({viewModel}) => {
        const code = viewModel.code

        const handlePress = () => {
            selectCode(code.code)
            viewModel.setRevealed(true)
            void controller.copyCode(code)
        }

        return (
            <View
                key={`code-${code.id}`}
                style={{
                    width: 278,
                    marginEnd: theme.spacing.xl,
                    marginBottom: theme.spacing.xl
                }}>
                <View style={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: 278,
                    height: 200,
                    backgroundColor: '#19273B',
                    borderRadius: 3,
                    shadowColor: 'black',
                    shadowOpacity: 0.4,
                    shadowOffset: {width: 1, height: 0},
                    shadowRadius: 5,
                }}>
                    <TouchableRipple
                        onPress={handlePress}
                        onLongPress={handlePress}
                        style={{width: '100%'}}
                    >
                        <View style={{width: '100%', paddingHorizontal: 10}}>
                            <Title style={{
                                fontFamily: 'Agrandir-Bold',
                                borderWidth: 0.5,
                                borderColor: '#EAEADF',
                                borderRadius: 3,
                                textAlign: 'center',
                                paddingTop: 8,
                                paddingBottom: 6,
                                opacity: viewModel.isRevealed ? 1 : 0.25
                            }} variation={'subtitle1'}>
                                {viewModel.isRevealed ? code.code : '*********'}
                            </Title>
                        </View>
                    </TouchableRipple>
                    <Title style={{marginTop: theme.spacing.lg, height: 36}} variation={'subtitle2'} numberOfLines={2}>
                        {code.title}
                    </Title>
                    {!viewModel.isRevealed &&
                        <Button
                            style={{marginTop: theme.spacing.xl * 2, marginBottom: theme.spacing.xl * 2}}
                            onPress={handlePress}
                            variation={'gravity'}>
                            See Code
                        </Button>}
                    {viewModel.isRevealed &&
                        <View style={{flexDirection: 'row'}}>
                            <Button
                                style={{
                                    marginTop: theme.spacing.xl * 2,
                                    marginBottom: theme.spacing.xl * 2,
                                    marginEnd: theme.spacing.xl
                                }}
                                onPress={() => {
                                    void controller.codeWorked(code)
                                }}
                                variation={'gravity'}>
                                It Worked
                            </Button>
                            <Button
                                style={{marginTop: theme.spacing.xl * 2, marginBottom: theme.spacing.xl * 2}}
                                onPress={() => {
                                    void controller.codeDidntWork(code)
                                }}
                                variation={'tertiary'}>
                                Didn't Work
                            </Button>
                        </View>
                    }
                </View>
            </View>
        )
    }

    const addCode = () => {
        return <View
            key={`code-add`}
            style={{
                width: 278,
                marginEnd: theme.spacing.xl,
                marginBottom: theme.spacing.xl
            }}>
            <View style={{
                justifyContent: 'center',
                alignItems: 'center',
                width: 278,
                height: 200,
                backgroundColor: '#19273B',
                borderRadius: 3,
                padding: theme.spacing.md,
                borderWidth: 0.5,
                borderColor: '#EAEADF',
            }}>
                <Title style={{fontFamily: 'Agrandir-Bold', textAlign: 'center', paddingBottom: theme.spacing.lg}}
                    variation={'title2'}>New Code</Title>
                <Title style={{marginTop: theme.spacing.lg, height: 36}} variation={'subtitle2'} numberOfLines={2}>
                    Do you know of a code that is missing?
                </Title>

                <Button
                    style={{marginTop: theme.spacing.xl * 2, marginBottom: theme.spacing.xl * 2}}
                    onPress={() => controller.addCode()}
                    variation={'primary'}>
                    Add Code
                </Button>
            </View>
        </View>
    }

    const Codes = () => {
        if (!controller.codes || controller.codes.length == 0) return null

        return (
            <FlatList
                style={{flex: 1}}
                contentContainerStyle={{
                    flexDirection: 'row',
                }}
                horizontal={true}
                onScroll={()=> !isCodesListScrolling && setCodesListScrolling(true)}
                scrollEventThrottle={16}
                showsHorizontalScrollIndicator={false}
                data={controller.codes}
                extraData={selectedCode}
                renderItem={({item, index}) => (
                    <>
                        {controller.codesVideo && index === 0 && <CodeVideo
                            playable={index === 0 && props.codesVideoPlayable}
                            controller={controller}
                            robloxGameDetailController={props.robloxGameDetailController}
                            stopPlaying={isTouchOutSide || isCodesListScrolling}
                         />}
                        <Code viewModel={item}/>
                        {index === controller.codes!.length - 1 && addCode()}
                    </>
                )}
            />
        )
    }

    const CodesSection = () => {
        const codeViewModels = controller.codes
        const isCollapsed: boolean = watchingSecretsBook != undefined && isLandscape()

        const title = isCollapsed ? 'Codes' : 'Active Codes'
        const style: any = isCollapsed ? undefined : {flex: 1}

        return (
            <RobloxGameDetailContentSection
                style={style}
                title={title}
                verified={true}
                collapsed={isCollapsed}
                onTitlePress={() => setWatchingSecretsBook(undefined)}>

                {!codeViewModels &&
                <View style={{flex: 1, alignItems: 'center', justifyContent: 'center'}}>
                    <ActivityIndicator color={Color.primary} size={40} />
                </View>}

                {codeViewModels && codeViewModels.length > 0 && !isCollapsed && Codes()}

                {codeViewModels && codeViewModels.length == 0 && !isCollapsed &&
                    <View style={{flex: 1}}>
                        <View style={{flexDirection: isLandscape() ? 'row' : 'column'}}>
                            {controller.evaluatedCodesSupport &&
                                <View style={{flex: 1, justifyContent: 'center'}}>
                                    <Title variation={'title1'}>No codes. We checked.</Title>
                                    <Title style={{
                                        marginTop: theme.spacing.xxxl,
                                        marginBottom: theme.spacing.xxxl * 2,
                                        marginRight: theme.spacing.xxl,
                                        maxWidth: 400
                                    }} variation={'title2'}>
                                        {props.robloxGameDetailController.parentBook.title} has no codes
                                        right now. If the game still has a codes input, the developer plans to release codes later.
                                    </Title>
                                </View>}

                            {!controller.evaluatedCodesSupport &&
                                <View style={{flex: 1, justifyContent: 'center'}}>
                                    <Title variation={'title1'}>New game! Codes?</Title>
                                    <Title style={{
                                        marginTop: theme.spacing.xxxl,
                                        marginBottom: theme.spacing.xxxl * 2,
                                        marginRight: theme.spacing.xxl,
                                        maxWidth: 400
                                    }} variation={'title2'}>
                                        We just added this game to Lava. We still need to check if there are codes for it.
                                    </Title>
                                </View>}

                            <View style={{flex: 1}}>
                                {addCode()}
                            </View>
                        </View>
                        <View style={{flexGrow: 1}} />
                    </View>
                }
            </RobloxGameDetailContentSection>
        )
    }

    return (
        <View style={{flex: 1}} onTouchEnd={(e)=>[props.collapseSidePanel?.(), setIsTouchOutSide(true)]}>
            {CodesSection()}
            <View style={{height: theme.spacing.xxxl * 2}} />
            {SecretsSection()}
        </View>
    )
})
