import React, {useContext, useEffect, useState} from 'react'
import CurrentAccountContext from '../../../vizz_account/lib/CurrentAccount'
import {Dimensions} from 'react-native'
import {Style} from "../../../../app/lib/Appearance"
import DimBackground from '../DimBackground'
import VerticalSlide from '../VerticalSlide'
import BookModel from '../../models/BookModel'
import Book from './Book'
import SeriesController from '../../controllers/library_widget/SeriesController'
import {reaction} from 'mobx'
import {observer} from 'mobx-react'
import BookLoading from './BookLoading'
import HomeController from '../../../start/controllers/HomeController'


type Props = {
    firstBook?: BookModel
    firstBookOpenTrigger?: string
    firstBookOpenPosition?: number
    minTop: number
    searchBarHeight: number
    onDeactivated?: (() => void)
    homeController?: HomeController
}

const screen = Dimensions.get("screen")
const window = Dimensions.get("window")

const Series = observer((props: Props) => {
    const currentAccount = useContext(CurrentAccountContext)
    const [series] = useState<SeriesController>(() => new SeriesController(currentAccount, props.homeController))
    const [seriesActive, setSeriesActive] = useState<boolean>(true)

    const [bookTops, setBookTops] = useState<number[]>([0,0,0])

    const [book0FullyHidden, setBook0FullyHidden] = useState<boolean>(true)
    const [book1FullyHidden, setBook1FullyHidden] = useState<boolean>(true)
    const [book2FullyHidden, setBook2FullyHidden] = useState<boolean>(true)
    const [book3FullyHidden, setBook3FullyHidden] = useState<boolean>(true)

    const [initialFirstBook] = useState<BookModel|undefined>(() => props.firstBook)

    const DURATION = 300

    useEffect(() => {
        if (props.firstBook) series.initialize(props.firstBook, props.firstBookOpenTrigger, props.firstBookOpenPosition)

        return () => {
            series.uninitialize()
        }
    }, [])

    useEffect(() => {
        if (props.firstBook == initialFirstBook) return // we only want this useEffect when props.firstBook actually changes

        // NOTE: props.firstBook can be undefined for two reasons, both in the initial loading state when Series is being rendered
        // and when it's intentionally set to undefined in order to dismiss itself. We need to handle both cases.
        if (props.firstBook == undefined)
            series.close('external') // this triggers the closeAll reaction
        else
            series.initialize(props.firstBook, props.firstBookOpenTrigger)

    }, [props.firstBook])

    useEffect(() => {
        calcBookTops()

        const cleanupCloseMonitor = reaction(() => series.closeAll, (closeAll) => {
            if (!closeAll) return
            setSeriesActive(false)
        })

        const cleanupBookIndexMonitor = reaction(() => series.activeLayerIndex, () => {
            calcBookTops()
        })

        return () => {
            cleanupCloseMonitor()
            cleanupBookIndexMonitor()
        }
    }, [])

    const calcBookTops = () => {
        const height = Dimensions.get('screen').height
        const heightPreviewRow = height * Style.Series.previewRowHeightPerct
        const heightNameOnly = height * Style.Series.nameHeightPerct

        let tops = []

        if (series.activeLayerIndex == 0) {
            tops[0] = props.minTop
            tops[1] = screen.height
            tops[2] = screen.height
            tops[3] = screen.height

            setBook0FullyHidden(false)
        } else if (series.activeLayerIndex == 1) {
            tops[0] =  props.minTop
            tops[1] = props.minTop + heightPreviewRow
            tops[2] = screen.height
            tops[3] = screen.height

            setBook0FullyHidden(false)
            setBook1FullyHidden(false)
        } else if (series.activeLayerIndex == 2) {
            tops[0] =  props.minTop
            tops[1] =  props.minTop + heightNameOnly
            tops[2] =  props.minTop + heightNameOnly + heightPreviewRow
            tops[3] = screen.height

            setBook0FullyHidden(false)
            setBook1FullyHidden(false)
            setBook2FullyHidden(false)

        } else if (series.activeLayerIndex >= 3) {
            tops[0] =  props.minTop
            tops[1] =  props.minTop
            tops[2] =  props.minTop + heightNameOnly
            tops[3] =  props.minTop + heightNameOnly + heightPreviewRow

            setBook0FullyHidden(false)
            setBook1FullyHidden(false)
            setBook2FullyHidden(false)
            setBook3FullyHidden(false)
        }

        setBookTops(tops)
    }

    return (
        <DimBackground active={seriesActive} duration={DURATION} onActivate={() => {}} onActivated={() => {}} onDeactivate={() => series.close('tapped-background')} onDeactivated={props.onDeactivated}>
            <VerticalSlide top={bookTops[0]} active={seriesActive} duration={DURATION} covered={series.activeLayerIndex}>
                {series.activeBookIndex == undefined ?
                    <BookLoading
                        seriesController={series}
                        searchBarHeight={props.searchBarHeight}
                    />
                :
                    <Book
                        seriesController={series}
                        bookIndex={0}
                        searchBarHeight={props.searchBarHeight}
                        covered={!seriesActive || series.activeLayerIndex > 0}
                    />
                }
            </VerticalSlide>

            <VerticalSlide top={bookTops[1]} active={seriesActive} duration={DURATION} onAtTop={(top) => { if (top == Dimensions.get('screen').height) setBook1FullyHidden(true) }} covered={series.activeLayerIndex - 1}>
                {!book1FullyHidden &&
                <Book
                    seriesController={series}
                    bookIndex={1}
                    searchBarHeight={props.searchBarHeight}
                    covered={series.activeLayerIndex > 1}
                />}
            </VerticalSlide>

            <VerticalSlide top={bookTops[2]} active={seriesActive} duration={DURATION} onAtTop={(top) => { if (top == Dimensions.get('screen').height) setBook2FullyHidden(true) }} covered={series.activeLayerIndex - 2}>
                {!book2FullyHidden &&
                    <Book
                        seriesController={series}
                        bookIndex={2}
                        searchBarHeight={props.searchBarHeight}
                        covered={series.activeLayerIndex > 2}
                    />}
            </VerticalSlide>

            <VerticalSlide top={bookTops[3]} active={seriesActive} duration={DURATION} onAtTop={(top) => { if (top == Dimensions.get('screen').height) setBook3FullyHidden(true) }}>
                {!book3FullyHidden &&
                    <Book
                        seriesController={series}
                        bookIndex={3}
                        searchBarHeight={props.searchBarHeight}
                        covered={series.activeLayerIndex > 3}
                    />}
            </VerticalSlide>

        </DimBackground>
    )
})

export default Series