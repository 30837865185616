import React, {useEffect, useRef, useState} from 'react'
import {Animated, StyleSheet, View} from "react-native"
import {ProgressBar} from "react-native-paper"
import {AnimationUtils} from '../../../../app/utils/AnimationUtils'
import {ArrayUtils} from '../../../../app/utils/ArrayUtils'
import {LavaImage} from "../../../../app/views/components/LavaImage"

type Props = {
    imageUrl: string
    numColumns: number
    numRows: number
    durationMS: number
}

class Tile {
    key: string
    opacity: Animated.Value = new Animated.Value(1.0)
    visible: boolean = true

    constructor(key: string) {
        this.key = key
    }
}

export const TiledImageLoading = (props: Props) => {

    const emptyTiles = (): Tile[][] => {
        let tiles: Tile[][] = []
        for (let i=0; i<props.numRows; i++) {
            let row: Tile[] = []
            for (let j=0; j<props.numColumns; j++) {
                row.push(new Tile(`${i}_${j}`))
            }
            tiles.push(row)
        }
        return tiles
    }

    const tileBorderColor = 'rgba(255, 255, 255, 0.2)'
    const [tiles, setTiles] = useState<Tile[][]>(() => emptyTiles())
    const [layout, setLayout] = useState({x: 0, y: 0, width: 0, height: 0})
    const [progress, setProgress] = useState(0)
    const progressRef = useRef(0)
    const progressHandle = useRef<any>()
    const [reachedEnd, setReachedEnd] = useState(false)


    useEffect(() => {
        revealNext()
        startProgress()
        return () => {
            clearTimeout(progressHandle.current)
        }
    }, [])

    // TILES

    const renderCoverView = () => {
        return (
            <View
                style={{
                    flex: 1,
                    backgroundColor: 'transparent',
                    ...StyleSheet.absoluteFillObject
                }}
                onLayout={(e) => setLayout(e.nativeEvent.layout)}>
                {
                    tiles.map((row, index) => {
                        return renderRow(row, index)
                    })
                }
            </View>
        )
    }

    const renderRow = (tiles: Tile[], rowIndex: number) => {
        return (
            <View key={`${rowIndex}`} style={{flex: 1, flexDirection: 'row'}}>
                {
                    tiles.map((tile) => {
                        return renderTile(tile)
                    })
                }
            </View>
        )
    }

    const renderTile = (tile: Tile) => {
        return (
            <Animated.View
                key={tile.key}
                style={{
                    width: tileWidth(),
                    height: tileHeight(),
                    backgroundColor: 'black',
                    borderWidth: 0.5,
                    borderColor: tileBorderColor,
                    opacity: tile.opacity
                }}
            />
        )
    }

    const tileWidth = () => {
        return layout.width / props.numColumns
    }

    const tileHeight = () => {
        return layout.height / props.numRows
    }

    const tileDuration = () => {
        return props.durationMS / (props.numRows * props.numColumns)
    }

    const randomTile = (): Tile|null => {
        const rowsWithVisibleTiles = tiles.filter((row) => {
            return row.filter((tile) => tile.visible).length > 0
        })

        if (rowsWithVisibleTiles.length == 0) return null

        const row = ArrayUtils.shuffle(rowsWithVisibleTiles)[0]
        const visibleTiles = row.filter((tile) => tile.visible)
        return ArrayUtils.shuffle(visibleTiles)[0]
    }

    const revealNext = () => {
        let tile = randomTile()
        if (tile == null) return
        tile.visible = false

        Animated.timing(tile.opacity, {
            toValue: 0.0,
            duration: tileDuration(),
            useNativeDriver: true,
        }).start((finished) => {
            if (finished) {
                revealNext()
            }
        })
    }

    // PROGRESS

    const startProgress = () => {
        progressHandle.current = AnimationUtils.animateProgress(props.durationMS, (increment: number) => {
            progressRef.current = progressRef.current + increment
            setProgress(progressRef.current)

            if (progressRef.current > 1) {
                clearTimeout(progressHandle.current)
                setReachedEnd(true)
            }
        })
    }

    const renderProgress = () => {
        return (
            <View style={{position: 'absolute', left: 16, bottom: 16, right: 16}}>
                <ProgressBar style={{backgroundColor: 'rgba(0, 0, 0, 0.5)'}} color={'white'} progress={progress} indeterminate={reachedEnd} />
            </View>
        )
    }

    return (
        <View style={{flex: 1, backgroundColor: 'black'}}>
            <LavaImage
                style={{
                    flex: 1,
                    backgroundColor: 'black',
                    borderWidth: 0.5,
                    borderColor: tileBorderColor
                }}
                resizeMode={'cover'}
                source={{uri: props.imageUrl}}/>
            {renderCoverView()}
            {renderProgress()}
        </View>
    )
}