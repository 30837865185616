import {action, computed, makeObservable, observable} from "mobx"
import {CurrentAccount} from '../../../vizz_account/lib/CurrentAccount'
import {FeatureFlagModel} from "../../../vizz_account/models/FeatureFlagModel"
import BookModel from "../../models/BookModel"
import PageModel from "../../models/PageModel"
import VideoModel from "../../models/VideoModel"
import {ActivityName, TimedActivity} from "../../../../app/lib/services/TimedActivity"
import {GameBookModel} from "../../models/GameBookModel"
import HomeController from "../../../start/controllers/HomeController"
import {ProfileModel, ProfileType} from "../../../social/models/ProfileModel"
import SentryService from "../../../../app/services/SentryService"
import PersonModel from "../../../vizz_account/models/PersonModel"

export enum GameBookControllerState {
    UNINITIALIZED = 'uninitialized',
    INITIALIZING = 'initializing',
    INITIALIZED = 'initialized',
}

class GameBookController {
    private debug: boolean = false  // don't set this to true in production

    private currentAccount: CurrentAccount
    private homeController: HomeController

    private bookFetchTimeout?: any

    private initialPageIndex: number
    private showRelated: boolean

    @observable gameBook: GameBookModel
    private parentBook: GameBookModel
    @observable currentPageIndex: number | null = null
    @observable state: GameBookControllerState = GameBookControllerState.UNINITIALIZED

    constructor(currentAccount: CurrentAccount, homeController: HomeController, parentBook: GameBookModel, book: GameBookModel, pageIndex: number, showRelated: boolean) {
        this.consoleDebug(`new()`)

        this.currentAccount = currentAccount
        this.homeController = homeController
        this.parentBook = parentBook
        this.gameBook = book
        this.initialPageIndex = pageIndex
        this.currentPageIndex = pageIndex
        this.showRelated = showRelated

        makeObservable(this)
    }

    public async initialize() {
        this.consoleDebug(`initialize()`)

        TimedActivity.log(ActivityName.PLAY_VIDEO, 'init-book')

        this.updateState(GameBookControllerState.INITIALIZING)
        this.updateState(GameBookControllerState.INITIALIZED)   // It's important for this to be the last line so we do
    }

    public async uninitialize() {
        if (this.bookFetchTimeout) clearTimeout(this.bookFetchTimeout)
    }

    @computed
    get active() {
        return true
    }


    // Public helper methods

    public async shouldShowRelatedVideos() {
        if (!this.currentAccount.hasFeature(FeatureFlagModel.SUGGESTIONS_REQUIRE_YOUTUBE)) return true

        if (this.book == undefined) return false
        if (this.book.pages == undefined) return false

        const page = this.book.pages[this.currentPageIndex ?? 0] as any
        const hasYouTube = (await this.homeController?.launcherWidgetController?.isAppInstalledAsync('YouTube'))
        const hasRoblox = (await this.homeController?.launcherWidgetController?.isAppInstalledAsync('Roblox'))

        return (hasYouTube || hasRoblox) && !page.verified
    }

    @computed
    get book(): BookModel {
        return this.gameBook
    }

    @action
    setGameBook(book: GameBookModel) {
        this.gameBook = book
    }

    @action
    setBookPageVerified(id: number, person: PersonModel, profile: ProfileModel, verifiedAt: string) {
        this.consoleDebug(`setBookPageVerified(${id})`)
        if (this.gameBook.pages == undefined) return

        const verifiedPerson = { id: person.id, key: person.key, profile: profile } as PersonModel

        const pageIndex = this.gameBook.pages.findIndex((p) => p.id == id.toString())
        this.gameBook.pages[pageIndex].video.verified_by_person = verifiedPerson
        this.gameBook.pages[pageIndex].video.verified_by_person_id = verifiedPerson.id
        this.gameBook.pages[pageIndex].video.verified_at = verifiedAt
    }

    @action
    public setCurrentPageIndex(id: number|null) {
        this.consoleDebug(`setCurrentPageIndex(${id})`)

        this.currentPageIndex = id

        if (this.currentPageIndex != null) {
            const pages = this.book.pages ?? []
            const video = pages[this.currentPageIndex].video
            void this.recordPlayedVideo(video, this.currentPageIndex+1)
        }
    }

    public async recordPlayedVideo(video: VideoModel, position: number) {
        this.consoleDebug(`recordPlayedVideo(${video})`)

        let book = await this.currentAccount.api.post(`browse.viewing_path`, {
            video_id: video.id,
            viewed: true,
            played: true,
        }) as BookModel

        this.currentAccount.personData.numberOfVideosStarted += 1
        this.currentAccount.dayData.numberOfVideosStarted += 1

        this.currentAccount.analytics.logEvent('video', 'started', video.title, {
            video_id: video.id,
            video_title: video.title,
            parent_book_id: this.parentBook.id,
            parent_book_title: this.parentBook.title,
            book_title: this.book.title,
            concept_name: this.book.concept?.primary_name.name,
            book_id: this.book.id,
            position_in_book: position,
            number_of_concepts_opened_today: this.currentAccount.dayData.numberOfConceptsOpened,
            number_of_concepts_opened_ever: this.currentAccount.personData.numberOfConceptsOpened,
            number_of_videos_started_today: this.currentAccount.dayData.numberOfVideosStarted,
            number_of_videos_started_ever: this.currentAccount.personData.numberOfVideosStarted,
        })
    }

    @action
    public addVideoAfterIndex(video: VideoModel, afterIndex: number) {
        const page = {
            id: 'video' + video.id + Math.floor(Math.random() * 10000).toString(),
            title: video.title,
            video: video,
        } as PageModel

        this.book.pages?.splice(afterIndex+1, 0, page)
    }

    public async resetBookState() {
        try {
            await this.currentAccount.api.delete(`browse.reset_book_path(${this.book.id})`)
        } catch (error) {
            SentryService.captureError(error)
        }
    }

    // Private helper methods

    @action
    private updateState(state: GameBookControllerState) {
        this.consoleDebug(`updateState(${state})`)
        this.state = state
    }

    @computed
    get friendsForSharing() {
        return this.homeController?.friendWidgetController?.friends
            .filter((f) => f.profile_type == ProfileType.LAVA)
    }

    public async shareVideo(personKey: string, videoUrl: string) {
        const message = `Check out this Roblox secret I found on Lava! ${videoUrl}`
        await this.homeController.appController.message.sendMessage(personKey, message)
    }

    // Private instance utility methods

    private consoleDebug(method: string, force: boolean = false) {
        if(this.debug || force) console.log(`${this.constructor.name}: ${method}   state = ${this.state}`)
    }
}

export default GameBookController
