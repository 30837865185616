import React from 'react'
import {StyleSheet, View} from "react-native"
import {RouteProp, useNavigation} from "@react-navigation/native"
import {RootDrawerNavigatorList} from "../../lib/interfaces/root-tab-navigator"
import {StackNavigationProp} from "@react-navigation/stack"
import {Color} from "../../lib/Appearance"
import {observer} from "mobx-react"

export type FeedScreenRouteProp = RouteProp<RootDrawerNavigatorList, 'Home'>

export type FeedScreenNavigationProp = StackNavigationProp<
    RootDrawerNavigatorList,
    'Home'>


const CollectionFeedScreen = observer(() => {

    const navigation = useNavigation<any>()

    // const currentAccount = useContext(CurrentAccountContext)
    // const feedStore = useFeedStore()
    // const [refreshing, setRefreshing] = useState<boolean>(false)
    // const [showingVoicePrompt, setShowingVoicePrompt] = useState<boolean>(false)
    // const conceptsCarousel = useRef<FlatList>(null)
    // const feedList = useRef<FlatList>(null)
    // const isFocused = useRef(false)
    // const [askVizzText, setAskVizzText] = useState('')
    // const [flatListLayout, setFlatListLayout] = useState<LayoutRectangle>({x: 0, y: 0, width: 0, height: 0})
    // const isAutoPlayEnabled = useRef<boolean>(false)
    // const [hasCameraPermission, setHasCameraPermission] = useState<boolean|null>(null)
    // const playVizzOnNextLoadId = useRef<string|undefined>(undefined)
    // const [textSearchActive, setTextSearchActive] = useState(false)
    // const searchInput = useRef<any>(null)
    //
    // useEffect(() => {
    //     const eventBlock = reaction(() => feedStore.broadcastEvent,
    //         (broadcastEvent) => {
    //             if (broadcastEvent == 'reset-cache') {
    //                 refresh()
    //             }
    //         })
    //
    //     const playingVizz = reaction(() => currentAccount.playingVizzId,
    //         (vizzId) => {
    //             if (vizzId && !isAutoPlayEnabled.current) {
    //                 isAutoPlayEnabled.current = true
    //             }
    //         })
    //
    //     const vizzesBlock = reaction(() => feedStore.vizzes, (vizzes) => {
    //         if (playVizzOnNextLoadId.current && vizzes.length > 0 && vizzes[0].id == playVizzOnNextLoadId.current) {
    //             feedList.current?.scrollToIndex({index: 0, animated: true})
    //             currentAccount.updatePlayingVizz(playVizzOnNextLoadId.current)
    //             playVizzOnNextLoadId.current = undefined
    //         }
    //     })
    //
    //     // window.setTimeout(() => {
    //     //     props.navigation.navigate('ConceptNavigator', {conceptId: 641354})
    //     // }, 1000)
    //
    //     return () => {
    //         eventBlock()
    //         playingVizz()
    //         vizzesBlock()
    //     }
    // }, [])
    //
    // useFocusEffect(
    //     useCallback(() => {
    //         isFocused.current = true
    //         AppState.addEventListener('change', handleAppStateChange)
    //
    //         return () => {
    //             isFocused.current = false
    //             AppState.removeEventListener('change', handleAppStateChange);
    //             console.log('return of useCallBack() useFocusEffect()')
    //             currentAccount.updatePlayingVizz(null)
    //         }
    //     }, [])
    // )
    //
    // const handleAppStateChange = (nextAppState: AppStateStatus) => {
    //     if (nextAppState == 'active') {
    //         (async () => {
    //             isAutoPlayEnabled.current = false
    //             conceptsCarousel.current?.scrollToOffset({offset: 0, animated: false})
    //             await feedStore.setSelectedConcept(undefined)
    //             await refresh(true)
    //         })()
    //     }
    // };
    //
    // useEffect(() => {
    //     refresh()
    // }, [])
    //
    // const updateCamera = async () => {
    //     if (Platform.OS != 'web') {
    //         const { status } = await Camera.requestCameraPermissionsAsync()
    //         setHasCameraPermission(status === PermissionStatus.GRANTED)
    //     }
    // }
    //
    // const refresh = (refreshConcepts: boolean = false) => {
    //     updateCamera()
    //
    //     console.log('Feed: Try refresh')
    //     if (refreshing && isFocused) return
    //
    //     (async () => {
    //         console.log('Feed: Do refresh')
    //         currentAccount.updatePlayingVizz(null)
    //
    //         try {
    //             await feedStore.refresh(refreshConcepts)
    //             setRefreshing(false)
    //             showSwipeHint()
    //         } catch (error) {
    //             console.log(`Error loading collections ${error}`)
    //         }
    //     })()
    // }
    //
    // // Header
    //
    // const headerButtonSize = () => {
    //     return isTablet() ? 64 : 44
    // }
    //
    // const headerIconSize = () => {
    //     return isTablet() ? 36 : 24
    // }
    //
    // const header = () => {
    //     return (
    //         <View style={{flexDirection: 'row', alignItems: 'center', margin: 16}}>
    //             {(!textSearchActive && Platform.OS != 'web') &&
    //             <TouchableRipple style={{}}
    //                              onLongPress={onCameraTapped}
    //                              onPress={onCameraTapped}>
    //                 {Platform.OS == 'ios' && hasCameraPermission ?
    //                     <Camera style={[styles.micButton, {width: headerButtonSize(), height: headerButtonSize(), overflow: 'hidden', backgroundColor: Color.primary}]}>
    //                         <MaterialIcons style={{opacity: 0.5}} name="camera-alt" size={headerIconSize()} color="white"/>
    //                     </Camera>
    //                     :
    //                     <View style={[styles.micButton, {width: headerButtonSize(), height: headerButtonSize(), overflow: 'hidden', backgroundColor: Color.primary}]}>
    //                         <MaterialIcons style={{opacity: 0.5}} name="camera-alt" size={headerIconSize()} color="white"/>
    //                     </View>
    //                 }
    //             </TouchableRipple>
    //             }
    //
    //             <TextInput
    //                 style={{flex: 1, marginHorizontal: 16}}
    //                 ref={searchInput}
    //                 label="AskVizz"
    //                 mode={'outlined'}
    //                 multiline={false}
    //                 numberOfLines={1}
    //                 value={askVizzText}
    //                 onFocus={() => {
    //                     LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut)
    //                     setTextSearchActive(true)
    //                 }}
    //                 onBlur={() => {
    //                     LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut)
    //                     setTextSearchActive(false)
    //                 }}
    //                 onChangeText={text => setAskVizzText(text)}
    //             />
    //             {(textSearchActive || Platform.OS == 'web') &&
    //             <Button mode={'contained'} onPress={() => {
    //                 if (askVizzText.length > 0) {
    //                     searchInput.current?.blur()
    //                     setShowingVoicePrompt(true)
    //                 }
    //             }}>
    //                 <MaterialIcons name="search" size={40} color="white"/>
    //             </Button>
    //             }
    //
    //             {(!textSearchActive && Platform.OS != 'web') &&
    //             <TouchableRipple style={{borderRadius: headerButtonSize() / 2}}
    //                              onLongPress={onVoiceTapped}
    //                              onPress={onVoiceTapped}>
    //                 <View style={[styles.micButton, {width: headerButtonSize(), height: headerButtonSize()}]}>
    //                     <MaterialIcons name="mic" size={headerIconSize()} color="black"/>
    //                 </View>
    //             </TouchableRipple>
    //             }
    //         </View>
    //     )
    // }
    //
    // const onCameraTapped = () => {
    //     props.navigation.navigate('ObjectIdentifier', {onResult: handleObjectIdentifierResult})
    // }
    //
    // const onVoiceTapped = () => {
    //     currentAccount.updatePlayingVizz(null)
    //     setShowingVoicePrompt(true)
    // }
    //
    // const handleObjectIdentifierResult = (vizz?: Vizz, conceptId?: number) => {
    //     if (conceptId) {
    //         // TODO: Go to "new" concept navigator
    //     } else if (vizz) {
    //         feedStore.setHasMadeInitialConceptSelection(true)
    //         playVizzOnNextLoadId.current = vizz.id
    //         feedStore.addVizz(vizz)
    //     }
    // }
    //
    // // Concepts Grid
    //
    // const renderCollectionsGrid = () => {
    //     return (
    //         <FlatGrid
    //             style={styles.collection}
    //             itemDimension={200}
    //             renderItem={renderGridItem}
    //             data={feedStore.collections}
    //             spacing={16}
    //         />
    //     )
    // }
    //
    // const renderGridItem = (info: ListRenderItemInfo<VizzCollection>) => {
    //     return (
    //         <TouchableRipple onPress={() => onGridItemTapped(info)}
    //                          onLongPress={() => onGridItemTapped(info)}>
    //             <View>
    //                 <View style={{height: 150}}>
    //                     {info.item.data.length > 2 &&
    //                     <View style={[styles.coverImage, {transform: [{rotate: '2deg'}]}]}>
    //                         <Image style={{flex: 1}} source={{uri: info.item.data[2].coverImageUrl}}/>
    //                     </View>
    //                     }
    //                     {info.item.data.length > 1 &&
    //                     <View style={[styles.coverImage, {transform: [{ rotate: '-3deg' }]}]}>
    //                         <Image style={{flex: 1}} source={{uri: info.item.data[1].coverImageUrl}}/>
    //                     </View>
    //                     }
    //                     <View style={[styles.coverImage]}>
    //                         <Image style={{flex: 1}} source={{uri: VizzCollection.collectionCover(info.item)}}/>
    //                     </View>
    //                 </View>
    //                 <Subheading style={{height: 40, marginTop: 8, textAlign: 'center'}}
    //                             numberOfLines={2}>{info.item.title}</Subheading>
    //             </View>
    //         </TouchableRipple>
    //     )
    // }
    //
    // const onGridItemTapped = (info: ListRenderItemInfo<VizzCollection>) => {
    //     (async () => {
    //         await feedStore.setHasMadeInitialConceptSelection(true)
    //         await feedStore.setSelectedConcept(info.item.id)
    //         await refresh()
    //         scrollToSelectedConcept()
    //     })()
    // }
    //
    // const scrollToSelectedConcept = () => {
    //     const index = feedStore.collections
    //         .map((c) => c.id)
    //         .indexOf(feedStore.selectedConcept ?? '')
    //
    //     if (index && index > -1) {
    //         // Carousel flat list neeeds time to render before we can scroll
    //         // to the appropriate item.
    //         window.setTimeout(() => {
    //             conceptsCarousel.current?.scrollToIndex({
    //                 index: index,
    //                 animated: true,
    //                 viewPosition: 0.5
    //             })
    //         }, 500)
    //     }
    // }
    //
    // // Vizz List
    //
    // const viewabilityConfig = {
    //     waitForInteraction: false,
    //     itemVisiblePercentThreshold: 90,
    // }
    //
    // const onViewableItemsChanged = useRef((result: { viewableItems: Array<ViewToken>, changed: Array<ViewToken> }) => {
    //     if (!isAutoPlayEnabled.current) {
    //         return
    //     }
    //
    //     // it's possible that viewableItems items includes some that are not viewable, not sure why
    //     const definitelyViewableItems = result.viewableItems.filter((item) => item.isViewable)
    //
    //
    //     if (definitelyViewableItems.length == 0) {
    //         console.log('definitelyViewableItems == 0')
    //         currentAccount.updatePlayingVizz(null)
    //         return
    //     }
    //
    //     //console.log(`onViewableItemsChanged: ${definitelyViewableItems[0].item.id} =? ${currentAccount.playingVizzId}`)
    //     if (definitelyViewableItems[0].item.id != currentAccount.playingVizzId) {
    //         //console.log(`about to updatePlayingVizz: ${currentAccount.playingVizzId}`)
    //         currentAccount.updatePlayingVizz(definitelyViewableItems[0].item.id)
    //         console.log(`\nCHANGED playing vizz to ${currentAccount.playingVizzId}`)
    //     } else {
    //         console.log("did not update updatePlayingVizz")
    //     }
    // })
    //
    // const vizzWidth = () => {
    //     const width = flatListLayout.width - 32
    //     const proposedHeight = width * 3/4
    //     if (proposedHeight > (flatListLayout.height - 32)) {
    //         return (flatListLayout.height - 32) * (4/3)
    //     } else {
    //         return width
    //     }
    // }
    //
    // const renderListItem = (info: ListRenderItemInfo<Vizz>) => {
    //     const item: Vizz = info.item
    //
    //     return (
    //         <View style={{flex: 1, height: flatListLayout.height, justifyContent: 'center', alignItems: 'center'}}>
    //             <View style={{width: vizzWidth()}}>
    //                 <FixedAspectView aspectRatio={3 / 4}>
    //                     <VizzView vizz={item} showInlineTitle={true} />
    //                 </FixedAspectView>
    //             </View>
    //         </View>
    //     )
    // }
    //
    // const onListItemTapped = (info: ListRenderItemInfo<Vizz>) => {
    //     currentAccount.updatePlayingVizz(info.item.id)
    // }
    //
    // const renderList = () => {
    //     return (
    //         <FlatList
    //             ref={feedList}
    //             onLayout={(event) => {
    //                 setFlatListLayout(event.nativeEvent.layout)
    //             }}
    //             pagingEnabled={true}
    //             style={{flex: 1}}
    //             viewabilityConfig={viewabilityConfig}
    //             onViewableItemsChanged={onViewableItemsChanged.current}
    //             data={feedStore.vizzes}
    //             renderItem={renderListItem}
    //             keyExtractor={item => item.id}
    //         />
    //     )
    // }
    //
    // const showSwipeHint = () => {
    //     if (feedStore.vizzes.length < 4) return
    //
    //     feedList.current?.scrollToIndex({
    //         index: 3,
    //         animated: false
    //     })
    //
    //     window.setTimeout(() => {
    //         feedList.current?.scrollToIndex({
    //             index: 0,
    //             animated: true
    //         })
    //         setTimeout(() => {
    //             isAutoPlayEnabled.current = true
    //         }, 1000)
    //     }, 300)
    // }
    //
    // // Carousel
    //
    // const renderConceptsCarousel = () => {
    //     return (
    //         <View style={{height: 175}}>
    //             <FlatList
    //                 ref={conceptsCarousel}
    //                 horizontal={true}
    //                 data={feedStore.collections}
    //                 renderItem={renderCarouselItem}
    //                 keyExtractor={(c) => c.id}
    //             />
    //         </View>
    //     )
    // }
    //
    // const carouselItemSize = () => {
    //     return {
    //         width: 160,
    //         height: 120
    //     }
    // }
    //
    // const renderCarouselItem = (info: ListRenderItemInfo<VizzCollection>) => {
    //     return (
    //         <Observer>
    //             {() =>
    //                 <TouchableRipple
    //                     onLongPress={() => onCarouselItemTapped(info)}
    //                     onPress={() => onCarouselItemTapped(info)}>
    //                     <View>
    //                         <View style={{height: carouselItemSize().height, width: carouselItemSize().width, margin: 8}}>
    //                             <View style={styles.carouselItemImage}>
    //                                 <Image style={{flex: 1}} source={{uri: VizzCollection.collectionCover(info.item)}}/>
    //                             </View>
    //                             {feedStore.isSelectedConcept(info.item.id) &&
    //                             <View style={styles.checkmark}>
    //                                 <MaterialIcons name="check-circle" size={64} color={Color.secondary} />
    //                             </View>
    //                             }
    //                         </View>
    //                         <Paragraph style={{textAlign: 'center'}}
    //                                    numberOfLines={2}>{info.item.title}</Paragraph>
    //                     </View>
    //                 </TouchableRipple>
    //             }
    //         </Observer>
    //     )
    // }
    //
    // const onCarouselItemTapped = (info: ListRenderItemInfo<VizzCollection>) => {
    //     (async () => {
    //         conceptsCarousel.current?.scrollToIndex({
    //             index: info.index,
    //             animated: true,
    //             viewPosition: 0.5
    //         })
    //
    //         await feedStore.setSelectedConcept(info.item.id)
    //         await feedStore.addSelectedFeedConcept(info.item.id)
    //         await refresh()
    //     })()
    // }
    //
    // const showVizz = (key?: string, concept?: ConceptNodeResponse) => {
    //     setShowingVoicePrompt(false)
    //     console.log(`Show Vizz ${key} ${concept?.id} `)
    //     if (concept) {
    //         // TODO: Go to "new" concept navigator
    //     } else if (key) {
    //         props.navigation.navigate('VizzDetail', {vizzId: key, autoPlay: true})
    //     }
    // }
    //
    // return (
    //     <SafeAreaView style={{flex: 1}}>
    //         <View style={{flex: 1}}>
    //             {header()}
    //             {feedStore.hasMadeInitialConceptSelection ? renderConceptsCarousel() : null}
    //             {feedStore.hasMadeInitialConceptSelection ? renderList() : renderCollectionsGrid()}
    //         </View>
    //         {showingVoicePrompt && <VoicePrompt
    //             onFinish={showVizz}
    //             searchText={askVizzText.length > 0 ? askVizzText : undefined}/>}
    //         <StatusBar style="dark" />
    //     </SafeAreaView>
    // )

    // Old version is invalid, probably delete this entire file soon.
    return (<View/>)
})

const styles = StyleSheet.create({
    collection: {
        flex: 1
    },
    coverImage: {
        borderWidth: 2,
        borderColor: 'white',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        height: 150,
        shadowOffset: {
            width: 0.5,
            height: 0.5
        },
        shadowColor: 'black',
        shadowOpacity: 0.6,
        shadowRadius: 4
    },
    micButton: {
        justifyContent: 'center',
        alignItems: 'center',
        shadowRadius: 4,
        shadowColor: 'black',
        shadowOpacity: 0.5,
        borderRadius: 40,
        backgroundColor: Color.secondary
    },
    checkmark: {
        position: 'absolute',
        justifyContent: 'center',
        alignItems: 'center',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0
    },
    carouselItemImage: {
        flex: 1,
        shadowOffset: {
            width: 0.5,
            height: 0.5
        },
        shadowColor: 'black',
        shadowOpacity: 0.6,
        shadowRadius: 4
    }
})

export default CollectionFeedScreen
