import React from 'react'
import {StyleSheet, Text, View, ViewStyle} from "react-native"
import {ProfileModel} from "../../models/ProfileModel"
import {observer} from "mobx-react"
import {Color} from "../../../../app/lib/Appearance"
import {Typography} from '../../../../app/lib/Typography'
import {FriendDot} from "../../../lava-components/components/molecules/Friend/FriendDot"
import Title from '../../../lava-components/components/atoms/Title/Title'

type Props = {
    profile: ProfileModel
    forceOnline?: boolean
    style?: ViewStyle
    avatarBackgroundColor?: string
    friendRequestSent?: boolean
    variant?: 'primaryLarge' | 'secondary' | 'primarySmall'
}

const FriendDetails = observer((props: Props) => {
    const avatarUrl = props.profile.avatar_image_url ?? props.profile.roblox_profile?.avatar_headshot_url
    const username = props.profile.pretty_username || props.profile.roblox_profile?.display_name || ''

    if (props.friendRequestSent) {
        return false // Do not render FriendDetails because the user should already be in the friend list
    }

    return (
        <View style={[{flex: 1, justifyContent: 'center'}, props.style]}>
            <View style={{flexDirection: 'row'}}>
                <FriendDot
                    username={props.profile.username}
                    avatarUrl={avatarUrl}
                    variant={props.variant ?? 'secondary'}
                    dotStyle={{borderColor: '#5b512a'}}
                    status={props.forceOnline ? 'online' : 'offline'}
                    friendRequestSent={props.friendRequestSent}
                />

                <View style={{justifyContent: 'center', marginLeft: 16}}>
                    <Title variation='subtitle2'>
                        {username.trim()}
                    </Title>
                    { !props.friendRequestSent && props.profile.person?.age &&
                        <View style={{flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start'}}>
                            <Title variation='subtitle2'>
                                {props.profile.person?.age ? `(Age ${props.profile.person.age})` : ''}
                            </Title>
                        </View>
                    }
                    { props.friendRequestSent &&
                        <View style={{flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start'}}>
                            <Title variation='subtitle2'>
                                Friend request sent
                            </Title>
                        </View>
                    }
                </View>
            </View>
        </View>
    )
})

export default FriendDetails
