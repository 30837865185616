import React, {useEffect, useRef} from 'react'
import {Video} from '../Video'
import VideoCard from '../../../lava-components/components/molecules/VideoCard/VideoCard'
import {SecretViewModel} from '../../controllers/roblox_games_widget/RobloxGameDetailContentController'
import {AVPlaybackSource} from 'expo-av'
import {observer} from 'mobx-react'

type Props = {
    secretView: SecretViewModel
    videoPreRollSource: AVPlaybackSource | undefined
    playable: boolean | undefined
    onSecretSelected: (viewModel: SecretViewModel) => void
    stopPlaying: boolean
    featured?: boolean
}

export const SecretVideo = observer((props: Props) => {
    const videoRef = useRef<any>(null)

    useEffect(() => {
        if (videoRef && props.secretView.secret.video && props.playable) {
            if (props.stopPlaying) {
                videoRef.current?.autoPause()
            } else {
                videoRef.current?.autoPlay()
            }
        }
    }, [videoRef, props.secretView.secret.video, props.playable, props.stopPlaying])

    const handlePress = (event:any) => {
            const { locationX, locationY } = event.nativeEvent
            if (videoRef && props.playable && locationY > 120 && locationX < 65) {
                videoRef.current?.autoPause()
            } else {
                props.onSecretSelected(props.secretView)
            }
    }

    return (
        <VideoCard
            style={{
                marginRight: 28,
            }}
            variation={props.featured ? 'featured' : 'secrets'}
            showPlayButton={false}
            title={props.secretView.secret.title}
            thumbnailUrl={props.secretView.secret.video.thumbnail_url ?? ''}
            isVerified={props.secretView.verified}
            creatorName={props.secretView.secret.video.channel_title}
            duration={props.secretView.secret.video.duration_number}
            views={props.secretView.secret.video.view_count}
            uploadedOn={props.secretView.secret.video.published_at}
            videoComponent={props.playable && <Video
                video={props.secretView.secret.video}
                ref={videoRef}
                preRollSource={props.videoPreRollSource}
            />  }
            playable={props.playable && !props.stopPlaying}
            onPress={handlePress}
        />


    )
})