import {action, makeObservable, observable} from "mobx"
import {CurrentAccount} from "../../vizz_account/lib/CurrentAccount"

export enum VideoCallFakeState {
    LOADING,
    STARTED,
    FINISHED
}

export class VideoCallFakeController {
    private debug: boolean = false  // don't set this to true in production

    private currentAccount: CurrentAccount

    @observable state: VideoCallFakeState = VideoCallFakeState.LOADING;             @action private setState(state: VideoCallFakeState) { this.state = state }

    constructor(currentAccount: CurrentAccount) {
        this.consoleDebug(`new()`)

        this.currentAccount = currentAccount

        makeObservable(this)
    }

    public async initialize() {
        this.consoleDebug(`initialize()`)
        this.setState(VideoCallFakeState.STARTED)
    }

    public uninitialize() {
        this.consoleDebug(`uninitialize()`)
    }


    // Public methods


    // Private instance utility methods

    private consoleDebug(method: string, force: boolean = false) {
        if (this.debug || force) console.log(`${this.constructor.name}: ${method}  state = ${this.state}`)
    }
}
