import React, {useEffect, useRef, useState} from 'react'
import {VizzModel, VizzHelpers} from "../../../modules/vizz_maker/models/VizzModel"
import Vizz from "../../../modules/vizz_maker/views/Vizz"
import {observer} from "mobx-react"
import {ActivityIndicator, SafeAreaView, ScrollView, StyleSheet, Text, View} from "react-native"
import {Color} from "../../lib/Appearance"
import FixedAspectView from "../components/FixedAspectView"
import RailsAPIService from '../../services/RailsAPIService'

type Props = {
    vizzId: string
}

const SingleVizzWeb = observer((props: Props) => {

    const railsApi = new RailsAPIService()
    const [vizz, setVizz] = useState<(VizzModel | undefined)>(undefined)
    const [refreshing, setRefreshing] = useState<boolean>(false)

    const [primaryColor, setPrimaryColor] = useState<string>('#FFFFFF')
    const [foregroundColor, setForegroundColor] = useState<string>('#000000')

    useEffect(() => {
        void refresh()
    }, [])

    useEffect(() => {
        if (vizz) {
            setPrimaryColor(vizz.primaryColor)
            setForegroundColor(vizz.foregroundColor)
        }
    }, [vizz])

    const refresh = async () => {
        if (refreshing) return

        const vizz = await railsApi.get(`vizz_maker.vizz_path(${props.vizzId})`)
        setRefreshing(false)
        setVizz(vizz)
    }

    return (
        <SafeAreaView style={[styles.container, {backgroundColor: primaryColor}]}>
            {vizz != undefined ?
                <View style={{flex: 1}}>
                    <ScrollView
                        style={styles.scrollView}
                        contentContainerStyle={styles.scrollContainer}
                        alwaysBounceHorizontal={false}>
                        <View style={{marginHorizontal: '30%'}}>
                            <FixedAspectView aspectRatio={3/4}>
                                <Vizz vizz={vizz} />
                            </FixedAspectView>
                            <Text style={styles.title}>
                                {vizz.title}
                            </Text>
                            <Text style={[styles.body, {color: foregroundColor}]}>
                                {VizzHelpers.fullScript(vizz)}
                            </Text>
                        </View>
                    </ScrollView>
                </View>
                :
                <View style={styles.loading}>
                    <ActivityIndicator size="large" color={Color.primary}/>
                </View>
            }
        </SafeAreaView>
    )
})

const styles = StyleSheet.create({
    container: {
        flex: 1
    },
    scrollContainer: {
        alignItems: 'center'
    },
    scrollView: {
        flex: 1,
        marginTop: 16
    },
    vizzPlayer: {
        width: '100%'
    },
    loading: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center'
    },
    title: {
        color: Color.secondary,
        fontWeight: '800',
        fontSize: 48,
        paddingTop: 10,
        paddingBottom: 5,
        lineHeight: 54
    },
    body: {
        fontStyle: 'normal',
        fontWeight: '500',
        paddingTop: 4,
        paddingBottom: 24,
        lineHeight: 26,
        fontSize: 16
    }
})

export default SingleVizzWeb