import React, {useContext, useEffect, useRef, useState} from "react"
import {View, ViewStyle} from "react-native"
import {Camera, CameraType} from "expo-camera"
import {VideoCallFakeController, VideoCallFakeState} from "../controllers/VideoCallFakeController"
import CurrentAccountContext from "../../vizz_account/lib/CurrentAccount"
import {LavaImage} from "../../../app/views/components/LavaImage"

type Props = {
    style?: ViewStyle,
    flexDirection: "row" | "column",
    cameraEnabled: boolean
}

export const VideoCallFake = (props: Props) => {
    const currentAccount = useContext(CurrentAccountContext)
    const camera = useRef<Camera>(null)
    const [controller] = useState<VideoCallFakeController>(() => new VideoCallFakeController(currentAccount))

    useEffect(() => {
        void controller.initialize()

        return () => {
            controller.uninitialize()
        }
    }, [])

    return (
        <View style={[{flex: 1, flexDirection: props.flexDirection}, props.style]}>
            <View style={{flex: 1, backgroundColor: 'rgba(255,255,255,0.1)'}}>
                <LavaImage style={{flex: 1}} resizeMode={'cover'} source={{uri: "https://res.cloudinary.com/explanation/image/upload/v1692046150/riley-call_gjezaj.jpg"}} />
            </View>
            <View style={{flex: 1}}>
                {props.cameraEnabled &&
                <Camera
                    style={{flex: 1}}
                    ref={camera}
                    type={CameraType.front}
                    autoFocus={true}
                    zoom={0.0}
                />}
            </View>
        </View>
    )
}