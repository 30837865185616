import {FriendWidgetController} from "../controllers/FriendWidgetController"
import HomeController from "../../start/controllers/HomeController"
import React from "react"
import {StyleSheet} from "react-native"
import {observer} from "mobx-react"
import {Color} from "../../../app/lib/Appearance"
import {Typography} from "../../../app/lib/Typography"

type Props = {
    friendWidgetController: FriendWidgetController
    homeController: HomeController
}

export const ChannelDetail = observer((props: Props) => {
    // TODO: This web-specific component is only here because the native channel detail uses some agora components
    return null
})

const styles = StyleSheet.create({
    container: {
        flex: 1,
        overflow: 'hidden'
    },
    header: {
        flexDirection: 'row',
        alignItems: 'center',
        marginVertical: 0,
    },
    sectionContainer: {
        backgroundColor: Color.lavaGreen,
        borderRadius: 6,
        overflow: 'hidden'
    },
    usernameLabel: {
        color: Color.lavaGray,
        ...Typography.label
    },
    statusLabel: {
        color: Color.lavaGray,
        opacity: 0.6,
        marginTop: 2,
        ...Typography.label
    },
})