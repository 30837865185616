import {action, makeObservable, observable} from "mobx"
import {CurrentAccount} from "../../../modules/vizz_account/lib/CurrentAccount"
import HomeController, {HomeState} from "../../start/controllers/HomeController"
import {SpeechUtils} from "../../../app/utils/AVUtils"
import {StyleSheet} from "react-native"
import {Color} from "../../../app/lib/Appearance"
import {FeatureFlagModel} from "../../vizz_account/models/FeatureFlagModel"

export enum OnboardingState {
    // These steps need to be in the correct order because nextStep() below assumes they are
    BLANK,
    LOADING,
    IDFA,
    AUDIO,
    PITCH,
    PROFILE,
    AGE,
    SMS,
    INTERESTS,
    SAMPLE,
    FRIENDS,
    REFERRAL,
    SCHOOL,
    DONE,

    // phased out but we have not removed the files yet
    VOICE,
}

export class OnboardingController {
    private debug: boolean = false  // don't set this to true in production

    private currentAccount: CurrentAccount
    public homeController: HomeController
    public speech:SpeechUtils
    public styles = StyleSheet.create({
        messageText: {
            marginHorizontal: 32,
            marginBottom: 28,
            color: Color.tertiary,
            fontSize: 25,
            fontWeight: '400',
            lineHeight: 36,
            textAlign: 'center'
        },
    })

    @observable state: OnboardingState = OnboardingState.BLANK

    constructor(currentAccount: CurrentAccount, homeController: HomeController) {
        this.consoleDebug(`new()`)

        this.currentAccount = currentAccount
        this.homeController = homeController
        this.speech = new SpeechUtils(currentAccount, true)

        makeObservable(this)
    }

    public initialize() {
        this.consoleDebug(`initialize() - ${this.currentAccount.personData.profileOnboardingNeeded}`)

        if (! this.currentAccount.personData.onboardingNeeded) {
            this.consoleDebug(`done()`)
            void this.done()
        } else {
            this.setState(OnboardingState.LOADING)
            this.currentAccount.analytics.logEvent('onboarding', `started-${this.currentAccount.personData.lastDeviceType}`)
            OnboardingController.setStepState(this.currentAccount, this)
        }
    }

    public uninitialize() {
        this.consoleDebug(`uninitialize()`)
    }


    // Public methods

    public async done() {
        this.consoleDebug(`done()`)
        this.speech.pause()

        if (!this.currentAccount.personData.onboardingNeeded) {
            if (!this.currentAccount.person.activated_at) {
                void this.currentAccount.api.patch("vizz_account.person_path", { activated_at: new Date() })
            }
        } else {
            this.currentAccount.personData.onboardingNeeded = false
            void this.currentAccount.analytics.logEvent('onboarding','finished', undefined, {})
            void this.currentAccount.api.patch("vizz_account.person_path", { activated_at: new Date() })
        }

        this.homeController.setState(HomeState.HOME)
    }


    // Private helper methods

    @action
    public setState(state: OnboardingState) {
        this.consoleDebug(`setState(${state})`)
        this.state = state
    }


    // Private instance utility methods

    private consoleDebug(method: string, force: boolean = false) {
        if (this.debug || force) console.log(`${this.constructor.name}: ${method}  state = ${this.state}`)
    }


    // Static methods

    public nextStep() {
        this.consoleDebug(`nextStep()`)

        this.speech.pause()

        let nextStep = OnboardingState[this.state + 1].toLowerCase()
        if (nextStep == "interests" && !this.currentAccount.hasFeature(FeatureFlagModel.DAILY_CONTENT)) {
            nextStep = OnboardingState[this.state + 2].toLowerCase()
        }

        OnboardingController.setStep(
            nextStep,
            this.currentAccount,
            this,
            'do-not-force', // when advancing naturally through onboarding, we want to allow skipping over a step which has been completed
        )
    }

    public static setStep(name: string|undefined, currentAccount: CurrentAccount, onboardingController?: OnboardingController, mode: string = 'force-this-step') {

        switch (name) {
            case 'idfa':
                if (mode == 'force-this-step') {
                    currentAccount.personData.idfaOnboardingNeeded = true
                    currentAccount.personData.onboardingNeeded = true
                }

                if (onboardingController) this.setStepState(currentAccount, onboardingController)
                return
            case 'audio':
                currentAccount.personData.idfaOnboardingNeeded = false

                if (mode == 'force-this-step') {
                    currentAccount.personData.audioOnboardingNeeded = true
                    currentAccount.personData.onboardingNeeded = true
                }
                if (onboardingController) this.setStepState(currentAccount, onboardingController)
                return
            case 'pitch':
                currentAccount.personData.idfaOnboardingNeeded = false
                currentAccount.personData.audioOnboardingNeeded = false

                if (mode == 'force-this-step') {
                    currentAccount.personData.pitchOnboardingNeeded = true
                    currentAccount.personData.onboardingNeeded = true
                }
                if (onboardingController) this.setStepState(currentAccount, onboardingController)
                return

            case 'profile':
                currentAccount.personData.idfaOnboardingNeeded = false
                currentAccount.personData.audioOnboardingNeeded = false
                currentAccount.personData.pitchOnboardingNeeded = false

                if (mode == 'force-this-step') {
                    currentAccount.personData.profileOnboardingNeeded = true
                    currentAccount.personData.onboardingNeeded = true
                }
                if (onboardingController) this.setStepState(currentAccount, onboardingController)
                return
            case 'age':
                currentAccount.personData.idfaOnboardingNeeded = false
                currentAccount.personData.audioOnboardingNeeded = false
                currentAccount.personData.pitchOnboardingNeeded = false
                currentAccount.personData.profileOnboardingNeeded = false

                if (mode == 'force-this-step') {
                    currentAccount.personData.ageOnboardingNeeded = true
                    currentAccount.personData.onboardingNeeded = true
                }
                if (onboardingController) this.setStepState(currentAccount, onboardingController)
                return
            case 'sms':
                    currentAccount.personData.idfaOnboardingNeeded = false
                    currentAccount.personData.audioOnboardingNeeded = false
                    currentAccount.personData.pitchOnboardingNeeded = false
                    currentAccount.personData.profileOnboardingNeeded = false
                    currentAccount.personData.ageOnboardingNeeded = false

                    if (mode == 'force-this-step') {
                        currentAccount.personData.smsOnboardingNeeded = true
                        currentAccount.personData.onboardingNeeded = true
                    }
                    if (onboardingController) this.setStepState(currentAccount, onboardingController)
                    return
            case 'interests':
                currentAccount.personData.idfaOnboardingNeeded = false
                currentAccount.personData.audioOnboardingNeeded = false
                currentAccount.personData.pitchOnboardingNeeded = false
                currentAccount.personData.profileOnboardingNeeded = false
                currentAccount.personData.ageOnboardingNeeded = false
                currentAccount.personData.smsOnboardingNeeded = false

                if (mode == 'force-this-step') {
                    currentAccount.personData.interestsOnboardingNeeded = true
                    currentAccount.personData.onboardingNeeded = true
                }
                if (onboardingController) this.setStepState(currentAccount, onboardingController)
                return
            case 'sample':
                currentAccount.personData.idfaOnboardingNeeded = false
                currentAccount.personData.audioOnboardingNeeded = false
                currentAccount.personData.pitchOnboardingNeeded = false
                currentAccount.personData.profileOnboardingNeeded = false
                currentAccount.personData.ageOnboardingNeeded = false
                currentAccount.personData.smsOnboardingNeeded = false
                currentAccount.personData.interestsOnboardingNeeded = false

                if (mode == 'force-this-step') {
                    currentAccount.personData.sampleOnboardingNeeded = true
                    currentAccount.personData.onboardingNeeded = true
                }
                if (onboardingController) this.setStepState(currentAccount, onboardingController)
                return
            case 'friends':
                currentAccount.personData.idfaOnboardingNeeded = false
                currentAccount.personData.audioOnboardingNeeded = false
                currentAccount.personData.pitchOnboardingNeeded = false
                currentAccount.personData.profileOnboardingNeeded = false
                currentAccount.personData.ageOnboardingNeeded = false
                currentAccount.personData.smsOnboardingNeeded = false
                currentAccount.personData.interestsOnboardingNeeded = false
                currentAccount.personData.sampleOnboardingNeeded = false

                if (mode == 'force-this-step') {
                    currentAccount.personData.friendsOnboardingNeeded = true
                    currentAccount.personData.onboardingNeeded = true
                }
                if (onboardingController) this.setStepState(currentAccount, onboardingController)
                return
            case 'referral':
                currentAccount.personData.idfaOnboardingNeeded = false
                currentAccount.personData.audioOnboardingNeeded = false
                currentAccount.personData.pitchOnboardingNeeded = false
                currentAccount.personData.profileOnboardingNeeded = false
                currentAccount.personData.ageOnboardingNeeded = false
                currentAccount.personData.smsOnboardingNeeded = false
                currentAccount.personData.interestsOnboardingNeeded = false
                currentAccount.personData.sampleOnboardingNeeded = false
                currentAccount.personData.friendsOnboardingNeeded = false

                if (mode == 'force-this-step') {
                    currentAccount.personData.referralOnboardingNeeded = true
                    currentAccount.personData.onboardingNeeded = true
                }
                if (onboardingController) this.setStepState(currentAccount, onboardingController)
                return
            case 'school':
                currentAccount.personData.idfaOnboardingNeeded = false
                currentAccount.personData.audioOnboardingNeeded = false
                currentAccount.personData.pitchOnboardingNeeded = false
                currentAccount.personData.profileOnboardingNeeded = false
                currentAccount.personData.ageOnboardingNeeded = false
                currentAccount.personData.smsOnboardingNeeded = false
                currentAccount.personData.interestsOnboardingNeeded = false
                currentAccount.personData.sampleOnboardingNeeded = false
                currentAccount.personData.friendsOnboardingNeeded = false
                currentAccount.personData.referralOnboardingNeeded = false

                if (mode == 'force-this-step') {
                    currentAccount.personData.schoolOnboardingNeeded = true
                    currentAccount.personData.onboardingNeeded = true
                }
                if (onboardingController) this.setStepState(currentAccount, onboardingController)
                return

            case 'done':
                currentAccount.personData.idfaOnboardingNeeded = false
                currentAccount.personData.audioOnboardingNeeded = false
                currentAccount.personData.pitchOnboardingNeeded = false
                currentAccount.personData.profileOnboardingNeeded = false
                currentAccount.personData.ageOnboardingNeeded = false
                currentAccount.personData.smsOnboardingNeeded = false
                currentAccount.personData.interestsOnboardingNeeded = false
                currentAccount.personData.sampleOnboardingNeeded = false
                currentAccount.personData.friendsOnboardingNeeded = false
                currentAccount.personData.referralOnboardingNeeded = false
                currentAccount.personData.schoolOnboardingNeeded = false

                if (onboardingController) this.setStepState(currentAccount, onboardingController)
                return
        }
    }

    public static setStepState(currentAccount: CurrentAccount, onboardingController: OnboardingController) {
        if (currentAccount.personData.idfaOnboardingNeeded) {
            onboardingController.setState(OnboardingState.IDFA)
        } else if (currentAccount.personData.audioOnboardingNeeded) {
            onboardingController.setState(OnboardingState.AUDIO)
        } else if (currentAccount.personData.pitchOnboardingNeeded) {
            onboardingController.setState(OnboardingState.PITCH)
        } else if (currentAccount.personData.profileOnboardingNeeded) {
            onboardingController.setState(OnboardingState.PROFILE)
        } else if (currentAccount.personData.ageOnboardingNeeded) {
            onboardingController.setState(OnboardingState.AGE)
        } else if (currentAccount.personData.smsOnboardingNeeded) {
            onboardingController.setState(OnboardingState.SMS)
        } else if (currentAccount.personData.interestsOnboardingNeeded) {
            onboardingController.setState(OnboardingState.INTERESTS)
        } else if (currentAccount.personData.sampleOnboardingNeeded) {
            onboardingController.setState(OnboardingState.SAMPLE)
        } else if (currentAccount.personData.friendsOnboardingNeeded) {
            onboardingController.setState(OnboardingState.FRIENDS)
        } else if (currentAccount.personData.referralOnboardingNeeded) {
            onboardingController.setState(OnboardingState.REFERRAL)
            void onboardingController.homeController.loadInitialRecentGames()
        } else if (currentAccount.personData.schoolOnboardingNeeded) {
            onboardingController.setState(OnboardingState.SCHOOL)
        } else {
            void onboardingController.done()
        }
    }
}
