import React, {useContext, useState} from 'react'
import {View} from "react-native"
import {observer} from "mobx-react"
import {useNavigation} from '@react-navigation/native'
import {
    Card,
    Button,
    TextInput,
    Headline,
    ActivityIndicator
} from 'react-native-paper'
import {Color} from "../../lib/Appearance"
import CurrentAccountContext from '../../../modules/vizz_account/lib/CurrentAccount'

const GenerateVizzScreen = observer(() => {
    const navigation = useNavigation<any>()
    const [questionText, setQuestionText] = useState<string>("")
    const [answerScriptText, setAnswerScriptText] = useState<string>("")
    const [loading, setLoading] = useState<boolean>(false)
    const currentAccount = useContext(CurrentAccountContext)

    const generateVizz = async() => {
        setLoading(true)
        const result = await currentAccount.api.post("vizz_maker.search", {search_phrase: questionText, force_create: true, answer_script: answerScriptText})
        setLoading(false)
        navigation.navigate("EditVizz", {vizzKey: result.key})
    }

    return (
        <View style={{flex: 1, alignContent: "center"}}>
            <Headline style={{alignSelf: "center", margin: 10}}>Generate Vizz</Headline>
            <Card>
                <Card.Content>
                    <TextInput
                        onChangeText={(text) => setQuestionText(text)}
                        style={{margin: 10}}
                        value={questionText}
                        placeholder={"Enter a question: e.g. What is an apple?"}
                        disabled={loading}
                        />
                    <TextInput
                        onChangeText={(text) => setAnswerScriptText(text)}
                        style={{margin: 10, height: 100}}
                        multiline
                        numberOfLines={10}
                        value={answerScriptText}
                        placeholder={"Answer script (optional)"}
                        disabled={loading}
                        />
                    <Button
                        color={Color.primary}
                        disabled={loading}
                        style={{margin: 10}}
                        mode={'contained'}
                        onPress={generateVizz}>
                        Generate Vizz
                    </Button>
                    {loading ? <ActivityIndicator style={{marginStart: 16}} color={Color.secondary} /> : null}
                </Card.Content>
            </Card>
        </View>
    )
})

export default GenerateVizzScreen
