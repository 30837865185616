import {FriendWidgetController} from "../../controllers/FriendWidgetController"
import {StyleProp, View, ViewStyle} from "react-native"
import React from "react"
import {PresenceModel} from "../../models/PresenceModel"
import {observer} from "mobx-react"
import {FriendRow} from "../../../lava-components/components/molecules/Friend/FriendRow"

type Props = {
    presence: PresenceModel,
    disableOpenProfile?: boolean,
    friendWidgetController?: FriendWidgetController,
    style?: StyleProp<ViewStyle>,
}

export const Friend = observer((props: Props) => {
    const openPeopleDetails = () => {
        if (props.disableOpenProfile) return
        void props.friendWidgetController?.openChannelDetails([props.presence.person_key])
    }

    const openBook = () => {
        if (activityType() === 'playing') {
            if (props.presence.concept_id) {
                props.friendWidgetController?.homeController?.openBookFromPieces(
                    'friend-list',
                    props.presence.concept_id,
                    props.presence.concept_name ?? '',
                    'Game',
                    props.presence.concept_media_url,
                    undefined // no initial video_id should be forced
                )
            }
        }
    }

    const offlineMsg = () => {
        const status = props.presence.broadcasted_status
        if (status == 'online') return undefined
        let label = status.charAt(0).toUpperCase() + status.slice(1) // 'offline'

        if (props.presence.last_online_msg)
            label += `, ${props.presence.last_online_msg}`

        return label
    }

    const activityType = () => {
        if (props.presence.robloxing && props.presence.concept_media_url)
            return 'playing'
        else if (props.presence.talking)
            return 'talking'
    }

    const gameImageUrl = () => {
        if (activityType() == 'playing') {
            return props.presence.concept_media_url
        }
        return undefined
    }

    const activityName = () => {
        if (activityType() == 'playing') {
            return "Playing"
        } else if (activityType() == 'talking') {
            return "On Call"
        } else {
            return undefined
        }
    }

    return (
        <View style={props.style}>
            <FriendRow
                friendCircles={[
                    {
                        variant: 'primaryLarge',
                        username: props.presence.pretty_username,
                        avatarUrl: props.presence.roblox_profile_avatar_headshot_url,
                        status: props.presence.broadcasted_status
                    }
                ]}
                gameImageUrl={gameImageUrl()}
                inRoblox={props.presence.robloxing}
                activity={activityName()}
                lastSeen={offlineMsg()}
                onPress={openPeopleDetails}
                onActivityPress={openBook}
            />
        </View>
    )
})
