import React, {useContext, useEffect, useRef, useState} from 'react'
import {StyleSheet, View, ViewStyle, Text} from "react-native"
import {observer} from "mobx-react"
import {InterestsController, InterestsState} from "../../controllers/steps/InterestsController"
import CurrentAccountContext from "../../../vizz_account/lib/CurrentAccount"
import {OnboardingController, OnboardingState} from '../../controllers/OnboardingController'
import {reaction} from "mobx"
import {State, TapGestureHandler, TapGestureHandlerStateChangeEvent} from 'react-native-gesture-handler'
import {InterestsGrid} from '../../views/interests/InterestsGrid'
import {PrimaryButton} from '../../../../app/views/components/PrimaryButton'
import {isLandscape, isPortrait} from '../../../../app/lib/Appearance'

type Props = {
    style?: ViewStyle
    onboardingController: OnboardingController
}

export const Interests = observer((props: Props) => {
    const currentAccount = useContext(CurrentAccountContext)
    const [controller] = useState<InterestsController>(() => new InterestsController(currentAccount, props.onboardingController))


    useEffect(() => {
        const neededReaction = reaction(() => props.onboardingController.state, async(state) => {

            if (currentAccount.personData.interestsOnboardingNeeded) {
                if (state == OnboardingState.INTERESTS) await controller.initialize()
                if (state == OnboardingState.INTERESTS) await controller.startStep()
            }

        }, { fireImmediately: true })

        return () => {
            neededReaction()
            controller.uninitialize()
        }
    }, [])

    return (
        props.onboardingController.state == OnboardingState.INTERESTS && controller.state == InterestsState.LOADED ?
        <View style={[{flex: 1, alignItems: 'center', marginTop: isPortrait() ? 60 : 30}, props.style]}>
            <Text style={[props.onboardingController.styles.messageText, {marginBottom: 5}]}>Select up to five things</Text>

            <View style={{flexGrow: 1, width: '100%', flexDirection: isLandscape() ? 'row' : 'column'}}>

                {isLandscape() && <View style={{flex: 1}} />}

                <InterestsGrid
                    style={{flex: 5}}
                    maxSelect={5}
                    freezeSelection={controller.freezeSelections}
                    onLoad={(interestsGridController) => controller.setInterestsGridController(interestsGridController)}
                    onToggleSelectedInterest={(interest) => controller.onToggleSelectedInterest(interest)}
                />

                {isLandscape() &&
                <View style={{flex: 1, flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'center'}}>
                    {controller.interests &&
                    <PrimaryButton
                        disabled={controller.selectedInterests.length == 0}
                        onPress={() => controller.nextStep()}
                        iconType={'FontAwesome'}
                        icon={'thumbs-up'}
                        iconSide={'right'}
                    />}
                </View>}

                {isPortrait() &&
                <View style={{marginTop: 16, justifyContent: 'center', alignItems: 'center'}}>
                    {controller.interests &&
                    <PrimaryButton
                        disabled={controller.selectedInterests.length == 0}
                        onPress={() => controller.nextStep()}
                        iconType={'FontAwesome'}
                        icon={'thumbs-up'}
                        iconSide={'right'}
                    />}
                </View>}
            </View>
        </View> : null
    )
})

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
})